import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
    },

    businessUnit: {
      width: '55.6rem',
    },

    uniqueIdKey: {
      marginLeft: variables.gap.xl,

      '& > :nth-child(2)': {
        marginTop: variables.gap.lg,
      },
    },

    businessUnitCards: {
      marginTop: variables.gap.xl,
    },

    program: {
      width: '33.4rem',
      marginTop: variables.gap.xl,
    },

    programCards: {
      marginTop: variables.gap.lg,
    },
  }),
);
