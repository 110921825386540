import { memo, useState } from 'react';
import { Box } from '@material-ui/core';

import { Accordion, Icon } from '@components';
import { MindsetTable } from '@views';

import { useStyles } from './MindsetView.styles';
import { MindsetViewProps } from './MindsetView.props';

/**
 * MindsetView component
 * @returns {JSX.Element}
 */

export const MindsetView = memo(({ mindsetViews }: MindsetViewProps): JSX.Element => {
  const styles = useStyles();

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const toggleAccordion = (index: number) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <Box className={styles.mindsetView}>
      {mindsetViews.length &&
        mindsetViews.map((item, index) => {
          const { cohorts, defaultMindset, experienceId, experienceName, products } = item;
          return (
            <Accordion
              key={experienceId}
              expanded={expanded[index] ? !expanded[index] : true}
              onChange={() => toggleAccordion(index)}
              icon={<Icon.Mindset />}
              title={experienceName}
              uiType="secondary"
            >
              <MindsetTable
                cohorts={cohorts}
                defaultMindset={defaultMindset}
                products={products}
                experienceId={experienceId}
              />
            </Accordion>
          );
        })}
    </Box>
  );
});

MindsetView.displayName = 'MindsetView';
