import { PayloadAction, ActionReducerMapBuilder, isPending, isFulfilled, isRejected } from '@reduxjs/toolkit';
import { SnackbarKey } from 'notistack';

import {
  ApiError,
  experience,
  moduleDetails,
  users,
  userClients,
  deployment,
  businessUnitPrograms,
  template,
  signalLibrary,
  attributeLibrary,
} from '@modules';

import { GlobalSchema, Notification } from './Global.schema';

export const reducers = {
  isLoading: (state: GlobalSchema, action: PayloadAction<boolean>): void => {
    state.isLoading = action.payload;
  },
  enqueueNotification: (
    state: GlobalSchema,
    action: PayloadAction<
      Omit<Notification, 'key'> & {
        key?: string;
      }
    >,
  ): void => {
    state.notifications.push({
      ...action.payload,
      key: action.payload.key || new Date().getTime().toString(36),
    });
  },
  enqueueError: (state: GlobalSchema, action: PayloadAction<ApiError>): void => {
    const { code, errors, message } = action.payload;

    if (errors && Object.keys(errors).length) {
      Object.keys(errors)
        .reduce((acc: string[], k) => {
          errors[k].forEach((i: string) => acc.push(k ? `${k}: ${i}` : i));
          return acc;
        }, [])
        .forEach((error, index) => {
          state.notifications.push({
            code: code.toString(),
            message: error,
            options: { variant: 'error' },
            key: `${new Date().getTime().toString(36)}[${index}]`,
          });
        });
    } else {
      state.notifications.push({
        code: code.toString(),
        message,
        options: { variant: 'error' },
        key: new Date().getTime().toString(36),
      });
    }
  },
  closeNotification: (state: GlobalSchema, action: PayloadAction<SnackbarKey>): void => {
    state.notifications = state.notifications.map((notification) =>
      notification.key === action.payload ? { ...notification, dismissed: true } : { ...notification },
    );
  },
  removeNotification: (state: GlobalSchema, action: PayloadAction<SnackbarKey>): void => {
    state.notifications = state.notifications.filter((notification) => notification.key !== action.payload);
  },
};

const isAPendingAction = isPending(
  experience.thunk.update,
  moduleDetails.thunk.save,
  users.thunk.update,
  userClients.thunk.update,
  userClients.thunk.updateCohorts,
  deployment.thunk.update,
  deployment.thunk.attachJourney,
  businessUnitPrograms.thunk.update,
  template.thunk.update,
  signalLibrary.thunk.updateComposite,
  attributeLibrary.thunk.changeInLibrary,
);
const isAFulfilledAction = isFulfilled(
  experience.thunk.update,
  moduleDetails.thunk.save,
  users.thunk.update,
  userClients.thunk.update,
  userClients.thunk.updateCohorts,
  deployment.thunk.update,
  deployment.thunk.attachJourney,
  businessUnitPrograms.thunk.update,
  template.thunk.update,
  signalLibrary.thunk.updateComposite,
  attributeLibrary.thunk.changeInLibrary,
);
const isARejectedAction = isRejected(
  experience.thunk.update,
  moduleDetails.thunk.save,
  users.thunk.update,
  userClients.thunk.update,
  userClients.thunk.updateCohorts,
  deployment.thunk.update,
  deployment.thunk.attachJourney,
  businessUnitPrograms.thunk.update,
  template.thunk.update,
  signalLibrary.thunk.updateComposite,
  attributeLibrary.thunk.changeInLibrary,
);

export const extraReducers = (builder: ActionReducerMapBuilder<GlobalSchema>) => {
  builder.addMatcher(isAPendingAction, (state) => {
    state.saveStatus = 'loading';
  });

  builder.addMatcher(isAFulfilledAction, (state) => {
    state.saveStatus = 'success';
  });

  builder.addMatcher(isARejectedAction, (state) => {
    state.saveStatus = 'error';
  });
};
