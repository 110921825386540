import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  auth,
  businessUnitDeployments,
  businessUnitPrograms,
  Deployment,
  DeploymentStatus,
  MetaSchema,
  Program,
} from '@modules';
import { useAppDispatch } from '@store';
import { format, network, sort, isRoleEditor, env } from '@utils';
import { MenuOptions, OptionItem } from '@components';
import { content, page } from '@content';

import { SideBarItems } from './SideBar';

import {
  PrivatePagesAccessWall,
  PrivatePagesAccessHook,
  PrivateSideToggleHook,
  PrivateNavItemsHook,
  PrivateNavItemValue,
} from './Private.types';
import { AUTHENTICATING_STATUSES, NAV_ITEMS, NAV_DEP_OPTS, NAV_DEP_PROG_SUBS } from './Private.const';

/**
 * Private component hooks
 * @returns {Custom Hooks}
 */

const useProgramOptions = (
  programsMeta: MetaSchema,
  programsData: Program[],
  deploymentsMeta: MetaSchema,
  deploymentsData: Deployment[],
): MenuOptions =>
  programsMeta.status === 'loading' || deploymentsMeta.status === 'loading'
    ? [
        {
          type: 'spinner',
          id: 'spinner',
        },
      ]
    : [...programsData].sort(sort.comparator('DESC', 'createdAt')).map<OptionItem>((program) => ({
        id: `${program.id}`,
        label: program.name,
        value: `program|${program.id}`,
        subOptions: [
          ...NAV_DEP_PROG_SUBS.map((subOption) => ({
            ...subOption,
            id: subOption.value === PrivateNavItemValue.newDeployment ? `${program.id}|${subOption.id}` : subOption.id,
          })),
          ...deploymentsData
            .filter((deployment) => deployment.program.id === program.id)
            .sort(sort.comparator('DESC', 'createdAt'))
            .map<OptionItem>((deployment) => ({
              id: `${deployment.id}`,
              label: deployment.name,
              subtitle:
                deployment.status === DeploymentStatus.inMarket
                  ? format.date(deployment.waveDate, 'PP')
                  : content.notDeployed,
              description: deployment.status === DeploymentStatus.inMarket ? content.deployed : '',
              icon: deployment.status === DeploymentStatus.inMarket ? 'LockedOutline' : undefined,
              iconColorIgnore: true,
              value: PrivateNavItemValue.deployment,
            })),
        ],
      }));

const useNavItems = (accessNavItems: PrivatePagesAccessHook['navItems']): PrivateNavItemsHook => {
  const programsMeta = businessUnitPrograms.useAllMeta();
  const programsData = businessUnitPrograms.useAllData();

  const deploymentsMeta = businessUnitDeployments.useMeta();
  const deploymentsData = businessUnitDeployments.useData();

  const navItems = [...accessNavItems] as SideBarItems;
  const depsNavItemIndex = navItems.findIndex(
    (item) => item.text === 'entityManager' && item.data?.entity === 'deployment',
  );
  const programOptions = useProgramOptions(programsMeta, programsData, deploymentsMeta, deploymentsData);

  if (depsNavItemIndex !== -1) {
    navItems[depsNavItemIndex] = {
      ...navItems[depsNavItemIndex],
      opts: [...NAV_DEP_OPTS, ...programOptions],
    };
  }

  return navItems;
};

export const usePagesAccess = (): PrivatePagesAccessHook => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const { status } = auth.useUser();

  const token = network.token.get();

  const pageKey = useMemo(() => {
    const [, pathRootKey] = pathname.split('/');
    const pathRoot = `/${pathRootKey}`;
    const [currPageKey] = Object.entries(page).find(([, path]) => path.startsWith(pathRoot)) as string[];

    return currPageKey;
  }, [pathname]);

  const accessWall: PrivatePagesAccessWall = {
    experiences: false,
    admin: !isRoleEditor(),
    myAccount: env.isApp('dev'),
  };

  useEffect(() => {
    if (!token) {
      dispatch(auth.thunk.refresh());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    authenticating: !token && AUTHENTICATING_STATUSES.includes(status),
    authenticated: !!token,
    restricted: accessWall[pageKey as keyof PrivatePagesAccessWall] === false,
    navItems: useNavItems(
      NAV_ITEMS.filter((item) => !item.page || accessWall[item.page] !== false) as PrivatePagesAccessHook['navItems'],
    ),
  };
};

export const useSideToggle = (): PrivateSideToggleHook => {
  const [open, setOpen] = useState(false);

  const toggleSide = useCallback(
    (nextOpen: typeof open) => {
      if (open !== nextOpen) {
        setOpen(() => nextOpen);
      }
    },
    [setOpen, open],
  );

  return {
    open,
    handleOpen: useCallback(() => toggleSide(true), [toggleSide]),
    handleClose: useCallback(() => toggleSide(false), [toggleSide]),
  };
};
