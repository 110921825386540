import { base } from '@modules';

import { TemplateSchema } from './Template.schema';

export const initialState: TemplateSchema = {
  all: {
    ...base.state,
    data: [],
  },
  entity: {
    ...base.state,
    data: null,
  },
};
