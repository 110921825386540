import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    modulesCard: {
      padding: theme.spacing(1),
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      cursor: 'grab',
      marginBottom: theme.spacing(1),

      '&.dragging-source': {
        '& $coverImage, & $info, & $action': {
          opacity: 0,
        },
      },

      '&.dragging': {
        borderColor: variables.color.primary.mainPurple,
        borderWidth: variables.borderWidth.lg,
      },
    },

    coverImageWrapper: {
      height: '19.5rem',
      width: '100%',
      overflow: 'hidden',
      marginBottom: theme.spacing(1),
      background: variables.color.primary.backgroundLightestGray,
      position: 'relative',
    },

    coverImage: {
      width: '100%',
      height: 'auto',
      position: 'absolute',
    },

    arrow: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      padding: 0,
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: variables.color.primary.white,
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,

      '&:hover': {
        backgroundColor: variables.color.primary.mainPurple,

        '& svg path': {
          stroke: variables.color.primary.white,
        },
      },
    },

    left: {
      left: theme.spacing(0.5),
    },

    right: {
      right: theme.spacing(0.5),
    },

    content: {
      display: 'flex',
    },

    info: {
      paddingLeft: theme.spacing(1),
      flex: '1 1 auto',
    },

    name: {
      marginBottom: theme.spacing(0.5),
    },

    dark: {
      color: variables.color.primary.darkestGray,
    },

    action: {
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 auto',
    },

    actionBtnDrag: {
      padding: theme.spacing(1),
      cursor: 'grab',
    },
  }),
);
