import { variables } from '@styles';
import { SVGProps } from 'react';

/**
 * AlertCircleOutline component
 * @returns {JSX.Element}
 */

export const AlertCircleOutline = ({
  className = '',
  width = 8,
  height = 8,
  stroke = variables.color.semantic.negativeLight,
  fill = variables.color.semantic.negative,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8C3.20887 8 2.43552 7.76541 1.77772 7.32588C1.11993 6.88636 0.607239 6.26163 0.304488 5.53073C0.00173792 4.79982 -0.0774819 3.99556 0.076859 3.21964C0.2312 2.44371 0.612157 1.73098 1.17157 1.17157C1.73098 0.612157 2.44371 0.2312 3.21964 0.076859C3.99556 -0.0774819 4.79982 0.00173792 5.53073 0.304488C6.26163 0.607239 6.88636 1.11993 7.32588 1.77772C7.76541 2.43552 8 3.20887 8 4C7.99843 5.06038 7.5765 6.07689 6.8267 6.8267C6.07689 7.5765 5.06038 7.99843 4 8Z"
      fill={fill}
    />
    <rect x="3.25" y="1" width="1.5" height="3.5" rx="0.75" fill={stroke} />
    <rect x="3.25" y="5.5" width="1.5" height="1.5" rx="0.75" fill={stroke} />
  </svg>
);
