import { PublishModule } from '@modules';

import { PublishStates } from './PublishStates';
import { PublishParams } from './Publish.types';
import { ModulesListItem } from './ModulesList';

/**
 PublishUtils component.
 */

export const groupBy = (array: PublishModule[], predicate: (v: PublishModule) => string) =>
  array.reduce((acc: Record<string, PublishModule[]>, value: PublishModule) => {
    const key = predicate(value);

    (acc[key] ||= []).push(value);

    return acc;
  }, {});

export const getGroupedModulesList = (modules: PublishModule[]): ModulesListItem[] => {
  const modulesToPublish = groupBy(modules, (module) => module.experience.name);

  return Object.entries(modulesToPublish)
    .map(([key, value]) => ({
      experiencePosition: value[0].experience.position?.index || -1,
      experienceName: value.length > 1 ? key : value[0].moduleTemplateName,
      modules: value.map((module: PublishModule) => ({
        id: module.id,
        name: module.name,
        contentStatus: module.contentStatus,
        moduleTemplateName: module.moduleTemplateName,
      })),
    }))
    .sort((a, b) => (a.experiencePosition < b.experiencePosition ? -1 : 1));
};

const getPublishState = ({ publishing, publishResults, otherTemplatePublished, key }: PublishParams) => {
  if (!publishing && !publishResults?.publishSummary[key]) {
    return 'notPublished';
  }

  if (publishing || publishResults?.publishSummary[key] === 'ongoing') {
    return 'publishing';
  }

  if (!publishing && !otherTemplatePublished && publishResults?.publishSummary[key] === 'success') {
    return 'published';
  }
  if (!publishing && !otherTemplatePublished && publishResults?.publishSummary[key] === 'error') {
    return 'error';
  }

  if (!publishing && otherTemplatePublished) {
    return 'needsUpdate';
  }
};

export const getPublishMessage = (params: PublishParams) => {
  const publishState = getPublishState(params);

  if (publishState) {
    return <PublishStates state={publishState} />;
  }
};
