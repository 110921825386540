import { Icon, Select } from '@components';
import { content } from '@content';
import { Box, Button, InputBase, Menu, MenuItem, debounce } from '@material-ui/core';
import { SignalsSearchItem, attributeLibrary } from '@modules';
import { HTMLElementWithDocument, TreatmentBuilderContext } from '@routes';
import { Attribute } from '@routes/SignalBuilder/Attribute';
import { SEARCH_ATTRIBUTE_DELAY } from '@routes/SignalBuilder/DataAttributeLibrary/DataAttributeLibrary.const';
import { useAppDispatch } from '@store';
import { format, useHistory } from '@utils';
import React, { ChangeEvent, MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FORMAT_OPTIONS, PROFILE_ATTRIBUTES } from './AddSignalDropdown.const';
import { getChosenElem, rewriteChosenElementContent } from './AddSignalDropdown.helpers';
import { TPersinalizationToken } from './AddSignalDropdown.props';
import { useStyles } from './AddSignalDropdown.styles';

const AddSignalDropdown = () => {
  const dispatch = useAppDispatch();
  const { businessUnit } = useHistory().query;
  const styles = useStyles();
  const { items: attributes } = attributeLibrary.useListData();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSignals, setSelectedSignals] = useState<TPersinalizationToken[]>([]);
  const filteredAttributes = useMemo(
    () =>
      PROFILE_ATTRIBUTES.concat(attributes).filter(
        (attribute) =>
          !selectedSignals.map((signal) => signal.label).includes(attribute.label) &&
          ['textual', 'numeric'].includes(attribute.type),
      ),
    [PROFILE_ATTRIBUTES, attributes, selectedSignals],
  );

  const { chosenModuleElemId, cursorPosition, setCursorPosition, setIsModuleChanged } =
    useContext(TreatmentBuilderContext);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const runSearch = debounce(
    useCallback(
      (keyword: string) => {
        dispatch(
          attributeLibrary.thunk.search({
            businessUnit: businessUnit!,
            keyword,
          }),
        );
      },
      [dispatch, businessUnit],
    ),
    SEARCH_ATTRIBUTE_DELAY,
  );

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      runSearch(event.target.value);
    },
    [runSearch],
  );

  const handleSignalClick = (signal: SignalsSearchItem) => {
    const chosenElem = getChosenElem();
    const signalBody = ` <personefy-token data-attribute-name="${signal.name}" initialValue="${signal.label}" style="border-radius: 3px; color: #000; background-color: #d8b5ff; pointer-events:none; border-radius: 7px; padding: 0 10px;">${signal.label}</personefy-token> `;

    if (chosenElem) {
      setSelectedSignals((prev) => [
        { label: signal.label, name: signal.name, isNew: true, isDropdownShown: false },
        ...prev,
      ]);
      setTimeout(() => {
        setSelectedSignals((prev) =>
          prev.map((item) => ({
            ...item,
            isDropdownShown: true,
          })),
        );
      });
      rewriteChosenElementContent(chosenElem, cursorPosition, signalBody, chosenModuleElemId);

      const tokens = chosenElem.querySelectorAll('personefy-token');
      Array.from(tokens).forEach((token: any) => {
        token.addEventListener(
          'DOMSubtreeModified',
          (event: any) => {
            if (event.target.parentElement && event.target.parentElement.getAttribute('initialValue')) {
              event.target.parentElement.innerHTML = event.target.parentElement.getAttribute('initialValue');
            }
          },
          false,
        );
      });

      setTimeout(() => {
        chosenElem.focus();
      });

      handleClose();
      setIsModuleChanged(true);
    }
  };

  const handleRemoveSignal = (signal: TPersinalizationToken) => () => {
    const chosenElem = getChosenElem();
    const simpleRegEx = new RegExp(`<personefy-token [A-Za-z]+.*?>${signal.label}</personefy-token>`, 'g');
    let simpleMatch = null as RegExpMatchArray | null;
    const formatRegEx = new RegExp(
      `<personefy-token [A-Za-z]+.*?>${signal.label} [|] ${signal.format}</personefy-token>`,
      'g',
    );
    let formatMatch = null as RegExpMatchArray | null;
    const curPotentialTokens = chosenElem?.innerHTML
      .split('</personefy-token>')
      .filter((elem) => elem)
      .map((elem) => `${elem}</personefy-token>`);

    curPotentialTokens.forEach((potentialTokens) => {
      if (!simpleMatch) {
        simpleMatch = potentialTokens.match(simpleRegEx);
      }

      if (!formatMatch) {
        formatMatch = potentialTokens.match(formatRegEx);
      }
    });

    let currentPosition;

    if (chosenElem) {
      if (simpleMatch?.length) {
        // checking for spaces between token
        if (chosenElem.innerHTML.indexOf(` ${simpleMatch[0]} `) != -1) {
          currentPosition = chosenElem.innerHTML.indexOf(` ${simpleMatch[0]} `);
          chosenElem.innerHTML = chosenElem.innerHTML.replace(` ${simpleMatch[0]} `, '');
        } else {
          currentPosition = chosenElem.innerHTML.indexOf(simpleMatch[0]);
          chosenElem.innerHTML = chosenElem.innerHTML.replace(simpleMatch[0], '');
        }
      } else if (formatMatch?.length) {
        // checking for spaces between token
        if (chosenElem.innerHTML.indexOf(` ${formatMatch.join('|')} `) !== -1) {
          currentPosition = chosenElem.innerHTML.indexOf(` ${formatMatch.join('|')} `);
          chosenElem.innerHTML = chosenElem.innerHTML.replace(` ${formatMatch.join('|')} `, '');
        } else {
          currentPosition = chosenElem.innerHTML.indexOf(formatMatch.join('|'));
          chosenElem.innerHTML = chosenElem.innerHTML.replace(formatMatch.join('|'), '');
        }
      }

      chosenElem.dispatchEvent(new Event('input'));

      if (!signal.isNew) {
        setSelectedSignals((prev) => prev.filter((item) => item.label !== signal.label));
      }

      setTimeout(() => {
        chosenElem.focus();
      });
    }

    return currentPosition;
  };

  const handleFormatChange = (signal: TPersinalizationToken) => (e: any) => {
    const chosenElem = getChosenElem();
    const signals = [...selectedSignals];
    const curSignal = signals.find((selectedSignal) => selectedSignal.label === signal.label);
    const signalBody =
      e.target.value !== 'NO_DATA'
        ? ` <personefy-token data-attribute-name="${signal.name}" initialValue="${signal.label} | ${e.target.value}" style="border-radius: 3px; color: #000; background-color: #d8b5ff; pointer-events:none; border-radius: 7px; padding: 0 10px;">${signal.label} | ${e.target.value}</personefy-token> `
        : ` <personefy-token data-attribute-name="${signal.name}" initialValue="${signal.label} | ${e.target.value}" style="border-radius: 3px; color: #000; background-color: #d8b5ff; pointer-events:none; border-radius: 7px; padding: 0 10px;">${signal.label}</personefy-token> `;

    if (chosenElem && curSignal) {
      const currentPosition = handleRemoveSignal(signal)() ?? cursorPosition;

      curSignal.format = e.target.value;
      curSignal.isNew = false;
      curSignal.isDropdownShown = false;

      setSelectedSignals(signals);
      setTimeout(() => {
        setSelectedSignals((prev) =>
          prev.map((item) => ({
            ...item,
            isDropdownShown: true,
          })),
        );
      });

      rewriteChosenElementContent(chosenElem, currentPosition, signalBody, chosenModuleElemId);

      const tokens = chosenElem.querySelectorAll('personefy-token');
      Array.from(tokens).forEach((token: any) => {
        token.addEventListener(
          'DOMSubtreeModified',
          (event: any) => {
            if (event.target.parentElement && event.target.parentElement.getAttribute('initialValue')) {
              event.target.parentElement.innerHTML = event.target.parentElement.getAttribute('initialValue');
            }
          },
          false,
        );
      });

      setIsModuleChanged(true);
      setCursorPosition(chosenElem.innerHTML.length);

      setTimeout(() => {
        chosenElem.focus();
      });
    }
  };

  useEffect(() => {
    runSearch('');

    (function () {
      (window as any).currentMouseX = 0;
      (window as any).currentMouseY = 0;

      window.addEventListener(
        'mousemove',
        function (e) {
          (window as any).currentMouseX = e.pageX;
          (window as any).currentMouseY = e.pageY;
        },
        true,
      );
    })();

    return () => {
      window.removeEventListener('mousemove', () => {});
    };
  }, []);

  useEffect(() => {
    const moduleElem = document?.getElementById('module-modal') as HTMLElementWithDocument;
    const chosenElem = moduleElem?.contentDocument.getElementById(chosenModuleElemId);
    const regEx = /<personefy-token data-attribute-name="([A-Za-z]+.*)" [A-Za-z]+.*>([A-Za-z]+.*)<\/personefy-token>/g;
    let res;
    const curSignals: any = [];
    const potentialTokens = chosenElem?.innerHTML
      .split('</personefy-token>')
      .filter((elem) => elem)
      .map((elem) => `${elem}</personefy-token>`);

    potentialTokens?.forEach((potentialToken) => {
      do {
        res = regEx.exec(potentialToken);

        if (res) {
          const parts = (res.length === 3 ? res[2] : res[1]).split(' | ');
          curSignals.push({ name: res.length === 3 ? res[1] : '', label: parts[0], format: parts[1] });
        }
      } while (res);
    });

    setSelectedSignals(
      curSignals.map(
        (signalLabel: any) =>
          ({
            label: signalLabel.label,
            format: signalLabel.format ?? 'NO_DATA',
            name: signalLabel.name,
            isNew: false,
            isDropdownShown: true,
          } as TPersinalizationToken),
      ),
    );
  }, [chosenModuleElemId]);

  return (
    <Box className={styles.addSignalDropdown}>
      <button
        id="add-signal-btn"
        className={`${styles.addButton} ${anchorEl ? 'active' : ''}`}
        data-opened={Boolean(anchorEl)}
        onClick={handleClick}
      >
        <span>{content.addPersonalization}</span>
        <Icon.ArrowDown />
      </button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: styles.menuList,
        }}
      >
        <InputBase
          className={styles.search}
          placeholder={content.search}
          onChange={handleSearchChange}
          endAdornment={<Icon.SearchOutline />}
        />
        {filteredAttributes.map((attribute, index) => (
          <Attribute
            key={`attribute-${index}`}
            className={''}
            item={attribute}
            dateOnly={true}
            isCloudShown={attribute.signalType === 'foreign'}
            isClicable={true}
            onClick={handleSignalClick}
          />
        ))}
      </Menu>
      <Box className={styles.signalsWrapper}>
        {selectedSignals.map((signal, index) => (
          <React.Fragment key={`selected-signal-${index}`}>
            <Box className={styles.editingItem}>
              <Box className={`${styles.editingSignal} signal-item`}>{signal?.label}</Box>
              {signal.isDropdownShown && (
                <Select
                  className={styles.formatDropdown}
                  placeholder={content.format}
                  value={signal.format}
                  onChange={handleFormatChange(signal)}
                >
                  {FORMAT_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.id as unknown as string}
                      value={option.id as unknown as string}
                      style={{
                        borderBottom: option.hasBorder ? '1px solid #ccc' : 'none',
                        borderRadius: option.hasBorder ? '0' : '0.6rem',
                      }}
                    >
                      {signal.format !== option.id ? (
                        <Box className={styles.formatItem}>
                          <span>{option.type}</span>
                          <span>{option.output}</span>
                        </Box>
                      ) : (
                        option.label
                      )}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <Button className={styles.removeBtn} onClick={handleRemoveSignal({ ...signal, isNew: false })}>
                <Icon.TrashOutline width={'16px'} height={'16px'} />
              </Button>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default AddSignalDropdown;
