import { memo } from 'react';

import { content } from '@content';
import { Typography, Table, TableColumn, Pill } from '@components';
import { format } from '@utils';
import { TableMindsetWithEntity } from '@modules';

import { fillUpCohorts, getRegularColumnsCount, reformatMindset } from './MindsetTable.utils';
import { BADGE_COLORS, PAGE_BOTTOM_GAP, COHORT_CODE_NULLS } from './MindsetTable.const';
import { useStyles } from './MindsetTable.styles';
import { MindsetTableProps } from './MindsetTable.props';

/**
 * MindsetTable component. Returns Mindset Table.
 * @returns {JSX.Element}
 */

export const MindsetTable = memo(
  ({ cohorts, defaultMindset, products, experienceId, useDroppable }: MindsetTableProps): JSX.Element => {
    const filledUpCohorts = fillUpCohorts(cohorts);
    const styles = useStyles({ regularColumnsCount: getRegularColumnsCount(filledUpCohorts) + 1 });

    return (
      <Table
        uiType="secondary"
        measures={{ recordHeight: '', bottomGap: PAGE_BOTTOM_GAP }}
        columns={[
          {
            id: 'keys',
            title: '',
            className: (recId) => (recId ? styles.cell : ''),
            render: (value) => (
              <>
                {(value as string[]).map((key) => (
                  <Typography.Headline key={key} className={styles.item} data-key>
                    {key}
                  </Typography.Headline>
                ))}
              </>
            ),
          },
          ...filledUpCohorts.map(
            (cohort) =>
              ({
                id: `${cohort.code}`,
                title: (
                  <Typography.Body className={styles.columnTitle}>
                    {cohort.code !== COHORT_CODE_NULLS && cohort.name.toUpperCase()}
                  </Typography.Body>
                ),
                align: 'right',
                className: (recId) => {
                  if (cohort.code === COHORT_CODE_NULLS) {
                    return styles.fullWidthCell;
                  }
                  return recId ? styles.cell : '';
                },
                render: (value) => {
                  const mods = (value || []) as (TableMindsetWithEntity | null)[];

                  return (
                    <>
                      {mods.map((mod, idx) =>
                        mod ? (
                          <Pill
                            className={styles.item}
                            entity={mod.entity}
                            value={format.number(mod.audienceSize)}
                            percentage={mod.audienceShare?.toFixed(1)}
                            color={mod.hasContent ? BADGE_COLORS.html : BADGE_COLORS.none}
                            modal={mod.hasContent ? 'has content' : 'no content'}
                            fullWidth={mod.fullWidth}
                            key={mod.id}
                            droppableId={mod.id}
                            useDroppable={useDroppable}
                          />
                        ) : (
                          <Typography.Body className={styles.item} key={`empty-${idx}`}>
                            &nbsp;
                          </Typography.Body>
                        ),
                      )}
                    </>
                  );
                },
              } as TableColumn),
          ),
        ]}
        records={[
          {
            id: `${experienceId}`,
            keys: [...products.map((product) => product.name), content.nonTargeted],
            ...cohorts.reduce((kvPairs, cohort) => {
              const mods =
                products.map((product) => {
                  const mindset = product.mindsets[cohort.code];
                  if (mindset) {
                    return reformatMindset(mindset, false);
                  } else {
                    return undefined;
                  }
                }) || [];

              let nullMindset = null;
              if (cohort.code === COHORT_CODE_NULLS && defaultMindset) {
                nullMindset = reformatMindset(defaultMindset, true);
              }

              return {
                ...kvPairs,
                [cohort.code]: [...mods, nullMindset],
              };
            }, {}),
          },
        ]}
      />
    );
  },
);

MindsetTable.displayName = 'MindsetTable';
