import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ImageLibraryContent component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    imageLibraryContentGrid: {
      marginTop: theme.spacing(2),
      display: 'flex',
      maxHeight: '46rem',
      overflow: 'scroll',

      '& > :nth-child(2n)': {
        marginLeft: theme.spacing(1.75),
      },
    },

    imageColumn: {
      flex: '50%',
      maxWidth: '12rem',
      fontSize: 0,
      lineHeight: 0,
      userSelect: 'none',
    },

    imageWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover': {
        backgroundColor: variables.color.primary.white,
        opacity: variables.opacity.high,

        '& > button': {
          display: 'block',
        },
      },
    },

    image: {
      marginBottom: theme.spacing(2),

      '&:hover': {
        cursor: 'pointer',
      },
    },

    gridMenuButton: {
      position: 'absolute',
      right: 'calc(50% - 1.6rem)',
      top: 'calc(50% - 2.4rem)',
      display: 'none',
      opacity: variables.opacity.high,
    },

    imageLibraryContentList: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1.6rem',
      maxHeight: '46rem',
      overflow: 'scroll',

      '& > :last-child': {
        borderBottom: 'none',
      },
    },

    imageLibraryRow: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: '0.8rem 0',
      borderTop: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,

      '&:hover': {
        cursor: 'pointer',
        boxShadow: `0.1rem 0.1rem 0.7rem ${variables.color.primary.lightGrayDarker}`,

        '& > button': {
          display: 'block',
        },
      },
    },

    imageInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(1),
    },

    imageName: {
      marginBottom: theme.spacing(0.625),
      color: variables.color.primary.darkestGray,
    },

    listMenuButton: {
      position: 'absolute',
      right: 0,
      display: 'none',
      opacity: variables.opacity.high,
    },
  }),
);
