import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ImageContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    imageContainer: {
      backgroundColor: variables.color.primary.white,

      '&[data-ui-type=primary]': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),

        '& $title': {
          padding: theme.spacing(2),
        },

        '& $imageSection': {
          marginBottom: theme.spacing(2),
        },

        '& $caption': {
          marginBottom: theme.spacing(1),
        },

        '& $widthSection': {
          display: 'flex',
        },

        '& $width': {
          marginRight: theme.spacing(2),
        },
      },
    },

    imageSrcWrapper: {
      display: 'flex',
    },

    imageSrc: {
      width: '100%',
    },

    selectBtn: {
      marginLeft: theme.spacing(1),
      width: '6.5rem',
      height: '3.2rem',
    },

    closeBtn: {
      width: '6.5rem',
      height: '3.2rem',
      marginBottom: '20px',
    },

    label: {
      marginTop: '0',
      marginBottom: '5px',
    },

    imageSection: {},
    widthSection: {
      display: 'flex',
    },
    width: {
      marginRight: theme.spacing(2),
    },
    titleWrapper: {
      padding: theme.spacing(2),
    },
    title: {
      fontSize: '16px!important',
      lineHeight: '20px!important',
      color: `${variables.color.primary.darkPurple}!important`,
      paddingTop: '9px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    content: {
      padding: '10px 16px 16px',
      flexDirection: 'column',
    },

    DVcontent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginBottom: '15px',
    },

    cancelBtn: {
      marginRight: theme.spacing(1),

      '&.hovered': {
        color: variables.color.primary.mainPurple,
        border: `0.2rem solid ${variables.color.primary.lightPurple}`,
      },
      '&.hovered svg path': {
        stroke: variables.color.primary.mainPurple,
      },
    },

    renameBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '7px 10px 10px 16px',
    },

    renameBarButtons: {
      display: 'flex',
    },

    save: {
      marginTop: '20px',
      marginRight: 'auto',
    },

    caption: {
      color: variables.color.primary.darkestGray,
    },
  }),
);
