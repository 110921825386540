import { ChangeEvent, MouseEvent, memo, useCallback } from 'react';
import { Box, InputBase } from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import { Droppable } from 'react-beautiful-dnd';
import debounce from 'lodash.debounce';

import { Panel, Icon, Typography } from '@components';
import { content } from '@content';
import { useAppDispatch } from '@store';
import { signalLibrary } from '@modules';
import { func, searchQuery, useLoader } from '@utils';

import { Signal } from '../Signal';

import { SignalLibraryRow } from './SignalLibraryRow';
import { SignalLibraryProps } from './SignalLibrary.props';
import { useStyles } from './SignalLibrary.styles';
import { RUN_SEARCH_DELAY } from './SignalLibrary.const';

/**
 SignalLibrary component
 @returns {JSX.Element}
 */

export const SignalLibrary = memo(
  ({ className = '', searchClassName = '', height = 460, onEdit = func.nop }: SignalLibraryProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const styles = useStyles();

    const [businessUnit] = searchQuery.useMutualParams('businessUnit');

    const meta = signalLibrary.useListMeta();

    const signals = signalLibrary.useListData();

    const runSearch = debounce(
      useCallback(
        (keyword: string) =>
          dispatch(
            signalLibrary.thunk.search({
              businessUnit,
              keyword,
            }),
          ),
        [dispatch, businessUnit],
      ),
      RUN_SEARCH_DELAY,
    );

    const handleSearchChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        runSearch(event.target.value);
      },
      [runSearch],
    );

    const handleEditClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        const { id } = event.currentTarget.dataset;

        if (id) {
          onEdit(+id);
        }
      },
      [onEdit],
    );

    useLoader(meta);

    return (
      <Panel className={`SignalLibrary ${styles.panel} ${className}`}>
        <InputBase
          className={`${styles.searchField} ${searchClassName}`}
          placeholder={content.search}
          onChange={handleSearchChange}
          endAdornment={<Icon.SearchOutline />}
        />
        <Box>
          {signals.items.length === 0 && <Typography.Body align="center">{content.noItemsFound}</Typography.Body>}
          <Droppable
            droppableId="signalLibrary"
            type="SIGNAL"
            mode="virtual"
            isDropDisabled
            renderClone={(provided, snapshot, rubric) => (
              <Signal
                className={styles.dataAttributeSignal}
                provided={provided}
                item={signals.items[rubric.source.index]}
                isDragging={snapshot.isDragging}
              />
            )}
          >
            {(provided) => (
              <FixedSizeList
                height={height}
                width="100%"
                itemCount={signals.items.length}
                itemSize={43}
                outerRef={provided.innerRef}
                itemData={signals.items.map((item) => ({ ...item, handleEditClick }))}
              >
                {SignalLibraryRow}
              </FixedSizeList>
            )}
          </Droppable>
        </Box>
      </Panel>
    );
  },
);

SignalLibrary.displayName = 'SignalLibrary';
