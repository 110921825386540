import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    modulePerformanceByPosition: {
      padding: `${variables.gap.xl} ${variables.gap.sm}`,
    },
    commonCtirContainer: {
      width: '100%',
    },
    row: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'flex',
      padding: `0 ${variables.gap.md} 0 4.5rem`,
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      display: 'inline',
      fontSize: variables.font.size.xxxl,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.xxxl,
      letterSpacing: variables.letterSpacing.sm,
      color: variables.color.primary.gray,
      paddingLeft: '2.4rem',
    },
    accordion: {
      background: 'transparent',
      boxShadow: 'none',
    },
    accordionSummaryContainer: {
      padding: 0,
    },
    accordionSummaryContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '2.5rem 0',
    },
    listItemRightContent: {
      width: '40%',
      marginLeft: '4.4rem',
    },
    arrowDownIcon: {
      stroke: variables.color.primary.mainPurple,
      width: 20,
      height: 20,
    },
    radioRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    radioLabel: {
      color: variables.color.primary.gray,
      marginBottom: '1.2rem',
      marginRight: '1.2rem',
    },
    radioControl: {
      marginBottom: '1.1rem',
    },
    chartsContainer: {
      display: 'flex',
    },
  }),
);
