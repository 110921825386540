/**
 * ImageLibraryContent constants
 */

export enum ImageLibraryLayout {
  GRID = 'grid',
  LIST = 'list',
}

export const imageLibraryMenuOptions = [
  {
    id: 'View Details',
    label: 'View Details',
    active: false,
  },
  {
    id: 'Delete',
    label: 'Delete',
    active: false,
  },
];
