import { SVGProps } from 'react';

export const ResultsOutline = ({
  width = 24,
  height = 24,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <line x1="5.75" y1="18.25" x2="18.25" y2="18.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M8.75 11C8.75 10.5858 8.41421 10.25 8 10.25C7.58579 10.25 7.25 10.5858 7.25 11L8.75 11ZM8.75 16L8.75 11L7.25 11L7.25 16L8.75 16Z"
      fill={color}
    />
    <path
      d="M12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8L12.75 8ZM12.75 16L12.75 8L11.25 8L11.25 16L12.75 16Z"
      fill={color}
    />
    <path
      d="M16.75 5C16.75 4.58579 16.4142 4.25 16 4.25C15.5858 4.25 15.25 4.58579 15.25 5L16.75 5ZM16.75 16L16.75 5L15.25 5L15.25 16L16.75 16Z"
      fill={color}
    />
  </svg>
);
