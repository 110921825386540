import { Dispatch, RefObject, SetStateAction } from 'react';

import { content } from '@content';
import { MindsetData } from '@modules';
import { variables } from '@styles';
import { format, parse } from '@utils';

import {
  DATA_DISCLOSURE_ATTRIBUTE,
  DATA_EXPERIENCE_ID,
  DATA_FILLED_ATTRIBUTE,
  DATA_SPLITTER_ATTRIBUTE,
  DEFAULT_WRAPPED_ELEM_STYLES,
  ELEM_INFO_SVG,
  SPLITTER_ACTIVE_CLASSNAME,
  SPLITTER_DATASET_DISCLOSURE,
  SPLITTER_DATASET_EXPERIENCE_ID_KEY,
  SPLITTER_DATASET_ID_KEY,
  TRASH_SVG,
} from './SampleTemplate.const';
import {
  CurrentTarget,
  DisclosureTitle,
  EmailTemplateSplitter,
  SplitterInfos,
  WrapperTagNames,
  HTMLElementWithDocument,
  HTMLWrappedElement,
  Rows,
  ModuleTemplateType,
} from './SampleTemplate.types';
import { delay } from '@utils/func/func';

/**
 * SampleTemplate component utils
 */

// getting the last splitter key in iframe
export const getLastSplitterKey = (iframe: RefObject<HTMLIFrameElement>) => {
  const frameSplittersArray = iframe.current?.contentWindow?.document.querySelectorAll(`[${DATA_SPLITTER_ATTRIBUTE}]`);
  return frameSplittersArray?.[frameSplittersArray.length - 1].getAttribute(DATA_SPLITTER_ATTRIBUTE);
};

// getting disclosure splitter
export const getDisclosureSplitter = (iframe: RefObject<HTMLIFrameElement>) => {
  return iframe.current?.contentWindow?.document.querySelector(`[${DATA_DISCLOSURE_ATTRIBUTE}]`);
};

export const handleSplitterDragOver = (e: DragEvent) => {
  e.preventDefault();
};

// functions for dragging of the Filled splitter
export const handleSplitterDragStart = (event: DragEvent) => {
  const target = event.target as EmailTemplateSplitter;
  const splitterId = target.dataset[SPLITTER_DATASET_ID_KEY] as string;

  if (target.dataset[SPLITTER_DATASET_ID_KEY] && !target.dataset[SPLITTER_DATASET_DISCLOSURE]) {
    const experienceId = parse.integer(target.dataset[SPLITTER_DATASET_EXPERIENCE_ID_KEY] || '');
    const { cursor, modules } = target.experienceData;

    event.dataTransfer?.setData(
      'application/json',
      JSON.stringify({
        cursor,
        experienceId,
        modules,
        sourceSplitterId: splitterId,
      }),
    );
    event.dataTransfer?.setData(`${splitterId}`, '');
  } else if (target.dataset[SPLITTER_DATASET_DISCLOSURE]) {
    event.dataTransfer?.setData(
      'application/json',
      JSON.stringify({
        disclosureHtml: target.innerHTML,
        sourceSplitterId: splitterId,
      }),
    );
    event.dataTransfer?.setData(`${splitterId}`, '');
  }
};

export const handleSplitterDragEnter = (event: DragEvent) => {
  const sourceSplitterId = event.dataTransfer?.types[1];
  const target = event.target as HTMLElement;

  if (target.dataset[SPLITTER_DATASET_ID_KEY] !== sourceSplitterId) {
    target.classList.add(SPLITTER_ACTIVE_CLASSNAME);
  }
};

export const handleSplitterDragLeave = (event: DragEvent) => {
  const target = event.target as HTMLElement;

  if (target.dataset[SPLITTER_DATASET_ID_KEY]) {
    target.classList.remove(SPLITTER_ACTIVE_CLASSNAME);
  }
};

export const handleSidebarDragStart = (event: DragEvent) => {
  const target = event.target as HTMLElement;
  target?.classList.add('dragging');

  const handleChangeDragClassName = (() => () => {
    target?.classList.remove('dragging');
    target?.classList.add('dragging-source');
  })();

  setTimeout(handleChangeDragClassName, 1);
};

export const handleSidebarDragEnd = (event: DragEvent) => {
  const target = event.target as HTMLElement;
  target?.classList.remove('dragging');
  target?.classList.remove('dragging-source');
};

const getLastStyles = (styles: string[][]) =>
  styles.reduce((accum, current) => {
    if (current[0] && current[1]) {
      accum[current[0]] = current[1];
    }

    return accum;
  }, {} as { [key: string]: string });

const getSplitedBackgrImageStyles = (backgroundImageStyles: string[]) =>
  backgroundImageStyles.map((item) => {
    const splitedStyles = item.trim().split(':');
    const styleName = splitedStyles[0];
    const httpStr = splitedStyles[1];
    const urlStr = splitedStyles[2];

    if (splitedStyles.length === 2) {
      return splitedStyles;
    }

    return [styleName, [httpStr, urlStr].join(':')];
  });

export const getUniqueStyles = (textContent: string) => {
  const uniqueStyles = Array.from(new Set(textContent?.split(';')));
  const filteredUniqueStyles = uniqueStyles.filter((item) => !item.includes('background-image:'));
  const backgroundImageStyles = uniqueStyles.filter((item) => item.includes('background-image:'));
  const splitedUniqueStyles = filteredUniqueStyles.map((item) => item.trim().split(':'));
  const splitedBackgrImageStyles = getSplitedBackgrImageStyles(backgroundImageStyles);
  const lastStyles = getLastStyles([...splitedUniqueStyles, ...splitedBackgrImageStyles]);
  const lastStylesString = Object.entries(lastStyles)
    .map((item) => item.join(':'))
    .join(';\n');

  return `${lastStylesString};`;
};

export const fixFrameScale = (frame: HTMLIFrameElement | null): number => {
  let scale = 1;

  const win = frame?.contentWindow;

  if (win) {
    const { documentElement: doc } = win.document;
    const { innerWidth: winWidth } = win;

    if (doc) {
      const { scrollWidth: docWidth, style: docStyle } = doc;
      if (docWidth > winWidth) {
        scale = winWidth / docWidth;

        docStyle.transformOrigin = 'left top';
        docStyle.transform = `scale(${scale})`;
      }
    }
  }

  return scale;
};

export const initializeElemStyleId = (contentDocument: Document, tagName: WrapperTagNames) => {
  const elems = contentDocument.querySelectorAll(tagName);

  Array.from(elems).forEach((elem, index) => {
    const currentTargetAttributes = elem?.attributes as HTMLWrappedElement;
    const textContent = currentTargetAttributes.style?.textContent;
    const styleItems = getUniqueStyles(textContent);

    elem.setAttribute('id', `${tagName}-${index}`);
    elem.setAttribute('style', getUniqueStyles(`${styleItems} \n${DEFAULT_WRAPPED_ELEM_STYLES}`));
    elem.setAttribute('originalInnerHTML', elem.innerHTML.trim());
  });
};

export const initializeLinkStyleId = (contentDocument: Document) => {
  const links = contentDocument.querySelectorAll('a');
  let buttonCount = 0;

  Array.from(links).forEach((link, index) => {
    const linkAttributes = link?.attributes as HTMLWrappedElement;
    const textContent = linkAttributes.style?.textContent;
    const styleItems = getUniqueStyles(textContent);
    const hasBackgroundColor = Array.from(new Set(textContent?.split(';'))).some((item) =>
      item.includes('background-color'),
    );

    if (hasBackgroundColor) {
      link.setAttribute('id', `button-${index}`);
      link.setAttribute('style', getUniqueStyles(`${styleItems} \n${DEFAULT_WRAPPED_ELEM_STYLES}`));
      buttonCount += 1;
    } else {
      link.setAttribute('id', `a-${index - buttonCount}`);
      link.setAttribute('style', getUniqueStyles(`${styleItems} \n${DEFAULT_WRAPPED_ELEM_STYLES}`));
    }
  });
};

export const initializeDivStyleId = (contentDocument: Document) => {
  const textDivs = Array.from(contentDocument.querySelectorAll('div'));

  textDivs.forEach((textDiv, index) => {
    const linkAttributes = textDiv?.attributes as HTMLWrappedElement;
    const defaultStyles = linkAttributes.style?.textContent;
    const styles = getUniqueStyles(
      `${defaultStyles} cursor: pointer; \nborder: 2px solid transparent; \nborder-radius: 2px;`,
    );

    textDiv.setAttribute('id', `div-${index}`);
    textDiv.setAttribute('style', styles);
    textDiv.setAttribute('draggable', 'true');
  });
};

export const initializeJourneyStyleId = (
  contentDocument: Document,
  positions: MindsetData[],
  chosenElemId: string | null,
) => {
  let nextChosenElemId: typeof chosenElemId = null;

  const divs = contentDocument.querySelectorAll('div');
  const moduleDivs = Array.from(divs).filter((div) => div.getAttribute(DATA_FILLED_ATTRIBUTE));

  moduleDivs.forEach((div, index) => {
    const experienceId = Number(div.getAttribute(DATA_FILLED_ATTRIBUTE));

    if (experienceId) {
      const experienceData = positions.find((item) => item.experienceId === experienceId);
      const modulesCount = (experienceData?.modules || []).length;

      if (modulesCount) {
        const type: ModuleTemplateType = modulesCount === 1 ? 'single' : 'group';
        const divId = `journey-${type}-div-${index}`;

        if (chosenElemId && div.getAttribute('id') === chosenElemId) {
          nextChosenElemId = divId;
        } else {
          div.setAttribute(
            'style',
            `cursor: pointer; \nborder: ${variables.borderWidth.lg} solid transparent; \nborder-radius: ${variables.borderRadius.xxs}; \nposition: relative;`,
          );
        }

        div.setAttribute('id', divId);
        div.setAttribute('draggable', 'true');
      }
    }
  });

  return nextChosenElemId;
};

export const removePrevElemInfo = (chosenElemId: string, contentDocument: Document) => {
  const splitedChosenElemId = chosenElemId.split('-');

  if (splitedChosenElemId.length === 2) {
    const prevElemTagName = splitedChosenElemId[0];
    const prevElemIndex = splitedChosenElemId[1];
    const prevElemInfo = contentDocument.getElementById(`elem-info-${prevElemTagName}-${prevElemIndex}`);
    const prevTrashCan = contentDocument.getElementById(`trash-can-${prevElemTagName}-${prevElemIndex}`);

    if (prevElemInfo) prevElemInfo.remove();
    if (prevTrashCan) prevTrashCan.remove();
  }
  if (splitedChosenElemId.length === 4) {
    const prevElemTagName = `${splitedChosenElemId[0]}-${splitedChosenElemId[1]}-${splitedChosenElemId[2]}`;
    const prevElemIndex = splitedChosenElemId[3];
    const prevElemInfo = contentDocument.getElementById(`elem-info-${prevElemTagName}-${prevElemIndex}`);
    const prevTrashCan = contentDocument.getElementById(`trash-can-${prevElemTagName}-${prevElemIndex}`);

    if (prevElemInfo) prevElemInfo.remove();
    if (prevTrashCan) prevTrashCan.remove();
  }
};

export const convertElemInfoTagName = (elemId: string, currentTarget: CurrentTarget) => {
  let tagName = currentTarget?.tagName;

  if (elemId.includes('button')) {
    tagName = content.button;
  }

  if (elemId.includes('journey-single-div')) {
    tagName = content.singleModule;
  }

  if (elemId.includes('journey-group-div')) {
    tagName = content.moduleGroup;
  }

  switch (tagName) {
    case 'DIV':
      return content.div;
    case 'SPAN':
    case 'P':
    case 'H1':
    case 'STRONG':
    case 'A':
      return content.text;
    default:
      return format.capitalize(tagName);
  }
};

// Function for making splitters order array
export const getSplittersOrderArray = (splittersArray: EmailTemplateSplitter[]): SplitterInfos =>
  splittersArray.map((filledSplitter, index) => ({
    splitterId: filledSplitter.getAttribute(DATA_SPLITTER_ATTRIBUTE),
    experienceId: filledSplitter.getAttribute(DATA_FILLED_ATTRIBUTE),
    order: index + 1,
  }));

// Function for making splitters positions array
export const getSplittersPositionsArray = (splittersArray: EmailTemplateSplitter[]): SplitterInfos =>
  splittersArray
    .map((splitter, index) => ({
      splitterId: splitter.getAttribute(DATA_SPLITTER_ATTRIBUTE),
      experienceId: splitter.getAttribute(DATA_FILLED_ATTRIBUTE),
      order: index + 1,
    }))
    .filter((splitter) => splitter.experienceId);

// Function for sorting disclosures html sections
export const sortDisclosures = (infos: SplitterInfos, arrayToSort: HTMLElement[]) =>
  arrayToSort.sort((a, b) => {
    const expreienceIdA = a.getAttribute(DATA_EXPERIENCE_ID);
    const experienceIdB = b.getAttribute(DATA_EXPERIENCE_ID);
    const orderA = infos.find((info) => info.experienceId === expreienceIdA)?.order;
    const orderB = infos.find((info) => info.experienceId === experienceIdB)?.order;

    if (orderA && orderB && orderA !== orderB) {
      return orderA > orderB ? 1 : -1;
    }

    return 0;
  });

// Function for sorting disclosures titles
export const sortDisclosuresTitles = (infos: SplitterInfos, arrayToSort: DisclosureTitle[]) =>
  arrayToSort.sort((a, b) => {
    const expreienceIdA = a.experienceId.toString();
    const experienceIdB = b.experienceId.toString();
    const orderA = infos.find((info) => info.experienceId === expreienceIdA)?.order;
    const orderB = infos.find((info) => info.experienceId === experienceIdB)?.order;

    if (orderA && orderB && orderA !== orderB) {
      return orderA > orderB ? 1 : -1;
    }

    return 0;
  });

export const emptySplitter = (splitter: EmailTemplateSplitter) => {
  [DATA_FILLED_ATTRIBUTE, 'draggable', 'class', 'id', 'style'].forEach((attr) => splitter.removeAttribute(attr));
  splitter.innerHTML = '';
  splitter.experienceData = {
    cursor: 0,
    modules: [],
  };
  splitter.disclosuresData = [];
};

export const addElemInfo = (target: any, elemId: string, documentElemId: string) => {
  //setting elemInfo
  const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
  const elemInfo = contentDocument.createElement('div');
  const curElem = contentDocument.getElementById(elemId);

  elemInfo.setAttribute('id', `elem-info-${elemId}`);
  elemInfo.setAttribute('style', 'position: absolute; z-index: 1;');
  elemInfo.innerHTML = `
    <div
      id="elem-info-${elemId}__content"
      style="max-height: 18px; height: 18px; font-family: 'Manrope', sans-serif; padding: 1px 6px; border-radius: 0 0 2px 2px; display: flex; align-items: center; color: ${
        variables.color.primary.white
      }; background-color: ${variables.color.primary.lightPurple};margin-top: -2px;"
    >
      <span style="margin-right: 4px; font-size: 12px;">
        ${convertElemInfoTagName(elemId, target)}
      </span>
      ${ELEM_INFO_SVG}
    </div>
  `;

  curElem?.after(elemInfo);
};

export const addTrashCan = (target: any, elemId: string, documentElemId: string, curElemWidth: number) => {
  const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
  const trashCan = contentDocument.createElement('div');

  trashCan.setAttribute('id', `trash-can-${elemId}`);
  trashCan.setAttribute('style', `position: absolute; z-index: 1; margin-left: ${curElemWidth}px; cursor: pointer;`);
  trashCan.innerHTML = `
      <div
        id="trash-can-${elemId}__content"
        style="font-family: 'Manrope', sans-serif; padding: 3px 5px; border-radius: 0 2px 2px 0; display: flex; align-items: center; color: ${variables.color.primary.white}; background-color: ${variables.color.primary.lightPurple};"
      >
        ${TRASH_SVG}
      </div>
    `;

  target?.before(trashCan);
};

export const elemMouseEnter = (e: MouseEvent, elemId: string, documentElemId: string, isTemplateView: boolean) => {
  //redefine elem styles
  e.stopPropagation();
  e.preventDefault();
  const target = e.currentTarget as any;
  const currentTargetAttributes = target?.attributes;

  if (!currentTargetAttributes) {
    return;
  }
  const textContent = currentTargetAttributes.style?.textContent;
  const prevStyleItems = getUniqueStyles(textContent);

  if (target.style.borderColor !== 'rgb(107, 17, 201)') {
    target?.setAttribute('style', `${prevStyleItems} \nborder-color: ${variables.color.primary.lightPurple};`);
  }
  target?.setAttribute('hovered', 'true');
  target?.setAttribute('draggable', 'true');

  if (isTemplateView) {
    addElemInfo(target, elemId, documentElemId);
  }

  const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
  const curElem = contentDocument.getElementById(elemId);
  const curElemWidth = (curElem?.getBoundingClientRect().width ?? 1) - 1;

  if (isTemplateView) {
    //setting elemInfo
    addTrashCan(target, elemId, documentElemId, curElemWidth);
  }
};

export const elemMouseLeave = (e: MouseEvent) => {
  //redefine elem styles
  if (e) {
    e.stopPropagation();
  }
  const target = e?.target as any;
  const currentTargetAttributes = target?.attributes;
  const textContent = currentTargetAttributes?.style?.textContent;
  const prevStyleItems = getUniqueStyles(textContent);

  if (target?.style.borderColor !== 'rgb(107, 17, 201)') {
    target?.setAttribute('style', `${prevStyleItems} \nborder-color: transparent;`);
  }

  target?.removeAttribute('hovered');
};

export const resetPrevElem = (
  elemId: string,
  documentElemId: string,
  chosenElemId: string,
  setChosenElemId: (value: SetStateAction<string>) => void,
) => {
  //reseting previous element
  const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
  const prevChosenElem = contentDocument.getElementById(chosenElemId);
  const chosenElem = contentDocument.getElementById(elemId);

  setChosenElemId(elemId);

  if (elemId !== chosenElemId) {
    const prevElemStyles = prevChosenElem?.getAttribute('style') || '';
    const splitedChosenElemStyles = prevElemStyles?.split(';').map((item) => item.replace('\n', ''));
    const filteredStyles = `\n${splitedChosenElemStyles.excludeStrs(['border:', 'border-color:']).join(';\n')}`;
    const combinedStyles = getUniqueStyles(`${filteredStyles} \n${DEFAULT_WRAPPED_ELEM_STYLES}`).replaceAll(
      '!important',
      '',
    );

    prevChosenElem?.setAttribute('style', combinedStyles);

    //removing prev elemInfo element
    removePrevElemInfo(chosenElemId, contentDocument);
  }
};

export const restyleElemInfo = async (
  target: any,
  elemId: string,
  documentElemId: string,
  isTemplateView: boolean,
  setShowRemoveExperienceModal?: (value: SetStateAction<boolean>) => void,
) => {
  await delay(50);

  const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
  //restyle elemInfo
  let elemInfoContent = contentDocument.getElementById(`elem-info-${elemId}__content`);

  if (!elemInfoContent && isTemplateView) {
    addElemInfo(target, elemId, documentElemId);

    await delay(50);

    elemInfoContent = contentDocument.getElementById(`elem-info-${elemId}__content`);
  }

  elemInfoContent?.setAttribute(
    'style',
    `max-height: 18px; \nheight: 18px; \nfont-family: 'Manrope', sans-serif; \npadding: 1px 6px; \nborder-radius: 0 0 2px 2px; \ndisplay: flex; \nalign-items: center; \ncolor: ${variables.color.primary.white}; \nbackground-color: ${variables.color.primary.mainPurple};margin-top: -2px;`,
  );

  //restyle trashCanContent
  let trashCanContent = contentDocument.getElementById(`trash-can-${elemId}__content`);

  if (!trashCanContent && setShowRemoveExperienceModal) {
    const curElemWidth = (target?.getBoundingClientRect().width ?? 1) - 1;

    addTrashCan(target, elemId, documentElemId, curElemWidth);

    await delay(50);

    trashCanContent = contentDocument.getElementById(`trash-can-${elemId}__content`);
  }

  if (trashCanContent && setShowRemoveExperienceModal) {
    trashCanContent.setAttribute(
      'style',
      `font-family: 'Manrope', sans-serif; \npadding: 3px 5px; \nborder-radius: 0 2px 2px 0; \ndisplay: flex; \nalign-items: center; \ncolor: ${variables.color.primary.white}; \nbackground-color: ${variables.color.primary.mainPurple}; cursor: pointer;`,
    );

    trashCanContent.onclick = () => {
      setShowRemoveExperienceModal(true);
    };
  }
};

export const elemClick = async (
  e: MouseEvent,
  elemId: string,
  documentElemId: string,
  chosenElemId: string,
  isTemplateView: boolean,
  setChosenElemId: (value: SetStateAction<string>) => void,
  setShowRemoveExperienceModal?: (value: SetStateAction<boolean>) => void,
) => {
  if (e.preventDefault && e.stopPropagation) {
    e.stopPropagation();
    e.preventDefault();
  }

  resetPrevElem(elemId, documentElemId, chosenElemId, setChosenElemId);

  const target = e.target as any;
  const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;

  //setting current element
  const currentTargetAttributes = (e.target as CurrentTarget)?.attributes;
  const textContent = currentTargetAttributes.style.textContent;
  const styleItems = getUniqueStyles(`${textContent} \nborder-color: ${variables.color.primary.mainPurple};`);

  (e.target as CurrentTarget)?.setAttribute('style', styleItems);

  await restyleElemInfo(target, elemId, documentElemId, isTemplateView, setShowRemoveExperienceModal);

  target.style.borderColor = '#6B11C9';
};

export const getTemplateDoc = (): Document | null => {
  const node = window.document.querySelector('#template');

  return node && (node as HTMLElementWithDocument).contentDocument;
};

export const removeElemInfo = (elemName = '', documentElemId = 'template') => {
  const { contentDocument } = document.getElementById(documentElemId) as HTMLElementWithDocument;
  const infos = contentDocument.querySelectorAll(`div[id^='elem-info${elemName}']`);
  const trashCans = contentDocument.querySelectorAll(`div[id^='trash-can${elemName}']`);

  Array.from(infos).forEach((info) => info.remove());
  Array.from(trashCans).forEach((trashCan) => trashCan.remove());
};

export const getNewRowsObject = (prevState: Rows, keyToChange: string, experienceId: number | null) =>
  Object.keys(prevState).reduce(
    (acc, splId) => ({
      ...acc,
      [splId]: {
        ...prevState[splId],
      },
      [keyToChange]: {
        experienceId,
      },
    }),
    {},
  );
