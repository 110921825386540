import { FC, ReactElement, SVGProps } from 'react';

export const Magnifier: FC<SVGProps<SVGElement>> = ({ width = 28, height = 28 }): ReactElement => (
  <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3574 20.227C20.964 18.2692 23.1113 12.9478 21.1536 8.34116C19.1958 3.73456 13.8743 1.58725 9.26775 3.54501C4.66115 5.50277 2.51384 10.8242 4.47159 15.4308C6.42935 20.0374 11.7508 22.1847 16.3574 20.227Z"
      stroke="#8C8F96"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19.2207 19L26.2497 26.03" stroke="#8C8F96" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
