import { Box, Grid } from '@material-ui/core';

import { Icon, Typography } from '@components';
import { DashboardCard } from '@views';
import { content } from '@content';
import { variables } from '@styles';

import { BenchmarkItem } from './BenchmarkItem';

import { useStyles } from './BenchmarkingReport.styles';
import { BenchmarkingReportProps } from './BenchmarkingReport.props';

/**
 * BenchmarkingReport component
 * @returns {JSX.Element}
 */

export const BenchmarkingReport = ({ title, icon, data, hasChevronIcon }: BenchmarkingReportProps): JSX.Element => {
  const styles = useStyles();

  const ReportIcon = Icon[icon];

  return (
    <DashboardCard
      icon={<ReportIcon />}
      title={title}
      subtitle={content.benchmarking}
      className={styles.benchmarkingReport}
      hasChevronIcon={hasChevronIcon}
    >
      <Box className={styles.content}>
        <Grid container>
          <Grid item xs={6} className={styles.leftContainer}>
            <Box className={styles.titleContainer}>
              <Typography.Headline className={styles.title}>{content.allSubscribers}</Typography.Headline>
            </Box>
            <Grid container>
              <Grid item xs={6}>
                <BenchmarkItem
                  title={content.opens}
                  value={data.currentMonthCommonOpenRate}
                  trend={data.openRateTrend}
                />
              </Grid>
              <Grid item xs={6}>
                <BenchmarkItem title={content.clicks} value={data.currentMonthClickRate} trend={data.clickRateTrend} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={styles.rightContainer}>
            <Box className={styles.titleContainer}>
              <Typography.Headline className={styles.title}>
                {content.withoutAppleMailMPPSubscribers}
              </Typography.Headline>
              <Icon.AlertCircle stroke={variables.color.primary.mainPurple} />
            </Box>
            <BenchmarkItem
              title={content.opens}
              value={data.currentMonthRefinedOpenRate}
              trend={data.currentMonthRefinedOpenRate - data.last12MonthsRefinedOpenRate}
            />
          </Grid>
        </Grid>
      </Box>
    </DashboardCard>
  );
};
