import { variables } from '@styles';
import { SVGProps } from 'react';

/**
 * SignalOutline component
 * @returns {JSX.Element}
 */

export const SignalOutline = ({
  className = '',
  width = 16,
  height = 17,
  fill = variables.color.primary.mediumGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.64608 4.14645C6.84134 3.95118 7.15792 3.95118 7.35319 4.14645C7.54845 4.34171 7.54845 4.65829 7.35319 4.85355C5.3393 6.86744 5.3393 10.1326 7.35319 12.1465C7.54845 12.3417 7.54845 12.6583 7.35319 12.8536C7.15792 13.0488 6.84134 13.0488 6.64608 12.8536C4.24167 10.4492 4.24167 6.55085 6.64608 4.14645Z"
      fill={fill}
    />
    <path
      d="M9.06754 5.86031C8.87228 5.66505 8.5557 5.66505 8.36043 5.86031C6.9028 7.31794 6.9028 9.68123 8.36043 11.1389C8.5557 11.3341 8.87228 11.3341 9.06754 11.1389C9.2628 10.9436 9.2628 10.627 9.06754 10.4318C8.00043 9.36465 8.00043 7.63453 9.06754 6.56742C9.2628 6.37216 9.2628 6.05558 9.06754 5.86031Z"
      fill={fill}
    />
    <path
      d="M10.7817 7.57516C10.5864 7.3799 10.2698 7.3799 10.0745 7.57516C9.56369 8.08601 9.56369 8.91428 10.0745 9.42513C10.2698 9.6204 10.5864 9.6204 10.7817 9.42513C10.9769 9.22987 10.9769 8.91329 10.7817 8.71803C10.6613 8.59769 10.6613 8.4026 10.7817 8.28226C10.9769 8.087 10.9769 7.77042 10.7817 7.57516Z"
      fill={fill}
    />
  </svg>
);
