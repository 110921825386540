import { ApiError } from '@modules';
import { createAsyncThunk } from '@store';
import { network } from '@utils';
import FileDownload from 'js-file-download';

import { AttributeLibrarySearchArg } from './AttributeLibrary.types';
import { AttributesSearchPayload } from './AttributeLibrary.schema';

export const search = createAsyncThunk(
  'attributeLibrary/search',
  ({ businessUnit, ...rest }: AttributeLibrarySearchArg, { rejectWithValue, signal }) => {
    try {
      return network.get<AttributesSearchPayload>(
        {
          key: 'api_rules_engine_signals_search_by_business_unit',
          params: { businessUnit: businessUnit, itemsPerPage: 200, ...rest },
        },
        { signal },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const changeInLibrary = createAsyncThunk(
  'attributeLibrary/changeInLibrary',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any, { rejectWithValue }) => {
    try {
      return network.put<AttributesSearchPayload>(
        {
          key: 'api_rules_engine_signal_in_library_change',
          params: { signal: data.id },
        },
        {
          body: JSON.stringify(data),
        },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const getDataAttributeFeeds = createAsyncThunk(
  'attributeLibrary/getDataAttributeFeeds',
  (businessUnit: number, { rejectWithValue }) => {
    try {
      return network.get<any>({
        key: 'api_data_attribute_feed_list',
        params: { businessUnit },
      });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const getDataAttributeList = createAsyncThunk(
  'attributeLibrary/getDataAttributeList',
  (businessUnit: number, { rejectWithValue }) => {
    try {
      return network.get<any>({
        key: 'api_data_attribute_list',
        params: { businessUnit },
      });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const exportDataAttribute = createAsyncThunk(
  'attributeLibrary/exportDataAttribute',
  async (businessUnit: number, { rejectWithValue }) => {
    try {
      const response = await network.get<any>({
        key: 'api_data_attribute_export',
        params: { businessUnit },
      });

      const fileBlob = await response.blob();
      FileDownload(fileBlob, 'exported-attributes.csv');

      return response;
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const importDataAttribute = createAsyncThunk(
  'attributeLibrary/importDataAttribute',
  ({ businessUnit, body }: { businessUnit: number; body: FormData }, { rejectWithValue }) => {
    try {
      return network.post<any>(
        {
          key: 'api_data_attribute_import',
          params: { businessUnit },
        },
        {
          body,
        },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);
