import { ModuleFieldType } from './ModuleField.props';

export const MODULE_FIELD_ROWS_COUNT: Record<ModuleFieldType, number> = {
  '': 1,
  txt: 1,
  url: 1,
  var: 1,
  wsg: 1,
  htm: 5,
  sel: 1,
  lbl: 1,
  visualLibrary: 1,
  bee: 1,
  stn: 1,
  articleLibrary: 1,
};

export const MODULE_FIELD_COMPLEX_TYPES: ModuleFieldType[] = [
  'htm',
  'sel',
  'lbl',
  'wsg',
  'visualLibrary',
  'bee',
  'stn',
  'articleLibrary',
];

export const RTE_VAR_REGEXP = /%%=v\([^)]*\)=%%/g;
