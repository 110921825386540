/**
 * ModuleGroups component constants
 * @returns {Variable}
 */

export const INITIAL_QUERY = {
  page: 1,
  itemsPerPage: 20,
  searchTerm: '',
  businessUnitId: '',
};
