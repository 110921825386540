import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const headerHeight = '6.5rem';

const footerHeight = '5.4rem';

const divider = `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`;

const fieldValueWidth = '28rem';

const fieldLabelWidth = '17.9rem';

const checkboxIconSize = '1.6rem';

const addCohortButtonSize = '3.4rem';

const cohortDotsSize = '1.6rem';

const cohortRemoveSize = '1.6rem';

const dataVisPurgeLabelWidth = '15.4rem';

export const useStyles = makeStyles((theme) =>
  createStyles({
    businessUnitEditor: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    header: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      height: headerHeight,

      '& > $actions': {
        height: '4.1rem',
        alignItems: 'center',
      },
    },

    actions: {
      display: 'flex',

      '& > :not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },

    container: {
      height: `calc(100% - ${headerHeight} - ${footerHeight})`,
      overflowY: 'auto',
      borderTop: divider,
      borderBottom: divider,
    },

    group: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1, '45%', 4, 0),

      '&:not(:first-child)': {
        borderTop: divider,
      },

      '& > *': {
        minWidth: '50rem',
        marginTop: theme.spacing(3),
      },
    },

    horizontalGroup: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: theme.spacing(4, 0),
      minWidth: '50rem',

      '&:not(:first-child)': {
        borderTop: divider,

        '&[data-borderless="true"]': {
          borderTop: 'none',
        },
      },

      '& > *': {
        marginRight: theme.spacing(1),

        ['@media (max-width: 1300px)']: {
          width: 'calc(100%/5)',
        },

        '&:last-child': {
          marginRight: 0,

          ['@media (max-width: 1300px)']: {
            width: 'auto',
          },
        },
      },
    },

    horizontalGroupWithNoBorder: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: theme.spacing(4, 0),
      minWidth: '50rem',

      '& > *': {
        marginRight: theme.spacing(1),

        ['@media (max-width: 1300px)']: {
          width: 'calc(100%/5)',
        },

        '&:last-child': {
          marginRight: 0,

          ['@media (max-width: 1300px)']: {
            width: 'auto',
          },
        },
      },
    },

    textColumn: {
      maxWidth: '17.9rem',
      minWidth: '179px',
      marginRight: theme.spacing(3),
    },

    label: {
      marginBottom: theme.spacing(0.75),
    },

    actionBtn: {
      marginTop: theme.spacing(3.2),
      minHeight: '3.5rem',
    },

    checkbox: {
      justifyContent: 'flex-end',
      marginLeft: 0,
      marginRight: 0,
      minWidth: 'unset',
      maxWidth: '48.5rem',

      '&:not(&[data-first])': {
        marginTop: theme.spacing(0.5),
      },

      '& > .MuiCheckbox-root': {
        padding: 0,
        width: checkboxIconSize,
        height: checkboxIconSize,
        color: variables.color.primary.mediumGray,
        marginRight: theme.spacing(1),
      },

      '& > .MuiFormControlLabel-label': {
        width: `calc(${fieldValueWidth} - ${theme.spacing(1)}px - ${checkboxIconSize})`,
      },

      '&:not(.MuiDisabled) > .MuiFormControlLabel-label > .MuiTypography-root': {
        color: variables.color.primary.darkestGray,
      },

      '&.Mui-disabled': {
        '& > .MuiCheckbox-root, & > .MuiFormControlLabel-label > .MuiTypography-root': {
          color: variables.color.primary.lightGrayDarker,
        },
      },
    },

    addCohortBox: {
      display: 'flex',
    },

    addCohortInput: {
      width: `calc(100% - ${theme.spacing(1)}px - ${addCohortButtonSize})`,

      '& > div:first-child': {
        minWidth: fieldLabelWidth,
      },

      '& > div:nth-child(2)': {
        maxWidth: `calc(${fieldValueWidth} - ${theme.spacing(1)}px - ${addCohortButtonSize})`,
        marginLeft: 'auto',
      },
    },

    addCohortButton: {
      minWidth: addCohortButtonSize,
      height: addCohortButtonSize,
      marginLeft: theme.spacing(1),
    },

    addedCohorts: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingRight: theme.spacing(10.75),

      '& > *': {
        width: `calc(${fieldValueWidth} - ${theme.spacing(10.75)}px)`,
      },
    },

    cohorts: {
      display: 'flex',
      flexDirection: 'column',
    },

    cohort: {
      display: 'flex',
      background: variables.color.semantic.neutralLight,
      borderRadius: variables.borderRadius.sm,
      alignItems: 'center',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: '100%',
    },

    cohortDots: {
      display: 'flex',
      justifyContent: 'center',
      width: cohortDotsSize,
      height: cohortDotsSize,

      '& > circle': {
        fill: variables.color.semantic.neutral,
      },
    },

    cohortName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: theme.spacing(1),
      width: `calc(100% - ${cohortDotsSize} - ${cohortRemoveSize} - ${theme.spacing(1)}px * 2)`,
    },

    cohortRemove: {
      padding: 0,
      border: 0,
      width: cohortRemoveSize,
      height: cohortRemoveSize,
    },

    scheduleUpdates: {
      display: 'flex',
    },

    scheduleUpdate: {
      width: `calc((${fieldValueWidth} - ${theme.spacing(1)}px) / 2)`,

      '&:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },

    dataVisPurge: {
      display: 'flex',
    },

    dataVisPurgeInput: {
      '& > div:first-child': {
        minWidth: fieldLabelWidth,
      },

      '& > div:nth-child(2)': {
        maxWidth: `calc(${fieldValueWidth} - ${theme.spacing(1)}px - ${dataVisPurgeLabelWidth})`,
      },
    },

    dataVisPurgeLabel: {
      minWidth: dataVisPurgeLabelWidth,
      margin: theme.spacing(1, 0, 0, 1),
    },

    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: footerHeight,

      '& > $actions': {
        alignItems: 'flex-end',

        '& > *': {
          minHeight: '3rem',
        },
      },
    },
  }),
);
