import { content } from '@content';

import {
  ContainerType,
  DateFormatOptions,
  FontCaseOptions,
  FontFamilyOptions,
  NumberFormatOptions,
  UniversalContainerState,
  IdValueArray,
} from './UniversalContainer.props';

/**
 * UniversalContainer component utils
 * @returns {Function}
 */

export const getTitle = (type: ContainerType) => {
  switch (type) {
    case 'button':
      return content.button;
    case 'dataAttribute':
      return content.dataAttribute;
    default:
      return content.text;
  }
};

export const getOptionValueById = (
  options: FontCaseOptions | FontFamilyOptions | NumberFormatOptions | DateFormatOptions,
  event: { target: { value: string } },
) => {
  return (options as IdValueArray).find((item: { id: string }) => item.id === event.target.value)?.value;
};

export const getOptionIdByValue = (
  options: FontCaseOptions | FontFamilyOptions | NumberFormatOptions | DateFormatOptions,
  state: UniversalContainerState | undefined,
  param: 'fontFamily' | 'fontCase' | 'tokenNumberFormat' | 'tokenDateFormat',
) => (options as IdValueArray).find((item: { value: string | undefined }) => item.value === state?.[param])?.id;
