import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const footerInnerGap = variables.gap.sm;

export const useStyles = makeStyles(() =>
  createStyles({
    updateMailPlanTemplate: {
      display: 'flex',
      flexDirection: 'column',
    },

    tag: {
      marginTop: variables.gap.xl,
    },

    file: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      marginTop: variables.gap.sm,

      '& > label': {
        display: 'flex',
        flexDirection: 'row-reverse',

        '& > p': {
          marginLeft: 0,
          marginRight: variables.gap.xl,
        },
      },

      '& > p': {
        fontSize: variables.font.size.md,
      },
    },

    footer: {
      display: 'flex',
      margin: `${variables.gap.xl} -${footerInnerGap} 0 0`,

      '& > *': {
        margin: `0 ${footerInnerGap} 0 0`,
      },
    },
  }),
);
