import { makeStyles, createStyles } from '@material-ui/core/styles';

import { theme, variables } from '@styles';

/**
 * LibraryNavigation component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    libraryNavigation: {
      width: '34rem',
      // TODO: Change height when it interferes with layout
      height: '85vh',
      overflowY: 'scroll',
      backgroundColor: variables.color.primary.white,
      padding: theme.spacing(3, 3, 3, 0),

      ['@media (max-width: 1450px)']: {
        width: '28rem',
      },
    },

    upload: {
      marginTop: '1rem',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    searchField: {
      marginTop: theme.spacing(3.75),
      marginBottom: theme.spacing(1),
      backgroundColor: variables.color.primary.white,
      width: '100%',
    },

    listItem: {
      backgroundColor: variables.color.primary.white,
      marginBottom: theme.spacing(1),
    },

    loader: {
      textAlign: 'center',
      padding: variables.gap.xs,
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
    },
  }),
);
