import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ContentModuleCard component styles
 * @returns {Function}
 */

const transition = 'all 0.3s';

export const useStyles = makeStyles((theme) =>
  createStyles({
    contentModuleCard: {
      backgroundColor: variables.color.primary.white,
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      position: 'relative',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      transition,

      '&:after': {
        content: '""',
        position: 'absolute',
        height: `calc(100% + ${variables.borderWidth.xl})`,
        width: `calc(100% + ${variables.borderWidth.xl})`,
        left: `-${variables.borderWidth.lg}`,
        top: `-${variables.borderWidth.lg}`,
        border: `${variables.borderWidth.lg} solid ${variables.color.primary.lightPurple}`,
        borderRadius: variables.borderRadius.sm,
        opacity: 0,
        transition,
        zIndex: -1,
      },

      '&:hover': {
        borderColor: 'transparent',

        '&:after': {
          opacity: 1,
        },
      },

      '&.active': {
        '&:after': {
          height: `calc(100% + 0.3rem * 2)`,
          width: `calc(100% + 0.3rem * 2)`,
          left: `-0.3rem`,
          top: `-0.3rem`,
          border: `0.3rem solid ${variables.color.primary.mainPurple}`,
          opacity: 1,
        },
      },
    },

    contentImage: {
      height: '16.3rem',
      position: 'relative',
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      backgroundColor: variables.color.primary.backgroundLightestGray,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },

    coverImageWrapper: {
      height: '16.3rem',
      position: 'relative',
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      backgroundColor: variables.color.primary.backgroundLightestGray,
      overflow: 'hidden',
    },

    purgeBtn: {
      color: '#ccc',
      padding: 0,
    },

    coverImage: {
      width: '100%',
      height: 'auto',
      position: 'absolute',
    },

    content: {
      padding: theme.spacing(1, 2, 2, 2),
    },

    name: {
      marginBottom: theme.spacing(0.5),
      maxWidth: '80%',
    },

    index: {
      '&&': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        background: '#E8ECF0',
        borderRadius: '2px',
        width: 'fit-content',
        padding: '2px 5px',
        fontWeight: '700',
        fontSize: '9px',
        cursor: 'pointer',
      },
    },

    contentValue: {
      fontWeight: 'bold',
      color: '#000',
    },

    deploymentName: {
      width: '40%',
      textAlign: 'right',
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-end',
    },

    moduleRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    infoGroup: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '7px',
    },
  }),
);
