import { content } from '@content';
import { ApiError, global } from '@modules';
import { createAsyncThunk } from '@store';
import { network, textTemplate } from '@utils';

import { User, UserUpdateArg, UserUpdatePayload, UsersGetAllArg, UsersListPayload } from './Users.schema';

export const getAll = createAsyncThunk('users/getAll', (params: UsersGetAllArg, { rejectWithValue }) => {
  try {
    return network.get<UsersListPayload>({ key: 'list_users', params });
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});

export const update = createAsyncThunk('users/update', async (arg: UserUpdateArg, thunkApi) => {
  const params = { user: arg.id };

  thunkApi.dispatch(global.actions.isLoading(true));

  try {
    const response = await network.put<User>(
      { key: 'update_user', params },
      { body: JSON.stringify(arg.user), signal: thunkApi.signal },
    );

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.updatedSuccessfully, { value: content.user }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  } finally {
    thunkApi.dispatch(global.actions.isLoading(false));
  }
});

export const create = createAsyncThunk('users/create', async (user: UserUpdatePayload, thunkApi) => {
  thunkApi.dispatch(global.actions.isLoading(true));

  try {
    const response = await network.post<User>(
      { key: 'create_user' },
      { body: JSON.stringify(user), signal: thunkApi.signal },
    );

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.createdSuccessfully, { value: content.user }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  } finally {
    thunkApi.dispatch(global.actions.isLoading(false));
  }
});
