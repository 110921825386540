import { Client, BasePaginatedData, Role, Permission, BaseSchema } from '@modules';
import { sort } from '@utils';

export type IdsArray = {
  id: number;
}[];

export enum UserStatus {
  active = 'active',
  inactive = 'inactive',
}

export type UserRole = Pick<Role, 'id' | 'name'>;

export interface UserUpdatePayload {
  status?: UserStatus;
  email: string;
  firstName: string;
  lastName: string;
  businessUnits: IdsArray;
  enterprises: IdsArray;
  role?: UserRole;
}

export interface UserUpdateArg {
  id: number;
  user: UserUpdatePayload;
}

export type UserLastUsedClient = Pick<Client, 'id'>;

export type User = {
  businessUnits?: Client[];
  createdAt: number;
  email: string;
  firstname: string;
  id: number;
  isAgreementAccepted?: boolean;
  lastname: string;
  permissions?: Permission[];
  role: UserRole;
  lastUsedClient?: UserLastUsedClient;
  status?: UserStatus;
};

export type UsersGetAllArg = {
  page: number;
  itemsPerPage: number;
  sortBy?: string;
  sort?: sort.Order;
};

export type UserItemPayload = {
  role?: UserRole;
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  businessUnits: IdsArray;
  enterprises: IdsArray;
  createdAt: number;
  status: UserStatus;
};

export type UsersListPayload = BasePaginatedData<UserItemPayload>;

export interface UsersSchema {
  list: BaseSchema<UsersListPayload>;
  entity?: UserItemPayload;
}
