import { SVGProps } from 'react';

import { variables } from '@styles';

export const PersonCircle = ({
  className = '',
  width = 24,
  height = 24,
  color = variables.color.primary.mediumPurple,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6459 7.72594C10.2398 7.09641 11.0756 6.75 12 6.75C12.9182 6.75 13.7526 7.09875 14.3489 7.73156C14.9526 8.37281 15.247 9.23531 15.1776 10.1606C15.0389 12 13.6162 13.5 12 13.5C10.3837 13.5 8.95824 12 8.82231 10.1602C8.75527 9.22594 9.05199 8.35547 9.6459 7.72594Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.24993 12.1359C2.17587 6.65243 6.65243 2.17587 12.1359 2.24993C17.3948 2.32305 21.6768 6.60508 21.749 11.8649C21.824 17.3484 17.3484 21.8249 11.864 21.7499C6.60508 21.6768 2.32305 17.3948 2.24993 12.1359ZM17.9948 17.6362C18.0202 17.6258 18.0431 17.61 18.0618 17.5898C19.4699 16.0691 20.2515 14.0725 20.2499 11.9999C20.2499 7.44415 16.543 3.73868 11.9798 3.74993C7.41649 3.76118 3.67352 7.57915 3.74993 12.1406C3.78344 14.1643 4.56259 16.1045 5.93805 17.5893C5.95673 17.6095 5.97961 17.6253 6.00509 17.6357C6.03056 17.646 6.05801 17.6507 6.08547 17.6492C6.11294 17.6478 6.13975 17.6404 6.16402 17.6274C6.18828 17.6145 6.20939 17.5963 6.22587 17.5743C6.64516 17.0259 7.15862 16.5564 7.74227 16.1877C8.93571 15.4218 10.4479 14.9999 11.9999 14.9999C13.552 14.9999 15.0641 15.4218 16.2576 16.1877C16.8413 16.5566 17.3547 17.0262 17.774 17.5748C17.7905 17.5968 17.8116 17.6149 17.8358 17.6279C17.8601 17.6408 17.8869 17.6483 17.9144 17.6497C17.9418 17.6511 17.9693 17.6465 17.9948 17.6362Z"
      fill={color}
    />
  </svg>
);
