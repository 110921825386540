import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Signal component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    signal: {
      display: 'flex',
      alignItems: 'center',
      background: variables.color.primary.lightGray,
      justifyContent: 'space-between',
      borderRadius: '0.4rem',
      width: '94% !important',
      padding: theme.spacing(1, 0.875, 1, 0.5),
      '&:hover': {
        borderColor: `${variables.color.primary.mainPurple} !important`,
        background: `${variables.color.primary.lighterPurple} !important`,
        color: `${variables.color.primary.mainPurple} !important`,
      },
      '&:hover $text': {
        color: `${variables.color.primary.mainPurple} !important`,
      },
    },
    leftContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      flex: 1,
      marginLeft: theme.spacing(0.5),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    text: {
      color: variables.color.primary.darkestGray,
      fontFamily: variables.font.family.code,
    },
  }),
);
