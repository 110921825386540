import { SVGProps } from 'react';

export const CloudUploadOutline = ({
  width = 16,
  height = 16,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.4999 11.8002H12.0999C14.1999 11.8002 15.7999 10.4002 15.7999 8.4002C15.7999 6.7002 14.3999 5.6002 12.8999 5.3002C12.3999 2.9002 10.3999 1.2002 7.9999 1.2002C5.8999 1.2002 4.3999 2.60019 3.8999 4.2002C1.7999 4.60019 0.399902 6.1002 0.399902 8.0002C0.399902 10.2002 2.1999 11.9002 4.5999 11.9002H5.5999C6.0999 11.9002 6.3999 11.6002 6.3999 11.1002C6.3999 10.6002 5.9999 10.3002 5.5999 10.3002H4.5999C2.9999 10.3002 1.7999 9.30019 1.7999 7.9002C1.7999 6.4002 3.0999 5.60019 4.2999 5.4002C4.5999 5.4002 4.8999 5.2002 4.9999 4.9002C5.2999 3.9002 6.2999 2.7002 7.9999 2.7002C9.8999 2.7002 11.2999 4.10019 11.4999 6.10019C11.4999 6.5002 11.7999 6.8002 12.1999 6.8002C13.0999 6.8002 14.2999 7.3002 14.2999 8.5002C14.2999 9.7002 13.0999 10.2002 12.0999 10.2002H10.4999C9.9999 10.2002 9.6999 10.5002 9.6999 11.0002C9.6999 11.5002 10.0999 11.8002 10.4999 11.8002Z"
      fill={fill}
    />
    <path
      d="M7.20005 13.9998C7.20005 14.4998 7.60005 14.7998 8.00005 14.7998C8.40005 14.7998 8.80005 14.3998 8.80005 13.9998V7.7998L9.40005 8.4998C9.70005 8.7998 10.2 8.7998 10.5 8.4998C10.8 8.1998 10.8 7.6998 10.5 7.3998L8.50005 5.3998C8.20005 5.0998 7.70005 5.0998 7.40005 5.3998L5.40005 7.3998C5.10005 7.6998 5.10005 8.1998 5.40005 8.4998C5.70005 8.7998 6.20005 8.7998 6.50005 8.4998L7.20005 7.7998V13.9998Z"
      fill={fill}
    />
  </svg>
);
