import { SVGProps } from 'react';

export const ArrowNext = ({ className, stroke, width = 40, height = 40 }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.9102 30.6855L27.5935 20.0022L16.9102 9.31882"
      stroke={stroke}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
