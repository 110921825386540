import { createAsyncThunk } from '@store';
import { ApiError, global } from '@modules';
import { network, textTemplate } from '@utils';
import { content } from '@content';

import {
  Client,
  UserClientsPayload,
  UserClientsListData,
  UserClientsFullListData,
  UserClientsFieldsMappingData,
} from './UserClients.schema';
import {
  ClientUpdateArg,
  ClientCreateArg,
  ClientListArg,
  ClientFileUpdateArg,
  ClientUploadMailFile,
  ValidateEpsCredsQuery,
  ValidateEspCredsReply,
  ClientToggleArchivedQuery,
  TestSftpConnectionQuery,
  TestSftpConnectionReply,
} from './UserClients.types';
import { FILE_UPDATE_API_KEYS } from './UserClients.const';

export const getAll = createAsyncThunk('userClients/getAll', (...[, thunkApi]) => {
  try {
    return network.get<UserClientsPayload>({ key: 'get_user_business_units' }, { signal: thunkApi.signal });
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const getList = createAsyncThunk('userClients/getList', (params: ClientListArg, thunkApi) => {
  try {
    return network.get<UserClientsListData>({ key: 'list_clients', params }, { signal: thunkApi.signal });
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const getFullList = createAsyncThunk('userClients/getFullList', (...[, thunkApi]) => {
  try {
    return network.get<UserClientsFullListData>(
      { key: 'list_clients', params: { paginated: false } },
      { signal: thunkApi.signal },
    );
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const getById = createAsyncThunk('userClients/getById', (businessUnit: number, thunkApi) => {
  try {
    return network.get<Client>({ key: 'get_business_unit', params: { businessUnit } }, { signal: thunkApi.signal });
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const getSubject = createAsyncThunk('userClients/getSubject', (businessUnit: number, thunkApi) => {
  try {
    return network.get<Client>({ key: 'get_business_unit', params: { businessUnit } }, { signal: thunkApi.signal });
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const getFieldsMapping = createAsyncThunk('userClients/getFieldsMapping', (businessUnit: number, thunkApi) => {
  try {
    return network.get<UserClientsFieldsMappingData>(
      { key: 'get_client_personalization_fields_mapping', params: { businessUnit } },
      { signal: thunkApi.signal },
    );
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const updateFile = createAsyncThunk('userClients/updateFile', async (arg: ClientFileUpdateArg, thunkApi) => {
  const { id: businessUnit, key, body } = arg;

  thunkApi.dispatch(global.actions.isLoading(true));

  try {
    const response = await network.post<Client>(
      { key: FILE_UPDATE_API_KEYS[key], params: { businessUnit } },
      { body, signal: thunkApi.signal },
    );

    const value =
      key === 'mailPlanTemplate'
        ? content.mailPlanMappingFileTitle
        : `${content.businessUnit} ${content.engagementStatisticFiles}`;

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.updatedSuccessfully, { value }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  } finally {
    thunkApi.dispatch(global.actions.isLoading(false));
  }
});

export const updateCohorts = createAsyncThunk('userClients/updateCohorts', async (arg: ClientUpdateArg, thunkApi) => {
  const { id: businessUnit, ...payload } = arg;

  try {
    const response = await network.put<Client>(
      { key: 'update_client_cohorts', params: { businessUnit } },
      { body: JSON.stringify(payload), signal: thunkApi.signal },
    );

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.updatedSuccessfully, { value: `${content.client} ${content.cohorts}` }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const update = createAsyncThunk('userClients/update', async (arg: ClientUpdateArg, thunkApi) => {
  const { id: businessUnit, ...payload } = arg;

  try {
    const response = await network.put<Client>(
      { key: 'update_business_unit', params: { businessUnit } },
      { body: JSON.stringify(payload), signal: thunkApi.signal },
    );

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.updatedSuccessfully, { value: content.client }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const toggleArchived = createAsyncThunk(
  'userClients/toggleArchived',
  async ({ businessUnit, isArchived }: ClientToggleArchivedQuery, { dispatch, rejectWithValue }) => {
    try {
      const response = await network.put<Client>(
        { key: 'toggle_archive_business_unit', params: { businessUnit } },
        { body: JSON.stringify({ isArchived }) },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.entitySuccessfullyProceed, {
      //       entity: content.client,
      //       proceed: (isArchived ? content.archived : content.unarchived).toLowerCase(),
      //     }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return response;
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const create = createAsyncThunk('userClients/create', async (payload: ClientCreateArg, thunkApi) => {
  try {
    const response = await network.post<Client>(
      { key: 'create_business_unit' },
      { body: JSON.stringify(payload), signal: thunkApi.signal },
    );

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.createdSuccessfully, { value: content.client }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const clientUploadMailFile = createAsyncThunk(
  'userClients/clientUploadMailFile',
  async ({ businessUnit, body }: ClientUploadMailFile, { dispatch, rejectWithValue, signal }) => {
    try {
      dispatch(global.actions.isLoading(true));

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.updatedSuccessfully, { value: content.subscriberList }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return await network.post({ key: 'business_unit_upload_mail_file', params: { businessUnit } }, { signal, body });
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const validateEspCreds = createAsyncThunk(
  'userClients/validateEspCreds',
  async (query: ValidateEpsCredsQuery, { rejectWithValue }) => {
    try {
      return network.post<ValidateEspCredsReply>({ key: 'validate_esp_credentials' }, { body: JSON.stringify(query) });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const testSftpConnection = createAsyncThunk(
  'userClients/testSftpConnection',
  async (query: TestSftpConnectionQuery, { rejectWithValue }) => {
    try {
      return network.post<TestSftpConnectionReply>({ key: 'get_ftp_dir_tree' }, { body: JSON.stringify(query) });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const checkS3PublicBucketAvailability = createAsyncThunk(
  'userClients/checkS3PublicBucketAvailability',
  async (payload: { awsS3BucketName: string }, { rejectWithValue }) => {
    try {
      return network.post<any>({ key: 'check_s3_bucket_availability' }, { body: JSON.stringify(payload) });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const checkS3PrivateBucketAvailability = createAsyncThunk(
  'userClients/checkS3PrivateBucketAvailability',
  async (payload: { awsS3BucketName: string }, { rejectWithValue }) => {
    try {
      return network.post<any>({ key: 'check_s3_bucket_availability' }, { body: JSON.stringify(payload) });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const checkS3ImportBucketAvailability = createAsyncThunk(
  'userClients/checkS3ImportBucketAvailability',
  async (payload: { awsS3BucketName: string }, { rejectWithValue }) => {
    try {
      return network.post<any>({ key: 'check_s3_bucket_availability' }, { body: JSON.stringify(payload) });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);
