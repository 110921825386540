import { useEffect, useMemo, useRef } from 'react';

import { useAppDispatch } from '@store';
import { MetaSchema, global } from '@modules';

export const useLoader = (...metas: MetaSchema[]): boolean => {
  const dispatch = useAppDispatch();

  const loading = useMemo(() => metas.some((meta) => meta.status === 'loading'), [metas]);

  const loadingRef = useRef(false);

  useEffect(() => {
    if (loadingRef.current !== loading) {
      loadingRef.current = loading;
      dispatch(global.slice.actions.isLoading(loading));
    }
  }, [dispatch, loading]);

  return loading;
};
