import { network } from '@utils';
import { ApiError, global } from '@modules';
import { createAsyncThunk } from '@store';
import { content } from '@content';

import {
  Template,
  TemplateCreatePayload,
  TemplateCopyPayload,
  TemplateListParams,
  TemplateListData,
  TemplateUpdatePayload,
} from './Template.schema';
import { payloadToBody } from './Template.utils';

const createListGetter =
  (businessUnit: TemplateListParams['businessUnit'], signal: AbortSignal) => (page: TemplateListParams['page']) => {
    const params: TemplateListParams = { businessUnit: businessUnit, page, itemsPerPage: 8 };

    return network.get<TemplateListData>({ key: 'get_email_templates_by_business_unit', params }, { signal });
  };

export const getAll = createAsyncThunk(
  'template/getAll',
  async (businessUnit: number, { signal, dispatch, rejectWithValue }) => {
    const getList = createListGetter(businessUnit, signal);

    try {
      const firstList = await getList(1);

      if (firstList) {
        const { pageCount, items } = firstList;

        return pageCount === 1
          ? items
          : (
              await Promise.all(
                Array(pageCount - 1)
                  .fill(null)
                  .map((_, index) => getList(index + 2)),
              )
            ).reduce((acc, list) => [...acc, ...(list?.items || [])], items);
      } else {
        return [];
      }
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const create = createAsyncThunk(
  'template/create',
  async (payload: TemplateCreatePayload, { signal, dispatch, rejectWithValue }) => {
    try {
      const { businessUnit, ...data } = payload;

      return await network.post<Template>(
        { key: 'api_email_template_upload_by_business_unit', params: { businessUnit: businessUnit } },
        { signal, body: payloadToBody(data) },
      );
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const copy = createAsyncThunk(
  'template/copy',
  async (payload: TemplateCopyPayload, { signal, dispatch, rejectWithValue }) => {
    try {
      const { id: emailTemplate, deployment } = payload;

      const result = await network.post<Template>(
        { key: 'api_set_email_template_deployment', params: { deployment, emailTemplate } },
        { signal },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: content.successfullyCompleted,
      //     options: { variant: 'success' },
      //   }),
      // );

      return result;
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const update = createAsyncThunk(
  'template/update',
  async ({ id, ...restPayload }: TemplateUpdatePayload, { signal, dispatch, rejectWithValue }) => {
    try {
      // dispatch(global.actions.isLoading(true));

      const resp = await network.put<Template>(
        { key: 'update_email_template_deployment', params: { deploymentEmailTemplate: `${id}` } },
        {
          signal,
          body: JSON.stringify(restPayload),
        },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: content.successfullyCompleted,
      //     options: { variant: 'success' },
      //   }),
      // );

      return resp;
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const download = createAsyncThunk<
  void,
  {
    withModules: number;
    emailTemplateDeployment: number;
  }
>('template/download', async ({ withModules, emailTemplateDeployment }, { dispatch, rejectWithValue }) => {
  try {
    // dispatch(global.actions.isLoading(true));
    const resp = await network.get<Response>({
      key: 'api_email_template_download',
      params: { withModules, emailTemplateDeployment },
    });

    if (resp) {
      const blob = await resp.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = resp.headers.get('Content-Disposition')?.split('filename=')[1] || 'email_template';
      link.click();
    }

    return undefined;
  } catch (e) {
    dispatch(global.actions.enqueueError(e as ApiError));

    return rejectWithValue(e as ApiError);
  } finally {
    // dispatch(global.actions.isLoading(false));
  }
});

export const selectTemplateToDeployment = createAsyncThunk(
  'template/selectTemplateToDeployment',
  (deploymentEmailTemplate: number, { dispatch, rejectWithValue }) => {
    try {
      setTimeout(() => {
        // dispatch(global.actions.isLoading(true));
      }, 100);

      return network.put({ key: 'api_select_email_template_deployment', params: { deploymentEmailTemplate } });
    } catch (exception) {
      dispatch(global.actions.enqueueError(exception as ApiError));

      return rejectWithValue(exception as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);
