import { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { useAppDispatch } from '@store';
import {
  businessUnitPrograms,
  enterprise,
  EnterpriseUpdateEntityClientProgramArg,
  ProgramCreatePayload,
  ProgramUpdateArg,
} from '@modules';
import { textTemplate, useLoader, useWebTitle } from '@utils';

import { List, ListProps } from './List';
import { ProgramDetails, ProgramDetailsProps } from './ProgramDetails';

import { Mode } from './AdminPrograms.types';
import { useStyles } from './AdminPrograms.styles';

export const AdminPrograms = (): JSX.Element => {
  const styles = useStyles();

  const dispatch = useAppDispatch();

  const enterpriseListInit = enterprise.useFullListData();

  const enterpriseList = useMemo(
    () => enterpriseListInit.filter((ntps) => ntps.businessUnits.length !== 0),
    [enterpriseListInit],
  );

  const enterpriseEntity = enterprise.useEntityData();

  const enterpriseEntityMeta = enterprise.useEntityMeta();

  const programItem = businessUnitPrograms.useItemData();

  useLoader(enterprise.useListMeta(), enterpriseEntityMeta, businessUnitPrograms.useItemMeta());

  const [mode, setMode] = useState<Mode>({ type: 'list', alert: null, businessUnit: 0 });

  const webTitleDetail = useMemo(
    () => mode.type === 'program' && (programItem?.name || textTemplate(content.addValue, { value: content.program })),
    [mode.type, programItem?.name],
  );

  useWebTitle(!webTitleDetail && content.settings, content.programs, webTitleDetail);

  const handleListEnterpriseChange = useCallback<ListProps['onEnterpriseChange']>(
    (ntpsId) => {
      setMode((prev) => ({ ...prev, alert: null }));
      dispatch(enterprise.thunk.getEntity(ntpsId));
    },
    [dispatch, setMode],
  );

  const handleListAlertClose = useCallback<ListProps['onAlertClose']>(() => {
    setMode((prev) => ({ ...prev, alert: null }));
  }, [setMode]);

  const handleListProgramAdd = useCallback<ListProps['onProgramAdd']>(
    (businessUnitId) => {
      setMode((prev) => ({ ...prev, type: 'program', alert: null, businessUnit: businessUnitId }));
    },
    [setMode],
  );

  const handleListProgramEdit = useCallback<ListProps['onProgramEdit']>(
    async (id) => {
      const result = await dispatch(businessUnitPrograms.thunk.getItem({ program: id }));

      if (businessUnitPrograms.thunk.getItem.fulfilled.match(result)) {
        setMode((prev) => ({ ...prev, type: 'program', alert: null }));
      }
    },
    [dispatch, setMode],
  );

  const handleProgramDetailsCancel = useCallback<ProgramDetailsProps['onCancel']>(() => {
    dispatch(businessUnitPrograms.slice.actions.resetItem());
    setMode((prev) => ({ ...prev, type: 'list' }));
  }, [dispatch, setMode]);

  const isArchivedToggling = useRef(false);

  const handleProgramDetailsSubmit = useCallback<ProgramDetailsProps['onSubmit']>(
    async (values) => {
      const { id, ...createPayload } = values;
      let result;
      let succeed;
      const toggling = isArchivedToggling.current;
      isArchivedToggling.current = false;

      if (id) {
        if (toggling) {
          result = await dispatch(
            businessUnitPrograms.thunk.toggleArchived({ program: id, isArchived: !!createPayload.isArchived }),
          );
          succeed = businessUnitPrograms.thunk.toggleArchived.fulfilled.match(result);
        } else {
          result = await dispatch(businessUnitPrograms.thunk.update(values as ProgramUpdateArg));
          succeed = businessUnitPrograms.thunk.update.fulfilled.match(result);
        }
      } else {
        result = await dispatch(businessUnitPrograms.thunk.create(createPayload as ProgramCreatePayload));
        succeed = businessUnitPrograms.thunk.create.fulfilled.match(result);
      }

      if (succeed && result.payload) {
        const ntpsUpdProgArg = {
          businessUnitId: values.businessUnit,
          program: result.payload,
        } as EnterpriseUpdateEntityClientProgramArg;

        if (id) {
          dispatch(enterprise.slice.actions.updateEntityClientProgram(ntpsUpdProgArg));
        } else {
          dispatch(enterprise.slice.actions.appendEntityClientProgram(ntpsUpdProgArg));
        }

        if (!toggling) {
          dispatch(businessUnitPrograms.slice.actions.resetItem());
          setMode((prev) => ({ ...prev, type: 'list', alert: id ? 'updated' : 'added' }));
        }
      }
    },
    [dispatch, setMode],
  );

  const handleProgramDetailsArchivedToggle = useCallback<ProgramDetailsProps['onArchivedToggle']>(
    (id, businessUnit, isArchived) => {
      isArchivedToggling.current = true;
      handleProgramDetailsSubmit({ id, businessUnit: businessUnit, isArchived });
    },
    [handleProgramDetailsSubmit],
  );

  useEffect(() => {
    if (enterpriseList.length !== 0 && !enterpriseEntity && enterpriseEntityMeta.status === 'idle') {
      const [firstEnterprise] = enterpriseList;

      dispatch(enterprise.thunk.getEntity(firstEnterprise.id));
    }
  }, [dispatch, enterpriseList, enterpriseEntity, enterpriseEntityMeta.status]);

  return (
    <Box className={styles.adminPrograms}>
      {mode.type === 'list' && (
        <List
          alert={mode.alert}
          enterprise={enterpriseEntity}
          enterprises={enterpriseList}
          onAlertClose={handleListAlertClose}
          onEnterpriseChange={handleListEnterpriseChange}
          onProgramAdd={handleListProgramAdd}
          onProgramEdit={handleListProgramEdit}
        />
      )}
      {mode.type === 'program' && (
        <ProgramDetails
          enterprises={enterpriseList}
          enterprise={enterpriseEntity}
          businessUnitId={mode.businessUnit}
          program={programItem}
          onSubmit={handleProgramDetailsSubmit}
          onCancel={handleProgramDetailsCancel}
          onArchivedToggle={handleProgramDetailsArchivedToggle}
        />
      )}
    </Box>
  );
};
