import { useContext, useEffect } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import { Link as RouterLink, Switch, Route, useLocation } from 'react-router-dom';

import { AdminClients, AdminUsers, AdminPrograms } from '@routes';
import { Typography, Icon } from '@components';
import { content, page } from '@content';
import { history } from '@store';
import { isRoleEditor } from '@utils';
import { TopBarContext, TOP_BAR_CTX_VALUE } from '@layouts';

import { useStyles } from './Admin.styles';
import { AdminTab } from './Admin.types';

/**
 Admin route component.
 @returns {JSX.Element}
 */

export const Admin = (): JSX.Element => {
  const styles = useStyles();

  const TABS: AdminTab[] = [
    {
      path: 'adminClients',
      slug: true,
      label: 'enterpriseAndBusinessUnits',
      icon: {
        key: 'BusinessOutlineV2',
        className: 'tabIcon',
      },
      shown: !isRoleEditor(),
      Route: AdminClients,
    },
    {
      path: 'adminUsers',
      label: 'users',
      icon: {
        key: 'PeopleOutline',
        className: 'tabIconStroke',
      },
      shown: !isRoleEditor(),
      Route: AdminUsers,
    },
    {
      path: 'adminPrograms',
      label: 'programs',
      icon: {
        key: 'ProgramsOutline',
        className: 'tabIcon',
        width: 20,
        height: 20,
      },
      shown: !isRoleEditor(),
      Route: AdminPrograms,
    },
  ].filter((tab) => tab.shown) as AdminTab[];

  const [, setTopBarCtx] = useContext(TopBarContext);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      sectionName: content.settings,
    }));

    return () => setTopBarCtx(() => TOP_BAR_CTX_VALUE);
  }, [setTopBarCtx]);

  useEffect(() => {
    if (!TABS.find((tab) => page[tab.path] === history.location.pathname)) {
      const [defaultTab] = TABS;
      const nextPath = page[defaultTab?.path || 'dashboard'];

      history.replace(`${nextPath}${history.location.search}`);
    }
  }, [TABS]);

  return (
    <Box className={styles.settings}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        className={styles.tabs}
        value={useLocation().pathname}
        TabIndicatorProps={{ hidden: true }}
      >
        {TABS.map((tab) => (
          <Tab
            className={styles.tab}
            value={page[tab.path]}
            to={`${page[tab.path]}${history.location.search}`}
            label={<Typography.Headline className={styles.label}>{content[tab.label]}</Typography.Headline>}
            icon={(() => {
              const TabIcon = Icon[tab.icon.key];

              return <TabIcon className={styles[tab.icon.className]} width={tab.icon.width} height={tab.icon.height} />;
            })()}
            component={RouterLink}
            key={tab.label}
          />
        ))}
      </Tabs>
      <Box className={styles.content}>
        <Switch>
          {TABS.map((tab) => (
            <Route path={`${page[tab.path]}${tab.slug ? '/:id?' : ''}`} key={tab.label}>
              <tab.Route />
            </Route>
          ))}
        </Switch>
      </Box>
    </Box>
  );
};
