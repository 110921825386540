import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@material-ui/core';

import { parse, clipboard, format, useDeploymentAlerts, useLoader, useHistory } from '@utils';
import { content, page } from '@content';
import { Dropdown, Legend, Typography, Panel, Alert, Table, TableColumnSort } from '@components';
import { useAppDispatch } from '@store';
import { deployment, businessUnitDeployments, DeploymentStatus, modules, ModuleStatus } from '@modules';
import { DeploymentProcessingAlert, DeploymentPicker, DataVisualizationProcessAlert } from '@views';

import { DeploymentInfo } from './DeploymentInfo';
import { SmartContentFileAlert } from './SmartContentFileAlert';

import { useStyles } from './PublishToESP.styles';
import { STATUSES } from './PublishToESP.const';
import { ColId, Rec, Sort } from './PublishToESP.types';
import {
  statTooltip,
  statRender,
  mtagTooltip,
  mtagRender,
  dscrTooltip,
  dscrRender,
  posnRender,
  dateRender,
  getColSort,
  getSortedRecs,
  getPckdRender,
} from './PublishToESP.utils';

export function PublishToESP(): JSX.Element {
  const styles = useStyles();
  const history = useHistory();
  const [sort, setSort] = useState<Sort>({ order: null });
  const [selected, setSelected] = useState<number[]>([]);
  const htmlEmailShellRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const { businessUnit: businessUnitId, deployment: deploymentId } = history.query;
  const dep = deployment.useDeploymentData();
  const { data: deployments, ...deploymentsMeta } = businessUnitDeployments.useNonArchived();
  const { data: modulesList, ...modulesListMeta } = deployment.useModulesList();

  useLoader(deployment.useMeta(), deployment.useDataVizGenerationStatusMeta(), deploymentsMeta, modulesListMeta);

  const [published, modified, notPublished] = modulesList.reduce(
    (sum, module) => {
      let [pub, mod, not] = sum;

      if (module.status === ModuleStatus.published) {
        pub += 1;
      } else if (module.status === ModuleStatus.modified) {
        mod += 1;
      } else if (module.status === ModuleStatus.notPublished) {
        not += 1;
      }

      return [pub, mod, not];
    },
    [0, 0, 0],
  );

  const reloadWithDeployment = useCallback(
    (nextDepId: number) => {
      history.push('publishToEsp', { deployment: nextDepId });
    },
    [history],
  );

  const isDeploymentInMarket = useMemo(() => dep?.status === DeploymentStatus.inMarket, [dep?.status]);

  const alerts = useDeploymentAlerts(dep, Number(businessUnitId), page.publishToEsp);

  const handleCopyClipboard = useCallback((value) => clipboard.copy(value), []);

  const handleClick = useCallback(
    (id: number) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      setSelected(newSelected);
    },
    [selected],
  );

  const handlePckdChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const { id: modId } = event.target.dataset;

      if (modId) {
        handleClick(parse.integer(modId));
      } else {
        setSelected(checked ? modulesList.map((mod) => mod.id) : []);
      }
    },
    [handleClick, setSelected, modulesList],
  );

  const handleTableSort = useCallback(
    (colId: string, order: TableColumnSort) => {
      setSort({ by: colId as ColId, order });
    },
    [setSort],
  );

  const publishSelectedModules = useCallback(async () => {
    const resultAction = await dispatch(
      modules.thunk.publish({
        deploymentId: Number(deploymentId),
        selected,
      }),
    );

    if (modules.thunk.publish.fulfilled.match(resultAction)) {
      setSelected([]);
    }
  }, [deploymentId, dispatch, selected]);

  const publishHTMLEmailShell = useCallback(() => {
    if (htmlEmailShellRef.current) {
      htmlEmailShellRef.current.value = '';
      htmlEmailShellRef.current.click();
    }
  }, []);

  const publishSmartContentFile = useCallback(() => {
    dispatch(deployment.thunk.publishSmartFile(Number(deploymentId)));
  }, [deploymentId, dispatch]);

  const uploadHTMLEmailShell = useCallback(
    (e) => {
      if (!e.target.files?.length) {
        return;
      }

      const formData = new FormData();
      formData.append('emailShellFile', e.target.files[0]);

      dispatch(
        deployment.thunk.publishEmailFile({
          deployment: Number(deploymentId),
          body: formData,
        }),
      );
    },
    [deploymentId, dispatch],
  );

  const exportSmartContentFile = () => {
    if (!deploymentId) {
      return;
    }

    dispatch(deployment.thunk.exportSmartFile(deploymentId));
  };

  useEffect(() => {
    if (deploymentId && deployments.length) {
      const nextDep = deployments.find((d) => d.id === deploymentId);
      if (nextDep) return;
      reloadWithDeployment(deployments[0].id);
    }
  }, [deploymentId, deployments, reloadWithDeployment]);

  useEffect(() => {
    if (!businessUnitId) {
      return history.push('dashboard');
    }
    const promise = dispatch(businessUnitDeployments.thunk.getNonArchived(businessUnitId));

    return () => {
      promise.abort();
    };
  }, [dispatch, businessUnitId, history]);

  useEffect(() => {
    if (!deploymentId) {
      return undefined;
    }

    const thunks = [
      dispatch(deployment.thunk.getModulesList(deploymentId)),
      dispatch(deployment.thunk.getById(deploymentId)),
      dispatch(deployment.thunk.getGenerationStatus(deploymentId)),
    ];

    return () => {
      thunks.map((thunk) => thunk.abort());
      dispatch(deployment.actions.resetEntity());
      dispatch(deployment.actions.resetModulesList());
      dispatch(deployment.actions.resetDataVizGenerationStatus());
    };
  }, [dispatch, deploymentId]);

  return (
    <Box className={styles.publishToEsp}>
      <Box className={styles.header}>
        <DeploymentPicker
          value={deployments.length && deploymentId ? deploymentId : 0}
          items={deployments}
          onChange={reloadWithDeployment}
        />
        <Legend
          className={styles.legend}
          title={content.statusKey}
          items={[
            {
              ...STATUSES[ModuleStatus.notPublished],
              count: notPublished,
            },
            {
              ...STATUSES[ModuleStatus.modified],
              count: modified,
            },
            {
              ...STATUSES[ModuleStatus.published],
              count: published,
            },
          ]}
        />
        <Dropdown
          disabled={!dep}
          title={content.actions}
          options={[
            {
              label: content.publishSelectedModules,
              onClick: publishSelectedModules,
              disabled:
                isDeploymentInMarket ||
                alerts.length > 0 ||
                selected.length === 0 ||
                !dep?.businessUnit.enableContentPublishing ||
                !dep.businessUnit.isESPConfigured,
            },
            {
              label: content.publishHTMLEmailShell,
              onClick: publishHTMLEmailShell,
              disabled:
                isDeploymentInMarket || !dep?.businessUnit.enableContentPublishing || !dep.businessUnit.isESPConfigured,
            },
            {
              label: content.publishSmartContentFile,
              onClick: publishSmartContentFile,
              disabled:
                isDeploymentInMarket || !dep?.businessUnit.enableContentPublishing || !dep.businessUnit.isESPConfigured,
            },
            {
              label: content.exportSmartContentFile,
              onClick: exportSmartContentFile,
            },
          ]}
        />
        <input
          type="file"
          name="emailShellFile"
          ref={htmlEmailShellRef}
          onChange={uploadHTMLEmailShell}
          style={{ display: 'none' }}
        />
      </Box>
      {dep && !dep.businessUnit.isESPConfigured && (
        <DeploymentProcessingAlert type="error" text={content.espConfigureError} />
      )}
      {alerts.length > 0 &&
        alerts.map((alert) => <DeploymentProcessingAlert key={alert.id} type={alert.type} text={alert.text} />)}
      <DataVisualizationProcessAlert />
      <DeploymentInfo
        smartContentFileName={dep?.smartContentFileOriginalName ?? ''}
        remoteDir={dep?.remoteDir ?? ''}
        smartContentFilePublishedAt={
          dep?.smartContentFilePublishedAt ? format.date(dep.smartContentFilePublishedAt) : ''
        }
        mailShellFilePublishedAt={dep?.mailShellFilePublishedAt ? format.date(dep.mailShellFilePublishedAt) : ''}
        onCopyClipboard={handleCopyClipboard}
      />
      <Table
        className={styles.mods}
        uiType="secondary"
        autofit
        columns={[
          {
            id: ColId.pckd,
            title: (() => {
              const render = getPckdRender(handlePckdChange);

              return (
                render &&
                (render(selected.length !== 0 && selected.length === modulesList.length, '', ColId.pckd) as JSX.Element)
              );
            })(),
            className: styles.pckd,
            align: 'middle',
            render: getPckdRender(handlePckdChange),
          },
          {
            id: ColId.stat,
            title: content.status,
            className: styles.stat,
            sort: getColSort(ColId.stat, sort),
            tooltip: statTooltip,
            render: statRender,
          },
          {
            id: ColId.mtag,
            title: content.moduleTag,
            className: styles.mtag,
            sort: getColSort(ColId.mtag, sort),
            tooltip: mtagTooltip,
            render: mtagRender,
          },
          {
            id: ColId.dscr,
            title: content.description,
            className: styles.dscr,
            sort: getColSort(ColId.dscr, sort),
            tooltip: dscrTooltip,
            render: dscrRender,
          },
          {
            id: ColId.posn,
            title: content.position,
            className: styles.posn,
            sort: getColSort(ColId.posn, sort),
            render: posnRender,
          },
          {
            id: ColId.pubd,
            title: content.publishDate,
            className: styles.pubd,
            sort: getColSort(ColId.pubd, sort),
            render: dateRender,
          },
          {
            id: ColId.modd,
            title: content.modifyDate,
            className: styles.modd,
            sort: getColSort(ColId.modd, sort),
            render: dateRender,
          },
        ]}
        records={
          modulesListMeta.status === 'success' && modulesList.length !== 0
            ? getSortedRecs(
                modulesList.map(
                  (mod) =>
                    ({
                      id: `${mod.id}`,
                      pckd: selected.includes(mod.id),
                      stat: mod.status,
                      mtag: [mod.tag, history.getUrl('contentCenter', { id: mod.id })],
                      dscr: [mod.name, mod.product, mod.cohort],
                      posn: mod.position,
                      pubd: [mod.publishedAt, mod.publishedBy],
                      modd: [mod.updatedAt, mod.updatedBy],
                    } as Rec),
                ),
                sort,
              )
            : []
        }
        onSort={handleTableSort}
      />
      {modulesListMeta.status === 'error' && (
        <Panel>
          <Alert message={content.failedToLoadItems} />
        </Panel>
      )}
      {modulesListMeta.status === 'success' && modulesList.length === 0 && (
        <Panel textAlign="center">
          <Typography.Body>{content.publishNoModules}</Typography.Body>
        </Panel>
      )}
      <SmartContentFileAlert />
    </Box>
  );
}
