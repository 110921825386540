import { ChangeEvent, useCallback, useMemo, useEffect } from 'react';
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import { Typography, Select, Icon, TextField, TextFieldCallbacks, Alert } from '@components';
import { Client, ClientEspData, ClientEspType, userClients } from '@modules';
import { content } from '@content';
import { textTemplate, parse, useLoader } from '@utils';

import { useStyles } from './ClientEditor.styles';
import {
  CLIENT_CODE_LENGTH,
  CLIENT_UNIQUE_ID_MIN,
  CLIENT_UNIQUE_ID_LIMIT,
  CLIENT_ESP_TYPE_OPTIONS,
  CLIENT_UPDATE_SCHEDULE_OPTIONS,
  CLIENT_ESP_SWITCH_CLEANUP_KEYS,
  ENTERPRISE_HINT,
  CLIENT_ESP_SALESFORCE_SCHEDULE_FIELDS,
  CLIENT_ESP_SALESFORCE_API,
  CLIENT_ESP_SALESFORCE_FTP,
  CLIENT_ESP_SELLIGENT_TEXT_FIELDS,
  CLIENT_DATA_VIS_CLEAN_PERIOD_MIN,
  CLIENT_DATA_VIS_CLEAN_PERIOD_STEP,
  CLIENT_DATA_VIS_CLEAN_PERIOD_MAX,
  CLIENT_ESP_KEYS,
  CLIENT_ESP_SALESFORCE_AWS_PRIVATE_SCHEDULE_FIELDS,
} from './ClientEditor.const';
import { ClientEditorProps } from './ClientEditor.props';
import { enterpriseToOption, getValidationRule } from './ClientEditor.utils';
import { useAppDispatch } from '@store';

export const ClientEditor = ({
  enterprises,
  enterprise,
  businessUnit,
  onSubmit,
  onCancel,
  onArchivedToggle,
}: ClientEditorProps): JSX.Element => {
  const styles = useStyles();

  const dispatch = useAppDispatch();

  useLoader(userClients.useEspCredsValidationMeta(), userClients.useSftpTestConnectionMeta());

  const espCredsValidationData = userClients.useEspCredsValidationData();
  const sftpTestConnectionData = userClients.useSftpTestConnectionData();
  const s3BucketAvailabilityData = userClients.useS3BucketAvailabilityData();

  const { handleSubmit, reset, getValues, setValue, clearErrors, watch, trigger, control } = useForm<Client>({
    defaultValues: { ...businessUnit, enterprise },
  });

  const enterpriseOptions = useMemo(() => enterprises.map(enterpriseToOption), [enterprises]);

  const handleEnterpriseChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const nextEnterpriseId = +(event.target.value as string);
      const nextEnterprise = enterprises.find((item) => item.id === nextEnterpriseId);

      if (nextEnterprise) {
        setValue('enterprise', { id: nextEnterprise.id, name: nextEnterprise.name });
      }
    },
    [setValue, enterprises],
  );

  const espType = watch('espType');

  const handleFormSubmit = useCallback(
    (values: Client) =>
      onSubmit({
        ...(Object.fromEntries(
          Object.entries(values).filter(
            ([key]) => !CLIENT_ESP_SWITCH_CLEANUP_KEYS[espType as ClientEspType].includes(key as never),
          ),
        ) as Client),
      }),
    [onSubmit, espType],
  );

  const handleCancel = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const resetEspCredsValidationData = useCallback(() => {
    dispatch(userClients.actions.resetEspCredsValidation());
  }, [dispatch]);

  const handleSalesforceApiTest = useCallback(async () => {
    const areEspFieldsValid = await trigger(CLIENT_ESP_KEYS);

    if (areEspFieldsValid) {
      dispatch(
        userClients.thunk.validateEspCreds(
          CLIENT_ESP_KEYS.reduce((acc, key) => ({ ...acc, [key]: getValues(key) }), {} as ClientEspData),
        ),
      );
    } else {
      resetEspCredsValidationData();
    }
  }, [dispatch, trigger, getValues, resetEspCredsValidationData]);

  const handleArchivedToggle = useCallback(() => {
    if (businessUnit) {
      const { id, isArchived } = businessUnit;

      onArchivedToggle(id, !isArchived);
    }
  }, [onArchivedToggle, businessUnit]);

  const resetSftpTestConnectionData = useCallback(() => {
    dispatch(userClients.actions.resetSftpTestConnection());
  }, [dispatch]);

  const handleSalesforceFtpTest = useCallback(async () => {
    const areEspFieldsValid = await trigger(CLIENT_ESP_KEYS);

    if (areEspFieldsValid) {
      dispatch(
        userClients.thunk.testSftpConnection(
          CLIENT_ESP_KEYS.reduce((acc, key) => ({ ...acc, [key]: getValues(key) }), {} as ClientEspData),
        ),
      );
    } else {
      resetSftpTestConnectionData();
    }
  }, [dispatch, trigger, getValues, resetSftpTestConnectionData]);

  const resetS3BucketAvailabilityData = useCallback(() => {
    dispatch(userClients.actions.resetS3BucketAvailability());
  }, [dispatch]);

  const handleS3BucketTest = useCallback(
    (bucketType: 'public' | 'private' | 'import') => async () => {
      resetS3BucketAvailabilityData();

      const { awsPrivateBucket, awsPublicBucket, awsImportBucket } = getValues();

      switch (bucketType) {
        case 'private':
          dispatch(userClients.thunk.checkS3PrivateBucketAvailability({ awsS3BucketName: awsPrivateBucket }));
          break;

        case 'public':
          dispatch(userClients.thunk.checkS3PublicBucketAvailability({ awsS3BucketName: awsPublicBucket }));
          break;

        case 'import':
          dispatch(userClients.thunk.checkS3ImportBucketAvailability({ awsS3BucketName: awsImportBucket! }));
          break;

        default:
          break;
      }
    },
    [dispatch, getValues],
  );

  useEffect(
    () => () => {
      resetEspCredsValidationData();
      resetSftpTestConnectionData();
    },
    [resetEspCredsValidationData, resetSftpTestConnectionData],
  );

  const actions = useMemo(
    () => (
      <Box className={styles.actions}>
        {businessUnit && (
          <Button startIcon={<Icon.Archive />} variant="text" onClick={handleArchivedToggle}>
            <Typography.Headline>
              {textTemplate(businessUnit.isArchived ? content.unarchiveEntity : content.archiveEntity, {
                entity: content.businessUnit,
              })}
            </Typography.Headline>
          </Button>
        )}
        <Button variant="outlined" onClick={handleCancel}>
          {content.cancel}
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={!!businessUnit?.isArchived}>
          {businessUnit ? content.save : textTemplate(content.addValue, { value: content.businessUnit })}
        </Button>
      </Box>
    ),
    [handleArchivedToggle, handleCancel, businessUnit, styles.actions],
  );

  return (
    <form className={styles.businessUnitEditor} onSubmit={handleSubmit(handleFormSubmit)}>
      <Box className={styles.header}>
        <Box>
          <Typography.SmallCaption>
            {content.enterpriseAndBusinessUnits}
            {content.slash}
          </Typography.SmallCaption>
          <Typography.LargeTitle>
            {textTemplate(content[businessUnit ? 'editValue' : 'addValue'], { value: content.businessUnit })}
          </Typography.LargeTitle>
        </Box>
      </Box>
      <Box className={styles.container}>
        <Box className={styles.group}>
          <Controller
            name="enterprise"
            control={control}
            rules={{
              required: ENTERPRISE_HINT,
            }}
            render={({ field: { ref, value, ...rest }, fieldState }) => (
              <Select
                {...rest}
                disabled={!!businessUnit?.isArchived}
                direction="horizontalV2"
                label={content.enterpriseGroup}
                value={value?.id}
                required
                templated
                inputRef={ref}
                placeholder={content.enterprise}
                options={enterpriseOptions}
                error={fieldState.invalid}
                helperText={fieldState.error?.message || ENTERPRISE_HINT}
                onChange={handleEnterpriseChange}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            rules={{ required: textTemplate(content.entityName, { entity: content.businessUnit }) }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                disabled={!!businessUnit?.isArchived}
                inputRef={ref}
                required
                templated
                direction="horizontalV2"
                label={textTemplate(content.entityName, { entity: content.businessUnit })}
                placeholder={textTemplate(content.entityName, { entity: content.businessUnit })}
                error={fieldState.invalid}
                hint={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            rules={{
              required: content.businessUnitUniqueCode,
              minLength: {
                value: CLIENT_CODE_LENGTH,
                message: textTemplate(content.shouldBeCountCharsLong, {
                  count: CLIENT_CODE_LENGTH,
                }),
              },
            }}
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                disabled={!!businessUnit}
                inputRef={ref}
                required
                templated
                direction="horizontalV2"
                label={content.businessUnitUniqueCode}
                placeholder={content.businessUnitUniqueCode}
                error={fieldState.invalid}
                onlyUpperCase={true}
                hint={fieldState.error?.message || content.notChangeableAfterCreated}
                inputProps={{ maxLength: CLIENT_CODE_LENGTH }}
                {...rest}
              />
            )}
          />
          <Controller
            name="enableNullSubCountHandshake"
            control={control}
            render={({ field: { ref, value, ...rest } }) => (
              <FormControlLabel
                className={styles.checkbox}
                data-first
                label={<Typography.Caption>{content.enableNullSubscriberCountHandshake}</Typography.Caption>}
                control={<Checkbox {...rest} inputRef={ref} disabled checked={false} />}
              />
            )}
          />
          <Controller
            name="enableModuleReportingHandshake"
            control={control}
            render={({ field: { ref, value, ...rest } }) => (
              <FormControlLabel
                className={styles.checkbox}
                label={<Typography.Caption>{content.enableModuleReportingHandshake}</Typography.Caption>}
                control={<Checkbox {...rest} inputRef={ref} disabled checked={false} />}
              />
            )}
          />
          <Controller
            name="enableContentPublishing"
            control={control}
            render={({ field: { ref, value, ...rest } }) => (
              <FormControlLabel
                className={styles.checkbox}
                label={<Typography.Caption>{content.enableContentPublishing}</Typography.Caption>}
                control={<Checkbox {...rest} checked={value} disabled={!!businessUnit?.isArchived} inputRef={ref} />}
              />
            )}
          />
        </Box>
        <Box className={styles.group}>
          <Controller
            name="espType"
            control={control}
            rules={{ required: content.businessUnitEsp }}
            render={({ field: { ref, ...rest }, fieldState }) => (
              <Select
                {...rest}
                required
                templated
                direction="horizontalV2"
                inputRef={ref}
                disabled={!!businessUnit}
                label={content.businessUnitEsp}
                placeholder={content.businessUnitEsp}
                options={CLIENT_ESP_TYPE_OPTIONS}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        {espType === 'salesforce' && (
          <>
            {espCredsValidationData && (
              <Alert
                align="left"
                type={espCredsValidationData.isValid ? 'success' : 'error'}
                message={espCredsValidationData.message}
                onClose={resetEspCredsValidationData}
              />
            )}
            <Box className={styles.horizontalGroup} data-borderless={!!espCredsValidationData}>
              <Box className={styles.textColumn}>
                <Typography.Label className={styles.label}>{content.salesforceApi}</Typography.Label>
                <Typography.SmallCaption>{content.toEnableDeploymentPublishment}</Typography.SmallCaption>
              </Box>
              {CLIENT_ESP_SALESFORCE_API.map(({ key, label, type }) => (
                <Controller
                  key={key}
                  name={key}
                  control={control}
                  rules={{
                    required: `${content[label]}`,
                    validate: type && getValidationRule(type),
                  }}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <TextField
                      {...rest}
                      disabled={!!businessUnit?.isArchived}
                      required
                      templated
                      direction="vertical"
                      inputRef={ref}
                      label={`${content[label]}`}
                      placeholder={`${content[label]}`}
                      error={fieldState.invalid}
                      hint={fieldState.error?.message}
                    />
                  )}
                />
              ))}
              <Button
                className={styles.actionBtn}
                variant="outlined"
                disabled={!!businessUnit?.isArchived}
                onClick={handleSalesforceApiTest}
              >
                {content.test}
              </Button>
            </Box>
            {sftpTestConnectionData && (
              <Alert
                align="left"
                type={sftpTestConnectionData.isValid ? 'success' : 'error'}
                message={sftpTestConnectionData.message}
                onClose={resetSftpTestConnectionData}
              />
            )}
            <Box className={styles.horizontalGroup} data-borderless={!!sftpTestConnectionData}>
              <Box className={styles.textColumn}>
                <Typography.Label className={styles.label}>{content.secureFtp}</Typography.Label>
                <Typography.SmallCaption>{content.enterYourSalesforceFTP}</Typography.SmallCaption>
              </Box>
              {CLIENT_ESP_SALESFORCE_FTP.map(({ key, label, type }) => (
                <Controller
                  key={key}
                  name={key}
                  control={control}
                  rules={{
                    required: `${content[label]}`,
                    validate: type && getValidationRule(type),
                  }}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <TextField
                      {...rest}
                      disabled={!!businessUnit?.isArchived}
                      required
                      templated
                      direction="vertical"
                      inputRef={ref}
                      label={`${content[label]}`}
                      placeholder={`${content[label]}`}
                      error={fieldState.invalid}
                      hint={fieldState.error?.message}
                    />
                  )}
                />
              ))}
              <Button
                className={styles.actionBtn}
                disabled={!!businessUnit?.isArchived}
                variant="outlined"
                onClick={handleSalesforceFtpTest}
              >
                {content.test}
              </Button>
            </Box>
            <Box className={styles.horizontalGroupWithNoBorder}>
              <Box className={styles.textColumn}>
                <Typography.Label className={styles.label}>{content.ftpServerSchedule}</Typography.Label>
                <Typography.SmallCaption>{content.setTheDailyTime}</Typography.SmallCaption>
              </Box>
              <Box className={styles.scheduleUpdates}>
                {CLIENT_ESP_SALESFORCE_SCHEDULE_FIELDS.map(({ key, label }) => (
                  <Box className={styles.scheduleUpdate} key={key}>
                    <Controller
                      name={key}
                      control={control}
                      rules={{ required: content.value.toLowerCase() }}
                      render={({ field: { ref, value, ...rest }, fieldState }) => (
                        <Select
                          {...rest}
                          disabled={!!businessUnit?.isArchived}
                          templated
                          required
                          label={`${content[label]}`}
                          inputRef={ref}
                          value={value || ''}
                          placeholder={`${content[label]}`}
                          options={CLIENT_UPDATE_SCHEDULE_OPTIONS}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        )}
        {espType === 'selligent' && (
          <Box className={styles.group}>
            {CLIENT_ESP_SELLIGENT_TEXT_FIELDS.map(({ key, label: labelInit, type }) => {
              const label =
                typeof labelInit === 'string'
                  ? `${content[labelInit]}`
                  : textTemplate(`${content[labelInit.template]}`, {
                      [labelInit.key]: `${content[labelInit.value]}`,
                    });

              return (
                <Controller
                  key={key}
                  name={key}
                  control={control}
                  rules={{
                    required: label,
                    validate: type && getValidationRule(type),
                  }}
                  render={({ field: { ref, ...rest }, fieldState }) => (
                    <TextField
                      {...rest}
                      disabled={!!businessUnit?.isArchived}
                      required
                      templated
                      direction="horizontal"
                      inputRef={ref}
                      label={label}
                      placeholder={label}
                      error={fieldState.invalid}
                      hint={fieldState.error?.message}
                    />
                  )}
                />
              );
            })}
          </Box>
        )}
        <Box className={styles.group}>
          <Controller
            name="uniqueId"
            control={control}
            rules={{
              required: content.uniqueId,
              minLength: {
                value: CLIENT_UNIQUE_ID_MIN,
                message: textTemplate(content.shouldBeMinCountCharsLong, {
                  count: CLIENT_UNIQUE_ID_MIN,
                }),
              },
              maxLength: {
                value: CLIENT_UNIQUE_ID_LIMIT,
                message: textTemplate(content.lengthLimitExceeded, {
                  limit: CLIENT_UNIQUE_ID_LIMIT,
                }),
              },
            }}
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                {...rest}
                disabled={!!businessUnit?.isArchived}
                inputRef={ref}
                inputProps={{
                  minLength: CLIENT_UNIQUE_ID_MIN,
                  maxLength: CLIENT_UNIQUE_ID_LIMIT,
                }}
                required
                templated
                direction="horizontalV2"
                label={content.customerUniqueId}
                placeholder={content.uniqueId}
                error={fieldState.invalid}
                hint={fieldState.error?.message}
              />
            )}
          />
        </Box>
        {s3BucketAvailabilityData.public && (
          <Alert
            align="left"
            type={s3BucketAvailabilityData.public.isValid ? 'success' : 'error'}
            message={s3BucketAvailabilityData.public.message}
            onClose={resetS3BucketAvailabilityData}
          />
        )}
        <Box className={styles.horizontalGroup}>
          <Box className={styles.textColumn}>
            <Typography.Label className={styles.label}>{content.publicBucketName}*</Typography.Label>
            <Typography.SmallCaption></Typography.SmallCaption>
          </Box>
          <Controller
            name="awsPublicBucket"
            control={control}
            rules={{
              required: content.publicBucketName,
            }}
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                {...rest}
                disabled={!!businessUnit?.isArchived}
                inputRef={ref}
                required
                templated
                direction="horizontalV2"
                placeholder={content.publicBucketName}
                error={fieldState.invalid}
                hint={fieldState.error?.message}
              />
            )}
          />
          <Button
            style={{ marginTop: 0 }}
            className={styles.actionBtn}
            disabled={!!businessUnit?.isArchived}
            variant="outlined"
            onClick={handleS3BucketTest('public')}
          >
            {content.test}
          </Button>
        </Box>
        {s3BucketAvailabilityData.private && (
          <Alert
            align="left"
            type={s3BucketAvailabilityData.private.isValid ? 'success' : 'error'}
            message={s3BucketAvailabilityData.private.message}
            onClose={resetS3BucketAvailabilityData}
          />
        )}
        <Box className={styles.horizontalGroupWithNoBorder}>
          <Box className={styles.textColumn}>
            <Typography.Label className={styles.label}>{content.privateBucketName}*</Typography.Label>
            <Typography.SmallCaption></Typography.SmallCaption>
          </Box>
          <Controller
            name="awsPrivateBucket"
            control={control}
            rules={{
              required: content.privateBucketName,
            }}
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                {...rest}
                disabled={!!businessUnit?.isArchived}
                inputRef={ref}
                required
                templated
                direction="horizontalV2"
                placeholder={content.privateBucketName}
                error={fieldState.invalid}
                hint={fieldState.error?.message}
              />
            )}
          />
          <Button
            style={{ marginTop: 0 }}
            className={styles.actionBtn}
            disabled={!!businessUnit?.isArchived}
            variant="outlined"
            onClick={handleS3BucketTest('private')}
          >
            {content.test}
          </Button>
        </Box>
        {s3BucketAvailabilityData.import && (
          <Alert
            align="left"
            type={s3BucketAvailabilityData.import.isValid ? 'success' : 'error'}
            message={s3BucketAvailabilityData.import.message}
            onClose={resetS3BucketAvailabilityData}
          />
        )}
        <Box className={styles.horizontalGroup}>
          <Box className={styles.textColumn}>
            <Typography.Label className={styles.label}>{content.privateBucketName}*</Typography.Label>
            <Typography.SmallCaption>{content.enterYourAWSPrivateBucket}</Typography.SmallCaption>
          </Box>
          <Controller
            name="awsImportBucket"
            control={control}
            rules={{
              required: content.privateBucketName,
            }}
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                {...rest}
                disabled={!!businessUnit?.isArchived}
                inputRef={ref}
                required
                templated
                direction="horizontalV2"
                placeholder={content.privateBucketName}
                error={fieldState.invalid}
                hint={fieldState.error?.message}
              />
            )}
          />
          <Button
            style={{ marginTop: 0 }}
            className={styles.actionBtn}
            disabled={!!businessUnit?.isArchived}
            variant="outlined"
            onClick={handleS3BucketTest('import')}
          >
            {content.test}
          </Button>
        </Box>
        <Box className={styles.horizontalGroupWithNoBorder}>
          <Box className={styles.textColumn}>
            <Typography.Label className={styles.label}>{content.privateBucketSchedule}</Typography.Label>
            <Typography.SmallCaption>{content.setTheDailyTimeFromPrivateBucket}</Typography.SmallCaption>
          </Box>
          <Box className={styles.scheduleUpdates}>
            {CLIENT_ESP_SALESFORCE_AWS_PRIVATE_SCHEDULE_FIELDS.map(({ key, label }) => (
              <Box className={styles.scheduleUpdate} key={key}>
                <Controller
                  name={key}
                  control={control}
                  rules={{ required: content.value.toLowerCase() }}
                  render={({ field: { ref, value, ...rest }, fieldState }) => (
                    <Select
                      {...rest}
                      disabled={!!businessUnit?.isArchived}
                      templated
                      required
                      label={`${content[label]}`}
                      inputRef={ref}
                      value={value || ''}
                      placeholder={`${content[label]}`}
                      options={CLIENT_UPDATE_SCHEDULE_OPTIONS}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={styles.footer}>{actions}</Box>
    </form>
  );
};
