import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    uploadEmailActivity: {
      maxHeight: 'min(50vh, 35.1rem)',
      overflowY: 'auto',
    },

    field: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);
