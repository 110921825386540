import { memo, useEffect, useState, ChangeEvent, useCallback } from 'react';
import { Box, Button, MenuItem } from '@material-ui/core';

import { MindsetTable } from '@views';
import { experience, userClients, MailFileData } from '@modules';
import { content } from '@content';
import { Accordion, Typography, Icon, TableLegend, Select, TableLegendItem } from '@components';
import { useStyles } from './ExperienceOverview.styles';
import { ExperienceOverviewProps } from './ExperienceOverview.props';
import { useAppDispatch } from '@store';
import { format, searchQuery, useLoader } from '@utils';

/**
 * ExperienceOverview - Experience's mindsets table
 * @returns {JSX.Element}
 */
export const ExperienceOverview = memo(
  ({ expanded, onChange, onMapContentClick, onArchive, onEdit }: ExperienceOverviewProps): JSX.Element => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const experienceItem = experience.useItemData();
    const [mailFiles, setMailFiles] = useState<MailFileData[] | null>(null);
    const [businessUnitId] = searchQuery.useMutualParams('businessUnit');
    const [selectValue, setSelectValue] = useState<string>('');
    const [subscribersCount, setSubscribersCount] = useState<number>(0);
    const [tableLegendItems, setTableLegendItems] = useState<TableLegendItem[]>([
      {
        label: '',
        count: 0,
        color: 'transparent',
      },
    ]);

    useLoader(experience.useOverviewMeta());

    // getting data for mindset table by Experience id and Mail file id
    const overviewStateData = experience.useOverviewData();

    // getting user businessUnits (mail files) information by the businessUnit id
    useEffect(() => {
      (async () => {
        if (businessUnitId) {
          const result = await dispatch(userClients.thunk.getById(businessUnitId));

          if (userClients.thunk.getById.fulfilled.match(result) && result.payload && result.payload.mailFile.length) {
            setMailFiles(result.payload.mailFile);
          }
        }
      })();
    }, [businessUnitId, dispatch]);

    // dispatching Experience overview data by Experience id and Mail field id
    useEffect(() => {
      if (experienceItem && experienceItem.id) {
        if (!selectValue) {
          dispatch(
            experience.thunk.getExperienceOverview({
              experience: experienceItem.id.toString(),
            }),
          );
        } else {
          dispatch(
            experience.thunk.getExperienceOverview({
              experience: experienceItem.id.toString(),
              mailFileId: selectValue,
            }),
          );
        }
      }
    }, [selectValue, experienceItem, dispatch]);

    useEffect(() => {
      if (overviewStateData) {
        const products = overviewStateData.products;

        // calculating subscribers count
        let subscribersResult = overviewStateData.defaultMindset?.audienceSize || 0;

        products.forEach((product) => {
          const mindsets = product.mindsets;
          Object.keys(mindsets).forEach((key) => {
            subscribersResult += mindsets[key].audienceSize;
          });
        });

        setSubscribersCount(subscribersResult);

        // setting Table legend items
        setTableLegendItems([
          {
            color: 'transparent',
            count: overviewStateData.counts[0],
            label: content.contentNeeded,
          },
          {
            color: 'green',
            count: overviewStateData.counts[1],
            label: content.contentAdded,
          },
        ]);
      }
    }, [overviewStateData, subscribersCount]);

    const handleFieldChange = useCallback(
      (event: ChangeEvent<{ value: unknown }>) => {
        const targetValue = event.target.value as string;
        setSelectValue(targetValue);
      },
      [setSelectValue],
    );

    return (
      <Accordion
        title={experienceItem?.name || content.moduleGroupOverview}
        subtitle={`${content.moduleGroupOverview} • ${format.number(subscribersCount)} ${content.subscribers} • ${
          experienceItem?.mindsets?.length ?? 0
        } ${content.treatments}`}
        icon={<Icon.Experience />}
        expanded={expanded}
        onChange={onChange}
        onEdit={onEdit}
      >
        <Box className={styles.content}>
          <Box className={styles.btnActions}>
            <Button variant="outlined" startIcon={<Icon.DocumentsOutline />} onClick={onMapContentClick}>
              {content.mapContent}
            </Button>
            <Button variant="outlined" startIcon={<Icon.Archive />} disabled onClick={onArchive}>
              {content.archive}
            </Button>
          </Box>
          <Box className={styles.selectActions}>
            <Box className={styles.mailFileSelectWrapper}>
              {mailFiles ? (
                <>
                  <Typography.Body noWrap>{content.displayCountsTreatmentRequestFile}</Typography.Body>
                  <Select
                    className={styles.mailFileSelect}
                    disableUnderline
                    uiType="primary"
                    placeholder={content.all}
                    onChange={handleFieldChange}
                    value={selectValue ? selectValue : ''}
                  >
                    <MenuItem>{content.viewAll}</MenuItem>
                    {mailFiles.map((mailFile) => (
                      <MenuItem key={mailFile.id} value={mailFile.id}>
                        {mailFile.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <Typography.Body noWrap>{content.noTreatmentRequestFiles}</Typography.Body>
              )}
            </Box>
            <TableLegend items={tableLegendItems} />
          </Box>
          {overviewStateData && (
            <MindsetTable
              cohorts={overviewStateData.cohorts}
              defaultMindset={overviewStateData.defaultMindset}
              products={overviewStateData.products}
              experienceId={overviewStateData.experienceId}
              useDroppable={true}
            />
          )}
        </Box>
      </Accordion>
    );
  },
);

ExperienceOverview.displayName = 'ExperienceOverview';
