import { makeStyles, createStyles } from '@material-ui/core/styles';

import { theme, variables } from '@styles';

/**
 * Disclosure component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    disclosureInfo: {
      padding: theme.spacing(0, 1.5),
    },

    caption: {
      fontWeight: variables.font.weight.regular,
      color: variables.color.primary.darkestGray,
      paddingTop: theme.spacing(2.25),
    },

    disclosureInfoInput: {
      paddingTop: theme.spacing(2.25),

      '& .MuiInputBase-root': {
        border: `${variables.borderWidth.md} solid ${variables.color.primary.mainPurple}`,
        borderRadius: 'unset',
        backgroundColor: variables.color.primary.white,
        color: variables.color.primary.darkestGray,
        fontWeight: variables.font.weight.regular,
        fontSize: variables.font.size.sm,
      },

      '&[data-has-error=true]': {
        '& .MuiInputBase-root': {
          border: `${variables.borderWidth.md} solid ${variables.color.semantic.negative}`,
          backgroundColor: variables.color.semantic.negativeLight,
        },
      },
    },
  }),
);
