import { createSlice } from '@reduxjs/toolkit';

import { MetaSchema } from '@modules';
import { useAppSelector } from '@store';

import * as utils from './Roles.utils';
import * as thunk from './Roles.thunk';
import { initialState } from './Roles.state';
import { reducers, extraReducers } from './Roles.reducer';
import { RolesAllData } from './Roles.schema';

export * from './Roles.schema';

export const roles = {
  utils,
  thunk,
  slice: createSlice({
    name: 'roles',
    initialState,
    reducers,
    extraReducers,
  }),
  useAllMeta: (): MetaSchema =>
    useAppSelector(
      ({
        roles: {
          all: { data, ...meta },
        },
      }) => meta,
    ),
  useAllData: (): RolesAllData => useAppSelector((root) => root.roles.all.data),
};
