import { DATA_FILLED_ATTRIBUTE, ModuleTemplateType } from "@routes/TemplateView";
import { SHOULD_UPDATE_JUST_SELECTED_TEMPLATE_EXPERIENCES_IDS } from "./Deployments.const";
import { Template, deployment, template } from "@modules";
import { doc, parse } from "@utils";

export const updateTemplateExperienceIds = async (tmplId: number | undefined, dispatch: any, deploymentEmailTemplates: Template[] | null) => {
  if (!SHOULD_UPDATE_JUST_SELECTED_TEMPLATE_EXPERIENCES_IDS) {
    return true;
  }

  const tmpl = deploymentEmailTemplates?.find((item) => item.id === tmplId);

  if (tmpl) {
    const tmplPositions = tmpl.positions || [];
    const dptPositions = tmplPositions.filter((pos) => !!pos.experience?.id);
    const dptExpsIds = tmplPositions.map((pos) => Number(pos.experience?.id)).filter((id) => !!id);
    const dptExpsCount = dptExpsIds.length;

    if (dptExpsCount !== 0) {
      const tmplDoc = parse.doc(tmpl.html);

      if (tmplDoc) {
        const tmplHoldsNodes = Array.from(tmplDoc.querySelectorAll(`[${DATA_FILLED_ATTRIBUTE}]`));
        const tmplExpsIds = tmplHoldsNodes.map((item) => +`${item.getAttribute(DATA_FILLED_ATTRIBUTE)}`);
        const countsEqual = dptExpsCount === tmplExpsIds.length;
        const someExpsMissed = dptExpsIds.some((dptExpId) => !tmplExpsIds.includes(dptExpId));

        if (countsEqual && someExpsMissed) {
          dptExpsIds.forEach((dptExpId, index) => {
            const holder = tmplHoldsNodes[index];

            if (holder) {
              holder.setAttribute(DATA_FILLED_ATTRIBUTE, `${dptExpId}`);

              const holderId = holder.getAttribute('id');

              if (holderId) {
                const pos = dptPositions[index];
                const modulesCount = pos?.experience?.modules?.length;

                if (pos && modulesCount) {
                  const type: ModuleTemplateType = modulesCount === 1 ? 'single' : 'group';
                  const oppositeType: ModuleTemplateType = type === 'single' ? 'group' : 'single';

                  if (holderId.includes(oppositeType)) {
                    const holderNewId = holderId.replace(oppositeType, type);

                    holder.setAttribute('id', holderNewId);
                  }
                }
              }
            }
          });

          const tmplUpdResult = await dispatch(
            template.thunk.update({
              id: tmplId!,
              html: `${doc.getDoctype(tmplDoc)}${tmplDoc.documentElement.innerHTML}`,
            }),
          );

          return template.thunk.update.fulfilled.match(tmplUpdResult);
        }
      }
    }

    return true;
  }

  return false;
};