import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Switcher component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginLeft: 0,
      marginRight: 0,
    },
    label: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.darkestGray,
      marginRight: theme.spacing(1),
    },
  }),
);
