export const MODULE_HTML_TEMPLATE = {
  page: {
    template: {
      name: 'template-base',
      type: 'basic',
      version: '2.0.0',
    },
    body: {
      container: { style: { 'background-color': '#FFFFFF' } },
      content: {
        computedStyle: { messageWidth: '500px' },
        style: {
          'font-family': 'Arial, Helvetica Neue, Helvetica, sans-serif',
        },
      },
    },
    rows: ['${rowData}'],
  },
};

export const MAIL_BUILDER_TEMPLATE_ROW = {
  container: {
    style: {
      'background-color': 'transparent',
      'background-image': 'none',
      'background-repeat': 'no-repeat',
      'background-position': 'top left',
    },
  },
  content: {
    style: {
      color: '#000000',
      width: '500px',
      'background-image': 'none',
      'background-repeat': 'no-repeat',
      'background-position': 'top left',
    },
    computedStyle: {
      rowColStackOnMobile: true,
      rowReverseColStackOnMobile: false,
    },
  },
  columns: [
    {
      style: {
        'background-color': 'transparent',
        'border-bottom': '0px solid transparent',
        'border-left': '0px solid transparent',
        'border-right': '0px solid transparent',
        'border-top': '0px solid transparent',
        'padding-bottom': '5px',
        'padding-left': '0px',
        'padding-right': '0px',
        'padding-top': '5px',
      },
      modules: [
        {
          type: 'mailup-bee-newsletter-modules-image',
          descriptor: {
            image: {
              alt: '${title}',
              src: '${image_url}',
              href: '${article_url}',
              width: '270px',
              height: '187px',
            },
            style: {
              width: '100%',
              'padding-top': '0px',
              'padding-right': '0px',
              'padding-bottom': '0px',
              'padding-left': '0px',
            },
            computedStyle: {
              class: 'center autowidth',
              width: '166.66666666666666px',
              hideContentOnMobile: false,
            },
          },
        },
        {
          type: 'mailup-bee-newsletter-modules-button',
          descriptor: {
            button: {
              label:
                '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 28px;" data-mce-style="font-size: 14px; line-height: 28px;"><p style="font-size: 16px; line-height: 32px; word-break: break-word;" data-mce-style="font-size: 16px; line-height: 32px;">Read More</p></div>',
              href: '${article_url}',
              style: {
                'font-family': 'inherit',
                'background-color': '#3AAEE0',
                'border-radius': '4px',
                'border-top': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
                color: '#ffffff',
                'line-height': '200',
                'padding-top': '5px',
                'padding-right': '5px',
                'padding-bottom': '5px',
                'padding-left': '5px',
                width: 'auto',
                'max-width': '100%',
              },
            },
            style: {
              'text-align': 'left',
              'padding-top': '10px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px',
            },
            computedStyle: {
              width: 89,
              height: 42,
              hideContentOnMobile: false,
            },
          },
        },
      ],
      'grid-columns': 4,
    },
    {
      style: {
        'background-color': 'transparent',
        'border-bottom': '0px solid transparent',
        'border-left': '0px solid transparent',
        'border-right': '0px solid transparent',
        'border-top': '0px solid transparent',
        'padding-bottom': '5px',
        'padding-left': '0px',
        'padding-right': '0px',
        'padding-top': '5px',
      },
      modules: [
        {
          type: 'mailup-bee-newsletter-modules-heading',
          descriptor: {
            heading: {
              title: 'h1',
              text: '<strong>${title}</strong>',
              style: {
                color: '#555555',
                'font-size': '23px',
                'font-family': 'inherit',
                'link-color': '#E01253',
                'line-height': '120%',
                'text-align': 'left',
                direction: 'ltr',
                'font-weight': 'normal',
                'letter-spacing': '0px',
              },
            },
            style: {
              width: '100%',
              'text-align': 'left',
              'padding-top': '0px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px',
            },
            computedStyle: {
              width: 52,
              height: 42,
            },
          },
        },
        {
          type: 'mailup-bee-newsletter-modules-text',
          descriptor: {
            text: {
              html: '<div class=",txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px;">${creator}</p></div>',
              style: {
                color: '#555555',
                'line-height': '120%',
                'font-family': 'inherit',
              },
              computedStyle: {
                linkColor: '#0068a5',
              },
            },
            style: {
              'padding-top': '10px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px',
            },
            computedStyle: {
              hideContentOnMobile: false,
            },
          },
        },
        {
          type: 'mailup-bee-newsletter-modules-text',
          descriptor: {
            text: {
              html: '<div class=",txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px;">${text}</p></div>',
              style: {
                color: '#555555',
                'line-height': '120%',
                'font-family': 'inherit',
              },
              computedStyle: {
                linkColor: '#0068a5',
              },
            },
            style: {
              'padding-top': '10px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px',
            },
            computedStyle: {
              hideContentOnMobile: false,
            },
          },
        },
      ],
      'grid-columns': 8,
    },
  ],
  type: 'two-columns-3-9-empty',
};
