import { base } from '@modules';
import { SignalLibrarySchema } from './SignalLibrary.schema';

export const initialState: SignalLibrarySchema = {
  fields: {
    ...base.state,
    data: null,
  },
  list: { ...base.state, data: { ...base.paginagedState } },
  options: { ...base.state, data: { ...base.paginagedState } },
};
