import { SVGProps } from 'react';

export const Mindset = ({ width = 16, height = 16, fill = '#494949' }: SVGProps<SVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 9.56703L10.6026 8.93097C10.3833 9.06801 10.25 9.30839 10.25 9.56703H11ZM4.32913 5.7392L4.93943 6.17513C5.02613 6.05376 5.07466 5.90929 5.07884 5.76019L4.32913 5.7392ZM2.56472 8.20938L3.17502 8.64531H3.17502L2.56472 8.20938ZM4.32744 9H5.07744C5.07744 8.58579 4.74166 8.25 4.32744 8.25V9ZM7 11.8182H7.75C7.75 11.404 7.41421 11.0682 7 11.0682V11.8182ZM12.3047 5.86364C12.3047 7.15642 11.6263 8.29135 10.6026 8.93097L11.3974 10.2031C12.8414 9.3009 13.8047 7.69505 13.8047 5.86364H12.3047ZM8.69103 2.25C10.6868 2.25 12.3047 3.86788 12.3047 5.86364H13.8047C13.8047 3.03945 11.5152 0.75 8.69103 0.75V2.25ZM5.07884 5.76019C5.13338 3.8125 6.72986 2.25 8.69103 2.25V0.75C5.91549 0.75 3.65663 2.96105 3.57943 5.71821L5.07884 5.76019ZM3.17502 8.64531L4.93943 6.17513L3.71883 5.30327L1.95442 7.77345L3.17502 8.64531ZM2.97158 8.25C3.17493 8.25 3.29321 8.47984 3.17502 8.64531L1.95442 7.77345C1.36346 8.60079 1.95487 9.75 2.97158 9.75V8.25ZM4.32744 8.25H2.97158V9.75H4.32744V8.25ZM5.07744 11.3182V9H3.57744V11.3182H5.07744ZM4.82744 11.0682C4.96552 11.0682 5.07744 11.1801 5.07744 11.3182H3.57744C3.57744 12.0085 4.13709 12.5682 4.82744 12.5682V11.0682ZM7 11.0682H4.82744V12.5682H7V11.0682ZM7.75 13.5V11.8182H6.25V13.5H7.75ZM7.5 13.25C7.63807 13.25 7.75 13.3619 7.75 13.5H6.25C6.25 14.1904 6.80964 14.75 7.5 14.75V13.25ZM10.5 13.25H7.5V14.75H10.5V13.25ZM10.25 13.5C10.25 13.3619 10.3619 13.25 10.5 13.25V14.75C11.1904 14.75 11.75 14.1904 11.75 13.5H10.25ZM10.25 9.56703V13.5H11.75V9.56703H10.25Z"
      fill={fill}
    />
  </svg>
);
