import { SideBarItemPage, SideBarItems } from './SideBar';

export type PrivatePagesAccessHook = {
  authenticating: boolean;
  authenticated: boolean;
  restricted: boolean;
  navItems: SideBarItems;
};

export type PrivatePagesAccessWall = Partial<Record<SideBarItemPage, boolean>>;

export enum PrivateNavItemValue {
  deployment = 'deployment',
  newDeployment = 'new_deployment',
  newProgram = 'new_program',
}

export type PrivateNavItemsHook = PrivatePagesAccessHook['navItems'];

export type PrivateSideToggleHook = {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};
