import { Box, Button } from '@material-ui/core';

import { content } from '@content';

import { ModuleHeaderProps } from './ModuleHeader.props';
import { useStyles } from './ModuleHeader.styles';

export const ModuleHeader = ({ style = {}, dirty = false, onSave }: ModuleHeaderProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={styles.moduleHeader} style={style}>
      <Button
        className={styles.save}
        variant="contained"
        color="primary"
        size="medium"
        disabled={!dirty}
        onClick={dirty ? onSave : undefined}
      >
        {content.saveChanges}
      </Button>
    </Box>
  );
};
