import { ReactNode } from 'react';

import { TableColumn, TableRecord } from '@components';

import { useStyles } from './Disclosure.styles';

export enum ColumnId {
  name = 'name',
  copy = 'copy',
  disc = 'disc',
  cont = 'cont',
  html = 'html',
  edit = 'edit',
}

export type Col = TableColumn<ColumnId>;

export type Rec = TableRecord & Record<ColumnId, ReactNode>;

export type Styles = ReturnType<typeof useStyles>;
