import { SVGProps } from 'react';

export const PeopleCircleOutline = ({
  width = 16,
  height = 16,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8 14.5C4.41594 14.5 1.5 11.5841 1.5 8C1.5 4.41594 4.41594 1.5 8 1.5C11.5841 1.5 14.5 4.41594 14.5 8C14.5 11.5841 11.5841 14.5 8 14.5ZM8 2.5C4.96875 2.5 2.5 4.96875 2.5 8C2.5 11.0312 4.96875 13.5 8 13.5C11.0312 13.5 13.5 11.0328 13.5 8C13.5 4.96719 11.0328 2.5 8 2.5Z"
      fill={fill}
    />
    <path
      d="M10.1146 9.125C9.57089 9.125 9.04558 8.88375 8.63527 8.44594C8.22942 8.00572 7.98676 7.43967 7.94777 6.84219C7.90183 6.19531 8.10027 5.6 8.50652 5.16656C8.91277 4.73313 9.48058 4.5 10.1146 4.5C10.744 4.5 11.3137 4.73813 11.7187 5.17063C12.1237 5.60313 12.3271 6.20187 12.2812 6.84281C12.2415 7.43993 11.9989 8.00555 11.5937 8.44594C11.1831 8.88375 10.6581 9.125 10.1146 9.125ZM5.11933 9.23C4.18933 9.23 3.37151 8.37031 3.29651 7.31344C3.25808 6.77219 3.4262 6.27187 3.77058 5.90469C4.11495 5.5375 4.58995 5.34219 5.11902 5.34219C5.64808 5.34219 6.12277 5.54344 6.46495 5.90906C6.80714 6.27469 6.98058 6.77844 6.94152 7.31531C6.86527 8.37063 6.04777 9.23 5.11933 9.23ZM13.1365 11.1025C13.0868 10.9556 12.9659 10.7991 12.7234 10.6506C11.9903 10.2028 11.0884 9.96594 10.1146 9.96594C9.15933 9.96594 8.23245 10.2128 7.50433 10.6613C6.68402 11.1666 6.13277 11.9034 5.91058 12.7919C5.85808 13.0009 5.78152 13.39 5.86339 13.6078C7.17729 14.1075 8.62483 14.1304 9.9539 13.6726C11.283 13.2148 12.4093 12.3053 13.1368 11.1025H13.1365ZM5.11339 12.5428C5.33433 11.6613 5.80464 10.9262 6.53433 10.3319C6.56883 10.3034 6.59506 10.2662 6.61029 10.2242C6.62552 10.1821 6.62919 10.1368 6.62093 10.0928C6.61266 10.0489 6.59275 10.0079 6.56329 9.9743C6.53382 9.94066 6.49587 9.91554 6.45339 9.90156C6.07839 9.78188 5.65027 9.71781 5.11933 9.71781C4.37495 9.71781 3.58464 9.91938 2.98995 10.2856C2.8212 10.3897 2.65558 10.4297 2.52808 10.4653C3.03997 11.5967 3.88889 12.5423 4.9587 13.1728L5.01464 13.1684C5.02687 12.9572 5.05995 12.7476 5.11339 12.5428Z"
      fill={fill}
    />
  </svg>
);
