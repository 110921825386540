import { BaseSchema } from '@modules';

export enum Permission {
  /**
   * general
   */
  addUser = 'USER_MANAGE',
  addEnterprise = 'ENTERPRISE_MANAGE',
  createDeployment = 'DEPLOYMENT_CREATE',
  addClient = 'CLIENT_MANAGE',
  addProgram = 'PROGRAM_MANAGE',
  addCohort = 'STAGE_MANAGE',
  uploadFiles = 'FILES_UPLOAD',
  /**
   * module
   */
  viewPersonalizationStation = 'PERSONALIZATION_STATION_VIEW',
  editPersonalizationStation = 'PERSONALIZATION_STATION_EDIT',
  viewContentStation = 'CONTENT_STATION_VIEW',
  editContentStation = 'CONTENT_STATION_EDIT',
  viewBeeBuilder = 'BEE_BUILDER_VIEW',
  editBeeBuilder = 'BEE_BUILDER_EDIT',
  viewHtmlStation = 'HTML_STATION_VIEW',
  editHtmlStation = 'HTML_STATION_EDIT',
  viewDisclosureStation = 'DISCLOSURE_STATION_VIEW',
  editDisclosureStation = 'DISCLOSURE_STATION_EDIT',
  viewLiveContentStation = 'LIVECONTENT_STATION_VIEW',
  editLiveContentStation = 'LIVECONTENT_STATION_EDIT',
  viewModuleLibrary = 'MODULE_LIBRARY_VIEW',
  createModule = 'MODULE_CREATE',
  copyModule = 'MODULE_COPY',
  /**
   * experience
   */
  viewExperienceBuilder = 'EXPERIENCE_BUILDER_VIEW',
  editExperienceBuilder = 'EXPERIENCE_BUILDER_EDIT',
  deleteExperienceBuilder = 'EXPERIENCE_BUILDER_DELETE',
  viewAnalytics = 'ANALYTIC_VIEW',

  /**
   * datacenter
   */
  uploadSignalDatacenter = 'DATACENTER_SIGNAL_UPLOAD',
  uploadRecommenderFileDatacenter = 'DATACENTER_RECOMMENDER_FILE_UPLOAD',
  uploadMailPlanFileDatacenter = 'DATACENTER_MAILPLAN_FILE_UPLOAD',
  uploadMailFileDatacenter = 'DATACENTER_MAIL_FILE_UPLOAD',
  configureApiDatacenter = 'DATACENTER_API_CONFIGURATION',
  uploadEmailActivityDatacenter = 'DATACENTER_EMAIL_ACTIVITY_UPLOAD',

  /**
   * esp
   */
  auditDeployment = 'DEPLOYMENT_AUDIT',
  viewEsp = 'ESP_VIEW',
  publishEsp = 'ESP_PUBLISH',
}

export type PermissionKey = keyof typeof Permission;

export type GeneralPermission =
  | Permission.addUser
  | Permission.addEnterprise
  | Permission.createDeployment
  | Permission.addClient
  | Permission.addProgram
  | Permission.addCohort
  | Permission.uploadFiles;

export type ModulePermission =
  | Permission.viewPersonalizationStation
  | Permission.editPersonalizationStation
  | Permission.viewContentStation
  | Permission.editContentStation
  | Permission.viewBeeBuilder
  | Permission.editBeeBuilder
  | Permission.viewHtmlStation
  | Permission.editHtmlStation
  | Permission.viewDisclosureStation
  | Permission.editDisclosureStation
  | Permission.viewLiveContentStation
  | Permission.editLiveContentStation
  | Permission.viewModuleLibrary
  | Permission.createModule
  | Permission.copyModule;

export type ExperiencePermission =
  | Permission.viewExperienceBuilder
  | Permission.editExperienceBuilder
  | Permission.deleteExperienceBuilder
  | Permission.viewAnalytics;

export type DatacenterPermission =
  | Permission.uploadSignalDatacenter
  | Permission.uploadRecommenderFileDatacenter
  | Permission.uploadMailPlanFileDatacenter
  | Permission.uploadMailFileDatacenter
  | Permission.configureApiDatacenter
  | Permission.uploadEmailActivityDatacenter;

export type EspPermission = Permission.auditDeployment | Permission.viewEsp | Permission.publishEsp;

export type RoleGroupedPermissions = {
  general: GeneralPermission[];
  module: ModulePermission[];
  experience: ExperiencePermission[];
  datacenter: DatacenterPermission[];
  esp: EspPermission[];
};

export type RoleName = 'ROLE_SUPER_ADMIN' | 'ROLE_ADMIN' | 'ROLE_EDITOR';

export type Role = {
  id: number;
  name: RoleName;
  groupedPermissions: RoleGroupedPermissions;
  assignedUsersList: string[];
  canManageRoles?: boolean;
};

export type RoleGroupKey = keyof RoleGroupedPermissions;

export type RolesAllData = Role[];

export type RolesSchema = {
  all: BaseSchema<RolesAllData>;
};
