import {
  EMAIL_REGEXP,
  URL_REGEXP,
  PASSWORD_MIN_LENGTH,
  CSV_FILE_TYPES,
  ARCHIVE_FILE_TYPES,
  HTML_FILE_TYPES,
  URL_SECURE_REGEXP,
  HTML_COMMENT_REGEXP,
  IMAGE_FILE_TYPES,
  JS_FILE_TYPES,
  NUMERIC_REGEXP,
} from './validate.const';

const isString = (value: unknown): boolean => typeof value === 'string';

export const email = (value: string | unknown): boolean =>
  isString(value) && EMAIL_REGEXP.test((value as string).toLowerCase());

export const password = (value: string | unknown): boolean =>
  isString(value) && (value as string).length >= PASSWORD_MIN_LENGTH;

export const url = (value: string | unknown, secureOnly?: boolean): boolean =>
  isString(value) && (secureOnly ? URL_SECURE_REGEXP : URL_REGEXP).test(value as string);

export const html = (value: string | unknown): boolean => {
  if (!isString(value)) {
    return false;
  }

  const htmlStr = `${value}`.replaceAll(HTML_COMMENT_REGEXP, '').replaceAll('\n', '').replaceAll('\t', '').trim();

  try {
    const doc = new DOMParser().parseFromString(htmlStr, 'text/html');

    return doc.body.innerHTML === htmlStr;
  } catch (exc) {
    return false;
  }
};

const isFileTypeRefed = (fileType: string, refFileTypes: string[]): boolean => refFileTypes.includes(fileType);

export const isCsvFile = (fileType: string): boolean => isFileTypeRefed(fileType, CSV_FILE_TYPES);

export const isArchiveFile = (fileType: string): boolean => isFileTypeRefed(fileType, ARCHIVE_FILE_TYPES);

export const isHtmlFile = (fileType: string): boolean => isFileTypeRefed(fileType, HTML_FILE_TYPES);

export const isImageFile = (fileType: string): boolean => isFileTypeRefed(fileType, IMAGE_FILE_TYPES);

export const isJsfile = (fileType: string): boolean => isFileTypeRefed(fileType, JS_FILE_TYPES);

export const isNumeric = (value: string) => NUMERIC_REGEXP.test(value);
