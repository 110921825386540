import { FC, ReactElement } from 'react';
import { MenuItem } from '@material-ui/core';

import { Select } from '@components';
import { content } from '@content';
import { MailPartDropdownProps } from './MailPartDropdown.props';
import { useStyles } from './MailPartDropdown.styles';

const MailPartDropdown: FC<MailPartDropdownProps> = ({
  mailParts,
  currentMailPart,
  onChange,
  status,
}): ReactElement => {
  const styles = useStyles();
  return (
    <Select
      uiType="secondary"
      value={mailParts.length && currentMailPart ? currentMailPart : ''}
      onChange={onChange}
      disabled={!mailParts.length}
      className={styles.input}
      placeholder={!mailParts.length && status === 'success' ? content.noPositions : content.position}
      classNamePlaceholder={styles.placeholder}
    >
      {mailParts.map((mailPart) => (
        <MenuItem key={mailPart} value={mailPart}>
          {mailPart}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MailPartDropdown;
