import { createSlice } from '@reduxjs/toolkit';

import { MetaSchema } from '@modules';
import { useAppSelector } from '@store';

import { extraReducers, reducers } from './DataVisualization.reducer';
import {
  DataVisualizationEntity,
  DataVisualizationImagesResponseSchema,
  DataVisualizationItemSchema,
  DataVisualizationListDataSchema,
  SignalListDataSchema
} from './DataVisualization.schema';
import { initialState } from './DataVisualization.state';
import * as thunk from './DataVisualization.thunk';

export * from './DataVisualization.schema';

const slice = createSlice({
  name: 'dataVisualization',
  initialState,
  reducers,
  extraReducers,
});

export const dataVisualization = {
  thunk,
  slice,
  actions: slice.actions,
  useDataVisualizationListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          dataVisualizationList: { data, ...meta },
        },
      }) => meta,
    ),
  useDataVisualizationListData: (): DataVisualizationListDataSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          dataVisualizationList: { data },
        },
      }) => data,
    ),
  useSignalListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          signalList: { data, ...meta },
        },
      }) => meta,
    ),
  useSignalListData: (): SignalListDataSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          signalList: { data },
        },
      }) => data,
    ),
  useDVMeta: (): MetaSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          dataVisualization: { data, ...meta },
        },
      }) => meta,
    ),
  useDVData: (): DataVisualizationItemSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          dataVisualization: data,
        },
      }) => data,
    ),
  useDataVisualizationDeploymentsMeta: (): MetaSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          deployments: { data, ...meta },
        },
      }) => meta,
    ),
  useDataVisualizationDeploymentsData: (): SignalListDataSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          deployments: { data },
        },
      }) => data,
    ),
  useEntityMeta: (): MetaSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          entity: { data, ...meta },
        },
      }) => meta,
    ),
  useEntityData: (): DataVisualizationEntity => useAppSelector((root) => root.dataVisualization.entity.data),
  useDataVisualizationImagesMeta: (): MetaSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          dataVisualizationimages: { data, ...meta },
        },
      }) => meta,
    ),
  useDataVisualizationImagesData: (): DataVisualizationImagesResponseSchema =>
    useAppSelector(
      ({
        dataVisualization: {
          dataVisualizationimages: { data },
        },
      }) => data,
    ),
};
