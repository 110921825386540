import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const moduleTemplate = {
  display: 'flex',
  'flex-direction': 'column',
  alignItems: 'flex-start',
};

const upload = {
  marginBottom: variables.gap.lg,
  padding: `0.4rem ${variables.gap.lg}`,
  width: '100%',
};

const content = {
  marginRight: `-${variables.gap.sm}`,
  paddingRight: variables.gap.xs,
  'overflow-y': 'scroll',
  'overflow-x': 'hidden',
};

const item = {
  '&:not(:first-child)': {
    marginTop: variables.gap.sm,
  },
};

const rect = {
  ...({ position: 'relative' } as any), // eslint-disable-line @typescript-eslint/no-explicit-any
  display: 'flex',
  width: '20.8rem',
  height: '12rem',
  background: variables.color.secondary.white,
  border: `${variables.borderWidth.sm} solid ${variables.color.tertiary.gray}`,
  borderRadius: variables.borderRadius.sm,

  '&[data-selected="true"]': {
    overflow: 'hidden',
    background: 'transparent',
    borderColor: variables.color.primary.lightPurple,
    borderLeftWidth: variables.borderWidth.lg,

    '&[data-imaged="true"]': {
      height: 'auto',
    },
  },
};

const image = {
  width: '100%',
};

const remove = {
  ...({ position: 'absolute' } as any), // eslint-disable-line @typescript-eslint/no-explicit-any
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(83, 86, 90, 0.6)',
  color: variables.color.primary.white,
  textTransform: 'none',
  textDecoration: 'underline',
  opacity: variables.opacity.none,
  transition: 'all 0.4s',
  '&:hover': {
    background: 'rgba(83, 86, 90, 0.6)',
    opacity: variables.opacity.full,
    textDecoration: 'underline',
    fontWeight: variables.font.weight.semiBold,
    fontSize: variables.font.size.md,
    lineHeight: variables.lineHeight.md,
    letterSpacing: variables.letterSpacing.sm,
  },
};

const removing = {
  padding: '4rem',
};

const warning = {
  height: '4rem',
};

const question = {
  height: '2.7rem',
  marginTop: variables.gap.xl,
};

const options = {
  marginTop: variables.gap.xl,
};

const option = {
  padding: 0,
  height: '4.5rem',
};

const cancel = {
  ...option,
  width: '11.6rem',
};

const confirm = {
  ...option,
  width: '14.3rem',
  marginLeft: variables.gap.lg,
};

export const useStyles = makeStyles(() =>
  createStyles({
    moduleTemplate,
    upload,
    content,
    item,
    rect,
    image,
    remove,
    removing,
    warning,
    question,
    options,
    cancel,
    confirm,
  }),
);
