import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * DashboardHeader component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      background: variables.color.primary.darkestPurple,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 3),
    },
    topLine: {
      height: '0.4rem',
      background: variables.color.primary.mainPurple,
      borderRadius: theme.spacing(0.75, 0.75, 0, 0),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1.5),
      color: variables.color.primary.white,
    },
    subtitle: {
      marginLeft: theme.spacing(2),
      color: variables.color.primary.mediumPurple,
    },
  }),
);
