import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getDeploymentsByProgram } from './DeploymentsByProgram.thunk';
import { initialState } from './DeploymentsByProgram.state';
import { DeploymentsByProgramSchema } from './DeploymentsByProgram.schema';

export const reducers = {
  resetDeploymentsByProgram: (state: DeploymentsByProgramSchema): void => {
    state.requestId = '';
    state.data = initialState.data;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<DeploymentsByProgramSchema>): void => {
  builder.addCase(getDeploymentsByProgram.pending, (state, action) => {
    if (state.status !== 'loading') {
      state.requestId = action.meta.requestId;
      state.status = 'loading';
      state.error = initialState.error;
    }
  });

  builder.addCase(getDeploymentsByProgram.fulfilled, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = '';
      state.status = 'success';
      if (action.payload) {
        state.data = action.payload;
      }
    }
  });

  builder.addCase(getDeploymentsByProgram.rejected, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = '';
      state.status = 'error';
      state.error = {
        message: action.payload?.message || action.error.message,
      };
      state.data = initialState.data;
    }
  });
};
