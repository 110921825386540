import { ModulesSearchParams } from '@modules';

export const ITEMS_PER_PAGE = 8;

export const SEARCH_QUERY_INIT: ModulesSearchParams = {
  businessUnitId: NaN,
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  personalized: false,
  keyword: '',
  product: '',
  cohort: '',
  journey: '',
  startDate: null,
  endDate: null,
  searchType: 'deployment',
};
