import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    libraryContainer: {
      paddingTop: variables.gap.sm,
    },
    modulesGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(21.3rem, 1fr))',
      gap: variables.gap.lg,
    },
  }),
);
