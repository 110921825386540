import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * RightPanelLayout component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    rightPanel: {
      right: 0,
      width: '45rem',
      height: '63.7rem',
      backgroundColor: variables.color.primary.white,
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      zIndex: 1,
      borderTop: 'unset',

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        width: '27rem',
      },
    },
  }),
);
