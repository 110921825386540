import { FC, ReactElement } from 'react';
import { BarSingleChart, Typography } from '@components';
import { Box, Typography as MuiTypography, Grid, Divider } from '@material-ui/core';

import { content } from '@content';
import { format } from '@utils';

import { BarGroupedChartItem, BarGroupedChartProps } from './BarGroupedChart.props';
import { useStyles } from './BarGroupedChart.styles';

export const BarGroupedChart: FC<BarGroupedChartProps> = ({ data, itemsWithColors, sortValue }): ReactElement => {
  const styles = useStyles();

  const getColor = (item: BarGroupedChartItem) => {
    if (itemsWithColors) {
      const resultElement = itemsWithColors.find((el) => el.elementId === item.elementId);
      if (resultElement) {
        return resultElement?.color;
      }
    }
    return 'gray';
  };

  const highlightItem = (item: BarGroupedChartItem) => {
    const nodePieChart = document.getElementsByClassName('PieChart');

    if (nodePieChart) {
      Array.prototype.forEach.call(nodePieChart, (el: Element) => {
        const nodes = el.getElementsByTagName('path');

        for (let index = 0; index < nodes.length; index += 1) {
          const node = nodes[index];

          if (node) {
            const fill = node.getAttribute('fill');
            const colorItem = getColor(item);

            if (colorItem !== fill) {
              node.style.opacity = '0.5';
            }
          }
        }
      });
    }

    const nodeBarChart = document.getElementsByClassName('BarGroupedChart');

    if (nodeBarChart) {
      Array.prototype.forEach.call(nodeBarChart, (el: Element) => {
        const nodes = el.getElementsByTagName('rect');

        for (let index = 0; index < nodes.length; index += 1) {
          const node = nodes[index];

          if (node) {
            const id = node.getAttribute('id');

            if (id && item.elementId !== id) {
              node.style.opacity = '0.5';
            }
          }
        }
      });
    }
  };

  const grayOutItem = () => {
    const nodePieChart = document.getElementsByClassName('PieChart');

    if (nodePieChart) {
      Array.prototype.forEach.call(nodePieChart, (el: Element) => {
        const nodes = el.getElementsByTagName('path');

        for (let index = 0; index < nodes.length; index += 1) {
          const node = nodes[index];

          if (node) {
            node.style.opacity = '1';
          }
        }
      });
    }

    const nodeBarChart = document.getElementsByClassName('BarGroupedChart');

    Array.prototype.forEach.call(nodeBarChart, (el: Element) => {
      const nodes = el.getElementsByTagName('rect');

      for (let index = 0; index < nodes.length; index += 1) {
        const node = nodes[index];

        if (node) {
          node.style.opacity = '1';
        }
      }
    });
  };

  const renderItemsByCohort = () => {
    if (data.cohorts) {
      return data.cohorts.map((item) => (
        <Grid container key={item.cohortName}>
          <Grid item className={styles.gridItem}>
            <MuiTypography className={styles.label}>{item.cohortName}</MuiTypography>
          </Grid>
          {item.items.map((product, index) => (
            <Grid container key={product.elementId}>
              <Grid item className={styles.gridItemTypography}>
                <Typography.Caption className={styles.gridItemTypographyCaption}>{product.label}</Typography.Caption>
              </Grid>
              <Grid item className={styles.gridItemBarChart}>
                <BarSingleChart
                  data={[product]}
                  color={getColor(product)}
                  highlightItem={() => highlightItem(product)}
                  grayOutItem={grayOutItem}
                />
              </Grid>
              {item.items.length === index + 1 ? <Divider className={styles.divider} /> : null}
            </Grid>
          ))}
        </Grid>
      ));
    }
    return null;
  };

  const renderItemsByProduct = () => {
    if (data.products) {
      return data.products.map((item) => (
        <Grid container key={item.productName}>
          <Grid item className={styles.gridItem}>
            <MuiTypography className={styles.label}>{item.productName}</MuiTypography>
          </Grid>
          {item.items.map((cohort, index) => (
            <Grid container key={cohort.elementId}>
              <Grid item className={styles.gridItemTypography}>
                <Typography.Caption className={styles.gridItemTypographyCaption}>{cohort.label}</Typography.Caption>
              </Grid>
              <Grid item className={styles.gridItemBarChart}>
                <BarSingleChart
                  data={[cohort]}
                  color={getColor(cohort)}
                  highlightItem={() => highlightItem(cohort)}
                  grayOutItem={grayOutItem}
                />
              </Grid>
              {item.items.length === index + 1 ? <Divider className={styles.divider} /> : null}
            </Grid>
          ))}
        </Grid>
      ));
    }
    return null;
  };

  const renderItemsByOverall = () => {
    if (data.items) {
      return data.items.map((item) => (
        <Grid container key={item.elementId}>
          <Grid container>
            <Grid item className={styles.gridItemTypography}>
              <Typography.Caption className={styles.gridItemTypographyCaption}>
                {`${item.productName} / ${item.cohortName}`}
              </Typography.Caption>
            </Grid>
            <Grid item className={styles.gridItemBarChart}>
              <BarSingleChart
                data={[item]}
                color={getColor(item)}
                highlightItem={() => highlightItem(item)}
                grayOutItem={grayOutItem}
              />
            </Grid>
          </Grid>
        </Grid>
      ));
    }
    return null;
  };

  return (
    <div className="BarGroupedChart">
      <Box className={styles.totalCtirContainer}>
        <Typography.Caption>{content.totalCtir}</Typography.Caption>
        <MuiTypography className={styles.totalCtirValue}>{format.percent(data.total)}</MuiTypography>
      </Box>
      <Box className={styles.innerScrollContainer}>
        <Typography.Headline className={styles.ctirTitle}>{`${content.ctir} %`}</Typography.Headline>
        {sortValue === 'cohort' && renderItemsByCohort()}
        {sortValue === 'product' && renderItemsByProduct()}
        {sortValue === 'overall' && renderItemsByOverall()}
      </Box>
    </div>
  );
};
