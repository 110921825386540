import { useState, useEffect, memo } from 'react';
import { Box, ButtonBase } from '@material-ui/core';

import { Icon } from '@components';
import { textTemplate, format } from '@utils';
import { content } from '@content';
import { deployment, SmartContentFileStatus } from '@modules';
import { useStyles } from './SmartContentFileAlert.styles';

export const SmartContentFileAlert = memo((): JSX.Element | null => {
  const styles = useStyles();
  const [status, setStatus] = useState('');
  const dep = deployment.useDeploymentData();

  const handleClose = () => setStatus('');

  useEffect(() => {
    if (!dep?.smartContentFileStatus) {
      setStatus('');
      return;
    }

    switch (true) {
      case SmartContentFileStatus.completed === dep.smartContentFileStatus:
        setStatus(
          textTemplate(content.smartContentFileSuccess, { value: format.number(dep.smartContentFileProcessed) }),
        );
        break;
      case SmartContentFileStatus.failed === dep.smartContentFileStatus:
        setStatus(content.smartContentFileFailed);
        break;
      case dep.smartContentFileRowsCount !== 0:
        setStatus(
          `${textTemplate(content.smartContentFileTransmitting, {
            espType: dep.businessUnit.espType,
          })} <br /><br /> ${textTemplate(content.smartContentFileTransmittingCount, {
            rowsCount: format.number(dep.smartContentFileRowsCount),
            complete: Math.round((dep.smartContentFileProcessed / dep.smartContentFileRowsCount) * 100),
          })}`,
        );
        break;
      default:
        setStatus(textTemplate(content.smartContentFileTransmitting, { espType: dep.businessUnit.espType }));
    }
  }, [
    dep?.smartContentFileProcessed,
    dep?.smartContentFileStatus,
    dep?.smartContentFileRowsCount,
    dep?.businessUnit?.espType,
  ]);

  return status ? (
    <Box className={styles.smartContentFileAlert}>
      <Box className={styles.statusAlert}>
        <Box>
          <Icon.Bell />
        </Box>
        <Box className={styles.statusAlertText}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: status }} />
        </Box>
        <Box>
          <ButtonBase onClick={handleClose}>
            <Icon.Close width={20} height={20} stroke="#8C8F96" strokeWidth={6} />
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  ) : null;
});

SmartContentFileAlert.displayName = 'SmartContentFileAlert';
