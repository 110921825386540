import { useCallback, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';

import { Icon, Typography, WarningMark, Dropdown } from '@components';
import { searchQuery, useHistory, useLoader } from '@utils';
import { businessUnitDeployments, audit } from '@modules';
import { content } from '@content';
import { useAppDispatch } from '@store';
import { DeploymentPicker } from '@views';
import { variables } from '@styles';

import { Disclosure, DisclosureProps } from './Disclosure';
import { Subscriber } from './Subscriber';

import { ITEMS_PER_PAGE } from './Audit.const';
import { useStyles } from './Audit.styles';
import { TabsTypes } from './Audit.types';
import { TabPanel } from './TabPanel';

export function Audit(): JSX.Element {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const history = useHistory();

  const [tab, setTab] = useState<TabsTypes>('disclosure');

  const [businessUnit, deployment] = searchQuery.useMutualParams('businessUnit', 'deployment');

  const { data: deployments, ...deploymentsMeta } = businessUnitDeployments.useNonArchived();
  const { data: disclosures, ...disclosureMeta } = audit.useDisclosure();
  const {
    data: { total: nullSubscriberTotal },
    ...nullSubscriberMeta
  } = audit.useNullSubscriber();
  const currentDeployment = deployments.find((dep) => dep.id === deployment);

  useLoader(deploymentsMeta, disclosureMeta, nullSubscriberMeta);

  const reloadWithDeployment = useCallback(
    (nextDepId: number) => {
      history.push('auditAndQa', { deployment: nextDepId });
    },
    [history],
  );

  const handleChangeTab = (event: React.ChangeEvent<unknown>, value: TabsTypes) => setTab(value);

  const handleContentMapExport = useCallback(() => {
    dispatch(audit.thunk.exportContentMapExcelFile(deployment));
  }, [dispatch, deployment]);

  const handleRecordEditUrlRetrieve = useCallback<DisclosureProps['getEditValue']>(
    (id) => history.getUrl('contentCenter', { id }),
    [history],
  );

  useEffect(() => {
    if (deployments.length && !currentDeployment) {
      const [nextDep] = deployments;
      reloadWithDeployment(nextDep.id);
    }
  }, [reloadWithDeployment, deployments, currentDeployment]);

  useEffect(() => {
    if (!businessUnit) {
      return history.push('dashboard');
    }
    const promise = dispatch(businessUnitDeployments.thunk.getNonArchived(businessUnit));

    return () => {
      promise.abort();
      dispatch(audit.actions.resetDisclosure());
      dispatch(audit.actions.resetNullSubscriber());
    };
  }, [dispatch, history, businessUnit]);

  return (
    <>
      <Box className={styles.heading}>
        <DeploymentPicker
          value={deployments.length && currentDeployment ? deployment : 0}
          items={deployments}
          onChange={reloadWithDeployment}
        />
        <Dropdown
          title={content.actions}
          disabled={!currentDeployment}
          options={[
            {
              label: content.exportContentMapExcel,
              onClick: handleContentMapExport,
            },
          ]}
        />
      </Box>
      <Tabs value={tab} onChange={handleChangeTab} aria-label="audit tabs" TabIndicatorProps={{ hidden: true }}>
        <Tab
          label={
            <Box className={styles.tabLabelBox}>
              {disclosureMeta.status !== 'loading' &&
                (disclosures.total === 0 ? (
                  <Icon.ExclamationMark className={styles.tabMarkSuccess} />
                ) : (
                  <Icon.ExclamationMark fill={variables.color.secondary.orange} />
                ))}
              <Typography.Headline className={styles.tabLabel} component="span">
                {content.contentDisclosureAudit}
              </Typography.Headline>
            </Box>
          }
          value="disclosure"
          className={styles.tab}
        />
        <Tab
          label={
            <Box className={styles.tabLabelBox}>
              {nullSubscriberMeta.status !== 'loading' &&
                (nullSubscriberTotal === 0 ? (
                  <Icon.ExclamationMark className={styles.tabMarkSuccess} />
                ) : (
                  <WarningMark title={content.addContent} />
                ))}
              <Typography.Headline className={styles.tabLabel} component="span">
                {content.defaultCohortTab}
              </Typography.Headline>
            </Box>
          }
          value="nullSubscriber"
          className={styles.tab}
        />
      </Tabs>
      <TabPanel tab="disclosure" active={tab}>
        <Disclosure deployment={deployment} itemsPerPage={ITEMS_PER_PAGE} getEditValue={handleRecordEditUrlRetrieve} />
      </TabPanel>
      <TabPanel tab="nullSubscriber" active={tab}>
        <Subscriber deployment={deployment} itemsPerPage={ITEMS_PER_PAGE} getEditValue={handleRecordEditUrlRetrieve} />
      </TabPanel>
    </>
  );
}
