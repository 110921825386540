import { memo, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Typography as MuiTypography } from '@material-ui/core';
import { content } from '@content';

import { Label, Menu, Icon, MenuOptions, Typography, OptionItem } from '@components';
import { func } from '@utils';

import { useRuleValueStyles } from './Rules.styles';
import { RuleValueProps } from './Rules.types';

export const RuleValue = memo(
  ({
    value,
    options,
    handleMenuClick,
    children,
    onSave = func.nop,
    className = '',
    type = '',
  }: RuleValueProps): JSX.Element => {
    const styles = useRuleValueStyles();
    const [isEdit, setEdit] = useState(false);
    const [option, setOption] = useState<OptionItem | undefined>(
      value && typeof value === 'string' ? { id: value.toString(), label: value.toString(), value } : undefined,
    );

    useEffect(() => {
      if (value && typeof value === 'string') {
        setOption({ id: value.toString(), label: value.toString(), value });
      } else {
        setOption(undefined);
      }
    }, [value]);

    const handleEditClick = useCallback(() => setEdit(!isEdit), [isEdit, setEdit]);

    const handleSave = useCallback(() => {
      if (option) {
        onSave(option.value);
      }
      handleEditClick();
    }, [option, handleEditClick, onSave]);

    const handleChange = useCallback(
      (item: OptionItem) => {
        if (handleMenuClick) {
          handleMenuClick(item);
        }
        setOption(item);
      },
      [setOption, handleMenuClick],
    );

    const getLabel = () => {
      if (value) {
        if (typeof value === 'object') {
          const { from, to, before, on, after } = value;

          if (from && to) return `${from} to ${to}`;
          if (before) return before;
          if (on) return on;
          if (after) return after;
        }
        return value;
      } else if (option?.label) {
        return option.label;
      }
    };

    return (
      <Box className={`${styles.ruleValue} ${isEdit ? className : ''}`}>
        {isEdit && options && !children && (
          <Box className={styles.row}>
            <Menu options={options as MenuOptions} onClick={handleChange}>
              <Box className={styles.input}>
                <Typography.Body className={styles.inputText}>{option?.label || value}</Typography.Body>
                {type === 'signal' ? <Icon.CloseOutline /> : <Icon.ChevronDown />}
              </Box>
            </Menu>
            <IconButton className={styles.icon} onClick={handleSave}>
              <Icon.Checkmark />
            </IconButton>
          </Box>
        )}
        {isEdit && children && (
          <Box className={styles.row}>
            {children}
            <IconButton className={styles.icon} onClick={handleSave}>
              <Icon.Checkmark />
            </IconButton>
          </Box>
        )}

        {!isEdit && (
          <Box onClick={handleEditClick}>
            {type === 'required' ? (
              <MuiTypography className={value ? styles.hasRule : styles.notRule}>
                {value ? content.requiredRule : content.notRequiredRule}
              </MuiTypography>
            ) : (
              <Label variant="code" color="highlighted" className={styles.pill}>
                {getLabel()}
              </Label>
            )}
          </Box>
        )}
      </Box>
    );
  },
);

RuleValue.displayName = 'RuleValue';
