import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    adminClients: {
      padding: theme.spacing(3),
      flex: '1 1 auto',
      backgroundColor: variables.color.primary.white,
      borderTopRightRadius: variables.borderRadius.sm,
      borderBottomRightRadius: variables.borderRadius.sm,
    },
  }),
);
