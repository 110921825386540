import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { ModuleDisclosureStation, ModuleInfoPayload, ModuleInfoState } from '@modules';

import {
  getPopular,
  search,
  clone,
  duplicate,
  getModuleInfo,
  create,
  refreshArticles,
  resetModuleTemplateContent,
  renameModuleTemplate,
  replaceModuleDataVisualizationStation,
  checkModuleName,
  replaceModuleDisclosureStation,
} from './Modules.thunk';
import { initialState } from './Modules.state';
import { ModulesSchema, SearchModule } from './Modules.schema';

export const reducers = {
  resetLibrary: (state: ModulesSchema): void => {
    state.library = initialState.library;
  },
  updateLibraryItemDisclosure: (
    state: ModulesSchema,
    action: PayloadAction<{ id: number; moduleDisclosureStation: ModuleDisclosureStation }>,
  ): void => {
    if (action.payload) {
      const items = [...state.library.data.items];
      const curItem = items.find((item) => item.id === action.payload.id);

      if (curItem) {
        curItem.moduleDisclosureStation = action.payload.moduleDisclosureStation;
      }

      state.library.data.items = items;
    }
  },
  resetPopular: (state: ModulesSchema): void => {
    state.popular = initialState.popular;
  },
  resetClone: (state: ModulesSchema): void => {
    state.clone = initialState.clone;
  },
  resetModuleInfo: (state: ModulesSchema): void => {
    state.moduleInfo = initialState.moduleInfo;
  },
  addNewModule: (state: ModulesSchema, action: PayloadAction<SearchModule>): void => {
    if (action.payload) {
      state.library.data.items = [...state.library.data.items, action.payload as any];
    }
  },
  setNewModule: (state: ModulesSchema, action: PayloadAction<ModuleInfoState>): void => {
    if (action.payload) {
      state.newModule.data = action.payload;
    }
  },
  updateModule: (state: ModulesSchema, action: PayloadAction<ModuleInfoPayload>): void => {
    if (action.payload) {
      const curItem = state.library.data.items.find(
        (item) => action.payload.id === item.id || action.payload.id === (item as any).editableModuleId,
      );

      if (curItem) {
        curItem.coverImage = action.payload.coverImage!;
        curItem.name = action.payload.name!;
        curItem.moduleHtml = action.payload.moduleHtml!;
      }
    }
  },
  resetNewModule: (state: ModulesSchema): void => {
    state.newModule = initialState.newModule;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<ModulesSchema>): void => {
  builder.addCase(getPopular.pending, (state, action) => {
    if (state.popular.status !== 'loading') {
      state.popular.requestId = action.meta.requestId;
      state.popular.status = 'loading';
      state.popular.data = initialState.popular.data;
    }
  });

  builder.addCase(getPopular.fulfilled, (state, action) => {
    if (state.popular.status === 'loading' && state.popular.requestId === action.meta.requestId) {
      state.popular.requestId = initialState.popular.requestId;
      state.popular.status = 'success';
      state.popular.data = action.payload ?? initialState.popular.data;
    }
  });

  builder.addCase(getPopular.rejected, (state, action) => {
    if (state.popular.status === 'loading' && state.popular.requestId === action.meta.requestId) {
      state.popular.requestId = initialState.popular.requestId;
      state.popular.status = 'error';
      state.popular.error = action.error;
    }
  });

  builder.addCase(search.pending, (state, action) => {
    if (state.library.status !== 'loading') {
      state.library.requestId = action.meta.requestId;
      state.library.status = 'loading';
    }
  });

  builder.addCase(search.fulfilled, (state, action) => {
    if (state.library.status === 'loading' && state.library.requestId === action.meta.requestId) {
      state.library.requestId = initialState.library.requestId;
      state.library.status = 'success';
      state.library.data = action.payload
        ? {
            ...action.payload,
            items: state.library.data.items
              .filter((item) => !action.payload?.items.find((payloadItem) => payloadItem.id === item.id))
              .concat(action.payload.items),
          }
        : initialState.library.data;
    }
  });

  builder.addCase(search.rejected, (state, action) => {
    if (state.library.status === 'loading' && state.library.requestId === action.meta.requestId) {
      state.library.requestId = initialState.library.requestId;
      state.library.status = 'error';
      state.library.error = {
        message: action.payload?.message || action.error.message || '',
      };
    }
  });

  builder.addCase(clone.pending, (state, action) => {
    if (state.clone.status !== 'loading') {
      state.clone.requestId = action.meta.requestId;
      state.clone.status = 'loading';
    }
  });

  builder.addCase(clone.fulfilled, (state, action) => {
    if (state.clone.status === 'loading' && state.clone.requestId === action.meta.requestId) {
      state.clone.requestId = initialState.clone.requestId;
      state.clone.status = 'success';
    }
  });

  builder.addCase(clone.rejected, (state, action) => {
    if (state.clone.status === 'loading' && state.clone.requestId === action.meta.requestId) {
      state.clone.requestId = initialState.clone.requestId;
      state.clone.status = 'error';
      state.clone.error = {
        message: action.payload?.message || action.error.message || '',
      };
    }
  });

  builder.addCase(duplicate.pending, (state, action) => {
    if (state.duplicate.status !== 'loading') {
      state.duplicate.requestId = action.meta.requestId;
      state.duplicate.status = 'loading';
    }
  });

  builder.addCase(duplicate.fulfilled, (state, action) => {
    if (state.duplicate.status === 'loading' && state.duplicate.requestId === action.meta.requestId) {
      state.duplicate.requestId = initialState.duplicate.requestId;
      state.duplicate.status = 'success';
    }
  });

  builder.addCase(duplicate.rejected, (state, action) => {
    if (state.duplicate.status === 'loading' && state.duplicate.requestId === action.meta.requestId) {
      state.duplicate.requestId = initialState.duplicate.requestId;
      state.duplicate.status = 'error';
      state.duplicate.error = {
        message: action.payload?.message || action.error.message || '',
      };
    }
  });

  builder.addCase(getModuleInfo.pending, (state, action) => {
    if (state.moduleInfo.status !== 'loading') {
      state.moduleInfo.requestId = action.meta.requestId;
      state.moduleInfo.status = 'loading';
      state.moduleInfo.data = initialState.moduleInfo.data;
    }
  });

  builder.addCase(getModuleInfo.fulfilled, (state, action) => {
    if (state.moduleInfo.status === 'loading' && state.moduleInfo.requestId === action.meta.requestId) {
      state.moduleInfo.requestId = initialState.moduleInfo.requestId;
      state.moduleInfo.status = 'success';
      if (action.payload) {
        state.moduleInfo.data = action.payload;
      }
    }
  });

  builder.addCase(getModuleInfo.rejected, (state, action) => {
    if (state.moduleInfo.status === 'loading' && state.moduleInfo.requestId === action.meta.requestId) {
      state.moduleInfo.requestId = initialState.moduleInfo.requestId;
      state.moduleInfo.status = 'error';
      state.moduleInfo.error = action.error;
    }
  });

  builder.addCase(checkModuleName.pending, (state, action) => {
    if (state.moduleName.status !== 'loading') {
      state.moduleName.requestId = action.meta.requestId;
      state.moduleName.status = 'loading';
    }
  });

  builder.addCase(checkModuleName.fulfilled, (state, action) => {
    if (state.moduleName.status === 'loading' && state.moduleName.requestId === action.meta.requestId) {
      state.moduleName.requestId = initialState.moduleName.requestId;
      state.moduleName.status = 'success';
    }
  });

  builder.addCase(checkModuleName.rejected, (state, action) => {
    if (state.moduleName.status === 'loading' && state.moduleName.requestId === action.meta.requestId) {
      state.moduleName.requestId = initialState.moduleName.requestId;
      state.moduleName.status = 'error';
      state.moduleName.error = action.error;
    }
  });

  builder.addCase(create.pending, (state, action) => {
    if (state.newModule.status !== 'loading') {
      state.newModule.requestId = action.meta.requestId;
      state.newModule.status = 'loading';
    }
  });

  builder.addCase(create.fulfilled, (state, action) => {
    if (state.newModule.status === 'loading' && state.newModule.requestId === action.meta.requestId) {
      state.newModule.requestId = initialState.newModule.requestId;
      state.newModule.status = 'success';

      if (action.payload) {
        state.library.data.items = [action.payload as unknown as SearchModule, ...state.library.data.items];
      }
    }
  });

  builder.addCase(create.rejected, (state, action) => {
    if (state.newModule.status === 'loading' && state.newModule.requestId === action.meta.requestId) {
      state.newModule.requestId = initialState.newModule.requestId;
      state.newModule.status = 'error';
      state.newModule.error = action.error;
    }
  });

  builder.addCase(refreshArticles.pending, (state, action) => {
    if (state.refreshArticles.status !== 'loading') {
      state.refreshArticles.requestId = action.meta.requestId;
      state.refreshArticles.status = 'loading';
    }
  });

  builder.addCase(refreshArticles.fulfilled, (state, action) => {
    if (state.refreshArticles.status === 'loading' && state.refreshArticles.requestId === action.meta.requestId) {
      state.refreshArticles.requestId = initialState.refreshArticles.requestId;
      state.refreshArticles.status = 'success';
    }
  });

  builder.addCase(refreshArticles.rejected, (state, action) => {
    if (state.refreshArticles.status === 'loading' && state.refreshArticles.requestId === action.meta.requestId) {
      state.refreshArticles.requestId = initialState.refreshArticles.requestId;
      state.refreshArticles.status = 'error';
      state.refreshArticles.error = action.error;
    }
  });

  builder.addCase(renameModuleTemplate.pending, (state, action) => {
    if (state.renameModuleTemplate.status !== 'loading') {
      state.renameModuleTemplate.requestId = action.meta.requestId;
      state.renameModuleTemplate.status = 'loading';
    }
  });

  builder.addCase(renameModuleTemplate.fulfilled, (state, action) => {
    if (
      state.renameModuleTemplate.status === 'loading' &&
      state.renameModuleTemplate.requestId === action.meta.requestId
    ) {
      state.renameModuleTemplate.requestId = initialState.renameModuleTemplate.requestId;
      state.renameModuleTemplate.status = 'success';

      if (action.payload && state.moduleInfo.data) {
        state.moduleInfo.data.templateName = (action.payload as any).templateName;
      }
    }
  });

  builder.addCase(renameModuleTemplate.rejected, (state, action) => {
    if (
      state.renameModuleTemplate.status === 'loading' &&
      state.renameModuleTemplate.requestId === action.meta.requestId
    ) {
      state.renameModuleTemplate.requestId = initialState.renameModuleTemplate.requestId;
      state.renameModuleTemplate.status = 'error';
      state.renameModuleTemplate.error = action.error;
    }
  });

  builder.addCase(replaceModuleDataVisualizationStation.pending, (state, action) => {
    if (state.replaceModuleDataVisualizationStation.status !== 'loading') {
      state.replaceModuleDataVisualizationStation.requestId = action.meta.requestId;
      state.replaceModuleDataVisualizationStation.status = 'loading';
    }
  });

  builder.addCase(replaceModuleDataVisualizationStation.fulfilled, (state, action) => {
    if (
      state.replaceModuleDataVisualizationStation.status === 'loading' &&
      state.replaceModuleDataVisualizationStation.requestId === action.meta.requestId
    ) {
      state.replaceModuleDataVisualizationStation.requestId =
        initialState.replaceModuleDataVisualizationStation.requestId;
      state.replaceModuleDataVisualizationStation.status = 'success';

      if (action.payload && state.moduleInfo.data) {
        state.moduleInfo.data.moduleDataVisualizationStation = (action.payload as any).moduleDataVisualizationStation;
      }
    }
  });

  builder.addCase(replaceModuleDataVisualizationStation.rejected, (state, action) => {
    if (
      state.replaceModuleDataVisualizationStation.status === 'loading' &&
      state.replaceModuleDataVisualizationStation.requestId === action.meta.requestId
    ) {
      state.replaceModuleDataVisualizationStation.requestId =
        initialState.replaceModuleDataVisualizationStation.requestId;
      state.replaceModuleDataVisualizationStation.status = 'error';
      state.replaceModuleDataVisualizationStation.error = action.error;
    }
  });

  builder.addCase(replaceModuleDisclosureStation.pending, (state, action) => {
    if (state.replaceModuleDisclosureStation.status !== 'loading') {
      state.replaceModuleDisclosureStation.requestId = action.meta.requestId;
      state.replaceModuleDisclosureStation.status = 'loading';
    }
  });

  builder.addCase(replaceModuleDisclosureStation.fulfilled, (state, action) => {
    if (
      state.replaceModuleDisclosureStation.status === 'loading' &&
      state.replaceModuleDisclosureStation.requestId === action.meta.requestId
    ) {
      state.replaceModuleDisclosureStation.requestId = initialState.replaceModuleDisclosureStation.requestId;
      state.replaceModuleDisclosureStation.status = 'success';

      if (action.payload && state.moduleInfo.data) {
        state.moduleInfo.data.moduleDisclosureStation = (action.payload as any).moduleDisclosureStation;
      }
    }
  });

  builder.addCase(replaceModuleDisclosureStation.rejected, (state, action) => {
    if (
      state.replaceModuleDisclosureStation.status === 'loading' &&
      state.replaceModuleDisclosureStation.requestId === action.meta.requestId
    ) {
      state.replaceModuleDisclosureStation.requestId = initialState.replaceModuleDisclosureStation.requestId;
      state.replaceModuleDisclosureStation.status = 'error';
      state.replaceModuleDisclosureStation.error = action.error;
    }
  });

  builder.addCase(resetModuleTemplateContent.pending, (state, action) => {
    if (state.resetModuleTemplateContent.status !== 'loading') {
      state.resetModuleTemplateContent.requestId = action.meta.requestId;
      state.resetModuleTemplateContent.status = 'loading';
    }
  });

  builder.addCase(resetModuleTemplateContent.fulfilled, (state, action) => {
    if (
      state.resetModuleTemplateContent.status === 'loading' &&
      state.resetModuleTemplateContent.requestId === action.meta.requestId
    ) {
      state.resetModuleTemplateContent.requestId = initialState.resetModuleTemplateContent.requestId;
      const items = [...state.library.data.items];
      state.library.data.items = items.filter((item) => item.id !== action.payload);
      state.resetModuleTemplateContent.status = 'success';
    }
  });

  builder.addCase(resetModuleTemplateContent.rejected, (state, action) => {
    if (
      state.resetModuleTemplateContent.status === 'loading' &&
      state.resetModuleTemplateContent.requestId === action.meta.requestId
    ) {
      state.resetModuleTemplateContent.requestId = initialState.resetModuleTemplateContent.requestId;
      state.resetModuleTemplateContent.status = 'error';
      state.resetModuleTemplateContent.error = {
        message: action.payload?.message || action.error.message || '',
      };
    }
  });
};
