import { PropsWithChildren } from 'react';
import { Dialog, IconButton, ModalProps as MUIModalProps } from '@material-ui/core';

import { Icon } from '@components';
import { useStyles } from './Modal.styles';
import { ModalProps } from './Modal.props';

/**
 * Modal component
 * @returns {JSX.Element}
 */
export const Modal = ({
  children,
  open,
  onClose,
  maxWidth,
  borderRadius,
  padding,
  ...rest
}: PropsWithChildren<ModalProps>): JSX.Element => {
  const styles = useStyles({ maxWidth, borderRadius, padding });

  const handleClose: MUIModalProps['onClose'] = (event, reason) => {
    if (reason === 'backdropClick' || !onClose) {
      return;
    }

    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{ className: styles.backdrop }}
      PaperProps={{ className: styles.paper }}
      {...rest}
    >
      {onClose && (
        <IconButton className={styles.closeButton} onClick={onClose}>
          <Icon.Close />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};
