import { image } from '@content';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    loaderIcon: {
      width: '35px',
      height: '35px',
      backgroundSize: 'cover',
      backgroundImage: `url(${image.iconeChargementViolet})`,
      animation: 'spin 1s cubic-bezier(0.2, 0.61, 0.71, 0.61) infinite',
    },
  }),
);
