import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * MindsetView component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    mindsetView: {
      padding: theme.spacing(2, 4, 4, 42.5),

      ['@media (max-width: 1450px)']: {
        padding: theme.spacing(2, 2, 2, 35),
      },
    },
  }),
);
