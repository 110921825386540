import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from './Users.state';
import { getAll } from './Users.thunk';
import { UsersSchema } from './Users.schema';

export const reducers = {
  findById: (state: UsersSchema, action: PayloadAction<number>): void => {
    state.entity = state.list.data.items.find((i) => i.id === action.payload);
  },
  resetEntity: (state: UsersSchema): void => {
    state.entity = undefined;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<UsersSchema>): void => {
  builder.addCase(getAll.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      list.data = initialState.list.data;
      list.error = initialState.list.error;
    }
  });

  builder.addCase(getAll.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      if (action.payload) {
        list.data = action.payload;
      }
    }
  });

  builder.addCase(getAll.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
      list.data = initialState.list.data;
    }
  });
};
