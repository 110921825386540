import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    warning: {
      maxWidth: '34rem',
      marginBottom: '2.2rem',
    },
    message: {
      marginBottom: '2.2rem',
      marginLeft: variables.gap.lg,
      marginRight: variables.gap.lg,
    },
    input: {
      marginLeft: '1.2rem',
      marginRight: '1.2rem',
      marginBottom: '3.2rem',
    },
    content: {
      marginLeft: variables.gap.lg,
      marginRight: variables.gap.lg,
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '3.5rem',
      maxHeight: '15rem',
      overflowY: 'auto',
      paddingRight: variables.scrollbar.width,
    },
    item: {
      display: 'flex',
      '&:not(:last-child)': {
        marginBottom: '1.2rem',
      },
    },
    name: {
      flexGrow: 1,
    },
    confirm: {
      marginRight: variables.gap.lg,
    },
    close: {
      textTransform: 'initial',
      fontSize: variables.font.size.lg,
      letterSpacing: variables.letterSpacing.sm,
      textDecoration: 'underline',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'none',
      },
    },
  }),
);
