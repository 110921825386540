import { memo } from 'react';
import { Box } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { Typography } from '@components';
import { content } from '@content';

type DeploymentEmptyPositionProps = {
  index: number;
};

export const DeploymentEmptyPosition = memo(
  ({ index }: DeploymentEmptyPositionProps): JSX.Element => (
    <Droppable droppableId={`position-${index}`} type="JOURNEY">
      {(provided) => (
        <Box {...{ ref: provided.innerRef }} {...provided.droppableProps}>
          <Typography.Caption>{content.dragModuleGroupHere}</Typography.Caption>
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  ),
);

DeploymentEmptyPosition.displayName = 'DeploymentEmptyPosition';
