import { SVGProps } from 'react';

import { variables } from '@styles';

export const BarChartDown = ({
  className = '',
  fill = variables.color.primary.white,
  width = 24,
  height = 24,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M22.5 23.25H2.25C1.85218 23.25 1.47064 23.092 1.18934 22.8107C0.908035 22.5294 0.75 22.1478 0.75 21.75V1.5C0.75 1.30109 0.829018 1.11032 0.96967 0.96967C1.11032 0.829018 1.30109 0.75 1.5 0.75C1.69891 0.75 1.88968 0.829018 2.03033 0.96967C2.17098 1.11032 2.25 1.30109 2.25 1.5V21.75H22.5C22.6989 21.75 22.8897 21.829 23.0303 21.9697C23.171 22.1103 23.25 22.3011 23.25 22.5C23.25 22.6989 23.171 22.8897 23.0303 23.0303C22.8897 23.171 22.6989 23.25 22.5 23.25Z"
      fill={fill}
    />
    <path
      d="M18.6875 20.25H19.3125C19.7601 20.25 20.1893 20.0722 20.5057 19.7557C20.8222 19.4393 21 19.0101 21 18.5625V11.4375C21 10.9899 20.8222 10.5607 20.5057 10.2443C20.1893 9.92779 19.7601 9.75 19.3125 9.75H18.6875C18.2399 9.75 17.8107 9.92779 17.4943 10.2443C17.1778 10.5607 17 10.9899 17 11.4375V18.5625C17 19.0101 17.1778 19.4393 17.4943 19.7557C17.8107 20.0722 18.2399 20.25 18.6875 20.25Z"
      fill={fill}
    />
    <path
      d="M12.6875 20.2499H13.3125C13.7601 20.2499 14.1893 20.0721 14.5057 19.7556C14.8222 19.4392 15 19.0099 15 18.5624V8.19312C15 7.74556 14.8222 7.31634 14.5057 6.99987C14.1893 6.68341 13.7601 6.50562 13.3125 6.50562H12.6875C12.2399 6.50562 11.8107 6.68341 11.4943 6.99987C11.1778 7.31634 11 7.74556 11 8.19312V18.5624C11 19.0099 11.1778 19.4392 11.4943 19.7556C11.8107 20.0721 12.2399 20.2499 12.6875 20.2499Z"
      fill={fill}
    />
    <path
      d="M6.6875 20.2499H7.3125C7.76005 20.2499 8.18927 20.0721 8.50574 19.7556C8.82221 19.4392 9 19.0099 9 18.5624V4.6875C9 4.23995 8.82221 3.81073 8.50574 3.49426C8.18927 3.17779 7.76005 3 7.3125 3H6.6875C6.23995 3 5.81073 3.17779 5.49426 3.49426C5.17779 3.81073 5 4.23995 5 4.6875V18.5624C5 19.0099 5.17779 19.4392 5.49426 19.7556C5.81073 20.0721 6.23995 20.2499 6.6875 20.2499Z"
      fill={fill}
    />
  </svg>
);
