import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    htmlEditor: {
      minHeight: '13.5rem',
      maxHeight: 'calc(100vh - 256px)',
    },
  }),
);
