import { Box, IconButton } from '@material-ui/core';
import { format } from '@utils';
import { content } from '@content';
import { Typography, Icon } from '@components';

import { useStyles } from './MindsetPreviewModal.styles';
import { MindsetPreviewModalProps } from './MindsetPreviewModal.props';

/**
 * MindsetPreviewModal component
 * @returns {JSX.Element}
 */
export const MindsetPreviewModal = ({ entity, onRezise }: MindsetPreviewModalProps): JSX.Element => {
  const styles = useStyles();
  return (
    <Box className={styles.previewModal} onClick={onRezise}>
      {entity.coverImage && (
        <Box className={styles.imageContainer}>
          <img className={styles.image} src={entity.coverImage} alt={entity.name || ''} />
        </Box>
      )}
      <Box className={styles.row}>
        <Box>
          <Typography.Headline>{entity.name}</Typography.Headline>
          {entity.updatedAt && (
            <Typography.Caption className={styles.date}>
              {content.deploymentDate}: {format.date(entity.updatedAt, 'MMM. do, yyyy')}
            </Typography.Caption>
          )}
        </Box>
        <IconButton className={styles.resize}>
          <Icon.Resize />
        </IconButton>
      </Box>
    </Box>
  );
};
