import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar, SnackbarKey } from 'notistack';
import { Box, IconButton } from '@material-ui/core';

import { useAppDispatch } from '@store';
import { global } from '@modules';
import { Icon } from '@components';
import { content } from '@content';

import { useStyles } from './Notifications.styles';

let displayed: SnackbarKey[] = [];

export const Notifications = (): null => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const notifications = global.useNotifications();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach((notification) => {
      if (displayed.includes(notification.key) && notification.options.variant === 'error') {
        closeSnackbar(notification.key);
      }
    });
  }, [closeSnackbar, notifications, pathname]);

  useEffect(() => {
    notifications.forEach(({ key, message, options, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
      }

      if (displayed.includes(key)) {
        return;
      }

      let action = () => (
        <IconButton className={styles.closeButton} onClick={() => closeSnackbar(key)}>
          <Icon.CloseOutline width="20" height="20" />
        </IconButton>
      );

      if (options.variant === 'error') {
        // eslint-disable-next-line react/display-name
        action = () => (
          <>
            <Box className={styles.message} onClick={() => closeSnackbar(key)}>
              {content.somethingWentWrongTryToRefresh}
            </Box>
            <IconButton className={styles.closeButton} onClick={() => closeSnackbar(key)}>
              <Icon.CloseOutline width="20" height="20" />
            </IconButton>
          </>
        );
      }

      enqueueSnackbar(message, {
        key,
        ...options,
        persist: options.variant === 'error',
        action,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(global.actions.removeNotification(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });
  }, [styles, notifications, closeSnackbar, enqueueSnackbar, dispatch]);
  return null;
};
