import { ForwardedRef, forwardRef } from 'react';
import { Typography } from '@material-ui/core';

import { useSmallTagStyles } from './Typography.styles';
import { TypographyProps } from './Typography.props';

/**
 * Typography.SmallTag component
 * @returns {JSX.Element}
 */
export const SmallTag = forwardRef(
  ({ className = '', ...props }: TypographyProps, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const styles = useSmallTagStyles();

    return <Typography classes={styles} className={className} {...props} {...{ ref }} />;
  },
);

SmallTag.displayName = 'Typography.SmallTag';
