import React, { useEffect, useCallback, useState, ChangeEvent } from 'react';
import { Box, Checkbox, Button, Divider, FormGroup, FormControlLabel } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import { Typography, Select, TextField, Icon, Alert } from '@components';
import { content } from '@content';
import { validate, textTemplate, parse } from '@utils';
import { Client, UserStatus, roles as rolesModule, UserItemPayload, IdsArray, Role } from '@modules';

import { useStyles as useStylesParent } from '../AdminUsers.styles';
import { useStyles } from './UserPage.styles';
import { RequiredFields, UserPageProps } from './UserPage.props';

/**
 UserPage component.
 @returns {JSX.Element}
 */

export const UserPage = ({ entity, enterprises, roles, onSubmit, onClose }: UserPageProps): JSX.Element => {
  const styles = useStyles();
  const parentStyles = useStylesParent();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<UserItemPayload>({
    defaultValues: {
      email: '',
      firstname: '',
      lastname: '',
      role: undefined,
      businessUnits: [],
      enterprises: [],
    },
  });

  const errorsLength = Object.keys(errors).length;
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showArchivedAlert, setShowArchivedAlert] = useState<boolean>(false);
  const [showTip, setShowTip] = useState<boolean>(false);
  const [curRole, setCurRole] = useState<Role>();
  const [checked, setChecked] = useState<Record<string, Record<string, boolean>[]>>({});
  const [businessUnitIds, setBusinessUnitIds] = useState<IdsArray>([]);
  const [enterprisesIds, setEnterprisesIds] = useState<IdsArray>([]);

  // Set checked items on load
  useEffect(() => {
    let entityBUIds: number[] = [];
    const entityClients = entity?.businessUnits as unknown as Client[];

    if (entity) {
      entityBUIds = entityClients.reduce((acc: number[], item: Client) => {
        return [...acc, item.id];
      }, []);
    }

    setChecked(
      enterprises.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item.businessUnits?.map((businessUnit) => {
            return {
              [businessUnit.id]:
                curRole?.id === 1 ? true : (entity && entityBUIds && entityBUIds.includes(businessUnit.id)) || false,
            };
          }),
        }),
        {},
      ),
    );
  }, [enterprises, entity, curRole?.id]);

  // set chosen businessUnits ids and enterprises ids
  useEffect(() => {
    const cliIds = Object.keys(checked).reduce((acc: IdsArray, item) => {
      const newItem: IdsArray = [];

      checked[item].forEach((element) => {
        if (element[Object.keys(element)[0]]) {
          newItem.push({ id: parse.integer(Object.keys(element)[0]) });
        }
      });

      return [...acc, ...newItem];
    }, []);

    setBusinessUnitIds(cliIds);
    setValue('businessUnits', cliIds);

    const enterIds = Object.keys(checked).reduce<IdsArray>((acc, item) => {
      const next = [...acc];

      checked[item].forEach((element) => {
        if (element[Object.keys(element)[0]]) {
          const id = parse.integer(item);
          const index = next.findIndex((itm) => itm.id === id);

          if (index === -1) {
            next.push({ id });
          }
        }
      });

      return next;
    }, []);

    setEnterprisesIds(enterIds);
    setValue('enterprises', enterIds);
  }, [checked, setValue]);

  // change checked items
  const handleCheckChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.name === 'enterprise') {
        setChecked((prevState) => ({
          ...prevState,
          [event.target.value]: [
            ...prevState[event.target.value].map((item) => {
              return {
                [Object.keys(item)[0]]: event.target.checked,
              };
            }),
          ],
        }));
      } else {
        setChecked((prevState) => {
          const newState = Object.keys(prevState).reduce((acc, key) => {
            return {
              ...acc,
              [key]: prevState[key].map((item) => {
                if (Object.keys(item)[0] === event.target.value) {
                  return {
                    [Object.keys(item)[0]]: event.target.checked,
                  };
                }

                return item;
              }),
            };
          }, {});

          return {
            ...prevState,
            ...newState,
          };
        });
      }
    },
    [setChecked],
  );

  useEffect(() => {
    if (errors && Object.keys(errors).length !== 0) {
      setShowErrorAlert(true);
    }
  }, [errors, errorsLength]);

  useEffect(() => {
    if (entity && entity.status === UserStatus.inactive) {
      setShowArchivedAlert(true);
    } else {
      setShowArchivedAlert(false);
    }
  }, [entity]);

  useEffect(() => {
    if (entity) {
      reset({
        ...entity,
        businessUnits: businessUnitIds,
        enterprises: enterprisesIds,
      });
    }
  }, [entity, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRolesChange = useCallback(
    (event) => {
      const id = +event.target.value;
      const role = roles.find((item) => item.id === id);

      if (role) {
        if (role.name === 'ROLE_ADMIN') {
          setShowTip(true);
        } else {
          setShowTip(false);
        }

        setValue('role', role);
        setCurRole(role);
      }
    },
    [setValue, roles],
  );

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const handleCloseErrorAlert = () => setShowErrorAlert(false);
  const handleCloseArchivedAlert = () => setShowArchivedAlert(false);

  const requiredText = (name: RequiredFields) => (
    <Typography.SmallCaption
      className={`${parentStyles.required} ${errors[`${name}`] ? parentStyles.error : ''}`}
    >{`*${content.required}`}</Typography.SmallCaption>
  );

  const handleArchiveUser = useCallback(() => {
    if (getValues().status === UserStatus.active) {
      setValue('status', UserStatus.inactive);
    } else {
      setValue('status', UserStatus.active);
    }

    handleSubmit(onSubmit)();
  }, [getValues, handleSubmit, onSubmit, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.userPage}>
      <Box className={parentStyles.header}>
        <Box>
          <Typography.SmallCaption className={parentStyles.topTitle}>{`${content.users} /`}</Typography.SmallCaption>
          <Typography.LargeTitle>{entity ? content.editAUser : content.addAUser}</Typography.LargeTitle>
        </Box>
      </Box>
      <Box className={styles.body}>
        {showErrorAlert && (
          <Alert
            className={styles.alert}
            onClose={handleCloseErrorAlert}
            message={content.pleaseCheckAllRequiredFields}
            align="left"
          />
        )}
        {showArchivedAlert && (
          <Alert
            type="info"
            className={styles.alert}
            onClose={handleCloseArchivedAlert}
            message={content.thisUserCurrentlyArchived}
            align="left"
          />
        )}
        <Box className={styles.formRow}>
          <Typography.Body className={styles.inputLabel}>{`${content.email}*`}</Typography.Body>
          <Box className={styles.inputWrapper}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                validate: (val) => (validate.email(val) ? true : content.incorrectEmailAddress),
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  placeholder={`${content.enter} ${content.userEmail}`}
                  error={fieldState.invalid}
                />
              )}
            />
            {requiredText('email')}
          </Box>
        </Box>
        <Box className={styles.formRow}>
          <Typography.Body className={styles.inputLabel}>{`${content.firstName}*`}</Typography.Body>
          <Box className={styles.inputWrapper}>
            <Controller
              name="firstname"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  placeholder={`${content.enter} ${content.firstName}`}
                  error={fieldState.invalid}
                />
              )}
            />
            {requiredText('firstname')}
          </Box>
        </Box>
        <Box className={styles.formRow}>
          <Typography.Body className={styles.inputLabel}>{`${content.lastName}*`}</Typography.Body>
          <Box className={styles.inputWrapper}>
            <Controller
              name="lastname"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  placeholder={`${content.enter} ${content.lastName}`}
                  error={fieldState.invalid}
                />
              )}
            />
            {requiredText('lastname')}
          </Box>
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.formRow}>
          <Typography.Body className={styles.inputLabel}>{`${content.role}*`}</Typography.Body>
          <Box>
            <Box className={styles.inputWrapper}>
              <Controller
                name="role"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ref, value, ...field }, fieldState }) => (
                  <Select
                    {...field}
                    value={value ? `${value?.id}` : ''}
                    inputRef={ref}
                    placeholder={textTemplate(content.selectValue, { value: content.aRole })}
                    error={fieldState.invalid}
                    options={[...roles]
                      .sort((a, b) => a.id - b.id)
                      .map((item) => ({
                        id: `${item.id}`,
                        label: rolesModule.utils.nameToLabel(item),
                      }))}
                    onChange={handleRolesChange}
                  />
                )}
              />
              {requiredText('role')}
            </Box>
            {showTip && (
              <Box className={styles.tip}>
                <Icon.InsightOutline />
                <Typography.Label className={styles.tipText}>{content.adminUsersAreAble}</Typography.Label>
              </Box>
            )}
          </Box>
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.formRow}>
          <Typography.Body className={styles.inputLabel}>{`${content.businessUnitAccess}*`}</Typography.Body>
          <Controller
            name="businessUnits"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { ref } }) => (
              <FormGroup className={styles.checkboxes}>
                {enterprises?.map((enterprise) => (
                  <>
                    <FormControlLabel
                      key={enterprise.id}
                      inputRef={ref}
                      control={
                        <Checkbox
                          name="enterprise"
                          value={enterprise.id}
                          className={styles.checkbox}
                          onChange={handleCheckChange}
                          disabled={curRole?.id === 1}
                          checked={
                            checked &&
                            Object.keys(checked).length > 0 &&
                            checked[enterprise.id].length > 0 &&
                            checked[enterprise.id].every((item) => item[Object.keys(item)[0]])
                          }
                          indeterminate={
                            checked &&
                            Object.keys(checked).length > 0 &&
                            checked[enterprise.id].some((item) => item[Object.keys(item)[0]])
                          }
                        />
                      }
                      label={enterprise.name}
                    />
                    <FormGroup className={styles.businessUnits}>
                      {enterprise.businessUnits?.map((businessUnit, index) => (
                        <FormControlLabel
                          key={businessUnit.id}
                          control={
                            <Checkbox
                              name="businessUnit"
                              value={businessUnit.id}
                              className={styles.checkbox}
                              onChange={handleCheckChange}
                              disabled={curRole?.id === 1}
                              checked={
                                checked &&
                                Object.keys(checked).length > 0 &&
                                checked[enterprise.id][index][businessUnit.id]
                              }
                            />
                          }
                          label={businessUnit.name}
                        />
                      ))}
                    </FormGroup>
                  </>
                ))}
              </FormGroup>
            )}
          />
        </Box>
      </Box>
      <Box className={parentStyles.footer}>
        <Box>
          {entity && (
            <Button variant="text" startIcon={<Icon.Archive />} className={styles.archive} onClick={handleArchiveUser}>
              {textTemplate(
                getValues().status === UserStatus.active ? content.archiveEntity : content.unarchiveEntity,
                { entity: content.user },
              )}
            </Button>
          )}
          <Button className={parentStyles.cancel} variant="outlined" onClick={handleClose}>
            {content.cancel}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {entity ? content.save : textTemplate(content.addValue, { value: content.user })}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
