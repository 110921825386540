import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const gapY = variables.gap.lg;

const gapX = variables.gap.xl;

const iconSize = '2.8rem';

const textGapX = variables.gap.md;

export const useStyles = makeStyles(() =>
  createStyles({
    fields: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      margin: `-${gapY} 0 0 -${gapX}`,
    },

    field: {
      display: 'flex',
      alignItems: 'center',
      margin: `${gapY} 0 0 ${gapX}`,
      width: `calc(50% - ${gapX})`,
    },

    icon: {
      display: 'flex',
      alignItems: 'flex-end',
      width: iconSize,
      height: iconSize,
    },

    text: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: textGapX,
      width: `calc(100% - ${iconSize} - ${textGapX})`,
    },

    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    payload: {
      display: 'flex',
    },

    value: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    copy: {
      marginLeft: variables.gap.md,
      padding: 0,
      fontSize: 0,
    },
  }),
);
