import { useCallback, useContext, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Confirm, FileUpload, FileUploadProps, TextField } from '@components';
import { searchQuery, textTemplate, useHistory, validate } from '@utils';
import { deployment, template, TemplateCreatePayload, userClients } from '@modules';
import { DisclosureWarningModal } from '@views';
import { useAppDispatch } from '@store';
import { content } from '@content';

import { TemplateUploadProps } from './TemplateUpload.props';
import { useHtmlDraft } from './TemplateUpload.hooks';
import { HtmlDraftArg } from './TemplateUpload.types';
import { updateTemplateExperienceIds } from '../Deployments.helpers';
import { TopBarContext } from '@layouts';

export const TemplateUpload = ({ onClose }: TemplateUploadProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { deployment: deploymentId, mailFile: mailFileId } = history.query;
  const [businessUnit] = searchQuery.useMutualParams('businessUnit');
  const templateEntityMeta = template.useEntityMeta();
  const [succeed, setSucceed] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<TemplateCreatePayload>({ defaultValues: { businessUnit } });
  const currentClientData = userClients.useEntityData();
  const [, setTopBarCtx] = useContext(TopBarContext);

  const handleDraftDecorate: HtmlDraftArg = useCallback(
    (decoratedDraft) => {
      const { html, height, width } = decoratedDraft;

      form.setValue('html', html);
      form.setValue('width', width);
      form.setValue('height', height);

      setLoading(false);
    },
    [form],
  );

  const draft = useHtmlDraft(handleDraftDecorate);

  const handleFileReaderLoad = useCallback(
    (loadEvent: ProgressEvent<FileReader>) => {
      const draftSrcDoc = loadEvent.target?.result;

      if (typeof draftSrcDoc === 'string') {
        draft.setSrcDoc(draftSrcDoc);
      } else {
        setLoading(false);
      }
    },
    [draft],
  );

  const handleHtmlChange: FileUploadProps['onChange'] = useCallback(
    (file) => {
      form.clearErrors('html');

      if (file && !Array.isArray(file)) {
        if (validate.isHtmlFile(file?.type)) {
          setLoading(true);

          const reader = new FileReader();

          reader.addEventListener('load', handleFileReaderLoad);

          reader.readAsText(file);
        } else {
          form.setError('html', { message: content.invalidFileFormat });
        }
      } else {
        form.setValue('html', '');

        setLoading(false);
      }
    },
    [form, handleFileReaderLoad],
  );

  const getHTMLWithChangeUnsubscribePositionIfExist = (html: string): { changed: boolean; html: string } => {
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(html, 'text/html');
    const selectorsWithUnsibscribe = Array.prototype.slice
      .call(parsedDoc.documentElement.querySelectorAll('span'))
      .concat(Array.prototype.slice.call(parsedDoc.documentElement.querySelectorAll('div')))
      .filter(function (el) {
        return el.textContent.toLowerCase() === 'unsubscribe';
      });
    const lastSelectorWithUnsubcribe = selectorsWithUnsibscribe[selectorsWithUnsibscribe.length - 1];
    let mainSelector;
    let mainReplacableSelector;

    if (lastSelectorWithUnsubcribe) {
      if (['SPAN', 'DIV'].includes(lastSelectorWithUnsubcribe.parentElement.tagName)) {
        mainSelector = document.createElement(lastSelectorWithUnsubcribe.parentElement.tagName.toLowerCase());
        mainSelector.innerHTML = lastSelectorWithUnsubcribe.parentElement.innerHTML;
        mainReplacableSelector = lastSelectorWithUnsubcribe.parentElement;
      } else if (['SPAN', 'DIV'].includes(lastSelectorWithUnsubcribe.parentElement.parentElement.tagName)) {
        mainSelector = document.createElement(
          lastSelectorWithUnsubcribe.parentElement.parentElement.tagName.toLowerCase(),
        );
        mainSelector.innerHTML = lastSelectorWithUnsubcribe.parentElement.parentElement.innerHTML;
        mainReplacableSelector = lastSelectorWithUnsubcribe.parentElement.parentElement;
      }

      let parentTd: HTMLTableCellElement;

      if (!mainSelector) {
        parentTd = lastSelectorWithUnsubcribe.parentElement;

        if (!parentTd) {
          return { changed: false, html: '' };
        }

        do {
          parentTd = parentTd as any;
        } while (parentTd.tagName === 'td');

        parentTd = parentTd.parentElement as any;

        const startIndex = parentTd.innerHTML.indexOf('You can');
        const endIndex = (parentTd.innerHTML.indexOf('email.\n') ?? 0) + 6;
        const unsubscribeContent = parentTd.innerHTML.slice(startIndex, endIndex);
        const cutContent = parentTd.innerHTML.slice(0, startIndex);

        parentTd.innerHTML = cutContent;

        mainSelector = document.createElement('span');
        mainSelector.innerHTML = unsubscribeContent ?? '';
      } else {
        parentTd = mainReplacableSelector;

        do {
          parentTd = parentTd.parentElement as any;
        } while (parentTd.tagName === 'td');
      }

      const parentTdStyle = parentTd.style;
      const splitters = parsedDoc.documentElement.querySelectorAll('[data-sample-template-splitter]');
      const lastSplitterNumber = Array.from(splitters).reduce((acc, item) => {
        return Number(item.getAttribute('data-sample-template-splitter')) > acc
          ? Number(item.getAttribute('data-sample-template-splitter'))
          : acc;
      }, 0);
      const newTr = document.createElement('tr');
      const newTd = document.createElement('td');
      const splitterTr = document.createElement('tr');
      const parentTbody = parentTd.parentElement?.parentElement;

      splitterTr.innerHTML = `
        <td colspan="99">
          <div data-sample-template-splitter="${lastSplitterNumber + 1}"></div>
        </td>
      `;

      newTd.appendChild(mainSelector);
      newTd.setAttribute('style', parentTdStyle.cssText);
      newTr.appendChild(newTd);
      parentTbody?.appendChild(splitterTr);
      parentTbody?.appendChild(newTr);
      mainReplacableSelector?.remove();
    }

    return {
      html: parsedDoc.documentElement.outerHTML,
      changed: !!lastSelectorWithUnsubcribe,
    };
  };

  const handleSubmit: SubmitHandler<TemplateCreatePayload> = useCallback(
    async (payload) => {
      const htmlWithChangePositionObj = getHTMLWithChangeUnsubscribePositionIfExist(payload.html);

      setLoading(true);

      const result = await dispatch(
        template.thunk.create({
          ...payload,
          html: htmlWithChangePositionObj.changed ? htmlWithChangePositionObj.html : payload.html,
        }),
      );

      if (template.thunk.create.fulfilled.match(result)) {
        setSucceed(true);

        const res = (await dispatch(deployment.thunk.getInfoEmailTemplate(deploymentId!))) as any;

        if (res.payload.deploymentEmailTemplates.length === 1) {
          const curTemplateId = res.payload.deploymentEmailTemplates[0].id;

          const firstMailFile = currentClientData?.mailFile[0];
          const selectionResult = await dispatch(template.thunk.selectTemplateToDeployment(curTemplateId));

          if (template.thunk.selectTemplateToDeployment.fulfilled.match(selectionResult)) {
            const tmplUpdated = await updateTemplateExperienceIds(
              curTemplateId,
              dispatch,
              res.payload.deploymentEmailTemplates,
            );

            if (tmplUpdated) {
              history.push('', { template: curTemplateId });
            }
          }

          if (deploymentId && !mailFileId && firstMailFile) {
            dispatch(deployment.thunk.attachMailFile({ deploymentId, mailFileId: firstMailFile.id }));
          }

          setTopBarCtx((prev) => ({
            ...prev,
            isTemplateIdChanged: true,
          }));
        }
      }

      setLoading(false);
    },
    [deploymentId, currentClientData, mailFileId, dispatch, setSucceed],
  );

  return succeed ? (
    <DisclosureWarningModal onClose={onClose} onConfirm={onClose} />
  ) : (
    <Confirm
      open
      title={content.uploadTemplate}
      submitDisabled={loading}
      onCancel={onClose}
      onSubmit={form.handleSubmit(handleSubmit)}
    >
      <Controller
        name="name"
        control={form.control}
        rules={{ required: content.templateName }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            disabled={templateEntityMeta.status === 'loading'}
            label={content.templateName}
            placeholder={content.templateName}
            hint={error?.message}
            error={invalid}
          />
        )}
      />
      <Controller
        name="html"
        control={form.control}
        rules={{ required: textTemplate(content.pleaseSelect, { field: content.templateFile }) }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <FileUpload
            {...field}
            id="template_upload.html"
            extensions={['html', 'htm']}
            label={textTemplate(content.requiredField, { field: content.templateFile })}
            hint={error?.message || `*${content.required}. ${content.htmlFileFormatOnly}`}
            error={invalid}
            disabled={templateEntityMeta.status === 'loading'}
            uploading={templateEntityMeta.status === 'loading'}
            processing={loading && templateEntityMeta.status !== 'loading'}
            value=""
            onChange={handleHtmlChange}
          />
        )}
      />
      {draft.frame}
    </Confirm>
  );
};
