import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { MetaSchema } from '@modules';

import * as thunk from './Users.thunk';
import { initialState } from './Users.state';
import { reducers, extraReducers } from './Users.reducer';
import { UserItemPayload, UsersListPayload } from './Users.schema';

export * from './Users.schema';

const slice = createSlice({
  name: 'users',
  initialState,
  reducers,
  extraReducers,
});

export const users = {
  thunk,
  slice,
  actions: slice.actions,
  useListData: (): UsersListPayload =>
    useAppSelector(
      ({
        users: {
          list: { data },
        },
      }) => data,
    ),
  useListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        users: {
          list: { data, ...meta },
        },
      }) => meta,
    ),
  useEntityData: (): UserItemPayload | undefined => useAppSelector(({ users: { entity } }) => entity),
};
