import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const height = (px: number, limsOnly = false) => {
  const rem = `${px / 10}rem`;

  return {
    minHeight: rem,
    maxHeight: rem,
    ...(limsOnly ? null : { height: rem }),
  };
};

export const useStyles = makeStyles(() =>
  createStyles({
    mailBuilder: {
      '&[data-disabled="true"]': {
        opacity: variables.opacity.high,
        pointerEvents: 'none',
      },

      '&[data-loaded="false"]': height(5, true),

      '&[data-loaded="true"]': height(400),

      '& > iframe': {
        minWidth: 'initial !important',
      },
    },
  }),
);
