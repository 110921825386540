import { Box } from '@material-ui/core';

import { FullPageLoader, NotificationsProvider, Notifications, MobileOverlay } from '@components';

import { BaseProps } from './Base.props';
import { useStyles } from './Base.styles';

export const Base = ({ className = '', children }: BaseProps): JSX.Element => {
  const styles = useStyles();
  return (
    <NotificationsProvider>
      <MobileOverlay />
      {/* <FullPageLoader /> */}
      <Notifications />
      <Box className={`${styles.base} ${className}`}>{children}</Box>
    </NotificationsProvider>
  );
};
