import { SVGProps } from 'react';

export const SuperScript = ({ className = '', strokeWidth = '1.5' }: SVGProps<SVGElement>): JSX.Element => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M1 4.85352L9.98721 13.84"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.98721 4.85352L1 13.84"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.127 1.85586C12.127 1.62887 12.2171 1.41118 12.3776 1.25067C12.5382 1.09017 12.7559 1 12.9829 1V1C13.2099 1 13.4276 1.09017 13.5881 1.25067C13.7486 1.41118 13.8388 1.62887 13.8388 1.85586C13.8388 2.1327 13.7446 2.40132 13.5718 2.61757L12.127 4.42342H13.8388"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
