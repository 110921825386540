import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

import { content } from '@content';

const SPLITTER = ' | ';

const DELAY = 40; // ms

const HANDLE_INIT = 0; // timeout handle initial value

export type WebTitlePart = string | number | boolean | null | undefined;

export type WebTitleParts = WebTitlePart[];

export type WebTitleParams = {
  delay?: number;
  parts: WebTitleParts;
};

const updateDocTitle = (...parts: WebTitleParts) => {
  const next = [content.company, ...parts]
    .filter((part) => !!part)
    .reverse()
    .join(SPLITTER);

  if (next !== document.title) {
    document.title = next;
  }
};

// useful for page containers only
export const useWebTitle = (...parts: WebTitleParts) => {
  useEffect(() => {
    updateDocTitle(...parts);
  }, [parts]);
};

// useful for modal dialogs only (delay helps to override container web title setup)
export const useWebTitleDelayed = ({ delay = DELAY, parts }: WebTitleParams) => {
  const delayHandle = useRef(HANDLE_INIT);

  const resetDelay = useCallback(() => {
    window.clearTimeout(delayHandle.current);
    delayHandle.current = HANDLE_INIT;
  }, []);

  useLayoutEffect(() => {
    resetDelay();
    delayHandle.current = window.setTimeout(updateDocTitle, delay, ...parts);
  }, [resetDelay, delay, parts]);

  useEffect(() => () => resetDelay(), [resetDelay]);
};
