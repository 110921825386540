import { OptionItem } from '@components';
import { content } from '@content';
import { OptionsTypes } from '@views/LibraryNavigation/LibraryNavigation.const';

/**
 Treatments component variables
 @returns {variables}
 */

export enum MenuKeys {
  RENAME = 'rename',
  REMOVE_FROM_EMAIL_TEMPLATE = 'remove-from-email-template',
}

export const MENU_OPTIONS: OptionItem[] = [
  {
    id: MenuKeys.RENAME,
    label: content.rename,
    type: OptionsTypes.LAST_USED,
  },
];
