import { useCallback, useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { Typography } from '@components';
import { content } from '@content';

import { useStyles } from './QATests.styles';
import { SEND_TESTS } from './QATests.const';
import { FormValues } from './QATests.types';

/**
 QATests component
 @returns {JSX.Element}
 */

export const QATests = (): JSX.Element => {
  const styles = useStyles();

  const [checkedSendTestId, setCheckedSendTestId] = useState('');

  const { handleSubmit } = useForm<FormValues>();

  const handleSendChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedSendTestId(e.target.value);
    },
    [setCheckedSendTestId],
  );

  const onSubmit = useCallback(() => {
    console.log({
      checkedSendTestId: checkedSendTestId,
    });
  }, [checkedSendTestId]);

  return (
    <Box className={styles.qaTests}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Box className={styles.content}>
          <Typography.Title className={styles.title}>{content.testSettings}</Typography.Title>
          <FormControl className={styles.radioRow}>
            <RadioGroup value={checkedSendTestId} onChange={handleSendChange}>
              {SEND_TESTS.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  className={styles.radioControl}
                  label={<Typography.Label>{item.label}</Typography.Label>}
                  control={<Radio className={styles.checkbox} color="primary" />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box className={styles.footer}>
          <Button type="submit" variant="contained" color="primary">
            {content.sendTest}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
