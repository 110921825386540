export function chunk<T>(array: T[], size: number): T[][] {
  const results = [];

  while (array.length) {
    results.push(array.splice(0, size));
  }

  return results;
}

export function range(start: number, end: number): ReadonlyArray<number> {
  return Array(end - start + 1)
    .fill(undefined)
    .map((_, idx) => start + idx);
}

export function removeItem<T>(array: T[], value: T): T[] {
  return array.filter((el) => el !== value);
}

export const arrayBufferToBase64 = (buffer: Uint8Array) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
};
