import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * QATests component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    qaTests: {
      height: 'calc(100vh - 23.5rem)',
    },

    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    content: {
      textAlign: 'left',
      padding: theme.spacing(2),
    },

    title: {
      paddingBottom: theme.spacing(2),
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
    },

    radioRow: {
      marginTop: theme.spacing(2),
    },

    radioControl: {
      marginBottom: theme.spacing(1),
    },

    checkbox: {
      padding: theme.spacing(0, 0, 0, 1.125),
    },

    footer: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      borderTop: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
    },
  }),
);
