export const color = {
  primary: {
    // +++ brand colors +++
    mainPurple: '#6B11C9',
    mediumPurple: '#B3A5C3',
    darkPurple: '#100728',
    darkestPurple: '#100728',
    lightPurple: '#C8AEE4',
    lighterPurple: '#F7F0FF',
    lightestPurple: '#FBF5FF',
    // --- brand colors ---
    backgroundLightestGray: '#f9fbfc',
    backgroundLighterGray: '#f6f7f9',
    backgroundLightGray: '#f3f4f9',
    lightGrayDarker: '#dadee2',
    lightGray: '#e8ecf0',
    halfMediumGray: '#b7bcc0',
    mediumGray: '#89939c',
    darkGray: '#999',
    darkestGray: '#494949',
    white: '#fff',
    lightBlack: '#222',
    black: '#000',
    amber: '#ffbf00',
    // old colors TODO: Remove old colors after creation v2
    green: '#0bd4b8',
    gray: '#53565a',
    lightGreen: '#f5fdfb',
    red: '#f00',
  },
  semantic: {
    highlight: '#697ac5',
    highlightLight: '#dee4ff',
    positive: '#689d11',
    positiveLight: '#ecf6da',
    warning: '#A89016',
    warningLight: '#fdf5cd',
    negative: '#9d1111',
    negativeLight: '#ffeaea',
    backgroundNegative: '#FFF6F6',
    neutral: '#89939c',
    neutralLight: '#e8ecf0',
  },
  // old colors TODO: Remove old colors after creation v2
  secondary: {
    base: '#00263a',
    blue: '#0077c8',
    green: '#09edcd',
    orange: '#ff8674',
    gray: '#8c8f96',
    white: '#f6f6f6',
  },
  tertiary: {
    gray: '#d0d0ce',
    white: '#fbfbfb',
  },
  charts: {
    blueLight: '#56bfda',
    blue: '#80c1fa',
    purple: '#796bf6',
    purpleDark: '#6b30cc',
    pink: '#ed71b6',
    orange: '#f9b987',
    red: '#FD6767',
    green: '#86C71C',
  },
};
