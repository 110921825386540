import { memo } from 'react';
import { Box, Button } from '@material-ui/core';

import { Icon, Panel, Typography } from '@components';
import { content, image } from '@content';
import { useStyles } from './MobileOverlay.styles';

export const MobileOverlay = memo((): JSX.Element => {
  const styles = useStyles({ maxWidth: 1023 });

  return (
    <Box className={styles.mobileOverlay}>
      <Box className={styles.overlay}>
        <img className={styles.logo} src={image.composefyPurpleLogo} alt={content.company} />
        <Panel className={styles.panel}>
          <Icon.ExclamationMark className={styles.icon} />
          <Typography.Title className={styles.text}>{content.softwareNotOptimizedForMobile}</Typography.Title>
          <Button variant="contained" color="primary" component="a" href={content.companyPortal}>
            {content.backToCompanyPortal}
          </Button>
        </Panel>
      </Box>
    </Box>
  );
});

MobileOverlay.displayName = 'MobileOverlay';
