import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const badgeLink = {
  display: 'flex',
  alignItems: 'center',
};

const badgeLinkHidden = {
  'pointer-events': 'none',
  cursor: 'default',
  opacity: variables.opacity.none,
};

const badgeLinkUnclickable = {
  '&&': {
    'pointer-events': 'none',
    cursor: 'default',
  },
};

const label = {
  'white-space': 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  'text-align': 'end',
  margin: `0.1rem ${variables.gap.sm} 0.1rem 0`,

  '&&': {
    color: variables.color.primary.gray,
  },
};

const labelHighlighted = {
  '&&': {
    fontWeight: variables.font.weight.semiBold,
    textDecoration: 'underline',
  },
};

export const useStyles = makeStyles(() =>
  createStyles({
    badgeLink,
    badgeLinkHidden,
    badgeLinkUnclickable,
    label,
    labelHighlighted,
  }),
);
