import { Box } from '@material-ui/core';
import React from 'react';

import { BadgeProps } from './Badge.props';
import { useStyles } from './Badge.styles';

export const Badge: React.FC<BadgeProps> = ({
  className = '',
  size = 'large',
  color = 'blue',
  highlighted = false,
}): React.ReactElement => {
  const styles = useStyles();

  return (
    <Box className={`${styles.badge} ${styles[size]} ${styles[color]} ${className}`} data-highlighted={highlighted} />
  );
};
