import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * PublishStates component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    state: {
      padding: theme.spacing(1, 0),
      display: 'flex',
      justifyContent: 'flex-end',

      '& svg': {
        marginLeft: theme.spacing(0.4),
      },
    },

    success: {
      color: variables.color.semantic.positive,
    },

    error: {
      color: variables.color.semantic.negative,
    },

    warning: {
      color: variables.color.semantic.warning,
    },
  }),
);
