import { ForwardedRef, forwardRef } from 'react';
import { Typography } from '@material-ui/core';

import { useTagStyles } from './Typography.styles';
import { TypographyProps } from './Typography.props';

export const Tag = forwardRef(
  ({ className = '', ...props }: TypographyProps, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const styles = useTagStyles();

    return <Typography classes={styles} className={className} {...props} {...{ ref }} />;
  },
);

Tag.displayName = 'Typography.Tag';
