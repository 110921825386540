import { ExperienceItemMindset, MindsetData } from '@modules';

import { DEFAULT_CONDITIONS } from './SignalBuilder.const';

/**
 * SignalBuilder component utils
 */

export const getChosenTreatment = (
  mindsetsData: MindsetData[],
  experienceId: number | null,
  treatmentId: number | null,
) =>
  mindsetsData
    .find((mindsetData) => mindsetData.experienceId === experienceId)
    ?.modules.find((module) => module.id === treatmentId);

export const getChosenTreatmentResetData = (chosenTreatment: ExperienceItemMindset, businessUnitId: number) => ({
  id: chosenTreatment.id,
  businessUnit: {
    id: businessUnitId,
  },
  name: chosenTreatment.name,
  type: 'mixed',
  isEdit: true,
  metConditionCount: chosenTreatment.metConditionCount,
  conditions: chosenTreatment.conditions,
  mindsets: [
    null,
    {
      condition: {
        operator: '',
        required: false,
        signal: { id: undefined },
        value: '',
      },
      conditions: chosenTreatment.conditions.map((condition) => {
        let value: string = condition.value;
        if (typeof condition.value === 'boolean') {
          value = String(condition.value);
        }

        return {
          ...condition,
          value,
        };
      }),
      metConditionCount: chosenTreatment.metConditionCount,
    },
  ],
});

export const getResetData = (businessUnitId: number) => ({
  id: undefined,
  name: '',
  type: 'mixed',
  isEdit: true,
  businessUnit: {
    id: businessUnitId,
  },
  metConditionCount: 1,
  mindsets: [
    null,
    {
      condition: {
        operator: '',
        required: false,
        signal: { id: undefined },
        value: '',
      },
    },
  ],
  conditions: DEFAULT_CONDITIONS,
});
