import { memo } from 'react';
import { areEqual } from 'react-window';
import { Draggable } from 'react-beautiful-dnd';

import { Signal } from '@views';

import { SignalLibraryRowItem } from './SignalLibraryRow.types';
import { SignalLibraryRowProps } from './SignalLibraryRow.props';

export const SignalLibraryRow = memo(({ data, index, style }: SignalLibraryRowProps) => {
  const item = data[index];
  const draggableId = JSON.stringify({
    id: item.id,
    label: item.label,
    type: item.type,
    signalType: item.signalType,
  } as SignalLibraryRowItem);

  return (
    <Draggable draggableId={draggableId} index={index} key={item.id}>
      {(provided) => <Signal provided={provided} item={item} style={style} />}
    </Draggable>
  );
}, areEqual);

SignalLibraryRow.displayName = 'SignalLibraryRow';
