import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

function getColor(type: string): string {
  switch (type) {
    case 'error':
      return variables.color.secondary.orange;
    case 'success':
      return variables.color.primary.green;
    default:
      return variables.color.primary.lightPurple;
  }
}

export const useStyles = makeStyles(() =>
  createStyles({
    deploymentProcessingAlert: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: variables.gap.md,
    },
    icon: {
      fill: ({ type }: { type: string }) => getColor(type),
      marginRight: '1.1rem',
    },
    text: {
      color: ({ type }: { type: string }) => getColor(type),
      marginRight: '1.1rem',

      '& a': {
        color: variables.color.primary.lightPurple,
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.semiBold,
        lineHeight: variables.lineHeight.md,
        letterSpacing: variables.letterSpacing.sm,
        textDecoration: 'underline',
      },
    },
  }),
);
