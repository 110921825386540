import { content } from '@content';
import { OptionItem } from '@components';
/**
 * ContentCenterFilters component constants
 */

export enum OptionsTypes {
  LAST_USED = 'lastUsed',
  SORT_BY = 'sortBy',
  DIVIDER = 'divider',
}

export enum SortValues {
  LAST_USED = 'lastUsed',
  AUTHOR = 'author',
  CREATED = 'createdAt',
  UPDATED = 'updatedAt',
}

export const INITIAL_OPTIONS: OptionItem[] = [
  {
    id: '1',
    type: OptionsTypes.LAST_USED,
    label: content.onlyRecent,
    value: 1,
  },
  {
    id: '2',
    type: OptionsTypes.LAST_USED,
    label: content.everything,
    value: 0,
    active: true,
  },
  {
    id: '3',
    type: OptionsTypes.DIVIDER,
  },
  {
    id: '4',
    label: content.sortBy,
    subOptions: [
      {
        id: '5',
        type: OptionsTypes.SORT_BY,
        label: content.createdDate,
        value: SortValues.CREATED,
        active: true,
      },
      {
        id: '6',
        type: OptionsTypes.SORT_BY,
        label: content.editedDate,
        value: SortValues.UPDATED,
      },
      {
        id: '7',
        type: OptionsTypes.SORT_BY,
        label: content.lastDeployment,
        value: SortValues.LAST_USED,
      },
      {
        id: '8',
        type: OptionsTypes.SORT_BY,
        label: content.author,
        value: SortValues.AUTHOR,
      },
    ],
  },
];

export const ITEMS_PER_PAGE_FOR_SUGGESTIONS = 1000;
