import { memo, useCallback, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Grid } from '@material-ui/core';

import { Modal, Typography } from '@components';
import { content } from '@content';
import { format, useHistory } from '@utils';

import { useStyles } from './ModuleInfoModal.styles';
import { ModuleInfoModalProps } from './ModuleInfoModal.props';

export const ModuleInfoModal = memo(
  ({
    id,
    tag,
    name,
    deploymentsCount,
    clicks,
    impressions,
    clickToImpression,
    disclosureCopy,
    coverImage,
    open,
    onClose,
    onCopy,
  }: ModuleInfoModalProps): JSX.Element => {
    const styles = useStyles();

    const history = useHistory();

    const isCopyDisplay = useMemo(() => !!onCopy && Boolean(history.query.module), [onCopy, history]);

    const params = useMemo(
      () => [
        { label: content.numberOfDeployments, value: deploymentsCount },
        { label: content.totalNumberOfClicks, value: clicks },
        { label: content.totalNumberOfImpressions, value: impressions },
        { label: content.totalClickToImpression, value: clickToImpression },
      ],
      [clickToImpression, clicks, deploymentsCount, impressions],
    );

    const handleViewPerformanceClick = useCallback(() => {
      if (id) {
        history.push('analyticsLevel', { module: id });
      }

      onClose();
    }, [onClose, history, id]);

    const handleCopyClick = useCallback(() => {
      if (onCopy) {
        onCopy(id);
      }
    }, [onCopy, id]);

    return (
      <Modal open={open} onClose={onClose}>
        <Box className={styles.moduleInfoModal}>
          <Box className={styles.header}>
            <Typography.Caption>{tag}</Typography.Caption>
            <Box className={styles.title}>
              <Typography.Display>{name}</Typography.Display>
              <Box className={styles.actions}>
                {onCopy && isCopyDisplay && (
                  <Button className={styles.copy} variant="contained" color="secondary" onClick={handleCopyClick}>
                    {content.copyModule}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="secondary"
                  component={RouterLink}
                  to={history.getUrl('contentCenter', { id })}
                >
                  {content.editView}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className={styles.body}>
            <Grid container className={styles.params}>
              {params.map((param) => (
                <Grid item className={styles.param} key={param.label}>
                  <Typography.Tag>{param.label}</Typography.Tag>
                  {param.label === content.totalClickToImpression ? (
                    <Typography.Caption>{format.numberFracts(param.value, '-')}</Typography.Caption>
                  ) : (
                    <Typography.Caption>{format.number(param.value, '-')}</Typography.Caption>
                  )}
                </Grid>
              ))}
              <Button color="primary" variant="contained" onClick={handleViewPerformanceClick}>
                {content.viewPerformance}
              </Button>
            </Grid>
            {coverImage && <img className={styles.image} src={coverImage} alt={name || ''} />}
            {disclosureCopy && (
              <Box className={styles.disclosure}>
                <Typography.Tag>{content.disclosureCopy}</Typography.Tag>
                <Typography.Body dangerouslySetInnerHTML={{ __html: disclosureCopy }} />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    );
  },
);

ModuleInfoModal.displayName = 'ModuleInfoModal';
