import { useCallback, useMemo, MouseEvent, ChangeEvent } from 'react';
import { Box, Button } from '@material-ui/core';

import { Alert, Table, Icon, Typography, Select, SelectOptions } from '@components';

import { format, textTemplate } from '@utils';
import { variables } from '@styles';
import { content } from '@content';

import { REC_EXTRA_POSTFIX, REC_FILLER_POSTFIX } from './List.const';
import { Col, RecEdit, Rec } from './List.types';
import { useStyles } from './List.styles';
import { ListProps } from './List.props';

export const List = ({
  alert,
  enterprises,
  enterprise,
  onAlertClose,
  onEnterpriseChange,
  onProgramAdd,
  onProgramEdit,
}: ListProps): JSX.Element => {
  const styles = useStyles();

  const enterpriseOptions = useMemo<SelectOptions<number>>(
    () =>
      enterprises.map((ntps) => ({
        id: ntps.id,
        label: ntps.name,
      })),
    [enterprises],
  );

  const handleEnterpriseGroupChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const nextNtpsId = +(event.target.value as string);

      if (nextNtpsId) {
        onEnterpriseChange(nextNtpsId);
      }
    },
    [onEnterpriseChange],
  );

  const handleProgramEditClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const id = +`${event.currentTarget.dataset.id}`;

      if (id) {
        onProgramEdit(id);
      }
    },
    [onProgramEdit],
  );

  const handleProgramAddClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { clntId } = event.currentTarget.dataset;

      if (clntId) {
        onProgramAdd(+clntId);
      }
    },
    [onProgramAdd],
  );

  const cols = useMemo<Col[]>(
    () => [
      {
        id: 'clnt',
        title: content.businessUnit,
        width: '16rem',
        className: styles.clnt,
        render: (val) => <Typography.Headline className={styles.clntValue}>{val}</Typography.Headline>,
      },
      {
        id: 'name',
        title: textTemplate(content.entityName, { entity: content.program }),
        render: (val, recId) =>
          recId.endsWith(REC_FILLER_POSTFIX) ? (
            <Typography.Body className={styles.filler}>
              {textTemplate(content.noEntityAddedIntoContainer, {
                entity: content.program.toLowerCase(),
                container: content.businessUnit.toLowerCase(),
              })}
            </Typography.Body>
          ) : (
            val
          ),
      },
      { id: 'idty', title: content.id, width: '8%' },
      {
        id: 'date',
        title: content.dateAdded,
        width: '12rem',
        render: (val) => (typeof val === 'number' || typeof val === 'string' ? format.date(val, 'PP') : null),
      },
      {
        id: 'edit',
        title: content.state,
        width: '15rem',
        className: styles.edit,
        render: (val) => {
          const edit = val as RecEdit;

          return edit?.subId ? (
            <>
              {edit.archived ? content.archived : content.enabled}
              <Button
                className={styles.editProgram}
                startIcon={<Icon.Edit fill={variables.color.primary.darkestGray} />}
                variant="text"
                size="small"
                data-id={edit.subId}
                onClick={handleProgramEditClick}
              >
                {content.edit}
              </Button>
            </>
          ) : edit?.id ? (
            <Button
              className={styles.addProgram}
              startIcon={<Icon.AddCircleOutline fill={variables.color.primary.darkestGray} />}
              variant="outlined"
              data-clnt-id={edit.id}
              onClick={handleProgramAddClick}
            >
              {textTemplate(content.addValue, { value: content.program })}
            </Button>
          ) : null;
        },
      },
    ],
    [handleProgramEditClick, handleProgramAddClick, styles],
  );

  const recs = useMemo<Rec[]>(
    () =>
      enterprise?.businessUnits?.reduce<Rec[]>(
        (acc, businessUnit) => [
          ...acc,
          ...(businessUnit.programs && businessUnit.programs.length > 0
            ? (businessUnit.programs.map((program, idx) => ({
                id: `${program.id}`,
                clnt: idx === 0 ? businessUnit.name : '',
                name: program.name,
                idty: program.id,
                date: program.createdAt,
                edit: { id: businessUnit.id, subId: program.id, archived: !!program.isArchived } as RecEdit,
              })) as typeof acc)
            : ([
                {
                  id: `${businessUnit.id}${REC_FILLER_POSTFIX}`,
                  clnt: businessUnit.name,
                },
              ] as typeof acc)),
          {
            id: `${businessUnit.id}${REC_EXTRA_POSTFIX}`,
            edit: { id: businessUnit.id } as RecEdit,
          } as (typeof acc)[0],
        ],
        [],
      ) || [],
    [enterprise],
  );

  return (
    <>
      <Box className={styles.header}>
        <Typography.LargeTitle>{content.programs}</Typography.LargeTitle>
        <Typography.Body>{content.manageYourPrograms}</Typography.Body>
      </Box>
      <Select
        className={styles.ntps}
        label={textTemplate(content.selectedEntity, { entity: content.enterpriseGroup })}
        value={`${enterprise?.id || ''}`}
        options={enterpriseOptions}
        onChange={handleEnterpriseGroupChange}
      />
      {alert && (
        <Alert
          type="success"
          message={textTemplate(content.entitySuccessfullyProceed, {
            entity: content.program,
            proceed: content[alert].toLowerCase(),
          })}
          className={styles.alert}
          align="left"
          onClose={onAlertClose}
        />
      )}
      <Table uiType="tertiary" autofit measures={{ recordHeight: '' }} columns={cols} records={recs} />
    </>
  );
};
