import { makeStyles, createStyles, StyleRules } from '@material-ui/core/styles';

import { COLUMNS } from './Disclosure.const';
import { ColumnId } from './Disclosure.types';

const { name, copy, edit } = ColumnId;

const nameRealWidth = '19.75%';
const nameLeftGap = '4rem';
const editIconSize = '4rem';

const colsWidths = {
  [name]: `${nameRealWidth} + ${nameLeftGap}`,
  [copy]: '31.5%',
  [edit]: '11.33%',
};

const colsMixin: StyleRules<ColumnId> = Object.entries(colsWidths).reduce(
  (acc, [key, width]) => ({ ...acc, [key]: { width } }),
  {} as StyleRules<ColumnId>,
);

const colsCommonWidth = `(${Object.values(colsWidths).join(' + ')})`;

const regularColumnsCount = COLUMNS.length - Object.keys(colsWidths).length;

export const useStyles = makeStyles(() =>
  createStyles({
    ...colsMixin,

    column: {
      width: `calc((100% - ${colsCommonWidth}) / ${regularColumnsCount})`,
    },

    [name]: {
      width: nameRealWidth,
      marginLeft: nameLeftGap,
    },

    [edit]: {
      ...colsMixin[edit],

      '& > .MuiIconButton-root': {
        width: editIconSize,
        height: editIconSize,
        padding: 0,

        '& > .MuiIconButton-label': {
          height: '100%',

          '& > a[href]': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          },
        },
      },
    },
  }),
);
