import { ChangeEvent, memo, useMemo } from 'react';
import { Box, Button, MenuItem } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

import { Typography, Select, Icon, Alert } from '@components';
import { BetweenField, TimesField, DateField } from '@views';
import { content } from '@content';
import { Signal } from '@modules';

import { useStyles } from './Condition.styles';
import { ConditionProps, ArgumentType } from './Condition.props';
import { SIGNAL_ARGUMENTS } from './Condition.const';

import { ConditionSignalValue } from './ConditionSignalValue';
import { RequiredField } from './RequiredField';
import { textTemplate } from '@utils';

/**
 * Condition component
 * @returns {JSX.Element}
 */

export const Condition = memo(
  ({ uiType, condition, onAdd, mindsetIndex, error = '', onErrorClose }: ConditionProps): JSX.Element => {
    const styles = useStyles();
    const { setValue, watch, getFieldState } = useFormContext();

    const signal: Signal = watch(`mindsets.${mindsetIndex}.condition.signal`);
    const operator = watch(`mindsets.${mindsetIndex}.condition.operator`);
    const conditionsInit = watch(`mindsets.${mindsetIndex}.conditions`);
    const conditions = useMemo(() => conditionsInit || [], [conditionsInit]);

    const handleSignalOptionsChange = (
      event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
      field: ControllerRenderProps,
    ) => {
      const value = event.target.value;
      setValue(field.name, value as string, { shouldValidate: true });
      setValue(`mindsets.${mindsetIndex}.condition.value.${field.value}`, '');
      setValue(`mindsets.${mindsetIndex}.condition.value`, '');
    };

    return (
      <Box>
        {error && <Alert message={content.pleaseCorrectlyFillFields} description={error} onClose={onErrorClose} />}
        <Box className={uiType === 'signal' ? styles.signalTop : styles.treatmentTop}>
          <Box className={uiType === 'signal' ? styles.signalBox : styles.treatmentBox}>
            <Droppable droppableId={`mindsets.${mindsetIndex}.condition.signal`} type="SIGNAL">
              {(provided) => (
                <Box
                  className={styles.signalFieldContainer}
                  {...{ ref: provided.innerRef }}
                  {...provided.droppableProps}
                >
                  <Controller
                    name={`mindsets.${mindsetIndex}.condition.signal`}
                    defaultValue={signal}
                    rules={{
                      validate: (val) =>
                        (conditions && conditions.length && !condition.operator && !val.label && !condition.value) ||
                        !!val.label,
                    }}
                    render={({ field: { value }, fieldState }) => (
                      <>
                        <Typography.Label className={styles.label} data-invalid={fieldState.invalid}>
                          {content.attribute}
                        </Typography.Label>
                        <Box
                          className={styles.signalField}
                          data-signal={!!value.label}
                          data-invalid={fieldState.invalid}
                        >
                          {value?.label && signal?.label ? (
                            <>
                              <Typography.Headline
                                component="span"
                                title={value?.label}
                                display="inline"
                                noWrap
                                data-invalid={fieldState.invalid}
                              >
                                {value?.label}
                              </Typography.Headline>
                            </>
                          ) : (
                            <Typography.Body className={styles.placeholder} data-invalid={fieldState.invalid}>
                              {content.dragAnAttribute}
                            </Typography.Body>
                          )}
                        </Box>
                      </>
                    )}
                  />
                </Box>
              )}
            </Droppable>
          </Box>
          <Box className={uiType === 'signal' ? styles.signalArgumentBox : styles.treatmentArgumentBox}>
            <Controller
              name={`mindsets.${mindsetIndex}.condition.operator` as const}
              defaultValue={condition.operator}
              rules={{
                validate: (val) => {
                  if (signal.type === 'boolean') {
                    return (conditions.length && !val.operator && !condition.signal?.label) || !val;
                  }

                  return (conditions.length && !val.operator && !condition.signal?.label && !condition.value) || !!val;
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <Typography.Label className={styles.label} data-invalid={fieldState.invalid}>
                    {content.condition}
                  </Typography.Label>
                  {signal?.type === 'boolean' ? (
                    <Typography.Body className={styles.booleanCondition}>{content.has}</Typography.Body>
                  ) : (
                    <Select
                      className={styles.signalOptionsDropdown}
                      inputRef={field.ref}
                      value={field.value || ''}
                      error={fieldState.invalid}
                      onChange={(event: any) => handleSignalOptionsChange(event, field as ControllerRenderProps)}
                      placeholder={content.select}
                      onBlur={field.onBlur}
                    >
                      {signal?.type ? (
                        SIGNAL_ARGUMENTS[signal.type].map((argument: ArgumentType) => (
                          <MenuItem key={argument.value} value={argument.value}>
                            {argument.label}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">&nbsp;</MenuItem>
                      )}
                    </Select>
                  )}
                </>
              )}
            />
          </Box>
          {signal?.signalType !== 'statistic' && (
            <Box className={uiType === 'signal' ? styles.signalValueBox : styles.treatmentValueBox}>
              <Typography.Label
                className={styles.label}
                data-invalid={getFieldState(`mindsets.${mindsetIndex}.condition.value`).invalid}
              >
                {content.value}
              </Typography.Label>
              <ConditionSignalValue
                defaultValue={condition.value}
                freeSolo={signal?.type === 'numeric'}
                signalId={signal?.id}
                disabled={!signal}
                mindsetIndex={mindsetIndex}
              />
            </Box>
          )}
          {signal?.signalType === 'statistic' && operator === 'between' && (
            <BetweenField
              mindsetIndex={mindsetIndex}
              defaultValue={typeof condition.value !== 'string' ? condition.value : undefined}
            />
          )}
          {signal?.signalType === 'statistic' && ['on', 'before', 'after'].includes(operator) && (
            <DateField mindsetIndex={mindsetIndex} withLabel operator={operator} />
          )}
          {signal?.signalType === 'statistic' && operator === '=' && (
            <TimesField defaultValue={condition.value} mindsetIndex={mindsetIndex} withLabel />
          )}
          <Box className={styles.buttonContainer}>
            <Button variant="outlined" onClick={onAdd} className={styles.addButton}>
              <Icon.AddCircleOutline className={styles.iconAdd} />
              {textTemplate(content.addValue, { value: content.rule })}
            </Button>
          </Box>
        </Box>
        <Box className={styles.bottomContainer}>
          <RequiredField mindsetIndex={mindsetIndex} />
        </Box>
      </Box>
    );
  },
);

Condition.displayName = 'Condition';
