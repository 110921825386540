import { Counts } from './Experience.schema';
import { MindsetCounts, MindsetAudienceInfo } from './Experience.types';
import { MindsetTableElement } from './Experience.schema';

export const calcAudienceShare = (
  mindsetAudienceInfo: MindsetAudienceInfo,
  infos: MindsetAudienceInfo[] = [],
): number => {
  const local = mindsetAudienceInfo.audienceSize || 0;
  const total = infos.reduce<number>((summ, info) => summ + (info.audienceSize || 0), 0);
  const share = (local / total) * 100;

  return share || 0;
};

export const calcMindsetCounts = (counts: Counts): MindsetCounts => {
  const audience = (counts.match / counts.total) * 100;

  return {
    targetCount: counts.match,
    audience: Number.isNaN(audience) ? 0 : audience,
  };
};

export const convertStringToBool = (value: string | boolean | number) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

export const contentCount = (experiencesTables: MindsetTableElement[]): [number, number] => {
  let none = 0;
  let html = 0;

  experiencesTables.forEach((element) => {
    const products = element.products;

    products?.forEach((product) => {
      const mindsets = product.mindsets;
      Object.keys(mindsets).forEach((key) => {
        if (mindsets[key].hasContent) {
          html += 1;
        } else {
          none += 1;
        }
      });
    });

    if (element.defaultMindset?.hasContent) {
      html += 1;
    } else {
      none += 1;
    }
  });

  return [html, none];
};
