import { memo } from 'react';
import { Box } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { experience, deployment } from '@modules';
import { Typography } from '@components';
import { content } from '@content';
import { JourneyLibraryItem } from './JourneyLibraryItem';
import { useListStyles } from './JourneyLibrary.styles';
import { JourneyLibraryListProps } from './JourneyLibrary.types';

export const JourneyLibraryList = memo(({ selectJourney, loadMore }: JourneyLibraryListProps): JSX.Element => {
  const styles = useListStyles();
  const { items, currentPage, pageCount, total } = experience.useListData();
  const currentDeployment = deployment.useDeploymentData();
  const selectedJourneys =
    (currentDeployment?.positions || []).filter((i) => i.experience).map((i) => i.experience?.id) || [];

  return (
    <Droppable droppableId="journeyLibrary" type="JOURNEY">
      {(provided) => (
        <Box {...{ ref: provided.innerRef }} {...provided.droppableProps}>
          {total > 0 ? (
            <InfiniteScroll
              hasMore={currentPage < pageCount}
              next={loadMore}
              dataLength={items.length}
              loader={<Typography.Body className={styles.loader}>Loading...</Typography.Body>}
              scrollableTarget="journeyListWrapper"
            >
              {items
                .filter((i) => !selectedJourneys.includes(i.id))
                .map((journey, index) => (
                  <JourneyLibraryItem
                    key={journey.id}
                    index={index}
                    id={journey.id}
                    name={journey.name}
                    author={journey.author?.id}
                    createdAt={journey.createdAt}
                    updatedAt={journey.updatedAt}
                    lastUsed={journey.lastUsed}
                    selectJourney={selectJourney}
                  />
                ))}
            </InfiniteScroll>
          ) : (
            <Typography.Body className={styles.loader}>{content.noItemsFound}</Typography.Body>
          )}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
});

JourneyLibraryList.displayName = 'JourneyLibraryList';
