export const Copy: React.FC = (): React.ReactElement => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#copy)">
      <path
        d="M16.8756 6.875V4.375C16.8756 4.04348 16.7439 3.72554 16.5095 3.49112C16.275 3.2567 15.9571 3.125 15.6256 3.125H12.2922"
        stroke="#003B5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20833 3.125H1.875C1.54348 3.125 1.22554 3.2567 0.991117 3.49112C0.756696 3.72554 0.625 4.04348 0.625 4.375V18.125C0.625 18.4565 0.756696 18.7745 0.991117 19.0089C1.22554 19.2433 1.54348 19.375 1.875 19.375H6.875"
        stroke="#003B5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0171 3.9475C11.9755 4.07197 11.8959 4.18022 11.7894 4.25693C11.6829 4.33365 11.555 4.37495 11.4238 4.375H6.07547C5.94424 4.37495 5.81634 4.33365 5.70987 4.25693C5.60339 4.18022 5.52373 4.07197 5.48214 3.9475L4.6488 1.4475C4.61731 1.35353 4.60865 1.2534 4.62354 1.15541C4.63842 1.05742 4.67643 0.964386 4.73442 0.884004C4.79241 0.803622 4.8687 0.738205 4.95699 0.693166C5.04528 0.648127 5.14302 0.624761 5.24214 0.625002H12.2571C12.3562 0.624761 12.454 0.648127 12.5423 0.693166C12.6306 0.738205 12.7069 0.803622 12.7649 0.884004C12.8228 0.964386 12.8608 1.05742 12.8757 1.15541C12.8906 1.2534 12.882 1.35353 12.8505 1.4475L12.0171 3.9475Z"
        stroke="#003B5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.125 9.375H10.625C9.93464 9.375 9.375 9.93464 9.375 10.625V18.125C9.375 18.8154 9.93464 19.375 10.625 19.375H18.125C18.8154 19.375 19.375 18.8154 19.375 18.125V10.625C19.375 9.93464 18.8154 9.375 18.125 9.375Z"
        stroke="#003B5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.875 11.875H16.875" stroke="#003B5C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.875 14.375H16.875" stroke="#003B5C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.875 16.875H13.75" stroke="#003B5C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="copy">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
