import { SVGProps } from 'react';

export const DataOutline = ({
  width = 24,
  height = 24,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.8843 14.5L19.45 15.7347C20.1833 16.068 20.1833 16.6138 19.45 16.9472L13.283 19.7597C12.5792 20.0801 11.4208 20.0801 10.717 19.7597L4.55003 16.9472C3.81666 16.6138 3.81666 16.068 4.55003 15.7347L7.40755 14.4525M19.4466 7.06877L13.223 4.23125C12.5484 3.92292 11.4441 3.92292 10.7695 4.23125L4.55003 7.06877C3.81666 7.40211 3.81666 7.94753 4.55003 8.28086L10.717 11.0934C11.4208 11.4142 12.5792 11.4142 13.283 11.0934L19.45 8.28086C20.18 7.94753 20.18 7.40169 19.4466 7.06877Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9993 10L4.5499 11.598C3.8167 11.942 3.8167 12.5054 4.5499 12.8494L10.7155 15.7517C11.4191 16.0828 12.5772 16.0828 13.2809 15.7517L19.4465 12.8494C20.1838 12.5054 20.1838 11.942 19.4506 11.598L15.9979 10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
