import { makeStyles, createStyles } from '@material-ui/core';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    private: {
      display: 'flex',
      flexDirection: 'row-reverse',
      position: 'relative',
    },

    side: {
      position: 'relative',
      width: variables.sideBar.width,
      height: '100vh',
      zIndex: theme.zIndex.drawer,
    },

    sidebar: {
      position: 'absolute',
      height: '100%',
    },

    body: {
      display: 'flex',
      flexDirection: 'column',
      width: `calc(100% - ${variables.sideBar.width})`,
      height: '100vh',
      position: 'relative',
    },

    content: {
      padding: theme.spacing(3, 8),
      background: variables.color.primary.backgroundLighterGray,
      height: `calc(100% - ${variables.topBar.height})`,
      overflow: 'hidden',

      '&[data-hide-top-bar=true]': {
        padding: 0,
        height: '100%',
      },
    },

    warningMessage: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
      zIndex: 1,
    },

    updateButton: {
      marginRight: theme.spacing(1),
    },
  }),
);
