import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Treatments component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    treatments: {
      height: '100%',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      padding: theme.spacing(2.5, 2),
    },

    panelTitle: {
      fontSize: '16px!important',
      lineHeight: '20px!important',
      color: `${variables.color.primary.darkPurple}!important`,
      marginBottom: theme.spacing(0.5),

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        wordBreak: 'break-word',
      },
    },

    addTreatment: {
      marginRight: theme.spacing(1),

      '&.hovered': {
        color: variables.color.primary.mainPurple,
        border: `0.2rem solid ${variables.color.primary.lightPurple}`,
      },
      '&.hovered svg path': {
        stroke: variables.color.primary.mainPurple,
      },

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        width: '100%',
      },
    },

    renameBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    renameBarButtons: {
      display: 'flex',
    },

    menu: {
      padding: theme.spacing(1),
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
    },

    treatmentQuestionBody: {
      marginTop: '15px',
    },

    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
    },

    targetingBtn: {
      width: 'fit-content',
    },

    targetTreatments: {
      padding: theme.spacing(1.25, 2),
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      backgroundColor: variables.color.primary.backgroundLightestGray,
    },

    targetTreatmentsOptions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1.25, 2),
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      backgroundColor: variables.color.primary.backgroundLightestGray,
    },

    content: {
      padding: theme.spacing(2),
    },

    treatmentCards: {
      padding: theme.spacing(2),
      overflow: 'auto',
      height: 'calc(100vh - 310px)',
    },

    groupedTreatments: {
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
    },

    treatmentWrapper: {
      '&:nth-of-type(n)': {
        borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      },

      '&:nth-last-of-type(1)': {
        borderBottom: 'none',
      },
    },
  }),
);
