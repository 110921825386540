import { useState, useRef, useCallback } from 'react';
import { Button, Box } from '@material-ui/core';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';

import { Typography, Tooltip, TooltipHandle } from '@components';
import { content } from '@content';

import { MindsetModal } from '@views';
import { PillProps } from './Pill.props';
import { useStyles } from './Pill.styles';

/**
 Pill component.
 Gets an array of mindset items and outputs pill component.
 It uses in Mindset Table.
 @returns {JSX.Element}
 */

export const Pill = ({
  value,
  percentage,
  color,
  hoveredInSidebar = false,
  hidden,
  modal,
  entity,
  fullWidth,
  droppableId,
  useDroppable = false,
}: PillProps): JSX.Element => {
  const styles = useStyles();
  const [highlighted, setHighlighted] = useState(false);
  const handleHover = useCallback(() => {
    setHighlighted((prevState) => !prevState);
  }, [setHighlighted]);
  const childRef = useRef<TooltipHandle>(null);
  const tooltipId = Date.now().toString();

  const handleElementHide = (el: HTMLElement | null | undefined) => {
    if (el) el.style.display = 'none';
  };

  let modalTooltip: string | JSX.Element;
  if (modal === 'no content' || !entity) {
    modalTooltip = (
      <Typography.Caption className={styles.tooltipNoContent}>{content.noContentHasYetBeenAdded}</Typography.Caption>
    );
  } else {
    modalTooltip = (
      <>
        <MindsetModal
          entity={entity}
          onClose={() => {
            childRef.current?.showTooltip();
          }}
          handleCloseTooltip={() => handleElementHide(document.getElementById(tooltipId))}
        />
      </>
    );
  }

  const pillButton = (droppableProvided: DroppableProvided | null) => {
    let buttonDroppableProps;
    if (droppableProvided) {
      buttonDroppableProps = {
        ...{ ref: droppableProvided.innerRef },
        ...droppableProvided.droppableProps,
      };
    }
    return (
      <Button
        className={`${styles.pill} ${styles[color]} ${hidden ? styles.hidden : ''} 
              ${fullWidth ? styles.fullWidth : ''}
              ${highlighted || hoveredInSidebar ? styles.highlighted : ''}`}
        data-color={color}
        onClick={() => {
          setHighlighted(true);
          childRef.current?.showTooltip();
        }}
        {...buttonDroppableProps}
      >
        <Typography.Body component="span" className={styles.value} data-value>
          {value}
        </Typography.Body>
        {percentage !== '0' && (
          <Typography.Body component="span" className={styles.percentage} data-percentage>
            {`(${percentage}%)`}
          </Typography.Body>
        )}
      </Button>
    );
  };

  return (
    <Tooltip
      text={modalTooltip}
      align="top"
      tooltipOnClick
      ref={childRef}
      handleTooltipHover={handleHover}
      tooltipId={tooltipId}
    >
      <Box>
        {useDroppable ? (
          <Droppable droppableId={droppableId.toString()} type="MODULE">
            {(droppableProvided) => pillButton(droppableProvided)}
          </Droppable>
        ) : (
          pillButton(null)
        )}
      </Box>
    </Tooltip>
  );
};
