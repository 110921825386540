import { useCallback } from 'react';

import { Card } from '@components';
import { content } from '@content';
import { format } from '@utils';

import { ModuleCardProps } from './ModuleCard.props';

export const ModuleCard = ({ className = '', module, onViewDetailsClick }: ModuleCardProps): JSX.Element => {
  const handleEyeClick = useCallback(() => onViewDetailsClick(module), [onViewDetailsClick, module]);

  return (
    <Card
      className={className}
      image={module.coverImage}
      pretitle={content.product}
      title={module.productName || ''}
      params={
        module.statistics
          ? [
              {
                label: content.cohort,
                value: module.cohortName,
              },
              {
                label: content.deployment,
                value: module.deployment?.name,
              },
              {
                label: content.totalImpressions,
                value: format.number(module.statistics.totalImpressions, ''),
              },
              {
                label: content.ctir,
                value: format.percent(module.statistics.ctir),
              },
              {
                label: content.moduleTag,
                value: module.tag?.tag,
              },
            ]
          : undefined
      }
      warning={content.requestReporting}
      onEyeClick={handleEyeClick}
    />
  );
};
