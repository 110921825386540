import {
  ApiError,
  businessUnitDeployments,
  DeploymentPublishPayload,
  global,
  recommendedModules,
  SearchDeploymentPayload,
} from '@modules';
import { network, parse, textTemplate } from '@utils';
import { createAsyncThunk } from '@store';
import { content, page } from '@content';

import {
  Deployment,
  DeploymentEntity,
  DataVizGenerationStatusPayload,
  PublishModulePayload,
  InfoEmailTemplatePayload,
  DeploymentEntityData,
  DeploymentSearchParams,
  AttachMailFileParams,
} from './Deployment.schema';
import { DeploymentCreatePayload } from './Deployment.types';

export const create = createAsyncThunk(
  'deployment/create',
  async (
    payload: Omit<DeploymentCreatePayload, 'isCloning'> & { shouldCreateCopy: boolean },
    { dispatch, signal, rejectWithValue },
  ) => {
    try {
      return await network.post<Deployment>(
        { key: 'deployment_create' },
        {
          signal,
          body: JSON.stringify(payload),
        },
      );
    } catch (exception) {
      const apiError = exception as ApiError;

      dispatch(global.actions.enqueueError(apiError));

      return rejectWithValue(apiError);
    }
  },
);

export const archive = createAsyncThunk<void, number>(
  'deployment/archive',
  async (deployment, { rejectWithValue, dispatch, extra }) => {
    try {
      // dispatch(global.actions.isLoading(true));
      const resp = await network.put<Deployment>({ key: 'archive_deployment', params: { deployment } });

      if (resp) {
        extra.history.push(`${page.experienceBuilder}?businessUnit=${resp.businessUnit.id}`);
        dispatch(businessUnitDeployments.thunk.getNonArchived(resp.businessUnit.id));
      }

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.archivedSuccessfully, { value: content.deployment }),
      //     options: { variant: 'success' },
      //   }),
      // );
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));
      rejectWithValue(error);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const update = createAsyncThunk<
  Deployment | void,
  {
    deployment: number;
    body: FormData;
  }
>('deployment/update', async ({ deployment, body }, { dispatch, rejectWithValue, extra }) => {
  const searchParams = new URLSearchParams(extra.history.location.search);
  const businessUnit = parse.integer(searchParams.get('businessUnit') || '');

  try {
    const resp = await network.post<Deployment>({ key: 'deployment_update', params: { deployment } }, { body });
    dispatch(businessUnitDeployments.thunk.getNonArchived(businessUnit));
    // dispatch(
    //   global.actions.enqueueNotification({
    //     message: content.successfullyCompleted,
    //     options: { variant: 'success' },
    //   }),
    // );

    return resp;
  } catch (exception) {
    const error = exception as ApiError;

    dispatch(global.actions.enqueueError(error));

    return rejectWithValue(error);
  }
});

export const clone = createAsyncThunk<
  Deployment | void,
  {
    deployment: number;
    data: FormData;
  }
>('deployment/clone', async ({ deployment, data }, { dispatch, rejectWithValue, extra }) => {
  try {
    const clonedDeployment = await network.post<Deployment>(
      { key: 'deployment_clone', params: { deployment } },
      { body: data },
    );

    if (clonedDeployment) {
      await dispatch(businessUnitDeployments.thunk.getNonArchived(clonedDeployment.businessUnit.id));
      extra.history.push(
        `${page.dataCenter}?businessUnit=${clonedDeployment.businessUnit.id}&deployment=${clonedDeployment.id}`,
      );
    }

    // dispatch(
    //   global.actions.enqueueNotification({
    //     message: content.successfullyCompleted,
    //     options: { variant: 'success' },
    //   }),
    // );

    return clonedDeployment;
  } catch (exception) {
    const error = exception as ApiError;

    dispatch(global.actions.enqueueError(error));

    return rejectWithValue(error);
  }
});

export const getById = createAsyncThunk(
  'deployment/getById',
  async (deployment: number, { rejectWithValue, signal }) => {
    try {
      return await network.get<DeploymentEntity>({ key: 'deployment_info', params: { deployment } }, { signal });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const attachMailFile = createAsyncThunk(
  'deployment/attachMailFile',
  ({ deploymentId, mailFileId }: AttachMailFileParams, { dispatch, rejectWithValue }) => {
    try {
      return network.post<Deployment>(
        { key: 'deployment_update', params: { deployment: deploymentId } },
        {
          body: JSON.stringify({
            mailFile: mailFileId === -1 ? null : { id: mailFileId },
          }),
        },
      );
    } catch (exception) {
      const error = exception as ApiError;
      dispatch(global.actions.enqueueError(error));
      return rejectWithValue(error);
    }
  },
);

export const attachJourney = createAsyncThunk(
  'deployment/attachJourney',
  async (deployment: DeploymentEntityData, { dispatch, rejectWithValue }) => {
    try {
      if (!deployment) {
        dispatch(
          global.actions.enqueueNotification({
            message: textTemplate(content.pleaseSelect, { field: content.deployment }),
            options: { variant: 'error' },
          }),
        );
        return undefined;
      }

      // dispatch(global.actions.isLoading(true));

      const resp = await network.post<Deployment>(
        { key: 'deployment_update', params: { deployment: deployment.id } },
        {
          body: JSON.stringify({
            positions: deployment.positions
              .filter((position) => !!position?.experience)
              .map((position) => ({
                ...(position.id && { id: position.id }),
                experience: {
                  id: position.experience?.id,
                  name: position.experience?.name,
                },
                index: position.order,
              })),
            mailFile: deployment.mailFile?.id
              ? {
                  id: deployment.mailFile?.id,
                }
              : null,
          }),
        },
      );

      dispatch(recommendedModules.thunk.get(deployment.id));

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.updatedSuccessfully, { value: content.deployment }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return resp;
    } catch (exception) {
      const error = exception as ApiError;
      dispatch(global.actions.enqueueError(error));
      return rejectWithValue(error);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const uploadMailFile = createAsyncThunk<
  void,
  {
    deployment: number;
    body: FormData;
  }
>('deployment/uploadMailFile', async ({ deployment, body }, { dispatch, rejectWithValue }) => {
  try {
    // dispatch(global.actions.isLoading(true));
    await network.post({ key: 'deployment_upload_mail_file', params: { deployment } }, { body });

    // dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.updatedSuccessfully, { value: content.subscriberList }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return undefined;
  } catch (exception) {
    const error = exception as ApiError;

    dispatch(global.actions.enqueueError(error));

    return rejectWithValue(error);
  } finally {
    // dispatch(global.actions.isLoading(false));
  }
});

export const publishEmailFile = createAsyncThunk<
  Deployment | void,
  {
    deployment: number;
    body: FormData;
  }
>('deployment/publishEmailFile', async ({ deployment, body }, { rejectWithValue, dispatch }) => {
  try {
    const resp = await network.post<Deployment>(
      { key: 'publish_deployment_email_file', params: { deployment } },
      { body },
    );

    // dispatch(
    //   global.actions.enqueueNotification({
    //     message: content.successfullyCompleted,
    //     options: { variant: 'success' },
    //   }),
    // );

    return resp;
  } catch (exception) {
    const error = exception as ApiError;

    dispatch(global.actions.enqueueError(error));

    return rejectWithValue(error);
  }
});

export const publishSmartFile = createAsyncThunk<Deployment | void, number>(
  'deployment/publishSmartFile',
  async (deployment, { rejectWithValue, dispatch }) => {
    try {
      return await network.post<Deployment>({ key: 'publish_deployment_smart_file', params: { deployment } });
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const publishDeployment = createAsyncThunk(
  'deployment/publishDeployment',
  async ({ deployment }: DeploymentPublishPayload, { signal, rejectWithValue, dispatch }) => {
    try {
      const resp = await network.post<Deployment>({ key: 'publish_deployment', params: { deployment } }, { signal });

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: content.successfullyCompleted,
      //     options: { variant: 'success' },
      //   }),
      // );

      return resp;
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const getGenerationStatus = createAsyncThunk<DataVizGenerationStatusPayload | void, number>(
  'deployment/getGenerationStatus',
  async (deployment, { rejectWithValue }) => {
    try {
      return await network.get({ key: 'get_deployment_modules_generation_status', params: { deployment } });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  },
);

export const getModulesList = createAsyncThunk<PublishModulePayload[] | void, number>(
  'deployment/getModulesList',
  async (deployment, { rejectWithValue, signal }) => {
    try {
      return await network.get<PublishModulePayload[]>(
        { key: 'get_deployment_modules_list', params: { deployment } },
        { signal },
      );
    } catch (e) {
      return rejectWithValue(e as ApiError);
    }
  },
);

export const exportSmartFile = createAsyncThunk(
  'deployment/exportSmartFile',
  (deployment: number, { dispatch, rejectWithValue }) => {
    try {
      // dispatch(global.actions.isLoading(true));
      return network.get<Response>({ key: 'export_deployment_smart_file', params: { deployment } });
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const exportEntirePackageFile = createAsyncThunk(
  'deployment/exportEntirePackageFile',
  (deployment: number, { dispatch, rejectWithValue }) => {
    try {
      // dispatch(global.actions.isLoading(true));
      return network.get<Response>({ key: 'export_deployment_entire_files', params: { deployment } });
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const exportModulesFile = createAsyncThunk(
  'deployment/exportModulesFile',
  (
    { deploymentId, moduleIds }: { deploymentId: number; moduleIds: number[] },
    { dispatch, rejectWithValue, signal },
  ) => {
    try {
      // dispatch(global.actions.isLoading(true));
      return network.post<Response>(
        { key: 'export_segments_files', params: { deployment: deploymentId } },
        { signal, body: JSON.stringify({ moduleIds }) },
      );
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const exportTemplateFile = createAsyncThunk(
  'deployment/exportTemplateFile',
  (deployment: number, { dispatch, rejectWithValue }) => {
    try {
      // dispatch(global.actions.isLoading(true));
      return network.get<Response>({ key: 'export_deployment_email_template', params: { deployment } });
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const getInfoEmailTemplate = createAsyncThunk(
  'deployment/getInfoEmailTemplate',
  async (deployment: number, { dispatch, rejectWithValue }) => {
    try {
      // dispatch(global.actions.isLoading(true));
      return await network.get<InfoEmailTemplatePayload>({
        key: 'deployment_info_email_template',
        params: { deployment },
      });
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const search = createAsyncThunk(
  'deployment/search',
  async ({ businessUnit, name }: DeploymentSearchParams, { rejectWithValue }) => {
    try {
      return await network.get<SearchDeploymentPayload>({
        key: 'search_deployments_by_business_unit',
        params: {
          paginated: false,
          businessUnit: businessUnit,
          name,
        },
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  },
);
