import { useLocation } from 'react-router-dom';

import { parse } from '@utils';

export type SearchQueryParamPureValue = string;

export type SearchQueryParamValue = SearchQueryParamPureValue | number;

export type SearchQueryParamValueNullable = SearchQueryParamValue | null;

export type SearchQueryParams<Key extends string = string, Value = SearchQueryParamValueNullable> = Partial<
  Record<Key, Value>
>;

export const useParams = (...names: string[]): string[] => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return names.map((name) => params.get(name) || '');
};

export const useMutualParams = (...names: string[]): number[] => useParams(...names).map(parse.integer);

export const stringifyParams = <ParamKey extends string = string>(paramsMap: SearchQueryParams<ParamKey>) => {
  const params = Object.entries({ ...paramsMap })
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return params ? `?${params}` : '';
};

export const parseParams = <ParamKey extends string = string>(
  search: string,
): SearchQueryParams<ParamKey, SearchQueryParamPureValue> =>
  Object.fromEntries(
    Array.from(new URLSearchParams(search).entries()).filter(([, value]) => value),
  ) as SearchQueryParams<ParamKey, SearchQueryParamPureValue>;

export const updateParams = <ParamKey extends string = string>(
  search: string,
  freshParams: SearchQueryParams<ParamKey>,
): string =>
  stringifyParams<ParamKey>({
    ...parseParams<ParamKey>(search),
    ...freshParams,
  });
