import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    journeyInfo: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      flex: '0 0 32rem',
      marginRight: '2rem',
    },
    journeyInfoBox: {
      textAlign: 'center',
    },
    journeyInfoLabel: {
      marginBottom: variables.gap.sm,
    },
    journeyInfoValue: {
      fontWeight: variables.font.weight.semiBold,
      color: variables.color.primary.gray,
    },
  }),
);
