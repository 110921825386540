import { ForwardedRef, forwardRef } from 'react';
import { Typography } from '@material-ui/core';

import { useHeadlineStyles } from './Typography.styles';
import { TypographyProps } from './Typography.props';

export const Headline = forwardRef(
  ({ className = '', ...props }: TypographyProps, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const styles = useHeadlineStyles();

    return <Typography classes={styles} className={className} {...props} {...{ ref }} />;
  },
);

Headline.displayName = 'Typography.Headline';
