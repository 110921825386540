import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/*
  Table Legend styles
*/

export const useStyles = makeStyles((theme) =>
  createStyles({
    legend: {
      display: 'flex',
      justifyContent: 'flex-end',
    },

    badge: {
      flexShrink: 0,
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: variables.borderRadius.half,
      marginRight: theme.spacing(1),
    },

    transparent: {
      backgroundColor: 'transparent',
      border: `solid 1px ${variables.color.primary.mediumGray}`,
    },

    green: {
      backgroundColor: variables.color.semantic.positive,
    },

    yellow: {
      backgroundColor: variables.color.semantic.warning,
    },

    item: {
      overflow: 'auto',
      display: 'flex',
      alignItems: 'center',
      marginLeft: variables.gap.md,
    },

    label: {
      fontSize: variables.font.size.md,
      lineHeight: variables.lineHeight.lg,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      'white-space': 'nowrap',
    },

    count: {
      fontSize: variables.font.size.md,
      lineHeight: variables.lineHeight.lg,
      marginLeft: variables.gap.xxs,
    },
  }),
);
