/**
 * ImageLibrary constants
 */

export enum ImageLibraryLayout {
  GRID = 'grid',
  LIST = 'list',
}

export const headerMenuOptions = [
  {
    id: 'Image Library',
    label: 'Open Image Library',
    active: false,
  },
];
