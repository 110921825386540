import { ModuleStatus } from '@modules';
import { content } from '@content';

import { Statuses } from './PublishToESP.types';

export const STATUSES: Statuses = {
  [ModuleStatus.notPublished]: { color: 'tertiaryGray', label: content.unpublished },
  [ModuleStatus.published]: { color: 'primaryBase', label: content.published },
  [ModuleStatus.modified]: { color: 'orange', label: content.modified },
};
