import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * BoldV2 component
 * @returns {JSX.Element}
 */

export const BoldV2 = ({
  className = '',
  width = 7,
  height = 10,
  fill = variables.color.primary.mediumGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.6 4.85C6.23163 4.37143 6.67442 3.58571 6.67442 2.85714C6.67442 1.24286 5.53488 0 4.06977 0H0V10H4.58419C5.94512 10 7 8.78571 7 7.29286C7 6.20714 6.44 5.27857 5.6 4.85ZM1.95349 1.78571H3.90698C4.44744 1.78571 4.88372 2.26429 4.88372 2.85714C4.88372 3.45 4.44744 3.92857 3.90698 3.92857H1.95349V1.78571ZM4.23256 8.21429H1.95349V6.07143H4.23256C4.77302 6.07143 5.2093 6.55 5.2093 7.14286C5.2093 7.73571 4.77302 8.21429 4.23256 8.21429Z"
      fill={fill}
    />
  </svg>
);
