import { Modal } from '@components';

import { ForeignAttributes } from '@routes/DataCenter/ForeignAttributes';
import { ForeignAttributesModalProps } from './ForeignAttributesModal.props';

/**
 * AttributesImportModal component
 * @returns {JSX.Element}
 */

export const ForeignAttributesModal = ({
  businessUnit,
  businessUnitId,
  isModalOpen,
  onSuccess,
  onClose,
}: ForeignAttributesModalProps): JSX.Element => {
  return (
    <Modal open={!!isModalOpen} padding="0" onClose={onClose}>
      <ForeignAttributes businessUnit={businessUnitId} onClose={onClose} onSuccess={onSuccess} />
    </Modal>
  );
};
