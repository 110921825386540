import { SVGProps } from 'react';

export const HomeOutline = ({
  width = 24,
  height = 24,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6786 6.18945L12.9012 4.58607C12.6243 4.32195 12.2618 4.25044 12.0025 4.25C11.7419 4.24956 11.3782 4.32049 11.0994 4.58551L3.49764 11.4431C3.19008 11.7206 3.16567 12.1948 3.44312 12.5024C3.72057 12.8099 4.19482 12.8343 4.50238 12.5569L5.25 11.8825V18.4286C5.25 18.7681 5.37841 19.1003 5.61724 19.3505C5.85721 19.6019 6.19033 19.75 6.54545 19.75H9.81818C10.2324 19.75 10.5682 19.4142 10.5682 19V14.1429C10.5682 14.1035 10.5834 14.0723 10.6003 14.0546C10.6161 14.0381 10.6298 14.0357 10.6364 14.0357H13.3636C13.3702 14.0357 13.3839 14.0381 13.3997 14.0546C13.4166 14.0723 13.4318 14.1035 13.4318 14.1429V19C13.4318 19.4142 13.7676 19.75 14.1818 19.75H17.4545C17.8097 19.75 18.1428 19.6019 18.3828 19.3505C18.6216 19.1003 18.75 18.7681 18.75 18.4286V11.8824L19.4976 12.5569C19.8052 12.8343 20.2794 12.8099 20.5569 12.5024C20.8343 12.1948 20.8099 11.7206 20.5024 11.4431L17.8929 9.08907V5.53792C17.8929 5.12371 17.5571 4.78792 17.1429 4.78792H15.4286C15.0144 4.78792 14.6786 5.12371 14.6786 5.53792V6.18945ZM17.25 10.5293L12 5.79326L6.75 10.5293V18.25H9.06818V14.1429C9.06818 13.7276 9.22532 13.3227 9.51531 13.0189C9.80642 12.7139 10.2089 12.5357 10.6364 12.5357H13.3636C13.7911 12.5357 14.1936 12.7139 14.4847 13.0189C14.7747 13.3227 14.9318 13.7276 14.9318 14.1429V18.25H17.25V10.5293ZM16.3929 6.28792V7.73591L16.1786 7.5426V6.28792H16.3929Z"
    />
  </svg>
);
