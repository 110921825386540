import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ContentCenter component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    contentCenter: {
      display: 'flex',
      backgroundColor: variables.color.primary.white,
    },

    title: {
      padding: theme.spacing(3),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    contentCenterLeft: {
      flexGrow: 1,
    },

    upload: {
      margin: theme.spacing(1.25, 0, 0, 3),
    },

    delete: {
      margin: theme.spacing(1.25, 0, 0, 3),
    },

    businessUnit: {
      margin: theme.spacing(0, 0, 2.25),
    },
  }),
);
