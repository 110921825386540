import { useEffect, useRef, useState } from 'react';
import { Box, ButtonBase, IconButton } from '@material-ui/core';

import { format } from '@utils';
import { content } from '@content';
import { Typography, Icon } from '@components';
import { variables } from '@styles';

import { useStyles } from './ModulesCard.styles';
import { ModulesCardProps } from './ModulesCard.types';

export const ModulesCard = ({ name, coverImages, createdOn, onModulesDrag, experienceId }: ModulesCardProps) => {
  const styles = useStyles();
  const [index, setIndex] = useState(0);
  const [imageIsHigher, setImageIsHigher] = useState(false);
  const coverImageRef = useRef(null);

  const handleSlideLeft = () => {
    const nextIndex = index - 1;

    if (nextIndex < 0) {
      setIndex(coverImages.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };

  const handleSlideRight = () => {
    setIndex((index + 1) % coverImages.length);
  };

  useEffect(() => {
    const image = new Image();

    image.onload = function () {
      setImageIsHigher((coverImageRef.current as any)?.getBoundingClientRect().height > 195);
    };

    image.src = coverImages[index] ?? '';
  }, [coverImageRef, coverImages, index]);

  return (
    <Box className={styles.modulesCard} draggable onDragStart={onModulesDrag} data-id={experienceId || ''}>
      {coverImages && coverImages.length > 0 && (
        <Box className={styles.coverImageWrapper}>
          <img
            ref={coverImageRef}
            src={coverImages[index]}
            alt="cover-image"
            className={styles.coverImage}
            style={{ bottom: imageIsHigher ? 'none' : '0', top: imageIsHigher ? '0' : 'none' }}
          />
          {coverImages.length > 1 && (
            <>
              <IconButton onClick={handleSlideLeft} className={`${styles.arrow} ${styles.left}`}>
                <Icon.ChevronLeft />
              </IconButton>
              <IconButton onClick={handleSlideRight} className={`${styles.arrow} ${styles.right}`}>
                <Icon.ChevronRight />
              </IconButton>
            </>
          )}
        </Box>
      )}

      <Box className={styles.content}>
        <Box className={styles.info}>
          <Box className={styles.name}>
            <Typography.SmallCaption className={styles.dark}>{name}</Typography.SmallCaption>
          </Box>
          {createdOn && (
            <Typography.SuperSmallCaption display="inline">{`${content.createdOn} ${format.date(
              createdOn,
              format.DATE_PATTERN,
            )}`}</Typography.SuperSmallCaption>
          )}
        </Box>
        <Box className={styles.action}>
          <ButtonBase classes={{ root: styles.actionBtnDrag }}>
            <Icon.DraggableV2 fill={variables.color.primary.mediumGray} />
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
};
