import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector as use } from '@store';
import { MetaSchema, BaseSchema, User, PermissionKey, RoleName } from '@modules';

import * as thunk from './Auth.thunk';
import { state as initialState } from './Auth.state';
import { reducers, extraReducers } from './Auth.reducer';
import { hasSomePermissions, hasAllPermissions, hasEachPermission } from './Auth.utils';

export * from './Auth.schema';

export const auth = {
  thunk,
  slice: createSlice({
    name: 'auth',
    initialState,
    reducers,
    extraReducers,
  }),
  useMeta: (): MetaSchema =>
    use(
      ({
        auth: {
          user: { data, ...meta },
        },
      }) => meta,
    ),
  useData: (): User =>
    use(
      ({
        auth: {
          user: { data },
        },
      }) => data,
    ),
  usePwdResetInitMeta: (): MetaSchema => use(({ auth: { pwdResetInit } }) => pwdResetInit),
  usePwdResetConfirmMeta: (): MetaSchema => use(({ auth: { pwdResetConfirm } }) => pwdResetConfirm),
  useAcceptTermsMeta: (): MetaSchema => use(({ auth: { acceptTerms } }) => acceptTerms),
  usePermissionsPartially: (...permissionKeys: PermissionKey[]): boolean =>
    use((root) => hasSomePermissions(root.auth.user.data, ...permissionKeys)),
  usePermissions: (...permissionKeys: PermissionKey[]): boolean =>
    use((root) => hasAllPermissions(root.auth.user.data, ...permissionKeys)),
  usePermissionsSeparately: (...permissionKeys: PermissionKey[]): boolean[] =>
    use((root) => hasEachPermission(root.auth.user.data, ...permissionKeys)),
  useUser: (): BaseSchema<User> => use((state) => state.auth.user),
  useRole: (): RoleName => use((state) => state.auth.user.data.role.name),
  useLastClientUpdateMeta: (): MetaSchema => use((state) => state.auth.lastUsedClientUpdate),
  useLastClientUpdateData: (): User['lastUsedClient'] => use((state) => state.auth.user.data?.lastUsedClient),
};
