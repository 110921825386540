import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * InsightOutline component
 * @returns {JSX.Element}
 */

export const InsightOutline = ({
  className = '',
  width = 24,
  height = 24,
  fill = variables.color.primary.darkestGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.5C12.4142 1.5 12.75 1.83579 12.75 2.25V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V2.25C11.25 1.83579 11.5858 1.5 12 1.5Z"
      fill={fill}
    />
    <path
      d="M4.57529 4.57529C4.86819 4.2824 5.34306 4.2824 5.63595 4.57529L7.22689 6.16623C7.51979 6.45913 7.51979 6.934 7.22689 7.22689C6.934 7.51979 6.45913 7.51979 6.16623 7.22689L4.57529 5.63595C4.2824 5.34306 4.2824 4.86819 4.57529 4.57529Z"
      fill={fill}
    />
    <path
      d="M19.4247 4.57529C19.7176 4.86819 19.7176 5.34306 19.4247 5.63595L17.8338 7.22689C17.5409 7.51979 17.066 7.51979 16.7731 7.22689C16.4802 6.934 16.4802 6.45913 16.7731 6.16623L18.364 4.57529C18.6569 4.2824 19.1318 4.2824 19.4247 4.57529Z"
      fill={fill}
    />
    <path
      d="M1.5 12C1.5 11.5858 1.83579 11.25 2.25 11.25H4.5C4.91421 11.25 5.25 11.5858 5.25 12C5.25 12.4142 4.91421 12.75 4.5 12.75H2.25C1.83579 12.75 1.5 12.4142 1.5 12Z"
      fill={fill}
    />
    <path
      d="M18.75 12C18.75 11.5858 19.0858 11.25 19.5 11.25H21.75C22.1642 11.25 22.5 11.5858 22.5 12C22.5 12.4142 22.1642 12.75 21.75 12.75H19.5C19.0858 12.75 18.75 12.4142 18.75 12Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12C16.5 13.4504 15.8133 14.7401 14.75 15.5621V17C14.75 17.4142 14.4142 17.75 14 17.75H10C9.58579 17.75 9.25 17.4142 9.25 17V15.5621C8.18666 14.7401 7.5 13.4504 7.5 12ZM12 9C10.3431 9 9 10.3431 9 12C9 13.0678 9.55734 14.006 10.4005 14.5386C10.6181 14.6761 10.75 14.9154 10.75 15.1727V16.25H13.25V15.1727C13.25 14.9154 13.3819 14.6761 13.5995 14.5386C14.4427 14.006 15 13.0678 15 12C15 10.3431 13.6569 9 12 9Z"
      fill={fill}
    />
    <path
      d="M10.75 18.5C10.3358 18.5 10 18.8358 10 19.25C10 19.6642 10.3358 20 10.75 20H13.25C13.6642 20 14 19.6642 14 19.25C14 18.8358 13.6642 18.5 13.25 18.5H10.75Z"
      fill={fill}
    />
  </svg>
);
