import { SVGProps } from 'react';

import { variables } from '@styles';

export const BarChartUp = ({
  className = '',
  fill = variables.color.primary.white,
  width = 24,
  height = 24,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M22.5 23.25H2.25C1.85218 23.25 1.47064 23.092 1.18934 22.8107C0.908035 22.5294 0.75 22.1478 0.75 21.75V1.5C0.75 1.30109 0.829018 1.11032 0.96967 0.96967C1.11032 0.829018 1.30109 0.75 1.5 0.75C1.69891 0.75 1.88968 0.829018 2.03033 0.96967C2.17098 1.11032 2.25 1.30109 2.25 1.5V21.75H22.5C22.6989 21.75 22.8897 21.829 23.0303 21.9697C23.171 22.1103 23.25 22.3011 23.25 22.5C23.25 22.6989 23.171 22.8897 23.0303 23.0303C22.8897 23.171 22.6989 23.25 22.5 23.25Z"
      fill={fill}
    />
    <path
      d="M7.3125 20.25H6.6875C6.23995 20.25 5.81073 20.0722 5.49426 19.7557C5.17779 19.4393 5 19.0101 5 18.5625V11.4375C5 10.9899 5.17779 10.5607 5.49426 10.2443C5.81073 9.92779 6.23995 9.75 6.6875 9.75H7.3125C7.76005 9.75 8.18927 9.92779 8.50574 10.2443C8.82221 10.5607 9 10.9899 9 11.4375V18.5625C9 19.0101 8.82221 19.4393 8.50574 19.7557C8.18927 20.0722 7.76005 20.25 7.3125 20.25Z"
      fill={fill}
    />
    <path
      d="M13.3125 20.2499H12.6875C12.2399 20.2499 11.8107 20.0721 11.4943 19.7556C11.1778 19.4392 11 19.0099 11 18.5624V8.19312C11 7.74556 11.1778 7.31634 11.4943 6.99987C11.8107 6.68341 12.2399 6.50562 12.6875 6.50562H13.3125C13.7601 6.50562 14.1893 6.68341 14.5057 6.99987C14.8222 7.31634 15 7.74556 15 8.19312V18.5624C15 19.0099 14.8222 19.4392 14.5057 19.7556C14.1893 20.0721 13.7601 20.2499 13.3125 20.2499Z"
      fill={fill}
    />
    <path
      d="M19.3125 20.2499H18.6875C18.2399 20.2499 17.8107 20.0721 17.4943 19.7556C17.1778 19.4392 17 19.0099 17 18.5624V4.6875C17 4.23995 17.1778 3.81073 17.4943 3.49426C17.8107 3.17779 18.2399 3 18.6875 3H19.3125C19.7601 3 20.1893 3.17779 20.5057 3.49426C20.8222 3.81073 21 4.23995 21 4.6875V18.5624C21 19.0099 20.8222 19.4392 20.5057 19.7556C20.1893 20.0721 19.7601 20.2499 19.3125 20.2499Z"
      fill={fill}
    />
  </svg>
);
