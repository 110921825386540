import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * UserPage component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    userPage: {
      height: '100%',
    },

    body: {
      maxHeight: `calc(100% - 18.5rem)`,
      overflowY: 'auto',
    },

    alert: {
      marginBottom: theme.spacing(3),
    },

    archive: {
      marginRight: theme.spacing(3),
    },

    formRow: {
      display: 'flex',
      marginBottom: theme.spacing(3),
    },

    inputWrapper: {
      width: '28rem',
    },

    inputLabel: {
      flexBasis: '17.9rem',
      marginRight: theme.spacing(3),
    },

    divider: {
      margin: theme.spacing(4, 0),
    },

    tip: {
      width: '38.1rem',
      padding: theme.spacing(2),
      margin: theme.spacing(3, 0, 1),
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    tipText: {
      width: '30.9rem',
      color: variables.color.primary.mediumGray,
    },

    multiSelectPaper: {
      boxShadow: '1px 1px 7px 4px rgba(208, 208, 206, 0.3)',
    },

    multiSelectPlaceholderItem: {
      '&.Mui-disabled': {
        opacity: 1,
        borderBottom: `${variables.borderWidth.sm} solid ${variables.color.tertiary.gray}`,
      },
    },

    checkbox: {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },

    checkboxes: {
      display: 'flex',
      flexDirection: 'column',
    },

    businessUnits: {
      paddingLeft: theme.spacing(3.5),
      marginBottom: theme.spacing(0.5),
    },

    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);
