import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import { variables } from '@styles';

const closeSize = '2.4rem';

export interface StyleProps {
  maxWidth?: string;
  borderRadius?: string;
  padding?: string;
}

/**
 * Modal component styles
 * @returns {Function}
 */
export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },

    paper: {
      boxSizing: 'border-box',
      boxShadow: '0 0.4rem 0.4rem rgba(0, 0, 0, 0.25)',
      borderRadius: ({ borderRadius }) => borderRadius || variables.borderRadius.sm,
      padding: ({ padding }) => padding || theme.spacing(3),
      maxWidth: ({ maxWidth }) => maxWidth || '102.4rem',
      width: '100%',
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(3),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    alert: {
      padding: theme.spacing(3, 3, 0),
    },

    close: {
      padding: 0,
      minWidth: closeSize,
      width: closeSize,
      height: closeSize,

      '& svg': {
        width: '100%',
        height: '100%',

        '& > path': {
          stroke: variables.color.primary.darkestGray,
        },
      },

      '&:hover': {
        backgroundColor: 'transparent',

        '& svg > path': {
          stroke: variables.color.primary.lightPurple,
        },
      },
    },

    body: {
      padding: theme.spacing(3),

      '& > *:not(:first-child)': {
        marginTop: theme.spacing(3),
      },
    },

    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(3),
      borderTop: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    action: {
      padding: theme.spacing(1, 1.5),

      '&:not(:first-child)': {
        marginLeft: theme.spacing(1.25),
      },
    },

    arrow: {
      marginLeft: theme.spacing(1.25),

      '& > path': {
        stroke: variables.color.primary.white,
      },
    },
  }),
);
