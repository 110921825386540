import { createStyles, makeStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

const fieldsGap = '4rem';

const groupFieldsGapY = '2rem';

const fields = {
  display: 'flex',
  'flex-wrap': 'wrap',
  margin: `-${fieldsGap} 0 0 -${fieldsGap}`,
};

const field = {
  margin: `${fieldsGap} 0 0 ${fieldsGap}`,
  width: `calc(50% - ${fieldsGap})`,

  '&[data-wide="true"]': {
    width: '100%',
  },

  '&[data-type="htm"], &[data-type="bee"], &[data-type="stn"], &[data-type="articleLibrary"]': {
    width: '100%',

    '&:not(:first-child)': {
      marginTop: variables.gap.md,
    },
  },

  '&[data-type="visualLibrary"]': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: 'calc(31% - 2.5rem)',
    margin: '4rem 0 0.5rem 2.5rem',
  },
};

const extras = {
  ...fields,
  marginTop: variables.gap.lg,
};

const groupShadowLeft = '0.1rem';

const groupShadowRight = '0.4rem';

const groups = {
  marginTop: variables.gap.md,
  padding: `0 ${groupShadowRight} ${groupShadowRight} ${groupShadowLeft}`,
  maxHeight: '65rem',
  'overflow-y': 'scroll',
};

const group = {
  boxShadow: `${groupShadowLeft} 0.1rem ${groupShadowRight} ${variables.color.primary.lightPurple}`,
  marginTop: variables.gap.lg,
};

const groupFields = {
  ...fields,
  marginTop: `-${groupFieldsGapY}`,

  '& > *': {
    marginTop: groupFieldsGapY,
  },
};

export const useStyles = makeStyles(() =>
  createStyles({
    fields: {
      ...fields,
      '&[data-type="moduleDataVisualizationStation"]': {
        '& > [data-type="txt"]:first-child': {
          width: 'calc(67% - 2.5rem)',
        },
        '& > [data-type="txt"]:last-child': {
          margin: '4rem 0 0 2.5rem',
        },
      },
    },
    field,
    substations: {
      display: 'flex',
      flexDirection: 'column',
    },
    extras,
    groups,
    group,
    groupFields,
  }),
);
