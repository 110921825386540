import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * BenchmarkingReport component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    benchmarkingReport: {
      width: '49%',
      background: variables.color.primary.white,
    },

    content: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
    },

    titleContainer: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },

    title: {
      marginRight: theme.spacing(0.5),
    },

    leftContainer: {
      borderRight: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
    },

    rightContainer: {
      paddingLeft: theme.spacing(3),
    },

    alertIcon: {
      marginLeft: theme.spacing(0.5),
    },
  }),
);
