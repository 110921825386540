import { content } from '@content';

export const SEARCH_DEBOUNCE_DELAY = 500; // ms

export enum OptionsTypes {
  LAST_USED = 'lastUsed',
  SORT_BY = 'sortBy',
  DIVIDER = 'divider',
}

export enum SortValues {
  LAST_USED = 'lastUsed',
  AUTHOR = 'author',
  CREATED = 'created',
  UPDATED = 'updated',
}

export enum SortLabels {
  CREATED_DATE = 'Created date',
  EDITED_DATE = 'Edited date',
  LAST_DEPLOYMENT = 'Last deployment',
  AUTHOR = 'Author',
}

export enum SortExperienceValues {
  CREATED_ON = 'Created on',
  EDITED_ON = 'Edited on',
  LAST_DEPLOYMENT = 'Last Deployment',
  AUTHOR = 'Author',
}

export const initialOptions = [
  {
    id: '1',
    type: OptionsTypes.LAST_USED,
    label: content.onlyRecent,
    value: 1,
  },
  {
    id: '2',
    type: OptionsTypes.LAST_USED,
    label: content.everything,
    value: 0,
    active: true,
  },
  {
    id: '3',
    type: OptionsTypes.DIVIDER,
  },
  {
    id: '4',
    label: content.sortBy,
    subOptions: [
      {
        id: '5',
        type: OptionsTypes.SORT_BY,
        label: content.createdDate,
        value: SortValues.CREATED,
        active: true,
      },
      {
        id: '6',
        type: OptionsTypes.SORT_BY,
        label: content.editedDate,
        value: SortValues.UPDATED,
      },
      {
        id: '7',
        type: OptionsTypes.SORT_BY,
        label: content.lastDeployment,
        value: SortValues.LAST_USED,
      },
      {
        id: '8',
        type: OptionsTypes.SORT_BY,
        label: content.author,
        value: SortValues.AUTHOR,
      },
    ],
  },
];
