import { FC, ReactElement, MouseEvent } from 'react';
import { BarItemProps } from '@nivo/bar';

import { format } from '@utils';
import { variables } from '@styles';
import { BarChartTooltip } from '@components';

export const BarSingleChartComponent: FC<
  BarItemProps & {
    className?: string;
    highlightItem?: () => void;
    grayOutItem?: () => void;
  }
> = ({
  height,
  data,
  x,
  y,
  color,
  className = '',
  highlightItem,
  grayOutItem,
  onMouseEnter,
  showTooltip,
  onMouseLeave,
  hideTooltip,
}): ReactElement => {
  const tooltip = <BarChartTooltip indexValue={data.indexValue} value={data.value} unit="%" />;
  const handleTooltip = (event: MouseEvent<SVGElement>) => {
    if (highlightItem) {
      highlightItem();
    }
    showTooltip(tooltip, event);
  };
  const handleMouseEnter = (event: MouseEvent<SVGElement>) => {
    onMouseEnter(data, event);
    if (highlightItem) {
      highlightItem();
    }
    showTooltip(tooltip, event);
  };
  const handleMouseLeave = (e: MouseEvent<SVGElement>) => {
    onMouseLeave(data, e);
    if (grayOutItem) {
      grayOutItem();
    }
    hideTooltip();
  };

  return (
    <g transform={'translate('.concat(x.toString(), ', ').concat(y.toString(), ')')}>
      <rect
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        id={String(data.data.elementId)}
        className={className}
        width={data.value > 35 ? 400 : 11 * data.value}
        height={height}
        rx="4"
        ry="4"
        fill={color}
        strokeWidth="0"
        stroke={color}
        values={String(data.data.elementId)}
      />
      <text
        x={data.value > 35 ? 375 : 11 * data.value - 20}
        y="10.5"
        textAnchor="middle"
        dominantBaseline="central"
        fill="white"
        style={{
          fontSize: variables.font.size.xs,
          fontWeight: variables.font.weight.bold,
          lineHeight: variables.lineHeight.sm,
          letterSpacing: variables.letterSpacing.lg,
          pointerEvents: 'none',
        }}
      >
        {data.value > 35 && '> > >'}
        {data.value > 3 && data.value < 35 ? format.percent(data.value) : ''}
      </text>
    </g>
  );
};
