import { makeStyles, createStyles } from '@material-ui/core/styles';

import { theme, variables } from '@styles';

/**
 * Disclosure component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    disclosure: {
      width: '100%',
      position: 'relative',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      backgroundColor: variables.color.primary.white,
      boxShadow: `0 0.4rem 1.6rem rgba(0, 0, 0, 0.08)`,
    },

    mustReview: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '8.5rem',
      height: '1.6rem',
      borderRadius: variables.borderRadius.xxs,
      marginBottom: theme.spacing(0.25),
      backgroundColor: variables.color.semantic.negativeLight,
    },

    mustReviewText: {
      marginLeft: theme.spacing(0.5),
      fontWeight: 600,
      color: variables.color.semantic.negative,
    },

    checkboxWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0.375, 0, 0.375, 2),
    },

    borderTop: {
      width: '100%',
      height: '0.3rem',
      backgroundColor: variables.color.primary.mainPurple,
      borderRadius: `${variables.borderRadius.sm} ${variables.borderRadius.sm} 0 0`,

      '&[data-has-error=true]': {
        backgroundColor: variables.color.semantic.negative,
      },
    },
  }),
);
