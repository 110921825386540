import { content } from '@content';

/**
 * DataAttributeLibrary component utils
 * @returns {function}
 */

export const getImportMenuOptions = (
  onImportDataModalOpen: () => void,
  handleMailFileModalOpen: () => void,
  handleEmailActivityModalOpen: () => void,
  handleDataAttrubutesFeedModalOpen: () => void,
  handleForeignAttributesModalOpen: () => void,
) => [
  {
    label: content.dataAttributes,
    onClick: onImportDataModalOpen,
  },
  {
    label: content.subscriberList,
    onClick: handleMailFileModalOpen,
  },
  {
    label: content.emailActivityFile,
    onClick: handleEmailActivityModalOpen,
  },
  {
    label: content.dataAttributesFeed,
    onClick: handleDataAttrubutesFeedModalOpen,
  },
  {
    label: content.foreignAttributes,
    onClick: handleForeignAttributesModalOpen,
  },
];
