import { CSSProperties } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * TemplateView component styles
 * @returns {Function}
 */

const getFixedHeightAttrs = (height: string): CSSProperties => ({
  height,
  minHeight: height,
  maxHeight: height,
});

const getFixedWidthAttrs = (width: string): CSSProperties => ({
  width,
  minWidth: width,
  maxWidth: width,
});

export const useStyles = makeStyles((theme) =>
  createStyles({
    templateView: {
      ...getFixedHeightAttrs(`calc(100% + 2 * ${theme.spacing(3)}px)`),
      ...getFixedWidthAttrs(`calc(100% + 2 * ${theme.spacing(8)}px)`),
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      borderTop: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,

      '& > *, & > * > *': {
        ...getFixedHeightAttrs('100%'),
      },

      '& > *:not(:first-child)': {
        borderLeft: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      },

      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      height: '100%',
      minHeight: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
    } as any,

    leftPanel: {
      '&&': {
        width: '43rem',
        flex: '0 0 43rem',
        maxWidth: 'unset',
        minWidth: 'unset',
        zIndex: 10,

        ['@media (min-width: 1200px) and (max-width: 1580px)']: {
          width: '25rem',
          flex: '0 0 25rem',
        },
      },
    },

    centralPanel: {
      '&&': {
        position: 'relative',
        width: 'calc(100% - 67.2rem)',
        maxWidth: 'unset',
        minWidth: 'unset',

        ['@media (min-width: 1200px) and (max-width: 1580px)']: {
          width: 'calc(100% - 49.2rem)',
        },
      },
    },

    rightPanel: {
      '&&': {
        width: '44.8rem',
        flex: '0 0 44.8rem',
        maxWidth: 'unset',
        minWidth: 'unset',
        borderLeft: 'unset',
        zIndex: 1,

        ['@media (min-width: 1200px) and (max-width: 1580px)']: {
          width: '27rem',
          flex: '0 0 27rem',
        },
      },
    },

    rightPanelContent: {
      right: 0,
      width: '45rem',
      height: '63.7rem',
      backgroundColor: variables.color.primary.white,
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      zIndex: 1,
      borderTop: 'unset',

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        width: '27rem',
      },
    },

    treatmentLogic: {
      position: 'absolute',
      width: 'calc(100% - 44.8rem)',
      maxWidth: 'unset',
      minWidth: 'unset',
      background: variables.color.primary.white,

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        width: 'calc(100% - 26.8rem)!important',
      },
    },
  }),
);
