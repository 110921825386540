import { makeStyles, createStyles } from '@material-ui/core';

import { variables } from '@styles';

import { REC_EXTRA_POSTFIX } from './List.const';

const clntAfterIndex = 0;

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(4),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    alert: {
      marginBottom: theme.spacing(2),
    },

    ntps: {
      width: '28rem',
      marginBottom: theme.spacing(2),
    },

    clnt: {
      position: 'relative',
      maxHeight: '1.8rem',

      '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: clntAfterIndex,
        width: '100%',
        height: variables.borderWidth.md,
        background: variables.color.primary.white,
        bottom: theme.spacing(-2.125),
      },

      [`&[data-record-id$="${REC_EXTRA_POSTFIX}"], &[data-header="true"]`]: {
        '&:after': {
          background: 'transparent',
        },
      },
    },

    clntValue: {
      position: 'absolute',
      zIndex: clntAfterIndex + 1,
      top: 0,
      left: 0,
      maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      whiteSpace: 'initial',
    },

    filler: {
      overflow: 'visible',
    },

    edit: {
      position: 'relative',
    },

    editProgram: {
      position: 'absolute',
      right: 0,
    },

    addProgram: {
      margin: theme.spacing(0, 0, 2, 'auto'),
    },
  }),
);
