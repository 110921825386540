import { SVGProps } from 'react';

export const DraggableVerticalLines = ({
  width = 16,
  height = 16,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect x="2" y="4" width="12" height="1.5" rx="0.75" fill={fill} />
    <rect x="2" y="7.25" width="12" height="1.5" rx="0.75" fill={fill} />
    <rect x="2" y="10.5" width="12" height="1.5" rx="0.75" fill={fill} />
  </svg>
);
