import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Common button styles
 */
const button = {
  border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
  borderRadius: variables.borderRadius.sm,
  padding: '0.5rem',
};

/**
 * SelectEntity component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: ({ moduleHeight }: { moduleHeight: number }) => ({
      position: 'absolute',
      left: '100%',
      padding: theme.spacing(3),
      backgroundColor: variables.color.primary.white,
      width: '30.4rem',
      textAlign: 'start',
      overflow: 'hidden',
      height: moduleHeight,

      ['@media (max-width: 1450px)']: {
        width: '25rem',
      },
    }),
    fullContainer: {
      position: 'absolute',
      left: '100%',
      padding: theme.spacing(3),
      backgroundColor: variables.color.primary.white,
      width: '30.4rem',
      textAlign: 'start',

      ['@media (max-width: 1450px)']: {
        width: '25rem',
      },
    },
    grid: {
      margin: theme.spacing(1, -1),
    },
    removeButton: {
      ...button,
    },
    row: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    label: {
      textTransform: 'capitalize',
    },
    counts: {
      display: 'inline',
      marginLeft: theme.spacing(1),
      '& b': {
        color: variables.color.primary.darkestGray,
      },
    },
    prevButton: {
      ...button,
    },
    nextButton: {
      ...button,
      marginLeft: theme.spacing(1),
    },
    showMoreBlock: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '7.5rem',
      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%)',
    },
    showMoreButton: {
      backgroundColor: variables.color.primary.white,
      position: 'absolute',
      bottom: theme.spacing(2),
      left: '50%',
      transform: 'translateX(-50%)',

      '&:hover': {
        backgroundColor: variables.color.primary.white,
      },
    },
  }),
);
