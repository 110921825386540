import { KeyboardEvent, ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { Typography, Icon } from '@components';
import { func, textTemplate } from '@utils';

import { Dataset, DatasetKey } from './TextField.types';
import { TextFieldProps } from './TextField.props';
import { useStyles } from './TextField.styles';

/**
 * TextField (pure text input) component
 */
export const TextField = ({
  inputRef = null,
  inputProps = {},
  className = '',
  size = 'medium',
  direction = 'vertical',
  required = false,
  disableRequiredText = false,
  disabled = false,
  error = false,
  templated = false,
  label = '',
  subLabel = '',
  placeholder = '',
  hint = '',
  icon = undefined,
  iconLeft = false,
  onlyUpperCase = false,
  type = 'text',
  value = '',
  maxValue = '',
  minValue = '',
  readonly = false,
  suggestions = undefined,
  onKeyPress = func.nop,
  onKeyUp = func.nop,
  onChange = func.nop,
}: TextFieldProps): JSX.Element => {
  const styles = useStyles();

  const dataset = useMemo<Dataset>(
    () => ({
      [DatasetKey.size]: size,
      [DatasetKey.direction]: direction,
      [DatasetKey.state]: disabled ? 'disabled' : error ? 'error' : 'default',
      [DatasetKey.icon]: icon ? (iconLeft ? 'start' : 'end') : 'none',
    }),
    [size, direction, disabled, error, icon, iconLeft],
  );

  const bodyRef = useRef<HTMLElement>(null);

  const [focused, setFocused] = useState(false);

  const IconComp = useMemo(() => (icon ? Icon[icon] : null), [icon]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      onKeyPress(event.key, event);
    },
    [onKeyPress],
  );

  const limiter = useCallback(
    (inputValue: string) => {
      if (maxValue && +inputValue > +maxValue) {
        return maxValue;
      }
      if (minValue && +inputValue < +minValue) {
        return minValue;
      }

      return inputValue;
    },
    [maxValue, minValue],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const limitedValue = limiter(event.target.value);

      onChange(onlyUpperCase ? limitedValue.toUpperCase() : limitedValue);
    },
    [limiter, onChange],
  );

  const handleDocumentMouseDown = useCallback(
    (event) => {
      setFocused(!!bodyRef.current?.contains(event.target));
    },
    [setFocused],
  );

  const handleSuggestionClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onChange(event.currentTarget.innerText, true);
    },
    [onChange],
  );

  useEffect(() => {
    window.document.addEventListener('mousedown', handleDocumentMouseDown);

    return () => {
      window.document.removeEventListener('mousedown', handleDocumentMouseDown);
    };
  }, [handleDocumentMouseDown]);

  return (
    <Box className={`${styles.textField} ${className}`} {...dataset}>
      {(label || subLabel) && (
        <Box className={styles.meta}>
          {label && (
            <Typography.Label className={styles.label}>
              {required && templated ? textTemplate(content.requiredField, { field: label }) : label}
            </Typography.Label>
          )}
          {subLabel && <Typography.SmallCaption className={styles.subLabel}>{subLabel}</Typography.SmallCaption>}
        </Box>
      )}
      <Box className={styles.main}>
        <Box className={styles.body} {...{ ref: bodyRef }}>
          <input
            {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
            className={styles.input}
            ref={inputRef}
            placeholder={
              disabled
                ? ''
                : templated
                ? textTemplate(content.enterValue, { value: placeholder.toLowerCase() })
                : placeholder
            }
            readOnly={readonly}
            type={type}
            disabled={disabled}
            value={value}
            onKeyPress={handleKeyPress}
            onKeyUp={disabled ? func.nop : onKeyUp}
            onChange={disabled ? func.nop : handleChange}
          />
          {IconComp && <IconComp className={styles.icon} />}
          {focused && !!suggestions?.length && (
            <Box className={styles.sugs} tabIndex={0}>
              {suggestions.map((suggestion) => (
                <Typography.Headline className={styles.sugn} key={suggestion} onClick={handleSuggestionClick}>
                  {suggestion}
                </Typography.Headline>
              ))}
            </Box>
          )}
        </Box>
        {(hint || required) && (
          <Typography.SmallCaption className={styles.hint}>
            {hint
              ? templated && !disabled && error && !value
                ? textTemplate(content.pleaseEnter, { field: hint })
                : required && !disableRequiredText
                ? `*${content.required}. ${hint}`
                : hint
              : `*${content.required}`}
          </Typography.SmallCaption>
        )}
      </Box>
    </Box>
  );
};
