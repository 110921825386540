import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * LibrariesPanelWrapper component styles
 * @returns {Function}
 */

const headerHeight = '4.7rem';

export const useStyles = makeStyles((theme) =>
  createStyles({
    librariesPanelWrapper: {
      position: 'absolute',
      top: 0,
      zIndex: 1,
      width: '43rem',
      backgroundColor: variables.color.primary.white,

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        width: '25rem!important',
      },
    },

    header: {
      height: headerHeight,
      padding: '1.4rem 1.6rem',
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
    },

    returnBackBtn: {
      padding: 0,
      marginRight: theme.spacing(1.25),
    },

    content: {
      height: `calc(100% - ${headerHeight})`,
    },
  }),
);
