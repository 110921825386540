import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Alert component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      padding: theme.spacing(2),
      borderRadius: variables.borderRadius.sm,
      position: 'relative',
    },

    error: {
      color: variables.color.semantic.negative,
      fill: variables.color.semantic.negative,
      background: variables.color.semantic.negativeLight,
    },

    success: {
      color: variables.color.semantic.positive,
      fill: variables.color.semantic.positive,
      backgroundColor: variables.color.semantic.positiveLight,
    },

    info: {
      color: variables.color.semantic.highlight,
      fill: variables.color.semantic.highlight,
      backgroundColor: variables.color.semantic.highlightLight,

      '& svg path': {
        stroke: variables.color.semantic.highlight,
      },
    },

    warning: {
      color: variables.color.semantic.warning,
      fill: variables.color.semantic.warning,
      backgroundColor: variables.color.semantic.warningLight,
    },

    close: {
      cursor: 'pointer',
      outline: 'none',
      border: 0,
      background: 'transparent',
      padding: 0,
      fontSize: 0,
      position: 'absolute',
      top: theme.spacing(1.6),
      right: theme.spacing(1.6),
    },

    title: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
    },

    description: {
      marginTop: theme.spacing(0.5),
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.lg,
      whiteSpace: 'pre-wrap',
    },

    row: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: '90%',

      '&[data-align="left"]': {
        alignItems: 'flex-start',
      },

      '&[data-align="center"]': {
        alignItems: 'center',
      },

      '&[data-align="right"]': {
        alignItems: 'flex-end',
      },
    },
  }),
);
