import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      padding: '4rem 6rem 1.1rem',
    },
    backdrop: {
      backgroundColor: 'rgba(246, 246, 246, 0.6)',
      filter: 'blur(50px)',
    },
    paper: {
      boxSizing: 'border-box',
      boxShadow: '1px 1px 7px 4px rgba(208, 208, 206, 0.3)',
      borderRadius: variables.borderRadius.sm,
      maxWidth: '70rem',
      maxHeight: '44rem',
      width: '100%',
      overflow: 'hidden',
    },
    content: {
      padding: '0.8rem 6rem 0',
      overflowY: 'auto',
    },
    text: {
      marginBottom: '2.1rem',
      '& h2': {
        color: variables.color.primary.mainPurple,
      },
      '& h3': {
        color: variables.color.secondary.blue,
      },
      '& a': {
        color: variables.color.primary.lightPurple,
      },
    },
    checkboxControl: {
      marginLeft: 0,
      marginRight: '3.7rem',
      '& .MuiFormControlLabel-label': {
        fontSize: variables.font.size.sm,
        lineHeight: variables.lineHeight.sm,
        color: variables.color.primary.gray,
        fontWeight: variables.font.weight.regular,
      },
    },
    checkbox: {
      marginRight: '3.1rem',
    },
  }),
);
