import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * LibraryNavigation component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    moduleGroups: {
      height: '100%',
      backgroundColor: variables.color.primary.white,
      overflow: 'auto',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    list: {
      height: 'calc(100% - 4.6rem)',
      overflowY: 'auto',
    },

    searchBar: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },

    search: {
      '& .MuiInput-root': {
        padding: theme.spacing(0.5, 1.5),
      },
    },

    loader: {
      textAlign: 'center',
      padding: variables.gap.xs,
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
    },
  }),
);
