import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ModuleSettings component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    moduleSettings: {
      height: '100%',
    },

    header: {
      textAlign: 'left',
    },

    titleWrapper: {
      padding: theme.spacing(2),
    },

    title: {
      marginBottom: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '16px!important',
      color: `${variables.color.primary.darkPurple}!important`,
      lineHeight: '20px',
    },

    editModule: {
      marginRight: theme.spacing(1),

      '&.hovered': {
        color: variables.color.primary.mainPurple,
        border: `0.2rem solid ${variables.color.primary.lightPurple}`,
      },
      '&.hovered svg path': {
        stroke: variables.color.primary.mainPurple,
      },
    },

    renameBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    renameBarButtons: {
      display: 'flex',
    },

    tabsWrapper: {
      background: variables.color.primary.backgroundLightestGray,
      borderTop: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
    },

    tabs: {
      display: 'inline-flex',
      minHeight: 'unset',
      borderTop: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      marginTop: '-1px',
      marginBottom: '-2px',
    },

    tab: {
      minHeight: 'unset',
      textTransform: 'none',
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.md,
      color: variables.color.primary.mediumGray,
      background: variables.color.primary.backgroundLightestGray,
      border: `${variables.borderWidth.md} solid transparent`,
    },

    selectedTab: {
      background: variables.color.primary.white,
      textTransform: 'none',
      borderRight: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderBottom: 'none',
    },

    tabWrapper: {
      flexDirection: 'row',

      '&&': {
        '& > *:first-child': {
          marginRight: theme.spacing(1),
          marginBottom: '0',
        },
      },
    },

    editIcon: {
      marginRight: theme.spacing(0.75),
    },

    content: {
      padding: theme.spacing(2),
      height: '100%',
    },
  }),
);
