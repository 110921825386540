import { Attribute } from '@modules';
import { TFormatOption } from './AddSignalDropdown.props';

export const PROFILE_ATTRIBUTES: Attribute[] = [
  {
    id: 1000,
    isUploading: false,
    label: 'First Name',
    name: 'first_name',
    signalType: 'signal',
    totalSubscribers: 2000,
    type: 'textual',
    options: [],
    updatedAt: '',
  },
  {
    id: 1001,
    isUploading: false,
    label: 'Last Name',
    name: 'last_name',
    signalType: 'signal',
    totalSubscribers: 2000,
    type: 'textual',
    options: [],
    updatedAt: '',
  },
  {
    id: 1002,
    isUploading: false,
    label: 'Email Address',
    name: 'email_address',
    signalType: 'signal',
    totalSubscribers: 2000,
    type: 'textual',
    options: [],
    updatedAt: '',
  },
];

export const FORMAT_OPTIONS: TFormatOption[] = [
  {
    id: 'NO_DATA',
    label: 'No Data Manipulation',
    type: 'No Data Manipulation',
    output: '',
  },
  {
    id: 'L',
    label: 'Date: Long',
    type: 'Date',
    output: 'Friday, September 15, 2017',
  },
  {
    id: 'S',
    label: 'Date: Short',
    type: 'Date',
    output: '9/15/2017',
  },
  {
    id: 'Y',
    label: 'Date: Y',
    type: 'Date',
    output: 'September 2017',
  },
  {
    id: 'YYYY',
    label: 'Date: YYYY',
    type: 'Date',
    output: '2017',
  },
  {
    id: 'M',
    label: 'Date: M',
    type: 'Date',
    output: 'September 15',
  },
  {
    id: 'MM',
    label: 'Date: MM',
    type: 'Date',
    output: '9',
  },
  {
    id: 'MMM',
    label: 'Date: MMM',
    type: 'Date',
    output: 'Sep',
  },
  {
    id: 'MMMM',
    label: 'Date: MMMM',
    type: 'Date',
    output: 'September',
  },
  {
    id: 'dd',
    label: 'Date: dd',
    type: 'Date',
    output: '15',
  },
  {
    id: 'dddd',
    label: 'Date: dddd',
    type: 'Date',
    output: 'Fri',
  },
  {
    id: 'ddddd',
    label: 'Date: ddddd',
    type: 'Date',
    output: 'Friday',
    hasBorder: true,
  },
  {
    id: 'UC',
    label: 'String: Uppercase',
    type: 'String',
    output: 'BANANNA',
  },
  {
    id: 'PC',
    label: 'String: Propercase',
    type: 'String',
    output: 'Bananna',
  },
  {
    id: 'LC',
    label: 'String: Lowercase',
    type: 'String',
    output: 'baananna',
    hasBorder: true,
  },
  {
    id: 'NoD',
    label: 'Number: No Decimal',
    type: 'Number',
    output: '3,000',
  },
  {
    id: '1D',
    label: 'Number: 1 Decimal',
    type: 'Number',
    output: '3,000.0',
  },
  {
    id: '2D',
    label: 'Number: 2 Decimal',
    type: 'Number',
    output: '3,000.00',
  },
];
