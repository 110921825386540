import { useState } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { HexColorPicker } from 'react-colorful';

import { Typography, Icon } from '@components';
import { content } from '@content';
import { variables } from '@styles';

import { ColorPickerProps } from './ColorPicker.props';
import { useStyles } from './ColorPicker.styles';

/**
 ColorPicker component.
 @returns {JSX.Element}
 */

export const ColorPicker = ({
  color,
  width,
  disabled = false,
  placeholder,
  position = 'left',
  onColorChange,
}: ColorPickerProps): JSX.Element => {
  const styles = useStyles({ color });

  const [open, setOpen] = useState(false);

  const handleShowPicker = () => setOpen((prevState) => !prevState);

  const handleColorChange = (e: any) => {
    onColorChange(e.target.value);
  };

  return (
    <Box className={styles.colorPicker}>
      <Button
        className={styles.button}
        variant="outlined"
        color="primary"
        style={{ width, minWidth: width! }}
        disabled={disabled}
        onClick={handleShowPicker}
      >
        {!!placeholder && !color ? (
          <Typography.SuperSmallCaption className={styles.placeholder}>{placeholder}</Typography.SuperSmallCaption>
        ) : (
          <input className={styles.colorField} value={color} onChange={handleColorChange} />
        )}
        <Box className={styles.currentColor} style={{ marginLeft: width ? '0' : '32px', backgroundColor: color }} />
      </Button>
      {open && (
        <Box
          className={styles.container}
          style={{ left: position === 'left' ? 0 : 'auto', right: position === 'right' ? 0 : 'auto' }}
        >
          <Box className={styles.header}>
            <Typography.SuperSmallCaption className={styles.title}>{content.colorPicker}</Typography.SuperSmallCaption>
            <IconButton className={styles.close} onClick={handleShowPicker}>
              <Icon.CloseOutline stroke={variables.color.primary.mediumGray} />
            </IconButton>
          </Box>
          <HexColorPicker color={color} onChange={onColorChange} />
        </Box>
      )}
    </Box>
  );
};
