import {
  BaseSchema,
  BasePaginatedData,
  ModuleDisclosureStation,
  Deployment,
  ModuleInfoState,
  MetaSchema,
  ModuleDataVisualizationStation,
} from '@modules';

export type BarChartData = {
  id: number;
  label?: string;
  value?: 134;
};

export type MailPart = {
  id: number;
  name: string;
};

export enum ModuleStatus {
  notPublished = 'not-published',
  published = 'published',
  modified = 'modified',
}

export enum ModuleUniqueKey {
  id = 'id',
  editableModuleId = 'editableModuleId',
}

export type ModuleAuditStatus = 'off' | 'missing' | 'complete';

export type Module = {
  isPersonalized: boolean;
  id: number;
  originModuleId?: number;
  editableModuleId?: number;
  name: string;
  position: string;
  tag: {
    id: number;
    tag: string;
    index: string;
  };
  productName?: string;
  coverImage: string;
  imageFilepath: string;
  audienceSize: number;
  status: ModuleStatus;
  moduleCopy: string;
  moduleDisclosureStation: ModuleDisclosureStation | null;
  moduleDataVisualizationStation: ModuleDataVisualizationStation | null;
  moduleUrl: string;
  moduleHtml: string;
  deploymentsCount: number;
  deployment?: Partial<Deployment>;
  statistics?: {
    id: number;
    totalImpressions: number;
    totalClicks: number;
    totalOpens: number;
    ctir: number;
  };
  path?: string;
  width?: number;
  height?: number;
  extension?: string;
  size?: number;
  cohortName: string;
  mailPart: MailPart;
  auditDisclosureStatus?: ModuleAuditStatus;
  auditContentStatus?: ModuleAuditStatus;
  auditHtmlStatus?: ModuleAuditStatus;
  publishedAt: string | null;
  createdAt: string;
  updatedAt: string;
  lastUsed: string;
  templateName: string;
};

export type TModuleDVStationPayload = {
  moduleDataVisualizationStation: ModuleDataVisualizationStation | null;
};

export type ModulesPaginatedData = {
  currentPage?: number;
  items: Module[];
  pageCount?: number;
  total?: number;
};

export interface ModulesPaginatedSchema extends BaseSchema {
  data: ModulesPaginatedData;
}

export type ModulesInfoData = Module | null;

export interface ModulesInfoSchema extends BaseSchema {
  data: ModulesInfoData;
}

export type NewModuleInfoData = ModuleInfoState | null;

export interface NewModuleInfoSchema extends BaseSchema {
  data: NewModuleInfoData;
}

export type PopularModulesSchema = BaseSchema<SearchModulesPayload>;

export type ContentCenterType = 'yourLibrary' | 'templates' | 'imageLibrary' | 'dataVisual';

export type SearchModule = {
  id: number;
  templateId: number;
  name: string;
  businessUnitId: number;
  publishedAt: string | null;
  cohortName: string;
  productName: string;
  tag: {
    id: number;
    tag: string;
    index: string;
  };
  mailPartName: string;
  businessUnit: {
    id: number;
    name: string;
  };
  deployment: Pick<Deployment, 'id' | 'name'>;
  personalizationInfos?: string[];
  statistics: {
    totalImpressions: number;
    totalClicks: number;
    totalOpens: number;
    ctir: number;
  } | null;
  moduleCopy: string | null;
  moduleUrl: string | null;
  moduleHtml: string;
  moduleDisclosureStation: ModuleDisclosureStation | null;
  moduleDataVisualizationStation?: ModuleDataVisualizationStation | null;
  deploymentsCount: number;
  coverImage?: string;
  createdAt: string;
  updatedAt: string;
  lastUsed: string;
  templateName: string;
};

export type SearchModulesPayload = BasePaginatedData<SearchModule>;

export type SearchModulesSchema = BaseSchema<SearchModulesPayload>;

export interface ModulesSchema {
  popular: PopularModulesSchema;
  library: SearchModulesSchema;
  clone: BaseSchema;
  duplicate: BaseSchema;
  moduleInfo: ModulesInfoSchema;
  newModule: NewModuleInfoSchema;
  refreshArticles: MetaSchema;
  moduleName: BaseSchema;
  renameModuleTemplate: MetaSchema;
  resetModuleTemplateContent: BaseSchema;
  replaceModuleDataVisualizationStation: BaseSchema;
  replaceModuleDisclosureStation: BaseSchema;
}
