import { createStyles, makeStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    moduleLevel: {
      padding: `${variables.gap.xl} ${variables.gap.sm}`,
    },
    statisticsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: `4rem ${variables.gap.xl}`,
    },
    statisticsText: {
      color: variables.color.primary.gray,
      textAlign: 'center',
    },
    chartsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    moduleLibraryContainer: {
      padding: `${variables.gap.lg} 0`,
    },
    modulesGrid: {
      background: 'none',
      padding: `${variables.gap.lg} 0`,
    },
  }),
);
