import { PropsWithChildren, ReactElement, memo } from 'react';
import { Box } from '@material-ui/core';

import { Icon, Typography } from '@components';

import { useStyles } from './DeploymentProcessingAlert.styles';
import { DeploymentProcessingAlertProps } from './DeploymentProcessingAlert.props';

export const DeploymentProcessingAlert = memo(
  ({ type, text }: PropsWithChildren<DeploymentProcessingAlertProps>): ReactElement => {
    const styles = useStyles({ type });

    return (
      <Box className={styles.deploymentProcessingAlert}>
        <Icon.ExclamationMark className={styles.icon} />
        <Typography.Body className={styles.text}>{text}</Typography.Body>
      </Box>
    );
  },
);

DeploymentProcessingAlert.displayName = 'DeploymentProcessingAlert';
