import { HTMLElementWithDocument } from "@routes";

export const getChosenElem = (): HTMLTextAreaElement => {
  const moduleElem = document?.getElementById('module-modal') as HTMLElementWithDocument;
  const selectedElems = moduleElem?.contentDocument.querySelectorAll(
    '[style*="border: 2px solid rgb(107, 17, 201)"], [contenteditable]',
  );
  const elems: any = Array.from(selectedElems).filter((elem: any) => !elem.id.includes('journey'));
  return elems[0];
}

export const rewriteChosenElementContent = (chosenElem: HTMLTextAreaElement, cursorPosition: number, signalBody: string, chosenModuleElemId: string) => {
  chosenElem.innerHTML = [
    chosenElem.innerHTML.replaceAll('&amp;', `&`).slice(0, cursorPosition),
    signalBody,
    chosenElem.innerHTML.replaceAll('&amp;', `&`).slice(cursorPosition),
  ].join('');

  chosenElem.dispatchEvent(new Event("input"));
}