import React from 'react';
import { useParams } from 'react-router-dom';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { Box } from '@material-ui/core';

import { parse, textTemplate, useDeploymentAlerts, useLoader, searchQuery, useWebTitle, useHistory } from '@utils';
import { content, page } from '@content';
import { useAppDispatch } from '@store';
import { ModuleHeader, ModuleInfo, ModuleStation, ModuleStationField, ModuleTemplate } from '@views';
import { global, moduleDetails, userClients, DeploymentStatus, ModuleInfoState, mailer } from '@modules';

import { useStyles } from './ModuleCreator.styles';
import { ModuleCreatorThunkType, ModuleCreatorParams, ModuleDataRecord } from './ModuleCreator.types';
import { merge, generatePznInfos, getFieldNames } from './ModuleCreator.utils';

export const ModuleCreator: React.FC = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  const styles = useStyles();
  const params = useParams<ModuleCreatorParams>();

  const meta = moduleDetails.useMeta();
  const data = moduleDetails.useData();
  const flds = userClients.useFieldsMappingData();
  const fldsMeta = userClients.useFieldsMappingMeta();

  const history = useHistory();

  useWebTitle(textTemplate(content.entityManager, { entity: content.content }), data?.name);

  const [businessUnitId] = searchQuery.useMutualParams('businessUnit');

  const loading = useLoader(meta, fldsMeta, mailer.useEntityMeta());

  const [upds, setUpds] = React.useState<ModuleDataRecord>({});

  const [errs, setErrs] = React.useState(0);

  const subj = React.useMemo(
    () => merge({ ...data }, upds, { id: parse.integer(params.id) }),
    [params.id, data, upds],
  ) as ModuleInfoState;

  const alerts = useDeploymentAlerts(
    { ...subj.deployment, businessUnit: subj.businessUnit },
    subj.businessUnit?.id,
    page.contentCenter,
  );

  const readonly = React.useMemo(
    () =>
      subj.deployment?.status === DeploymentStatus.inMarket || subj.deployment?.status === DeploymentStatus.archived,
    [subj.deployment],
  );

  const dirty = React.useMemo(() => Object.keys(upds).length > 0, [upds]);

  const urlQuery = React.useMemo(() => ({ id: subj.tag?.tag }), [subj.tag?.tag]);

  const dcsUrlQuery = React.useMemo(() => ({ id: `terms_${urlQuery.id}` }), [urlQuery]);

  const opts = React.useMemo(() => getFieldNames(flds), [flds]);

  const withBee = React.useMemo(() => subj.createdWith === 'bee', [subj.createdWith]);

  const [lastThunk, setLastThunk] = React.useState<ModuleCreatorThunkType>(null);

  const runThunk = React.useCallback(
    (type: ModuleCreatorThunkType) => {
      setLastThunk(type);

      let action;

      if (type === 'map') {
        action = userClients.thunk.getFieldsMapping(businessUnitId);
      } else if (type === 'get') {
        action = moduleDetails.thunk.get(subj.id);
      } else if (type === 'save') {
        const updates = merge({}, upds) as Partial<ModuleInfoState>;

        const { moduleDisclosureStation: updsDis, moduleDataVisualizationStation: updsVis } = updates;

        const { personalizationData: subjPzn, ...subjWithoutPzn } = subj;
        const details = subjPzn && !subjPzn.fieldName ? subjWithoutPzn : subj;

        const { imageFile, coverImage, id, ...changes } = updates;
        const { moduleDisclosureStation, moduleDataVisualizationStation, ...rest } = changes;
        const disDirty = updsDis === null || !!updsDis;
        const visDirty = updsVis === null || !!updsVis;
        const restDirty = Object.keys(rest).length > 0;
        const isDirty = disDirty || visDirty || restDirty;

        action = moduleDetails.thunk.save({ details, isDirty });
      } else if (type === 'personalize') {
        action = moduleDetails.thunk.personalize(subj.id);
      } else if (type === 'add') {
        if (subj.deployment) {
          const {
            id: moduleId,
            deployment: { id: deploymentId },
          } = subj;
          history.push('contentCenter', { businessUnit: businessUnitId, deployment: deploymentId, module: moduleId });
        } else if (subj.id) {
          const { id: moduleId } = subj;
          history.push('contentCenter', { businessUnit: businessUnitId, module: moduleId });
        }
      }

      if (action) {
        dispatch(action as AsyncThunkAction<any, any, any>); // eslint-disable-line
      }
    },
    [dispatch, setLastThunk, history, subj, upds, businessUnitId],
  );

  const notify = React.useCallback(
    (message: string, variant = 'error') => {
      dispatch(global.actions.enqueueNotification({ message, options: { variant } }));
    },
    [dispatch],
  );

  const handleUpd = React.useCallback(
    (updates = {}, key = '') => {
      runThunk(null);
      setUpds((prevUpds) => {
        let freshUpds = updates;

        if (key) {
          const innerTarget = { inn: prevUpds[key] };
          const innerSource = { inn: updates };
          const { inn: innerUpds } = merge(innerTarget, innerSource) as ModuleDataRecord;

          freshUpds = { [key]: innerUpds };
        }

        const outerTarget = { out: prevUpds };
        const outerSource = { out: freshUpds };
        const { out: nextUpds } = (merge(outerTarget, outerSource) as ModuleDataRecord) || {};

        return nextUpds as ModuleDataRecord;
      });
    },
    [runThunk, setUpds],
  );

  const handleBeeUpd = React.useCallback(
    async (updates = {}, key = '') => {
      const { html, ...rest } = updates;

      if (html) {
        const result = await dispatch(mailer.thunk.createResource({ businessUnit: businessUnitId, html }));

        if (mailer.thunk.createResource.fulfilled.match(result)) {
          handleUpd({ imageFile: result.payload });
        }
      } else {
        handleUpd(rest, key);
      }
    },
    [dispatch, handleUpd, businessUnitId],
  );

  const handlePznUpd = React.useCallback(
    (updates = {}, key = '') => {
      let fieldName = subj.personalizationData?.fieldName;
      let personalizationInfos = [...(subj.personalizationData?.personalizationInfos || [])];

      if (key) {
        personalizationInfos[key] = { ...personalizationInfos[key], ...updates };
      } else {
        fieldName = updates.fieldName;
        const nextMap = flds?.find((fld) => fld.fieldName === fieldName);

        personalizationInfos = generatePznInfos(nextMap);
      }

      if (lastThunk) {
        runThunk(null);
      }

      setUpds((prevUpds) => ({
        ...prevUpds,
        personalizationData: { fieldName, personalizationInfos },
      }));
    },
    [runThunk, setUpds, flds, subj, lastThunk],
  );

  const handleSwitch = React.useCallback(
    (switched, key) => {
      handleUpd(switched ? {} : null, key);
    },
    [handleUpd],
  );

  const handlePznSwitch = React.useCallback(() => runThunk('personalize'), [runThunk]);

  const handleCopy = React.useCallback(
    (label, value, copied) => {
      if (copied) {
        notify(textTemplate(content.fieldValueCopiedToClipboard, { label }), 'success');
      } else {
        notify(textTemplate(content.fieldValueCopyToClipboardFailed, { label }), 'error');
      }
    },
    [notify],
  );

  const handleSave = React.useCallback(() => runThunk('save'), [runThunk]);

  const handleAdd = React.useCallback(() => runThunk('add'), [runThunk]);

  const handleClear = React.useCallback(() => {
    handlePznUpd();
    setUpds({});
  }, [handlePznUpd, setUpds]);

  const handleError = React.useCallback(
    (fixed) => {
      setErrs(fixed ? errs - 1 : errs + 1);
    },
    [setErrs, errs],
  );

  const parts = React.useMemo(() => {
    if (subj.deployment && subj.deployment.positions?.length) {
      return subj.deployment.positions.map((item) => ({ id: item.experience?.id, name: item.experience?.name }));
    }
    if (subj.experience) {
      return [{ id: subj.experience.id, name: subj.experience.name }];
    }
    return [{ id: 0, name: 'Position name' }];
  }, [subj.deployment, subj.experience]);

  React.useEffect(() => {
    switch (meta.status) {
      case 'idle':
        runThunk('get');
        break;
      case 'loading':
        if (lastThunk === 'save') {
          notify(content.savingModuleUpdates, 'info');
        } else if (lastThunk === 'personalize') {
          const value = subj.isPersonalized ? content.off : content.on;
          notify(textTemplate(content.turningModulePersonalization, { value }), 'info');
        }
        break;
      case 'error':
        if (lastThunk === 'get') {
          notify(content.couldNotLoadModuleDetails, 'error');
        } else if (lastThunk === 'save') {
          notify(content.couldNotSaveModuleUpdates, 'error');
        } else if (lastThunk === 'personalize') {
          const value = subj.isPersonalized ? content.off : content.on;
          notify(textTemplate(content.couldNotTurnModulePersonalization, { value }), 'error');
        }
        break;
      case 'success':
        if (lastThunk === 'get' && !flds?.length) {
          runThunk('map');
        } else if (lastThunk === 'save') {
          if (dirty) {
            setUpds({});
          } else {
            notify(content.moduleUpdatesSaved, 'success');
          }
        } else if (lastThunk === 'personalize') {
          if (subj.isPersonalized) {
            runThunk('map');
          }
          if (subj.moduleUrl) {
            handleUpd({
              moduleUrl: subj.isPersonalized ? subj.moduleUrl?.replace(/.$/, 'Y') : subj.moduleUrl?.replace(/.$/, 'N'),
            });
          }
          if (subj.moduleDisclosureStation?.url) {
            const url = subj.moduleDisclosureStation?.url;
            handleUpd(
              { url: subj.isPersonalized ? url?.replace(/.$/, 'Y') : url?.replace(/.$/, 'N') },
              'moduleDisclosureStation',
            );
          }
          const value = subj.isPersonalized ? content.on : content.off;
          notify(textTemplate(content.modulePersonalizationTurned, { value }), 'success');
        }
        break;
      default:
    }
  }, [runThunk, setUpds, notify, meta.status, lastThunk, dirty, subj, flds, handleUpd]);

  React.useEffect(
    () => () => {
      dispatch(userClients.slice.actions.resetFieldsMapping());
      dispatch(moduleDetails.slice.actions.reset());
    },
    [dispatch],
  );

  return (
    <Box className={styles.moduleCreator}>
      {(meta.status === 'success' || data) && (
        <>
          <ModuleHeader
            title={[]}
            actions={[
              {
                label: content.save,
                disabled: !dirty || loading || errs !== 0,
                onClick: handleSave,
              },
              {
                label: content.copyFromLibrary,
                onClick: handleAdd,
                disabled: alerts.length > 0 || subj.deployment?.isTechnical,
              },
              { label: content.clear, disabled: !dirty || loading, onClick: handleClear },
            ]}
            disabled={loading}
            readonly={readonly}
          />
          {/* {alerts.length > 0 && alerts.map((alert) => (
            <DeploymentProcessingAlert key={alert.id} type={alert.type} text={alert.text} />
          ))} */}
          <Box className={styles.body}>
            <ModuleInfo
              businessUnit={subj.businessUnit?.name}
              programName={subj.deployment?.programName}
              deployDate={subj.deployment?.waveDate}
              tag={subj.tag?.tag}
              experienceName={subj.experience?.name}
            />
            <Box className={styles.contentModuleCreator}>
              <Box className={styles.contentWrap}>
                <Box className={styles.content}>
                  <Box className={styles.stations} data-wide={withBee}>
                    <ModuleStation
                      className={styles.station}
                      title={content.emailBuilder}
                      viewable={withBee}
                      readonly={readonly || loading}
                      wideBody
                      fields={[
                        {
                          id: 'articleLibrary',
                          type: 'articleLibrary',
                        },
                        {
                          id: 'moduleHtmlTemplate',
                          type: 'bee',
                          value: JSON.stringify(subj.moduleHtmlTemplate),
                          query: urlQuery,
                        },
                      ]}
                      onChange={handleBeeUpd}
                    />
                    <ModuleStation
                      className={styles.station}
                      title={content.personalizationStation}
                      switchable
                      viewable
                      disabled={loading}
                      readonly={readonly}
                      fields={
                        subj.isPersonalized
                          ? [
                              {
                                id: 'copyVariable',
                                type: 'var',
                                label: textTemplate(content.variable, { value: content.copy }),
                                value: subj.personalizationVariables?.copyVariable || null,
                              },
                              {
                                id: 'urlVariable',
                                type: 'var',
                                label: textTemplate(content.variable, { value: content.url }),
                                value: subj.personalizationVariables?.urlVariable || null,
                              },
                              {
                                id: 'imageVariable',
                                type: 'var',
                                label: textTemplate(content.variable, { value: content.image }),
                                value: subj.personalizationVariables?.imageVariable || null,
                              },
                              {
                                id: 'disclosureVariable',
                                type: 'var',
                                label: textTemplate(content.variable, { value: content.disclosure }),
                                value: subj.personalizationVariables?.disclosureVariable || null,
                              },
                              {
                                id: 'disclosureUrlVariable',
                                type: 'var',
                                label: textTemplate(content.variable, { value: content.disclosureUrl }),
                                value: subj.personalizationVariables?.disclosureUrlVariable || null,
                              },
                            ]
                          : null
                      }
                      extras={
                        subj.isPersonalized
                          ? [
                              {
                                id: 'fieldName',
                                type: 'sel',
                                label: content.fieldName,
                                placeholder: textTemplate(content.selectValue, { value: content.fieldName }),
                                value: subj.personalizationData?.fieldName || opts[0].id,
                                options: opts,
                              },
                              ...((subj.personalizationData?.personalizationInfos
                                ? subj.personalizationData?.personalizationInfos.reduce(
                                    (fields, info, index) => [
                                      ...fields,
                                      {
                                        rootKey: `${index}`,
                                        id: 'value',
                                        type: 'lbl',
                                        style: 'title',
                                        label: content.pznValue,
                                        value: info.value.value,
                                      },
                                      {
                                        rootKey: `${index}`,
                                        id: 'description',
                                        type: 'lbl',
                                        style: 'body',
                                        label: content.valueDescription,
                                        value: info.value.description,
                                      },
                                      {
                                        rootKey: `${index}`,
                                        id: 'imagePath',
                                        type: 'url',
                                        wide: true,
                                        label: content.imageUrl,
                                        value: info.imagePath,
                                        query: urlQuery,
                                      },
                                      {
                                        rootKey: `${index}`,
                                        id: 'defaultContent',
                                        type: 'wsg',
                                        label: content.personalizationDisplayLanguage,
                                        value: info.defaultContent,
                                      },
                                      {
                                        rootKey: `${index}`,
                                        id: 'dynamicDisclosure',
                                        type: 'wsg',
                                        label: content.dynamicDisclosure,
                                        value: info.dynamicDisclosure,
                                      },
                                      {
                                        rootKey: `${index}`,
                                        id: 'trackingUrl',
                                        type: 'url',
                                        label: content.trackingUrl,
                                        value: info.trackingUrl,
                                        query: urlQuery,
                                      },
                                      {
                                        rootKey: `${index}`,
                                        id: 'trackingDisclosureUrl',
                                        type: 'url',
                                        label: content.trackingDisclosureUrl,
                                        value: info.trackingDisclosureUrl,
                                        query: dcsUrlQuery,
                                      },
                                    ],
                                    [] as ModuleStationField[],
                                  )
                                : []) as ModuleStationField[]),
                            ]
                          : null
                      }
                      onChange={handlePznUpd}
                      onError={handleError}
                      onSwitch={handlePznSwitch}
                      onCopy={handleCopy}
                    />
                    <ModuleStation
                      className={styles.station}
                      title={content.contentStation}
                      viewable={!withBee}
                      disabled={loading}
                      readonly={readonly}
                      fields={[
                        {
                          id: 'moduleCopy',
                          type: 'wsg',
                          label: content.moduleCopy,
                          value: subj.moduleCopy,
                        },
                        {
                          id: 'moduleUrl',
                          type: 'url',
                          label: content.moduleUrl,
                          value: subj.moduleUrl,
                          query: urlQuery,
                        },
                      ]}
                      onChange={handleUpd}
                      onError={handleError}
                      onCopy={handleCopy}
                    />
                    <ModuleStation
                      className={styles.station}
                      title={content.disclosureStation}
                      innerKey="moduleDisclosureStation"
                      switchable
                      viewable
                      disabled={loading}
                      readonly={readonly}
                      fields={
                        subj.moduleDisclosureStation
                          ? [
                              {
                                id: 'disclosureCopy',
                                type: 'wsg',
                                label: content.disclosureCopy,
                                value: subj.moduleDisclosureStation.disclosureCopy,
                              },
                              {
                                id: 'url',
                                type: 'url',
                                label: content.disclosureUrl,
                                value: subj.moduleDisclosureStation.url,
                                query: dcsUrlQuery,
                              },
                              { type: '' },
                              {
                                id: 'blockId',
                                type: 'txt',
                                label: content.disclosureBlockId,
                                value: subj.moduleDisclosureStation.blockId,
                              },
                            ]
                          : null
                      }
                      onChange={handleUpd}
                      onError={handleError}
                      onSwitch={handleSwitch}
                      onCopy={handleCopy}
                    />
                    <ModuleStation
                      className={styles.station}
                      title={content.dataVisualizationStation}
                      innerKey="moduleDataVisualizationStation"
                      switchable
                      viewable
                      disabled={loading}
                      readonly={readonly}
                      fields={
                        subj.moduleDataVisualizationStation
                          ? [
                              {
                                id: 'embedCodeFull',
                                type: 'txt',
                                label: textTemplate(content.embedCode, { value: content.company }),
                                value: subj.moduleDataVisualizationStation?.embedCode || null,
                                clipboardCopy: true,
                                disabled: true,
                              },
                              {
                                id: 'library',
                                type: 'visualLibrary',
                                value: subj.moduleDataVisualizationStation?.dataVisualization?.id || null,
                              },
                              {
                                id: '3rdPartyDesktop',
                                type: 'txt',
                                label: textTemplate(content.embedCode, { value: content.thirdPartyDesktop }),
                                value: '',
                                disabled: true,
                              },
                              {
                                id: '3rdPartyMobile',
                                type: 'txt',
                                label: textTemplate(content.embedCode, { value: content.thirdPartyMobile }),
                                value: '',
                                disabled: true,
                              },
                            ]
                          : null
                      }
                      onChange={handleUpd}
                      onError={handleError}
                      onSwitch={handleSwitch}
                      onCopy={handleCopy}
                    />
                    <ModuleStation
                      className={styles.station}
                      title={content.htmlStation}
                      collapsed
                      viewable
                      disabled={loading}
                      readonly={readonly}
                      fields={
                        [
                          {
                            id: 'moduleHtml',
                            type: 'htm',
                            label: content.moduleHtml,
                            value: subj.moduleHtml,
                            disabled: withBee,
                          },
                          subj.moduleDisclosureStation && {
                            rootKey: 'moduleDisclosureStation',
                            id: 'disclosureHtml',
                            type: 'htm',
                            label: content.disclosureHtml,
                            value: subj.moduleDisclosureStation.disclosureHtml,
                          },
                        ].filter((field) => field) as ModuleStationField[]
                      }
                      onChange={handleUpd}
                      onError={handleError}
                    />
                  </Box>
                  {!withBee && (
                    <ModuleTemplate
                      className={styles.template}
                      image={subj.coverImage}
                      parts={parts}
                      selectedId={subj.experience?.id || 0}
                      disabled={loading}
                      readonly={readonly}
                      onError={notify}
                      onChange={handleUpd}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
