import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const iconWidth = '3rem';

export const useStyles = makeStyles(() =>
  createStyles({
    moduleInfo: {
      display: 'flex',
      padding: 0,
      height: '7.5rem',
    },

    item: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: variables.gap.xl,
      width: '20%',

      '& > *:first-child': {
        width: iconWidth,
      },
    },

    text: {
      marginLeft: variables.gap.lg,
      width: `calc(100% - ${variables.gap.lg} - ${iconWidth})`,
      textTransform: 'uppercase',
    },

    label: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: variables.color.primary.gray,
    },
  }),
);
