import { Box } from '@material-ui/core';

import { content } from '@content';
import { Typography, BarSingleChart } from '@components';

import { useStyles } from './ModulePerformanceByPosition.styles';
import { ListItemPositionProps } from './ListItemPosition.props';

export const ListItemPosition = ({ position, value, color, index }: ListItemPositionProps): JSX.Element => {
  const styles = useStyles();
  return (
    <Box className={styles.listItem}>
      <Box className={styles.listItemRightContent}>
        <Typography.Tag>{`${content.position} ${index + 1}`}</Typography.Tag>
        <Typography.Title>{position}</Typography.Title>
      </Box>
      <Box>
        <Typography.Tag>{`${content.ctir} %`}</Typography.Tag>
        <BarSingleChart data={[{ label: position, value }]} color={color} />
      </Box>
    </Box>
  );
};
