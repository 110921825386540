/*
  Menu component styles.
*/

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: variables.color.primary.white,
      minWidth: 'unset',
      padding: theme.spacing(0.75),
      borderWidth: variables.borderWidth.sm,
      borderColor: variables.color.primary.lightGrayDarker,
      position: 'relative',

      '&:hover, &[data-opened="true"]': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
        //TODO: remove borderWidth on data-opened=true
        borderWidth: variables.borderWidth.sm,
        borderColor: variables.color.primary.mainPurple,

        '& svg, & svg path': {
          stroke: variables.color.primary.mainPurple,
        },

        '& $menuTitle': {
          color: variables.color.primary.mainPurple,
        },
      },

      '&.MuiButton-containedSecondary': {
        '& svg': {
          stroke: variables.color.primary.white,
        },

        '&:hover, &[data-opened="true"]': {
          backgroundColor: variables.color.secondary.blue,
        },
      },
    },

    menuTitle: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 0.75),
      textTransform: 'none',

      '& svg': {
        marginLeft: theme.spacing(1),
      },
    },

    paper: {
      padding: theme.spacing(1),
      minWidth: '24rem',
      boxShadow: '0 0.4rem 1.6rem rgba(0, 0, 0, 0.2)',
      borderRadius: variables.borderRadius.md,

      '&[data-anchor-y="bottom"]': {
        marginTop: variables.gap.xxs,
      },

      '&[data-anchor-y="top"]': {
        marginLeft: variables.gap.xxs,
      },
    },

    menuList: {
      padding: '0',
    },
  }),
);
