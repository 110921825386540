import { SVGProps } from 'react';

export const Italic = ({ className = '', strokeWidth = '1.5' }: SVGProps<SVGElement>): JSX.Element => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M12.5413 1H7.15527"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.38607 12.541H1"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.69287 12.5417L9.84839 1"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
