import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Pagination component styles
 * @returns {Function}
 */

const arrows = {
  margin: '0 0.2rem',
  padding: '0.6rem 0.8rem',
  borderRadius: variables.borderRadius.sm,
  height: '3rem',

  '& svg': {
    margin: '0 0.1rem',
  },

  '&:hover': {
    backgroundColor: variables.color.primary.lightestPurple,

    '& svg path': {
      stroke: variables.color.primary.mainPurple,
    },

    '& span': {
      color: variables.color.primary.mainPurple,
    },
  },

  '&.Mui-disabled': {
    '& svg path': {
      stroke: variables.color.primary.halfMediumGray,
    },

    '& span': {
      color: variables.color.primary.halfMediumGray,
    },
  },
};

export const useStyles = makeStyles(() =>
  createStyles({
    prev: {
      ...arrows,
    },
    next: {
      ...arrows,
    },
    item: {
      color: variables.color.primary.darkestGray,
      fontWeight: variables.font.weight.bold,
      fontSize: variables.font.size.md,
      lineHeight: variables.lineHeight.lg,
      margin: '0 0.2rem',
      padding: '0.6rem 1.2rem',
      height: '3rem',
      borderRadius: variables.borderRadius.sm,

      '&[aria-current="true"]': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
      },

      '&&': {
        '&:hover': {
          backgroundColor: variables.color.primary.lightestPurple,
          color: variables.color.primary.mainPurple,
        },
      },
    },
  }),
);
