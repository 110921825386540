import { OptionItem } from '@components';
import { TopBarContextValue } from './TopBar.types';
import { content } from '@content';
import { OptionsTypes } from '@views/LibraryNavigation/LibraryNavigation.const';

export const TOP_BAR_CTX_VALUE: TopBarContextValue = {
  variant: 'section',
  sectionName: '',
  deployments: [],
  deploymentId: 0,
  breadcrumbs: [],
  editableBreadcrumbs: [],
  experienceId: 0,
  experiences: [],
  businessUnits: [],
  businessUnitId: 0,
  mailFiles: [],
  mailFileId: 0,
  templates: [],
  templateId: 0,
  moduleType: '',
  moduleName: '',
  chosenTreatment: undefined,
  contentModuleName: '',
  editableImageName: '',
  moduleDeployment: '',
  currentEmailTemplate: '',
  lastEmailTemplateId: undefined,
  isTemplateListVisible: false,
  isTemplateIdChanged: false,
};

export enum MenuKeys {
  CHANGE_EMAIL_TEMPLATE = 'change-email-template',
  MANAGE_DEPLOYMENT = 'manage-deployment',
}

export const MENU_OPTIONS: OptionItem[] = [
  {
    id: MenuKeys.CHANGE_EMAIL_TEMPLATE,
    label: content.ChangeEmailTemplate,
    type: OptionsTypes.LAST_USED,
  },
  {
    id: MenuKeys.MANAGE_DEPLOYMENT,
    label: content.ManageDeployment,
    type: OptionsTypes.LAST_USED,
  },
];
