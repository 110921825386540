import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useTooltipStyle = makeStyles(() =>
  createStyles({
    barChartTooltip: {
      width: '15rem',
      padding: variables.gap.sm,
      position: 'relative',
      boxShadow: `0.1rem 0.1rem 0.7rem ${variables.color.tertiary.gray}`,
      background: variables.color.tertiary.white,
      borderRadius: variables.borderRadius.sm,
      zIndex: 1,
      '&::after': {
        content: "''",
        position: 'absolute',
        width: '1.5rem',
        height: '1.5rem',
        boxShadow: `0.1rem 0.1rem 0.7rem ${variables.color.tertiary.gray}`,
        bottom: '-0.7rem',
        left: 'calc(50% - 0.8rem)',
        background: variables.color.tertiary.white,
        transform: 'rotate(45deg)',
        zIndex: -2,
      },
      '&::before': {
        content: "''",
        width: '3rem',
        height: '1.5rem',
        position: 'absolute',
        background: variables.color.tertiary.white,
        left: 'calc(50% - 1.5rem)',
        bottom: 0,
        zIndex: -1,
      },
    },
    tooltipCaption: {
      color: variables.color.primary.gray,
    },
  }),
);

export const useTrendStyle = makeStyles(() =>
  createStyles({
    barChartTrend: {
      position: 'relative',
      left: '40rem',
      top: '5.5rem',
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      marginLeft: variables.gap.xs,
    },
  }),
);
