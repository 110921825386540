import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ModuleModal component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    DVModalWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      position: 'absolute',
      backgroundColor: '#fff',
      height: 'calc(150vh + 24.2rem)',
      top: 0,
      zIndex: 1,
      padding: '25rem 0 0',
    },

    moduleContent: {
      position: 'relative',
      width: '100%',
    },

    imageWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(62% - 26.5px)',
      margin: '0 5px',
      backgroundColor: variables.color.primary.backgroundLighterGray,
      borderRadius: '3px',
      overflow: 'hidden',
    },

    image: {
      maxHeight: '100%',
      maxWidth: '100%',
    },

    defaultImageWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'calc(100% - 10px)',
      height: 'calc(38% - 26.5px)',
      backgroundColor: variables.color.primary.backgroundLighterGray,
      margin: '0 5px',
      borderRadius: '3px',
      overflow: 'hidden',
    },

    DVImageLabel: {
      fontSize: '20px',
      textTransform: 'uppercase',
      position: 'absolute',
      top: '20px',
      left: '20px',
    },

    defaultImage: {
      maxHeight: '100%',
      maxWidth: '100%',
    },

    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px',
    },

    actionBtn: {

    },

    segmentSelect: {
      width: '240px'
    }
  }),
);
