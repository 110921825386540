import { FC, ReactElement } from 'react';
import { Typography } from '@components';
import { Typography as MuiTypography } from '@material-ui/core';

import { format } from '@utils';
import { content } from '@content';

import { useTooltipStyle } from './PieChart.styles';
import { PieChartTooltipProps } from './PieChart.props';

export const PieChartTooltip: FC<PieChartTooltipProps> = ({ datum: { value, data } }): ReactElement => {
  const styles = useTooltipStyle();
  return (
    <div className={styles.tooltip}>
      <Typography.Caption className={styles.caption}>{data?.title}</Typography.Caption>
      <MuiTypography className={styles.text}>{`${content.impression}: ${format.number(value)}`}</MuiTypography>
      <MuiTypography className={styles.text}>{`${content.ctir}: ${format.percent(data?.ctir)}`}</MuiTypography>
    </div>
  );
};
