import { withStyles, Tab as MuiTabs } from '@material-ui/core';
import { variables } from '@styles';

import { TabProps } from './Tab.props';

export const Tab = withStyles({
  root: {
    textTransform: 'none',
    color: ({ color }: { color?: string; margin?: string }) => color || variables.color.primary.gray,
    fontSize: variables.font.size.md,
    border: `0.4rem solid ${variables.color.primary.lightGreen}`,
    boxSizing: 'border-box',
    borderTopLeftRadius: variables.borderRadius.sm,
    borderTopRightRadius: variables.borderRadius.sm,
    marginRight: variables.gap.md,
    letterSpacing: variables.letterSpacing.sm,
    padding: '2.8rem 0',
    '&:focus': {
      fontWeight: variables.font.weight.bold,
      background: variables.color.primary.lightGreen,
    },
    '&:selected': {
      fontWeight: variables.font.weight.bold,
      background: variables.color.primary.lightGreen,
    },
  },
  selected: {
    fontSize: variables.font.size.md,
    fontWeight: variables.font.weight.bold,
    background: variables.color.primary.lightGreen,
  },
})((props: TabProps) => <MuiTabs disableRipple {...props} />);
