import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';

import { Typography, TextField, SelectOptions } from '@components';
import { content } from '@content';
import { Conditions, ConditionsProps } from '@routes/DataCenter/Conditions';
import { format, textTemplate, useLoader, useHistory } from '@utils';
import {
  signal,
  signalLibrary,
  experience,
  MindsetData,
  GetSubscriberCountArg,
  deployment,
  Signal,
  ExperienceItemMindset,
  attributeLibrary,
  modules,
} from '@modules';
import {
  RenameModuleModal,
  TreatmentBuilderContext,
  RenameModuleModalFormValues,
  getExperienceElement,
  EMPTY_MODULE_NOTE,
  DroppableAttribute,
  ModuleTemplateType,
} from '@routes';
import { useAppDispatch } from '@store';

import { SignalBuilderContext, DEFAULT_CONDITIONS, DEFAULT_MINDSET_INDEX } from '../SignalBuilder.const';

import { SignalLibraryBuilderProps } from './SignalLibraryBuilder.props';
import { useStyles } from './SignalLibraryBuilder.styles';
import { getSaveButtonLabel, preparePayload, prepareRenamePayloadBuilder } from './SignalLibraryBuilder.utils';
import { SearchSelect } from '@components/SearchSelect';
import { searchOptions } from '@modules/SignalLibrary/SignalLibrary.thunk';
import { broadcaster } from '@utils/network/broadcast';

export const SignalLibraryBuilder = memo(
  ({ uiType = 'signal', treatmentPanelType, onClose, selectTreatment }: SignalLibraryBuilderProps) => {
    const styles = useStyles();

    const { control, trigger, getValues, watch, reset } = useFormContext();
    const dispatch = useAppDispatch();
    const { deployment: deploymentId, businessUnit: businessUnitId } = useHistory().query;

    const [isCountsRefreshed, setIsCountsRefreshed] = useState(false);
    const [isAttributeChanged, setIsAttributeChanged] = useState(false);
    const [showRenameModuleModal, setShowRenameModuleModal] = useState(false);

    const { fields } = useFieldArray({ name: 'conditions', keyName: 'conditionId' });
    const watchConditionsArray = watch(`mindsets.${DEFAULT_MINDSET_INDEX}.conditions`) || [];
    const countsData = experience.useCountsData()[0];
    const signalMeta = signal.useItemMeta();
    const signalLibraryMeta = signalLibrary.useListMeta();
    const { items: attributes } = attributeLibrary.useListData();
    const rawSignalOptions = signalLibrary.useOptionsData();
    const experienceCountsMeta = experience.useCountsMeta();
    const mindsetsData = experience.useMindsetsData();
    const experienceData = experience.useItemData();
    const dpt = deployment.useDeploymentData();
    const [curAttribute, setCurAttribute] = useState<Signal>();
    const { chosenAttributeId, setChosenAttributeId } = useContext(SignalBuilderContext);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [signalData, setSignalData] = useState<any>();
    const [newExpName, setNewExpName] = useState<string>();
    const [initialAttribute, setInitialAttribute] = useState<any>();
    const isDisabled = useMemo(
      () =>
        (!isCountsRefreshed && treatmentPanelType === 'logicBuilder') ||
        (!isAttributeChanged && treatmentPanelType === 'externalScoring'),
      [isCountsRefreshed, isAttributeChanged, treatmentPanelType],
    );
    const {
      editableTreatmentId,
      chosenElemId,
      currentExperienceId,
      setNewExperienceCloned,
      chosenModuleId: chosenTreatmentId,
      chosenExperienceId,
      setChosenExperienceId,
      setChosenModuleId,
      setChosenElemId,
      setDefaultChosenTreats,
      setChosenModuleInnerHtml,
    } = useContext(TreatmentBuilderContext);
    const preSelectedOptions = useMemo(() => {
      return experienceData?.modules
        ?.map((module: any) => (module.conditions ? module.conditions[0]?.value : undefined))
        .filter((id) => id) as SelectOptions<string>;
    }, [experienceData?.modules]);

    useLoader(signalMeta, signalLibraryMeta, experienceCountsMeta);

    const signalCounts = useMemo(() => {
      const audience = (countsData?.match / countsData?.total) * 100;
      return {
        targetCount: countsData?.match,
        audience: Number.isNaN(audience) ? 0 : audience,
      };
    }, [countsData]);

    const conditions = fields.map((field, i) => ({
      ...field,
      ...watchConditionsArray[i],
    }));
    const [addedConditions, setAddedConditions] = useState([]);
    const [conditionsError, setConditionError] = useState('');

    const validate = (skipNameValidation = false, value?: any) => {
      if (!(value ? value : addedConditions).length && treatmentPanelType === 'logicBuilder') {
        setConditionError('At least one condition must be added.');

        return false;
      }

      const requiredFields = conditions.reduce(
        (acc, val, idx) => {
          const conditionKey = `conditions.${idx}`;
          const conditionValuesKeys = Object.keys(getValues(`${conditionKey}.value`));

          return [
            ...acc,
            `${conditionKey}.signal`,
            `${conditionKey}.operator`,
            `${conditionKey}.value`,
            ...(conditionValuesKeys.length ? conditionValuesKeys.map((key) => `${conditionKey}.value.${key}`) : []),
          ];
        },
        [...(skipNameValidation ? [] : ['name']), 'metConditionCount'],
      );

      return trigger(requiredFields);
    };

    const clearForm = useCallback(() => {
      dispatch(signal.actions.resetCounts());
      dispatch(signal.actions.resetSignal());
      reset({
        id: undefined,
        name: '',
        type: 'mixed',
        businessUnit: {
          id: businessUnitId,
        },
        metConditionCount: 1,
        conditions: DEFAULT_CONDITIONS,
      });
    }, [businessUnitId, dispatch, reset]);

    const saveSignal = async () => {
      const isValid = await validate();
      const data = getValues();
      const dataWithConditions = {
        ...data,
        businessUnit: data.businessUnit,
        conditions: data.mindsets[DEFAULT_MINDSET_INDEX].conditions,
        metConditionCount: data.mindsets[DEFAULT_MINDSET_INDEX].metConditionCount,
        name: data.name,
        type: data.type,
      };

      if (isValid) {
        if (data?.id) {
          await dispatch(signalLibrary.thunk.updateComposite(dataWithConditions));
        } else {
          await dispatch(signalLibrary.thunk.createComposite(dataWithConditions));
        }
        setChosenAttributeId(null);
        clearForm();
      }
    };

    const getNewTreatmentsData = useCallback(
      (data) => {
        let newMindsetData = data.mindsets[DEFAULT_MINDSET_INDEX];
        const mindsetData = mindsetsData.find((mindset) => mindset.experienceId === currentExperienceId);
        const treatments = mindsetData?.modules;

        let groupedTreatments = treatments?.filter((treatment) => !treatment.isNullSubscriber);
        const editedGroupedTreatment = groupedTreatments?.find((treatment) => treatment.id === editableTreatmentId);

        if (editableTreatmentId && editedGroupedTreatment) {
          newMindsetData = { ...data.mindsets[DEFAULT_MINDSET_INDEX], id: editedGroupedTreatment.id };
          groupedTreatments = groupedTreatments?.filter((treatment) => treatment.id !== editableTreatmentId);
        }

        if (groupedTreatments) {
          return {
            newTreatmentsData: [...groupedTreatments, newMindsetData],
            editedGroupedTreatment,
          };
        } else {
          return null;
        }
      },
      [currentExperienceId, editableTreatmentId, mindsetsData],
    );

    const saveTreatment = useCallback(
      async (experienceId: number, newExperienceName = '', groupedTreatments: ExperienceItemMindset[] = []) => {
        const isValid = await validate();
        const data = getValues();
        const editedGroupedTreatment = getNewTreatmentsData(data)?.editedGroupedTreatment;
        const universalTreatment = experienceData?.modules?.filter((module) => module.name === 'Universal')[0];

        if (businessUnitId && experienceId && isValid && groupedTreatments) {
          let result: any;
          let curModules: ExperienceItemMindset[] = [];

          if (treatmentPanelType === 'logicBuilder') {
            curModules = mindsetsData.find((item) => item.experienceId === experienceId)?.modules || [];
            const filteredTreatments = groupedTreatments.filter((treatment) => treatment);

            result = await dispatch(
              experience.thunk.update([
                preparePayload(
                  businessUnitId,
                  String(newExperienceName ? newExperienceName : experienceData?.name),
                  data.name,
                  filteredTreatments,
                ),
                experienceId,
              ]),
            );
          } else {
            const payload = {
              businessUnit: { id: businessUnitId },
              signal: { id: curAttribute?.id },
              name: data.name,
              signal_options: selectedOptions.map((selectedOption: any) => selectedOption.id),
            };
            result = await dispatch(experience.thunk.createExternalScoreTreatment([payload, experienceId]));
          }

          if (selectTreatment) {
            dispatch(experience.actions.updateModule(universalTreatment!));

            selectTreatment(universalTreatment!);
          }

          if (experience.thunk.update.fulfilled.match(result) && result.payload) {
            broadcaster.postMessage('refresh');
          }

          if (onClose) {
            onClose();
          }

          setChosenAttributeId(null);

          if (!editedGroupedTreatment) {
            clearForm();
          }

          return result.payload.modules;
        }
      },
      [
        clearForm,
        addedConditions,
        businessUnitId,
        currentExperienceId,
        mindsetsData,
        chosenTreatmentId,
        curAttribute,
        dispatch,
        experienceData,
        selectedOptions,
        getValues,
        setChosenAttributeId,
        validate,
        getNewTreatmentsData,
        setChosenModuleId,
        setDefaultChosenTreats,
        setChosenModuleInnerHtml,
      ],
    );

    const handleTreatmentSave = useCallback(async () => {
      if (
        (experienceData?.deployments.length ?? 0) > 1 ||
        experienceData?.type === 'revision' ||
        experienceData?.revisions.length
      ) {
        setShowRenameModuleModal(true);
      } else {
        const data = getValues();
        const newGroupedTreatments = getNewTreatmentsData(data)?.newTreatmentsData as ExperienceItemMindset[];

        const curModules = await saveTreatment(currentExperienceId!, '', newGroupedTreatments);
        const modulesCount = curModules.length;

        if (modulesCount) {
          const type: ModuleTemplateType = modulesCount === 1 ? 'single' : 'group';
          const elemIdParts = chosenElemId.split('-');
          const index = elemIdParts[elemIdParts.length - 1];
          const divId = `journey-${type}-div-${index}`;

          setChosenElemId(divId);
        }
      }
    }, [
      experienceData,
      chosenElemId,
      mindsetsData,
      currentExperienceId,
      chosenTreatmentId,
      setShowRenameModuleModal,
      saveTreatment,
      getValues,
    ]);

    const handleRenameModuleModalClose = useCallback(() => {
      setShowRenameModuleModal(false);
    }, [setShowRenameModuleModal]);

    const handleConfirmRenameModule = useCallback(
      async (nameData: RenameModuleModalFormValues) => {
        let experienceId: number | undefined;
        const data = getValues();

        if (businessUnitId && deploymentId && chosenTreatmentId) {
          const isValid = await validate();
          const newGroupedTreatments = getNewTreatmentsData(data)?.newTreatmentsData;
          const renamePayload = prepareRenamePayloadBuilder(
            businessUnitId,
            experienceData,
            newGroupedTreatments ?? [],
            {
              id: deploymentId,
            },
            nameData.name,
            data.name,
            { id: currentExperienceId! },
          );
          const preparedMindsets = [...(renamePayload.mindsets ?? [])] as any[];
          renamePayload.mindsets = renamePayload.mindsets?.filter((mindset) => mindset.id);

          if (currentExperienceId && isValid && newGroupedTreatments) {
            const createExperienceResult = await dispatch(experience.thunk.create(renamePayload));

            if (experience.thunk.create.fulfilled.match(createExperienceResult)) {
              const payload = createExperienceResult.payload;
              const newExpId = payload?.id;
              experienceId = payload?.id;
              const mindsets = payload?.modules?.filter((module) => module.name !== 'Universal') ?? [];
              const newModule = preparedMindsets.filter((mindset) => !mindset.id)[0];

              await saveTreatment(experienceId ?? 0, payload?.name, [...mindsets, newModule]);

              if (newExpId) {
                const newMindsets = await dispatch(experience.thunk.getItemMindsets(newExpId));

                const splitterId = getExperienceElement(payload, String(chosenExperienceId));

                setNewExperienceCloned({
                  cloned: true,
                  splitterId: splitterId ? splitterId : null,
                  experienceId: newExpId,
                });

                setChosenExperienceId(newExpId);

                if (experience.thunk.getItemMindsets.fulfilled.match(newMindsets) && newMindsets.payload) {
                  const newModuleId = newMindsets.payload[0]?.id;

                  if (newModuleId) {
                    setChosenModuleId(newModuleId);
                  }
                }
              }
            }

            setChosenAttributeId(null);
          }
        }

        handleRenameModuleModalClose();

        if (treatmentPanelType === 'externalScoring') {
          const newGroupedTreatments = getNewTreatmentsData(data)?.newTreatmentsData as ExperienceItemMindset[];

          await saveTreatment(experienceId!, '', newGroupedTreatments);
        }
      },
      [
        currentExperienceId,
        chosenTreatmentId,
        businessUnitId,
        deploymentId,
        dispatch,
        experienceData,
        handleRenameModuleModalClose,
        chosenExperienceId,
        setNewExperienceCloned,
        getValues,
        validate,
        getNewTreatmentsData,
        setChosenAttributeId,
        setChosenExperienceId,
        setChosenModuleId,
      ],
    );

    const refreshCounts = useCallback(() => {
      if (isCountsRefreshed) setIsCountsRefreshed(false);
    }, [isCountsRefreshed, setIsCountsRefreshed]);

    const handleRefresh =
      (skipNameValidation = false, value?: any) =>
      async () => {
        const isValid = await validate(skipNameValidation, value);

        if (isValid) {
          const values = getValues();

          const newValues = {
            ...values,
            conditions: values.mindsets[DEFAULT_MINDSET_INDEX].conditions,
            mindsets: values.mindsets.filter((item: MindsetData[]) => !!item),
            mailFile: dpt?.mailFile?.id,
          } as unknown as GetSubscriberCountArg;

          dispatch(experience.thunk.getSubscriberCount(newValues));

          setIsCountsRefreshed(true);
        }
      };

    const handleConditionsRulesChange = useCallback<Required<ConditionsProps>['onChange']>(
      async (value: any) => {
        if (uiType === 'signal') {
          refreshCounts();
        } else if (uiType === 'treatment') {
          await handleRefresh(true, value)();
        }
      },
      [refreshCounts, handleRefresh, uiType],
    );

    const handleConditionsChange = (value: any) => {
      setAddedConditions(value);

      if (value.length) {
        setConditionError('');
      }
    };

    const handleAttributeChange = useCallback(
      (attribute: Signal) => {
        if (attribute) {
          setCurAttribute(attribute);
          setIsAttributeChanged(initialAttribute?.id !== attribute.id);
          dispatch(searchOptions({ signal: attribute.id, itemsPerPage: 10000 }));
        }
      },
      [searchOptions, curAttribute, dispatch, setCurAttribute, setIsCountsRefreshed],
    );

    const handleSearchOptionsChange = useCallback(
      (keyword: string) => {
        if (curAttribute) {
          dispatch(searchOptions({ signal: curAttribute?.id, keyword }));
        }
      },
      [curAttribute, dispatch, searchOptions],
    );

    const handleSelectedOptionsChange = (options: any) => {
      setSelectedOptions(options);
    };

    const handleTouchOptions = () => {
      setIsAttributeChanged(true);
    };

    const handleCancel = () => {
      if (onClose) {
        onClose();
      }
    };

    useEffect(() => {
      if (businessUnitId && chosenAttributeId) {
        dispatch(signal.thunk.getItem({ businessUnit: businessUnitId, signal: chosenAttributeId }));
      }
    }, [chosenAttributeId, businessUnitId, dispatch]);

    useEffect(() => {
      const options = rawSignalOptions.items.map((option: any) => ({
        id: option.id,
        label: option.value,
        signalId: curAttribute?.id ?? 0,
        selected: preSelectedOptions.length
          ? !!preSelectedOptions.find((preSelected) => (preSelected as any) === option.value)
          : true,
        value: option.id,
      }));

      if (options.length && curAttribute) {
        setSignalData({
          options,
          id: curAttribute?.id ?? 0,
        });
      } else {
        setSignalData({
          options: [],
          id: 0,
        });
      }
    }, [rawSignalOptions, curAttribute, setSignalData, preSelectedOptions]);

    useEffect(() => {
      dispatch(experience.actions.resetCounts());

      return () => {
        dispatch(signalLibrary.actions.resetOptions(null));
      };
    }, []);

    const handleUniqueExpNameCheck = async (imageName: string) => {
      const response = await dispatch(
        experience.thunk.getList({
          businessUnit: businessUnitId!,
          lastUsed: 0,
          sortBy: 'created',
        }),
      );

      const items = [...(response as any).payload.items]
        .filter((exp) => exp.name.includes(imageName))
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      const lastItem = items[items.length - 1];
      const splittedNameParts: string[] = lastItem?.name?.split(' ');
      const regExp = /\(([^)]+)\)/;

      if (!splittedNameParts) {
        return '';
      }

      const matches = regExp.exec(splittedNameParts[splittedNameParts.length - 1] ?? '');
      const nameNumber = Number(matches ? matches[1] : 0);

      let newName = '';

      if (nameNumber) {
        newName = `${splittedNameParts.filter((part, index) => index < splittedNameParts.length - 1).join(' ')} (${
          nameNumber + 1
        })`;
      } else {
        newName = `${lastItem.name} (1)`;
      }

      return newName;
    };

    const getNewExpName = async (): Promise<string> => {
      const splittedNameParts = (experienceData?.name ?? '').split(' ');
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(splittedNameParts[splittedNameParts.length - 1] ?? '');
      const nameNumber = Number(matches ? matches[1] : 0);

      let newName = '';

      if (nameNumber) {
        const initialName = splittedNameParts.filter((part, index) => index < splittedNameParts.length - 1).join(' ');

        newName = await handleUniqueExpNameCheck(initialName);
      } else {
        newName = `${experienceData?.name} (1)`;

        newName = await handleUniqueExpNameCheck(experienceData?.name ?? '');
      }

      return newName;
    };

    useEffect(() => {
      (async () => {
        const curNewName = await getNewExpName();

        setNewExpName(curNewName);
      })();
    }, [experienceData?.name, setNewExpName]);

    useEffect(() => {
      const filteredModules = experienceData?.modules?.filter((module) => module.conditions);

      if (filteredModules && filteredModules[0]?.conditions) {
        const signalName: string = (filteredModules[0].conditions[0]?.signal as any)?.label;
        const curSignal = attributes.find((attribute) => attribute.label === signalName);

        handleAttributeChange(curSignal as unknown as Signal);
        setInitialAttribute(curSignal);
      }
    }, [experienceData, attributes]);

    return (
      <Box className={styles.signalBuilder} data-ui-type={uiType}>
        {uiType === 'signal' && (
          <Typography.LargeTitle className={styles.pageTitle}>
            {chosenAttributeId ? textTemplate(content.editValue, { value: content.attribute }) : content.scoreAttribute}
          </Typography.LargeTitle>
        )}
        <Box className={styles.body}>
          <Box className={styles.header}>
            {treatmentPanelType === 'logicBuilder' && (
              <Controller
                name="name"
                control={control}
                rules={{ required: content.attributeName }}
                render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
                  <TextField
                    {...field}
                    className={uiType === 'signal' ? styles.signalName : styles.treatmentName}
                    inputRef={ref}
                    required
                    templated
                    label={uiType === 'signal' ? format.capitalize(content.attributeName) : content.treatmentName}
                    placeholder={uiType === 'signal' ? format.capitalize(content.attributeName) : content.treatmentName}
                    hint={error?.message}
                    error={invalid}
                  />
                )}
              />
            )}
            {treatmentPanelType === 'logicBuilder' && (
              <Box className={uiType === 'signal' ? styles.attributeInfoSignal : styles.attributeInfoTreatment}>
                <Box className={styles.attributeInfoBox}>
                  <Typography.Label className={styles.attributeInfoTitle}>
                    {content.targetCount?.toUpperCase()}
                  </Typography.Label>
                  <Typography.Body className={styles.attributeInfoValue}>
                    {format.number(signalCounts.targetCount) ?? 0}
                  </Typography.Body>
                </Box>
                <Box style={uiType === 'treatment' ? { marginRight: 0 } : {}} className={styles.attributeInfoBox}>
                  <Typography.Label className={styles.attributeInfoTitle}>
                    {content.audienceShare?.toLocaleUpperCase()}
                  </Typography.Label>
                  <Typography.Body className={styles.attributeInfoValue}>
                    {format.percent(signalCounts.audience || 0)}
                  </Typography.Body>
                </Box>
                {uiType === 'signal' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRefresh()}
                    disabled={isCountsRefreshed || !conditions[0].operator}
                  >
                    {content.refresh}
                  </Button>
                )}
              </Box>
            )}
          </Box>
          {treatmentPanelType === 'externalScoring' && (
            <>
              <DroppableAttribute
                className={styles.droppableAttribute}
                initialAttribute={initialAttribute}
                mindsetIndex={1000}
                onChange={handleAttributeChange}
              />
              <SearchSelect
                preSelected={preSelectedOptions}
                innerSearch={false}
                signalData={signalData}
                onChange={handleSelectedOptionsChange}
                onSearchChange={handleSearchOptionsChange}
                onTouchEnd={handleTouchOptions}
              />
            </>
          )}

          {treatmentPanelType === 'logicBuilder' && (
            <>
              <Conditions
                uiType={uiType}
                mindsetIndex={DEFAULT_MINDSET_INDEX}
                conditionsError={conditionsError}
                onChange={handleConditionsRulesChange}
                onConditionsChange={handleConditionsChange}
              />
            </>
          )}
        </Box>
        <Box className={styles.footer}>
          {treatmentPanelType === 'externalScoring' && (
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              {content.cancel}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isDisabled}
            onClick={uiType === 'signal' ? saveSignal : handleTreatmentSave}
          >
            {getSaveButtonLabel(uiType, chosenAttributeId)}
          </Button>
        </Box>
        {showRenameModuleModal && (
          <RenameModuleModal
            title={textTemplate(content.renameValue, { value: content.moduleGroup })}
            alertMessage={content.thisModuleGroupUsedByAnotherDeployment}
            moduleData={{
              moduleTemplateName: newExpName ?? '',
              deployments: experienceData?.deployments || [],
              isRenameRequired: experienceData?.type === 'revision',
            }}
            onClose={handleRenameModuleModalClose}
            onSubmit={handleConfirmRenameModule}
          />
        )}
      </Box>
    );
  },
);

SignalLibraryBuilder.displayName = 'SignalLibraryBuilder';
