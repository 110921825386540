import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector as use } from '@store';
import { MetaSchema } from '@modules';
import * as thunk from './ModuleDetails.thunk';
import { initialState } from './ModuleDetails.state';
import { reducers, extraReducers } from './ModuleDetails.reducer';
import { ModuleInfoState } from './ModuleDetails.schema';

export * from './ModuleDetails.schema';

const name = 'moduleDetails';

export const moduleDetails = {
  thunk,
  slice: createSlice({
    name,
    initialState,
    reducers,
    extraReducers,
  }),
  useMeta: (): MetaSchema => use(({ [name]: { data, ...meta } }) => meta),
  useData: (): ModuleInfoState => use(({ [name]: { data } }) => data),
};
