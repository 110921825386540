import { memo, useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, ButtonBase } from '@material-ui/core';

import { Typography, Icon } from '@components';
import { content } from '@content';
import { format, textTemplate, useHistory } from '@utils';
import { variables } from '@styles';

import { useStyles } from './PreviewSelectedModule.styles';
import { PreviewSelectedModuleProps } from './PreviewSelectedModule.props';
import { TOP_BAR_CTX_VALUE, TopBarContext } from '@layouts';

/**
 PreviewSelectedModule component.
 @returns {JSX.Element}
 */

export const PreviewSelectedModule = memo(
  ({
    id,
    name,
    coverImage,
    open,
    createdOn,
    editedOn,
    onClose,
    contentCenterType,
    onDuplicateClick,
  }: PreviewSelectedModuleProps): JSX.Element => {
    const styles = useStyles();
    const [, setTopBarCtx] = useContext(TopBarContext);
    const history = useHistory();

    useEffect(() => {
      setTopBarCtx((prev) => ({
        ...prev,
        sectionName: textTemplate(content.entityManager, { entity: content.content }),
      }));

      return () => {
        setTopBarCtx(() => TOP_BAR_CTX_VALUE);
      };
    }, [setTopBarCtx]);

    return (
      <Box className={`${styles.previewModule} ${open ? 'open' : ''}`}>
        <Box className={styles.header}>
          <Typography.Title>{name}</Typography.Title>
          <ButtonBase onClick={onClose}>
            <Icon.CloseOutline stroke={variables.color.primary.mediumGray} />
          </ButtonBase>
        </Box>
        <Box className={styles.coverImage} style={{ backgroundImage: `url(${coverImage})` }} />
        <Box className={styles.footer}>
          <Box className={styles.footerLeft}>
            {createdOn && (
              <Typography.Body>{`${content.createdOn} ${format.date(createdOn, 'MMMM d, yyyy')}`}</Typography.Body>
            )}
            {editedOn && (
              <Typography.Body>{`${content.editedOn} ${format.date(editedOn, 'MMMM d, yyyy')}`}</Typography.Body>
            )}
          </Box>
          <Box className={styles.footerRight}>
            {contentCenterType === 'yourLibrary' && (
              <Button
                className={styles.duplicate}
                variant="outlined"
                color="primary"
                onClick={onDuplicateClick}
                startIcon={<Icon.DuplicateOutline />}
              >
                {content.duplicate}
              </Button>
            )}
            {/* <Button
              variant="contained"
              color="primary"
              startIcon={<Icon.Edit fill={variables.color.primary.white} />}
              component={RouterLink}
              to={history.getUrl('contentCenter', { id })}
            >
              {content[contentCenterType === 'templates' ? 'open' : 'edit']}
            </Button> */}
          </Box>
        </Box>
      </Box>
    );
  },
);

PreviewSelectedModule.displayName = 'PreviewSelectedModule';
