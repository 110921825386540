import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * ExperienceEditor page styles
 * @returns {Function}
 */
export const useStyles = makeStyles(() =>
  createStyles({
    experienceEditor: {
      display: 'flex',
      alignItems: 'stretch',
      height: '100%',
    },
  }),
);
