import { useMemo } from 'react';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { Icon, Panel, Tooltip, Typography } from '@components';
import { format } from '@utils';
import { ModuleInfoProps } from './ModuleInfo.props';
import { useStyles } from './ModuleInfo.styles';

export const ModuleInfo = ({
  className,
  businessUnit,
  programName,
  deployDate,
  tag,
  experienceName,
}: ModuleInfoProps): JSX.Element => {
  const styles = useStyles();
  const items = useMemo(
    () => [
      {
        ico: Icon.Cohort,
        key: content.businessUnit,
        val: businessUnit || content.dash,
        has: !!businessUnit,
      },
      {
        ico: Icon.Product,
        key: content.programName,
        val: programName || content.dash,
        has: !!programName,
      },
      {
        ico: Icon.Calendar,
        key: content.deployDate,
        val: deployDate ? format.date(deployDate) : content.dash,
        has: !!deployDate,
      },
      {
        ico: Icon.Tag,
        key: content.moduleTag,
        val: tag || content.dash,
        has: !!tag,
      },
      {
        ico: Icon.People,
        key: content.moduleGroup,
        val: experienceName || content.none,
        has: !!experienceName,
      },
    ],
    [businessUnit, programName, deployDate, tag, experienceName],
  );

  return (
    <Panel className={`${styles.moduleInfo} ${className}`}>
      {items.map((item) => (
        <Tooltip pos="fix" align="center" gap="xs" text={item.has ? item.val : ''} key={item.key}>
          <Box className={styles.item}>
            <item.ico />
            <Box className={styles.text}>
              <Typography.Tag className={styles.label}>{item.key}</Typography.Tag>
              <Typography.Body className={styles.label}>{item.val}</Typography.Body>
            </Box>
          </Box>
        </Tooltip>
      ))}
    </Panel>
  );
};
