import { Box } from '@material-ui/core';

import { Dropdown, Typography } from '@components';
import { content } from '@content';

import { ModuleHeaderProps } from './ModuleHeader.props';
import { useStyles } from './ModuleHeader.styles';

export const ModuleHeader = ({
  className,
  title,
  actions,
  disabled = false,
  readonly = false,
}: ModuleHeaderProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={`${styles.moduleHeader} ${className}`}>
      <Box className={styles.content} data-wide={readonly}>
        <Typography.Caption>{`${content.product} | ${content.cohort}`}</Typography.Caption>
        <Typography.Display noWrap className={styles.name} title={title.join(' | ')}>
          {title.join(' | ')}
        </Typography.Display>
      </Box>
      {!readonly && <Dropdown title={content.actions} options={actions} disabled={disabled} />}
    </Box>
  );
};
