import { experience } from '@modules';
import { format } from '@utils';

import { Info } from './MindsetDetails.types';

export const INFOS: Info[] = [
  { icn: 'PeopleOutline', key: 'targetAudience', val: (mindset) => format.number(mindset.audienceSize || 0) },
  {
    icn: 'PieChartOutline',
    key: 'audienceShare',
    val: (mindset, mindsets) => format.percent(experience.utils.calcAudienceShare(mindset, mindsets)),
  },
];
