import { FC, ReactElement, SVGProps } from 'react';

export const Directory: FC<SVGProps<SVGElement>> = ({ className }): ReactElement => (
  <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M15.174 29.6709C14.9325 29.6709 14.692 29.6363 14.4588 29.5696L1.96251 25.9667C0.809283 25.6356 0.00238024 24.5376 0 23.295V3.39043C0 1.8576 1.20083 0.611328 2.67777 0.611328C2.68372 0.611328 20.5296 0.611328 20.5296 0.611328C22.0065 0.611328 23.2074 1.8576 23.2074 3.39043V22.8442C23.2074 24.377 22.0065 25.6233 20.5296 25.6233H17.8518V26.8906C17.8518 27.6329 17.5733 28.3307 17.0675 28.8557C16.5629 29.3806 15.8905 29.6697 15.174 29.6709ZM2.66944 2.46407C2.18149 2.46901 1.78518 2.88278 1.78518 3.39043V23.2925C1.78637 23.7051 2.05534 24.0707 2.43975 24.1806L14.9372 27.7836C15.0122 27.8058 15.0907 27.8169 15.1705 27.8169C15.4085 27.8169 15.6394 27.7181 15.8048 27.5464C15.9738 27.371 16.0666 27.1388 16.0666 26.8906V7.57762C16.0666 7.16261 15.7977 6.79577 15.4121 6.68584L2.88009 2.4863C2.83844 2.47518 2.7587 2.4653 2.68253 2.46407C2.67777 2.46407 2.67301 2.46407 2.66944 2.46407ZM20.5296 23.7705C21.0223 23.7705 21.4222 23.3555 21.4222 22.8442V3.39043C21.4222 2.87908 21.0223 2.46407 20.5296 2.46407H8.62243L15.9238 4.91091C17.0509 5.23329 17.8506 6.33258 17.8518 7.57639V23.7693H20.5296V23.7705Z"
      fill="#8C8F96"
    />
  </svg>
);
