import { ChangeEvent, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button } from '@material-ui/core';

import { content } from '@content';
import { func, textTemplate } from '@utils';
import { ProgramUpdatePayload } from '@modules';
import { Alert, Icon, Select, SelectOptions, TextField, Typography } from '@components';

import { useStyles } from './ProgramDetails.styles';
import { ProgramDetailsProps } from './ProgramDetails.props';

export const ProgramDetails = ({
  enterprises,
  enterprise,
  businessUnitId,
  program,
  onSubmit,
  onCancel,
  onArchivedToggle,
}: ProgramDetailsProps): JSX.Element => {
  const styles = useStyles();

  const readonly = useMemo(() => !!program?.hasDeployments || !!program?.isArchived, [program]);

  const { handleSubmit, control, watch, setValue, getValues, reset } = useForm<ProgramUpdatePayload>({
    defaultValues: {
      ...(program ? { id: program.id } : null),
      name: program?.name || '',
      businessUnit: program?.businessUnit?.id || businessUnitId,
      enterpriseGroup: program?.businessUnit?.enterprise?.id || enterprise?.id,
    },
  });

  const enterpriseGroup = watch('enterpriseGroup');

  const businessUnit = watch('businessUnit');

  const enterpriseOptions = useMemo<SelectOptions<number>>(
    () =>
      enterprises.map((ntps) => ({
        id: ntps.id,
        label: ntps.name,
      })),
    [enterprises],
  );

  const currEnterprise = useMemo(
    () => enterprises.find((ntps) => ntps.id === enterpriseGroup),
    [enterprises, enterpriseGroup],
  );

  const currClient = useMemo(
    () => currEnterprise?.businessUnits.find((cli) => cli.id === businessUnit),
    [currEnterprise, businessUnit],
  );

  const businessUnitOptions = useMemo<SelectOptions<number>>(
    () =>
      currEnterprise?.businessUnits.map((ntps) => ({
        id: ntps.id,
        label: ntps.name,
      })) || [],
    [currEnterprise],
  );

  const handleEnterpriseGroupChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const nextNtpsGroup = Number(event.target.value);
      const [ntpsFirstClient] = enterprises.find((ntps) => ntps.id === nextNtpsGroup)?.businessUnits || [{ id: 0 }];

      setValue('enterpriseGroup', nextNtpsGroup);
      setValue('businessUnit', ntpsFirstClient.id);
    },
    [setValue, enterprises],
  );

  const handleCancel = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const handleArchivedToggle = useCallback(() => {
    if (program) {
      const { id, isArchived } = program;

      onArchivedToggle(id, getValues('businessUnit'), !isArchived);
    }
  }, [onArchivedToggle, getValues, program]);

  return (
    <form className={styles.programDetails} onSubmit={handleSubmit(onSubmit)}>
      <Box className={styles.header}>
        <Box>
          <Typography.SmallCaption>
            {content.programs}
            {content.slash}
          </Typography.SmallCaption>
          <Typography.LargeTitle>
            {textTemplate(content[program ? 'editValue' : 'addValue'], { value: content.program })}
          </Typography.LargeTitle>
        </Box>
      </Box>
      <Box className={styles.container} data-alerted={!!program?.hasDeployments}>
        {program?.hasDeployments && (
          <Alert
            align="left"
            message={textTemplate(content.itsNotPossibleToProcessThisEntity, {
              process: content.edit.toLowerCase(),
              entity: content.program.toLowerCase(),
            })}
            description={textTemplate(content.afterEntityAddedToContainerItsReadonly, {
              entity: content.deployment.toLowerCase(),
              container: content.program.toLowerCase(),
            })}
            type="warning"
          />
        )}
        <Controller
          name="enterpriseGroup"
          control={control}
          rules={{ required: content.enterpriseGroup }}
          render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
            <Select
              {...field}
              direction="horizontal"
              required
              templated
              disabled={!!program}
              inputRef={ref}
              label={content.enterpriseGroup}
              placeholder={textTemplate(content.selectValue, { value: content.enterpriseGroup })}
              error={invalid}
              helperText={error?.message}
              options={enterpriseOptions}
              onChange={handleEnterpriseGroupChange}
            />
          )}
        />
        <Controller
          name="businessUnit"
          control={control}
          rules={{ required: content.businessUnit }}
          render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
            <Select
              {...field}
              direction="horizontal"
              required
              templated
              disabled={!!program}
              inputRef={ref}
              label={content.businessUnit}
              placeholder={textTemplate(content.selectValue, { value: content.businessUnit })}
              error={invalid}
              helperText={error?.message}
              options={businessUnitOptions}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: content.programName }}
          render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
            <TextField
              {...field}
              direction="horizontal"
              inputRef={ref}
              required
              templated
              disabled={readonly}
              label={textTemplate(content.entityName, { entity: content.program })}
              subLabel={content.afterDeploymentIsAdded}
              placeholder={content.programName}
              hint={error?.message}
              error={invalid}
            />
          )}
        />
      </Box>
      <Box className={styles.footer}>
        <Box className={styles.actions}>
          {program && (
            <Button
              startIcon={<Icon.Archive />}
              variant="text"
              disabled={!!currClient?.isArchived}
              onClick={currClient?.isArchived ? func.nop : handleArchivedToggle}
            >
              <Typography.Headline>
                {textTemplate(program.isArchived ? content.unarchiveEntity : content.archiveEntity, {
                  entity: content.program,
                })}
              </Typography.Headline>
            </Button>
          )}
          <Button onClick={handleCancel} variant="outlined" color="primary">
            {content.cancel}
          </Button>
          <Button disabled={readonly} variant="contained" color="primary" type="submit">
            {program ? content.save : textTemplate(content.addValue, { value: content.program })}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
