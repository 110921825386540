import { Box, Button, Checkbox, InputBase } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Accordion, TextField, Typography } from '@components';
import { content } from '@content';
import { Libraries } from '@routes';
import { clipboard, textTemplate, validate } from '@utils';

import { roundByRank } from '@utils/format/format';
import { EditMode } from '../EditMode';
import { ImageContainerProps, ImageContainerState } from './ImageContainer.props';
import { useStyles } from './ImageContainer.styles';

/**
 ImageContainer component.
 @returns {JSX.Element}
 */

export const ImageContainer = ({
  chosenImage,
  uiType = 'primary',
  initialValues,
  isModuleChanged,
  isImageEditor = false,
  isDVEditor = false,
  dirty = false,
  panelTitleValue,
  setPanelTitleValue,
  setIsModuleChanged,
  onChange,
  setChosenLibrary,
  onDimensionsChange,
  onLinkChange,
  onAltTextChange,
  onCloseImageEditor,
  onSave,
}: ImageContainerProps) => {
  const styles = useStyles();
  const [state, setState] = useState<ImageContainerState>(null);
  const [expanded, setExpanded] = useState<boolean>(true);
  const { width, height, name } = chosenImage;
  const [maintainProportions, setMaintainProportions] = useState(true);
  const [activeDimensionField, setActiveDimensionField] = useState<'width' | 'height' | undefined>('width');
  const imageRatio = useMemo(() => roundByRank(Number(width) / Number(height), 10000), [width, height]);
  const [isEditTitleModeOn, setIsEditTitleModeOn] = useState(false);

  useEffect(() => {
    if (initialValues && !isModuleChanged) setState((prevState) => ({ ...prevState, ...initialValues }));
  }, [initialValues, isModuleChanged]);

  useEffect(() => onChange(state), [state, onChange]);

  const handleSelectImage = useCallback(() => {
    setChosenLibrary(Libraries.IMAGES);
    clipboard.copy(state?.imageSrc ?? '');
  }, [state, setChosenLibrary]);

  const handleImageChange = useCallback(
    (value: string) => {
      setState((prevState) => ({ ...prevState, imageSrc: value }));
      setIsModuleChanged(true);
    },
    [setState, setIsModuleChanged],
  );

  const handleCheckChange = (e: any) => {
    setMaintainProportions(e.target.checked);
  };

  const handleDimensionsChange = useCallback(
    (param: 'width' | 'height', value: string) => {
      if (validate.isNumeric(value)) {
        setState((prevState) => {
          if (param === 'height' && onDimensionsChange) {
            const curWidth = maintainProportions
              ? roundByRank(Number(value) * imageRatio, 1)
              : Number(prevState?.width);

            onDimensionsChange([curWidth, Number(value)]);
          } else if (param === 'width' && onDimensionsChange) {
            const curHeight = maintainProportions
              ? roundByRank(Number(value) / imageRatio, 1)
              : Number(prevState?.height);

            onDimensionsChange([Number(value), curHeight]);
          }

          return { ...prevState, [param]: value };
        });

        setIsModuleChanged(true);
      }

      switch (param) {
        case 'width':
          setActiveDimensionField('width');
          break;

        case 'height':
          setActiveDimensionField('height');
          break;
      }
    },
    [maintainProportions, imageRatio, setState, setIsModuleChanged],
  );

  const handleLinkChange = (value: string) => {
    if (onLinkChange) {
      onLinkChange(value);
    }

    setState((prevState) => ({ ...prevState, link: value }));
    setIsModuleChanged(true);
  };

  const handleAltTextChange = (value: string) => {
    if (onAltTextChange) {
      onAltTextChange(value);
    }

    setState((prevState) => ({ ...prevState, altText: value }));
    setIsModuleChanged(true);
  };

  const toggleAccordion = useCallback(() => setExpanded((prevState) => !prevState), [setExpanded]);

  const getContent = () => {
    return (
      <>
        {!isDVEditor && (
          <Box className={styles.imageSection}>
            {isImageEditor && (
              <Button className={styles.closeBtn} variant="outlined" color="primary" onClick={onCloseImageEditor}>
                {content.close}
              </Button>
            )}
            <Typography.Caption className={styles.caption}>{content.imagePath}</Typography.Caption>
            <Box className={styles.imageSrcWrapper}>
              <TextField
                className={styles.imageSrc}
                size="small"
                direction="vertical"
                placeholder={content.none}
                value={state?.imageSrc}
                onChange={handleImageChange}
              />
              <Button className={styles.selectBtn} variant="outlined" color="primary" onClick={handleSelectImage}>
                {content.copy}
              </Button>
            </Box>
          </Box>
        )}
        <Box className={styles.widthSection}>
          <Box className={styles.width}>
            <Typography.Caption className={styles.caption}>{content.width}</Typography.Caption>
            <TextField
              size="small"
              direction="vertical"
              placeholder={content.notSet}
              value={state?.width && state.width}
              maxValue={chosenImage.chosenModuleElemId ? '300' : 'auto'}
              type={'number'}
              onChange={(value) => handleDimensionsChange('width', value)}
            />
          </Box>
          <Box>
            <Typography.Caption className={styles.caption}>{content.height}</Typography.Caption>
            <TextField
              size="small"
              direction="vertical"
              placeholder={content.notSet}
              value={state?.height && state.height}
              maxValue={chosenImage.chosenModuleElemId ? '300' : 'auto'}
              type={'number'}
              onChange={(value) => handleDimensionsChange('height', value)}
            />
          </Box>
        </Box>
        {!isDVEditor && (
          <Box>
            <Typography.Label className={styles.label}>
              {content.maintainProportions}
              <Checkbox onChange={handleCheckChange} checked={maintainProportions} indeterminate={false} />
            </Typography.Label>
          </Box>
        )}
        {isImageEditor && !isDVEditor && (
          <Button
            className={styles.save}
            variant="contained"
            color="primary"
            size="medium"
            disabled={!dirty}
            onClick={dirty ? onSave : undefined}
          >
            {content.saveChanges}
          </Button>
        )}
      </>
    );
  };

  const handleTriggerPanelTitle = () => {
    setIsEditTitleModeOn((prev) => !prev);
  };

  const handlePanelTitleValueChange = (e: any) => {
    if (setPanelTitleValue) {
      setPanelTitleValue(e.target.value);
    }

    setIsModuleChanged(true);
  };

  const handleCancelRenaming = () => {
    handleTriggerPanelTitle();
    setIsModuleChanged(false);

    if (setPanelTitleValue) {
      setPanelTitleValue(name);
    }
  };

  useEffect(() => {
    setState({
      chosenModuleElemId: chosenImage.chosenModuleElemId || '',
      imageSrc: chosenImage.imageSrc || '',
      width: chosenImage.width?.toString(),
      height: chosenImage.height?.toString(),
      link: chosenImage.link ?? '',
      altText: chosenImage.altText ?? '',
    });

    setActiveDimensionField('width');
    setMaintainProportions(true);
  }, [chosenImage, width, height, setActiveDimensionField, setState, setMaintainProportions]);

  useEffect(() => {
    if (maintainProportions && activeDimensionField === 'width') {
      setState((prev) => ({
        ...prev,
        height: roundByRank(Number(state?.width) / imageRatio, 1).toString(),
      }));
    }
  }, [state?.width, activeDimensionField, maintainProportions]);

  useEffect(() => {
    if (maintainProportions && activeDimensionField === 'height') {
      setState((prev) => ({
        ...prev,
        width: roundByRank(Number(state?.height) * imageRatio, 1).toString(),
      }));
    }
  }, [state?.height, activeDimensionField, maintainProportions]);

  return (
    <>
      {!isDVEditor ? (
        <Box
          className={styles.imageContainer}
          data-ui-type={uiType}
          style={{ paddingTop: isImageEditor ? '8px' : '0' }}
        >
          {isImageEditor ? (
            isEditTitleModeOn ? (
              <Box className={styles.renameBar}>
                <InputBase
                  onDoubleClick={handleTriggerPanelTitle}
                  value={panelTitleValue}
                  onChange={handlePanelTitleValueChange}
                />
                <Box className={styles.renameBarButtons}>
                  <Button
                    className={styles.cancelBtn}
                    variant="outlined"
                    color="primary"
                    onClick={handleCancelRenaming}
                  >
                    {content.cancel}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box onDoubleClick={handleTriggerPanelTitle}>
                <Typography.Title className={styles.title}>{name}</Typography.Title>
              </Box>
            )
          ) : (
            <Box className={styles.titleWrapper}>
              <Typography.Title className={styles.title} style={{ padding: '0' }}>
                {content.image}
              </Typography.Title>
              <EditMode />
            </Box>
          )}
          {isImageEditor ? (
            <Box className={styles.content}>{getContent()}</Box>
          ) : (
            <>
              <Accordion expanded={expanded} onChange={toggleAccordion} title={content.settings} uiType="quaternary">
                {getContent()}
              </Accordion>
              <Accordion expanded={true} title={content.linkSettings} uiType="quaternary">
                <Box className={styles.widthSection}>
                  <Box className={styles.width}>
                    <Typography.Caption className={styles.caption}>{content.linkUrl}</Typography.Caption>
                    <TextField
                      size="small"
                      direction="vertical"
                      placeholder={textTemplate(content.enterValue, { value: content.linkAddress.toLowerCase() })}
                      value={state?.link && state.link}
                      onChange={handleLinkChange}
                    />
                  </Box>
                  <Box>
                    <Typography.Caption className={styles.caption}>{content.altText}</Typography.Caption>
                    <TextField
                      size="small"
                      direction="vertical"
                      placeholder={textTemplate(content.enterValue, { value: content.altText.toLowerCase() })}
                      value={state?.altText && state.altText}
                      onChange={handleAltTextChange}
                    />
                  </Box>
                </Box>
              </Accordion>
            </>
          )}
        </Box>
      ) : (
        <Box className={styles.DVcontent}>{getContent()}</Box>
      )}
    </>
  );
};
