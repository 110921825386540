import {
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';

import { Icon } from '@components';
import { variables } from '@styles';

import { useStyles } from './ListItem.styles';
import { ListItemProps } from './ListItem.types';

/**
 * ListItem component
 * @returns {JSX.Element}
 */
export const ListItem = ({
  label,
  description,
  secondaryLabel,
  secondaryText,
  selected = false,
  draggable = false,
  stylesListItem = '',
  onClick,
  onDragStart,
  id,
}: ListItemProps): JSX.Element => {
  const styles = useStyles();

  return (
    <MuiListItem
      button
      classes={{ root: `${styles.listItem} ${stylesListItem}`, selected: styles.selected, container: styles.container }}
      selected={selected}
      ContainerComponent="div"
      ContainerProps={{
        className: draggable ? 'MuiListItem-draggable' : '',
      }}
      data-id={id}
      onClick={onClick}
      draggable={draggable}
      onDragStart={onDragStart}
    >
      <ListItemIcon classes={{ root: styles.iconListItem }}>
        {draggable ? (
          <Icon.DraggableV2 fill={variables.color.primary.mediumPurple} />
        ) : (
          <Icon.Eye stroke={variables.color.primary.darkestGray} />
        )}
      </ListItemIcon>
      <ListItemText
        classes={{
          root: styles.listItemText,
          primary: styles.primaryText,
          secondary: styles.secondaryText,
          multiline: styles.multilineText,
        }}
        primary={label}
        secondary={description}
      />
      <ListItemSecondaryAction classes={{ root: styles.secondaryActionRoot }}>
        {selected ? (
          <Icon.ChevronRight className={styles.iconChevronRight} stroke="" />
        ) : (
          <>
            {secondaryLabel && (
              <Typography classes={{ root: styles.secondaryLabelRoot }} noWrap display="inline">
                {secondaryLabel}
              </Typography>
            )}
            {secondaryText && (
              <Typography classes={{ root: styles.secondaryTextRoot }} noWrap display="inline">
                {secondaryText}
              </Typography>
            )}
          </>
        )}
      </ListItemSecondaryAction>
    </MuiListItem>
  );
};
