import { useCallback } from 'react';
import { Typography, Menu } from '@components';
import { IconDirections } from '@components/Menu/Menu.constants';
import { Box } from '@material-ui/core';

import { Image } from '@modules';

import { ImageLibraryLayout } from '../ImageLibrary.constants';
import { DividedImages } from '../ImageLibrary.types';

import { ImageLibraryContentProps } from './ImageLibraryContent.props';
import { useStyles } from './ImageLibraryContent.styles';
import { imageLibraryMenuOptions } from './ImageLibraryContent.constants';

/**
 * ImageLibraryContent component
 * @returns {JSX.Element}
 */

export const ImageLibraryContent = ({ images, layout }: ImageLibraryContentProps): JSX.Element => {
  const styles = useStyles();

  const getImageName = (item: Image): string => {
    return `${Math.round(item.width)} x ${Math.round(item.height)} pixels`;
  };

  const handleMenuOpen = useCallback(() => {
    //TODO: write logic for Browser Image Library
  }, []);

  return (
    <>
      {layout === ImageLibraryLayout.GRID && (
        <Box className={styles.imageLibraryContentGrid}>
          <Box className={styles.imageColumn}>
            {(images as DividedImages)?.firstColumn.map((item) => (
              <Box key={item.name} className={styles.imageWrapper}>
                <img width={item.width} height={item.height} className={styles.image} src={item.path} />
                <Menu
                  buttonClassName={styles.gridMenuButton}
                  onClick={handleMenuOpen}
                  iconDirection={IconDirections.VERTICAL}
                  options={imageLibraryMenuOptions}
                />
              </Box>
            ))}
          </Box>
          <Box className={styles.imageColumn}>
            {(images as DividedImages)?.secondColumn.map((item) => (
              <Box key={item.name} className={styles.imageWrapper}>
                <img width={item.width} height={item.height} className={styles.image} src={item.path} />
                <Menu
                  buttonClassName={styles.gridMenuButton}
                  onClick={handleMenuOpen}
                  iconDirection={IconDirections.VERTICAL}
                  options={imageLibraryMenuOptions}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {layout === ImageLibraryLayout.LIST && (
        <Box className={styles.imageLibraryContentList}>
          {(images as Image[])?.map((item) => (
            <Box key={item.id} className={styles.imageLibraryRow}>
              <img width={50} height={40} src={item.path} />
              <Box className={styles.imageInfo}>
                <Typography.Body className={styles.imageName}>{item.name}</Typography.Body>
                <Typography.SmallCaption>{getImageName(item)}</Typography.SmallCaption>
              </Box>
              <Menu
                buttonClassName={styles.listMenuButton}
                onClick={handleMenuOpen}
                iconDirection={IconDirections.VERTICAL}
                options={imageLibraryMenuOptions}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
