import { memo } from 'react';
import { useState, MouseEvent, useMemo } from 'react';
import { Card as MuiCard, CardMedia, CardContent, Box, Typography as MuiTypography } from '@material-ui/core';

import { Typography, Trend } from '@components';
import { content } from '@content';
import { format } from '@utils';

import { TrendCurve } from './TrendCurve';
import { ModulePopularPopover } from './ModulePopularPopover';

import { ModulePopularCardProps } from './ModulePopularCard.props';
import { useStyles } from './ModulePopularCard.styles';

/**
 ModulePopularCard component.
 @returns {JSX.Element}
 */

export const ModulePopularCard = memo(({ className = '', item }: ModulePopularCardProps): JSX.Element => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const chartData = useMemo(
    () => (item.chartData ? item.chartData.map((value) => Number(value.ctirValue)) : []),
    [item.chartData],
  );

  return (
    <>
      <MuiCard
        className={`${styles.card} ${className}`}
        aria-owns={open ? `mouse-over-popover-${item.id}` : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {item.imageFilepath && <CardMedia className={styles.image} image={item.imageFilepath} />}
        <CardContent className={styles.cardContent}>
          <Typography.Headline>{item.name}</Typography.Headline>
          <Box className={styles.row}>
            <Typography.Headline>{content.ctir}</Typography.Headline>
            <MuiTypography className={styles.ctir}>{format.percent(item.ctir)}</MuiTypography>
            {chartData.length ? (
              <>
                <Trend trend={item.ctirTrend} />
                <TrendCurve data={chartData} trend={item.ctirTrend} />
              </>
            ) : null}
          </Box>
        </CardContent>
      </MuiCard>
      <ModulePopularPopover open={open} anchorEl={anchorEl} onClose={handlePopoverClose} module={item} />
    </>
  );
});

ModulePopularCard.displayName = 'ModulePopularCard';
