import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * SideBar component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    sideBar: {
      width: variables.sideBar.width,
      overflow: 'hidden',
      padding: theme.spacing(2.75, 2),
      backgroundColor: variables.color.primary.darkestPurple,
      display: 'flex',
      flexDirection: 'column',
      transition: 'width 0.3s ease',
      height: '100%',

      '&$expanded': {
        width: '25.6rem',

        '& $navItemText': {
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },

        '& $navDividerTextPrimary': {
          '& > span': {
            display: 'inline',
          },

          '&::after': {
            transform: 'translateY(-50%)',
          },
        },
      },
    },

    logo: {
      margin: 'auto -62px 77px',
      transform: 'rotate(-90deg)',
      width: '163px',
    },

    navItemIcon: {
      minWidth: 'auto',
    },

    navItemIconFill: {
      fill: variables.color.primary.mediumPurple,
    },

    navItemIconStroke: {
      stroke: variables.color.primary.mediumPurple,
    },

    navItem: {
      padding: theme.spacing(0.75, 1),
      borderRadius: variables.borderRadius.sm,
      cursor: 'pointer',

      '&$selected, &$selected:hover': {
        background: variables.color.primary.mainPurple,

        '& $navItemTextPrimary': {
          color: variables.color.primary.white,
        },

        '& $navItemIconFill': {
          fill: variables.color.primary.white,
        },

        '& $navItemIconStroke': {
          stroke: variables.color.primary.white,
        },
      },

      '&:hover': {
        background: variables.color.primary.lighterPurple,

        '& $navItemIconFill': {
          fill: variables.color.primary.darkestPurple,
        },

        '& $navItemIconStroke': {
          stroke: variables.color.primary.darkestPurple,
        },

        '& $navItemTextPrimary': {
          color: variables.color.primary.darkestPurple,
        },
      },

      '&:not(:last-child)': {
        marginBottom: theme.spacing(0.25),
      },
    },

    navItemText: {
      display: 'none',
      marginLeft: theme.spacing(2),
      marginTop: 0,
      marginBottom: 0,
    },

    navItemTextPrimary: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.mediumPurple,
    },

    navDivider: {
      padding: theme.spacing(2, 1, 1),
      overflowX: 'hidden',
    },

    navDividerText: {
      marginTop: 0,
      marginBottom: 0,
    },

    navDividerTextPrimary: {
      fontSize: variables.font.size.xxxs,
      fontWeight: variables.font.weight.bold,
      color: 'rgba(144, 163, 200, 0.6)',
      lineHeight: variables.lineHeight.xxxs,
      height: variables.lineHeight.xxxs,
      textTransform: 'uppercase',
      position: 'relative',

      '& > span': {
        display: 'none',
        backgroundColor: variables.color.primary.darkestPurple,
        paddingRight: theme.spacing(1),
        position: 'relative',
        zIndex: 1,
      },

      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        height: '0.1rem',
        background: 'rgba(144, 163, 200, 0.3)',
        width: '99rem',
        top: '50%',

        transform: 'translateY(-50%) translateX(-8px)',
      },
    },

    expanded: {},
    selected: {},
  }),
);
