import { FC, ReactElement } from 'react';
import { Tooltip } from '@material-ui/core';

import { Icon } from '@components';
import { useTooltipStyles, useStyles } from './WarningMark.styles';

export const WarningMark: FC<{
  title: string;
}> = ({ title }): ReactElement => {
  const styles = useStyles();
  const tooltipStyles = useTooltipStyles();

  return (
    <Tooltip disableFocusListener title={title} arrow placement="top-start" classes={tooltipStyles}>
      <span className={styles.exclamationMark}>
        <Icon.ExclamationMark />
      </span>
    </Tooltip>
  );
};
