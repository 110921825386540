import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * PreviewSelectedModule component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    previewModule: {
      padding: variables.gap.md,
      display: 'none',
      width: '45%',
      borderLeft: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,

      '&.open': {
        display: 'block',
      },
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
    },

    coverImage: {
      paddingBottom: '55.1%',
      height: 0,
      position: 'relative',
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      marginBottom: theme.spacing(2),
      backgroundColor: variables.color.primary.backgroundLightestGray,
      backgroundSize: '200% auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',

      ['@media (min-width: 1450px)']: {
        paddingBottom: 0,
        height: '29.2rem',
      },
    },

    footer: {
      display: 'flex',
      justifyContent: 'space-between',

      ['@media (max-width: 1250px)']: {
        flexDirection: 'column',
      },
    },

    footerLeft: {
      paddingRight: theme.spacing(1),
    },

    footerRight: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',

      ['@media (max-width: 1250px)']: {
        paddingTop: theme.spacing(1),
        justifyContent: 'flex-start',
      },
    },

    duplicate: {
      marginRight: variables.gap.sm,
    },
  }),
);
