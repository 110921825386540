import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

const pwdResetReq = {
  display: 'flex',
  alignItems: 'flex-start',
  maxWidth: '87.4rem',
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    pwdResetReq,
    form: {
      width: '42.2rem',
      background: variables.color.primary.white,
      padding: theme.spacing(6),
    },
    arrowIcon: {
      marginRight: theme.spacing(1),
    },
    buttons: {
      flex: 1,
    },
  }),
);
