import { ForwardedRef, forwardRef } from 'react';
import { Typography } from '@material-ui/core';

import { useCaptionStyles } from './Typography.styles';
import { TypographyProps } from './Typography.props';

export const Caption = forwardRef(
  ({ className = '', ...props }: TypographyProps, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const styles = useCaptionStyles();

    return <Typography classes={styles} className={className} {...props} {...{ ref }} />;
  },
);

Caption.displayName = 'Typography.Caption';
