import { Box } from '@material-ui/core';

import { content } from '@content';
import { Typography } from '@components';

import { ModulesListProps } from './ModulesList.props';
import { useStyles } from './ModulesList.styles';
import { useEffect, useState } from 'react';

/**
 ModulesList component.
 @returns {JSX.Element}
 */

export const ModulesList = ({ list }: ModulesListProps) => {
  const styles = useStyles();
  const [formattedList, setFormattedList] = useState<any[]>([]);

  useEffect(() => {
    const curFormattedList = list.map((item) => ({
      ...item,
      modules: item.modules.map((module) => ({
        ...module,
        formattedModuleTemplateName:
          (module.moduleTemplateName ?? '').length > 50
            ? `...${module.moduleTemplateName?.slice(
                module.moduleTemplateName.length - 50,
                module.moduleTemplateName.length,
              )}`
            : module.moduleTemplateName,
      })),
    }));

    setFormattedList(curFormattedList);
  }, [list]);

  return (
    <Box className={styles.modulesList}>
      {formattedList.length &&
        formattedList.map((item, index) => (
          <Box key={index}>
            <Typography.Caption className={styles.title}>{item.experienceName}</Typography.Caption>
            <Box className={styles.section}>
              {item.modules.length > 1
                ? item.modules.map((module: any) => (
                    <Box className={styles.row} key={module.id}>
                      {module.contentStatus === 'none' ? (
                        <>
                          <Typography.Caption>{content.contentNotAdded}</Typography.Caption>
                          <Typography.SuperSmallCaption className={styles.moduleName}>
                            {module.name}
                          </Typography.SuperSmallCaption>
                        </>
                      ) : (
                        <>
                          <Typography.Caption>{module.formattedModuleTemplateName}</Typography.Caption>
                          <Typography.SuperSmallCaption className={styles.moduleName}>
                            {module.name}
                          </Typography.SuperSmallCaption>
                        </>
                      )}
                    </Box>
                  ))
                : null}
            </Box>
          </Box>
        ))}
    </Box>
  );
};
