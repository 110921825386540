import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ModuleModal component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    moduleModalWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      position: 'absolute',
      backgroundColor: variables.color.primary.backgroundLighterGray,
      height: 'calc(150vh + 24.2rem)',
      top: 0,
      left: '2px',
      zIndex: 1,
      padding: '25rem 7.7rem 0 7.8rem',
      overflowY: 'auto',
      overflowX: 'hidden',
    },

    moduleContent: {
      position: 'relative',
      width: '58rem',
      minWidth: '600px',
    },

    iframe: {
      width: '100%',
      minHeight: '100%',
      border: 'unset',
      overflow: 'hidden',

      '&::-webkit-scrollbar': {
        width: variables.scrollbar.width,
        height: variables.scrollbar.height,
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#dfe5eb',
        borderRadius: variables.borderRadius.md,
      },
    },

    closeBtn: {
      padding: '3px 5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      background: variables.color.primary.mainPurple,
      top: '0rem',
      right: '-2.6rem',
      cursor: 'pointer',
      borderRadius: '0 2px 2px 0',

      '&:hover': {
        background: variables.color.primary.mainPurple,
      },
    },

    removeBtn: {
      padding: '3px 5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      background: variables.color.primary.mainPurple,
      top: '3rem',
      right: '-2.6rem',
      cursor: 'pointer',
      borderRadius: '0 2px 2px 0',

      '&:hover': {
        background: variables.color.primary.mainPurple,
      },
    },
  }),
);
