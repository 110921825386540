import { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { Typography } from '@components';
import { func, textTemplate } from '@utils';

import { Dataset, DatasetKey } from './TextArea.types';
import { TextAreaProps } from './TextArea.props';
import { useStyles } from './TextArea.styles';

/**
 * TextArea (pure text area) component
 */
export const TextArea = ({
  textAreaRef = null,
  className = '',
  size = 'medium',
  required = false,
  resize = true,
  disabled = false,
  error = false,
  templated = false,
  label = '',
  placeholder = '',
  hint = '',
  value = '',
  onChange = func.nop,
  onKeyUp = func.nop,
}: TextAreaProps): JSX.Element => {
  const styles = useStyles();

  const dataset = useMemo(
    () =>
      ({
        [DatasetKey.size]: size,
        [DatasetKey.state]: disabled ? 'disabled' : error ? 'error' : 'default',
      } as Dataset),
    [size, disabled, error],
  );

  const bodyRef = useRef<HTMLElement>(null);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <Box className={`${styles.textArea} ${className}`} {...dataset}>
      {label && (
        <Typography.Label className={styles.label}>
          {required && templated ? textTemplate(content.requiredField, { field: label }) : label}
        </Typography.Label>
      )}
      <Box className={styles.body} {...{ ref: bodyRef }}>
        <textarea
          data-has-resize={resize}
          className={styles.textAreaInput}
          ref={textAreaRef}
          placeholder={
            disabled || error
              ? ''
              : templated
              ? textTemplate(content.enterValue, { value: placeholder.toLowerCase() })
              : placeholder
          }
          disabled={disabled}
          value={value}
          onChange={disabled ? func.nop : handleChange}
          onKeyUp={disabled ? func.nop : onKeyUp}
        />
      </Box>
      {(hint || required) && (
        <Typography.SmallCaption className={styles.hint}>
          {hint
            ? templated && !disabled && error
              ? textTemplate(content.pleaseEnter, { field: hint })
              : required
              ? `*${content.required}. ${hint}`
              : hint
            : `*${content.required}`}
        </Typography.SmallCaption>
      )}
    </Box>
  );
};
