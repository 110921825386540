import { Box } from '@material-ui/core';
import { useCallback, useContext, useEffect, useState } from 'react';

import { ImageContainerState, Wysiwyg } from '@components';
import { content } from '@content';
import { Section, TOP_BAR_CTX_VALUE, TopBarContext } from '@layouts';
import { imageLibrary } from '@modules';
import { useAppDispatch } from '@store';
import { textTemplate, useHistory } from '@utils';

import { LibrariesPanelWrapper } from '../../TemplateView/LibrariesPanel/LibrariesPanelWrapper';
import { DEFAULT_CHOSEN_IMAGE, Libraries } from '../../TemplateView/TemplateView.const';
import { ChosenImage } from '../../TemplateView/TemplateView.types';
import { getLibrariesWrapperTitle, getLibraryPanelComponent } from '../../TemplateView/TemplateView.utils';
import { ImageModal } from '../ImageModal/ImageModal';
import { ImageEditorProps } from './ImageEditor.props';
import { useStyles } from './ImageEditor.styles';

/**
 Viewing Image -
 ImageEditor component
 @returns {JSX.Element}
 */

export const ImageEditor = ({
  chosenImage,
  query,
  setChosenImage,
  setIsImageEditorVisible,
}: ImageEditorProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { businessUnit: businessUnitId = 0 } = history.query;
  const [chosenModuleElemId, setChosenModuleElemId] = useState('');
  const [chosenLibrary, setChosenLibrary] = useState<Libraries | null>(Libraries.IMAGES);
  const [imageModule, setImageModule] = useState<ChosenImage>(DEFAULT_CHOSEN_IMAGE);
  const [isModuleChanged, setIsModuleChanged] = useState(false);
  const [topBarCtx, setTopBarCtx] = useContext(TopBarContext);
  const [panelTitleValue, setPanelTitleValue] = useState(chosenImage.name);

  const handleChooseLibrary = useCallback(
    (libraryName: Libraries | null) => {
      setChosenLibrary(libraryName);
    },
    [setChosenLibrary],
  );

  const handleChooseImage = useCallback(
    (image: any) => {
      setChosenImage(image);
      setImageModule(image);
      setIsModuleChanged(false);
    },
    [setChosenImage],
  );

  const handleReturnBack = useCallback(() => {
    setChosenLibrary(null);
  }, [setChosenLibrary]);

  const handleImageStyleChange = useCallback(
    (state: ImageContainerState) => {},
    [chosenImage, chosenLibrary, chosenModuleElemId],
  );

  const handleDimensionsChange = ([width, height]: [number, number]) => {
    setImageModule((prev) => ({
      ...prev,
      width,
      height,
    }));

    setIsModuleChanged(true);
  };

  useEffect(() => {
    setImageModule(chosenImage);
    setPanelTitleValue(chosenImage.name);
  }, [chosenImage]);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      variant: 'section',
      sectionName: textTemplate(content.entityManager, { entity: content.content }),
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
    };
  }, [setTopBarCtx]);

  const handleModuleClose = useCallback(() => {
    setIsImageEditorVisible(false);

    dispatch(
      imageLibrary.thunk.search({
        ...query,
        businessUnit: query.businessUnitId,
        keyword: query.keyword!,
      }),
    );
  }, [setIsImageEditorVisible]);

  const handleUniqueImageNameCheck = async (imageName: string) => {
    const response = await dispatch(
      imageLibrary.thunk.checkIfExists({
        keyword: imageName,
        businessUnit: businessUnitId!,
        page: 1,
      }),
    );
    const items = (response.payload as unknown as { items: Array<any> })?.items.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    const lastItem = items[items.length - 1];
    const splittedNameParts: string[] = lastItem?.name?.split(' ');

    let nameNumber = 0;

    if (splittedNameParts) {
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(splittedNameParts[splittedNameParts.length - 1] ?? '');

      nameNumber = Number(matches ? matches[1] : 0);
    }

    let newName = '';

    if (nameNumber) {
      newName = `${splittedNameParts.filter((part, index) => index < splittedNameParts.length - 1).join(' ')} (${
        nameNumber + 1
      })`;
    } else if (lastItem) {
      newName = `${lastItem.name} (1)`;
    } else {
      newName = imageName;
    }

    return newName;
  };

  const getNewImageName = async (): Promise<string> => {
    const curName = panelTitleValue ?? '';
    const splittedNameParts = curName.split(' ');
    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(splittedNameParts[splittedNameParts.length - 1] ?? '');
    const nameNumber = Number(matches ? matches[1] : 0);

    let newName = '';

    if (nameNumber) {
      const initialName = splittedNameParts.filter((part, index) => index < splittedNameParts.length - 1).join(' ');

      newName = await handleUniqueImageNameCheck(initialName);
    } else {
      newName = `${curName} (1)`;

      newName = await handleUniqueImageNameCheck(curName);
    }

    return newName;
  };

  const handleModuleSave = useCallback(async () => {
    const canvas = document.createElement('canvas');
    canvas.id = 'app-canvas';

    const bodyElem = document.getElementsByTagName('body')[0];
    bodyElem.appendChild(canvas);

    const appCanvas: any = document.getElementById('app-canvas');
    appCanvas.style.background = '#fff';
    const ctx = canvas!.getContext('2d');
    const img = document.getElementById('editing-image') as any;

    canvas.width = imageModule.width!;
    canvas.height = imageModule.height!;

    const imgWidth = chosenImage?.width ?? 0;
    const imgHeight = chosenImage?.height ?? 0;
    const sizer = Math.min(canvas.width / imgWidth, canvas.height / imgHeight);

    ctx?.drawImage(img, 0, 0, imgWidth, imgHeight, 0, 0, imgWidth * sizer, imgHeight * sizer);
    const originalExtension = chosenImage.imageSrc?.split('.')[chosenImage.imageSrc?.split('.').length - 1];
    const fileExtension = originalExtension === 'jpg' ? 'image/jpeg' : 'image/png';
    appCanvas.toBlob(async (blob: Blob) => {
      appCanvas.remove();

      const body = new FormData();
      const newName = await getNewImageName();

      const file = new File([blob], newName);
      body.append('file', file);
      const result = await dispatch(imageLibrary.thunk.create({ params: { businessUnit: businessUnitId! }, body }));

      if (result.payload && imageLibrary.thunk.create.fulfilled.match(result)) {
        handleModuleClose();
      }
    }, fileExtension);
  }, [panelTitleValue, imageModule, chosenImage]);

  return (
    <>
      <Box className={styles.templateView}>
        <Section className={styles.leftPanel} range={2} gap={0}>
          <LibrariesPanelWrapper onChooseLibrary={handleChooseLibrary} isImageLibrary={true}>
            {getLibraryPanelComponent(Libraries.IMAGES, true, handleChooseImage, chosenImage)}
          </LibrariesPanelWrapper>
        </Section>
        <Section className={styles.centralPanel} range={6} gap={0}>
          {!!module && (
            <>
              <ImageModal modalTopPosition={170} chosenImage={imageModule} onClose={handleModuleClose} />
            </>
          )}
        </Section>
        <Section className={styles.rightPanel} range={4} gap={0}>
          <Box className={styles.rightPanelContent}>
            <Wysiwyg.ImageContainer
              isModuleChanged={isModuleChanged}
              isImageEditor={true}
              dirty={isModuleChanged}
              chosenImage={chosenImage}
              panelTitleValue={panelTitleValue}
              setIsModuleChanged={setIsModuleChanged}
              setChosenLibrary={setChosenLibrary}
              onChange={handleImageStyleChange}
              onDimensionsChange={handleDimensionsChange}
              onSave={handleModuleSave}
              setPanelTitleValue={setPanelTitleValue}
              onCloseImageEditor={handleModuleClose}
            />
          </Box>
        </Section>
      </Box>
    </>
  );
};
