import { memo } from 'react';
import { ButtonBase } from '@material-ui/core';

import { Typography, Icon, Label } from '@components';
import { content } from '@content';
import { format } from '@utils';

import { SignalProps } from './Signal.props';
import { useStyles } from './Signal.styles';
import { getStyle } from './Signal.utils';

/**
 Signal component
 @returns {JSX.Element}
 */

export const Signal = memo(
  ({ className, provided, item, style, isClicable, isDragging, onClick }: SignalProps): JSX.Element => {
    const styles = useStyles();

    const handleSignalClick = () => {
      if (onClick) {
        onClick(item);
      }
    };

    return (
      <div
        /* eslint-disable react/jsx-props-no-spreading */
        {...(provided ?? {}).draggableProps}
        {...(provided ?? {}).dragHandleProps}
        {...{ ref: (provided ?? {}).innerRef }}
        /* eslint-enable react/jsx-props-no-spreading */
        style={getStyle({ provided: provided ?? ({ draggableProps: { style: {} } } as any), style, isDragging })}
        className={`${className} ${styles.signal}`}
        onClick={handleSignalClick}
      >
        <div className={styles.leftContainer} style={{ cursor: isClicable ? 'pointer' : 'default' }}>
          <ButtonBase>
            <Icon.SignalOutline />
          </ButtonBase>
          <div className={styles.content}>
            <div className={styles.row}>
              <Typography.Caption title={item.label} display="inline" noWrap component="span" className={styles.text}>
                {item.label}
              </Typography.Caption>
              {item.error ? (
                <Label variant="badge" color="negative">
                  {content.error}
                </Label>
              ) : item.isUploading ? (
                <Label variant="badge" color="positive">
                  {content.inProgress}
                </Label>
              ) : (
                <ButtonBase>{!isClicable && <Icon.DraggableV2 />}</ButtonBase>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Signal.displayName = 'Signal';
