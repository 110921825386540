import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    uploadModuleRecommenderFile: {
      padding: '0 1.2rem 1.5rem 2rem',
    },
    title: {
      marginBottom: '6.6rem',
    },
    fieldLabel: {
      marginBottom: '1.1rem',
    },
    fileField: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    fileRow: {
      marginBottom: '3.1rem',
    },
    fileErrorMsg: {
      color: variables.color.secondary.orange,
      fontWeight: variables.font.weight.bold,
    },
    footer: {
      marginTop: '3rem',
    },
    confirmBtn: {
      marginRight: '1rem',
    },
  }),
);
