import { memo } from 'react';
import { Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { content } from '@content';

import { useStyles } from './Condition.styles';
import { RequiredFieldProps } from './Condition.props';

/**
 * RequiredField component
 * @returns {JSX.Element}
 */

export const RequiredField = memo(
  ({
    condition,
    mindsetIndex,
    fieldName = `mindsets.${mindsetIndex}.condition.required`,
  }: RequiredFieldProps): JSX.Element => {
    const styles = useStyles();
    const { setValue, trigger } = useFormContext();
    return (
      <Box>
        <Box className={styles.requiredCheckBox}>
          <FormControlLabel
            label={content.thisRequiredRule}
            classes={{
              label: styles.checkboxLabel,
            }}
            control={
              <Controller
                name={fieldName}
                defaultValue={condition.required}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    classes={{ root: styles.checkbox }}
                    checked={field.value}
                    inputRef={field.ref}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue(`mindsets.${mindsetIndex}.metConditionCount`, 1);
                      trigger(`mindsets.${mindsetIndex}.metConditionCount`);
                    }}
                  />
                )}
              />
            }
          />
        </Box>
      </Box>
    );
  },
);

RequiredField.displayName = 'RequiredField';
