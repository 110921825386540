import { ActionReducerMapBuilder, SerializedError, PayloadAction } from '@reduxjs/toolkit';

import {
  getItem,
  getItemMindsets,
  getList,
  create,
  update,
  remove,
  search,
  getSubscriberCount,
  getSignalsOptions,
  getExperienceOverview,
  getItemsMindsetsBatch,
  createExternalScoreTreatment,
} from './Experience.thunk';
import { initialState } from './Experience.state';
import { Counts, ExperienceItemMindset, ExperienceSchema, MindsetData } from './Experience.schema';
import { MoveMindsetArg, MoveTreatmentArg, SetModuleHtmlArg } from './Experience.types';

export const reducers = {
  removeJourneyFromList: ({ list }: ExperienceSchema, { payload }: PayloadAction<number>): void => {
    list.data.items = list.data.items.filter((i) => i.id !== payload);
  },
  resetJourney: ({ item }: ExperienceSchema): void => {
    item.data = null;
  },
  resetListData: ({ list }: ExperienceSchema): void => {
    list.status = initialState.list.status;
    list.data = initialState.list.data;
  },
  resetCounts: ({ counts }: ExperienceSchema): void => {
    counts.data = initialState.counts.data;
  },
  removeItemMindset: ({ mindsets }: ExperienceSchema, { payload }: PayloadAction<number>): void => {
    mindsets.data = mindsets.data.filter((item) => item.experienceId !== payload);
  },
  clearItemsMindsets: ({ mindsets }: ExperienceSchema): void => {
    mindsets.data = [];
  },
  moveMindset: ({ mindsets }: ExperienceSchema, { payload }: PayloadAction<MoveMindsetArg>): void => {
    const items = [...mindsets.data[0].modules];
    const minIndex = Math.min(payload.from, payload.to);
    const maxIndex = Math.max(payload.from, payload.to);

    items.splice(payload.to, 0, items.splice(payload.from, 1)[0]);

    for (let i = minIndex; i <= maxIndex; i++) {
      items[i].index = i;
    }

    mindsets.data[0].modules = items;
  },
  moveTreatment: ({ mindsets }: ExperienceSchema, { payload }: PayloadAction<MoveTreatmentArg>): void => {
    const { experienceId, from, to } = payload;
    const mindset =
      typeof experienceId === 'number' ? mindsets.data.find((item) => item.experienceId === experienceId) : null;

    if (mindset) {
      const universal = mindset.modules.find((item) => item.isNullSubscriber);
      const items = mindset.modules.filter((item) => !item.isNullSubscriber);
      const minIndex = Math.min(from, to);
      const maxIndex = Math.max(from, to);

      items.splice(to, 0, items.splice(from, 1)[0]);

      for (let i = minIndex; i <= maxIndex; i++) {
        items[i].index = i;
      }

      if (universal) {
        items.unshift(universal);
      }

      mindset.modules = items;
    }
  },
  setModuleHtml: (
    { mindsets }: ExperienceSchema,
    { payload: { id, moduleHtml } }: PayloadAction<SetModuleHtmlArg>,
  ): void => {
    let module: ExperienceItemMindset | undefined;

    mindsets.data.some(({ modules }) => {
      module = modules.find((item) => item.id === id);

      return module;
    });

    if (module) {
      module.moduleHtml = moduleHtml;
    }
  },
  updateModule: ({ mindsets }: ExperienceSchema, { payload }: PayloadAction<ExperienceItemMindset>): void => {
    mindsets.data = mindsets.data.map((mindset) => {
      const { modules } = mindset;
      const moduleIndex = modules.findIndex((item) => item.id === payload.id);

      if (moduleIndex === -1) {
        return mindset;
      }

      const updatedModules = [...modules];

      updatedModules[moduleIndex] = {
        ...modules[moduleIndex],
        ...payload,
      };

      return {
        experienceId: mindset.experienceId,
        modules: updatedModules,
      };
    });
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<ExperienceSchema>): void => {
  /**
   * Creating experience item
   */
  builder.addCase(create.pending, ({ item }, action) => {
    if (item.status !== 'loading') {
      item.requestId = action.meta.requestId;
      item.status = 'loading';
      item.error = undefined;
    }
  });

  builder.addCase(create.fulfilled, ({ item }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'success';
      if (action.payload) {
        item.data = action.payload;
      }
    }
  });

  builder.addCase(create.rejected, ({ item }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'error';
      item.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  /**
   * Updating experience item
   */
  builder.addCase(update.pending, ({ item }, action) => {
    if (item.status !== 'loading') {
      item.requestId = action.meta.requestId;
      item.status = 'loading';
      item.error = undefined;
    }
  });

  builder.addCase(update.fulfilled, ({ item, mindsets }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'success';
      if (action.payload) {
        item.data = action.payload;

        const { modules } = action.payload;

        if (modules) {
          const prev = mindsets.data;
          const next = [...prev];
          const [, experienceId] = action.meta.arg;
          const payload = { experienceId, modules };
          const prevItemIndex = prev.findIndex((module) => module.experienceId === experienceId);

          if (prevItemIndex === -1) {
            next.push(payload);
          } else {
            next[prevItemIndex] = payload;
          }

          mindsets.data = next;
        }
      }
    }
  });

  builder.addCase(update.rejected, ({ item }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'error';
      item.error = (action.payload as unknown as SerializedError) || action.error;
    }
  });

  /**
 * Create external score treatment
 */
  builder.addCase(createExternalScoreTreatment.pending, ({ item }, action) => {
    if (item.status !== 'loading') {
      item.requestId = action.meta.requestId;
      item.status = 'loading';
      item.error = undefined;
    }
  });

  builder.addCase(createExternalScoreTreatment.fulfilled, ({ item, mindsets }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'success';
      if (action.payload) {
        item.data = action.payload;

        const { modules } = action.payload;

        if (modules) {
          const prev = mindsets.data;
          const next = [...prev];
          const [, experienceId] = action.meta.arg;
          const payload = { experienceId, modules };
          const prevItemIndex = prev.findIndex((module) => module.experienceId === experienceId);

          if (prevItemIndex === -1) {
            next.push(payload);
          } else {
            next[prevItemIndex] = payload;
          }

          mindsets.data = next;
        }
      }
    }
  });

  builder.addCase(createExternalScoreTreatment.rejected, ({ item }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'error';
      item.error = (action.payload as unknown as SerializedError) || action.error;
    }
  });

  /**
   * Deleting experience item
   */
  builder.addCase(remove.fulfilled, ({ item }) => {
    item.data = initialState.item.data;
  });

  /**
   * Fetching experience item
   */
  builder.addCase(getItem.pending, ({ item }, action) => {
    if (item.status !== 'loading') {
      item.requestId = action.meta.requestId;
      item.status = 'loading';
    }
  });

  builder.addCase(getItem.fulfilled, ({ item }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'success';
      if (action.payload) {
        item.data = action.payload;

        if (action.payload.isFileBased) {
          const total = action.payload.mindsets.reduce((sum, i) => sum + (i.audienceSize || 0), 0);
          item.data.mindsets = action.payload.mindsets.map((mindset) => ({
            ...mindset,
            targetCount: mindset.audienceSize,
            audience: ((mindset.audienceSize || 0) / total) * 100,
          }));
        }
      }
    }
  });

  builder.addCase(getItem.rejected, ({ item }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'error';
      item.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  /**
   * Fetching Experience Overview
   */
  builder.addCase(getExperienceOverview.pending, ({ overview }, action) => {
    if (overview.status !== 'loading') {
      overview.requestId = action.meta.requestId;
      overview.status = 'loading';
    }
  });

  builder.addCase(getExperienceOverview.fulfilled, ({ overview }, action) => {
    if (overview.status === 'loading' && overview.requestId === action.meta.requestId) {
      overview.requestId = '';
      overview.status = 'success';

      if (action.payload) {
        let none = 0;
        let html = 0;
        const products = action.payload.products;

        products.forEach((product) => {
          const mindsets = product.mindsets;

          Object.keys(mindsets).forEach((key) => {
            if (mindsets[key].hasContent) {
              html += 1;
            } else {
              none += 1;
            }
          });
        });

        if (action.payload.defaultMindset?.hasContent) {
          html += 1;
        } else {
          none += 1;
        }

        overview.data = action.payload;
        overview.data.counts = [none, html];
      }
    }
  });

  builder.addCase(getExperienceOverview.rejected, ({ overview }, action) => {
    if (overview.status === 'loading' && overview.requestId === action.meta.requestId) {
      overview.requestId = '';
      overview.status = 'error';
      overview.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  /**
   * Fetching experience item mindsets
   */
  builder.addCase(getItemMindsets.pending, ({ mindsets }, action) => {
    if (mindsets.status !== 'loading') {
      mindsets.requestId = action.meta.requestId;
      mindsets.status = 'loading';
    }
  });

  builder.addCase(getItemMindsets.fulfilled, ({ mindsets }, action) => {
    if (mindsets.status === 'loading' && mindsets.requestId === action.meta.requestId) {
      mindsets.requestId = '';
      mindsets.status = 'success';

      const modules = action.payload;

      if (modules) {
        const prev = mindsets.data;
        const next = [...prev];
        const experienceId = action.meta.arg;
        const payload = { experienceId, modules };
        const prevItemIndex = prev.findIndex((item) => item.experienceId === experienceId);

        if (prevItemIndex === -1) {
          next.push(payload);
        } else {
          next[prevItemIndex] = payload;
        }

        mindsets.data = next;
      }
    }
  });

  /**
   * Fetching experiences items mindsets batch
   */
  builder.addCase(getItemsMindsetsBatch.pending, ({ mindsets }, action) => {
    if (mindsets.status !== 'loading') {
      mindsets.requestId = action.meta.requestId;
      mindsets.status = 'loading';
    }
  });

  builder.addCase(getItemsMindsetsBatch.fulfilled, ({ mindsets }, action) => {
    if (mindsets.status === 'loading' && mindsets.requestId === action.meta.requestId) {
      mindsets.requestId = '';
      mindsets.status = 'success';

      if (action.payload) {
        action.meta.arg.forEach((experienceId, index) => {
          const modules = action.payload[index].modules;

          if (modules) {
            const resultObject: MindsetData = {
              experienceId,
              modules,
            };

            if (!mindsets.data.some((element) => element.experienceId === experienceId)) {
              mindsets.data = [...mindsets.data, resultObject];
            } else {
              mindsets.data = [resultObject];
            }
          }
        });
      }
    }
  });

  builder.addCase(getItemsMindsetsBatch.rejected, ({ mindsets }, action) => {
    if (mindsets.status === 'loading' && mindsets.requestId === action.meta.requestId) {
      mindsets.requestId = '';
      mindsets.status = 'error';
      mindsets.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(getSubscriberCount.pending, ({ counts }, action) => {
    if (counts.status !== 'loading') {
      counts.requestId = action.meta.requestId;
      counts.data = initialState.counts.data;
      counts.status = 'loading';
    }
  });

  builder.addCase(getSubscriberCount.fulfilled, ({ counts }, action) => {
    if (counts.status === 'loading' && counts.requestId === action.meta.requestId) {
      counts.requestId = initialState.counts.requestId;
      counts.data = (action.payload.filter((count) => count) as Counts[]) || initialState.counts.data;
      counts.status = 'success';
    }
  });

  builder.addCase(getSubscriberCount.rejected, ({ counts }, action) => {
    if (counts.status === 'loading' && counts.requestId === action.meta.requestId) {
      counts.requestId = initialState.counts.requestId;
      counts.status = 'error';
      counts.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(getSignalsOptions.fulfilled, ({ item }, { meta: { arg }, payload }) => {
    arg.signals.forEach((signalId) => {
      if (item.data) {
        const index = item.data.mindsets ? item.data.mindsets[arg.mindsetIndex].conditions.findIndex((i) => i.signal?.id === signalId) : 0;

        if (index > -1 && item.data.mindsets) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          item.data.mindsets[arg.mindsetIndex].conditions[index].signal!.options = payload[signalId];
        }
      }
    });
  });

  /**
   * Searching experiences
   */
  builder.addCase(search.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
    }
  });

  builder.addCase(search.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      if (action.payload) {
        list.data = action.payload;
      }
    }
  });

  builder.addCase(search.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(getList.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      if (action.meta.arg.page === 1) {
        list.data.items = [];
      }
    }
  });

  builder.addCase(getList.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      if (action.payload) {
        if (action.payload.currentPage === 1) {
          list.data = {
            ...action.payload,
            items: action.payload.items,
          };
        } else {
          list.data = {
            ...action.payload,
            items: list.data.items.concat(action.payload.items),
          };
        }
      }
    }
  });

  builder.addCase(getList.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });
};
