import { useRef, useState, KeyboardEvent, useCallback, memo, ChangeEvent, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Popper, TextField } from '@material-ui/core';
import { formatISO } from 'date-fns';

import { Icon, Toggle, Typography, DatePicker } from '@components';
import { content } from '@content';
import { useStyles } from './ModuleLibraryFilter.styles';
import { ModuleLibraryFilterProps, ModuleLibraryFormProps } from './ModuleLibraryFilter.props';

export const ModuleLibraryFilter = memo(
  ({ onSearch, onReset, defaultKeyword }: ModuleLibraryFilterProps): JSX.Element => {
    const styles = useStyles();
    const inputRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { handleSubmit, control, reset, setValue, getValues } = useForm<ModuleLibraryFormProps>({
      defaultValues: {
        keyword: '',
        journey: '',
        cohort: '',
        product: '',
        startDate: null,
        endDate: null,
        personalized: false,
        searchType: true,
      },
    });

    useEffect(() => {
      if (!defaultKeyword && defaultKeyword !== '') {
        return undefined;
      }

      return setValue('keyword', defaultKeyword);
    }, [defaultKeyword, setValue]);

    function handleClose() {
      setAnchorEl(null);
    }

    const handleClick = () => {
      if (anchorEl) {
        handleClose();
        return;
      }
      setAnchorEl(inputRef.current);
    };

    const handleResetClick = useCallback(() => {
      reset({
        keyword: '',
        journey: '',
        cohort: '',
        product: '',
      });
      onReset();
    }, [reset, onReset]);

    function handleSearchKeyboard(e: KeyboardEvent<HTMLInputElement>) {
      if (e.keyCode === 13) {
        onSearch(getValues());
      }
    }

    function handleSearchTypeChange(e: ChangeEvent<HTMLInputElement>) {
      setValue('searchType', e.target.checked);
      onSearch(getValues());
    }

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : '';

    return (
      <div>
        <Box display="flex" justifyContent="space-between" pb="1rem">
          <Box flexGrow={1}>
            <Controller
              name="keyword"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <TextField
                  ref={inputRef}
                  inputRef={ref}
                  {...rest}
                  onKeyUp={handleSearchKeyboard}
                  autoComplete="off"
                  placeholder={content.searchModuleLibrary}
                  fullWidth
                  className={styles.queryField}
                  InputProps={{
                    startAdornment: <Icon.Magnifier />,
                    endAdornment: (
                      <IconButton
                        onClick={handleClick}
                        className={`${
                          open
                            ? `${styles.filterDropdownBtn} ${styles.filterDropdownOpenedBtn}`
                            : styles.filterDropdownBtn
                        }`}
                      >
                        <Icon.ArrowDown className={styles.arrowDownIcon} />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
            <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" keepMounted>
              <form onSubmit={handleSubmit(onSearch)}>
                <Box className={styles.filter}>
                  <Grid container className={styles.filterGrid}>
                    <Grid item className={styles.filterLabel}>
                      <Typography.Headline>{content.moduleGroup}</Typography.Headline>
                    </Grid>
                    <Grid item className={styles.filterTextField}>
                      <Controller
                        name="journey"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <TextField fullWidth placeholder={content.enterModuleGroup} inputRef={ref} {...rest} />
                        )}
                      />
                    </Grid>
                    <Grid item className={styles.filterLabel}>
                      <Typography.Headline>{content.subscriberCohort}</Typography.Headline>
                    </Grid>
                    <Grid item className={styles.filterTextField}>
                      <Controller
                        name="cohort"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <TextField fullWidth placeholder={content.enterSubscriberCohort} inputRef={ref} {...rest} />
                        )}
                      />
                    </Grid>
                    <Grid item className={styles.filterLabel}>
                      <Typography.Headline>{content.product}</Typography.Headline>
                    </Grid>
                    <Grid item className={styles.filterTextField}>
                      <Controller
                        name="product"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                          <TextField fullWidth placeholder={content.enterProduct} inputRef={ref} {...rest} />
                        )}
                      />
                    </Grid>
                    <Grid item className={styles.dateLabel}>
                      <Typography.Headline>{content.dateRange}</Typography.Headline>
                    </Grid>
                    <Grid item className={styles.startDate}>
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            fullWidth
                            placeholder={content.enterStartDate}
                            value={field.value}
                            clearable
                            autoOk
                            onChange={(val) =>
                              val ? setValue(field.name, formatISO(val)) : setValue(field.name, null)
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item className={styles.endDate}>
                      <Controller
                        name="endDate"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            fullWidth
                            placeholder={content.enterEndDate}
                            value={field.value}
                            clearable
                            autoOk
                            onChange={(val) =>
                              val ? setValue(field.name, formatISO(val)) : setValue(field.name, null)
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box className={styles.footer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleResetClick}
                      className={styles.filterResetBtn}
                    >
                      {content.reset}
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      {content.search}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Popper>
          </Box>
          <Box display="flex" alignItems="flex-end" pb="0.8rem" pr="2.2rem" ml="11.7%">
            <FormControlLabel
              className={styles.personalizationCheck}
              label={content.personalized}
              control={
                <Controller
                  name="personalized"
                  control={control}
                  render={({ field: { ref, ...rest } }) => <Checkbox inputRef={ref} {...rest} />}
                />
              }
            />
            <Controller
              name="searchType"
              control={control}
              defaultValue
              render={({ field: { value, onChange, ...rest } }) => (
                <Toggle
                  defaultChecked={value}
                  labelLeft={content.module}
                  labelRight={content.deployment}
                  onChange={(e) => {
                    handleSearchTypeChange(e);
                  }}
                  {...rest}
                />
              )}
            />
          </Box>
        </Box>
      </div>
    );
  },
);

ModuleLibraryFilter.displayName = 'ModuleLibraryFilter';
