import { memo } from 'react';
import { Box, FormControlLabel, Checkbox, ButtonBase, MenuItem } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { Controller, useFormContext } from 'react-hook-form';
import { formatISO } from 'date-fns';

import { Typography, Select, Icon, DatePicker } from '@components';
import { BetweenField, TimesField } from '@views';
import { content } from '@content';
import { variables } from '@styles';
import { Signal } from '@modules';
import { parse } from '@utils';
import { ConditionSignalValue } from './ConditionSignalValue';
import { useStyles, useViewStyles } from './Condition.styles';
import { ConditionProps, ConditionViewProps, ArgumentType } from './Condition.props';
import { SIGNAL_ARGUMENTS } from './Condition.const';
import { ConditionBooleanValue } from './ConditionBooleanValue';

export const Condition = memo(
  ({ index, className = '', mindsetIndex, isOnlyCondition, onDelete, condition }: ConditionProps): JSX.Element => {
    const styles = useStyles();
    const { setValue, trigger, watch } = useFormContext();
    const signal: Signal = watch(`mindsets.${mindsetIndex}.conditions.${index}.signal`);
    const operator = watch(`mindsets.${mindsetIndex}.conditions.${index}.operator`);

    const handleDelete = () => {
      if (isOnlyCondition) {
        return;
      }
      onDelete();
    };

    return (
      <Box className={`${styles.condition} ${className}`}>
        <Box className={styles.top}>
          <Box className={styles.signalBox}>
            <Typography.Caption className={styles.fieldLabel} component="span">
              {content.dragAttributeHere}
            </Typography.Caption>
            <Droppable droppableId={`mindsets.${mindsetIndex}.conditions.${index}.signal`} type="SIGNAL">
              {(provided) => (
                <Box
                  className={styles.signalFieldContainer}
                  {...{ ref: provided.innerRef }}
                  {...provided.droppableProps}
                >
                  <Controller
                    name={`mindsets.${mindsetIndex}.conditions.${index}.signal` as const}
                    defaultValue={condition.signal}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value }, fieldState }) => (
                      <Box className={styles.signalField} data-signal={!!value} data-invalid={fieldState.invalid}>
                        {value && (
                          <>
                            <Icon.SmallDots />
                            <Typography.Headline
                              component="span"
                              title={value?.name}
                              className={styles.signalName}
                              display="inline"
                              noWrap
                            >
                              {value?.name}
                            </Typography.Headline>
                          </>
                        )}
                      </Box>
                    )}
                  />
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
          <Box className={styles.argumentBox}>
            <Typography.Caption component="span" className={styles.fieldLabel}>
              {content.argument}
            </Typography.Caption>
            <Controller
              name={`mindsets.${mindsetIndex}.conditions.${index}.operator` as const}
              defaultValue={condition.operator}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <Select
                  uiType="tertiary"
                  className={styles.signalOptionsDropdown}
                  inputRef={field.ref}
                  value={field.value || ''}
                  error={fieldState.invalid}
                  onChange={({ target: { value } }: any) => {
                    setValue(field.name, value as string, { shouldValidate: true });
                    setValue(`mindsets.${mindsetIndex}.conditions.${index}.value.${field.value}`, '');
                    setValue(`mindsets.${mindsetIndex}.conditions.${index}.value`, '');
                  }}
                  onBlur={field.onBlur}
                >
                  {signal?.type ? (
                    SIGNAL_ARGUMENTS[signal.type].map((argument: ArgumentType) => (
                      <MenuItem key={argument.value} value={argument.value}>
                        {argument.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">&nbsp;</MenuItem>
                  )}
                </Select>
              )}
            />
          </Box>
          {signal?.signalType === 'statistic' && operator === 'between' && (
            <BetweenField
              mindsetIndex={mindsetIndex}
              conditionIndex={index}
              defaultValue={typeof condition.value !== 'string' ? condition.value : undefined}
            />
          )}
          {signal?.signalType === 'statistic' && operator === '=' && (
            <TimesField mindsetIndex={mindsetIndex} conditionIndex={index} defaultValue={condition.value} />
          )}
          {signal?.signalType === 'statistic' && ['on', 'before', 'after'].includes(operator) && (
            <Box className={styles.signalValueBox}>
              <Typography.Caption component="span" className={styles.fieldLabel}>
                {content.value}
              </Typography.Caption>
              <Controller
                name={`mindsets.${mindsetIndex}.conditions.${index}.value.${operator}` as const}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <DatePicker
                    inputVariant="outlined"
                    InputProps={{
                      startAdornment: <Icon.Calendar width={15.5} height={15.5} withoutDate />,
                    }}
                    variant="inline"
                    autoOk
                    format="MM/dd/yyyy"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    value={parse.dateToMui(field.value)}
                    onChange={(val) => {
                      if (val) {
                        setValue(
                          `mindsets.${mindsetIndex}.conditions.${index}.value.${operator}`,
                          formatISO(val, { representation: 'date' }),
                          { shouldValidate: true },
                        );
                      }
                    }}
                  />
                )}
              />
            </Box>
          )}
          {signal?.type && signal?.signalType !== 'statistic' && (
            <Box className={styles.signalValueBox}>
              <Typography.Caption component="span" className={styles.fieldLabel}>
                {content.attributeValue}
              </Typography.Caption>
              {signal?.type === 'boolean' ? (
                <ConditionBooleanValue
                  index={index}
                  mindsetIndex={mindsetIndex}
                  defaultValue={condition.value}
                  signalId={signal.id}
                />
              ) : (
                <ConditionSignalValue
                  index={index}
                  mindsetIndex={mindsetIndex}
                  defaultValue={condition.value}
                  freeSolo={signal?.type === 'numeric'}
                  signalId={signal?.id}
                  disabled={!signal}
                />
              )}
            </Box>
          )}
        </Box>
        {!isOnlyCondition && (
          <Box className={styles.requiredCheckBox}>
            <FormControlLabel
              label={content.conditionAlwaysRequired}
              classes={{
                label: styles.checkboxLabel,
              }}
              control={
                <Controller
                  name={`mindsets.${mindsetIndex}.conditions.${index}.required` as const}
                  defaultValue={condition.required}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      inputRef={field.ref}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(`mindsets.${mindsetIndex}.metConditionCount`, 0);
                        trigger(`mindsets.${mindsetIndex}.metConditionCount`);
                      }}
                    />
                  )}
                />
              }
            />
          </Box>
        )}
        {!isOnlyCondition && (
          <ButtonBase className={styles.deleteButton} onClick={handleDelete}>
            <Icon.SmallClose width={30} height={30} strokeWidth={1} stroke={variables.color.secondary.gray} />
          </ButtonBase>
        )}
      </Box>
    );
  },
);

Condition.displayName = 'Condition';

export const ConditionView = memo(({ name, type, operator, value, required }: ConditionViewProps): JSX.Element => {
  const styles = useViewStyles();
  const operatorLabel = type ? SIGNAL_ARGUMENTS[type].find((i) => i.value === operator)?.label : '';
  let valueView = value;

  if (typeof value === 'object') {
    const [from, to] = Object.values(value);
    valueView = `${from}${to ? ` ${content.and} ${to}` : ''}`;
  }

  return (
    <Box className={styles.conditionView}>
      <Typography.Body noWrap title={`${name} ${operatorLabel} ${valueView}.`}>
        {`${name} ${operatorLabel} ${valueView}.`}
      </Typography.Body>
      {required && (
        <FormControlLabel
          className={styles.conditionViewRequired}
          label={content.conditionAlwaysRequired}
          control={<Checkbox checked={required} />}
        />
      )}
    </Box>
  );
});

ConditionView.displayName = 'ConditionView';
