import { Box } from '@material-ui/core';
import isEqual from 'lodash.isequal';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { UploadImageModal } from '@components';
import { content, page } from '@content';
import {
  ContentCenterType,
  DataVisualizationListItemSchema,
  ModulesSearchParams,
  dataVisualization,
  imageLibrary,
  modules,
} from '@modules';
import { history, useAppDispatch } from '@store';
import { textTemplate, useHistory, useWebTitle } from '@utils';
import { ContentLibrary, ContentLibraryType, DuplicateModuleModal } from '@views';

import { ContentCenterFilters } from './ContentCenterFilters';
import { UploadModuleModal, UploadModuleModalProps } from './UploadModuleModal';

import { TopBarContext } from '@layouts';
import { ChosenImage, DEFAULT_CHOSEN_DV, DEFAULT_CHOSEN_IMAGE } from '@routes/TemplateView';
import { UploadFormValues } from '@routes/TemplateView/LibrariesPanel/ImageLibraryPanel/ImageLibraryPanel.types';
import { ITEMS_PER_PAGE, SEARCH_QUERY_INIT } from './ContentCenter.const';
import { useStyles } from './ContentCenter.styles';
import { UploadVisualizationModal, UploadVisualizationModalProps } from './UploadVisualizationModal';
import { broadcaster, messageReceiver } from '@utils/network/broadcast';

/**
 ContentCenter route component.
 @returns {JSX.Element}
 */

export const ContentCenter = (): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { businessUnit: businessUnitId } = useHistory().query;

  const [detailModule, setDetailModule] = useState<null | any>(null);
  const [contentCenterType, setContentCenterType] = useState<ContentCenterType>('yourLibrary');
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [libraryType, setLibraryType] = useState<ContentLibraryType>('fourGrid');
  const [uploadModuleModalOpen, setUploadModuleModalOpen] = useState(false);
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);
  const [isTemplateEditorVisible, setIsTemplateEditorVisible] = useState(false);
  const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
  const [isDVEditorVisible, setIsDVEditorVisible] = useState(false);
  const [curModule, setCurModule] = useState<any>();
  const { businessUnit } = useHistory().query;
  const [, setTopBarCtx] = useContext(TopBarContext);
  const [chosenImage, setChosenImage] = useState<ChosenImage>(DEFAULT_CHOSEN_IMAGE);
  const [chosenDV, setChosenDV] = useState<DataVisualizationListItemSchema>(DEFAULT_CHOSEN_DV);
  const [uploadVisualOpen, setUploadVisualOpen] = useState(false);
  const [query, setQuery] = useState<ModulesSearchParams>({
    ...SEARCH_QUERY_INIT,
    ...(businessUnitId ? { businessUnitId } : null),
  });
  const libraryData = modules.useLibraryData();
  const libraryItems = useMemo(
    () =>
      libraryData.items.map((item) => {
        const tagParts = item.tag.index.split('-');
        const formattedName =
          tagParts.length === 3
            ? item.templateName
            : `${item.templateName ?? (item as any).moduleTemplateName} - ${item.name}`;

        return {
          ...item,
          formattedName,
        };
      }),
    [libraryData.items],
  );
  const [DVItems, setDVItems] = useState<DataVisualizationListItemSchema[]>([]);
  const dataVisualizations = dataVisualization.useDataVisualizationListData();
  const libraryImages = imageLibrary.useAllData();
  const modulesList =
    contentCenterType === 'imageLibrary'
      ? libraryImages.items
      : contentCenterType === 'dataVisual'
      ? DVItems
      : libraryItems;
  const pageCount = useMemo(
    () =>
      contentCenterType === 'imageLibrary'
        ? libraryImages.pageCount
        : contentCenterType === 'dataVisual'
        ? 1
        : libraryData.pageCount,
    [libraryImages.pageCount, libraryData.pageCount, contentCenterType],
  );

  useEffect(() => {
    const DVs = JSON.parse(JSON.stringify(dataVisualizations.items ?? [])) as DataVisualizationListItemSchema[];

    setDVItems([]);

    DVs.forEach((dataVisual) => {
      if (dataVisual?.thumbnailPath) {
        const image = new Image();

        image.onload = function () {
          dataVisual.width = image.width;
          dataVisual.height = image.height;
          dataVisual.thumbnailPath = `${dataVisual.thumbnailPath}?${new Date().getTime()}`;
          setDVItems((prev) => [...prev, dataVisual]);
        };
        image.onerror = () => {
          setDVItems((prev) => [...prev, dataVisual]);
        };

        image.src = dataVisual.thumbnailPath as string;
      }
    });
  }, [dataVisualizations.items, setDVItems]);

  useWebTitle(textTemplate(content.entityManager, { entity: content.content }));

  useEffect(() => {
    if (!businessUnitId) {
      history.push(page.dashboard);
      return undefined;
    }

    return () => {
      dispatch(modules.actions.resetClone());
    };
  }, [businessUnitId, dispatch]);

  // getting modules
  useEffect(() => {
    let promise: any;

    setTimeout(() => {
      if (query.page > pageCount) {
        return;
      }

      if (contentCenterType === 'imageLibrary') {
        promise = dispatch(
          imageLibrary.thunk.search({
            ...query,
            businessUnit: query.businessUnitId,
            keyword: query.keyword!,
          }),
        );

        setDVItems([]);
      } else if (contentCenterType === 'dataVisual') {
        dispatch(dataVisualization.actions.resetDataVisualizationList());
        promise = dispatch(
          dataVisualization.thunk.getListDataVisualization({
            ...query,
            businessUnit: query.businessUnitId,
          }),
        );
      } else {
        promise = dispatch(modules.thunk.search(query));
        setDVItems([]);
      }
    }, 200 * query.page);
  }, [businessUnitId, contentCenterType, dispatch, query, pageCount]);

  useEffect(() => {
    dispatch(modules.actions.resetLibrary());
  }, []);

  useEffect(() => {
    // if (contentCenterType === 'yourLibrary') {
    messageReceiver.onmessage = (event) => {
      dispatch(modules.actions.resetLibrary());
      setQuery((prev) => ({
        ...prev,
        page: 1,
      }));
      dispatch(modules.thunk.search({ ...query, page: 1 }));
    };
    // } else {
    //   messageReceiver.onmessage = () => {};
    // }
  }, [query, messageReceiver, setQuery, contentCenterType]);

  useEffect(() => {
    return () => {
      // messageReceiver.close();
      dispatch(modules.actions.resetLibrary());
      dispatch(imageLibrary.actions.resetLibrary());
      dispatch(dataVisualization.actions.resetDataVisualizationList());
    };
  }, []);

  const handleFilterSearch = (values: any) => {
    setChosenDV(DEFAULT_CHOSEN_DV);
    dispatch(modules.actions.resetLibrary());
    dispatch(imageLibrary.actions.resetLibrary());
    dispatch(dataVisualization.actions.resetDataVisualizationList());
    switch (values.searchType) {
      case 'deployment':
        setContentCenterType('yourLibrary');
        break;
      case 'module':
        setContentCenterType('templates');
        break;
      case 'library':
        setContentCenterType('imageLibrary');
        break;
      case 'dataVisual':
        setContentCenterType('dataVisual');
        break;
      default:
        break;
    }

    setDetailModule(null);
    setLibraryType('fourGrid');

    setQuery((prev) => ({
      ...prev,
      ...values,
      businessUnitId,
      page: 1,
      sortBy: 'id',
    }));
  };

  const handlePageChange = useCallback(
    (event) => {
      setQuery((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    },
    [setQuery],
  );

  const openModal = (module: any, moduleType: string) => {
    if (moduleType === 'instance' || moduleType === 'image') {
      return;
    }

    setDetailModule(module);
    setLibraryType('twoGrid');
  };

  const handleCloseDuplicateModal = () => {
    setShowDuplicateModal(false);
  };

  const handleUploadModuleModalOpen = useCallback(() => {
    switch (contentCenterType) {
      case 'yourLibrary':
        setUploadModuleModalOpen(true);
        break;
      case 'imageLibrary':
        setUploadImageModalOpen(true);
        break;
      case 'dataVisual':
        setUploadVisualOpen(true);
        break;
      default:
        break;
    }
  }, [setUploadModuleModalOpen, contentCenterType]);

  const handleUploadModuleModalClose = useCallback(() => {
    if (contentCenterType !== 'imageLibrary') {
      setUploadModuleModalOpen(false);

      return;
    }

    setUploadImageModalOpen(false);
  }, [setUploadModuleModalOpen, contentCenterType]);

  const handleUploadModule: UploadModuleModalProps['onSubmit'] = useCallback(
    async (values) => {
      if (businessUnitId) {
        if (!isEqual(query, SEARCH_QUERY_INIT)) {
          await dispatch(modules.thunk.search({ ...SEARCH_QUERY_INIT, businessUnitId }));
        }

        const result = await dispatch(modules.thunk.create({ ...values, businessUnitId }));

        if (modules.thunk.create.fulfilled.match(result)) {
          handleUploadModuleModalClose();

          setTimeout(() => {
            broadcaster.postMessage('refresh');
          }, 500);
        }
      }
    },
    [businessUnitId, dispatch, handleUploadModuleModalClose, query],
  );

  const handleCreateImage = async (file: File | null, businessUnitItem: number) => {
    const body = new FormData();

    body.append('file', file as Blob);

    const result = await dispatch(
      imageLibrary.thunk.create({ params: { businessUnit: businessUnitItem }, body, itemsPerPage: ITEMS_PER_PAGE }),
    );

    if (result.payload && imageLibrary.thunk.create.fulfilled.match(result)) {
      handleUploadModuleModalClose();
    }
  };

  const handleUploadImageSubmit = useCallback(
    async (values: UploadFormValues) => {
      if (businessUnit && Array.isArray(values.files)) {
        for (const file of values.files) {
          await handleCreateImage(file, businessUnit);
        }
      } else if (businessUnit && !Array.isArray(values.files)) {
        await handleCreateImage(values.files, businessUnit);
      }
    },
    [handleCreateImage, businessUnit],
  );

  const handleTemplateView = (module: any, moduleType: string) => {
    if (moduleType === 'image') {
      setIsImageEditorVisible(true);
      setChosenImage({
        id: module.id,
        width: module.width,
        height: module.height,
        name: module.name,
        imageSrc: module.path,
        chosenModuleElemId: null,
        link: '',
        altText: '',
      });
    }

    if (moduleType === 'instance') {
      setTimeout(() => {
        setTopBarCtx((prev) => ({
          ...prev,
          moduleDeployment: module.deployment?.name,
          currentEmailTemplate: module.deploymentTemplateName,
          chosenTreatment: { name: module.name } as any,
          contentModuleName: module.templateName,
        }));
      }, 500);
      setIsTemplateEditorVisible(true);
      setCurModule(module);
    }

    if (moduleType === 'template') {
      setTimeout(() => {
        setTopBarCtx((prev) => ({
          ...prev,
          contentModuleName: module.templateName,
        }));
      }, 500);
      setIsTemplateEditorVisible(true);
      setCurModule(module);
    }

    if (moduleType === 'dataVisual') {
      setChosenDV(module);
      setIsDVEditorVisible(true);
      setCurModule(module);
    }

    setDetailModule(module);
  };

  useEffect(() => {
    setDetailModule(chosenDV);
  }, [chosenDV]);

  useEffect(() => {
    setDetailModule(chosenImage);
  }, [chosenImage]);

  const handleUploadVisualModalClose = useCallback(() => {
    setUploadVisualOpen(false);
  }, [setUploadVisualOpen]);

  const handleFormVisualSubmit: UploadVisualizationModalProps['onSubmit'] = useCallback(
    async (values) => {
      const valuesWithClient = { ...values, 'businessUnit[id]': businessUnitId };
      valuesWithClient.isMaxScaleEnabled = (valuesWithClient.isMaxScaleEnabled ? 1 : 0) as any;

      const formData = new FormData();

      Object.keys(valuesWithClient).forEach((key) => {
        formData.append(key, valuesWithClient[key as keyof typeof valuesWithClient] as string | Blob);
      });

      const result = await dispatch(dataVisualization.thunk.create(formData));

      if (dataVisualization.thunk.create.fulfilled.match(result)) {
        setTimeout(() => {
          dispatch(
            dataVisualization.thunk.getListDataVisualization({
              ...query,
              businessUnit: query.businessUnitId,
            }),
          );
        }, 2000);
        handleUploadVisualModalClose();
      }
    },
    [businessUnitId, query, dispatch, handleUploadVisualModalClose],
  );

  return (
    <Box className={styles.contentCenter}>
      <Box className={styles.contentCenterLeft}>
        <ContentCenterFilters
          type={contentCenterType}
          setIsDVEditorVisible={setIsDVEditorVisible}
          setIsImageEditorVisible={setIsImageEditorVisible}
          setIsTemplateEditorVisible={setIsTemplateEditorVisible}
          onSearch={handleFilterSearch}
          onUploadModule={handleUploadModuleModalOpen}
        />
        {uploadModuleModalOpen && (
          <UploadModuleModal onSubmit={handleUploadModule} onClose={handleUploadModuleModalClose} />
        )}
        {uploadImageModalOpen && (
          <UploadImageModal onSubmit={handleUploadImageSubmit} onClose={handleUploadModuleModalClose} />
        )}
        {uploadVisualOpen && (
          <UploadVisualizationModal onSubmit={handleFormVisualSubmit} onCancel={handleUploadVisualModalClose} />
        )}
        <ContentLibrary
          type={libraryType}
          activeModuleId={detailModule?.id}
          modulesList={modulesList}
          currentPage={query.page}
          pageCount={pageCount}
          chosenImage={chosenImage}
          chosenDV={chosenDV}
          query={query}
          isImageEditorVisible={isImageEditorVisible}
          curModule={curModule}
          isDVEditorVisible={isDVEditorVisible}
          isTemplateEditorVisible={isTemplateEditorVisible}
          setIsTemplateEditorVisible={setIsTemplateEditorVisible}
          setChosenImage={setChosenImage}
          setChosenDV={setChosenDV}
          setIsImageEditorVisible={setIsImageEditorVisible}
          setIsDVEditorVisible={setIsDVEditorVisible}
          onViewDetail={openModal}
          onTemplateView={handleTemplateView}
          onPageChange={handlePageChange}
        />
      </Box>
      {showDuplicateModal && detailModule && (
        <DuplicateModuleModal detailModuleId={detailModule.id} onClose={handleCloseDuplicateModal} />
      )}
    </Box>
  );
};
