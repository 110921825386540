import { base } from '@modules';

import { MailPartsSchema } from './MailParts.schema';

export const initialState: MailPartsSchema = {
  mailPartsByProgram: {
    ...base.state,
    data: [],
  },
};
