import { memo } from 'react';
import { Box, IconButton } from '@material-ui/core';

import { Icon, Panel, Typography } from '@components';
import { useStyles } from './DeploymentInfo.styles';
import { DEP_INFO_FIELDS, DEP_INFO_FIELD_VALUE_PLACEHOLDER } from './DeploymentInfo.const';
import { DeploymentInfoProps } from './DeploymentInfo.props';

export const DeploymentInfo = memo(
  ({
    smartContentFileName: scfName,
    remoteDir: rmtDir,
    smartContentFilePublishedAt: scfDate,
    mailShellFilePublishedAt: msfDate,
    onCopyClipboard,
  }: DeploymentInfoProps): JSX.Element => {
    const styles = useStyles();

    const fields = [scfName, rmtDir, scfDate, msfDate].map((value, index) => ({
      ...DEP_INFO_FIELDS[index],
      value,
    }));

    const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
      const { value } = event.currentTarget.dataset;
      onCopyClipboard(value as string);
    };

    return (
      <Panel>
        <Box className={styles.fields}>
          {fields.map((field) => (
            <Box className={styles.field} key={field.label}>
              <Box className={styles.icon}>{field.icon}</Box>
              <Box className={styles.text}>
                <Typography.Tag className={styles.label} title={field.label}>
                  {field.label}
                </Typography.Tag>
                <Box className={styles.payload}>
                  <Typography.Body className={styles.value} title={field.value}>
                    {field.value || DEP_INFO_FIELD_VALUE_PLACEHOLDER}
                  </Typography.Body>
                  {field.copyable && field.value && (
                    <IconButton
                      className={styles.copy}
                      title={field.value}
                      data-value={field.value}
                      onClick={handleCopy}
                    >
                      <Icon.Copy />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Panel>
    );
  },
);

DeploymentInfo.displayName = 'DeploymentInfo';
