import { base } from '@modules';
import { MailerSchema } from './Mailer.schema';

export const initialState: MailerSchema = {
  auth: {
    ...base.state,
    data: null,
  },
  entity: {
    ...base.state,
    data: null,
  },
};
