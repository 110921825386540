import { UpdateAttributeArg } from './AttributeLibrary.types';
import { ActionReducerMapBuilder, SliceCaseReducers, PayloadAction } from '@reduxjs/toolkit';

import {
  exportDataAttribute,
  getDataAttributeFeeds,
  getDataAttributeList,
  importDataAttribute,
  search,
} from './AttributeLibrary.thunk';
import { create, updateComposite } from '../SignalLibrary/SignalLibrary.thunk';
import { Attribute, AttributeLibrarySchema } from './AttributeLibrary.schema';

export const reducers: SliceCaseReducers<AttributeLibrarySchema> = {
  updateAttribute: ({ list }, { payload }: PayloadAction<UpdateAttributeArg>) => {
    list.data = {
      ...list.data,
      items: list.data.items.map((item) => (item.id === payload.id ? { ...item, ...payload } : item)),
    };
  },
  appendAttribute: ({ list }, { payload }: PayloadAction<Attribute>) => {
    const itemIndex = list.data.items.findIndex((item) => item.id === payload.id);

    if (itemIndex === -1) {
      list.data = {
        ...list.data,
        items: [...list.data.items, payload],
      };
    } else {
      const items = [...list.data.items];

      items[itemIndex] = {
        ...items[itemIndex],
        ...payload,
      };

      list.data = {
        ...list.data,
        items,
      };
    }
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<AttributeLibrarySchema>): void => {
  builder.addCase(search.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
    }
  });

  builder.addCase(search.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';

      if (action.payload) {
        list.data = {
          ...action.payload,
          items: action.payload.items.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          }),
        };
      }
    }
  });

  builder.addCase(search.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(getDataAttributeFeeds.pending, ({ feedList }, action) => {
    if (feedList.status !== 'loading') {
      feedList.requestId = action.meta.requestId;
      feedList.status = 'loading';
    }
  });

  builder.addCase(getDataAttributeFeeds.fulfilled, ({ feedList }, action) => {
    if (feedList.status === 'loading' && feedList.requestId === action.meta.requestId) {
      feedList.requestId = '';
      feedList.status = 'success';
      if (action.payload) {
        feedList.data = action.payload;
      }
    }
  });

  builder.addCase(getDataAttributeFeeds.rejected, ({ feedList }, action) => {
    if (feedList.status === 'loading' && feedList.requestId === action.meta.requestId) {
      feedList.requestId = '';
      feedList.status = 'error';
      feedList.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(getDataAttributeList.pending, ({ dataList }, action) => {
    if (dataList.status !== 'loading') {
      dataList.requestId = action.meta.requestId;
      dataList.status = 'loading';
    }
  });

  builder.addCase(getDataAttributeList.fulfilled, ({ dataList }, action) => {
    if (dataList.status === 'loading' && dataList.requestId === action.meta.requestId) {
      dataList.requestId = '';
      dataList.status = 'success';
      if (action.payload) {
        dataList.data = action.payload;
      }
    }
  });

  builder.addCase(getDataAttributeList.rejected, ({ dataList }, action) => {
    if (dataList.status === 'loading' && dataList.requestId === action.meta.requestId) {
      dataList.requestId = '';
      dataList.status = 'error';
      dataList.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(importDataAttribute.pending, ({ importFile }, action) => {
    if (importFile.status !== 'loading') {
      importFile.requestId = action.meta.requestId;
      importFile.status = 'loading';
    }
  });

  builder.addCase(importDataAttribute.fulfilled, ({ importFile }, action) => {
    if (importFile.status === 'loading' && importFile.requestId === action.meta.requestId) {
      importFile.requestId = '';
      importFile.status = 'success';
      if (action.payload) {
        // dataList.data = action.payload;
      }
    }
  });

  builder.addCase(importDataAttribute.rejected, ({ importFile }, action) => {
    if (importFile.status === 'loading' && importFile.requestId === action.meta.requestId) {
      importFile.requestId = '';
      importFile.status = 'error';
      importFile.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(exportDataAttribute.pending, ({ exportFile }, action) => {
    if (exportFile.status !== 'loading') {
      exportFile.requestId = action.meta.requestId;
      exportFile.status = 'loading';
    }
  });

  builder.addCase(exportDataAttribute.fulfilled, ({ exportFile }, action) => {
    if (exportFile.status === 'loading' && exportFile.requestId === action.meta.requestId) {
      exportFile.requestId = '';
      exportFile.status = 'success';
      if (action.payload) {
        // dataList.data = action.payload;
      }
    }
  });

  builder.addCase(exportDataAttribute.rejected, ({ exportFile }, action) => {
    if (exportFile.status === 'loading' && exportFile.requestId === action.meta.requestId) {
      exportFile.requestId = '';
      exportFile.status = 'error';
      exportFile.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(create.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      list.error = undefined;
    }
  });

  builder.addCase(create.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      if (action.payload) {
        list.data = { ...list.data, items: [...list.data.items, action.payload] };
      }
    }
  });

  builder.addCase(create.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(updateComposite.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      list.error = undefined;
    }
  });

  builder.addCase(updateComposite.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      const signal = action.payload;
      if (signal) {
        list.data = { ...list.data, items: list.data.items.map((item) => (item.id === signal.id ? signal : item)) };
      }
    }
  });

  builder.addCase(updateComposite.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });
};
