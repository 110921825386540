import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';

import { Panel, Typography } from '@components';
import { content, page } from '@content';
import { ModuleCard } from '@views';
import { modules } from '@modules';
import { PopularModulesProps } from './PopularModules.props';
import { useStyles } from './PopularModules.styles';

export const PopularModules = memo(({ businessUnitId, onViewDetail }: PopularModulesProps): JSX.Element => {
  const styles = useStyles();
  const { items } = modules.usePopularData();

  return (
    <Panel>
      <Box className={styles.header}>
        <Typography.Title component="h2">{content.topPerformingContent}</Typography.Title>
        <Button
          className={styles.viewFullLibrary}
          variant="contained"
          color="secondary"
          component={Link}
          to={`${page.analytics}${page.moduleLevel}?businessUnit=${businessUnitId}`}
        >
          {content.viewFullLibrary}
        </Button>
      </Box>
      <Box className={styles.container}>
        {items.map((item) => (
          <ModuleCard key={item.id} module={item} onViewDetailsClick={onViewDetail} />
        ))}
      </Box>
    </Panel>
  );
});

PopularModules.displayName = 'PopularModules';
