import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { ModuleDetailsSchema, MailBuilderTemplateRow } from './ModuleDetails.schema';
import { initialState } from './ModuleDetails.state';
import { get, save, personalize } from './ModuleDetails.thunk';

export const reducers = {
  reset: (state: ModuleDetailsSchema): void => {
    Object.assign(state, initialState);
  },
  addArticle: (state: ModuleDetailsSchema, action: PayloadAction<MailBuilderTemplateRow>): void => {
    if (state.data?.moduleHtmlTemplate) {
      const rows = [...state.data.moduleHtmlTemplate.page.rows, action.payload];
      const moduleHtmlTemplate = JSON.parse(JSON.stringify(state.data.moduleHtmlTemplate));
      moduleHtmlTemplate.page.rows = rows;
      state.data.moduleHtmlTemplate = moduleHtmlTemplate;
    } else {
      state.data = {
        ...state.data,
        moduleHtmlTemplate: {
          page: {
            template: {
              name: 'template-base',
              type: 'basic',
              version: '2.0.0',
            },
            body: {
              container: { style: { 'background-color': '#FFFFFF' } },
              content: {
                computedStyle: { messageWidth: '500px' },
                style: {
                  'font-family': 'Arial, Helvetica Neue, Helvetica, sans-serif',
                },
              },
            },
            rows: [action.payload],
          },
        },
      } as never;
    }
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<ModuleDetailsSchema>): void => {
  builder.addCase(get.pending, (state, action) => {
    if (state.status !== 'loading') {
      state.requestId = action.meta.requestId;
      state.status = 'loading';
      state.data = initialState.data;
    }
  });

  builder.addCase(get.fulfilled, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = initialState.requestId;
      state.status = 'success';
      if (action.payload) {
        state.data = {
          ...action.payload,
          ...(action.payload.isPersonalized &&
            action.payload.personalizationVariables && {
              personalizationData: {
                fieldName: action.payload.personalizationVariables?.fieldName || '',
                personalizationInfos: action.payload.personalizationInfos,
              },
            }),
        };
      }
    }
  });

  builder.addCase(get.rejected, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = initialState.requestId;
      state.status = 'error';
      state.error = action.error;
    }
  });

  builder.addCase(save.pending, (state, action) => {
    if (state.status !== 'loading') {
      state.requestId = action.meta.requestId;
      state.status = 'loading';
    }
  });

  builder.addCase(save.fulfilled, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = initialState.requestId;
      state.status = 'success';
      if (action.payload) {
        state.data = {
          ...action.payload,
          ...(action.payload.isPersonalized &&
            action.payload.personalizationVariables && {
              personalizationData: {
                fieldName: action.payload.personalizationVariables?.fieldName || '',
                personalizationInfos: action.payload.personalizationInfos,
              },
            }),
        };
      }
    }
  });

  builder.addCase(save.rejected, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = initialState.requestId;
      state.status = 'error';
      state.error = action.error;
    }
  });

  builder.addCase(personalize.pending, (state, action) => {
    if (state.status !== 'loading') {
      state.requestId = action.meta.requestId;
      state.status = 'loading';
    }
  });

  builder.addCase(personalize.fulfilled, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = initialState.requestId;
      state.status = 'success';
      state.data = action.payload ?? initialState.data;
      if (action.payload?.isPersonalized && state.data) {
        state.data.personalizationData = {
          fieldName: action.payload.personalizationVariables?.fieldName || '',
          personalizationInfos: action.payload.personalizationInfos,
        };
      }
      if (state.data && action.payload?.tag) {
        state.data.tag = action.payload.tag;
      }
    }
  });

  builder.addCase(personalize.rejected, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = initialState.requestId;
      state.status = 'error';
      state.error = action.error;
    }
  });
};
