import { SVGProps } from 'react';

export const ProgramsOutline = ({
  width = 24,
  height = 24,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9996 3C12.3527 3 12.6994 3.09721 13.0046 3.28172L19.3379 7.10996C19.5391 7.23173 19.7061 7.40611 19.8223 7.61574C19.9384 7.82537 19.9997 8.06295 20 8.30482V15.6947C19.9999 15.9367 19.9389 16.1744 19.8229 16.3841C19.7068 16.5939 19.5399 16.7685 19.3388 16.8904L13.0054 20.7187C12.7002 20.9029 12.3535 21 12.0004 21C11.6474 21 11.3006 20.9029 10.9954 20.7187L4.66208 16.8904C4.46075 16.7686 4.29367 16.5941 4.17751 16.3843C4.06134 16.1745 4.00014 15.9367 4 15.6947V8.30482C4.00028 8.06295 4.06156 7.82537 4.17771 7.61574C4.29387 7.40611 4.46087 7.23173 4.66208 7.10996L10.9946 3.28172C11.2997 3.09721 11.6465 3 11.9996 3ZM12.7498 19.1205L18.5 15.6447V9.18195L12.7498 12.6798V18.8838V19.1205ZM11.2498 19.1197V12.6798L5.5 9.18219V15.6442L11.2498 19.1197ZM6.26658 7.89278L11.7706 4.56538C11.8441 4.52096 11.923 4.5 11.9996 4.5C12.0762 4.5 12.155 4.52089 12.2284 4.5653L17.7332 7.89269L11.9998 11.3803L6.26658 7.89278Z"
    />
  </svg>
);
