import { CSSProperties } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { variables } from '@styles';

export function getStyle({
  provided,
  style,
  isDragging,
}: {
  provided: DraggableProvided;
  style?: CSSProperties;
  isDragging?: boolean;
}): CSSProperties {
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const marginBottom = 10;
  return {
    ...combined,
    height: isDragging ? combined.height : (combined.height as number) - marginBottom,
    border: isDragging
      ? `${variables.borderWidth.sm} solid ${variables.color.primary.lightPurple}`
      : `${variables.borderWidth.sm} solid transparent`,
    width: 300,
    marginBottom,
  };
}
