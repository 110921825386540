import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';
const login = {
  display: 'flex',
  alignItems: 'flex-start',
  maxWidth: '87.4rem',
};
export const useStyles = makeStyles((theme) =>
  createStyles({
    login,
    form: {
      width: '42.9rem',
      background: variables.color.primary.white,
      padding: theme.spacing(6),
    },
    arrowIcon: {
      marginLeft: theme.spacing(1.25),
    },
  }),
);
