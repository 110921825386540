import { useMemo } from 'react';
import { Select as MuiSelect, FormControl, FormHelperText, InputLabel, MenuItem, Box } from '@material-ui/core';

import { Typography, Icon } from '@components';
import { content } from '@content';
import { variables } from '@styles';
import { textTemplate } from '@utils';

import { SelectProps } from './Select.props';
import { useStyles, usePopoverStyles } from './Select.styles';
import { SELECT_VISIBLE_OPTIONS_LIMIT } from './Select.const';

/**
 * Select component
 * @returns {JSX.Element}
 */

export function Select<OptionId = string>({
  className = '',
  uiType = 'primary',
  direction = 'vertical',
  children,
  options = [],
  captionText,
  label,
  required = false,
  disabled = false,
  templated = false,
  error = false,
  helperText,
  errorMsg = '',
  value,
  placeholder = '',
  classNamePlaceholder = '',
  visibleOptionsLimit = SELECT_VISIBLE_OPTIONS_LIMIT,
  MenuProps = {},
  ...props
}: SelectProps<OptionId>): JSX.Element {
  const styles = useStyles({ visibleOptionsLimit });
  const popoverStyles = usePopoverStyles();

  const hint = useMemo(
    () =>
      helperText
        ? templated && !disabled && error
          ? textTemplate(content.pleaseSelect, { field: helperText })
          : required
          ? `*${content.required}. ${helperText}`
          : helperText
        : required
        ? `*${content.required}`
        : '',
    [templated, disabled, error, helperText, required],
  );

  const Hint = useMemo(() => Typography[uiType === 'tertiary' ? 'SmallCaption' : 'Body'], [uiType]);

  return (
    <Box className={`MuiFormControl-root ${className} ${styles[uiType]} ${styles[direction]} MuiFormControl-fullWidth`}>
      {captionText && <Typography.Caption className={styles.captionText}>{captionText}</Typography.Caption>}
      {label && (
        <InputLabel disableAnimation shrink={false} className={styles.label}>
          {required && templated ? textTemplate(content.requiredField, { field: label }) : label}
        </InputLabel>
      )}
      <Box className={styles.box}>
        {placeholder && (!value || (Array.isArray(value) && value.length === 0)) && (
          <Typography.Body
            data-has-error={error}
            data-is-required={required}
            className={`${styles.placeholder} ${classNamePlaceholder} ${disabled ? 'disabled' : ''}`}
          >
            {disabled
              ? ''
              : templated
              ? textTemplate(content.selectValue, { value: placeholder.toLowerCase() })
              : placeholder}
          </Typography.Body>
        )}
        <MuiSelect
          className={styles.select}
          displayEmpty
          IconComponent={() => <Icon.ChevronDown stroke={variables.color.primary.mediumGray} />}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            PopoverClasses: popoverStyles,
            MenuListProps: {
              className: styles.menuList,
            },
            ...MenuProps,
          }}
          error={error}
          value={value}
          disabled={disabled}
          {...props}
        >
          {children ||
            options.map((option) => (
              <MenuItem
                key={option.id as unknown as string}
                value={option.id as unknown as string}
                disabled={!!option.disabled}
                selected={option.checked}
                data-hidden={!!option.hidden}
              >
                {option.label}
              </MenuItem>
            ))}
        </MuiSelect>
        {errorMsg && <FormHelperText className={styles.validationMessage}>{errorMsg}</FormHelperText>}
        {hint && (
          <Hint className={styles.hint} data-disabled={disabled} data-has-error={error}>
            {hint}
          </Hint>
        )}
      </Box>
    </Box>
  );
}
