import { useEffect, useMemo, useCallback, useContext } from 'react';
import { Box, Button } from '@material-ui/core';

import { page, content } from '@content';
import { searchQuery, useLoader } from '@utils';
import { recommendedModules, businessUnitDeployments, userClients, modules } from '@modules';
import { history, useAppDispatch as useDispatch } from '@store';
import { DeploymentPicker, RecommenderTable } from '@views';
import { TopBarContext } from '@layouts';

import { useStyles } from './ModuleRecommender.styles';

export function ModuleRecommender(): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const [ctx, setCtx] = useContext(TopBarContext);

  const businessUnitDepsMeta = businessUnitDeployments.useNonArchivedMeta();
  const businessUnitDepsData = businessUnitDeployments.useNonArchivedData();

  const [businessUnit, deploymentId] = searchQuery.useMutualParams('businessUnit', 'deployment');

  const businessUnitEntityMeta = userClients.useEntityMeta();

  const refreshArticlesMeta = modules.useRefreshArticlesMeta();

  const dep = useMemo(
    () => businessUnitDepsData.find((d) => d.id === deploymentId) || null,
    [businessUnitDepsData, deploymentId],
  );

  const reloadWithDeployment = useCallback(
    (nextDepId: number) => {
      setCtx({ ...ctx, deployments: businessUnitDepsData, deploymentId: nextDepId });
      history.push(`${page.moduleRecommender}?businessUnit=${businessUnit}&deployment=${nextDepId}`);
    },
    [setCtx, ctx, businessUnitDepsData, businessUnit],
  );

  useLoader(businessUnitDepsMeta, businessUnitEntityMeta, refreshArticlesMeta);

  useEffect(() => {
    if (!businessUnit) {
      history.push(page.dashboard);
    }
  }, [businessUnit]);

  useEffect(() => {
    if (!businessUnit) {
      return undefined;
    }
    dispatch(businessUnitDeployments.thunk.getNonArchived(businessUnit));
    dispatch(userClients.thunk.getById(businessUnit));

    return () => {
      dispatch(recommendedModules.slice.actions.reset());
      dispatch(businessUnitDeployments.slice.actions.resetAll());
    };
  }, [dispatch, businessUnit]);

  useEffect(() => {
    if (businessUnitDepsMeta.status === 'success' && businessUnitDepsData.length !== 0 && !dep) {
      const [nextDep] = businessUnitDepsData;
      reloadWithDeployment(nextDep.id);
    }
  }, [reloadWithDeployment, businessUnitDepsMeta.status, businessUnitDepsData, dep]);

  const refreshArticles = useCallback(async () => {
    await dispatch(modules.thunk.refreshArticles(deploymentId));
    dispatch(recommendedModules.thunk.get(deploymentId));
  }, [dispatch, deploymentId]);

  return (
    <>
      <Box className={styles.header}>
        <DeploymentPicker
          className={styles.picker}
          value={businessUnitDepsData.length && deploymentId ? deploymentId : 0}
          items={businessUnitDepsData}
          onChange={reloadWithDeployment}
        />
        <Button variant="contained" color="primary" onClick={refreshArticles} disabled={!dep?.id}>
          {content.refreshArticles}
        </Button>
      </Box>
      <RecommenderTable />
    </>
  );
}
