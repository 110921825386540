import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Pill component styles
 * @returns {Function}
 */

const highlightedStyles = {
  '&[data-color="transparent"]': {
    backgroundColor: variables.color.primary.lighterPurple,
    borderColor: variables.color.primary.lightPurple,

    '& [data-value]': {
      color: variables.color.primary.mainPurple,
    },

    '& [data-percentage]': {
      color: variables.color.primary.mainPurple,
    },
  },

  '&[data-color="green"]': {
    backgroundColor: variables.color.semantic.positiveLight,

    '& [data-value]': {
      color: variables.color.semantic.positive,
    },

    '& [data-percentage]': {
      color: variables.color.semantic.positive,
    },
  },
};

export const useStyles = makeStyles(() =>
  createStyles({
    pill: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '3.4rem',
      transition: 'all 0.3s',
      maxWidth: '16.5rem',
      padding: '0.6rem 1.2rem',

      '&:hover': highlightedStyles,
    },

    highlighted: highlightedStyles,

    unClickable: {
      'pointer-events': 'none',
      cursor: 'default',
    },

    hidden: {
      'pointer-events': 'none',
      cursor: 'default',
      opacity: variables.opacity.none,
    },

    value: {
      '&&': {
        fontWeight: variables.font.weight.bold,
        marginRight: variables.gap.xxs,
        letterSpacing: 0,
        transition: 'all 0.3s',
        'white-space': 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },

    percentage: {
      fontSize: variables.font.size.xs,
      lineHeight: variables.lineHeight.xs,
      letterSpacing: 0,
      transition: 'all 0.3s',
    },

    transparent: {
      backgroundColor: 'transparent',
      border: `solid 1px ${variables.color.primary.lightGray}`,

      '& [data-value]': {
        color: variables.color.primary.darkestGray,
      },
    },

    green: {
      backgroundColor: variables.color.semantic.positive,
      color: variables.color.primary.white,

      '& [data-value], & [data-percentage]': {
        color: variables.color.primary.white,
      },
    },

    tooltipNoContent: {
      padding: '1.6rem',
      textAlign: 'center',
      fontWeight: variables.font.weight.bold,
    },

    fullWidth: {
      width: '100%',
      maxWidth: 'unset',
    },
  }),
);
