import { SnackbarProvider } from 'notistack';
import { BoxProps } from '@material-ui/core';

import { Icon } from '@components';
import { variables } from '@styles';

import { useStyles } from './NotificationsProvider.styles';

export const NotificationsProvider = ({ children }: { children: BoxProps['children'] }): JSX.Element => {
  const styles = useStyles();

  return (
    <SnackbarProvider
      maxSnack={4}
      autoHideDuration={4000}
      iconVariant={{
        success: <Icon.Check fill={variables.color.semantic.positive} />,
        warning: <Icon.AlertCircleOutlineV3 fill={variables.color.semantic.warning} />,
        error: <Icon.AlertCircleOutlineV3 />,
        info: <Icon.InformationCircleOutline />,
      }}
      classes={{
        root: `${styles.provider}`,
        variantSuccess: `${styles.snack} ${styles.success}`,
        variantError: `${styles.snack} ${styles.error}`,
        variantInfo: `${styles.snack} ${styles.info}`,
        variantWarning: `${styles.snack} ${styles.warning}`,
      }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </SnackbarProvider>
  );
};
