import { base } from '@modules';

import { ClientDeploymentsSchema } from './ClientDeployments.schema';

export const initialState: ClientDeploymentsSchema = {
  preDeployment: {
    ...base.state,
    data: { items: [] },
  },
  inMarket: {
    ...base.state,
    data: { items: [] },
  },
  nonArchived: {
    ...base.state,
    data: [],
  },
  all: {
    ...base.state,
    data: [],
  },
  inMarketSortByDate: {
    ...base.state,
    data: [],
  },
};
