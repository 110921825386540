import { Box, Button, Divider, Grid } from '@material-ui/core';
import { format } from '@utils';
import { content } from '@content';
import { Modal, Typography, Label } from '@components';

import { useStyles } from './MindsetFullModal.styles';
import { MindsetFullModalProps } from './MindsetFullModal.props';

/**
 * MindsetFullModal component
 * @returns {JSX.Element}
 */
export const MindsetFullModal = ({ entity, onClose }: MindsetFullModalProps): JSX.Element => {
  const styles = useStyles();
  return (
    <Modal open onClose={onClose} maxWidth={'58rem'}>
      <Box className={styles.mindsetFullModal}>
        <Box className={styles.header}>
          <Typography.Display className={styles.title}>{entity.name}</Typography.Display>
          {entity.updatedAt && (
            <Typography.Caption className={styles.date}>
              {content.deploymentDate}: {format.date(entity.updatedAt, 'MMM. do, yyyy')}
            </Typography.Caption>
          )}
        </Box>
        <Divider />
        <Box className={styles.content}>
          {entity.coverImage && (
            <Box className={styles.imageContainer}>
              <img className={styles.image} src={entity.coverImage} alt={entity.name || ''} />
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography.Body className={styles.gridTitle}>{content.cohort}</Typography.Body>
              <Typography.Headline className={styles.gridInfo}>{entity.cohort}</Typography.Headline>
            </Grid>
            <Grid item xs={6}>
              <Typography.Body className={styles.gridTitle}>{content.deployment}</Typography.Body>
              <Typography.Headline className={styles.gridInfo}>{entity.deployment}</Typography.Headline>
            </Grid>
            <Grid item xs={6}>
              <Typography.Body className={styles.gridTitle}>{content.totalImpressions}</Typography.Body>
              <Typography.Headline className={styles.gridInfo}>
                {entity.statistics?.totalImpressions}
              </Typography.Headline>
            </Grid>
            <Grid item xs={6}>
              <Typography.Body className={styles.gridTitle}>{content.ctir}</Typography.Body>
              <Typography.Headline className={styles.gridInfo}>
                {format.percent(entity.statistics?.ctir)}
              </Typography.Headline>
            </Grid>
            <Grid item xs={12}>
              <Typography.Body className={styles.gridTitle}>{content.moduleTag}</Typography.Body>
              <Typography.Headline className={styles.gridInfo}>{entity.tag}</Typography.Headline>
            </Grid>
            {entity.conditions && entity.conditions.length ? (
              <Grid item xs={12}>
                <Typography.Body className={styles.gridTitle}>{content.rules}</Typography.Body>
                <Typography.Headline className={styles.gridInfo}>{content.usingAtLeastTwoRules}</Typography.Headline>
                <Box>
                  {entity.conditions.map((item, key) => (
                    <Box key={key} className={styles.rulesItem}>
                      <Box className={styles.rulesBody}>
                        <Typography.Caption className={styles.counter}>{key + 1}.</Typography.Caption>
                        <Label variant="code">{item.signal?.name}</Label>
                        <Typography.Body className={styles.argument}>{item.operator}</Typography.Body>
                        <Label variant="code">{item.value}</Label>
                      </Box>
                      {item.required && <Typography.Label>{content.requiredRule}</Typography.Label>}
                    </Box>
                  ))}
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        <Divider />
        <Box className={styles.footer}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {content.close}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
