import { LegendItem, TableColumn, TableColumnSort, TableRecord } from '@components';
import { ModuleStatus } from '@modules';

export enum ColId {
  pckd = 'pckd',
  stat = 'stat',
  mtag = 'mtag',
  dscr = 'dscr',
  posn = 'posn',
  pubd = 'pubd',
  modd = 'modd',
}

export type Col = TableColumn<ColId>;

export type ColTip = Col['tooltip'];

export type ColRdr = Col['render'];

export type Rec = TableRecord & {
  [ColId.pckd]: boolean;
  [ColId.stat]: ModuleStatus;
  [ColId.mtag]: [string, string];
  [ColId.dscr]: [string, string, string];
  [ColId.posn]: string;
  [ColId.pubd]: [number, string];
  [ColId.modd]: [number, string];
};

export type Statuses = Record<ModuleStatus, Pick<LegendItem, 'label' | 'color'>>;

export type Sort = {
  by?: ColId;
  order: TableColumnSort;
};
