import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

import { Icon } from '@components';
import { content } from '@content';
import { ModuleAuditStatus } from '@modules';

import { Chip } from './Chip';

import { Col, ColumnId } from './Disclosure.types';

export const emptyTooltip: Col['tooltip'] = (rec, col) => (!rec && typeof col.title === 'string' ? col.title : '');

export const statusTooltip: Col['tooltip'] = (rec, col) => {
  if (rec) {
    const key = rec[col.id] as ModuleAuditStatus;

    return content[key];
  }

  if (typeof col.title === 'string') {
    return col.title;
  }

  return '';
};

export const renderHtml = (value: ReactNode): JSX.Element => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: value as string }} />
);

export const renderChip = (value: ReactNode): JSX.Element => <Chip status={value as ModuleAuditStatus} />;

export const renderLink = (value: ReactNode): JSX.Element => (
  <IconButton>
    <Link to={value as string}>
      <Icon.Pencil />
    </Link>
  </IconButton>
);

export const COLUMNS: Col[] = [
  {
    id: ColumnId.name,
    title: content.moduleName,
  },
  {
    id: ColumnId.copy,
    title: content.moduleCopy,
    multiline: true,
    tooltip: emptyTooltip,
    render: renderHtml,
  },
  {
    id: ColumnId.disc,
    title: content.disclosure,
    align: 'middle',
    tooltip: statusTooltip,
    render: renderChip,
  },
  {
    id: ColumnId.cont,
    title: content.content,
    align: 'middle',
    tooltip: statusTooltip,
    render: renderChip,
  },
  {
    id: ColumnId.html,
    title: content.html,
    align: 'middle',
    tooltip: statusTooltip,
    render: renderChip,
  },
  {
    id: ColumnId.edit,
    align: 'middle',
    tooltip: emptyTooltip,
    render: renderLink,
  },
];
