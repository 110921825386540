/**
 QATests component variables
 @returns {variables}
 */

export const SEND_TESTS = [
  {
    id: 'oneTestForEachPermut',
    label: 'Send one test for each permutation (22)',
  },
  {
    id: 'shownAtLeastOnce',
    label: 'Send so that every module is shown at least once (9)',
  },
  {
    id: 'internalTestRecipients',
    label: 'Send tests to internal test recipients (6)',
  },
];
