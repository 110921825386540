import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useSwitchStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '3.2rem',
      height: '1.8rem',
      padding: 0,
      borderRadius: variables.borderRadius.lg,
      boxShadow: '0 0 0.5rem rgba(108, 194, 255, 0.4)',
      margin: `0 ${variables.gap.sm}`,
    },
    switchBase: {
      color: variables.color.primary.lightPurple,
      padding: '0.2rem',

      '&$checked': {
        transform: 'translateX(1.4rem)',
        color: variables.color.primary.lightPurple,

        '&.Mui-disabled': {
          color: variables.color.tertiary.gray,
        },

        '& + $track': {
          opacity: 1,
          backgroundColor: variables.color.primary.white,
        },
      },
    },
    thumb: {
      width: '1.4rem',
      height: '1.4rem',
      boxShadow: 'none',
    },
    track: {
      borderRadius: variables.borderRadius.lg,
      backgroundColor: variables.color.primary.white,
    },
    checked: {},
  }),
);

export const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      '.MuiTypography-root&': {
        color: variables.color.primary.gray,
      },
    },
  }),
);
