import { SVGProps } from 'react';

export const ChevronDown = ({
  width = 16,
  height = 16,
  className,
  strokeWidth = '1.5',
  stroke = '#494949',
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M4 6L8 10L12 6" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
