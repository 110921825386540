import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    signal: {
      display: 'flex',
      alignItems: 'center',
      background: variables.color.primary.white,
      justifyContent: 'space-between',
      borderRadius: '1rem 0.4rem',
      padding: theme.spacing(1, 1.5, 1, 2),
      '&:hover': {
        borderColor: `${variables.color.primary.mainPurple} !important`,
        background: `${variables.color.primary.lighterPurple} !important`,
        color: `${variables.color.primary.mainPurple} !important`,
      },
      '&:hover $text': {
        color: `${variables.color.primary.mainPurple} !important`,
      },
    },
    leftContainer: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      flex: 1,
      marginLeft: '2rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    text: {},
  }),
);
