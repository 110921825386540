import { FC, ReactElement, MouseEvent } from 'react';
import { BarItemProps } from '@nivo/bar';
import { BarChartTooltip } from './BarChartTooltip';
import { STEP_X } from './BarChart.const';

export const BarChartComponent: FC<BarItemProps & { className: string; unit?: string }> = ({
  height,
  data,
  onMouseEnter,
  showTooltip,
  onMouseLeave,
  hideTooltip,
  y,
  color,
  className,
  unit,
}): ReactElement => {
  const tooltip = <BarChartTooltip indexValue={data.data.label} value={data.value} unit={unit} />;
  const highlightItem = () => {
    const nodes = document.getElementsByClassName(className);
    for (let item = 0; item < nodes.length; item += 1) {
      const node = nodes[item] as HTMLElement;
      if (node) {
        const id = Number(node.getAttribute('id'));
        if (data.index !== id) {
          node.style.opacity = '0.5';
        }
      }
    }
  };
  const grayOutItem = () => {
    const nodes = document.getElementsByClassName(className);
    for (let item = 0; item < nodes.length; item += 1) {
      const node = nodes[item] as HTMLElement;
      if (node) {
        node.style.opacity = '1';
      }
    }
  };
  const handleTooltip = (event: MouseEvent<SVGElement>) => {
    highlightItem();
    showTooltip(tooltip, event);
  };
  const handleMouseEnter = (event: MouseEvent<SVGElement>) => {
    onMouseEnter(data, event);
    highlightItem();
    showTooltip(tooltip, event);
  };
  const handleMouseLeave = (e: MouseEvent<SVGElement>) => {
    onMouseLeave(data, e);
    grayOutItem();
    hideTooltip();
  };
  return (
    <g transform={'translate('.concat((data.index * STEP_X).toString(), ', ').concat(y.toString(), ')')}>
      <rect
        id={String(data.index)}
        className={className}
        width={15}
        height={height}
        rx="4"
        ry="4"
        fill={color}
        strokeWidth="0"
        stroke={color}
        values={String(data.value)}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
      />
    </g>
  );
};
