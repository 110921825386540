import { FC, ReactElement } from 'react';
import { MenuItem } from '@material-ui/core';

import { Select } from '@components';
import { content } from '@content';
import { DeploymentDropdownProps } from './DeploymentDropdown.props';
import { useStyles } from './DeploymentDropdown.styles';

export const DeploymentDropdown: FC<DeploymentDropdownProps> = ({
  deployments,
  currentDeployment,
  onChange,
  status,
}): ReactElement => {
  const styles = useStyles();
  return (
    <Select
      uiType="secondary"
      value={deployments.length && currentDeployment ? currentDeployment : ''}
      onChange={onChange}
      disabled={!deployments.length}
      className={styles.deploymentDropdown}
      placeholder={!deployments.length && status === 'success' ? content.noDeployment : content.deployment}
      classNamePlaceholder={styles.placeholder}
    >
      {deployments.map((deployment) => (
        <MenuItem key={deployment.name} value={deployment.id}>
          {deployment.name}
        </MenuItem>
      ))}
    </Select>
  );
};
