import { createSlice } from '@reduxjs/toolkit';

import { MetaSchema, ModuleCreateData } from '@modules';
import { useAppSelector } from '@store';

import * as thunk from './Modules.thunk';
import { initialState } from './Modules.state';
import { reducers, extraReducers } from './Modules.reducer';
import { ModulesInfoData, SearchModulesPayload } from './Modules.schema';

export * from './Modules.schema';
export * from './Modules.types';

const slice = createSlice({
  name: 'modules',
  initialState,
  reducers,
  extraReducers,
});

export const modules = {
  thunk,
  slice,
  actions: slice.actions,
  usePopularMeta: (): MetaSchema =>
    useAppSelector(
      ({
        modules: {
          popular: { data, ...meta },
        },
      }) => meta,
    ),
  usePopularData: (): SearchModulesPayload =>
    useAppSelector(
      ({
        modules: {
          popular: { data },
        },
      }) => data,
    ),
  useLibraryMeta: (): MetaSchema =>
    useAppSelector(
      ({
        modules: {
          library: { data, ...meta },
        },
      }) => meta,
    ),
  useLibraryData: (): SearchModulesPayload =>
    useAppSelector(
      ({
        modules: {
          library: { data },
        },
      }) => data,
    ),
  useCloneMeta: (): MetaSchema =>
    useAppSelector(
      ({
        modules: {
          clone: { data, ...meta },
        },
      }) => meta,
    ),
  useDuplicateMeta: (): MetaSchema =>
    useAppSelector(
      ({
        modules: {
          duplicate: { data, ...meta },
        },
      }) => meta,
    ),
  useModuleInfoMeta: (): MetaSchema =>
    useAppSelector(
      ({
        modules: {
          moduleInfo: { data, ...meta },
        },
      }) => meta,
    ),
  useModuleInfoData: (): ModulesInfoData =>
    useAppSelector(
      ({
        modules: {
          moduleInfo: { data },
        },
      }) => data,
    ),
  useNewModuleMeta: (): MetaSchema =>
    useAppSelector(
      ({
        modules: {
          newModule: { data, ...meta },
        },
      }) => meta,
    ),
  useNewModuleData: (): ModuleCreateData =>
    useAppSelector(
      ({
        modules: {
          newModule: { data },
        },
      }) => data,
    ),
  useRefreshArticlesMeta: (): MetaSchema =>
    useAppSelector(
      ({
        modules: {
          refreshArticles: { data, ...meta },
        },
      }) => meta,
    ),
};
