import {
  useMemo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  MouseEvent,
  isValidElement,
  cloneElement,
} from 'react';
import { Box } from '@material-ui/core';
import debounce from 'lodash.debounce';

import { Panel, Typography, SortButton, Pagination } from '@components';

import { TableProps, TableColumnSort, TableColumnId, TableStyles } from './Table.props';
import { useStyles } from './Table.styles';
import { HEIGHT_AUTOFIT_DELAY, MEASURES_BY_UI_TYPE } from './Table.const';
import { useDecoratedColumns, useVirtualRender } from './Table.hooks';
import { getCellClassName, getNextSort } from './Table.utils';

/**
 Table component.
 @returns {JSX.Element}
 */

export function Table<ColumnId extends TableColumnId = TableColumnId>(props: TableProps<ColumnId>): JSX.Element {
  const {
    className: css = '',
    uiType = 'primary',
    autofit = false,
    measures: measuresInit = null,
    title = '',
    columns: columnsInit,
    records: recordsInit,
    page = Number.NaN,
    pages = Number.NaN,
    onPageChange,
    onClick,
    onSort,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [style, setStyle] = useState({});

  const measures = { ...MEASURES_BY_UI_TYPE[uiType], ...measuresInit };

  const extendedProps = { ...props, ...measures } as unknown as TableStyles;

  const styles = useStyles(extendedProps);

  const paged = useMemo(
    () => onPageChange && Number.isSafeInteger(page) && Number.isSafeInteger(pages) && pages > 1,
    [onPageChange, page, pages],
  );

  const columns = useDecoratedColumns(columnsInit);

  const { preholder, records, postholder, handleScroll } = useVirtualRender(recordsInit, measures);

  const handleSortClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { dataset } = event.currentTarget;
      const columnId = dataset.columnId as ColumnId;
      const sort = (dataset.sort || null) as TableColumnSort;

      if (onSort) {
        onSort(columnId, getNextSort(sort));
      }
    },
    [onSort],
  );

  const handleRecordClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const { dataset } = event.currentTarget;
      const recordId = dataset.recordId as string;
      const columnId = dataset.columnId as ColumnId;

      if (onClick) {
        onClick(recordId, columnId);
      }
    },
    [onClick],
  );

  const handlePaginationChange = useCallback(
    (event, nextPage: number) => onPageChange && onPageChange(nextPage),
    [onPageChange],
  );

  const fitHeight = debounce(
    useCallback(() => {
      if (autofit && ref.current) {
        const nextStyle = {
          maxHeight: `calc(100vh - ${ref.current.offsetTop / 10}rem - ${measures.bottomGap})`,
        };

        if (JSON.stringify(style) !== JSON.stringify(nextStyle)) {
          setStyle(nextStyle);
        }
      }
    }, [setStyle, ref, style, autofit, measures.bottomGap]),
    HEIGHT_AUTOFIT_DELAY,
  );

  useLayoutEffect(fitHeight);

  return (
    <Box className={`${styles.table} ${css}`} {...{ ref, style }} data-ui-type={uiType}>
      {title && <Typography.Title className={styles.title}>{title}</Typography.Title>}
      <Panel className={styles.head}>
        {columns.map((column) => (
          <Box
            key={column.id}
            className={`${styles.header} ${getCellClassName(column)}`}
            width={column.width}
            data-header="true"
            data-align={column.align || 'left'}
          >
            {column.sort !== undefined && (
              <SortButton
                className={styles.sort}
                data-column-id={column.id}
                data-sort={column.sort || ''}
                order={column.sort || undefined}
                onClick={onSort && handleSortClick}
              />
            )}
            {typeof column.title === 'string' ? (
              <Typography.Headline className={styles.headerTitle}>{column.title}</Typography.Headline>
            ) : (
              column.title
            )}
          </Box>
        ))}
      </Panel>
      <Box className={styles.body} onScroll={handleScroll}>
        {preholder && <Box style={preholder} key="preholder" />}
        {records.map((record) => (
          <Panel className={styles.record} data-clickable={!!onClick} key={record.id}>
            {columns.map((column) => (
              <Box
                key={column.id}
                className={[
                  styles.cell,
                  column.multiline ? styles.cellMulti : '',
                  getCellClassName(column, record),
                ].join(' ')}
                width={column.width}
                data-record-id={record.id}
                data-column-id={column.id}
                data-sortable={column.sort !== undefined}
                data-header="false"
                data-align={column.align || 'left'}
                onClick={onClick && handleRecordClick}
              >
                {(() => {
                  const lineClassName = column.multiline ? styles.valueMulti : styles.valueSingle;
                  const className = `${styles.value} ${lineClassName}`;
                  const value = column.render
                    ? column.render(record[column.id], record.id, column.id)
                    : record[column.id];

                  return isValidElement(value) ? (
                    cloneElement(value as JSX.Element, { className: `${className} ${value.props.className || ''}` })
                  ) : (
                    <Typography.Body className={className}>{value}</Typography.Body>
                  );
                })()}
              </Box>
            ))}
          </Panel>
        ))}
        {postholder && <Box style={postholder} key="postholder" />}
      </Box>
      {paged && (
        <Pagination className={styles.pagination} page={page} pageCount={pages} onChangePage={handlePaginationChange} />
      )}
    </Box>
  );
}
