import { ChangeEvent, memo, useCallback } from 'react';
import { Box, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { Typography } from '@components';
import { content } from '@content';

import { useStyles } from './TimesField.styles';
import { TimesFieldProps } from './TimesField.props';

export const TimesField = memo(
  ({
    mindsetIndex,
    defaultValue,
    fieldName = `mindsets.${mindsetIndex}.condition.value`,
    withLabel = false,
  }: TimesFieldProps): JSX.Element => {
    const styles = useStyles();

    const { setValue } = useFormContext();

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setValue(fieldName, event.target.value);
      },
      [setValue, fieldName],
    );

    return (
      <Box className={styles.timesField}>
        {withLabel && <Typography.Label className={styles.label}>{content.times}</Typography.Label>}
        <Controller
          name={fieldName}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <TextField
              fullWidth
              defaultValue={defaultValue}
              type="number"
              inputRef={ref}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              InputProps={{ inputProps: { min: 0 } }}
              {...rest}
              onChange={handleChange}
            />
          )}
        />
      </Box>
    );
  },
);

TimesField.displayName = 'TimesField';
