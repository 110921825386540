import { base } from '@modules';

import { AuthSchema } from './Auth.schema';

export const state: AuthSchema = {
  user: {
    ...base.state,
    data: {
      createdAt: 0,
      email: '',
      firstname: '',
      id: Number.NaN,
      isAgreementAccepted: false,
      lastname: '',
      permissions: [],
      role: { id: 0, name: 'ROLE_EDITOR' },
    },
  },
  pwdResetInit: { ...base.state },
  pwdResetConfirm: { ...base.state },
  acceptTerms: { ...base.state },
  lastUsedClientUpdate: { ...base.state },
};
