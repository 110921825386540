import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { content } from '@content';
import { Confirm, FileUpload, FileUploadProps } from '@components';
import { textTemplate, validate } from '@utils';

import { UploadCSVFileModalProps } from './UploadCSVFileModal.props';
import { FormValues } from './UploadCSVFileModal.types';

/**
 * UploadModal component
 * @returns {JSX.Element}
 */

export const UploadCSVFileModal = ({ title, onClose, onConfirm, label }: UploadCSVFileModalProps): JSX.Element => {
  const { handleSubmit, control, setValue, clearErrors, setError, watch } = useForm<FormValues>({
    defaultValues: { file: null },
  });

  const uploadFile = watch('file');

  const handleFileChange: FileUploadProps['onChange'] = useCallback(
    (file) => {
      clearErrors('file');

      if (file && !Array.isArray(file)) {
        if (validate.isCsvFile(file?.type)) {
          setValue('file', file);
        } else {
          setError('file', { message: content.invalidFileFormat });
        }
      } else {
        setValue('file', null);
      }
    },
    [clearErrors, setError, setValue],
  );

  return (
    <Confirm
      open
      submitDisabled={!uploadFile}
      title={title}
      submitLabel={content.continue}
      skipSubmitArrow
      onCancel={onClose}
      onSubmit={handleSubmit(onConfirm)}
    >
      <Controller
        name="file"
        control={control}
        rules={{ required: textTemplate(content.pleaseSelect, { field: content.imageFile }) }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <FileUpload
            {...field}
            id="image_upload.png"
            extensions={['csv']}
            label={label ? label : content.uploadFile}
            hint={error?.message || `*${content.required}. ${content.csvFileFormatOnly}`}
            error={invalid}
            value=""
            onChange={handleFileChange}
          />
        )}
      />
    </Confirm>
  );
};
