export const Bell = (): JSX.Element => (
  <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 22C8.12702 22.4327 8.39076 22.8126 8.75175 23.0828C9.11274 23.3531 9.55156 23.4992 10.0025 23.4992C10.4534 23.4992 10.8923 23.3531 11.2532 23.0828C11.6142 22.8126 11.878 22.4327 12.005 22"
      stroke="#6CC2FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 3.25V1" stroke="#6CC2FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10 3.25C11.9891 3.25 13.8968 4.04018 15.3033 5.4467C16.7098 6.85322 17.5 8.76088 17.5 10.75C17.5 17.796 19 19 19 19H1C1 19 2.5 17.084 2.5 10.75C2.5 8.76088 3.29018 6.85322 4.6967 5.4467C6.10322 4.04018 8.01088 3.25 10 3.25V3.25Z"
      stroke="#6CC2FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
