import { Card as MuiCard, CardMedia, CardContent, IconButton, Box } from '@material-ui/core';

import { Typography, Icon, Tooltip } from '@components';

import { useStyles } from './Card.styles';
import { CardProps } from './Card.props';

export const Card = ({
  className = '',
  image = '',
  pretitle = '',
  title,
  params,
  warning = '',
  onEyeClick,
}: CardProps): JSX.Element => {
  const styles = useStyles();

  return (
    <MuiCard className={`${styles.card} ${className}`}>
      {image ? (
        <CardMedia className={styles.image} image={image} />
      ) : (
        <Box className={`${styles.image} ${styles.placeholder}`} />
      )}
      <CardContent className={styles.content}>
        <IconButton className={styles.eye} onClick={onEyeClick}>
          <Icon.Eye />
        </IconButton>
        {pretitle && (
          <Tooltip gap="sm" text={pretitle}>
            <Typography.Caption className={styles.pretitle}>{pretitle}</Typography.Caption>
          </Tooltip>
        )}
        <Tooltip text={title}>
          <Typography.Headline className={styles.title}>{title}</Typography.Headline>
        </Tooltip>
        {params && params.length !== 0 ? (
          <Box className={styles.params}>
            {params.map((param) => (
              <Box className={styles.param} key={param.label}>
                <Typography.Caption className={styles.label}>{param.label}</Typography.Caption>
                <Tooltip align="end" gap="sm" text={param.value || ''}>
                  <Typography.Caption className={styles.value}>{param.value || '-'}</Typography.Caption>
                </Tooltip>
              </Box>
            ))}
          </Box>
        ) : (
          <Box className={styles.warning}>
            <Icon.ExclamationMark className={styles.warningIcon} />
            {warning && <Typography.Caption className={styles.warningMessage}>{warning}</Typography.Caption>}
          </Box>
        )}
      </CardContent>
    </MuiCard>
  );
};
