import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Condition component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    top: {
      display: 'flex',
    },
    label: {
      marginBottom: theme.spacing(1),
      '&[data-invalid="true"]': {
        color: variables.color.semantic.negative,
      },
    },
    signalBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexBasis: `calc(100% / 3 - ${theme.spacing(1.5)}px)`,
      maxWidth: `calc(100% / 3 - ${theme.spacing(1.5)}px)`,
      flexShrink: 0,
      marginRight: theme.spacing(2.25),
    },
    signalFieldContainer: {
      maxHeight: '6.5rem',
    },
    placeholder: {
      color: variables.color.primary.halfMediumGray,
      '&[data-invalid="true"]': {
        color: variables.color.semantic.negative,
      },
    },
    signalField: {
      background: variables.color.primary.white,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
      height: '3.4rem',
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(1, 1.5),
      '&[data-invalid="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.semantic.negative}`,
        background: variables.color.semantic.backgroundNegative,
        color: variables.color.semantic.negative,
      },
    },
    argumentBox: {
      flexBasis: `calc(100% / 3 - ${theme.spacing(1.5)}px)`,
      maxWidth: `calc(100% / 3 - ${theme.spacing(1.5)}px)`,
      marginRight: theme.spacing(2.25),
      display: 'flex',
      flexDirection: 'column',
    },
    signalValueBox: {
      flexBasis: `calc(100% / 3 - ${theme.spacing(1.5)}px)`,
      maxWidth: `calc(100% / 3 - ${theme.spacing(1.5)}px)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    signalOptionsDropdown: {
      '&[data-invalid="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.semantic.negative}`,
        background: variables.color.semantic.backgroundNegative,
        color: variables.color.semantic.negative,
        borderRadius: variables.borderRadius.sm,
        '& .MuiInput-root': {
          border: `${variables.borderWidth.sm} solid ${variables.color.semantic.negative}`,
        },
      },
    },
    signalValueInput: {
      '&[data-invalid="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.semantic.negative}`,
        background: variables.color.semantic.backgroundNegative,
        color: variables.color.semantic.negative,
        borderRadius: variables.borderRadius.sm,
      },
    },
    requiredCheckBox: {
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
        marginRight: 0,
      },
      '& .MuiFormControlLabel-label': {
        marginLeft: theme.spacing(0.75),
      },
    },
    checkboxLabel: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      color: variables.color.primary.darkestGray,
      lineHeight: variables.lineHeight.md,
    },
    deleteButton: {
      position: 'absolute',
      top: '0.3rem',
      right: '0.5rem',
      borderRadius: variables.borderRadius.sm,
    },
    numericInput: {
      textAlign: 'right',
      '& + .MuiAutocomplete-endAdornment': {
        right: theme.spacing(0.5),
      },
    },
    stringInput: {
      marginLeft: 0,
      '& + .MuiAutocomplete-endAdornment': {
        right: theme.spacing(0.5),
      },
    },
    numericOption: {
      textAlign: 'right',
      width: '100%',
    },
    iconAdd: {
      marginRight: theme.spacing(1),
    },
    bottomContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    addButton: {
      padding: theme.spacing(1, 1.5),
    },
    checkbox: {
      padding: 0,
    },
    booleanCondition: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
