import { SVGProps } from 'react';

export const Link = ({ className = '', strokeWidth = '1.5' }: SVGProps<SVGElement>): JSX.Element => (
  <svg width="20" height="9" viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M8.37017 2.18479C8.03973 1.81307 7.63449 1.51537 7.18099 1.31119C6.72749 1.10701 6.23598 1.00096 5.73865 1H4.55398C3.61141 1 2.70744 1.37448 2.04094 2.04105C1.37444 2.70763 1 3.6117 1 4.55438C1 5.49706 1.37444 6.40113 2.04094 7.06771C2.70744 7.73428 3.61141 8.10876 4.55398 8.10876H5.73865C6.23595 8.1076 6.7274 8.00146 7.18087 7.7973C7.63434 7.59313 8.03961 7.29554 8.37017 6.92397"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3994 2.18479C11.7298 1.81303 12.1351 1.5153 12.5886 1.31112C13.0421 1.10694 13.5336 1.00091 14.031 1H15.2156C16.1582 1 17.0622 1.37448 17.7287 2.04105C18.3952 2.70763 18.7696 3.6117 18.7696 4.55438C18.7696 5.49706 18.3952 6.40113 17.7287 7.06771C17.0622 7.73428 16.1582 8.10876 15.2156 8.10876H14.031C13.5336 8.1077 13.0421 8.00161 12.5887 7.79744C12.1352 7.59326 11.7299 7.29561 11.3994 6.92397"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.73828 4.55273H14.0309"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
