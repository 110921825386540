import { useState } from 'react';

import { MindsetModalProps } from './MindsetModal.props';
import { MindsetFullModal } from './MindsetFullModal';
import { MindsetPreviewModal } from './MindsetPreviewModal';

/**
 * MindsetModal component
 * @returns {JSX.Element}
 */
export const MindsetModal = ({ entity, onClose, handleCloseTooltip }: MindsetModalProps): JSX.Element => {
  const [fullInfo, setFullInfo] = useState(false);
  if (fullInfo) {
    return <MindsetFullModal entity={entity} onClose={onClose} />;
  }
  return (
    <MindsetPreviewModal
      entity={entity}
      onRezise={() => {
        setFullInfo(true);
        if (handleCloseTooltip) handleCloseTooltip();
      }}
    />
  );
};
