import { Box, ListItemIcon } from '@material-ui/core';

import { Typography, Icon } from '@components';
import { content } from '@content';

import { useStyles } from './TemplateItem.styles';
import { TemplateItemProps } from './TemplateItem.props';

/**
 TemplateItem component.
 @returns {JSX.Element}
 */

export const TemplateItem = ({ item, selected = false, onClick }: TemplateItemProps): JSX.Element => {
  const styles = useStyles({ selected });

  return (
    <Box className={styles.templateItem} onClick={() => onClick(item.id)}>
      <Box>
        <img className={styles.image} src={item?.previewImage} alt={item.name} />
      </Box>
      <Box className={styles.content}>
        <Box className={styles.leftContent}>
          <Typography.Headline noWrap className={styles.name}>
            {item.name}
          </Typography.Headline>
          <Typography.Caption className={styles.experience}>
            {item?.experiencesCount ? `${item?.experiencesCount} ${content.moduleGroupsAdded}` : ''}
          </Typography.Caption>
        </Box>
        <ListItemIcon classes={{ root: styles.iconListItem }}>
          <Icon.Eye stroke="" />
        </ListItemIcon>
      </Box>
    </Box>
  );
};
