import { FC, ReactElement } from 'react';

export const ArrowRight: FC = (): ReactElement => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.45508 12L11.7968 6.65832L6.45508 1.31664"
      stroke="#003B5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
