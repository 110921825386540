import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { content } from '@content';
import { Confirm, FileUpload, FileUploadProps } from '@components';
import { textTemplate, validate } from '@utils';

import { UploadModalProps } from './UploadImageModal.props';
import { FormValues } from './UploadImageModal.types';

/**
 * UploadImageModal component
 * @returns {JSX.Element}
 */

export const UploadImageModal = ({ onSubmit, onClose }: UploadModalProps): JSX.Element => {
  const { handleSubmit, control, setValue, clearErrors, setError, watch } = useForm<FormValues>({
    defaultValues: { files: [] },
  });

  const uploadFile = watch('files');

  const handleImageChange: FileUploadProps['onChange'] = useCallback(
    (files) => {
      clearErrors('files');

      if (Array.isArray(files)) {
        const isImagesValid = files.reduce((acc, file) => acc && validate.isImageFile(file?.type), true);
        if (isImagesValid) {
          setValue('files', files);
        } else {
          setError('files', { message: content.invalidFileFormat });
        }
      } else {
        setValue('files', []);
      }
    },
    [clearErrors, setError, setValue],
  );

  return (
    <Confirm
      open
      submitDisabled={!uploadFile}
      title={content.uploadImage}
      submitLabel={textTemplate(content.uploadValue, { value: content.image })}
      skipSubmitArrow
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="files"
        control={control}
        rules={{ required: textTemplate(content.pleaseSelect, { field: content.imageFile }) }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <FileUpload
            {...field}
            id="image_upload.png"
            extensions={['png', 'jpeg', 'jpg']}
            label={textTemplate(content.requiredField, { field: content.imageFile })}
            hint={error?.message || `*${content.required}. ${content.imageFileFormatsOnly}`}
            error={invalid}
            multiple={true}
            value={''}
            onChange={handleImageChange}
          />
        )}
      />
    </Confirm>
  );
};
