import { Box } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { formatISO } from 'date-fns';

import { Typography, DatePicker, Icon } from '@components';
import { content } from '@content';

import { DateFieldProps } from './DateField.props';
import { useStyles } from './DateField.styles';
import { parse } from '@utils';

export const DateField = ({
  mindsetIndex,
  operator,
  withLabel,
  fieldName = `mindsets.${mindsetIndex}.condition.value`,
}: DateFieldProps): JSX.Element => {
  const styles = useStyles();
  const { setValue } = useFormContext();

  return (
    <Box className={styles.dataField}>
      {withLabel && <Typography.Label className={styles.label}>{content.value}</Typography.Label>}
      <Controller
        name={`${fieldName}.${operator}` as const}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <DatePicker
            inputVariant="outlined"
            InputProps={{
              startAdornment: <Icon.Calendar width={15.5} height={15.5} withoutDate />,
            }}
            variant="inline"
            autoOk
            format="MM/dd/yyyy"
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            value={parse.dateToMui(field.value)}
            onChange={(val) => {
              if (val) {
                setValue(`${fieldName}.${operator}`, formatISO(val, { representation: 'date' }), {
                  shouldValidate: true,
                });
              }
            }}
          />
        )}
      />
    </Box>
  );
};

DateField.displayName = 'DateField';
