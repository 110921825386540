import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Switch, BrowserRouter, Router, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { store, history } from '@store';
import { theme } from '@styles';
import { page } from '@content';
import {
  Admin,
  Audit,
  Login,
  PasswordResetRequest,
  PasswordReset,
  Dashboard,
  Deployments,
  Experiences,
  ModuleCreator,
  ModuleRecommender,
  ContentCenter,
  PublishToESP,
  Analytics,
  SignalBuilder,
  ExperienceEditor,
  Publish,
  MyAccount,
} from '@routes';
import { func } from '@utils';
import { RoutesCollector } from '@components';
import { Public, Private } from '@layouts';

import './polyfills';

render(
  <StrictMode>
    <Provider store={store}>
      <RoutesCollector>
        <BrowserRouter basename="/" getUserConfirmation={func.nop}>
          <Router history={history}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Switch>
                <Public exact path={page.login}>
                  <Login />
                </Public>
                <Public exact path={page.passwordResetRequest}>
                  <PasswordResetRequest />
                </Public>
                <Public exact path={page.passwordReset}>
                  <PasswordReset />
                </Public>
                <Private path={page.admin}>
                  <Admin />
                </Private>
                <Private exact path={page.dashboard}>
                  <Dashboard />
                </Private>
                <Private exact path={page.myAccount2}>
                  <MyAccount />
                </Private>
                <Private exact path={page.contentCenter} hideTopBar={true}>
                  <ContentCenter />
                </Private>
                <Private exact path={page.moduleRecommender}>
                  <ModuleRecommender />
                </Private>
                <Private exact path={page.auditAndQa}>
                  <Audit />
                </Private>
                <Private exact path={`${page.contentCenter}/:id?`}>
                  <ModuleCreator />
                </Private>
                <Private exact path={page.publishToEsp}>
                  <PublishToESP />
                </Private>
                <Private exact path={page.deployments}>
                  <Deployments />
                </Private>
                <Private exact path={page.deploymentReview}>
                  <Publish />
                </Private>
                <Private exact path={page.experiences}>
                  <Experiences />
                </Private>
                <Private exact path={`${page.dataCenter}/:id?`}>
                  <SignalBuilder treatmentPanelType={'logicBuilder'} />
                </Private>
                <Private exact path={`${page.experiences}/:id`}>
                  <ExperienceEditor />
                </Private>
                <Private path={page.analytics}>
                  <Analytics />
                </Private>
                <Redirect to={page.dashboard} />
              </Switch>
            </ThemeProvider>
          </Router>
        </BrowserRouter>
      </RoutesCollector>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
