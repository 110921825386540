import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Icon, Menu } from '@components';
import { content, image, page } from '@content';
import { HistoryQueryParamKey, func, searchQuery, textTemplate } from '@utils';

import { EnterpriseMenu } from './EnterpriseMenu';

import { SideBarItem, SideBarItemText, SideBarProps } from './SideBar.props';
import { useStyles } from './SideBar.styles';

/**
 * SideBar component
 * @returns {JSX.Element}
 */
export const SideBar = ({
  expanded = false,
  enterprises = [],
  enterpriseId = 0,
  businessUnitId = 0,
  items,
  onEnterpriseToggle = func.nop,
  onClientChange = func.nop,
  onItemClick = func.nop,
  onMenuToggle = func.nop,
  onMenuClick = func.nop,
}: SideBarProps): JSX.Element => {
  const styles = useStyles();
  const history = useHistory();

  const { pathname, search } = useLocation();
  const [navHome] = items;

  const getItemLabel = useCallback((item: SideBarItem) => {
    const itemDataEntries = Object.entries(item.data || {});
    const itemDataTextEntries = itemDataEntries.map(([key, value]) => [key, content[value]]);
    const itemData = Object.fromEntries(itemDataTextEntries);

    return textTemplate(content[item.text], itemData);
  }, []);

  const getItemIcon = useCallback(
    (item: SideBarItem) => {
      const both = item.colr === 'both';
      const stroke = !item.colr || item.colr === 'stroke' || both;
      const fill = item.colr === 'fill' || both;

      return {
        ItemIcon: item.icon ? Icon[item.icon] : null,
        listItemIconClassName: [stroke && styles.navItemIconStroke, fill && styles.navItemIconFill]
          .filter((cn) => !!cn)
          .join(' '),
      };
    },
    [styles.navItemIconFill, styles.navItemIconStroke],
  );

  const selected = useMemo(() => {
    const [, pathRootKey] = pathname.split('/');
    const pathRoot = `/${pathRootKey}`;
    const currNavItem =
      items.find((item) => {
        const pageKey = item.page || item.subPage;

        return pageKey && page[pageKey]?.startsWith(pathRoot);
      }) || navHome;

    return getItemLabel(currNavItem);
  }, [getItemLabel, pathname, items, navHome]);

  const [opened, setOpened] = useState<SideBarItemText | undefined>();

  const handleItemClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const nextItemLabel = event.currentTarget.dataset.label as SideBarItemText;
      const nextItem = nextItemLabel && items.find((item) => getItemLabel(item) === nextItemLabel);

      if (nextItem) {
        if (nextItem.opts) {
          setOpened(nextItemLabel);
        }

        onItemClick(nextItem);
      }
    },
    [onItemClick, getItemLabel, items],
  );

  const handleMenuToggle = useCallback<typeof onMenuToggle>(
    (open) => {
      if (!open) {
        setOpened(undefined);
      }

      onMenuToggle(open);
    },
    [onMenuToggle, setOpened],
  );

  useEffect(() => {
    history.listen((location) => {
      setTimeout(() => {
        const currentRoute = `${location.pathname}${location.search}`;

        if (currentRoute !== '/login') {
          localStorage.setItem('lastRoute', `${location.pathname}${location.search}`);
        }
      }, 200);
    });
  }, [history]);

  return (
    <Box className={`${styles.sideBar} ${expanded ? styles.expanded : ''}`}>
      <EnterpriseMenu
        expanded={expanded}
        enterprises={enterprises}
        enterpriseId={enterpriseId}
        businessUnitId={businessUnitId}
        onEnterpriseToggle={onEnterpriseToggle}
        onClientChange={onClientChange}
      />
      <List component="nav" disablePadding>
        {items.map((item) => {
          const { ItemIcon, listItemIconClassName } = getItemIcon(item);
          const itemLabel = getItemLabel(item);

          return (
            <Menu
              appearance={{ anchor: { x: 'right', y: 'top' } }}
              options={item.opts || []}
              key={itemLabel}
              onClick={item.opts ? onMenuClick : func.nop}
              onToggle={item.opts ? handleMenuToggle : func.nop}
            >
              <ListItem
                classes={{
                  root: item.icon ? styles.navItem : styles.navDivider,
                  selected: styles.selected,
                }}
                disableGutters
                selected={selected === itemLabel || (item.opts && opened === itemLabel)}
                component={item.icon && item.page ? Link : 'li'}
                to={`${page[item.page || navHome.page]}${searchQuery.updateParams<HistoryQueryParamKey>(search, {
                  program: null,
                  deployment: null,
                  mailFile: null,
                  module: null,
                  template: null,
                })}`}
                data-label={itemLabel}
                onClick={item.page || item.opts || item.actn ? handleItemClick : undefined}
              >
                {ItemIcon && (
                  <ListItemIcon classes={{ root: styles.navItemIcon }} className={listItemIconClassName}>
                    <ItemIcon fill="" stroke="" color="" />
                  </ListItemIcon>
                )}
                <ListItemText
                  classes={{
                    root: item.icon ? styles.navItemText : styles.navDividerText,
                    primary: item.icon ? styles.navItemTextPrimary : styles.navDividerTextPrimary,
                  }}
                  primary={item.icon ? itemLabel : <span>{itemLabel}</span>}
                />
              </ListItem>
            </Menu>
          );
        })}
      </List>
      <img className={styles.logo} src={image.composefyWhiteLogo} alt={content.company} />
    </Box>
  );
};
