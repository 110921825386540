import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    condition: {
      background: 'rgba(255, 255, 255, 0.3)',
      border: `${variables.borderWidth.sm} solid rgba(208, 208, 206, 0.3)`,
      borderRadius: variables.borderRadius.sm,
      boxShadow: '0.1rem 0.1rem 0.4rem rgba(108, 194, 255, 0.3)',
      padding: '1.6rem 1.9rem 1.8rem',
      position: 'relative',
    },
    top: {
      display: 'flex',
      marginBottom: '0.9rem',
    },
    fieldLabel: {
      marginBottom: '0.4rem',
    },
    signalBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexBasis: '21rem',
      flexShrink: 0,
      maxWidth: '21rem',
      marginRight: '1.8rem',
    },
    signalFieldContainer: {
      maxHeight: '5rem',
    },
    signalField: {
      background: variables.color.secondary.white,
      border: `${variables.borderWidth.sm} solid ${variables.color.tertiary.gray}`,
      borderRadius: variables.borderRadius.sm,
      height: '5rem',
      alignItems: 'center',
      display: 'flex',
      padding: '1rem',
      '&[data-signal="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightPurple}`,
      },
      '&[data-invalid="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.secondary.orange}`,
      },
    },
    signalName: {
      marginLeft: '0.8rem',
    },
    argumentBox: {
      flexBasis: '24%',
      marginRight: '1.8rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '24%',
    },
    signalValueBox: {
      flexBasis: '24%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: '24%',
    },
    signalOptionsDropdown: {
      '& .MuiInputBase-root': {
        height: '5rem',
      },
      '& .MuiInputBase-input': {
        fontSize: variables.font.size.lg,
        lineHeight: variables.lineHeight.lg,
        fontWeight: variables.font.weight.bold,
        padding: '0.4rem 3rem 0.5rem 2rem',
      },
    },
    signalValueInput: {
      '& .MuiInput-root': {
        paddingLeft: variables.gap.lg,
        letterSpacing: variables.letterSpacing.sm,
        fontWeight: variables.font.weight.bold,
      },
    },
    requiredCheckBox: {
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
        marginRight: 0,
      },
      '& .MuiFormControlLabel-label': {
        marginLeft: '1rem',
      },
    },
    checkboxLabel: {
      fontSize: '1.2rem',
      color: variables.color.secondary.gray,
      lineHeight: variables.lineHeight.sm,
      marginLeft: '1.5rem',
    },
    deleteButton: {
      position: 'absolute',
      top: '0.3rem',
      right: '0.5rem',
      borderRadius: variables.borderRadius.sm,
    },
    numericInput: {
      textAlign: 'right',
    },
    stringInput: {
      marginLeft: '2rem',
    },
    numericOption: {
      textAlign: 'right',
      width: '100%',
    },
  }),
);

export const useViewStyles = makeStyles(() =>
  createStyles({
    conditionView: {
      display: 'grid',
      height: '8.4rem',
      boxShadow: '0.1rem 0.1rem 0.4rem rgba(108, 194, 255, 0.3)',
      border: `${variables.borderWidth.sm} solid rgba(208, 208, 206, 0.3)`,
      borderRadius: variables.borderRadius.sm,
      padding: variables.gap.lg,
      maxWidth: '68.7rem',
      '&:not(:last-of-type)': {
        marginBottom: variables.gap.sm,
      },
    },
    conditionViewRequired: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: '0.4rem',
      '& .MuiFormControlLabel-label': {
        fontWeight: variables.font.weight.regular,
        fontSize: variables.font.size.sm,
        color: variables.color.secondary.gray,
        marginLeft: '0.9rem',
      },
    },
  }),
);
