import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const link = {
  color: variables.color.primary.lightPurple,
  lineHeight: variables.lineHeight.md,
  '&[data-ui-type="default"]': { textDecoration: 'underline' },
  '&[data-ui-type="pure"]': { textDecoration: 'none' },
  '&:hover': {
    color: variables.color.secondary.blue,
  },
};

const large = {
  ...link,
  fontSize: variables.font.size.lg,
  fontWeight: variables.font.weight.bold,
};

const small = {
  ...link,
  fontSize: variables.font.size.md,
  fontWeight: variables.font.weight.semiBold,
};

const tiny = {
  ...small,
  fontSize: variables.font.size.sm,
};

export const useStyles = makeStyles(() => createStyles({ large, small, tiny }));
