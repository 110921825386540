import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

import { MindsetTableStyles } from './MindsetTable.types';

/**
 * MindsetTable component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    legend: {
      marginBottom: variables.gap.xl,
    },

    cell: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${variables.gap.md} 0`,

      '&&': {
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      },

      '& >div:not(:first-child)': {
        marginTop: variables.gap.lg,
      },
    },

    item: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      alignSelf: 'flex-end',
      marginTop: variables.gap.lg,
      minHeight: '3rem',
      display: 'flex',
      alignItems: 'center',

      '&:first-child': {
        marginTop: 0,
      },

      '&[data-key]': {
        alignSelf: 'flex-start',
        width: '100%',
      },
    },

    columnTitle: {
      '&:not(:empty)': {
        color: variables.color.primary.mediumGray,
        fontSize: variables.font.size.xxxs,
        fontWeight: variables.font.weight.bold,
        padding: '0.2rem 0.5rem',
        backgroundColor: variables.color.primary.backgroundLightGray,
        borderRadius: variables.borderRadius.xxs,
      },
    },

    fullWidthCell: ({ regularColumnsCount }: MindsetTableStyles) => ({
      display: 'flex',
      flexDirection: 'column',
      padding: `${variables.gap.md} 0`,

      '&&': {
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      },

      '& >div:not(:first-child)': {
        marginTop: variables.gap.lg,
      },

      '& .tooltip-button-wrapper': {
        width: `calc(100% * ${regularColumnsCount})`,
      },
    }),
  }),
);
