import { network } from '@utils';
import { createAsyncThunk } from '@store';
import { content, page } from '@content';
import { global, ApiError } from '@modules';

import { keys } from './Auth.keys';
import {
  LoginQuery,
  LoginReply,
  RefreshReply,
  PwdResetReqQuery,
  PwdResetReqReply,
  PwdResetQuery,
  PwdResetReply,
  AcceptTermsReply,
  LastUsedClientUpdateQuery,
  LastUsedClientUpdateReply,
} from './Auth.types';

export const login = createAsyncThunk(keys.login, async (query: LoginQuery) => {
  localStorage.removeItem('lastSave.time');
  localStorage.removeItem('lastSave.status');

  const resp = await network.postWithCreds<LoginReply>({ key: keys.login }, { body: JSON.stringify(query) });

  if (resp) {
    network.token.set(resp.token);
  }

  return resp;
});

export const refresh = createAsyncThunk(keys.refresh, async (arg, { extra, rejectWithValue }) => {
  try {
    const resp = await network.postWithCreds<RefreshReply>({ key: keys.refresh });

    if (resp) {
      network.token.set(resp.token);
    }

    if (!resp?.user.isAgreementAccepted) {
      extra.history.push(page.login);
    }

    return resp;
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const requestPasswordReset = createAsyncThunk(keys.pwdResetInit, (query: PwdResetReqQuery) =>
  network.post<PwdResetReqReply>({ key: keys.pwdResetInit }, { body: JSON.stringify(query) }),
);

export const resetPassword = createAsyncThunk(keys.pwdResetConfirm, (query: PwdResetQuery, thunkApi) => {
  try {
    return network.post<PwdResetReply>(
      { key: keys.pwdResetConfirm },
      { body: JSON.stringify(query), signal: thunkApi.signal },
    );
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const acceptTerms = createAsyncThunk(
  keys.acceptTerms,
  async (user: number, { extra, dispatch, rejectWithValue }) => {
    try {
      dispatch(global.actions.isLoading(true));
      const resp = await network.post<AcceptTermsReply>({ key: keys.acceptTerms, params: { user } });

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: content.successfullyCompleted,
      //     options: { variant: 'success' },
      //   }),
      // );
      extra.history.push(page.dashboard);

      return resp;
    } catch (e) {
      dispatch(
        global.actions.enqueueNotification({
          message: (e as ApiError).message,
          options: { variant: 'error' },
        }),
      );

      return rejectWithValue(e as ApiError);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const logout = createAsyncThunk(keys.logout, async (...[, { dispatch, extra }]) => {
  try {
    await network.postWithCreds({ key: keys.logout });
    network.token.set('');
    extra.history.push(page.login);
  } catch (e) {
    dispatch(
      global.actions.enqueueNotification({
        message: (e as ApiError).message,
        options: { variant: 'error' },
      }),
    );
  }
});

export const lastUsedClientUpdate = createAsyncThunk(
  keys.lastUsedClientUpdate,
  (params: LastUsedClientUpdateQuery, { rejectWithValue }) => {
    try {
      return network.put<LastUsedClientUpdateReply>({
        key: keys.lastUsedClientUpdate,
        params: { businessUnit: params.businessUnit },
      });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);
