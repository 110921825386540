import { ApiError, global } from '@modules';
import { createAsyncThunk } from '@store';
import { network } from '@utils';

import { ImageLibraryPayload, Image } from './ImageLibrary.schema';
import { ImageLibraryCreateArg, ImageLibrarySearchArg } from './ImageLibrary.types';

export const search = createAsyncThunk(
  'imageLibraryImages/search',
  ({ businessUnit, ...rest }: ImageLibrarySearchArg, thunkApi) => {
    try {
      thunkApi.dispatch(global.actions.isLoading(true));

      return network.get<ImageLibraryPayload>(
        { key: 'search_image_library_items_by_business_unit', params: { businessUnit: businessUnit, ...rest } },
        { signal: thunkApi.signal },
      );
    } catch (exception) {
      return thunkApi.rejectWithValue(exception as ApiError);
    } finally {
      thunkApi.dispatch(global.actions.isLoading(false));
    }
  },
);

export const getImageLibraryItemContent = createAsyncThunk(
  'imageLibraryImages/getImageLibraryItemContent',
  (params: { imageLibraryItem: number }, thunkApi) => {
    try {
      thunkApi.dispatch(global.actions.isLoading(true));

      return network.get<string>({ key: 'get_image_library_item_content', params }, { signal: thunkApi.signal });
    } catch (exception) {
      return thunkApi.rejectWithValue(exception as ApiError);
    } finally {
      thunkApi.dispatch(global.actions.isLoading(false));
    }
  },
);

export const checkIfExists = createAsyncThunk(
  'imageLibraryImages/check',
  ({ businessUnit, ...rest }: ImageLibrarySearchArg, thunkApi) => {
    try {
      return network.get<ImageLibraryPayload>(
        { key: 'search_image_library_items_by_business_unit', params: { businessUnit: businessUnit, ...rest } },
        { signal: thunkApi.signal },
      );
    } catch (exception) {
      return thunkApi.rejectWithValue(exception as ApiError);
    }
  },
);

export const create = createAsyncThunk(
  'imageLibraryImages/create',
  async (
    { params, body, itemsPerPage }: { body: FormData; params: ImageLibraryCreateArg; itemsPerPage?: number },
    { signal, rejectWithValue, dispatch },
  ) => {
    try {
      const data = await network.post<Image>(
        { key: 'image_library_upload_by_business_unit', params: { businessUnit: params.businessUnit } },
        { signal, body },
      );

      return { data: data!, itemsPerPage };
    } catch (exception) {
      dispatch(global.actions.enqueueError(exception as ApiError));
      return rejectWithValue(exception as ApiError);
    }
  },
);
