import { NullSubscriberAuditItem } from '@modules';

import { useStyles } from './Subscriber.styles';
import { Col, Rec } from './Subscriber.types';
import { COLUMNS } from './Subscriber.const';

export const getColumns = (styles: ReturnType<typeof useStyles>): Col[] =>
  COLUMNS.map((column) => ({
    ...column,
    className: styles[column.id as keyof typeof styles] || styles.column,
  }));

export const itemToRecord = (item: NullSubscriberAuditItem, getEditValue: (id: number) => string): Rec => ({
  id: `${item.id}`,
  posn: item.position,
  name: item.name,
  edit: getEditValue(item.id),
});
