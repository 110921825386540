import { ReactElement } from 'react';
import { Typography, Trend } from '@components';
import { Divider, Grid, Typography as MuiTypography } from '@material-ui/core';

import { OverallMetricsDataSchema } from '@modules';
import { format } from '@utils';
import { content } from '@content';
import { useStyles } from './OverallSummary.styles';

export const OverallMetrics = ({
  data: {
    open_rate,
    opt_out,
    ctor,
    impressions_sent,
    total_modules,
    modules_variations,
    hard_bounce,
    open_rate_trends,
    opt_out_trends,
    ctor_trends,
  },
}: {
  data: OverallMetricsDataSchema;
}): ReactElement => {
  const styles = useStyles();
  const data = [
    { label: content.openRate, value: format.percent(open_rate), trend: open_rate_trends },
    { label: content.optOut, value: format.percent(opt_out), trend: opt_out_trends },
    { label: `${content.ctor} %`, value: format.percent(ctor), trend: ctor_trends },
    { label: content.impressionsSent, value: format.number(impressions_sent) },
    { label: content.totalModulesLibraryProgram, value: format.number(total_modules) },
    { label: content.numberModulesVariations, value: format.number(modules_variations) },
    { label: content.hardBounce, value: format.number(hard_bounce) },
  ];
  return (
    <div className={styles.overallMetricsContainer}>
      {data.map((item) => (
        <div key={item.label}>
          <Grid container className={styles.gridContainer}>
            <Typography.Body className={styles.metricTitle}>{item.label}</Typography.Body>
            <MuiTypography className={styles.metricValue}>{item.value}</MuiTypography>
            <Grid container item className={styles.metricTrend}>
              {item.trend ? <Trend trend={item.trend} /> : null}
            </Grid>
          </Grid>
          <Divider />
        </div>
      ))}
    </div>
  );
};
