import { useEffect, useCallback, useState, useImperativeHandle, memo, forwardRef } from 'react';
import { Box, MenuItem, Button, SwitchProps, SelectProps } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';

import { Typography, Toggle, Select } from '@components';
import { content } from '@content';
import { experience } from '@modules';
import { useAppDispatch } from '@store';
import { JourneyLibraryList } from './JourneyLibraryList';
import { useStyles } from './JourneyLibrary.styles';
import { JourneyLibraryProps, JourneyLibraryQuery } from './JourneyLibrary.types';

const JourneyLibraryWithRef = forwardRef(
  ({ disabled = false, selectJourney, addJourney, businessUnit }: JourneyLibraryProps, ref): JSX.Element => {
    const styles = useStyles();
    const [query, setQuery] = useState<JourneyLibraryQuery>({
      page: 1,
      lastUsed: 0,
      sortBy: 'created',
    });
    const dispatch = useAppDispatch();

    const loadMoreJourney = useCallback(() => {
      setQuery((prevQuery) => ({
        ...prevQuery,
        page: prevQuery.page + 1,
      }));
    }, []);

    const onToggleLastUsed: SwitchProps['onChange'] = (e, checked) => {
      setQuery((prevQuery) => ({
        ...prevQuery,
        page: 1,
        lastUsed: checked ? 1 : 0,
      }));
    };

    const onChangeSortBy: SelectProps['onChange'] = (event) => {
      setQuery((prevQuery) => ({
        lastUsed: prevQuery.lastUsed,
        page: 1,
        sortBy: event.target.value as 'author' | 'created' | 'updated' | 'lastUsed',
      }));
    };

    useImperativeHandle(
      ref,
      () => ({
        setQuery,
      }),
      [],
    );

    useEffect(() => {
      if (!businessUnit) {
        return undefined;
      }
      const promise = dispatch(
        experience.thunk.getList({
          businessUnit,
          page: query.page,
          lastUsed: query.lastUsed,
          sortBy: query.sortBy,
        }),
      );

      return () => {
        promise.abort();
      };
    }, [businessUnit, dispatch, query]);

    useEffect(
      () => () => {
        setQuery(() => ({
          page: 1,
          lastUsed: 0,
          sortBy: 'created',
        }));
        dispatch(experience.actions.resetListData());
      },
      [dispatch],
    );

    return (
      <Box className={`JourneyLibrary ${styles.journeyLibrary}`}>
        <Box className={styles.header}>
          <Typography.Title noWrap className={styles.title}>
            {content.moduleGroupLibrary}
          </Typography.Title>
          <Toggle
            labelLeft={content.all}
            labelRight={content.lastUsed}
            className={styles.toggle}
            checked={!!query.lastUsed}
            onChange={onToggleLastUsed}
          />
          <Select
            value={query.sortBy}
            uiType="tertiary"
            placeholder={content.sortBy}
            className={styles.select}
            onChange={onChangeSortBy}
          >
            <MenuItem value="author">{content.author}</MenuItem>
            <MenuItem value="created">{content.created}</MenuItem>
            <MenuItem value="updated">{content.edited}</MenuItem>
            <MenuItem value="lastUsed">{content.lastUsed}</MenuItem>
          </Select>
          <Button variant="contained" color="secondary" onClick={addJourney} disabled={disabled}>
            {content.addModuleGroup}
          </Button>
        </Box>
        <Droppable droppableId="journeyLibraryWrapper" type="DEPLOYMENT_POSITION">
          {(provided) => (
            <Box
              id="journeyListWrapper"
              className={styles.journeyListWrapper}
              {...{ ref: provided.innerRef }}
              {...provided.droppableProps}
            >
              <JourneyLibraryList selectJourney={selectJourney} loadMore={loadMoreJourney} />
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    );
  },
);

JourneyLibraryWithRef.displayName = 'JourneyLibraryWithRef';

export const JourneyLibrary = memo(JourneyLibraryWithRef);
