import { useEffect, useCallback, useState, useMemo, useContext } from 'react';
import { Box, IconButton } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { Controller, useForm } from 'react-hook-form';

import { Icon, TextField, Typography, UploadImageModal } from '@components';
import { content } from '@content';
import { imageLibrary } from '@modules';
import { useHistory, useLoader } from '@utils';
import { useAppDispatch } from '@store';
import { TreatmentBuilderContext, getConvertedImagesByWidth } from '@routes';

import { ImageLibraryPanelProps } from './ImageLibraryPanel.props';
import { useStyles } from './ImageLibraryPanel.styles';
import { SearchFormValues, UploadFormValues } from './ImageLibraryPanel.types';
import { DEFAULT_PANEL_IMAGE_WIDTH, RUN_SEARCH_DELAY } from './ImageLibraryPanel.const';

/**
 ImageLibraryPanel component
 @returns {JSX.Element}
 */

export const ImageLibraryPanel = ({
  chosenImage,
  isImageLibrary = false,
  onChooseImage,
}: ImageLibraryPanelProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { businessUnit } = useHistory().query;
  const { chosenModuleElemId } = useContext(TreatmentBuilderContext);

  const [chosenImageId, setChosenImageId] = useState('');
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);

  const { control, setValue } = useForm<SearchFormValues>({
    defaultValues: { keyword: '' },
  });
  const searchKeyword = imageLibrary.useSearchQuery();

  const libraryImages = imageLibrary.useAllData();
  const images = useMemo(
    () => getConvertedImagesByWidth(libraryImages.items, DEFAULT_PANEL_IMAGE_WIDTH),
    [libraryImages.items],
  );

  // useLoader(imageLibrary.useAllMeta(), imageLibrary.useEntityMeta());

  const getImages = useCallback(
    (keyword: string) => {
      if (businessUnit) {
        setValue('keyword', keyword);

        return dispatch(imageLibrary.thunk.search({ keyword, businessUnit, page: 1 }));
      }
    },
    [dispatch, setValue, businessUnit],
  );

  const runSearch = debounce(getImages, RUN_SEARCH_DELAY);

  const handleKeywordChange = useCallback(
    (keyword: string) => {
      dispatch(imageLibrary.actions.resetLibrary());
      setValue('keyword', keyword);

      runSearch(keyword);
    },
    [setValue, runSearch],
  );

  const handleUploadImageModalOpen = useCallback(() => {
    setShowUploadImageModal(true);
  }, [setShowUploadImageModal]);

  const handleUploadImageModalClose = useCallback(() => {
    setShowUploadImageModal(false);
  }, [setShowUploadImageModal]);

  const handleCreateImage = async (file: File | null, businessUnitItem: number) => {
    const body = new FormData();

    body.append('file', file as Blob);

    const result = await dispatch(imageLibrary.thunk.create({ params: { businessUnit: businessUnitItem }, body }));

    if (result.payload && imageLibrary.thunk.create.fulfilled.match(result)) {
      handleUploadImageModalClose();
    }
  };

  const handleFormSubmit = useCallback(
    async (values: UploadFormValues) => {
      const promises = [];

      if (businessUnit && Array.isArray(values.files)) {
        for (const file of values.files) {
          promises.push(handleCreateImage(file, businessUnit));
        }
      } else if (businessUnit && !Array.isArray(values.files)) {
        promises.push(handleCreateImage(values.files, businessUnit));
      }

      await Promise.all(promises);
    },
    [handleCreateImage, businessUnit],
  );

  const handleChooseImage = useCallback(
    (item) => (e: any) => {
      const chosenElem = e.target;
      const linkParent = chosenElem.parentElement?.tagName === 'A' ? chosenElem.parentElement : null;
      const linkValue = linkParent?.getAttribute('href') ?? linkParent?.getAttribute('temphref') ?? '';
      const altTextValue = linkParent?.getAttribute('title') ?? '';

      setChosenImageId(chosenElem.id);
      onChooseImage({
        id: item.id,
        chosenModuleElemId: chosenModuleElemId,
        width: isImageLibrary ? item.width : Math.round(item.previewWidth * 0.5),
        height: isImageLibrary ? item.height : Math.round(item.previewHeight * 0.5),
        imageSrc: item.path,
        name: item.name,
        link: linkValue,
        altText: altTextValue,
      });
    },
    [chosenModuleElemId, onChooseImage],
  );

  useEffect(() => {
    const curImage = images.find((image) => image.path === chosenImage?.imageSrc);

    setChosenImageId((curImage?.id ?? '').toString());
  }, [chosenImage]);

  useEffect(() => {
    const promise = getImages(searchKeyword ?? '');

    return () => promise?.abort();
  }, [dispatch, getImages, searchKeyword]);

  return (
    <Box className={styles.imageLibraryPanel}>
      {!isImageLibrary && (
        <Box className={styles.header}>
          <Controller
            name="keyword"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                className={styles.search}
                size="small"
                inputRef={ref}
                placeholder={content.search}
                onChange={handleKeywordChange}
                icon="SearchOutline"
              />
            )}
          />
          <IconButton className={styles.uploadBtn} onClick={handleUploadImageModalOpen}>
            <Icon.CloudUploadOutline />
          </IconButton>
          {showUploadImageModal && (
            <UploadImageModal onSubmit={handleFormSubmit} onClose={handleUploadImageModalClose} />
          )}
        </Box>
      )}
      <Box className={styles.content}>
        {images.map((item, index) => (
          <Box
            key={`${item.name} ${index}`}
            data-chosen={chosenImageId === String(item.id)}
            className={styles.imageWrapper}
          >
            <div
              id={String(item.id)}
              className={styles.image}
              onClick={handleChooseImage(item)}
              style={{ backgroundImage: `url(${item.path})` }}
            />
            <Box className={styles.imageContent}>
              <Box className={styles.moduleRow}>
                <Typography.Headline className={styles.name}>{item.name}</Typography.Headline>
              </Box>
              <Box className={styles.moduleRow}>
                <Typography.SmallCaption className={styles.index}>
                  {`${Math.round(item.width)}x${Math.round(item.height)}`}
                </Typography.SmallCaption>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
