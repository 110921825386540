import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    topDeployments: {
      display: 'flex',
      flexDirection: 'column',
    },

    head: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    viewAll: {
      width: '8.9rem',
    },

    body: {
      display: 'flex',
      marginTop: variables.gap.xl,
    },

    inDevelopment: {
      width: '30%',
    },

    inMarket: {
      width: '70%',
    },
  }),
);
