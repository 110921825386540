import React from 'react';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { Collapse, Panel, Switch } from '@components';
import { func } from '@utils';

import { ModuleField, ModuleFieldValue } from './ModuleField';

import { ModuleStationField, ModuleStationProps } from './ModuleStation.props';
import { useStyles } from './ModuleStation.styles';

export const ModuleStation = ({
  className = '',
  title,
  innerKey = '',
  collapsed = false,
  switchable = false,
  viewable = false,
  disabled = false,
  readonly = false,
  wideBody = false,
  saveVerticalSpace = false,
  fields,
  extras: extrasInit,
  onChange,
  onError,
  onSwitch,
  onCopy,
}: ModuleStationProps): JSX.Element | null => {
  const styles = useStyles();

  const [open, setOpen] = React.useState(!collapsed && !!fields);

  const switched = React.useMemo(() => !!fields, [fields]);

  const fieldsOld = React.useRef(fields);

  const extras = React.useMemo(() => extrasInit?.filter((field) => !field.rootKey), [extrasInit]);

  const groups = React.useMemo(
    () =>
      extrasInit
        ?.filter((field) => field.rootKey)
        ?.reduce((grps, field) => {
          const index = field.rootKey as unknown as number;
          const grp = grps[index] || (grps[index] = []); // eslint-disable-line
          grp.push(field);

          return grps;
        }, [] as ModuleStationField[][]),
    [extrasInit],
  );

  const handleSwitch = React.useCallback(
    (value) => {
      if (onSwitch) {
        onSwitch(value, innerKey);
      }
    },
    [onSwitch, innerKey],
  );

  const handleFieldChange = React.useCallback(
    (id, value, key) => {
      if (onChange) {
        onChange({ [id]: value || null }, key);
      }
    },
    [onChange],
  );

  const renderField = React.useCallback(
    (field: ModuleStationField, index) => (
      <ModuleField
        className={`${styles.field} ${field.className || ''}`}
        key={field.id || `empty.${index}`}
        rootKey={field.rootKey || innerKey}
        id={field.id}
        type={field.type}
        style={field.style}
        wide={field.wide}
        disabled={field.disabled || disabled}
        placeholder={readonly ? '' : field.placeholder}
        readonly={readonly}
        label={field.label}
        value={(field.value as ModuleFieldValue) || ''}
        query={field.query}
        options={field.options}
        clipboardCopy={field.clipboardCopy}
        onChange={field.disabled || disabled ? func.nop : handleFieldChange}
        onError={onError || func.nop}
        onCopy={onCopy || func.nop}
      />
    ),
    [onError, onCopy, handleFieldChange, innerKey, styles.field, disabled, readonly],
  );

  React.useEffect(() => {
    if (!!fieldsOld.current !== !!fields) {
      fieldsOld.current = fields;
      setOpen(!collapsed && !!fields);
    }
  }, [setOpen, fieldsOld, fields, collapsed]);

  return viewable ? (
    <Collapse
      className={className}
      title={title}
      headChildren={
        switchable
          ? [
              {
                position: 'center',
                content: (
                  <Switch
                    labels={{ off: content.no, on: content.yes }}
                    value={switched}
                    disabled={disabled || readonly}
                    onChange={disabled || readonly ? func.nop : handleSwitch}
                  />
                ),
              },
            ]
          : []
      }
      open={open}
      disabled={!fields || disabled}
      skipBodyInnerGap={wideBody}
      saveVerticalSpace={saveVerticalSpace}
      onToggle={disabled ? func.nop : setOpen}
    >
      {fields && fields.length > 0 && (
        <Box className={styles.fields} data-type={innerKey}>
          {fields.map(renderField)}
        </Box>
      )}
      {extras && extras.length > 0 && (
        <>
          <Box className={styles.extras}>{extras.map(renderField)}</Box>
          {groups && groups.length > 0 && (
            <Box className={styles.groups}>
              {groups.map((group, index) => (
                <Panel className={styles.group} key={index}>
                  {' '}
                  {/* eslint-disable-line */}
                  <Box className={styles.groupFields}>{group.map(renderField)}</Box>
                </Panel>
              ))}
            </Box>
          )}
        </>
      )}
    </Collapse>
  ) : null;
};
