import { SVGProps } from 'react';

export const PartlySunny = ({
  width = 24,
  height = 24,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.24734 14.3836C4.40401 14.3593 4.54902 14.2862 4.66172 14.1747C4.77442 14.0631 4.84905 13.9189 4.875 13.7625C5.44172 10.3205 7.95141 8.25 10.875 8.25C13.5905 8.25 15.4041 10.0195 16.1344 11.8941C16.1813 12.0142 16.2587 12.1201 16.359 12.2012C16.4593 12.2823 16.579 12.3359 16.7062 12.3567C19.05 12.7387 21 14.3039 21 17.025C21 19.8094 18.7219 21.75 15.9375 21.75H4.96875C2.64844 21.75 0.75 20.5922 0.75 18.0375C0.75 15.7655 2.56266 14.6522 4.24734 14.3836V14.3836Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M18.0375 12.7219C18.496 12.3683 18.8663 11.9133 19.1196 11.3926C19.3728 10.872 19.5021 10.2997 19.4972 9.72074C19.4923 9.14176 19.3534 8.57178 19.0914 8.05546C18.8294 7.53914 18.4514 7.0905 17.987 6.74469C17.5226 6.39889 16.9844 6.16532 16.4147 6.06228C15.8449 5.95924 15.259 5.98953 14.703 6.15078C14.1469 6.31203 13.6357 6.59985 13.2094 6.9917C12.7832 7.38355 12.4534 7.86878 12.2461 8.40937M21.75 9.75H23.25M15.75 2.25V3.75M10.4465 4.44656L11.5073 5.50734M21.0534 4.44656L19.9926 5.50734"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
