import { memo, useMemo, useEffect, useState, useCallback, MouseEvent } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';

import { Icon, Typography } from '@components';
import { content } from '@content';
import { BetweenField, TimesField, DateField, BetweenFieldValue } from '@views';
import { format, func } from '@utils';
import { Mindset, Condition as ConditionModel } from '@modules';
import { ExperienceMindsetCondition } from '@routes';
import { variables } from '@styles';

import { Condition, ArgumentType, ConditionSignalValue, RequiredField, SIGNAL_ARGUMENTS } from './Condition';
import { MetCondition } from './MetCondition';
import { ConditionValue } from './ConditionValue';

import { useStyles } from './Conditions.styles';
import { SignalType } from './Conditions.types';
import { FieldsLabel } from './Conditions.const';
import { ConditionsProps } from './Conditions.props';

/**
 * Conditions component
 * @returns {JSX.Element}
 */

export const Conditions = memo(
  ({ uiType = 'signal', mindsetIndex, conditionsError, onChange = func.nop, onConditionsChange }: ConditionsProps) => {
    const styles = useStyles();
    const { trigger, setValue, watch, setError, clearErrors, formState } = useFormContext();
    const [errorMsg, setErrorMsg] = useState('REARERAER');

    const mindsetKey = useMemo(() => `mindsets.${mindsetIndex}`, [mindsetIndex]);
    const itemKey = useMemo(() => `${mindsetKey}.condition`, [mindsetKey]);
    const itemSignalKey = useMemo(() => `${itemKey}.signal`, [itemKey]);
    const itemOperatorKey = useMemo(() => `${itemKey}.operator`, [itemKey]);
    const itemValueKey = useMemo(() => `${itemKey}.value`, [itemKey]);
    const listKey = useMemo(() => `${mindsetKey}.conditions`, [mindsetKey]);
    const getListItemKey = useCallback((index: number) => `${listKey}.${index}`, [listKey]);
    const getListItemSignalKey = useCallback((index: number) => `${getListItemKey(index)}.signal`, [getListItemKey]);
    const getListItemOperatorKey = useCallback(
      (index: number) => `${getListItemKey(index)}.operator`,
      [getListItemKey],
    );
    const getListItemValueKey = useCallback((index: number) => `${getListItemKey(index)}.value`, [getListItemKey]);
    const getListItemRequiredKey = useCallback(
      (index: number) => `${getListItemKey(index)}.required`,
      [getListItemKey],
    );
    const metKey = useMemo(() => `${mindsetKey}.metConditionCount`, [mindsetKey]);

    const conditionsInit = watch(listKey);
    const conditions: ExperienceMindsetCondition[] = useMemo(() => conditionsInit || [], [conditionsInit]);
    const condition: ExperienceMindsetCondition = watch(itemKey);
    const met = watch(metKey) || 0;

    useEffect(() => {
      if (!condition) {
        setValue(itemKey, {
          signal: {
            id: undefined,
          },
          operator: '',
          value: '',
          required: false,
        });
      }
    }, [setValue, condition, itemKey]);

    useEffect(() => {
      const allCount = conditions.length;
      const requiredCount = conditions.filter((item) => item.required).length;

      if (met < requiredCount) {
        setValue(metKey, requiredCount);
      } else if (met > allCount) {
        setValue(metKey, allCount);
      }
    }, [setValue, conditions, met, metKey]);

    const validate = useCallback(() => {
      const itemSubKeys = [itemSignalKey, itemOperatorKey, itemValueKey];

      if (conditions && !conditions.length && !condition.value) {
        itemSubKeys.forEach((subKey) => setError(subKey, { type: 'value' }));
      }

      return trigger(itemSubKeys);
    }, [trigger, setError, itemSignalKey, itemOperatorKey, itemValueKey, conditions, condition]);

    useEffect(() => {
      if (formState.errors && !formState.isValid && conditions && !conditions.length) {
        const fieldsWithErrors = (formState.errors.mindsets as any)?.filter((i: Mindset) => i)[0]?.condition;
        if (fieldsWithErrors) {
          const msg = Object.keys(fieldsWithErrors).reduce((acc, key) => `${acc}• ${FieldsLabel[key]} \n`, '');
          setErrorMsg(msg);
        } else {
          setErrorMsg('');
        }
      } else {
        setErrorMsg('');
      }
    }, [formState, conditions]);

    const clearErrorMsg = () => {
      setErrorMsg('');
      clearErrors();
    };

    const handleConditionAdd = useCallback(async () => {
      const isValid = await validate();

      if (isValid && condition.signal?.label) {
        setValue(listKey, conditions.concat(condition));

        setValue(itemKey, {
          signal: undefined,
          operator: '',
          value: '',
          required: false,
        });

        onChange(conditions.concat(condition));
      }
    }, [onChange, validate, setValue, condition, conditions, listKey, itemKey]);

    const deleteCondition = (event: MouseEvent<HTMLButtonElement>) => {
      const index = Number(event.currentTarget.dataset.index);

      const nextConditions = [...conditions];

      nextConditions.splice(+index, 1);

      setValue(listKey, nextConditions);

      const lastConditionIndex = conditions.length - 1;

      if (met > lastConditionIndex) {
        setValue(metKey, lastConditionIndex);
      }

      onChange();
    };

    useEffect(() => {
      if (onConditionsChange) {
        onConditionsChange(conditions);
      }
    }, [conditions]);

    return (
      <Box>
        <Typography.Title className={styles.conditionsText}>{content.rules}</Typography.Title>
        <Box className={styles.content}>
          <Box className={styles.mindsetMatch}>
            <Typography.Body className={styles.metConditionText}>{content.when}</Typography.Body>
            <MetCondition defaultValue={met} mindsetIndex={mindsetIndex} onChange={onChange} />
            <Typography.Body className={styles.metConditionText}>{content.followingAreMet}</Typography.Body>
          </Box>
          <Box>
            {conditions.length > 0 ? (
              <Box className={styles.conditions}>
                {conditions
                  .filter((item) => !!item)
                  .map((item, index) => (
                    <Box key={item.id} className={styles.ruleContainer}>
                      <Box className={styles.row}>
                        <ConditionValue
                          type="signal"
                          value={item.signal?.label || content.addOrDragAttribute}
                          className={styles.draggableContainer}
                          onSave={onChange}
                        >
                          <Box className={styles.signalBox}>
                            <Droppable droppableId={getListItemSignalKey(index)} type="SIGNAL">
                              {(provided) => (
                                <Box
                                  className={styles.signalFieldContainer}
                                  {...{ ref: provided.innerRef }}
                                  {...provided.droppableProps}
                                >
                                  <Box className={styles.signalField} data-signal={!!item.signal}>
                                    {item.signal?.label ? (
                                      <>
                                        <Typography.Body
                                          className={styles.inputText}
                                          title={item.signal?.label}
                                          display="inline"
                                          noWrap
                                          component="span"
                                        >
                                          {item.signal?.label}
                                        </Typography.Body>
                                      </>
                                    ) : (
                                      <Typography.Body className={styles.inputText}>
                                        {content.addOrDragAttribute}
                                      </Typography.Body>
                                    )}
                                  </Box>
                                  {provided.placeholder}
                                </Box>
                              )}
                            </Droppable>
                          </Box>
                        </ConditionValue>
                        <ConditionValue
                          className={styles.operator}
                          value={
                            (item.signal &&
                              SIGNAL_ARGUMENTS[item.signal.type as SignalType]?.find(
                                (argument: ArgumentType) => argument.value === item.operator,
                              )?.label) ||
                            'Condition'
                          }
                          options={
                            item.signal?.type
                              ? SIGNAL_ARGUMENTS[item.signal?.type as SignalType].map((argument) => ({
                                  id: argument.label,
                                  label: argument.label,
                                  value: argument.value,
                                }))
                              : []
                          }
                          onSave={(newValue) => {
                            setValue(getListItemOperatorKey(index), newValue, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            onChange();
                          }}
                        />
                        {item.signal?.signalType === 'statistic' && item.operator === 'between' && (
                          <ConditionValue value={item.value ? item.value : content.enterAValue} onSave={onChange}>
                            <BetweenField
                              mindsetIndex={mindsetIndex}
                              defaultValue={item.value as unknown as BetweenFieldValue}
                              fieldName={getListItemValueKey(index)}
                            />
                          </ConditionValue>
                        )}
                        {item.signal?.signalType === 'statistic' && item.operator === '=' && (
                          <ConditionValue value={item.value ? item.value : content.enterAValue} onSave={onChange}>
                            <TimesField
                              defaultValue={item.value}
                              mindsetIndex={mindsetIndex}
                              fieldName={getListItemValueKey(index)}
                            />
                          </ConditionValue>
                        )}
                        {item.signal?.signalType === 'statistic' &&
                          ['on', 'before', 'after'].includes(item.operator) && (
                            <ConditionValue value={item.value ? item.value : content.enterAValue} onSave={onChange}>
                              <DateField
                                mindsetIndex={mindsetIndex}
                                operator={item.operator}
                                fieldName={getListItemValueKey(index)}
                              />
                            </ConditionValue>
                          )}
                        {item.signal?.type && item.signal?.signalType !== 'statistic' && (
                          <ConditionValue
                            value={
                              item.value
                                ? typeof item.value === 'object'
                                  ? item.value
                                  : format.capitalize(item.value)
                                : content.enterAValue
                            }
                            onSave={onChange}
                          >
                            <ConditionSignalValue
                              defaultValue={item.value}
                              freeSolo={item.signal?.type === 'numeric'}
                              signalId={item.signal?.id}
                              disabled={!item.signal}
                              mindsetIndex={mindsetIndex}
                              fieldName={getListItemValueKey(index)}
                            />
                          </ConditionValue>
                        )}
                        <ConditionValue value={item.required} type="required" onSave={onChange}>
                          <RequiredField
                            mindsetIndex={mindsetIndex}
                            fieldName={getListItemRequiredKey(index)}
                            conditionIndex={index}
                          />
                        </ConditionValue>
                      </Box>
                      <IconButton data-index={index} onClick={deleteCondition}>
                        <Icon.TrashOutline width={18} height={18} />
                      </IconButton>
                    </Box>
                  ))}
              </Box>
            ) : (
              <Box
                className={styles.emptyConditions}
                style={{
                  borderColor: !conditionsError ? variables.color.primary.lightGray : variables.color.primary.red,
                }}
              >
                <Icon.PartlySunny color={variables.color.primary.mediumGray} />
                <Typography.Body className={styles.bodyText}>{content.youDontHaveAnyConditionAdded}</Typography.Body>
                <Typography.Body>{content.addASetOfConditions}</Typography.Body>
              </Box>
            )}
          </Box>
          {!!conditionsError && <div className={styles.conditionError}>{conditionsError}</div>}
          <Box className={styles.conditionsList}>
            {condition && (
              <Condition
                className={styles.condition}
                uiType={uiType}
                condition={condition as ConditionModel}
                onAdd={handleConditionAdd}
                mindsetIndex={mindsetIndex}
                error={errorMsg}
                onErrorClose={clearErrorMsg}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

Conditions.displayName = 'Conditions';
