import { createAsyncThunk } from '@store';
import { network } from '@utils';
import { DeploymentStatus, ApiError } from '@modules';
import { page } from '@content';

import { ClientDeploymentData, ClientDeploymentPartData } from './ClientDeployments.schema';

export const getAll = createAsyncThunk('businessUnitDeployments/getAll', (businessUnit: number, { signal }) =>
  network.get<ClientDeploymentPartData>(
    {
      key: 'get_business_unit_deployments',
      params: {
        businessUnit: businessUnit,
        status: [DeploymentStatus.preDeployment, DeploymentStatus.inMarket, DeploymentStatus.archived],
        deploymentNameSort: 'ASC',
        paginated: false,
      },
    },
    { signal },
  ),
);

export const getNonArchived = createAsyncThunk(
  'businessUnitDeployments/getNonArchived',
  async (businessUnit: number, { signal, extra, rejectWithValue }) => {
    try {
      return await network.get<ClientDeploymentPartData>(
        {
          key: 'get_business_unit_deployments',
          params: {
            businessUnit: businessUnit,
            status: [DeploymentStatus.preDeployment, DeploymentStatus.inMarket],
            deploymentNameSort: 'ASC',
            paginated: false,
          },
        },
        { signal },
      );
    } catch (exception) {
      const error = exception as ApiError;

      if (`${error.code}` === '403') {
        extra.history.push(page.dashboard);
      }
      return rejectWithValue(error);
    }
  },
);

export const getPreDeployment = createAsyncThunk(
  'businessUnitDeployments/getPreDeployment',
  (businessUnit: number, { signal }) =>
    network.get<ClientDeploymentData>(
      {
        key: 'get_business_unit_deployments',
        params: {
          businessUnit: businessUnit,
          status: [DeploymentStatus.preDeployment],
          page: 1,
          itemsPerPage: 6,
        },
      },
      { signal },
    ),
);

export const getInMarket = createAsyncThunk('businessUnitDeployments/getInMarket', (businessUnit: number, { signal }) =>
  network.get<ClientDeploymentData>(
    {
      key: 'get_business_unit_deployments',
      params: {
        businessUnit: businessUnit,
        status: [DeploymentStatus.inMarket],
        page: 1,
        itemsPerPage: 6,
      },
    },
    { signal },
  ),
);

export const getInMarketSortByDate = createAsyncThunk(
  'businessUnitDeployments/getAllSortByDate',
  (businessUnit: number, { signal }) =>
    network.get<ClientDeploymentPartData>(
      {
        key: 'get_business_unit_deployments',
        params: {
          businessUnit: businessUnit,
          status: [DeploymentStatus.inMarket],
          deploymentDateSort: 'DESC',
          paginated: false,
        },
      },
      { signal },
    ),
);
