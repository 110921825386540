export const generateSplitterId = (() => {
  let id = 0;

  return () => {
    id += 1;

    return id.toString();
  };
})();

export const fixFrameScale = (frame: HTMLIFrameElement): number => {
  let scale = 1;

  const win = frame.contentWindow;

  if (win) {
    const { documentElement: doc } = win.document;
    const { innerWidth: winWidth } = win;
    const { scrollWidth: docWidth, style: docStyle } = doc;

    if (docWidth > winWidth) {
      scale = winWidth / docWidth;

      docStyle.transformOrigin = 'left top';
      docStyle.transform = `scale(${scale})`;
    }
  }

  return scale;
};
