import { useState, ChangeEvent, useEffect, useCallback, memo, KeyboardEvent, useMemo } from 'react';
import { Box, TextField } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Typography, Icon } from '@components';
import { content } from '@content';
import { experience, ExperienceSearchArgs } from '@modules';
import { useLoader } from '@utils';
import { useAppDispatch } from '@store';
import { variables } from '@styles';

import { ModulesCard } from './ModulesCard';

import { useStyles } from './ModuleGroups.styles';
import { ModuleGroupsProps } from './ModuleGroups.types';
import { INITIAL_QUERY } from './ModuleGroups.const';
import { messageReceiver } from '@utils/network/broadcast';

/**
 * ModuleGroups component.
 * @returns {JSX.Element}
 */
export const ModuleGroups = memo(
  ({ businessUnitId, onModulesDrag, usedExperiences }: ModuleGroupsProps): JSX.Element => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const [query, setQuery] = useState<ExperienceSearchArgs>({
      ...INITIAL_QUERY,
      businessUnitId,
    });
    const [keyword, setKeyword] = useState('');
    const { items, currentPage, pageCount, total } = experience.useListData();
    const { status, error } = experience.useListMeta();
    const itemsToShow = useMemo(
      () =>
        usedExperiences
          ? items.filter((el) => {
              return usedExperiences?.every((id) => id !== el.id);
            })
          : items,
      [items, usedExperiences],
    );

    // useLoader(experience.useListMeta());

    const loadMoreExperiences = useCallback(() => {
      setQuery((prevQuery) => ({
        ...prevQuery,
        page: prevQuery.page ? prevQuery.page + 1 : 1,
      }));
    }, []);

    useEffect(() => {
      if (!businessUnitId) {
        return undefined;
      }

      const promise = dispatch(experience.thunk.search(query));

      return () => {
        promise.abort();
      };
    }, [businessUnitId, dispatch, query]);

    useEffect(() => {
      messageReceiver.onmessage = async (event) => {
        setQuery((prevState) => {
          dispatch(experience.thunk.search({ ...prevState, page: 1 }));
          return { ...prevState, page: 1 };
        });
      };
    }, [messageReceiver, setQuery]);

    useEffect(() => {
      return () => {
        // messageReceiver.close();
        dispatch(experience.actions.resetListData());
      };
    }, [messageReceiver]);

    const handleChangeKeyword = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => setKeyword(event.target.value),
      [setKeyword],
    );

    const handleSearch = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          setQuery((prevState) => ({
            ...prevState,
            searchTerm: keyword,
          }));
        }
      },
      [keyword],
    );

    return (
      <Box className={styles.moduleGroups}>
        <Box className={styles.content}>
          <Box className={styles.searchBar}>
            <TextField
              classes={{
                root: styles.search,
              }}
              fullWidth
              variant="standard"
              placeholder={content.search}
              value={keyword}
              onChange={handleChangeKeyword}
              onKeyPress={handleSearch}
              InputProps={{
                disableUnderline: true,
                endAdornment: <Icon.SearchOutline stroke={variables.color.primary.mediumGray} />,
              }}
            />
          </Box>
          {total > 0 && (
            <Box className={styles.list} id="moduleGroupsList">
              <InfiniteScroll
                hasMore={currentPage < pageCount}
                next={loadMoreExperiences}
                dataLength={items.length}
                loader=""
                scrollableTarget="moduleGroupsList"
              >
                {itemsToShow.map((item) => (
                  <ModulesCard
                    createdOn={item.createdAt}
                    experienceId={item.id}
                    key={item.id}
                    name={item.name}
                    onModulesDrag={onModulesDrag}
                    coverImages={item.modules.filter((module) => module.coverImage).map((module) => module.coverImage)}
                  />
                ))}
              </InfiniteScroll>
            </Box>
          )}
          {status === 'loading' && query.page === 1 && (
            <Typography.Body align="center">{content.loading}...</Typography.Body>
          )}
          {status === 'error' && (
            <Typography.Body align="center">
              {error?.message ? error.message : content.failedToLoadItems}
            </Typography.Body>
          )}
        </Box>
      </Box>
    );
  },
);

ModuleGroups.displayName = 'ModuleGroups';
