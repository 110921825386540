import { memo } from 'react';
import { Box } from '@material-ui/core';

import { global } from '@modules';

import { FullPageLoaderProps } from './FullPageLoader.props';
import { useStyles } from './FullPageLoader.styles';
import { Loader } from '@components/Loader';

export const FullPageLoader = memo(({ blockRender = false }: FullPageLoaderProps): JSX.Element | null => {
  const styles = useStyles();
  const isLoading = global.useIsLoading();

  return blockRender || isLoading ? (
    <Box className={styles.fullPageLoader}>
      <Loader />
    </Box>
  ) : null;
});

FullPageLoader.displayName = 'FullPageLoader';
