import { base } from '@modules';

import { AnalyticsSchema } from './Analytics.schema';

export const initialState: AnalyticsSchema = {
  numberModulesPerPosition: {
    ...base.state,
    data: {
      average: 0,
      deployments: [],
    },
  },
  ctirPerPosition: {
    ...base.state,
    data: {
      average: 0,
      deploymentsData: [],
    },
  },
  positionVolume: {
    ...base.state,
    data: {
      average: 0,
      deploymentsCount: 0,
      deploymentsData: [],
    },
  },
  impressionByCohort: {
    ...base.state,
    data: [],
  },
  impressionByProduct: {
    ...base.state,
    data: [],
  },
  impressionByOverall: {
    ...base.state,
    data: [],
  },
  commonCtirByDeployment: {
    ...base.state,
    data: [],
  },
  summaryPerformance: {
    ...base.state,
    data: {
      total: 0,
      items: [],
    },
  },
  modulePerformanceOverTime: {
    ...base.state,
    data: {
      average: 0,
      deployments: [],
    },
  },
  moduleVolumeOverTime: {
    ...base.state,
    data: {
      average: 0,
      deploymentsData: [],
    },
  },
  clickToOpenReport: {
    ...base.state,
    data: {
      average: 0,
      deploymentsData: [],
      trend: 0,
    },
  },
  overallMetrics: {
    ...base.state,
    data: {
      ctor: 0,
      impressions_sent: 0,
      hard_bounce: 0,
      open_rate: 0,
      opt_out: 0,
      total_modules: 0,
      modules_variations: 0,
      open_rate_trends: 0,
      opt_out_trends: 0,
      ctor_trends: 0,
    },
  },
  clickRate: {
    ...base.state,
    data: {
      average: 0,
      deploymentsData: [],
    },
  },
  optOutRate: {
    ...base.state,
    data: {
      average: 0,
      deploymentsData: [],
      trend: 0,
    },
  },
  openRate: {
    ...base.state,
    data: {
      average: 0,
      deploymentsData: [],
      trend: 0,
    },
  },
  amriReport: {
    ...base.state,
    data: {
      currentMonthDeploymentValue: 0,
      previousMonthValue: 0,
    },
  },
  benchmarkingReportEnterprise: {
    ...base.state,
    data: {
      currentMonthCommonOpenRate: 0,
      currentMonthRefinedOpenRate: 0,
      currentMonthClickRate: 0,
      last12MonthsCommonOpenRate: 0,
      last12MonthsRefinedOpenRate: 0,
      last12MonthsClickRate: 0,
      openRateTrend: 0,
      clickRateTrend: 0,
    },
  },
  benchmarkingReportBU: {
    ...base.state,
    data: {
      currentMonthCommonOpenRate: 0,
      currentMonthRefinedOpenRate: 0,
      currentMonthClickRate: 0,
      last12MonthsCommonOpenRate: 0,
      last12MonthsRefinedOpenRate: 0,
      last12MonthsClickRate: 0,
      openRateTrend: 0,
      clickRateTrend: 0,
    },
  },
  topPerformingContent: {
    ...base.state,
    data: [],
  },
  lowPerformingContent: {
    ...base.state,
    data: [],
  },
};
