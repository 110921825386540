import { base } from '@modules';

import { ClientProgramsSchema } from './ClientPrograms.schema';

export const initialState: ClientProgramsSchema = {
  all: {
    ...base.state,
    data: [],
  },
  list: {
    ...base.state,
    data: {
      items: [],
      total: 0,
      currentPage: 1,
      pageCount: 1,
    },
  },
  item: {
    ...base.state,
    data: null,
  },
  entity: undefined,
};
