import 'react-app-polyfill/ie11';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Array<T> {
    excludeStrs(excludingStyles: string[]): string[];
  }
}

Array.prototype.excludeStrs = function (excludingStrs: string[]) {
  let array = this as string[];

  if (excludingStrs.length) {
    array = array.filter((elem) => !excludingStrs.some((style) => elem.includes(style)));
  }

  return array;
};
