import { DRAFT_HOLDER_MARKER_KEY_CC, DRAFT_HOLDER_WIDTH_RATIO, DRAFT_HOLDER_CELL_SPAN } from './TemplateUpload.const';
import { DraftSize } from './TemplateUpload.types';

const holderMarkerGenerator = () => {
  let id = 0;

  return () => {
    id += 1;

    return id.toString();
  };
};

const collectHoldersSiblings = (domList: HTMLCollection): HTMLElement[] => {
  const bros: HTMLElement[] = [];
  const elements = Array.from(domList) as HTMLElement[];

  const [firstElement, ...siblings] = elements;

  const parentWidth = firstElement?.parentElement?.offsetWidth || 1;
  const ratio = firstElement?.offsetWidth / parentWidth;
  const isElementWideEnough = ratio > DRAFT_HOLDER_WIDTH_RATIO;

  elements.forEach((element) => {
    bros.push(...collectHoldersSiblings(element.children));
  });

  if (isElementWideEnough && firstElement) {
    let { offsetTop: prevY } = firstElement;

    const rows = siblings.filter((element) => {
      const { offsetTop: y, offsetHeight: h, dataset } = element;
      const holderMarker = dataset[DRAFT_HOLDER_MARKER_KEY_CC];
      const isNewRow = !holderMarker && y > prevY && h !== 0;

      if (isNewRow) {
        prevY = y;
      }

      return isNewRow;
    });

    bros.push(...rows);
  }

  return bros;
};

const reduceNarrowSiblings = (bros: HTMLElement[]): HTMLElement[] => {
  const [widestBro] = [...bros].sort((a, b) => (a.offsetWidth > b.offsetWidth ? -1 : 1));
  const widestBroWidth = widestBro?.offsetWidth || 0;

  return bros.filter((bro) => bro.offsetWidth / widestBroWidth > DRAFT_HOLDER_WIDTH_RATIO);
};

const interrelateSiblingsWithHolders = (bros: HTMLElement[]): void => {
  const generateHolderMarker = holderMarkerGenerator();

  bros.forEach((element) => {
    const doc = element.ownerDocument;
    let mom = element.parentElement;
    let bro: typeof element | null = element;

    if (mom) {
      if (bro.tagName === 'TR') {
        const row = doc.createElement('tr');
        const col = doc.createElement('td');

        col.colSpan = DRAFT_HOLDER_CELL_SPAN;

        row.appendChild(col);
        mom.insertBefore(row, bro);

        bro = null;
        mom = col;
      }

      const holder = doc.createElement('div');

      holder.dataset[DRAFT_HOLDER_MARKER_KEY_CC] = generateHolderMarker();

      if (bro) {
        mom.insertBefore(holder, bro);
      } else {
        mom.appendChild(holder);
      }
    }
  });
};

export const fillDocWithPlaceholders = (doc: Document): void => {
  const siblings = collectHoldersSiblings(doc.body.children);
  const wideSiblings = reduceNarrowSiblings(siblings);

  interrelateSiblingsWithHolders(wideSiblings);
};

export const getDraftSize = (frameDoc: Document): DraftSize => {
  const { scrollWidth, scrollHeight } = frameDoc.body;

  return {
    width: scrollWidth,
    height: scrollHeight,
  };
};
