import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { AuthSchema } from './Auth.schema';
import { state as initialState } from './Auth.state';
import {
  login,
  logout,
  refresh,
  requestPasswordReset,
  resetPassword,
  acceptTerms,
  lastUsedClientUpdate,
} from './Auth.thunk';

export const reducers = {
  resetLastUsedClient: (state: AuthSchema) => {
    state.user.data.lastUsedClient = initialState.user.data.lastUsedClient;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<AuthSchema>): void => {
  builder.addCase(login.pending, (state, action) => {
    if (state.user.status !== 'loading') {
      state.user.requestId = action.meta.requestId;
      state.user.status = 'loading';
    }
  });

  builder.addCase(login.fulfilled, (state, action) => {
    if (state.user.status === 'loading' && state.user.requestId === action.meta.requestId) {
      state.user.requestId = initialState.user.requestId;
      state.user.status = 'success';
      state.user.data = action.payload?.user ?? initialState.user.data;
    }
  });

  builder.addCase(login.rejected, (state, action) => {
    if (state.user.status === 'loading' && state.user.requestId === action.meta.requestId) {
      state.user.requestId = initialState.user.requestId;
      state.user.status = 'error';
      state.user.error = action.error;
    }
  });

  builder.addCase(refresh.pending, (state, action) => {
    if (state.user.status !== 'loading') {
      state.user.requestId = action.meta.requestId;
      state.user.status = 'loading';
    }
  });

  builder.addCase(refresh.fulfilled, (state, action) => {
    if (state.user.status === 'loading' && state.user.requestId === action.meta.requestId) {
      state.user.requestId = initialState.user.requestId;
      state.user.status = 'success';
      state.user.data = action.payload?.user ?? initialState.user.data;
    }
  });

  builder.addCase(refresh.rejected, (state, action) => {
    if (state.user.status === 'loading' && state.user.requestId === action.meta.requestId) {
      state.user.requestId = initialState.user.requestId;
      state.user.status = 'error';
      state.user.error = action.error;
    }
  });

  builder.addCase(requestPasswordReset.pending, (state, action) => {
    if (state.pwdResetInit.status !== 'loading') {
      state.pwdResetInit.requestId = action.meta.requestId;
      state.pwdResetInit.status = 'loading';
    }
  });

  builder.addCase(requestPasswordReset.fulfilled, (state, action) => {
    if (state.pwdResetInit.status === 'loading' && state.pwdResetInit.requestId === action.meta.requestId) {
      state.pwdResetInit.requestId = initialState.pwdResetInit.requestId;
      state.pwdResetInit.status = 'success';
    }
  });

  builder.addCase(requestPasswordReset.rejected, (state, action) => {
    if (state.pwdResetInit.status === 'loading' && state.pwdResetInit.requestId === action.meta.requestId) {
      state.pwdResetInit.requestId = initialState.pwdResetInit.requestId;
      state.pwdResetInit.status = 'error';
      state.pwdResetInit.error = action.error;
    }
  });

  builder.addCase(resetPassword.pending, (state, action) => {
    if (state.pwdResetConfirm.status !== 'loading') {
      state.pwdResetConfirm.requestId = action.meta.requestId;
      state.pwdResetConfirm.status = 'loading';
    }
  });

  builder.addCase(resetPassword.fulfilled, (state, action) => {
    if (state.pwdResetConfirm.status === 'loading' && state.pwdResetConfirm.requestId === action.meta.requestId) {
      state.pwdResetConfirm.requestId = initialState.pwdResetConfirm.requestId;
      state.pwdResetConfirm.status = 'success';
    }
  });

  builder.addCase(resetPassword.rejected, (state, action) => {
    if (state.pwdResetConfirm.status === 'loading' && state.pwdResetConfirm.requestId === action.meta.requestId) {
      state.pwdResetConfirm.requestId = initialState.pwdResetConfirm.requestId;
      state.pwdResetConfirm.status = 'error';
      state.pwdResetConfirm.error = action.error;
    }
  });

  builder.addCase(acceptTerms.pending, (state, action) => {
    if (state.acceptTerms.status !== 'loading') {
      state.acceptTerms.requestId = action.meta.requestId;
      state.acceptTerms.status = 'loading';
    }
  });

  builder.addCase(acceptTerms.fulfilled, (state, action) => {
    if (state.acceptTerms.status === 'loading' && state.acceptTerms.requestId === action.meta.requestId) {
      state.acceptTerms.requestId = initialState.acceptTerms.requestId;
      state.acceptTerms.status = 'success';
      state.user.data.isAgreementAccepted =
        action.payload?.isAgreementAccepted ?? initialState.user.data.isAgreementAccepted;
    }
  });

  builder.addCase(acceptTerms.rejected, (state, action) => {
    if (state.acceptTerms.status === 'loading' && state.acceptTerms.requestId === action.meta.requestId) {
      state.acceptTerms.requestId = initialState.acceptTerms.requestId;
      state.acceptTerms.status = 'error';
      state.acceptTerms.error = action.error;
    }
  });

  builder.addCase(logout.pending, (state, action) => {
    if (state.user.status !== 'loading') {
      state.user.requestId = action.meta.requestId;
      state.user.status = 'loading';
    }
  });

  builder.addCase(logout.fulfilled, (state, action) => {
    if (state.user.status === 'loading' && state.user.requestId === action.meta.requestId) {
      state.user = initialState.user;
    }
  });

  builder.addCase(logout.rejected, (state, action) => {
    if (state.user.status === 'loading' && state.user.requestId === action.meta.requestId) {
      state.user.requestId = initialState.user.requestId;
      state.user.status = 'error';
      state.user.error = action.error;
    }
  });

  builder.addCase(lastUsedClientUpdate.pending, (state, action) => {
    if (state.lastUsedClientUpdate.status !== 'loading') {
      state.lastUsedClientUpdate.requestId = action.meta.requestId;
      state.lastUsedClientUpdate.status = 'loading';
    }
  });

  builder.addCase(lastUsedClientUpdate.fulfilled, (state, action) => {
    if (
      state.lastUsedClientUpdate.status === 'loading' &&
      state.lastUsedClientUpdate.requestId === action.meta.requestId
    ) {
      state.lastUsedClientUpdate.requestId = initialState.lastUsedClientUpdate.requestId;
      state.lastUsedClientUpdate.status = 'success';

      if (action.payload) {
        state.user.data = action.payload;
      }
    }
  });

  builder.addCase(lastUsedClientUpdate.rejected, (state, action) => {
    if (
      state.lastUsedClientUpdate.status === 'loading' &&
      state.lastUsedClientUpdate.requestId === action.meta.requestId
    ) {
      state.lastUsedClientUpdate.requestId = initialState.lastUsedClientUpdate.requestId;
      state.lastUsedClientUpdate.status = 'error';
      state.lastUsedClientUpdate.error = action.error;
    }
  });
};
