import { Controller, useForm } from 'react-hook-form';

import { content } from '@content';
import { Confirm, TextField, Typography } from '@components';

import { AddModuleGroupModalProps } from './AddModuleGroupModal.props';
import { FormValues } from './AddModuleGroupModal.types';

/**
 * AddModuleGroupModal component
 * @returns {JSX.Element}
 */

export const AddModuleGroupModal = ({ onSubmit, onClose }: AddModuleGroupModalProps): JSX.Element => {
  const defaultValues = {
    name: '',
  };

  const form = useForm<FormValues>({ defaultValues });

  return (
    <Confirm
      open
      title={content.moduleGroup}
      onCancel={onClose}
      onSubmit={form.handleSubmit(onSubmit)}
      submitLabel={content.save}
      skipSubmitArrow
    >
      <Typography.Caption>{content.pleaseSetNameForYourContentGroup}</Typography.Caption>
      <Controller
        name="name"
        control={form.control}
        rules={{ required: content.moduleGroupName }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            label={content.moduleGroupName}
            placeholder={content.moduleGroupName}
            hint={error?.message}
            error={invalid}
          />
        )}
      />
    </Confirm>
  );
};
