import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const size = (width: number, height: number = width): Record<string, string> => ({
  width: `${width}rem`,
  height: `${height}rem`,
  borderRadius: `${height / 2}rem`,
});

const color = (value: string) => ({
  backgroundColor: value,
  boxShadow: '0.1rem 0.1rem 0.7rem 0.4rem rgba(208, 208, 206, 0.3)',
  '&[data-highlighted="true"]': { boxShadow: `0 0 0.5rem ${value}` },
});

const badge = {
  flexShrink: 0,
  border: `${variables.borderWidth.sm} solid ${variables.color.primary.white}`,
};

const small = size(0.8);

const medium = size(2.7, 1.2);

const large = size(2.3, 1.2);

const blue = color(variables.color.primary.lightPurple);

const orange = color(variables.color.secondary.orange);

const gray = color(variables.color.secondary.gray);

const primaryBase = color(variables.color.primary.mainPurple);

const secondaryBlue = color(variables.color.secondary.blue);

const tertiaryGray = color(variables.color.tertiary.gray);

const transparent = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
};

export const useStyles = makeStyles(() =>
  createStyles({
    badge,
    small,
    medium,
    large,
    blue,
    orange,
    gray,
    primaryBase,
    secondaryBlue,
    tertiaryGray,
    transparent,
  }),
);
