import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Trend component styles
 * @returns {Function}
 */

export const useStyle = makeStyles((theme) =>
  createStyles({
    trend: {
      display: 'flex',
      alignItems: 'center',
    },

    text: {
      marginLeft: theme.spacing(0.425),
      marginRight: theme.spacing(1),
      fontSize: variables.font.size.sm,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.md,
    },

    textTrendUp: {
      color: variables.color.semantic.positive,
    },

    textTrendDown: {
      color: variables.color.semantic.negative,
    },
  }),
);
