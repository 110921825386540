import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    stepper: {
      padding: 0,
      width: '60%',

      '& .MuiStepConnector-line': {
        borderTop: `${variables.borderWidth.sm} dashed ${variables.color.secondary.gray}`,
      },
    },

    step: {
      paddingRight: variables.gap.lg,

      '&:first-child': {
        paddingLeft: 0,
      },

      '&:not(:first-child)': {
        paddingLeft: variables.gap.lg,
      },

      '& .MuiStepLabel-iconContainer': {
        paddingRight: 0,
        marginRight: variables.gap.md,
      },
    },

    stepIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2rem',
      height: '2rem',
      border: `${variables.borderWidth.lg} solid ${variables.color.secondary.green}`,
      borderRadius: variables.borderRadius.half,
      background: variables.color.secondary.green,

      '&[data-upcoming]': {
        background: variables.color.primary.white,
      },
    },
  }),
);
