import React, { useEffect } from 'react';

export function useOnClickOutside(
  ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>,
  handler: (value: Event) => void,
): void {
  useEffect(
    () => {
      const listener = (event: Event): void => {
        // Do nothing if clicking ref's element or descendent elements
        const target = event.target as HTMLElement;

        const dropDown = document.querySelector(
          '[style="z-index: -1; position: fixed; inset: 0px; background-color: transparent; -webkit-tap-highlight-color: transparent;"]',
        );

        if ((ref.current && ref.current.contains(target)) || !!dropDown) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
      };
    },
    // Hook checks if ref.current contains event target,
    // and if it contains, then the handler executing.
    [ref, handler],
  );
}
