import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuItem } from '@material-ui/core';
import { Select } from '@components';
import { useStyles } from './Condition.styles';
import { ConditionBooleanValueProps } from './Condition.props';
import { useAppDispatch } from '@store';
import { signalLibrary } from '@modules';
import { useLoader } from '@utils';

export const ConditionBooleanValue = ({
  index,
  defaultValue,
  mindsetIndex,
  signalId,
}: ConditionBooleanValueProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { setValue } = useFormContext();

  const { items: options } = signalLibrary.useOptionsData();

  const meta = signalLibrary.useOptionsMeta();

  useLoader(meta);

  useEffect(() => {
    if (!signalId) {
      return undefined;
    }

    const promise = dispatch(signalLibrary.thunk.searchOptions({ signal: signalId }));
    return () => {
      dispatch(signalLibrary.actions.resetOptions(null));
      promise.abort();
    };
  }, [dispatch, signalId]);

  return (
    <Controller
      name={`mindsets.${mindsetIndex}.conditions.${index}.value` as const}
      defaultValue={defaultValue}
      rules={{
        required: true,
      }}
      render={({ field, fieldState }) => (
        <Select
          uiType="tertiary"
          className={styles.signalOptionsDropdown}
          inputRef={field.ref}
          value={field.value || ''}
          error={fieldState.invalid}
          onChange={({ target: { value } }: any) => {
            setValue(field.name, String(value) as string, { shouldValidate: true });
          }}
          onBlur={field.onBlur}
        >
          {options.map((argument) => (
            <MenuItem key={String(argument.value)} value={String(argument.value)}>
              {String(argument.value)}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

ConditionBooleanValue.displayName = 'ConditionBooleanValue';
