import { SVGProps } from 'react';

export const ExpandOutline = ({
  width = 16,
  height = 16,
  stroke = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.5 10V13.5H10M13.1812 13.1803L9.5 9.5M2.5 6V2.5H6M2.81875 2.81969L6.5 6.5M10 2.5H13.5V6M13.1803 2.81875L9.5 6.5M6 13.5H2.5V10M2.81969 13.1812L6.5 9.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
