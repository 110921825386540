import { makeStyles, createStyles } from '@material-ui/core/styles';

const height = '3rem';

/**
 * DropEdit styles
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    dropEdit: {
      display: 'flex',
      flexDirection: 'column',

      '&[data-ui-type="primary"] > $body': {
        marginTop: theme.spacing(0.5),
      },

      '&[data-ui-type="secondary"] > $body': {
        marginTop: theme.spacing(1),
      },
    },

    label: {
      width: '100%',
    },

    body: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height,
    },

    bodyTextField: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height,
    },

    edit: {
      padding: 0,
      marginLeft: theme.spacing(1.25),
    },

    select: {
      '& > .MuiBox-root > .MuiInputBase-root': {
        padding: 0,
        height,

        '& > .MuiSelect-root': {
          padding: theme.spacing(0.75, 4, 0.75, 1),
        },

        '& > .MuiSelect-icon': {
          right: theme.spacing(1),
        },
      },
    },

    textField: {
      '& input': {
        height: '2.9rem',
      },
    },

    confirm: {
      height,
      minWidth: 'initial',
      marginLeft: theme.spacing(0.5),
    },

    confirmTextField: {
      height,
      width: '3rem',
      minWidth: 'initial',
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0.2),
    },
  }),
);
