/**
 SampleTemplate component variables
 @returns {variables}
 */

export const DEFAULT_HIGHLIGHTED_ELEMS = [
  {
    id: '',
    event: null,
  },
];
