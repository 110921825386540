import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      boxSizing: 'border-box',
      borderRadius: variables.borderRadius.sm,
      padding: variables.gap.lg,
      backdropFilter: 'blur(2rem)',
      background: `rgba(255, 255, 255, ${variables.opacity.high})`,
    },
  }),
);
