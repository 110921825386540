import { base } from '@modules';
import { SignalSchema } from './Signal.schema';

export const initialState: SignalSchema = {
  item: {
    ...base.state,
    data: null,
  },
  counts: {
    ...base.state,
    data: {
      match: 0,
      total: 0,
    },
  },
};
