import { SVGProps } from 'react';

export const Bold = ({ className = '', strokeWidth = '1.5' }: SVGProps<SVGElement>): JSX.Element => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M1 12.5421H6.00137C6.91967 12.5421 7.80037 12.1773 8.44971 11.5279C9.09905 10.8785 9.46385 9.99781 9.46385 9.07946C9.46385 8.16112 9.09905 7.28038 8.44971 6.63102C7.80037 5.98165 6.91967 5.61684 6.00137 5.61684H6.38609C6.68922 5.61684 6.98938 5.55713 7.26944 5.44112C7.5495 5.32511 7.80397 5.15507 8.01832 4.94072C8.23266 4.72636 8.40269 4.47188 8.5187 4.19181C8.6347 3.91174 8.69441 3.61156 8.69441 3.30842C8.69441 3.00527 8.6347 2.70509 8.5187 2.42502C8.40269 2.14495 8.23266 1.89048 8.01832 1.67612C7.80397 1.46176 7.5495 1.29173 7.26944 1.17572C6.98938 1.05971 6.68922 1 6.38609 1H1"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.53906 1V12.5417"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.00155 5.61719H2.53906"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
