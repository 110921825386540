import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * EditTab component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    editTab: {
      height: 'calc(100% - 100px)',
      overflow: 'scroll',
      margin: '0 -15px'
    },

    selectElementWrapper: {
      width: '41.5rem',
      height: '8.3rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(30),
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
    },

    caption: {
      marginTop: theme.spacing(0.5),
    },
  }),
);
