import { ChangeEvent, DragEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';

import { content } from '@content';
import { Icon, Typography } from '@components';
import { textTemplate } from '@utils';

import { Dataset } from './FileUpload.types';
import { FileUploadProps } from './FileUpload.props';
import { useStyles } from './FileUpload.styles';

/**
 * FileUpload (file drop input) component
 */
export function FileUpload<Id = string>({
  uiType = 'primary',
  className = '',
  disabled = false,
  error = false,
  label = '',
  hint = '',
  extensions = [],
  uploading = false,
  processing = false,
  multiple = false,
  value: valueInit = '',
  id,
  inputRef = null,
  onChange,
  onMouseEnter,
  onMouseLeave,
}: FileUploadProps<Id>): JSX.Element {
  const styles = useStyles();

  const accept = useMemo(() => extensions.map((ext) => `.${ext}`).join(','), [extensions]);

  const valueRef = useRef(valueInit);

  const [value, setValue] = useState(valueInit);

  const [bodyActive, setBodyActive] = useState(false);

  const pending = useMemo(
    () => (uploading ? content.uploading : processing ? content.processing : ''),
    [uploading, processing],
  );

  const frozen = useMemo(() => disabled || !!pending, [disabled, pending]);

  const dataset = useMemo<Dataset>(
    () => ({
      'data-ui-type': uiType,
      'data-state': frozen ? 'disabled' : error ? 'error' : 'default',
      'data-filled': !!value,
    }),
    [uiType, frozen, error, value],
  );

  const isValid = multiple ? !!(value as File[])?.length : !!value;

  useEffect(() => {
    if (valueRef.current !== valueInit) {
      valueRef.current = valueInit;
      setValue(valueInit);
    }
  }, [valueInit]);

  const updateValue = useCallback(
    (nextValue: File[] | File | null) => {
      setValue(nextValue || '');
      onChange(nextValue, id);
    },
    [setValue, onChange, id],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files || []);

      updateValue(multiple ? files : files[0]);
    },
    [updateValue],
  );

  const handleClear = useCallback(() => {
    updateValue(multiple ? [] : null);
  }, [updateValue]);

  const handleRemoveItem = useCallback(
    (index, curValue) => () => {
      const filteredItemList = (curValue as File[]).filter((val, itemIndex) => index !== itemIndex);
      const val = !filteredItemList.length ? null : filteredItemList;

      updateValue(val);
    },
    [updateValue],
  );

  const handleBodyDragEnter = useCallback(
    (event: DragEvent<HTMLLabelElement>) => {
      event.preventDefault();

      setBodyActive(true);
    },
    [setBodyActive],
  );

  const handleBodyDragOver = useCallback((event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  }, []);

  const handleBodyDragLeave = useCallback(
    (event: DragEvent<HTMLLabelElement>) => {
      event.preventDefault();

      setBodyActive(false);
    },
    [setBodyActive],
  );

  const handleBodyDrop = useCallback(
    (event: DragEvent<HTMLLabelElement>) => {
      event.preventDefault();

      setBodyActive(false);

      const files = Array.from(event.dataTransfer.files || []);

      updateValue(multiple ? files : files[0]);
    },
    [setBodyActive, updateValue],
  );

  const input = useMemo(
    () => (
      <input
        ref={inputRef}
        type="file"
        accept={accept}
        disabled={frozen}
        id={String(id)}
        value=""
        multiple={multiple}
        hidden
        onChange={frozen ? undefined : handleChange}
      />
    ),
    [handleChange, inputRef, accept, frozen, id],
  );

  return (
    <Box className={`${styles.fileUpload} ${className}`} {...dataset}>
      {label && <Typography.Label className={styles.label}>{label}</Typography.Label>}
      <Box className={styles.main} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {Array.isArray(value) ? (
          value.map((file, index) => (
            <Box
              key={`imag-item-${index}`}
              className={styles.body}
              data-active={!frozen && bodyActive}
              onDragEnter={frozen ? undefined : handleBodyDragEnter}
              onDragOver={frozen ? undefined : handleBodyDragOver}
              onDragLeave={frozen ? undefined : handleBodyDragLeave}
              onDrop={frozen ? undefined : handleBodyDrop}
              component={uiType === 'primary' && !isValid ? 'label' : 'div'}
              id={uiType === 'primary' ? String(id) : undefined}
            >
              {isValid ? (
                <>
                  {uiType !== 'tertiary' && <Icon.DocumentOutline className={styles.icon} />}
                  <Typography.Label className={styles.name}>{file.name}</Typography.Label>
                  <Box className={styles.meta}>
                    {pending && <Typography.Label>{pending}</Typography.Label>}
                    {uiType === 'primary' && (
                      <IconButton
                        className={styles.clear}
                        size="small"
                        disabled={frozen}
                        onClick={frozen ? undefined : handleRemoveItem(index, value)}
                      >
                        <Icon.TrashOutline />
                      </IconButton>
                    )}
                  </Box>
                </>
              ) : (
                <>
                  {uiType === 'primary' && (
                    <>
                      {input}
                      {[content.dragAndDropHere, content.or, content.browseFiles].map((txt) => (
                        <Typography.Label className={styles.placeholder} key={txt}>
                          {txt}
                        </Typography.Label>
                      ))}
                    </>
                  )}
                  {uiType === 'secondary' && (
                    <>
                      <Icon.CloudUploadOutline className={styles.icon} />
                      <Typography.Label className={styles.placeholder}>{content.dropAFileToAdd}</Typography.Label>
                    </>
                  )}
                  {uiType === 'tertiary' && (
                    <Typography.Label className={styles.placeholder}>{content.none}</Typography.Label>
                  )}
                </>
              )}
            </Box>
          ))
        ) : (
          <Box
            className={styles.body}
            data-active={!frozen && bodyActive}
            onDragEnter={frozen ? undefined : handleBodyDragEnter}
            onDragOver={frozen ? undefined : handleBodyDragOver}
            onDragLeave={frozen ? undefined : handleBodyDragLeave}
            onDrop={frozen ? undefined : handleBodyDrop}
            component={uiType === 'primary' && !isValid ? 'label' : 'div'}
            id={uiType === 'primary' ? String(id) : undefined}
          >
            {isValid ? (
              <>
                {uiType !== 'tertiary' && <Icon.DocumentOutline className={styles.icon} />}
                <Typography.Label className={styles.name}>{(value as File).name}</Typography.Label>
                <Box className={styles.meta}>
                  {pending && <Typography.Label>{pending}</Typography.Label>}
                  {uiType === 'primary' && (
                    <IconButton
                      className={styles.clear}
                      size="small"
                      disabled={frozen}
                      onClick={frozen ? undefined : handleClear}
                    >
                      <Icon.TrashOutline />
                    </IconButton>
                  )}
                </Box>
              </>
            ) : (
              <>
                {uiType === 'primary' && (
                  <>
                    {input}
                    {[content.dragAndDropHere, content.or, content.browseFiles].map((txt) => (
                      <Typography.Label className={styles.placeholder} key={txt}>
                        {txt}
                      </Typography.Label>
                    ))}
                  </>
                )}
                {uiType === 'secondary' && (
                  <>
                    <Icon.CloudUploadOutline className={styles.icon} />
                    <Typography.Label className={styles.placeholder}>{content.dropAFileToAdd}</Typography.Label>
                  </>
                )}
                {uiType === 'tertiary' && (
                  <Typography.Label className={styles.placeholder}>{content.none}</Typography.Label>
                )}
              </>
            )}
          </Box>
        )}
        {hint && <Typography.SmallCaption className={styles.hint}>{hint}</Typography.SmallCaption>}
      </Box>
      {uiType === 'secondary' && (
        <Box id={String(id)} component="label">
          {input}
          <Button className={styles.browse} variant="outlined" color="primary" component="div">
            {isValid ? content.replace : textTemplate(content.selectValue, { value: content.file })}
          </Button>
        </Box>
      )}
      {uiType === 'tertiary' && (
        <>
          <Box id={String(id)} component="label">
            {input}
            <Button className={styles.browse} variant="outlined" color="primary" component="div">
              {isValid ? content.change : content.select}
            </Button>
          </Box>
          {isValid && (
            <IconButton
              className={styles.clear}
              size="small"
              disabled={frozen}
              onClick={frozen ? undefined : handleClear}
            >
              <Icon.TrashOutline />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
}
