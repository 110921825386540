import { ActionReducerMapBuilder, nanoid, PayloadAction } from '@reduxjs/toolkit';

import { content } from '@content';

import { initialState } from './Deployment.state';
import {
  create,
  update,
  clone,
  getById,
  search,
  attachJourney,
  publishEmailFile,
  publishSmartFile,
  publishDeployment,
  getGenerationStatus,
  getModulesList,
  getInfoEmailTemplate,
  attachMailFile,
} from './Deployment.thunk';
import { DeploymentSchema, PublishActions, PublishSummary } from './Deployment.schema';
import { DeploymentInstertJourneyPayload, DeploymentMoveJourneyPayload } from './Deployment.types';

export const reducers = {
  clearError: ({ entity }: DeploymentSchema): void => {
    entity.error = initialState.entity.error;
    entity.status = initialState.entity.status;
  },

  appendPosition: ({ entity }: DeploymentSchema): void => {
    if (entity.data) {
      entity.data.positions = [
        ...(entity.data?.positions || []),
        {
          id: nanoid(),
          experience: null,
        },
      ];
    }
  },

  moveJourney: ({ entity }: DeploymentSchema, { payload }: PayloadAction<DeploymentMoveJourneyPayload>): void => {
    if (entity.data) {
      const newValues = entity.data.positions.map((i) => i.experience);
      newValues.splice(payload.to, 0, newValues.splice(payload.from, 1)[0]);

      entity.data.positions = entity.data.positions.map((item, index) => ({
        ...item,
        experience: newValues[index],
      }));
    }
  },

  insertJourney: ({ entity }: DeploymentSchema, { payload }: PayloadAction<DeploymentInstertJourneyPayload>): void => {
    if (entity.data) {
      entity.data.positions[payload.index].experience = payload.data;
    }
  },

  emptyPosition: ({ entity }: DeploymentSchema, { payload }: PayloadAction<number>): void => {
    if (entity.data) {
      entity.data.positions[payload] = {
        ...entity.data.positions[payload],
        experience: null,
      };
    }
  },

  removePosition: ({ entity }: DeploymentSchema, { payload }: PayloadAction<number>): void => {
    if (entity.data) {
      entity.data.positions = entity.data.positions.filter((item, index) => index !== payload);
    }
  },

  resetPositions: ({ entity }: DeploymentSchema): void => {
    if (entity.data) {
      entity.data.positions = [];
    }
  },

  resetEntity: ({ entity }: DeploymentSchema): void => {
    entity.data = initialState.entity.data;
  },

  changeDataMailLoadingStatus: ({ entity }: DeploymentSchema, { payload }: PayloadAction<boolean>): void => {
    if (entity.data?.isMailFileUploading) {
      entity.data.isMailFileUploading = payload;
    }
  },

  resetModulesList: ({ modulesList }: DeploymentSchema): void => {
    modulesList.data = [];
  },

  resetDataVizGenerationStatus: ({ dataVizGenerationStatus }: DeploymentSchema): void => {
    dataVizGenerationStatus.data = initialState.dataVizGenerationStatus.data;
  },

  updateEntityPublishSummary: ({ entity }: DeploymentSchema, { payload }: PayloadAction<PublishSummary>): void => {
    if (entity.data) {
      entity.data = {
        ...entity.data,
        lastPublishDetails: {
          ...(entity.data?.lastPublishDetails as PublishActions),
          publishSummary: payload,
          hasErrors: Object.values(payload).some((item) => item === 'error'),
          isCompleted: !Object.values(payload).some((item) => item === 'ongoing'),
        },
      };
    }
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<DeploymentSchema>): void => {
  builder.addCase(create.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(create.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(create.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        ...action.error,
        message: action.error.message || content.somethingWentWrong,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(update.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(update.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(update.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(clone.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(clone.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(clone.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(getById.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(getById.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(getById.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(search.pending, ({ deploymentsList }, action) => {
    if (deploymentsList.status !== 'loading') {
      deploymentsList.requestId = action.meta.requestId;
      deploymentsList.data = initialState.deploymentsList.data;
      deploymentsList.error = initialState.deploymentsList.error;
      deploymentsList.status = 'loading';
    }
  });

  builder.addCase(search.fulfilled, ({ deploymentsList }, action) => {
    if (deploymentsList.status === 'loading' && deploymentsList.requestId === action.meta.requestId) {
      deploymentsList.requestId = initialState.deploymentsList.requestId;
      deploymentsList.status = 'success';
      deploymentsList.data = action.payload ?? initialState.deploymentsList.data;
    }
  });

  builder.addCase(search.rejected, ({ deploymentsList }, action) => {
    if (deploymentsList.status === 'loading' && deploymentsList.requestId === action.meta.requestId) {
      deploymentsList.requestId = initialState.deploymentsList.requestId;
      deploymentsList.error = {
        message: action.payload?.message || action.error.message,
      };
      deploymentsList.status = 'error';
    }
  });

  builder.addCase(attachJourney.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(attachJourney.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(attachJourney.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';

      if (action.meta.arg) {
        const positions = action.meta.arg.positions.filter((position) => position.id);
        entity.data = { ...action.meta.arg, positions };
      }
    }
  });

  builder.addCase(publishEmailFile.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(publishEmailFile.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(publishEmailFile.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(publishSmartFile.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(publishSmartFile.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = '';
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(publishSmartFile.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(publishDeployment.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(publishDeployment.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = '';
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(publishDeployment.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(getGenerationStatus.pending, ({ dataVizGenerationStatus }, action) => {
    if (dataVizGenerationStatus.status !== 'loading') {
      dataVizGenerationStatus.requestId = action.meta.requestId;
      dataVizGenerationStatus.status = 'loading';
      dataVizGenerationStatus.error = initialState.dataVizGenerationStatus.error;
      dataVizGenerationStatus.data = initialState.dataVizGenerationStatus.data;
    }
  });

  builder.addCase(getGenerationStatus.fulfilled, ({ dataVizGenerationStatus }, action) => {
    if (dataVizGenerationStatus.status === 'loading' && dataVizGenerationStatus.requestId === action.meta.requestId) {
      dataVizGenerationStatus.requestId = '';
      dataVizGenerationStatus.status = 'success';
      dataVizGenerationStatus.data = action.payload || initialState.dataVizGenerationStatus.data;
    }
  });

  builder.addCase(getGenerationStatus.rejected, ({ dataVizGenerationStatus }, action) => {
    if (dataVizGenerationStatus.status === 'loading' && dataVizGenerationStatus.requestId === action.meta.requestId) {
      dataVizGenerationStatus.requestId = '';
      dataVizGenerationStatus.status = 'error';
      dataVizGenerationStatus.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(getModulesList.pending, ({ modulesList }, action) => {
    if (modulesList.status !== 'loading') {
      modulesList.requestId = action.meta.requestId;
      modulesList.data = initialState.modulesList.data;
      modulesList.error = initialState.modulesList.error;
      modulesList.status = 'loading';
    }
  });

  builder.addCase(getModulesList.fulfilled, ({ modulesList }, action) => {
    if (modulesList.status === 'loading' && modulesList.requestId === action.meta.requestId) {
      modulesList.requestId = initialState.modulesList.requestId;
      modulesList.status = 'success';
      if (action.payload) {
        modulesList.data = action.payload.map(({ tag, ...rest }) => ({
          ...rest,
          tag: tag.tag,
          index: tag.index,
        }));
      }
    }
  });

  builder.addCase(getModulesList.rejected, ({ modulesList }, action) => {
    if (modulesList.status === 'loading' && modulesList.requestId === action.meta.requestId) {
      modulesList.requestId = initialState.modulesList.requestId;
      modulesList.data = initialState.modulesList.data;
      modulesList.error = {
        message: action.payload?.message || action.error.message,
      };
      modulesList.status = 'error';
    }
  });

  builder.addCase(getInfoEmailTemplate.pending, ({ infoEmailTemplate }, action) => {
    if (infoEmailTemplate.status !== 'loading') {
      infoEmailTemplate.requestId = action.meta.requestId;
      infoEmailTemplate.data = initialState.infoEmailTemplate.data;
      infoEmailTemplate.error = initialState.infoEmailTemplate.error;
      infoEmailTemplate.status = 'loading';
    }
  });

  builder.addCase(getInfoEmailTemplate.fulfilled, ({ infoEmailTemplate }, action) => {
    if (infoEmailTemplate.status === 'loading' && infoEmailTemplate.requestId === action.meta.requestId) {
      infoEmailTemplate.requestId = '';
      infoEmailTemplate.status = 'success';
      if (action.payload) {
        infoEmailTemplate.data = action.payload;
      }
    }
  });

  builder.addCase(attachMailFile.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(attachMailFile.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(attachMailFile.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });
};
