import { Box } from '@material-ui/core';

import { content } from '@content';
import { Icon, Typography } from '@components';
import { variables } from '@styles';

import { useStyles } from './PublishStates.styles';
import { PublishStatesProps } from './PublishStates.props';

/**
 PublishStates component.
 @returns {JSX.Element}
 */

export const PublishStates = ({ state }: PublishStatesProps) => {
  const styles = useStyles();

  return (
    <>
      {state === 'notPublished' && (
        <Box className={styles.state}>
          <Typography.SuperSmallCaption>{content.notPublished}</Typography.SuperSmallCaption>
        </Box>
      )}
      {state === 'publishing' && (
        <Box className={styles.state}>
          <Typography.SuperSmallCaption>{`${content.publishing}...`}</Typography.SuperSmallCaption>
        </Box>
      )}
      {state === 'published' && (
        <Box className={styles.state}>
          <Typography.SuperSmallCaption className={styles.success}>{content.published}</Typography.SuperSmallCaption>
          <Icon.CheckmarkCircleOutline width="1.35rem" height="1.35rem" stroke={variables.color.semantic.positive} />
        </Box>
      )}
      {state === 'error' && (
        <Box className={styles.state}>
          <Typography.SuperSmallCaption className={styles.error}>{content.error}</Typography.SuperSmallCaption>
          <Icon.AlertCircleOutlineV2 width="1.35rem" height="1.35rem" fill={variables.color.semantic.negative} />
        </Box>
      )}
      {state === 'needsUpdate' && (
        <Box className={styles.state}>
          <Typography.SuperSmallCaption className={styles.warning}>{content.needsUpdate}</Typography.SuperSmallCaption>
          <Icon.AlertCircleOutlineV2 width="1.35rem" height="1.35rem" fill={variables.color.semantic.warning} />
        </Box>
      )}
    </>
  );
};
