import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { MailerSchema } from './Mailer.schema';
import { initialState } from './Mailer.state';
import { authenticate, createResource } from './Mailer.thunk';

export const reducers = {
  resetAuth: (state: MailerSchema): void => {
    state.auth = initialState.auth;
  },
  resetEntity: (state: MailerSchema): void => {
    state.entity = initialState.entity;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<MailerSchema>): void => {
  builder.addCase(authenticate.pending, (state, action) => {
    if (state.auth.status !== 'loading') {
      state.auth.requestId = action.meta.requestId;
      state.auth.error = initialState.auth.error;
      state.auth.data = initialState.auth.data;
      state.auth.status = 'loading';
    }
  });

  builder.addCase(authenticate.fulfilled, (state, action) => {
    if (state.auth.status === 'loading' && state.auth.requestId === action.meta.requestId) {
      state.auth.requestId = initialState.auth.requestId;
      state.auth.data = action.payload || initialState.auth.data;
      state.auth.status = 'success';
    }
  });

  builder.addCase(authenticate.rejected, (state, action) => {
    if (state.auth.status === 'loading' && state.auth.requestId === action.meta.requestId) {
      state.auth.requestId = initialState.auth.requestId;
      state.auth.error = {
        message: action.payload?.message || action.error.message,
      };
      state.auth.status = 'error';
    }
  });

  builder.addCase(createResource.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.error = initialState.entity.error;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(createResource.fulfilled, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.status = 'success';
    }
  });

  builder.addCase(createResource.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = {
        message: action.payload?.message || action.error.message,
      };
      state.entity.status = 'error';
    }
  });
};
