import { makeStyles, createStyles, StyleRules } from '@material-ui/core/styles';

import { variables } from '@styles';

import { ColId } from './PublishToESP.types';

const { pckd, stat, dscr } = ColId;

const colsWidths = {
  [pckd]: '4rem',
  [stat]: '9rem',
};

const whitespaceMixin = {
  '& > *': {
    whiteSpace: 'normal',
  },
};

const colsMixin: StyleRules<ColId> = Object.entries(colsWidths).reduce(
  (acc, [key, width]) => ({
    ...acc,
    [key]: { ...whitespaceMixin, width },
  }),
  {} as StyleRules<ColId>,
);

const colRegWidth = `calc((100% - (${Object.values(colsWidths).join(' + ')})) / ${
  Object.keys(ColId).length - Object.keys(colsWidths).length
})`;

const colsRegMixin = Object.keys(ColId)
  .filter((colId) => !(colId in colsWidths))
  .reduce((acc, colId) => ({ ...acc, [colId]: { ...whitespaceMixin, width: colRegWidth } }), {} as StyleRules<ColId>);

export const useStyles = makeStyles(() =>
  createStyles({
    mods: {
      marginTop: variables.gap.sm,
    },

    ...colsMixin,
    ...colsRegMixin,

    [pckd]: {
      ...colsMixin[pckd],
      alignItems: 'center',

      '& > .MuiFormControlLabel-root': {
        margin: 0,
        overflow: 'initial',
      },
    },

    [dscr]: {
      ...colsRegMixin[dscr],

      '&[data-header="false"] > :first-child > :first-child': {
        marginBottom: variables.gap.sm,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },

    publishToEsp: {
      display: 'flex',
      flexDirection: 'column',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: variables.gap.sm,
    },

    legend: {
      margin: `0 ${variables.gap.xl}`,

      '& [data-variant="label"]': {
        color: variables.color.primary.gray,
      },

      '& [data-variant="count"]': {
        color: variables.color.primary.gray,
        fontSize: variables.font.size.sm,
      },
    },
  }),
);
