import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

const border = `${variables.borderWidth.sm} solid ${variables.color.semantic.neutralLight}`;

export const useStyles = makeStyles((theme) =>
  createStyles({
    forbidden: {
      '& > *:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },

    list: {
      display: 'flex',
      flexDirection: 'column',
      border,
      borderRadius: variables.borderRadius.sm,
    },

    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1.25),

      '&:not(:first-child)': {
        borderTop: border,
      },

      '& > *:not(:last-child)': {
        marginRight: theme.spacing(0.5),
      },
    },

    icon: {
      marginLeft: 'auto',

      '& > path': {
        stroke: variables.color.semantic.positive,
      },
    },

    status: {
      color: variables.color.semantic.positive,
    },
  }),
);
