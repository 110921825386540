import { forwardRef, ForwardedRef, MouseEvent, useCallback, useState, useMemo } from 'react';
import { Box, CircularProgress, Divider, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@material-ui/core';

import { Icon, Loader, Typography } from '@components';
import { variables } from '@styles';
import { func } from '@utils';

import { OptionProps, OptionItem } from './Option.props';
import { useStyles } from './Option.styles';

/*
  Menu option (item) component which uses in Menu component.
  Can be used recursively by providing a sub-options array in the options array.
  Based on Material UI MenuItem.
*/

export const Option = forwardRef(
  (
    {
      id,
      type = 'default',
      label = '',
      subtitle = '',
      description = '',
      icon = undefined,
      iconColorIgnore = false,
      value = undefined,
      subOptions = undefined,
      active: activeInit = false,
      loading = false,
      showCheckmark = true,
      disabled = false,
      capitalizedLabel = false,
      onClick = func.nop,
    }: OptionProps,
    ref: ForwardedRef<HTMLElement>,
  ): JSX.Element => {
    const styles = useStyles();

    const [childAnchorEl, setChildAnchorEl] = useState<null | HTMLElement>(null);

    const open = useMemo(() => Boolean(childAnchorEl), [childAnchorEl]);

    const active = useMemo(() => activeInit || open, [activeInit, open]);

    const activeSubOption = useMemo(() => subOptions?.find((item) => item.active), [subOptions]);

    const IconComp = useMemo(() => (icon ? Icon[icon] : null), [icon]);

    const handleClick = useCallback(() => {
      onClick({ id, type, label, value, active, disabled, description, subOptions });
    }, [id, type, label, value, active, disabled, subOptions, description, onClick]);

    const handleChildClick = (event: MouseEvent<HTMLElement>) => {
      setChildAnchorEl(event.currentTarget);
    };

    const handleChildClose = useCallback(() => {
      setChildAnchorEl(null);
    }, []);

    const handleItemClick = useCallback(
      (option: OptionItem) => {
        onClick(option);
        handleChildClose();
      },
      [onClick, handleChildClose],
    );

    return (
      <>
        {type === 'divider' ? (
          <Divider classes={{ root: styles.divider }} />
        ) : type === 'spinner' ? (
          <Box className={styles.spinner}>
            <Loader width={20} height={20} />
          </Box>
        ) : (
          <MuiMenuItem
            className={`${styles.menuItem} ${loading ? 'menu-item-with-loading' : ''} ${
              !showCheckmark ? 'no-background' : ''
            }`}
            onClick={subOptions ? handleChildClick : handleClick}
            selected={!!active || loading}
            disabled={!!disabled}
            {...(subOptions ? { 'data-opened': open } : {})}
          >
            <Typography.Body
              component="span"
              className={`${styles.menuItemLabel} ${capitalizedLabel ? 'capitalized' : ''}`}
            >
              <Box className={styles.menuItemTop}>
                {label}
                {loading && <Loader height={15} width={15} />}
              </Box>
              {subtitle && <Typography.Caption className={styles.subtitle}>{subtitle}</Typography.Caption>}
            </Typography.Body>
            {description && <Typography.Caption className={styles.description}>{description}</Typography.Caption>}
            {subOptions ? (
              <>
                {activeSubOption && <Typography.Body>{activeSubOption.label}</Typography.Body>}
                <Icon.ChevronRight stroke={variables.color.primary.mediumGray} />
              </>
            ) : active ? (
              showCheckmark && <Icon.Checkmark stroke={variables.color.primary.white} />
            ) : (
              IconComp && <IconComp className={iconColorIgnore ? styles.iconColorless : ''} />
            )}
          </MuiMenuItem>
        )}
        {subOptions && (
          <MuiMenu
            elevation={0}
            anchorEl={childAnchorEl}
            open={open}
            keepMounted
            onClose={handleChildClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            MenuListProps={{
              className: styles.menuList,
            }}
            PaperProps={{
              className: styles.paperChild,
            }}
          >
            {subOptions.map((subOption) => (
              <Option {...subOption} onClick={handleItemClick} key={subOption.id} ref={ref} />
            ))}
          </MuiMenu>
        )}
      </>
    );
  },
);

Option.displayName = 'Option';
