import React, { useEffect, useCallback, useState } from 'react';
import { Box, Divider } from '@material-ui/core';
import { ModuleLibraryFilter, ModuleLibraryList, ModuleInfoModal } from '@views';
import { page, content } from '@content';
import { variables } from '@styles';
import { BarChart, Typography } from '@components';
import { history, useAppDispatch } from '@store';
import { Module, modules, userClients, ModulesSearchParams, analytics } from '@modules';
import { searchQuery, useLoader, useScrollableContainer } from '@utils';
import { SEARCH_QUERY_INIT } from './ModuleLevel.const';
import { getModuleUniqueKey } from '@routes';

import { useStyles } from './ModuleLevel.styles';
import { ModuleLevelStatistics } from './ModuleLevelStatistics';

export const ModuleLevel = (): React.ReactElement => {
  const styles = useStyles();
  const ref = useScrollableContainer(null);
  const dispatch = useAppDispatch();
  const [businessUnitId, deploymentId, moduleId] = searchQuery.useMutualParams('businessUnit', 'deployment', 'module');
  const [detailModal, setDetailModal] = useState<null | Module>(null);
  const [query, setQuery] = useState<ModulesSearchParams>({
    ...SEARCH_QUERY_INIT,
    businessUnitId,
  });
  const libraryData = modules.useLibraryData();

  const { deployments: modulePerformanceData, average: modulePerformanceAverage } =
    analytics.useModulePerformanceData();
  const modulePerformanceMeta = analytics.useModulePerformanceMeta();

  const { deploymentsData: moduleVolumeData, average: moduleVolumeAverage } = analytics.useModuleVolumeData();
  const moduleVolumeMeta = analytics.useModuleVolumeMeta();

  const moduleInfo = modules.useModuleInfoData();
  const moduleInfoMeta = modules.useModuleInfoMeta();

  useLoader(
    userClients.useEntityMeta(),
    modules.useLibraryMeta(),
    modules.useCloneMeta(),
    modulePerformanceMeta,
    moduleVolumeMeta,
    moduleInfoMeta,
  );

  const reloadWithModule = useCallback(
    (nextModuleId: number | undefined | null) => {
      if (nextModuleId) {
        if (deploymentId) {
          history.push(
            `${page.analytics}?businessUnit=${businessUnitId}&deployment=${deploymentId}&module=${nextModuleId}`,
          );
        } else {
          history.push(`${page.analytics}?businessUnit=${businessUnitId}&module=${nextModuleId}`);
        }
      } else if (deploymentId) {
        history.push(`${page.analytics}?businessUnit=${businessUnitId}&deployment=${deploymentId}`);
      } else {
        history.push(`${page.analytics}${page.moduleLevel}?businessUnit=${businessUnitId}`);
      }
    },
    [businessUnitId, deploymentId],
  );

  useEffect(() => {
    if (!moduleId) {
      if (moduleInfo?.id) {
        reloadWithModule(moduleInfo.id);
      }
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getModuleVolumeOverTime({
        module: String(moduleId),
      }),
    );

    return () => {
      dispatch(analytics.actions.resetModuleVolumeOverTime());
      promise.abort();
    };
  }, [dispatch, moduleId, businessUnitId, moduleInfo, reloadWithModule]);

  useEffect(() => {
    if (!moduleId) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getModulePerformanceOverTime({
        module: String(moduleId),
      }),
    );

    return () => {
      dispatch(analytics.actions.resetModulePerformanceOverTime());
      promise.abort();
    };
  }, [dispatch, moduleId]);

  useEffect(() => {
    if (!businessUnitId) {
      history.push(page.dashboard);
      return undefined;
    }

    const promise = dispatch(userClients.thunk.getById(businessUnitId));

    return () => {
      dispatch(modules.actions.resetClone());
      promise.abort();
    };
  }, [businessUnitId, dispatch]);

  useEffect(() => {
    if (!moduleId) {
      return undefined;
    }
    const promise = dispatch(modules.thunk.getModuleInfo({ module: moduleId }));

    return () => {
      promise.abort();
    };
  }, [moduleId, dispatch]);

  useEffect(() => {
    const promise = dispatch(
      modules.thunk.search({
        ...query,
        ...(moduleId && { moduleId }),
      }),
    );

    return () => {
      dispatch(modules.actions.resetLibrary());
      promise.abort();
    };
  }, [businessUnitId, moduleId, dispatch, query]);

  const handleFilterReset = useCallback(() => {
    setQuery({
      ...SEARCH_QUERY_INIT,
      searchType: query.searchType,
      businessUnitId,
    });
    dispatch(modules.actions.resetModuleInfo());
    reloadWithModule(null);
  }, [businessUnitId, query.searchType, dispatch, reloadWithModule]);

  const handlePageChange = useCallback((event, nextPage) => {
    setQuery((prev) => ({
      ...prev,
      page: nextPage,
    }));
  }, []);

  const handleFilterSearch = useCallback(
    (values) =>
      setQuery({
        itemsPerPage: SEARCH_QUERY_INIT.itemsPerPage,
        ...values,
        searchType: values.searchType ? 'deployment' : 'module',
        businessUnitId,
        page: 1,
      }),
    [businessUnitId],
  );

  const closeModal = useCallback(() => setDetailModal(null), []);

  const openModal = useCallback((module) => setDetailModal(module), []);

  return (
    <Box className={styles.moduleLevel} {...{ ref }}>
      <div>
        <ModuleLibraryFilter
          onSearch={handleFilterSearch}
          onReset={handleFilterReset}
          defaultKeyword={moduleInfo?.name || ''}
        />
        {moduleInfo?.name && <ModuleLevelStatistics moduleInfo={moduleInfo} />}
        <Box className={styles.chartsContainer}>
          <BarChart
            className="moduleVolumeOverTime"
            data={moduleVolumeData}
            average={moduleVolumeAverage}
            color={variables.color.charts.pink}
            title={content.moduleVolumeOverTime}
            legendLeft={content.impressions}
          />
          <BarChart
            className="modulePerformanceOverTime"
            data={modulePerformanceData}
            average={modulePerformanceAverage}
            color={variables.color.charts.orange}
            title={content.modulePerformanceOverTime}
            unit={content.percentageUnit}
            legendLeft={`${content.ctir}%`}
          />
        </Box>
        {modulePerformanceData.length || modulePerformanceData.length ? <Divider /> : null}
        <Box className={styles.moduleLibraryContainer}>
          {libraryData.total ? (
            <Typography.Title>{`${content.relatedModuleViews} (${libraryData.total})`}</Typography.Title>
          ) : null}
          <ModuleLibraryList
            modules={libraryData.items}
            currentPage={query.page}
            pageCount={libraryData.pageCount}
            onViewDetail={openModal}
            changePage={handlePageChange}
            modulesGridStyles={styles.modulesGrid}
            disabledScroll
          />
        </Box>
        <ModuleInfoModal
          open={!!detailModal}
          onClose={closeModal}
          id={detailModal && detailModal[getModuleUniqueKey(query)]}
          name={detailModal?.name}
          tag={detailModal?.tag?.tag}
          deploymentsCount={detailModal?.deploymentsCount}
          clicks={detailModal?.statistics?.totalClicks}
          impressions={detailModal?.statistics?.totalImpressions}
          clickToImpression={detailModal?.statistics?.ctir}
          disclosureCopy={detailModal?.moduleDisclosureStation?.disclosureCopy}
          coverImage={detailModal?.coverImage}
        />
      </div>
    </Box>
  );
};
