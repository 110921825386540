import { makeStyles, createStyles } from '@material-ui/core';

import { variables } from '@styles';

import { REC_EXTRA_POSTFIX } from './List.const';

const ntpsAfterIndex = 0;

const ntpsEditSize = '1.6rem';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(4),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    actions: {
      display: 'flex',

      '& > :not(:first-child)': {
        marginLeft: theme.spacing(3),
      },
    },

    viewArchived: {
      display: 'none',
    },

    alert: {
      marginBottom: theme.spacing(2),
    },

    ntps: {
      position: 'relative',
      maxHeight: '1.8rem',

      '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: ntpsAfterIndex,
        width: '100%',
        height: variables.borderWidth.xl,
        background: variables.color.primary.white,
        bottom: theme.spacing(-2.25),
      },

      [`&[data-record-id$="${REC_EXTRA_POSTFIX}"], &[data-header="true"]`]: {
        '&:after': {
          background: 'transparent',
        },
      },
    },

    ntpsValue: {
      position: 'absolute',
      zIndex: ntpsAfterIndex + 1,
      top: 0,
      left: 0,
      maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },

    ntpsName: {
      maxWidth: `calc(100% - ${theme.spacing(1)}px - ${ntpsEditSize})`,
      whiteSpace: 'initial',
    },

    ntpsEdit: {
      width: ntpsEditSize,
      height: ntpsEditSize,
      padding: 0,
      margin: theme.spacing(0.125, 0, 0, 1),
    },

    filler: {
      overflow: 'visible',
    },

    edit: {
      position: 'relative',
    },

    editClient: {
      position: 'absolute',
      right: 0,
    },

    addClient: {
      marginBottom: variables.gap.sm,
    },
  }),
);
