import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';
import { color } from '@styles/variables/color';

/**
 * NewDeployment component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    radioTitle: {
      color: color.primary.darkestGray,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.lg,
      textTransform: 'none!important' as any
    },
    radioLabel: {
      color: color.primary.darkestGray,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.lg,
      textTransform: 'initial'
    },
    separator: {
      backgroundColor: '#e2e2e2',
      height: '1px',
      marginTop: '0!important'
    },
    fileUpload: {
      marginBottom: '24px',
    }
  }),
);
