import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  getNumberOfModulesPerPosition,
  getCtirPerPosition,
  getPositionVolume,
  getImpressionByCohort,
  getImpressionByProduct,
  getImpressionByOverall,
  getCommonCtirByDeployment,
  getSummaryPerformance,
  getModulePerformanceOverTime,
  getModuleVolumeOverTime,
  getClicksToOpenReport,
  getOverallMetrics,
  getClickRate,
  getOptOutRate,
  getOpenRate,
  getAmriChangeReport,
  getBenchmarkingReportEnterprise,
  getBenchmarkingReportBU,
  getTopPerformingContentReport,
  getLowPerformingContentReport,
} from './Analytics.thunk';
import { initialState } from './Analytics.state';
import { AnalyticsSchema } from './Analytics.schema';

export const reducers = {
  resetNumberModulesPerPosition: (state: AnalyticsSchema): void => {
    state.numberModulesPerPosition = initialState.numberModulesPerPosition;
  },
  resetCtirPerPosition: (state: AnalyticsSchema): void => {
    state.ctirPerPosition = initialState.ctirPerPosition;
  },
  resetPositionVolume: (state: AnalyticsSchema): void => {
    state.positionVolume = initialState.positionVolume;
  },
  resetImpressionByCohort: (state: AnalyticsSchema): void => {
    state.impressionByCohort = initialState.impressionByCohort;
  },
  resetImpressionByProduct: (state: AnalyticsSchema): void => {
    state.impressionByProduct = initialState.impressionByProduct;
  },
  resetImpressionByOverall: (state: AnalyticsSchema): void => {
    state.impressionByOverall = initialState.impressionByOverall;
  },
  resetCommonCtirByDeployment: (state: AnalyticsSchema): void => {
    state.commonCtirByDeployment = initialState.commonCtirByDeployment;
  },
  resetModulePerformanceOverTime: (state: AnalyticsSchema): void => {
    state.modulePerformanceOverTime = initialState.modulePerformanceOverTime;
  },
  resetModuleVolumeOverTime: (state: AnalyticsSchema): void => {
    state.moduleVolumeOverTime = initialState.moduleVolumeOverTime;
  },
  resetClickToOpenReport: (state: AnalyticsSchema): void => {
    state.clickToOpenReport = initialState.clickToOpenReport;
  },
  resetOverallMetrics: (state: AnalyticsSchema): void => {
    state.overallMetrics = initialState.overallMetrics;
  },
  resetClickRate: (state: AnalyticsSchema): void => {
    state.clickRate = initialState.clickRate;
  },
  resetOptOutRate: (state: AnalyticsSchema): void => {
    state.optOutRate = initialState.optOutRate;
  },
  resetOpenRate: (state: AnalyticsSchema): void => {
    state.openRate = initialState.openRate;
  },
  resetSummaryPerformance: (state: AnalyticsSchema): void => {
    state.summaryPerformance = initialState.summaryPerformance;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<AnalyticsSchema>): void => {
  builder.addCase(getNumberOfModulesPerPosition.pending, ({ numberModulesPerPosition }, action) => {
    if (numberModulesPerPosition.status !== 'loading') {
      numberModulesPerPosition.requestId = action.meta.requestId;
      numberModulesPerPosition.status = 'loading';
      numberModulesPerPosition.error = initialState.numberModulesPerPosition.error;
    }
  });

  builder.addCase(getNumberOfModulesPerPosition.fulfilled, ({ numberModulesPerPosition }, action) => {
    if (numberModulesPerPosition.status === 'loading' && numberModulesPerPosition.requestId === action.meta.requestId) {
      numberModulesPerPosition.requestId = '';
      numberModulesPerPosition.status = 'success';
      if (action.payload && action.payload.average) {
        numberModulesPerPosition.data = action.payload;
      }
    }
  });

  builder.addCase(getNumberOfModulesPerPosition.rejected, ({ numberModulesPerPosition }, action) => {
    if (numberModulesPerPosition.status === 'loading' && numberModulesPerPosition.requestId === action.meta.requestId) {
      numberModulesPerPosition.requestId = '';
      numberModulesPerPosition.status = 'error';
      numberModulesPerPosition.error = {
        message: action.payload?.message || action.error.message,
      };
      numberModulesPerPosition.data = initialState.numberModulesPerPosition.data;
    }
  });
  builder.addCase(getCtirPerPosition.pending, ({ ctirPerPosition }, action) => {
    if (ctirPerPosition.status !== 'loading') {
      ctirPerPosition.requestId = action.meta.requestId;
      ctirPerPosition.status = 'loading';
      ctirPerPosition.error = initialState.ctirPerPosition.error;
    }
  });

  builder.addCase(getCtirPerPosition.fulfilled, ({ ctirPerPosition }, action) => {
    if (ctirPerPosition.status === 'loading' && ctirPerPosition.requestId === action.meta.requestId) {
      ctirPerPosition.requestId = '';
      ctirPerPosition.status = 'success';
      if (action.payload) {
        ctirPerPosition.data = action.payload;
      }
    }
  });

  builder.addCase(getCtirPerPosition.rejected, ({ ctirPerPosition }, action) => {
    if (ctirPerPosition.status === 'loading' && ctirPerPosition.requestId === action.meta.requestId) {
      ctirPerPosition.requestId = '';
      ctirPerPosition.status = 'error';
      ctirPerPosition.error = {
        message: action.payload?.message || action.error.message,
      };
      ctirPerPosition.data = initialState.ctirPerPosition.data;
    }
  });

  builder.addCase(getPositionVolume.pending, ({ positionVolume }, action) => {
    if (positionVolume.status !== 'loading') {
      positionVolume.requestId = action.meta.requestId;
      positionVolume.status = 'loading';
      positionVolume.error = initialState.positionVolume.error;
    }
  });

  builder.addCase(getPositionVolume.fulfilled, ({ positionVolume }, action) => {
    if (positionVolume.status === 'loading' && positionVolume.requestId === action.meta.requestId) {
      positionVolume.requestId = '';
      positionVolume.status = 'success';
      if (action.payload) {
        positionVolume.data = action.payload;
      }
    }
  });

  builder.addCase(getPositionVolume.rejected, ({ positionVolume }, action) => {
    if (positionVolume.status === 'loading' && positionVolume.requestId === action.meta.requestId) {
      positionVolume.requestId = '';
      positionVolume.status = 'error';
      positionVolume.error = {
        message: action.payload?.message || action.error.message,
      };
      positionVolume.data = initialState.positionVolume.data;
    }
  });

  builder.addCase(getImpressionByCohort.pending, ({ impressionByCohort }, action) => {
    if (impressionByCohort.status !== 'loading') {
      impressionByCohort.requestId = action.meta.requestId;
      impressionByCohort.status = 'loading';
      impressionByCohort.error = initialState.impressionByCohort.error;
    }
  });

  builder.addCase(getImpressionByCohort.fulfilled, ({ impressionByCohort }, action) => {
    if (impressionByCohort.status === 'loading' && impressionByCohort.requestId === action.meta.requestId) {
      impressionByCohort.requestId = '';
      impressionByCohort.status = 'success';
      if (action.payload) {
        impressionByCohort.data = action.payload;
      }
    }
  });

  builder.addCase(getImpressionByCohort.rejected, ({ impressionByCohort }, action) => {
    if (impressionByCohort.status === 'loading' && impressionByCohort.requestId === action.meta.requestId) {
      impressionByCohort.requestId = '';
      impressionByCohort.status = 'error';
      impressionByCohort.error = {
        message: action.payload?.message || action.error.message,
      };
      impressionByCohort.data = initialState.impressionByCohort.data;
    }
  });

  builder.addCase(getImpressionByProduct.pending, ({ impressionByProduct }, action) => {
    if (impressionByProduct.status !== 'loading') {
      impressionByProduct.requestId = action.meta.requestId;
      impressionByProduct.status = 'loading';
      impressionByProduct.error = initialState.impressionByProduct.error;
    }
  });

  builder.addCase(getImpressionByProduct.fulfilled, ({ impressionByProduct }, action) => {
    if (impressionByProduct.status === 'loading' && impressionByProduct.requestId === action.meta.requestId) {
      impressionByProduct.requestId = '';
      impressionByProduct.status = 'success';
      if (action.payload) {
        impressionByProduct.data = action.payload;
      }
    }
  });

  builder.addCase(getImpressionByProduct.rejected, ({ impressionByProduct }, action) => {
    if (impressionByProduct.status === 'loading' && impressionByProduct.requestId === action.meta.requestId) {
      impressionByProduct.requestId = '';
      impressionByProduct.status = 'error';
      impressionByProduct.error = {
        message: action.payload?.message || action.error.message,
      };
      impressionByProduct.data = initialState.impressionByProduct.data;
    }
  });

  builder.addCase(getImpressionByOverall.pending, ({ impressionByOverall }, action) => {
    if (impressionByOverall.status !== 'loading') {
      impressionByOverall.requestId = action.meta.requestId;
      impressionByOverall.status = 'loading';
      impressionByOverall.error = initialState.impressionByOverall.error;
    }
  });

  builder.addCase(getImpressionByOverall.fulfilled, ({ impressionByOverall }, action) => {
    if (impressionByOverall.status === 'loading' && impressionByOverall.requestId === action.meta.requestId) {
      impressionByOverall.requestId = '';
      impressionByOverall.status = 'success';
      if (action.payload) {
        impressionByOverall.data = action.payload;
      }
    }
  });

  builder.addCase(getImpressionByOverall.rejected, ({ impressionByOverall }, action) => {
    if (impressionByOverall.status === 'loading' && impressionByOverall.requestId === action.meta.requestId) {
      impressionByOverall.requestId = '';
      impressionByOverall.status = 'error';
      impressionByOverall.error = {
        message: action.payload?.message || action.error.message,
      };
      impressionByOverall.data = initialState.impressionByOverall.data;
    }
  });

  builder.addCase(getCommonCtirByDeployment.pending, ({ commonCtirByDeployment }, action) => {
    if (commonCtirByDeployment.status !== 'loading') {
      commonCtirByDeployment.requestId = action.meta.requestId;
      commonCtirByDeployment.status = 'loading';
      commonCtirByDeployment.error = initialState.commonCtirByDeployment.error;
    }
  });

  builder.addCase(getCommonCtirByDeployment.fulfilled, ({ commonCtirByDeployment }, action) => {
    if (commonCtirByDeployment.status === 'loading' && commonCtirByDeployment.requestId === action.meta.requestId) {
      commonCtirByDeployment.requestId = '';
      commonCtirByDeployment.status = 'success';
      if (action.payload) {
        commonCtirByDeployment.data = action.payload;
      }
    }
  });

  builder.addCase(getCommonCtirByDeployment.rejected, ({ commonCtirByDeployment }, action) => {
    if (commonCtirByDeployment.status === 'loading' && commonCtirByDeployment.requestId === action.meta.requestId) {
      commonCtirByDeployment.requestId = '';
      commonCtirByDeployment.status = 'error';
      commonCtirByDeployment.error = {
        message: action.payload?.message || action.error.message,
      };
      commonCtirByDeployment.data = initialState.commonCtirByDeployment.data;
    }
  });

  builder.addCase(getModulePerformanceOverTime.pending, ({ modulePerformanceOverTime }, action) => {
    if (modulePerformanceOverTime.status !== 'loading') {
      modulePerformanceOverTime.requestId = action.meta.requestId;
      modulePerformanceOverTime.status = 'loading';
      modulePerformanceOverTime.error = initialState.modulePerformanceOverTime.error;
    }
  });

  builder.addCase(getModulePerformanceOverTime.fulfilled, ({ modulePerformanceOverTime }, action) => {
    if (
      modulePerformanceOverTime.status === 'loading' &&
      modulePerformanceOverTime.requestId === action.meta.requestId
    ) {
      modulePerformanceOverTime.requestId = '';
      modulePerformanceOverTime.status = 'success';
      if (action.payload) {
        modulePerformanceOverTime.data = action.payload;
      }
    }
  });

  builder.addCase(getModulePerformanceOverTime.rejected, ({ modulePerformanceOverTime }, action) => {
    if (
      modulePerformanceOverTime.status === 'loading' &&
      modulePerformanceOverTime.requestId === action.meta.requestId
    ) {
      modulePerformanceOverTime.requestId = '';
      modulePerformanceOverTime.status = 'error';
      modulePerformanceOverTime.error = {
        message: action.payload?.message || action.error.message,
      };
      modulePerformanceOverTime.data = initialState.modulePerformanceOverTime.data;
    }
  });

  builder.addCase(getModuleVolumeOverTime.pending, ({ moduleVolumeOverTime }, action) => {
    if (moduleVolumeOverTime.status !== 'loading') {
      moduleVolumeOverTime.requestId = action.meta.requestId;
      moduleVolumeOverTime.status = 'loading';
      moduleVolumeOverTime.error = initialState.moduleVolumeOverTime.error;
    }
  });

  builder.addCase(getModuleVolumeOverTime.fulfilled, ({ moduleVolumeOverTime }, action) => {
    if (moduleVolumeOverTime.status === 'loading' && moduleVolumeOverTime.requestId === action.meta.requestId) {
      moduleVolumeOverTime.requestId = '';
      moduleVolumeOverTime.status = 'success';
      if (action.payload) {
        moduleVolumeOverTime.data = action.payload;
      }
    }
  });

  builder.addCase(getModuleVolumeOverTime.rejected, ({ moduleVolumeOverTime }, action) => {
    if (moduleVolumeOverTime.status === 'loading' && moduleVolumeOverTime.requestId === action.meta.requestId) {
      moduleVolumeOverTime.requestId = '';
      moduleVolumeOverTime.status = 'error';
      moduleVolumeOverTime.error = {
        message: action.payload?.message || action.error.message,
      };
      moduleVolumeOverTime.data = initialState.moduleVolumeOverTime.data;
    }
  });

  builder.addCase(getSummaryPerformance.pending, ({ summaryPerformance }, action) => {
    if (summaryPerformance.status !== 'loading') {
      summaryPerformance.requestId = action.meta.requestId;
      summaryPerformance.status = 'loading';
      summaryPerformance.error = initialState.summaryPerformance.error;
    }
  });

  builder.addCase(getSummaryPerformance.fulfilled, ({ summaryPerformance }, action) => {
    if (summaryPerformance.status === 'loading' && summaryPerformance.requestId === action.meta.requestId) {
      summaryPerformance.requestId = '';
      summaryPerformance.status = 'success';
      if (action.payload) {
        summaryPerformance.data = {
          total: action.payload.sum,
          items: action.payload.summaryPerformanceData,
        };
      }
    }
  });

  builder.addCase(getSummaryPerformance.rejected, ({ summaryPerformance }, action) => {
    if (summaryPerformance.status === 'loading' && summaryPerformance.requestId === action.meta.requestId) {
      summaryPerformance.requestId = '';
      summaryPerformance.status = 'error';
      summaryPerformance.error = {
        message: action.payload?.message || action.error.message,
      };
      summaryPerformance.data = initialState.summaryPerformance.data;
    }
  });

  builder.addCase(getClicksToOpenReport.pending, ({ clickToOpenReport }, action) => {
    if (clickToOpenReport.status !== 'loading') {
      clickToOpenReport.requestId = action.meta.requestId;
      clickToOpenReport.status = 'loading';
      clickToOpenReport.error = initialState.clickToOpenReport.error;
    }
  });

  builder.addCase(getClicksToOpenReport.fulfilled, ({ clickToOpenReport }, action) => {
    if (clickToOpenReport.status === 'loading' && clickToOpenReport.requestId === action.meta.requestId) {
      clickToOpenReport.requestId = '';
      clickToOpenReport.status = 'success';
      if (action.payload) {
        clickToOpenReport.data = action.payload;
      }
    }
  });

  builder.addCase(getClicksToOpenReport.rejected, ({ clickToOpenReport }, action) => {
    if (clickToOpenReport.status === 'loading' && clickToOpenReport.requestId === action.meta.requestId) {
      clickToOpenReport.requestId = '';
      clickToOpenReport.status = 'error';
      clickToOpenReport.error = {
        message: action.payload?.message || action.error.message,
      };
      clickToOpenReport.data = initialState.clickToOpenReport.data;
    }
  });

  builder.addCase(getOverallMetrics.pending, ({ overallMetrics }, action) => {
    if (overallMetrics.status !== 'loading') {
      overallMetrics.requestId = action.meta.requestId;
      overallMetrics.status = 'loading';
      overallMetrics.error = initialState.overallMetrics.error;
    }
  });

  builder.addCase(getOverallMetrics.fulfilled, ({ overallMetrics }, action) => {
    if (overallMetrics.status === 'loading' && overallMetrics.requestId === action.meta.requestId) {
      overallMetrics.requestId = '';
      overallMetrics.status = 'success';
      if (action.payload) {
        overallMetrics.data = action.payload;
      }
    }
  });

  builder.addCase(getOverallMetrics.rejected, ({ overallMetrics }, action) => {
    if (overallMetrics.status === 'loading' && overallMetrics.requestId === action.meta.requestId) {
      overallMetrics.requestId = '';
      overallMetrics.status = 'error';
      overallMetrics.error = {
        message: action.payload?.message || action.error.message,
      };
      overallMetrics.data = initialState.overallMetrics.data;
    }
  });

  builder.addCase(getClickRate.pending, ({ clickRate }, action) => {
    if (clickRate.status !== 'loading') {
      clickRate.requestId = action.meta.requestId;
      clickRate.status = 'loading';
      clickRate.error = initialState.clickRate.error;
    }
  });

  builder.addCase(getClickRate.fulfilled, ({ clickRate }, action) => {
    if (clickRate.status === 'loading' && clickRate.requestId === action.meta.requestId) {
      clickRate.requestId = '';
      clickRate.status = 'success';
      if (action.payload) {
        clickRate.data = action.payload;
      }
    }
  });

  builder.addCase(getClickRate.rejected, ({ clickRate }, action) => {
    if (clickRate.status === 'loading' && clickRate.requestId === action.meta.requestId) {
      clickRate.requestId = '';
      clickRate.status = 'error';
      clickRate.error = {
        message: action.payload?.message || action.error.message,
      };
      clickRate.data = initialState.clickRate.data;
    }
  });

  builder.addCase(getOptOutRate.pending, ({ optOutRate }, action) => {
    if (optOutRate.status !== 'loading') {
      optOutRate.requestId = action.meta.requestId;
      optOutRate.status = 'loading';
      optOutRate.error = initialState.optOutRate.error;
    }
  });

  builder.addCase(getOptOutRate.fulfilled, ({ optOutRate }, action) => {
    if (optOutRate.status === 'loading' && optOutRate.requestId === action.meta.requestId) {
      optOutRate.requestId = '';
      optOutRate.status = 'success';
      if (action.payload) {
        optOutRate.data = action.payload;
      }
    }
  });

  builder.addCase(getOptOutRate.rejected, ({ optOutRate }, action) => {
    if (optOutRate.status === 'loading' && optOutRate.requestId === action.meta.requestId) {
      optOutRate.requestId = '';
      optOutRate.status = 'error';
      optOutRate.error = {
        message: action.payload?.message || action.error.message,
      };
      optOutRate.data = initialState.optOutRate.data;
    }
  });

  builder.addCase(getOpenRate.pending, ({ openRate }, action) => {
    if (openRate.status !== 'loading') {
      openRate.requestId = action.meta.requestId;
      openRate.status = 'loading';
      openRate.error = initialState.openRate.error;
    }
  });

  builder.addCase(getOpenRate.fulfilled, ({ openRate }, action) => {
    if (openRate.status === 'loading' && openRate.requestId === action.meta.requestId) {
      openRate.requestId = '';
      openRate.status = 'success';
      if (action.payload) {
        openRate.data = action.payload;
      }
    }
  });

  builder.addCase(getOpenRate.rejected, ({ openRate }, action) => {
    if (openRate.status === 'loading' && openRate.requestId === action.meta.requestId) {
      openRate.requestId = '';
      openRate.status = 'error';
      openRate.error = {
        message: action.payload?.message || action.error.message,
      };
      openRate.data = initialState.openRate.data;
    }
  });

  builder.addCase(getAmriChangeReport.pending, ({ amriReport }, action) => {
    if (amriReport.status !== 'loading') {
      amriReport.requestId = action.meta.requestId;
      amriReport.status = 'loading';
      amriReport.error = initialState.amriReport.error;
    }
  });

  builder.addCase(getAmriChangeReport.fulfilled, ({ amriReport }, action) => {
    if (amriReport.status === 'loading' && amriReport.requestId === action.meta.requestId) {
      amriReport.requestId = '';
      amriReport.status = 'success';
      if (action.payload) {
        amriReport.data = action.payload;
      }
    }
  });

  builder.addCase(getAmriChangeReport.rejected, ({ amriReport }, action) => {
    if (amriReport.status === 'loading' && amriReport.requestId === action.meta.requestId) {
      amriReport.requestId = '';
      amriReport.status = 'error';
      amriReport.error = {
        message: action.payload?.message || action.error.message,
      };
      amriReport.data = initialState.amriReport.data;
    }
  });

  builder.addCase(getBenchmarkingReportEnterprise.pending, ({ benchmarkingReportEnterprise }, action) => {
    if (benchmarkingReportEnterprise.status !== 'loading') {
      benchmarkingReportEnterprise.requestId = action.meta.requestId;
      benchmarkingReportEnterprise.status = 'loading';
      benchmarkingReportEnterprise.error = initialState.benchmarkingReportEnterprise.error;
    }
  });

  builder.addCase(getBenchmarkingReportEnterprise.fulfilled, ({ benchmarkingReportEnterprise }, action) => {
    if (
      benchmarkingReportEnterprise.status === 'loading' &&
      benchmarkingReportEnterprise.requestId === action.meta.requestId
    ) {
      benchmarkingReportEnterprise.requestId = '';
      benchmarkingReportEnterprise.status = 'success';
      if (action.payload) {
        benchmarkingReportEnterprise.data = action.payload;
      }
    }
  });

  builder.addCase(getBenchmarkingReportEnterprise.rejected, ({ benchmarkingReportEnterprise }, action) => {
    if (
      benchmarkingReportEnterprise.status === 'loading' &&
      benchmarkingReportEnterprise.requestId === action.meta.requestId
    ) {
      benchmarkingReportEnterprise.requestId = '';
      benchmarkingReportEnterprise.status = 'error';
      benchmarkingReportEnterprise.error = {
        message: action.payload?.message || action.error.message,
      };
      benchmarkingReportEnterprise.data = initialState.benchmarkingReportBU.data;
    }
  });

  builder.addCase(getBenchmarkingReportBU.pending, ({ benchmarkingReportBU }, action) => {
    if (benchmarkingReportBU.status !== 'loading') {
      benchmarkingReportBU.requestId = action.meta.requestId;
      benchmarkingReportBU.status = 'loading';
      benchmarkingReportBU.error = initialState.benchmarkingReportBU.error;
    }
  });

  builder.addCase(getBenchmarkingReportBU.fulfilled, ({ benchmarkingReportBU }, action) => {
    if (benchmarkingReportBU.status === 'loading' && benchmarkingReportBU.requestId === action.meta.requestId) {
      benchmarkingReportBU.requestId = '';
      benchmarkingReportBU.status = 'success';
      if (action.payload) {
        benchmarkingReportBU.data = action.payload;
      }
    }
  });

  builder.addCase(getBenchmarkingReportBU.rejected, ({ benchmarkingReportBU }, action) => {
    if (benchmarkingReportBU.status === 'loading' && benchmarkingReportBU.requestId === action.meta.requestId) {
      benchmarkingReportBU.requestId = '';
      benchmarkingReportBU.status = 'error';
      benchmarkingReportBU.error = {
        message: action.payload?.message || action.error.message,
      };
      benchmarkingReportBU.data = initialState.benchmarkingReportBU.data;
    }
  });

  builder.addCase(getTopPerformingContentReport.pending, ({ topPerformingContent }, action) => {
    if (topPerformingContent.status !== 'loading') {
      topPerformingContent.requestId = action.meta.requestId;
      topPerformingContent.status = 'loading';
      topPerformingContent.error = initialState.topPerformingContent.error;
    }
  });

  builder.addCase(getTopPerformingContentReport.fulfilled, ({ topPerformingContent }, action) => {
    if (topPerformingContent.status === 'loading' && topPerformingContent.requestId === action.meta.requestId) {
      topPerformingContent.requestId = '';
      topPerformingContent.status = 'success';
      if (action.payload) {
        topPerformingContent.data = action.payload;
      }
    }
  });

  builder.addCase(getTopPerformingContentReport.rejected, ({ topPerformingContent }, action) => {
    if (topPerformingContent.status === 'loading' && topPerformingContent.requestId === action.meta.requestId) {
      topPerformingContent.requestId = '';
      topPerformingContent.status = 'error';
      topPerformingContent.error = {
        message: action.payload?.message || action.error.message,
      };
      topPerformingContent.data = initialState.topPerformingContent.data;
    }
  });

  builder.addCase(getLowPerformingContentReport.pending, ({ lowPerformingContent }, action) => {
    if (lowPerformingContent.status !== 'loading') {
      lowPerformingContent.requestId = action.meta.requestId;
      lowPerformingContent.status = 'loading';
      lowPerformingContent.error = initialState.lowPerformingContent.error;
    }
  });

  builder.addCase(getLowPerformingContentReport.fulfilled, ({ lowPerformingContent }, action) => {
    if (lowPerformingContent.status === 'loading' && lowPerformingContent.requestId === action.meta.requestId) {
      lowPerformingContent.requestId = '';
      lowPerformingContent.status = 'success';
      if (action.payload) {
        lowPerformingContent.data = action.payload;
      }
    }
  });

  builder.addCase(getLowPerformingContentReport.rejected, ({ lowPerformingContent }, action) => {
    if (lowPerformingContent.status === 'loading' && lowPerformingContent.requestId === action.meta.requestId) {
      lowPerformingContent.requestId = '';
      lowPerformingContent.status = 'error';
      lowPerformingContent.error = {
        message: action.payload?.message || action.error.message,
      };
      lowPerformingContent.data = initialState.lowPerformingContent.data;
    }
  });
};
