import { BadgeColorByContentStatus } from './MindsetTable.types';

/**
 * MindsetTable component constants
 * @returns {variables}
 */

export const PAGE_BOTTOM_GAP = '2.5rem';

export const BADGE_COLORS: BadgeColorByContentStatus = {
  none: 'transparent',
  html: 'green',
};

export const STAGES_MIN = 4; // stages (regular + default) amount

export const COHORT_CODE_NULLS = 'NULS';
