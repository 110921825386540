import { Image } from '@modules';

import { DividedImages } from './ImageLibrary.types';

/**
 * ImageLibrary utils
 */

export const getConvertedImagesByWidth = (images: Image[], newWidth = 120) => {
  //Сonvert images proportionally to given width.
  return images.map((image) => ({
    ...image,
    previewWidth: newWidth,
    previewHeight: image?.height / (image?.width / newWidth),
  }));
};

export const divideImagesIntoTwoGroups = (images: Image[]) => {
  //Divide images into two same groups by height.
  let firstColumnHeight = 0;
  let secondColumnHeight = 0;

  const defaultSameArraysValue = {
    firstColumn: [],
    secondColumn: [],
  };

  const sameArrays = images.reduce((accum: DividedImages, current: Image) => {
    if (firstColumnHeight <= secondColumnHeight) {
      firstColumnHeight += current.height;
      accum?.firstColumn.push(current);
    } else {
      secondColumnHeight += current.height;
      accum?.secondColumn.push(current);
    }

    return accum;
  }, defaultSameArraysValue);

  return sameArrays;
};
