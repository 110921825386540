import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: variables.gap.xl,
      justifyContent: 'space-between',
    },

    viewFullLibrary: {
      padding: `0.4rem ${variables.gap.lg}`,
    },

    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(21.3rem, 1fr))',
      gridColumnGap: variables.gap.lg,
      gridRowGap: variables.gap.lg,
    },
  }),
);
