import { SVGProps } from 'react';

export const Eye = ({ width = 16, height = 17, stroke = '#89939C' }: SVGProps<SVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.98937 4C5.55375 4 3.05531 5.40969 1.08844 8.22906C1.03229 8.31042 1.00153 8.4066 1.00003 8.50544C0.998525 8.60428 1.02636 8.70135 1.08 8.78438C2.59125 11.15 5.05625 13 7.98937 13C10.8906 13 13.4062 11.1444 14.9203 8.77344C14.9727 8.69203 15.0006 8.59727 15.0006 8.50047C15.0006 8.40366 14.9727 8.3089 14.9203 8.2275C13.4028 5.88375 10.8687 4 7.98937 4Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 11C9.38071 11 10.5 9.88071 10.5 8.5C10.5 7.11929 9.38071 6 8 6C6.61929 6 5.5 7.11929 5.5 8.5C5.5 9.88071 6.61929 11 8 11Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
