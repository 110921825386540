import { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Confirm, FileUpload, FileUploadProps } from '@components';
import { textTemplate } from '@utils';
import { content } from '@content';

import { UploadEmailActivityProps } from './UploadEmailActivity.props';
import { FieldId, FormValues } from './UploadEmailActivity.types';
import { FIELDS } from './UploadEmailActivity.const';
import { useFormValues } from './UploadEmailActivity.hooks';
import { useStyles } from './UploadEmailActivity.styles';

export const UploadEmailActivity = ({ entity, onClose, onSubmit }: UploadEmailActivityProps): JSX.Element => {
  const styles = useStyles();

  const { defaultValues, validateValue, getFreshValues } = useFormValues(entity);

  const { control, handleSubmit, clearErrors, setValue, setError } = useForm<FormValues>({
    defaultValues,
  });

  const handleFileChange: FileUploadProps<FieldId>['onChange'] = useCallback(
    (file, id) => {
      clearErrors(id);

      const message = validateValue(file);

      if (message) {
        setError(id, { message });
      }

      setValue(id, file || undefined);
    },
    [clearErrors, setError, setValue, validateValue],
  );

  const handleConfirmSubmit = useCallback<SubmitHandler<FormValues>>(
    (values) => {
      onSubmit('emailActivity', getFreshValues(values));
      onClose();
    },
    [onSubmit, getFreshValues, onClose],
  );

  return (
    <Confirm
      open
      maxWidth="64.4rem"
      title={content.emailActivityFile}
      bodyClassName={styles.uploadEmailActivity}
      submitLabel={content.upload}
      skipSubmitArrow
      onCancel={onClose}
      onSubmit={handleSubmit(handleConfirmSubmit)}
    >
      {FIELDS.map(({ id, event }) => (
        <Controller
          key={id}
          name={id}
          control={control}
          rules={{ validate: validateValue }}
          render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
            <FileUpload
              {...field}
              className={styles.field}
              uiType="secondary"
              inputRef={ref}
              id={id}
              extensions={['csv']}
              label={textTemplate(content.eventFile, { event: content[event] })}
              hint={error?.message}
              error={invalid}
              value={(field.value as File)?.name}
              onChange={handleFileChange}
            />
          )}
        />
      ))}
    </Confirm>
  );
};
