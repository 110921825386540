export const FORM_FIELD_TYPE = {
  txt: 'text',
  pwd: 'password',
  cbx: 'checkbox',
};

export const FORM_FIELD_DEFAULT_VALUE = {
  txt: '',
  pwd: '',
  cbx: false,
};
