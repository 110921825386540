import { makeStyles, createStyles } from '@material-ui/core';

import { SectionStyleProps } from './Section.types';

export const useStyles = makeStyles((theme) =>
  createStyles({
    section: ({ range, gap = 3 }: SectionStyleProps) => {
      const width = `${(range * 100) / 12}%`;

      return {
        width,
        minWidth: width,
        maxWidth: width,

        '&:not(:last-child)': {
          marginRight: theme.spacing(gap),
        },
      };
    },
  }),
);
