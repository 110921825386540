import { createAsyncThunk } from '@store';
import { BarChartData, ApiError } from '@modules';
import { network } from '@utils';
import {
  PositionVolumeDeploymentsDataSchema,
  ImpressionByCohortDataSchema,
  ImpressionByProductDataSchema,
  ImpressionByOverallDataSchema,
  CommonCtirByDeploymentDataSchema,
  SummaryPerformanceItemSchema,
  OverallMetricsDataSchema,
  AmriReportDataSchema,
  BenchmarkingReportDataSchema,
  GetAmriReportParams,
  GetReportEnterpriseParams,
  GetBenchmarkingReportParams,
  GetPerformingContentParams,
  PerformingContentDataSchema,
} from './Analytics.schema';

export const getNumberOfModulesPerPosition = createAsyncThunk<
  {
    average: number;
    deployments: BarChartData[];
  } | void,
  {
    program: number;
    mailPart: string;
  }
>('analytics/getNumberOfModulesPerPosition', async ({ program, mailPart }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'modules_per_position', params: { program, mailPart } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getCtirPerPosition = createAsyncThunk<
  {
    average: number;
    deploymentsData: BarChartData[];
  } | void,
  {
    program: number;
    mailPart: string;
  }
>('analytics/getCtirPerPosition', async ({ program, mailPart }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'ctir_by_position', params: { program, mailPart } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getPositionVolume = createAsyncThunk<
  {
    average: number;
    deploymentsCount: number;
    deploymentsData: PositionVolumeDeploymentsDataSchema[];
  } | void,
  {
    program: number;
    mailPart: string;
  }
>('analytics/getPositionVolume', async ({ program, mailPart }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_position_volume', params: { program, mailPart } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getImpressionByCohort = createAsyncThunk<
  ImpressionByCohortDataSchema | void,
  {
    deployment: string;
    sort: string;
  }
>('analytics/getImpressionByCohort', async ({ deployment, sort }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_module_performance_by_position', params: { deployment, sort } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getImpressionByProduct = createAsyncThunk<
  ImpressionByProductDataSchema | void,
  {
    deployment: string;
    sort: string;
  }
>('analytics/getImpressionByProduct', async ({ deployment, sort }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_module_performance_by_position', params: { deployment, sort } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getImpressionByOverall = createAsyncThunk<
  ImpressionByOverallDataSchema | void,
  {
    deployment: string;
    sort: string;
  }
>('analytics/getImpressionByOverall', async ({ deployment, sort }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_module_performance_by_position', params: { deployment, sort } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getCommonCtirByDeployment = createAsyncThunk<
  CommonCtirByDeploymentDataSchema[] | void,
  {
    deployment: string;
  }
>('analytics/getCommonCtirByDeployment', async ({ deployment }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_module_performance_by_position', params: { deployment } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getSummaryPerformance = createAsyncThunk<
  {
    sum: number;
    summaryPerformanceData: SummaryPerformanceItemSchema[];
  } | void,
  {
    program: string | number;
  }
>('analytics/getSummaryPerformance', ({ program }, { rejectWithValue }) => {
  try {
    return network.get({ key: 'summary_performance', params: { program } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getModulePerformanceOverTime = createAsyncThunk<
  {
    average: number;
    deployments: BarChartData[];
  } | void,
  {
    module: string;
  }
>('analytics/getModulePerformanceOverTime', ({ module }, { rejectWithValue }) => {
  try {
    return network.get({ key: 'module_performance_over_time', params: { module } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getModuleVolumeOverTime = createAsyncThunk<
  {
    average: number;
    deploymentsData: BarChartData[];
  } | void,
  {
    module: string;
  }
>('analytics/getModuleVolumeOverTime', ({ module }, { rejectWithValue }) => {
  try {
    return network.get({ key: 'get_module_volume', params: { module } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getClicksToOpenReport = createAsyncThunk<
  {
    average: number;
    deploymentsData: BarChartData[];
    trend: number;
  } | void,
  {
    program: number;
  }
>('analytics/getClicksToOpenReport', ({ program }, { rejectWithValue }) => {
  try {
    return network.get({ key: 'get_clicks_to_open_report', params: { program } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getOverallMetrics = createAsyncThunk<
  OverallMetricsDataSchema | void,
  {
    program: number;
  }
>('analytics/getOverallMetrics', ({ program }, { rejectWithValue }) => {
  try {
    return network.get({ key: 'overall_metrics', params: { program } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getClickRate = createAsyncThunk<
  {
    average: number;
    deploymentsData: BarChartData[];
    deploymentsCount: number;
  } | void,
  {
    program: number;
  }
>('analytics/getClickRate', ({ program }, { rejectWithValue }) => {
  try {
    return network.get({ key: 'click_rate', params: { program } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getOptOutRate = createAsyncThunk<
  {
    average: number;
    deploymentsData: BarChartData[];
    deploymentsCount: number;
    trend: number;
  } | void,
  {
    program: number;
  }
>('analytics/getOptOutRate', ({ program }, { rejectWithValue }) => {
  try {
    return network.get({ key: 'opt_out_rate', params: { program } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getOpenRate = createAsyncThunk<
  {
    average: number;
    deploymentsData: BarChartData[];
    deploymentsCount: number;
    trend: number;
  } | void,
  {
    program: number;
  }
>('analytics/getOpenRate', ({ program }, { rejectWithValue }) => {
  try {
    return network.get({ key: 'get_open_rate_by_deployment_report', params: { program } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getAmriChangeReport = createAsyncThunk(
  'analytics/getAmriChangeReport',
  ({ businessUnit }: GetAmriReportParams, { rejectWithValue }) => {
    try {
      return network.get<AmriReportDataSchema>({
        key: 'get_amri_change_report_by_business_unit',
        params: { businessUnit: businessUnit },
      });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const getBenchmarkingReportEnterprise = createAsyncThunk(
  'analytics/getBenchmarkingReportEnterprise',
  (params: GetReportEnterpriseParams, { rejectWithValue, signal }) => {
    try {
      return network.get<BenchmarkingReportDataSchema>(
        { key: 'get_enterprise_benchmarking_report', params },
        { signal },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const getBenchmarkingReportBU = createAsyncThunk(
  'analytics/getBenchmarkingReportBU',
  ({ businessUnit }: GetBenchmarkingReportParams, { rejectWithValue, signal }) => {
    try {
      return network.get<BenchmarkingReportDataSchema>(
        { key: 'get_benchmarking_report_by_business_unit', params: { businessUnit: businessUnit } },
        { signal },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const getTopPerformingContentReport = createAsyncThunk(
  'analytics/getTopPerformingContentReport',
  ({ businessUnit }: GetPerformingContentParams, { rejectWithValue }) => {
    try {
      return network.get<PerformingContentDataSchema>({
        key: 'get_top_performing_content_report_by_business_unit',
        params: { businessUnit: businessUnit },
      });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const getLowPerformingContentReport = createAsyncThunk(
  'analytics/getLowPerformingContentReport',
  ({ businessUnit }: GetPerformingContentParams, { rejectWithValue }) => {
    try {
      return network.get<PerformingContentDataSchema>({
        key: 'get_lowest_performing_content_report_by_business_unit',
        params: { businessUnit: businessUnit },
      });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);
