import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ImageContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    DVContainer: {
      backgroundColor: variables.color.primary.white,

      '&[data-ui-type=primary]': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),

        '& $imageSection': {
          marginBottom: theme.spacing(2),
        },

        '& $caption': {
          marginBottom: theme.spacing(1),
        },
      },
    },

    actions: {
      display: 'flex',
      padding: '10px 16px 16px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    deployment: {
      width: '200px',
    },

    generateImagesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },

    DVSegmentItem: {
      marginTop: '15px',
    },

    progressItem: {
      position: 'relative',
      display: 'flex',
      gap: '15px',
      alignItems: 'center',
      marginBottom: '3px',
      marginTop: '10px',
    },

    DVSegmentItemLabel: {
      fontWeight: 'bold',
    },

    DVSegmentItemName: {
      lineHeight: '12px',
      marginBottom: '5px',
    },

    genStatusLabel: {
      width: '150px',
      fontWeight: 'bold',
    },

    infoItem: {
      display: 'flex',
      gap: '15px',
      marginTop: '20px',
    },

    infoItemLabel: {
      fontWeight: 'bold',
    },

    grayText: {
      color: variables.color.secondary.gray,
    },

    loading: {
      transform: 'scale(0.5)',
      marginTop: '-10px',
    },

    generateBtn: {
      width: '138px',
      height: '33px',
    },

    imageProgress: {
      width: 'calc(100% - 162px)',
    },

    uploadDefaultImage: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },

    genResult: {
      position: 'absolute',
      fontWeight: 'initial',
      right: '0',
    },

    selectBtn: {
      marginLeft: theme.spacing(1),
      width: '6.5rem',
      height: '3.2rem',
    },

    closeBtn: {
      width: '6.5rem',
      height: '3.2rem',
    },

    label: {
      display: 'flex',
      gap: '5px',
      marginTop: '0',
      marginBottom: '5px',
      fontWeight: 800,
    },

    feature: {
      marginBottom: '25px',
    },

    featureSection: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '20px',
    },
    featureCaption: {
      color: variables.color.primary.darkestGray,
    },
    featureTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: '10px',
    },
    featureInput: {
      width: '100%',
    },

    sourceCodeSection: {
      display: 'flex',
      gap: '10px',
      marginBottom: '10px',
    },

    codeEditor: {
      maxHeight: 'calc(100vh - 460px)',
    },

    titleWrapper: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    title: {
      fontSize: '16px!important',
      lineHeight: '20px!important',
      color: `${variables.color.primary.darkPurple}!important`,
      paddingTop: '9px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    content: {
      padding: '10px 16px 16px',
      flexDirection: 'column',
    },

    itemValue: {
      fontWeight: 700,
    },

    save: {
      marginTop: '20px',
      marginRight: 'auto',
    },
  }),
);
