import { Confirm } from '@components';
import { content } from '@content';

import { ChangeTemplateModalProps } from './ChangeTemplateModal.props';
import { Box } from '@material-ui/core';

/**
 * ChangeTemplateModal component
 * @returns {JSX.Element}
 */

export const ChangeTemplateModal = ({
  title,
  submitLabel = content.save,
  cancelLabel = content.cancel,
  onClose,
  onSubmit,
}: ChangeTemplateModalProps): JSX.Element => {
  return (
    <Confirm
      open
      padding="0"
      maxWidth="41.6rem"
      submitLabel={submitLabel}
      cancelLabel={cancelLabel}
      skipSubmitArrow
      title={title}
      onCancel={onClose}
      onSubmit={onSubmit}
    >
      <Box style={{ fontSize: '16px' }}>
        By changing the template, your modules and user stories placements will be permamently lost.
      </Box>
    </Confirm>
  );
};
