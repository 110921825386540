import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

import { ALIGN_DEFAULT, SHIFT_BY_GAP, GAP_DEFAULT, SHIFT_DEFAULT, SIZE_DEFAULT, POS_DEFAULT } from './Tooltip.const';
import { TooltipStyle } from './Tooltip.props';

/**
 * Tooltip component styles
 * @returns {Function}
 */

const backgroundColor = variables.color.primary.white;
const boxShadow = '0 0 2rem rgba(0, 0, 0, 0.1)';

const otherTooltipStyles = {
  width: '38rem',
  borderRadius: '1.6rem',
  padding: 0,
};

export const useStyles = makeStyles(() =>
  createStyles({
    popper: ({ align = ALIGN_DEFAULT, gap = GAP_DEFAULT, shift = SHIFT_DEFAULT, pos = POS_DEFAULT }: TooltipStyle) => ({
      ...(pos === 'fix'
        ? ({
            position: 'fixed !important',
          } as Record<string, unknown>)
        : null),

      ...(shift
        ? {
            [align === 'end' ? 'right' : 'left']: `${variables.gap[shift]} !important`,
          }
        : null),

      pointerEvents: 'auto',

      '&[x-placement*="bottom"]': {
        top: `-${variables.gap[SHIFT_BY_GAP[gap]]} !important`,
      },

      '&[x-placement*="top"]': {
        top: `${variables.gap[SHIFT_BY_GAP[gap]]} !important`,
      },
    }),

    arrow: ({ align = ALIGN_DEFAULT }: TooltipStyle) => ({
      marginLeft: 'initial !important',
      marginRight: 'initial !important',
      color: backgroundColor,
      fontSize: variables.font.size.xxxl,

      '&:before': {
        border: 'none',
      },

      ...{
        start: {
          left: '0 !important',
          transform: 'translateX(25%) !important',
        },

        center: {
          left: '50% !important',
          transform: 'translateX(-50%) !important',
        },

        end: {
          left: 'auto !important',
          right: '0 !important',
          transform: 'translateX(-25%) !important',
        },

        top: {
          top: '99.7%',
          left: '50% !important',
          transform: 'translateX(-50%) !important',

          '[x-placement*="bottom"] &': {
            top: '0.1rem !important',
          },
        },
      }[align],
    }),

    tooltip: ({ size = SIZE_DEFAULT }: TooltipStyle) => ({
      backgroundColor,
      boxShadow,
      ...otherTooltipStyles,
      color: variables.color.primary.darkestGray,
      fontSize: variables.font.size[size],
    }),
  }),
);
