import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const varStartGap = '4rem';

const truncatedText = {
  'white-space': 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const moduleField = {
  ...({ position: 'relative' } as any), // eslint-disable-line @typescript-eslint/no-explicit-any

  '&[data-type="var"]': {
    padding: `0 ${variables.gap.lg}`,
  },

  '&[data-type="htm"]': {
    padding: `${variables.gap.sm} ${variables.gap.lg}`,
    borderRadius: variables.borderRadius.sm,
    color: variables.color.tertiary.gray,

    '&, & *': {
      background: variables.color.secondary.base,
    },

    '& .MuiInput-multiline': {
      '&, &:hover': {
        '&::before, &::after': {
          border: 0,
        },
      },
    },
  },
};

const copy = {
  ...({ position: 'absolute' } as any), // eslint-disable-line @typescript-eslint/no-explicit-any
  zIndex: 1,
  width: '2rem',
  height: '2rem',
  padding: 0,
  borderRadius: variables.borderRadius.sm,

  '&:hover *': {
    stroke: variables.color.secondary.blue,
  },

  '&[data-type="url"], &[data-type="txt"]': {
    right: 0,
    width: '1.8rem',
    height: '1.8rem',
  },

  '&.Mui-disabled': {
    opacity: variables.opacity.medium,
  },
};

const label = {
  ...truncatedText,

  '&[data-type="var"]': {
    paddingLeft: varStartGap,
  },

  '&[data-type="htm"]': {
    color: variables.color.tertiary.gray,
  },
};

const text = {
  ...truncatedText,

  '&[data-style="title"]': {
    lineHeight: '3.5rem',
  },
};

const input = {
  width: '100%',
};

const muiInput = {
  ...truncatedText,
  height: '3rem',
  padding: '0 !important',

  '&[data-type="var"]': {
    color: variables.color.primary.lightPurple,
    paddingLeft: `${varStartGap} !important`,
  },

  '&[data-type="htm"]': {
    height: 'auto',
  },
};

const variable = {
  color: muiInput['&[data-type="var"]'].color,
};

export const useStyles = makeStyles(() =>
  createStyles({
    moduleField,
    copy,
    label,
    text,
    input,
    muiInput,
    variable,

    error: {
      paddingTop: '0.3rem',
      borderTop: `${variables.borderWidth.sm} solid ${variables.color.secondary.orange}`,

      '&&': {
        fontWeight: variables.font.weight.bold,
        color: variables.color.secondary.orange,
      },
    },
  }),
);
