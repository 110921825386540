import { variables } from '@styles';

import { TableUiType, TableMeasures } from './Table.props';

/**
 * Table component variables
 * @returns {Variables}
 */

export const PX_PER_REM = 10;

export const VIRTUAL_DELAY = 40; // ms

export const VIRTUAL_EDGE = 30;

export const HEIGHT_AUTOFIT_DELAY = 40; // ms

export const MEASURES_BY_UI_TYPE: Record<TableUiType, TableMeasures> = {
  primary: {
    headBgColor: `rgba(255, 255, 255, ${variables.opacity.high})`,
    recordHeight: '8.5rem',
    recordGap: variables.gap.xs,
    recordShadowWidth: '0.1rem',
    recordShadowBlur: '0.4rem',
    recordBorderWidth: variables.borderWidth.sm,
    pageSize: 6,
    bottomGap: '0rem',
    virtualBuffer: 8,
    tooltipShiftY: 'xxs',
  },
  secondary: {
    headBgColor: variables.color.primary.white,
    recordHeight: '12rem',
    recordGap: variables.gap.xs,
    recordShadowWidth: '0rem',
    recordShadowBlur: '0rem',
    recordBorderWidth: variables.borderWidth.sm,
    pageSize: Number.NaN,
    bottomGap: '2.5rem',
    virtualBuffer: 7,
    tooltipShiftY: 'xxxs',
  },
  tertiary: {
    headBgColor: variables.color.primary.white,
    recordHeight: '5rem',
    recordGap: '0',
    recordShadowWidth: '0rem',
    recordShadowBlur: '0rem',
    recordBorderWidth: variables.borderWidth.sm,
    pageSize: Number.NaN,
    bottomGap: '2.5rem',
    virtualBuffer: 7,
    tooltipShiftY: 'xxxs',
  },
};
