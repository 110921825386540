import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';

import { enterprise, EnterpriseUpdateArg, userClients, Client, EnterpriseClient, auth } from '@modules';
import { useAppDispatch } from '@store';
import { content } from '@content';
import { useLoader, useHistory, useWebTitle, textTemplate } from '@utils';

import { List, ListProps } from './List';
import { ClientEditor, ClientEditorProps } from './ClientEditor';
import { EnterpriseEditor, EnterpriseEditorProps } from './EnterpriseEditor';

import { useStyles } from './AdminClients.styles';
import { Mode } from './AdminClients.types';

export const AdminClients = (): JSX.Element => {
  const styles = useStyles();

  const history = useHistory();

  const { id: businessUnitSlugId, enterprise: enterpriseId, businessUnit: querybusinessUnitId } = history.query;

  const dispatch = useAppDispatch();

  const [mode, setMode] = useState<Mode>({ type: 'list', enterprise: 0, listReload: true });

  const [alert, setAlert] = useState(false);

  const list = enterprise.useFullListData();

  const subject = userClients.useSubjectData();

  const businessUnit = useMemo(() => {
    try {
      if (subject) {
        const { uniqueIdKey, ...copy } = JSON.parse(JSON.stringify(subject)) as typeof subject;

        return { ...copy, uniqueId: uniqueIdKey } as Client;
      }
    } catch (exc) {
      // subj parsing failed
    }

    return subject;
  }, [subject]);

  const currEnterprise = useMemo(() => list.find((item) => item.id === mode.enterprise), [list, mode.enterprise]);

  const webTitleDetail = useMemo(() => {
    if (mode.type === 'enterprise') {
      return currEnterprise?.name || textTemplate(content.addValue, { value: content.enterprise });
    }

    if (mode.type === 'businessUnit') {
      return businessUnit?.name || textTemplate(content.addValue, { value: content.businessUnit });
    }
  }, [mode.type, currEnterprise?.name, businessUnit?.name]);

  useWebTitle(!webTitleDetail && content.settings, content.enterpriseAndBusinessUnits, webTitleDetail);

  useLoader(enterprise.useListMeta(), userClients.useSubjectMeta());

  const navToList = useCallback(
    (listReload: Mode['listReload'] = false) => {
      setMode({ type: 'list', enterprise: 0, listReload });
    },
    [setMode],
  );

  const navToClient = useCallback(
    (ntpsId: number) => {
      setMode({ type: 'businessUnit', enterprise: ntpsId, listReload: false });
    },
    [setMode],
  );

  const navToEnterprise = useCallback(
    (ntpsId = 0) => {
      setMode({ type: 'enterprise', enterprise: ntpsId, listReload: false });
    },
    [setMode],
  );

  const handleListAlertClose = useCallback<ListProps['onAlertClose']>(() => {
    setAlert(false);
  }, [setAlert]);

  const handleListEnterpriseAdd = useCallback<ListProps['onEnterpriseAdd']>(() => {
    navToEnterprise();
  }, [navToEnterprise]);

  const handleListEnterpriseEdit = useCallback<ListProps['onEnterpriseEdit']>(
    (ntpsId) => {
      navToEnterprise(ntpsId);
    },
    [navToEnterprise],
  );

  const handleEnterpriseEditorCancel = useCallback<EnterpriseEditorProps['onCancel']>(() => {
    navToList();
  }, [navToList]);

  const handleEnterpriseEditorSubmit = useCallback<EnterpriseEditorProps['onSubmit']>(
    async (values) => {
      const payload = { ...currEnterprise, name: values.name };
      let succeed = false;

      if (payload.id) {
        const result = await dispatch(enterprise.thunk.update(payload as EnterpriseUpdateArg));
        succeed = enterprise.thunk.update.fulfilled.match(result);
      } else {
        const result = await dispatch(enterprise.thunk.create(payload));
        succeed = enterprise.thunk.create.fulfilled.match(result);
      }

      if (succeed) {
        navToList();
      }
    },
    [dispatch, navToList, currEnterprise],
  );

  const handleListClientAdd = useCallback<ListProps['onClientAdd']>(
    (ntpsId) => {
      dispatch(userClients.slice.actions.resetSubject());
      navToClient(ntpsId);
    },
    [dispatch, navToClient],
  );

  const handleListClientEdit = useCallback<ListProps['onClientEdit']>(
    async (ntpsId, id) => {
      const result = await dispatch(userClients.thunk.getSubject(id));

      if (userClients.thunk.getSubject.fulfilled.match(result)) {
        navToClient(ntpsId);
      }
    },
    [dispatch, navToClient],
  );

  // const handleListArchivedView = useCallback<ListProps['onArchivedView']>(() => {
  // TODO: show "View Archived" modal
  // }, []);

  const handleClientEditorSubmit = useCallback<ClientEditorProps['onSubmit']>(
    async (payload) => {
      const { id, ...createPayload } = payload;
      let result;
      let succeed = false;

      if (id) {
        result = await dispatch(userClients.thunk.update(payload));
        succeed = userClients.thunk.update.fulfilled.match(result);
      } else {
        result = await dispatch(userClients.thunk.create(createPayload));
        succeed = userClients.thunk.create.fulfilled.match(result);
      }

      if (succeed) {
        if (result.payload && payload.enterprise) {
          const { id: businessUnitId, name, code, espType, isArchived, programs } = result.payload as EnterpriseClient;

          dispatch(
            enterprise.slice.actions.updateListItemClient({
              sourceEnterpriseId: mode.enterprise,
              enterpriseId: payload.enterprise?.id,
              businessUnit: {
                id: businessUnitId,
                name,
                code,
                espType,
                isArchived,
                ...(programs ? { programs } : null),
              },
            }),
          );
        }

        navToList(true);
      }
    },
    [dispatch, navToList, mode.enterprise],
  );

  const handleClientEditorCancel = useCallback<ClientEditorProps['onCancel']>(() => {
    navToList();
  }, [navToList]);

  const handleClientEditorArchivedToggle = useCallback<ClientEditorProps['onArchivedToggle']>(
    async (id, archived) => {
      const result = await dispatch(userClients.thunk.toggleArchived({ businessUnit: id, isArchived: archived }));

      if (userClients.thunk.toggleArchived.fulfilled.match(result) && result.payload) {
        const { id: businessUnitId, name, code, espType, isArchived, programs, enterprise: ntps } = result.payload;

        if (ntps) {
          dispatch(
            enterprise.slice.actions.updateListItemClient({
              sourceEnterpriseId: ntps.id,
              enterpriseId: ntps.id,
              businessUnit: {
                id: businessUnitId,
                name,
                code,
                espType,
                isArchived,
                ...(programs ? { programs } : null),
              },
            }),
          );
        }

        if (id === querybusinessUnitId && isArchived) {
          dispatch(auth.slice.actions.resetLastUsedClient());

          navToList();

          history.push('', { businessUnit: null });
        }
      }
    },
    [dispatch, navToList, history, querybusinessUnitId],
  );

  useEffect(() => {
    if (businessUnitSlugId && enterpriseId) {
      const nexbusinessUnitId = businessUnitSlugId;

      history.push('', { id: null });

      handleListClientEdit(enterpriseId, nexbusinessUnitId);
    }
  }, [enterpriseId, businessUnitSlugId, handleListClientEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={styles.adminClients}>
      {(mode.type === 'list' || mode.type === 'enterprise') && (
        <List
          items={list}
          alert={alert}
          onAlertClose={handleListAlertClose}
          onEnterpriseAdd={handleListEnterpriseAdd}
          onEnterpriseEdit={handleListEnterpriseEdit}
          onClientEdit={handleListClientEdit}
          onClientAdd={handleListClientAdd}
          // onArchivedView={handleListArchivedView}
        />
      )}
      {mode.type === 'businessUnit' && currEnterprise && (
        <ClientEditor
          enterprises={list}
          enterprise={currEnterprise}
          businessUnit={businessUnit}
          onSubmit={handleClientEditorSubmit}
          onCancel={handleClientEditorCancel}
          onArchivedToggle={handleClientEditorArchivedToggle}
        />
      )}
      {mode.type === 'enterprise' && (
        <EnterpriseEditor
          enterprise={currEnterprise}
          onCancel={handleEnterpriseEditorCancel}
          onSubmit={handleEnterpriseEditorSubmit}
        />
      )}
    </Box>
  );
};
