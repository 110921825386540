import { useMemo, useState, MouseEvent, useRef, useCallback, useEffect } from 'react';
import { FormControl, FormHelperText, InputLabel, Box, TextField, Menu, MenuItem, Button } from '@material-ui/core';

import { Typography, Icon } from '@components';
import { content } from '@content';
import { variables } from '@styles';
import { textTemplate } from '@utils';

import { SelectOption, SelectOptions, SelectProps } from './SearchSelect.props';
import { useStyles } from './SearchSelect.styles';
import { SELECT_VISIBLE_OPTIONS_LIMIT } from './SearchSelect.const';
import debounce from 'lodash.debounce';

/**
 * Search Select component
 * @returns {JSX.Element}
 */

export function SearchSelect<OptionId = string>({
  className = '',
  uiType = 'primary',
  children,
  signalData = { options: [], id: 0 },
  captionText,
  label,
  required = false,
  disabled = false,
  templated = false,
  error = false,
  helperText,
  errorMsg = '',
  value,
  placeholder = '',
  classNamePlaceholder = '',
  visibleOptionsLimit = SELECT_VISIBLE_OPTIONS_LIMIT,
  MenuProps = {},
  innerSearch = true,
  signalId,
  preSelected = [],
  onChange,
  onTouchEnd,
  onSearchChange,
  ...props
}: SelectProps<OptionId> & {
  innerSearch?: boolean;
  signalId?: number;
  preSelected: SelectOptions<OptionId>;
  onChange?: (selectedOptions: SelectOptions<OptionId>) => void;
  onSearchChange?: (keyword: string) => void;
  onTouchEnd?: () => void;
}): JSX.Element {
  const styles = useStyles({ visibleOptionsLimit });
  const [focused, setFocused] = useState(false);
  const [items, setItems] = useState(signalData.options);
  const [filteredItems, setFilteredItems] = useState(items);
  const [selected, setSelected] = useState<SelectOptions<OptionId>>([]);

  const handleSearchChange = debounce(
    useCallback(
      (e: any) => {
        if (innerSearch) {
          const foundItems: SelectOptions<OptionId> = items.filter((item) =>
            (item.label as string).toLocaleLowerCase().includes(e.target.value.trim().toLocaleLowerCase()),
          );

          setFilteredItems(foundItems);
        } else {
          setFilteredItems(
            signalData.options.map((option) => {
              const curItem = selected.find((item) => item.id === option.id);

              return {
                ...option,
                selected: curItem?.selected,
              };
            }),
          );
        }

        if (onSearchChange) {
          onSearchChange(e.target.value.trim().toLocaleLowerCase());
        }
      },
      [items, signalData.options, setFilteredItems],
    ),
    300,
  );

  const handleFocusChange = () => {
    setFocused((prev) => !prev);
  };

  const handleClickItem = useCallback(
    (selctedOption: SelectOption<OptionId>) => () => {
      const newItems = [...items];
      const curItem = newItems.find((item) => item.id === selctedOption.id);

      if (curItem) {
        const curSelected = selected.find((item) => item.id === curItem.id);
        curItem.selected = !curItem.selected;

        if (curItem.selected && !curSelected) {
          setSelected(selected.concat([curItem]));
        } else {
          setSelected(selected.filter((item) => item.id !== curItem.id));
        }

        setItems(newItems);
      }

      if (onTouchEnd) {
        onTouchEnd();
      }
    },
    [items, selected, setSelected],
  );

  const handleSelectAll = useCallback(() => {
    const newItems = [...items];

    newItems.forEach((item) => {
      item.selected = true;
    });

    setItems(newItems);
    setSelected(newItems);

    if (onTouchEnd) {
      onTouchEnd();
    }
  }, [items, signalData.options, selected, setItems, setSelected]);

  const handleClear = useCallback(() => {
    const newItems = [...items];

    newItems.forEach((item) => {
      item.selected = false;
    });
    setItems(newItems);
    setSelected([]);

    if (onTouchEnd) {
      onTouchEnd();
    }
  }, [items, signalData.options, setItems]);

  useEffect(() => {
    if (onChange) {
      setFilteredItems(
        items.map((option) => {
          const curItem = selected.find((item) => item.id === option.id);

          return {
            ...option,
            selected: curItem?.selected,
          };
        }),
      );
      onChange(items.filter((item) => item.selected));
    }
  }, [items, selected]);

  useEffect(() => {
    if (preSelected.length) {
      return;
    }

    const interval = setInterval(() => {
      if (localStorage.getItem('options') !== JSON.stringify(signalData.options)) {
        const options = JSON.parse(localStorage.getItem('options') ?? '[]');

        setSelected(options.map((item: any) => ({ ...item })));
        clearInterval(interval);
      }
    }, 200);
  }, [signalData.id, preSelected, setItems, setSelected]);

  useEffect(() => {
    setItems((prev) =>
      signalData.options.map((option) => {
        const curItem = prev.find((item) => item.id === option.id);

        return { ...curItem, ...option };
      }),
    );

    if (preSelected.length) {
      const preSelectedOptions = signalData.options.filter((option) => preSelected.includes(option.label as any));
      setSelected(preSelectedOptions);

      return;
    }

    localStorage.setItem('options', JSON.stringify(signalData.options));
  }, [signalData.options, setItems]);

  return (
    <FormControl disabled={disabled} className={className} error={error} fullWidth>
      {captionText && <Typography.Caption className={styles.captionText}>{captionText}</Typography.Caption>}
      {label && (
        <InputLabel disableAnimation shrink={false} className={styles.label}>
          {required && templated ? textTemplate(content.requiredField, { field: label }) : label}
        </InputLabel>
      )}
      <Box className={styles.container}>
        <Button onClick={handleSelectAll} color="primary" size={'small'} style={{ textDecoration: 'underline' }}>
          {content.selectAll}
        </Button>
        <Button onClick={handleClear} color="primary" size={'small'} style={{ textDecoration: 'underline' }}>
          {content.clear}
        </Button>
        <Box className={styles.box}>
          <TextField
            style={{ width: '100%' }}
            onChange={handleSearchChange}
            onFocus={handleFocusChange}
            onBlur={handleFocusChange}
          />
          <div className={styles.dropdown}>
            {filteredItems.map((option, index) => (
              <div
                onClick={handleClickItem(option)}
                key={`search-select-item-${index}`}
                className={styles.dropdownItem}
              >
                <span className={styles.checkbox}>{option.selected && <Icon.Checkmark />}</span>
                {option.label}
              </div>
            ))}
          </div>
          {!focused && <Icon.SearchOutline className={styles.icon} stroke={variables.color.primary.mediumGray} />}
          {errorMsg && <FormHelperText className={styles.validationMessage}>{errorMsg}</FormHelperText>}
        </Box>
      </Box>
    </FormControl>
  );
}
