import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { content } from '@content';
import { Confirm, FileUpload, FileUploadProps, TextField } from '@components';
import { dataVisualization } from '@modules';
import { textTemplate, useLoader, validate } from '@utils';

import { UploadVisualizationModalProps } from './UploadVisualizationModal.props';
import { FormValues } from './UploadVisualizationModal.types';

/**
 * UploadVisualizationModal component
 * @returns {JSX.Element}
 */

export const UploadVisualizationModal = ({ onCancel, onSubmit }: UploadVisualizationModalProps): JSX.Element => {
  const { handleSubmit, control, setValue, clearErrors, setError, watch } = useForm<FormValues>({
    defaultValues: { name: '', visualizationCodeFile: null, isMaxScaleEnabled: false },
  });

  const dataVisualizationMeta = dataVisualization.useEntityMeta();
  useLoader(dataVisualizationMeta);

  const visualName = watch('name');
  const uploadFile = watch('visualizationCodeFile');
  const defaultImage = watch('defaultImage');

  const handleVisualizationChange: FileUploadProps['onChange'] = useCallback(
    (file) => {
      clearErrors('visualizationCodeFile');

      if (file && !Array.isArray(file)) {
        if (validate.isJsfile(file?.type)) {
          setValue('visualizationCodeFile', file);
        } else {
          setError('visualizationCodeFile', { message: content.invalidFileFormat });
        }
      } else {
        setValue('visualizationCodeFile', null);
      }
    },
    [clearErrors, setError, setValue],
  );

  const handleDefaultImageChange: FileUploadProps['onChange'] = useCallback(
    (file) => {
      clearErrors('defaultImage');

      if (file && !Array.isArray(file)) {
        if (validate.isImageFile(file?.type)) {
          setValue('defaultImage', file);
        } else {
          setError('defaultImage', { message: content.invalidFileFormat });
        }
      } else {
        setValue('defaultImage', null);
      }
    },
    [clearErrors, setError, setValue],
  );

  return (
    <Confirm
      open
      submitDisabled={!visualName || !uploadFile || !defaultImage}
      title={content.uploadVisualization}
      submitLabel={textTemplate(content.addValue, { value: content.visualization })}
      skipSubmitArrow
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: content.visualizationName }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            disabled={dataVisualizationMeta.status === 'loading'}
            label={content.visualizationName}
            placeholder={content.visualizationName}
            hint={error?.message}
            error={invalid}
          />
        )}
      />
      <Controller
        name="visualizationCodeFile"
        control={control}
        rules={{ required: textTemplate(content.pleaseSelect, { field: content.visualizationFile }) }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <FileUpload
            {...field}
            id="visual_upload.js"
            extensions={['js', 'json']}
            label={textTemplate(content.requiredField, { field: content.visualizationFile })}
            hint={error?.message || `*${content.required}. ${content.jsFileFormatOnly}`}
            error={invalid}
            value=""
            onChange={handleVisualizationChange}
          />
        )}
      />
      <Controller
        name="defaultImage"
        control={control}
        rules={{ required: textTemplate(content.pleaseSelect, { field: content.defaultImage }) }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <FileUpload
            {...field}
            id="default_image"
            extensions={['jpeg', 'jpg', 'png']}
            label={textTemplate(content.requiredField, { field: content.defaultImage })}
            hint={error?.message || `*${content.required}. ${content.imageFileFormatsOnly}`}
            error={invalid}
            value=""
            onChange={handleDefaultImageChange}
          />
        )}
      />
      <Controller
        name="isMaxScaleEnabled"
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <FormControlLabel
            label={content.visualizationHasMaxScale}
            control={<Checkbox inputRef={ref} checked={value} {...rest} />}
          />
        )}
      />
    </Confirm>
  );
};
