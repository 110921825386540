import { base } from '@modules';

import { DataVisualizationSchema } from './DataVisualization.schema';

export const initialState: DataVisualizationSchema = {
  dataVisualizationList: {
    ...base.state,
    data: [] as any,
  },
  dataVisualization: {
    ...base.state,
    data: null,
  },
  deployments: {
    ...base.state,
    data: [] as any,
  },
  signalList: {
    ...base.state,
    data: [],
  },
  entity: {
    ...base.state,
    data: null,
  },
  dataVisualizationimages: {
    ...base.state,
    data: {
      continuationToken: undefined,
      items: []
    },
  }
};
