import { content } from '@content';
import {
  FontCases,
  FontFamilies,
  UniversalContainerState,
  FontStyle,
  TextAligns,
  DivContainerState,
} from '@components';

import { DataAttributesPanel, DataVisualizationLibrary, LibraryPanel } from './LibrariesPanel';
import { ImageLibraryPanel } from './LibrariesPanel';
import { HTMLElementWithDocument } from './SampleTemplate/SampleTemplate.types';

import { Libraries } from './TemplateView.const';
import { ChosenImage, RedefinedWysiwygStyles } from './TemplateView.types';
import { DataVisualizationListItemSchema } from '@modules';
import { Dispatch, SetStateAction } from 'react';

/**
 TemplateView component utils
 @returns {functions}
 */

export const getLibrariesWrapperTitle = (chosenLibrary: Libraries | null) => {
  switch (chosenLibrary) {
    case Libraries.IMAGES:
      return content.images;
    case Libraries.DATA_VISUAL:
      return content.dataVisualization;
    case Libraries.DATA_ATTRIBUTES:
      return content.dataAttributes;
    default:
      return content.library;
  }
};

export const getLibraryPanelComponent = (
  chosenLibrary: Libraries | null,
  isImageLibrary: boolean,
  onChooseItem: (item: ChosenImage | DataVisualizationListItemSchema) => void,
  chosenItem?: ChosenImage | DataVisualizationListItemSchema,
  setChosenItem?: any,
) => {
  switch (chosenLibrary) {
    case Libraries.IMAGES:
      return (
        <ImageLibraryPanel
          isImageLibrary={isImageLibrary}
          chosenImage={chosenItem as ChosenImage}
          onChooseImage={onChooseItem}
        />
      );
    case Libraries.DATA_VISUAL:
      return (
        <DataVisualizationLibrary
          isDVLibrary={isImageLibrary}
          chosenDV={chosenItem as DataVisualizationListItemSchema}
          onDVSelect={onChooseItem}
          setChosenDV={setChosenItem as Dispatch<SetStateAction<DataVisualizationListItemSchema>>}
        />
      );
    default:
      return (
        <ImageLibraryPanel
          isImageLibrary={isImageLibrary}
          chosenImage={chosenItem as ChosenImage}
          onChooseImage={onChooseItem}
        />
      );
  }
};

function camelize(str: string) {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    ?.replace(/\s+/g, '');
}

export const transformStylesStringToObject = (cssText: string) => {
  const regex = /([\w-]*)\s*:\s*([^;]*)/g;
  let match = {} as unknown as RegExpExecArray | null;
  const properties: { [key: string]: string } = {};

  while ((match = regex.exec(cssText))) {
    properties[camelize(match[1]).replaceAll('-', '')] = match[2].trim();
  }

  return properties;
};

const getWysiwygFontStyle = (stylesObj: { [key: string]: string }): FontStyle[] => {
  const fontStyles: FontStyle[] = [];

  if (stylesObj.fontStyle === 'italic') {
    fontStyles.push(stylesObj.fontStyle);
  }
  if (stylesObj.fontWeight === 'bold') {
    fontStyles.push(stylesObj.fontWeight);
  }
  if (stylesObj.textDecoration === 'underline') {
    fontStyles.push('underlined');
  }

  return fontStyles;
};

const getInitialFontCase = (textTransform: string) => {
  switch (textTransform) {
    case 'lowercase':
      return 'lower';
    case 'uppercase':
      return 'upper';
    case 'capitalize':
      return 'title';
    case 'sentence':
      return 'sentence';
    default:
      return 'original';
  }
};

const getTextTransformStyle = (fontCase: FontCases | undefined) => {
  switch (fontCase) {
    case 'lower':
      return 'lowercase';
    case 'upper':
      return 'uppercase';
    case 'title':
      return 'capitalize';
    case 'sentence':
      return 'sentence';
    default:
      return 'unset';
  }
};

export const getUniversalInitialValues = (
  chosenModuleElemId: string,
  stylesObj: { [key: string]: string },
): UniversalContainerState => {
  const moduleModal = document?.getElementById('module-modal') as HTMLElementWithDocument;
  const changingElem = moduleModal?.contentDocument.getElementById(chosenModuleElemId);
  const linkUrl = changingElem?.parentElement?.getAttribute('temphref');
  const linkAltText = changingElem?.parentElement?.getAttribute('title');
  const camelizedFontFamily = camelize(stylesObj.fontFamily?.split(', ')[0].toLowerCase().replaceAll('-', ' '));

  return {
    fontFamily: (camelizedFontFamily as FontFamilies) || 'arial',
    fontSize: +stylesObj.fontSize?.replace('px', '') || 14,
    fontColor: stylesObj.color,
    fontStyle: getWysiwygFontStyle(stylesObj),
    textAlign: (stylesObj.textAlign as TextAligns) || 'inherit',
    fontCase: getInitialFontCase(stylesObj.textTransform),
    buttonBgColor: stylesObj.backgroundColor,
    link: linkUrl || '',
    altText: linkAltText || '',
  };
};

export function convertWysiwygStylesToString(stylesObj: RedefinedWysiwygStyles) {
  let styles = '';

  for (const [p, val] of Object.entries(stylesObj)) {
    styles += `${p}: ${val};\n`;
  }

  return `\n${styles}`;
}

export const cotvertFromCamelToHyphenCase = (str: string) => {
  return str.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
};

export const getRedefinedWysiwygStyles = (stylesObj: UniversalContainerState) => {
  const redefinedStyles: RedefinedWysiwygStyles = {
    fontFamily: stylesObj.fontFamily,
    fontSize: `${stylesObj.fontSize}px`,
    color: stylesObj.fontColor || 'unset',
    textAlign: stylesObj.textAlign,
    textTransform: getTextTransformStyle(stylesObj.fontCase),
    fontStyle: 'normal',
    textDecoration: 'unset',
    fontWeight: 'normal',
    backgroundColor: stylesObj.buttonBgColor || 'unset',
  };

  if (stylesObj.fontStyle?.includes('bold')) {
    redefinedStyles.fontWeight = 'bold';
  }
  if (stylesObj.fontStyle?.includes('italic')) {
    redefinedStyles.fontStyle = 'italic';
  }
  if (stylesObj.fontStyle?.includes('underlined')) {
    redefinedStyles.textDecoration = 'underline';
  }

  return redefinedStyles;
};

export const getSentenceCasedText = (elemInnerHTML: string) => {
  const firstLetter = elemInnerHTML[0].toUpperCase();
  const remainingText = elemInnerHTML.slice(1, elemInnerHTML.length).toLowerCase();

  return firstLetter + remainingText;
};

export const getDivInitialValues = (stylesObj: { [key: string]: string }): DivContainerState => {
  return {
    imageSrc: stylesObj.backgroundImage ? stylesObj.backgroundImage.replace('url', '').replace(/[{()}]/g, '') : 'none',
    backgroundColor: stylesObj.backgroundColor !== 'undefined' ? stylesObj.backgroundColor : 'unset',
  };
};

export const getBackgroundImageStyles = (state: DivContainerState, divOffsetWidth: number, divOffsetHeight: number) => {
  if (
    state &&
    state.backgroundColor &&
    state.backgroundColor !== 'undefined' &&
    state.backgroundColor !== 'unset' &&
    state.imageSrc === 'none'
  ) {
    return `background-color: ${state.backgroundColor}; background-image: none;`;
  }

  if (state?.imageSrc) {
    const backgroundImageStyles = `background-size: ${divOffsetWidth}px ${divOffsetHeight}px; background-repeat: no-repeat; background-position: center; background-color: unset;`;

    if (state.imageSrc === 'url():' || state.imageSrc === 'none' || !state.imageSrc) {
      return 'background-image: none;';
    }

    if (state.imageSrc.includes('url')) {
      return `background-image: ${state.imageSrc}; ${backgroundImageStyles}`;
    }

    return `background-image: url(${state.imageSrc}); ${backgroundImageStyles}`;
  }
};
