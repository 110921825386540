import { ChangeEvent, memo, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

import { Typography } from '@components';
import { content } from '@content';
import { Signal } from '@modules';

import { useStyles } from './DroppableAttribute.styles';
import { AttributeProps } from './DroppableAttribute.props';

/**
 * Condition component
 * @returns {JSX.Element}
 */

export const DroppableAttribute = memo(
  ({ uiType, mindsetIndex, className, initialAttribute, onChange }: AttributeProps): JSX.Element => {
    const styles = useStyles();
    const { watch } = useFormContext();
    const [isVisible, setIsVisible] = useState(true);

    const signal: Signal = watch(`mindsets.${mindsetIndex}.condition.signal`);

    useEffect(() => {
      if (onChange) {
        onChange(signal);
      }
    }, [signal]);

    useEffect(() => {
      setIsVisible(false);

      setTimeout(() => setIsVisible(true));
    }, [initialAttribute, signal]);

    return (
      <>
        {isVisible && (
          <Box
            className={`${(styles as any)[className!]} ${uiType === 'signal' ? styles.signalTop : styles.treatmentTop}`}
          >
            <Box className={uiType === 'signal' ? styles.signalBox : styles.treatmentBox}>
              <Droppable droppableId={`mindsets.${mindsetIndex}.condition.signal`} type="SIGNAL">
                {(provided) => (
                  <Box
                    className={styles.signalFieldContainer}
                    {...{ ref: provided.innerRef }}
                    {...provided.droppableProps}
                  >
                    <Controller
                      name={`mindsets.${mindsetIndex}.condition.signal`}
                      defaultValue={initialAttribute ? initialAttribute : signal}
                      render={({ field: { value }, fieldState }) => (
                        <>
                          <Typography.Label className={styles.label} data-invalid={fieldState.invalid}>
                            {content.attribute}
                          </Typography.Label>
                          <Box
                            className={styles.signalField}
                            data-signal={!!value?.label}
                            data-invalid={fieldState.invalid}
                          >
                            {value?.label && signal?.label ? (
                              <>
                                <Typography.Headline
                                  component="span"
                                  title={value?.label}
                                  display="inline"
                                  noWrap
                                  data-invalid={fieldState.invalid}
                                >
                                  {value?.label}
                                </Typography.Headline>
                              </>
                            ) : (
                              <Typography.Body className={styles.placeholder} data-invalid={fieldState.invalid}>
                                {content.dragAnAttribute}
                              </Typography.Body>
                            )}
                          </Box>
                        </>
                      )}
                    />
                  </Box>
                )}
              </Droppable>
            </Box>
          </Box>
        )}
      </>
    );
  },
);

DroppableAttribute.displayName = 'Attribute';
