export const Cohort: React.FC = (): React.ReactElement => (
  <svg width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.0623 29.125V21.2938C24.7895 19.6936 25.9941 17.6099 26.5187 15.3146C27.0433 13.0193 26.8636 10.6192 26.003 8.4276C25.1425 6.23602 23.641 4.35487 21.6948 3.02981C19.7486 1.70475 17.448 0.997355 15.0935 1.00001C6.16979 1.00001 4.24979 8.3475 1.06229 16.6C1.00779 16.7421 0.988633 16.8953 1.00648 17.0464C1.02433 17.1975 1.07864 17.342 1.16474 17.4674C1.25084 17.5929 1.36614 17.6955 1.50072 17.7665C1.6353 17.8375 1.78513 17.8748 1.93729 17.875H4.31229V21.625C4.31229 22.6196 4.70738 23.5734 5.41064 24.2767C6.1139 24.9799 7.06773 25.375 8.06229 25.375H9.93729V29.125"
      stroke="#8C8F96"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6472 9.6879C10.5265 9.62465 10.421 9.53591 10.338 9.42783C10.255 9.31975 10.1965 9.19492 10.1666 9.06199C10.1366 8.92906 10.1359 8.7912 10.1645 8.65797C10.1931 8.52473 10.2503 8.39931 10.3322 8.2904C11.2697 7.05348 12.6152 6.1888 14.1298 5.84985C15.6444 5.5109 17.2301 5.71963 18.6054 6.43897C19.9807 7.15831 21.0566 8.34172 21.642 9.7791C22.2275 11.2165 22.2847 12.8148 21.8035 14.2904C21.7612 14.42 21.6912 14.5389 21.5983 14.6386C21.5055 14.7384 21.3919 14.8168 21.2657 14.8682C21.1394 14.9196 21.0034 14.9428 20.8673 14.9363C20.7311 14.9298 20.598 14.8936 20.4772 14.8304L10.6472 9.6879Z"
      stroke="#8C8F96"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
