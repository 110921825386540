import { Body } from './Body';
import { Caption } from './Caption';
import { Display } from './Display';
import { Headline } from './Headline';
import { Tag } from './Tag';
import { Title } from './Title';
import { Label } from './Label';
import { LargeTitle } from './LargeTitle';
import { SmallCaption } from './SmallCaption';
import { SmallTag } from './SmallTag';
import { SuperSmallCaption } from './SuperSmallCaption';
import { Code } from './Code';

export const Typography = {
  Body,
  Caption,
  Display,
  Headline,
  Tag,
  Title,
  Label,
  LargeTitle,
  SmallCaption,
  SmallTag,
  SuperSmallCaption,
  Code,
};
