import { SVGProps } from 'react';

export const GolfOutline = ({
  width = 24,
  height = 24,
  stroke = 'white',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 18.75V1.5L20.25 5.25L12 9"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 15.75C7.92199 15.75 3.78293 17.775 3.01699 21.5972C2.92465 22.058 3.21433 22.5 3.75011 22.5H20.2501C20.7864 22.5 21.0761 22.058 20.9837 21.5972C20.2173 17.775 16.0782 15.75 12.0001 15.75Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
