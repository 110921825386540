import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    provider: {
      bottom: '50px',
      maxWidth: '600px'
    },
    snack: {
      '&&': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(2, 6.5),
        borderRadius: variables.borderRadius.sm,
        backgroundColor: variables.color.primary.white,
        boxShadow: '0px 0.4rem 1.6rem rgba(0, 0, 0, 0.16)',
        borderLeft: `${variables.borderWidth.xl} solid transparent`,
        color: variables.color.primary.darkestGray,
        fontWeight: variables.font.weight.bold,
        position: 'relative',
        right: '0',
        top: '0',

        '& #notistack-snackbar': {
          padding: 0,
          fontSize: variables.font.size.md,

          '& svg': {
            position: 'absolute',
            top: theme.spacing(2),
            left: theme.spacing(2.3),
          },
        },

        '& > div:nth-child(2)': {
          marginRight: 0,
          padding: theme.spacing(0.5, 0, 0),
        },
      },
    },

    success: {
      '&&': {
        borderColor: variables.color.semantic.positive,
      },
    },

    error: {
      '&&': {
        borderColor: variables.color.semantic.negative,
      },
    },

    warning: {
      '&&': {
        borderColor: variables.color.semantic.warning,
      },
    },

    info: {
      '&&': {
        borderColor: variables.color.primary.mainPurple,
      },
    },
  }),
);
