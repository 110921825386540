import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    deploymentBuilder: {
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(255, 255, 255, 0.7)',
      borderRadius: variables.borderRadius.sm,
      flex: '1 1 29rem',
      maxWidth: '29rem',
      marginRight: variables.gap.sm,
      marginLeft: 0,
      padding: variables.gap.lg,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '3.3rem',
    },
    title: {
      fontWeight: variables.font.weight.semiBold,
    },
    infoBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    infoTitle: {
      marginBottom: variables.gap.xs,
    },
    positions: {
      display: 'flex',
    },
    positionsCount: {
      '&&': {
        marginRight: variables.gap.md,
      },
    },
    addPosition: {
      width: '2.9rem',
      height: '2.9rem',
      boxShadow: '1px 1px 5px rgba(0, 59, 92, 0.2)',
      background: variables.color.primary.white,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightPurple}`,
      borderRadius: variables.borderRadius.half,
      color: variables.color.primary.lightPurple,
      fontSize: '3rem',
      '&:disabled': {
        color: variables.color.secondary.gray,
        borderColor: variables.color.secondary.gray,
      },
    },
    positionsList: {
      marginTop: '3.4rem',
      marginRight: '-2rem',
      paddingRight: variables.gap.md,
      overflowY: 'auto',
    },

    drawer: {
      width: '',
    },
  }),
);

export const usePositionStyles = makeStyles(() =>
  createStyles({
    positionsListItem: {
      display: 'flex',
      alignItems: 'center',
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.secondary.white}`,
      height: '5rem',
      padding: '0.8rem 0.7rem 0.8rem 1.2rem',
      '&[data-empty="true"]': {
        background: variables.color.secondary.white,
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightPurple}`,
        overflow: 'hidden',
      },
    },
    positionsListBox: {
      height: '6.8rem',
      marginBottom: variables.gap.md,
    },
    positionsListItemIcon: {
      flexShrink: 0,
    },
    positionsListItemLabel: {
      flexGrow: 1,

      '&&': {
        margin: `0 ${variables.gap.sm}`,
      },
    },
  }),
);
