import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Box } from '@material-ui/core';

import { func, useHistory, useLoader } from '@utils';
import { Alert, Confirm, Icon, TextField, Typography } from '@components';
import { content } from '@content';
import { variables } from '@styles';
import { experience, modules, global } from '@modules';

import { RenameModuleModalProps } from './RenameModuleModal.props';
import { RenameModuleModalFormValues } from './RenameModuleModal.types';
import { useStyles } from './RenameModuleModal.styles';
import { useAppDispatch } from '@store';
/**
 * RenameModuleModal component
 * @returns {JSX.Element}
 */

export const RenameModuleModal = ({
  title,
  alertMessage,
  moduleData,
  type = 'moduleGroup',
  isDeleteTreatment = false,
  submitLabel = content.save,
  onClose,
  onSubmit,
}: RenameModuleModalProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { businessUnit } = useHistory().query;
  const styles = useStyles();
  const { control, handleSubmit, setValue, getValues } = useForm<RenameModuleModalFormValues>({
    defaultValues: {
      name: moduleData.moduleTemplateName,
    },
  });

  const handleConfirmSubmit = useCallback<SubmitHandler<RenameModuleModalFormValues>>(
    (formValues) => {
      onSubmit(formValues, isDeleteTreatment);
    },
    [onSubmit, isDeleteTreatment],
  );

  const inputText = useMemo(
    () => (type === 'moduleGroup' ? content.moduleGroupName : content.contentModuleName),
    [type],
  );

  useLoader(experience.useItemMeta());

  const getNameWitnCopyAndIndex = (value: string) => {
    if (value.indexOf('(copy)') !== -1) {
      return value.replace('(copy)', '(copy 1)');
    }

    const regExp = /\(copy ([^\D]+)\)/;
    const matches = value.match(regExp);

    if (matches) {
      const parts = value.split('(copy');
      const newIndex = Number(matches[1]) + 1;

      return `${parts[0]}(copy ${newIndex})`;
    }

    return `${value} (copy)`;
  };

  const checkIfNameUnique = async (name: string) => {
    setValue('name', name);
    let resp;
    let replacedName = name;

    do {
      resp = await dispatch(modules.thunk.checkModuleName({ templateName: replacedName, businessUnit: businessUnit! }));

      if ((resp as any).error) {
        replacedName = getNameWitnCopyAndIndex(replacedName);
        setValue('name', replacedName);
      }
    } while ((resp as any).error);

    return true;
  };

  const handleFormSubmit = async (e: any) => {
    const { name } = getValues();

    e.preventDefault();

    const isNameUnique = await checkIfNameUnique(name);

    if (isNameUnique) {
      handleSubmit(handleConfirmSubmit)();
    }
  };

  useEffect(() => {
    if (moduleData.moduleTemplateName) {
      (async () => {
        const isNameUnique = await checkIfNameUnique(moduleData.moduleTemplateName);

        if (!isNameUnique) {
          dispatch(
            global.actions.enqueueNotification({
              message: 'To rename the content module, please provide a unique name across the Business Unit.',
              options: { variant: 'error' },
            }),
          );
        }
      })();
    }
  }, [moduleData.moduleTemplateName, dispatch]);

  return (
    <Confirm
      open
      padding="0"
      maxWidth="41.6rem"
      submitLabel={submitLabel}
      skipSubmitArrow
      title={title}
      onCancel={onClose}
      onSubmit={handleFormSubmit}
    >
      {moduleData.hasBeenUsedAroundTemplates || moduleData.isRenameRequired ? (
        <Alert onClose={func.nop} align="left" description={alertMessage} type="warning" />
      ) : null}
      <Controller
        name="name"
        control={control}
        rules={{ required: inputText }}
        defaultValue={moduleData.moduleTemplateName}
        render={({ field: { ref, ...field }, fieldState }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            label={inputText}
            placeholder={inputText}
            error={fieldState.invalid}
            hint={
              fieldState.error?.message
                ? fieldState.error?.message
                : type === 'moduleGroup'
                ? content.thisChangeWillNotApply
                : content.thisChangeWillNotApplyContentModules
            }
          />
        )}
      />
      {moduleData.deployments.length > 1 || moduleData.isRenameRequired ? (
        <Box className={styles.deployments}>
          <Typography.Label className={styles.deploymentsTitle}>
            {type === 'moduleGroup'
              ? content.deploymentsUsingThisModuleGroup
              : content.deploymentsUsingThisContentModule}
          </Typography.Label>
          <Box className={styles.cards}>
            {moduleData.deployments.map((item) => (
              <Box key={item.id} className={styles.card}>
                <Typography.Label>{item.name}</Typography.Label>
                <Box className={styles.cardInfo}>
                  <Icon.CheckmarkCircleOutline stroke={variables.color.semantic.positive} />
                  <Typography.Body className={styles.cardStatus}>{item.status}</Typography.Body>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
    </Confirm>
  );
};
