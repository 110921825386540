import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * AlertCircleOutlineV3 component
 * @returns {JSX.Element}
 */

export const AlertCircleOutlineV3 = ({
  className = '',
  width = 20,
  height = 20,
  fill = variables.color.semantic.negative,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 12.2501C9.8079 12.2504 9.62296 12.1769 9.48341 12.0448C9.34385 11.9126 9.26032 11.732 9.25007 11.5401L8.98008 5.8301C8.97049 5.69398 8.99098 5.55742 9.04008 5.43011C9.08527 5.30024 9.15675 5.18109 9.25007 5.0801C9.34861 4.97394 9.46769 4.88889 9.60008 4.8301C9.72189 4.78411 9.85006 4.75714 9.98008 4.75011C10.1283 4.74787 10.2752 4.77861 10.4101 4.84011C10.536 4.8909 10.6488 4.96954 10.7401 5.07012C10.8362 5.16895 10.9081 5.28877 10.9501 5.42013C10.9976 5.54808 11.0213 5.68364 11.0201 5.82012L10.7501 11.5401C10.7398 11.732 10.6563 11.9126 10.5167 12.0448C10.3772 12.1769 10.1922 12.2504 10.0001 12.2501Z"
      fill={fill}
    />
    <path
      d="M9.47993 15.0901C9.63515 15.1905 9.81513 15.2459 9.99992 15.2501C10.2446 15.2359 10.4754 15.1322 10.6487 14.9589C10.822 14.7857 10.9256 14.5548 10.9399 14.3101C10.939 14.1248 10.8833 13.9439 10.7799 13.7902C10.6796 13.6328 10.5328 13.5104 10.3599 13.4401C10.1892 13.369 10.0008 13.3516 9.81993 13.3902C9.63786 13.4216 9.47009 13.509 9.33992 13.6402C9.21041 13.7729 9.12038 13.9391 9.07991 14.1201C9.0405 14.3043 9.05795 14.4961 9.12993 14.6702C9.20532 14.84 9.32652 14.9854 9.47993 15.0901Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19.75C8.07163 19.75 6.18657 19.1782 4.58319 18.1068C2.97981 17.0355 1.73014 15.5127 0.992186 13.7312C0.254231 11.9496 0.0611397 9.98918 0.437346 8.09787C0.813551 6.20655 1.74215 4.46927 3.10571 3.10571C4.46927 1.74215 6.20655 0.813551 8.09787 0.437346C9.98918 0.0611397 11.9496 0.254231 13.7312 0.992186C15.5127 1.73014 17.0355 2.97981 18.1068 4.58319C19.1782 6.18657 19.75 8.07163 19.75 10C19.7474 12.585 18.7193 15.0634 16.8914 16.8914C15.0634 18.7193 12.585 19.7474 10 19.75ZM10 1.75C8.3683 1.75 6.77324 2.23386 5.41653 3.14038C4.05983 4.0469 3.00241 5.33537 2.37799 6.84286C1.75357 8.35035 1.59018 10.0092 1.90851 11.6095C2.22683 13.2098 3.0126 14.6798 4.16638 15.8336C5.32016 16.9874 6.79016 17.7732 8.3905 18.0915C9.99084 18.4098 11.6496 18.2464 13.1571 17.622C14.6646 16.9976 15.9531 15.9402 16.8596 14.5835C17.7661 13.2268 18.25 11.6317 18.25 10C18.2474 7.81277 17.3773 5.71588 15.8307 4.16928C14.2841 2.62268 12.1872 1.75264 10 1.75Z"
      fill={fill}
    />
  </svg>
);
