import { SVGProps } from 'react';

import { variables } from '@styles';

export const PieChartOutline = ({
  className = '',
  width = 24,
  height = 24,
  fill = variables.color.primary.darkestGray,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.69064 16.6124C2.81313 16.6905 2.95568 16.7314 3.10095 16.73C3.20328 16.7335 3.30526 16.7164 3.40094 16.68L3.7534 16.5218C4.43929 18.1568 5.56055 19.5792 7.00209 20.6297C8.67179 21.8465 10.6849 22.5014 12.7509 22.5C15.3368 22.5 17.8167 21.4728 19.6452 19.6443C21.4737 17.8158 22.5009 15.3359 22.5009 12.75C22.5009 10.1641 21.4737 7.68419 19.6452 5.85571C17.8167 4.02725 15.3368 3.00001 12.7509 3V2.25C12.7484 2.05189 12.6685 1.86263 12.5284 1.72253C12.3883 1.58244 12.1991 1.50259 12.0009 1.5C10.2496 1.50151 8.52634 1.94067 6.98785 2.77756C5.44937 3.61444 4.1444 4.82252 3.19156 6.29202C2.23872 7.76152 1.66822 9.44583 1.53189 11.1919C1.39556 12.938 1.69773 14.6904 2.41095 16.29C2.47099 16.4223 2.56815 16.5343 2.69064 16.6124ZM12.7509 4.5V12C12.7529 12.1441 12.7122 12.2856 12.6339 12.4066C12.5556 12.5276 12.4432 12.6227 12.3109 12.68L5.12427 15.9063C5.61771 17.098 6.38502 18.1604 7.36772 19.0054C8.52221 19.9981 9.92892 20.652 11.4321 20.8946C12.9352 21.1372 14.4762 20.9591 15.8844 20.38C17.2926 19.8009 18.513 18.8433 19.4106 17.6134C20.3082 16.3835 20.848 14.9292 20.97 13.4115C21.0921 11.8938 20.7918 10.3719 20.1024 9.01434C19.4129 7.65677 18.3612 6.51646 17.0637 5.7197C15.7662 4.92294 14.2735 4.5008 12.7509 4.5ZM4.12484 14.7507L11.2509 11.56V3.03C9.8743 3.14124 8.54171 3.56783 7.35638 4.27673C6.17106 4.98563 5.16477 5.95783 4.41547 7.11804C3.66616 8.27825 3.19396 9.59534 3.03537 10.9673C2.87678 12.3393 3.03608 13.7294 3.50095 15.03L3.65091 14.9629C3.73338 14.8823 3.83408 14.821 3.9459 14.785C4.00443 14.7662 4.06451 14.7548 4.12484 14.7507Z"
      fill={fill}
    />
  </svg>
);
