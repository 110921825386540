import { Box } from '@material-ui/core';

import { Icon } from '@components';
import { Typography } from '@components/Typography';
import { variables } from '@styles';

import { useStyles } from './DashboardHeader.styles';
import { DashboardHeaderProps } from './DashboardHeader.props';

/**
 * DashboardHeader component
 * @returns {JSX.Element}
 */

export const DashboardHeader = ({
  icon,
  title,
  subtitle,
  hasChevronIcon = false,
}: DashboardHeaderProps): JSX.Element => {
  const styles = useStyles();
  return (
    <>
      <Box className={styles.topLine} />
      <Box className={styles.header}>
        {icon && icon}
        <Box>
          <Box className={styles.titleContainer}>
            <Typography.LargeTitle className={styles.title}>{title}</Typography.LargeTitle>
            {hasChevronIcon && <Icon.ChevronDown stroke={variables.color.primary.white} />}
          </Box>
          {subtitle && <Typography.Label className={styles.subtitle}>{subtitle}</Typography.Label>}
        </Box>
      </Box>
    </>
  );
};
