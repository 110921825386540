import { Box } from '@material-ui/core';

import { HtmlEditor } from '@components';

import { useStyles } from './EditTab.styles';
import { useState } from 'react';

/**
 EditTab route component
 @returns {JSX.Element}
 */

type TProps = {
  module?: any;
  headerHeight?: number;
  onEditorChange?: (value: string) => void;
};
export const EditTab: React.FC<TProps> = ({ module, headerHeight, onEditorChange }): JSX.Element => {
  const styles = useStyles();
  const [code, setCode] = useState<string>(module?.moduleHtml);

  const handleCodeChanged = (value: string) => {
    setCode(value);

    if (onEditorChange) {
      onEditorChange(value);
    }
  };

  return (
    <Box className={styles.editTab}>
      <HtmlEditor
        style={{ maxHeight: `calc(100vh - ${headerHeight ?? 0}px - 150px)` }}
        value={code}
        onUpdate={handleCodeChanged}
      />
    </Box>
  );
};
