import { Controller, useForm } from 'react-hook-form';
import { Box } from '@material-ui/core';

import { Confirm, Icon, TextField, Typography } from '@components';
import { DeploymentStatus } from '@modules';
import { textTemplate } from '@utils';
import { content } from '@content';

import { ForbiddenProps } from './Forbidden.props';
import { FormValues } from './Forbidden.types';
import { useStyles } from './Forbidden.styles';

export const Forbidden = ({ name, deployments, onCancel, onSubmit }: ForbiddenProps): JSX.Element => {
  const styles = useStyles();

  const { control, watch, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name,
    },
  });

  return (
    <Confirm
      open
      title={content.changeModuleGroupWarningSummary}
      bodyClassName={styles.forbidden}
      submitLabel={textTemplate(content.duplicateValue, { value: content.moduleGroup })}
      skipSubmitArrow
      submitDisabled={!watch('name')}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography.Body>{textTemplate(content.changeModuleGroupWarning, { name })}</Typography.Body>
      {deployments && deployments.length !== 0 && (
        <Box className={styles.list}>
          {deployments.map((item) => (
            <Box className={styles.item} key={item.name}>
              <Typography.Label>{item.name}</Typography.Label>
              {item.status === DeploymentStatus.inMarket && (
                <>
                  <Icon.CheckmarkCircleOutline className={styles.icon} />
                  <Typography.Body className={styles.status}>{content.published}</Typography.Body>
                </>
              )}
            </Box>
          ))}
        </Box>
      )}
      <Typography.Body>{content.changeModuleGroupWarningInstruction}</Typography.Body>
      <Controller
        name="name"
        control={control}
        render={({ field: { ref, ...field }, fieldState }) => (
          <TextField
            {...field}
            inputRef={ref}
            templated
            label={content.moduleGroupName}
            placeholder={content.moduleGroupName}
            error={fieldState.invalid}
            hint={fieldState.error?.message}
          />
        )}
      />
    </Confirm>
  );
};
