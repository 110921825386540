import { SVGProps } from 'react';

export const SmallDots = ({ fill = '#8C8F96', className = '' }: SVGProps<SVGElement>): JSX.Element => (
  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.39593" cy="1.39593" r="1.39593" fill={fill} />
    <circle cx="7.59955" cy="1.39593" r="1.39593" fill={fill} />
    <circle cx="1.39593" cy="7.61859" r="1.39593" fill={fill} />
    <circle cx="7.60394" cy="7.61859" r="1.39593" fill={fill} />
    <circle cx="1.39593" cy="13.81" r="1.39593" fill={fill} />
    <circle cx="7.60345" cy="13.81" r="1.39593" fill={fill} />
  </svg>
);
