import { Box } from '@material-ui/core';

import { ModuleCard } from '@views';
import { Pagination, Panel } from '@components';
import { useScrollableContainer } from '@utils';
import { useStyles } from './ModuleLibraryList.styles';
import { ModuleLibraryListProps } from './ModuleLibraryList.props';

export const ModuleLibraryList = ({
  modules,
  currentPage,
  pageCount,
  onViewDetail,
  changePage,
  modulesGridStyles,
  disabledScroll,
}: ModuleLibraryListProps): JSX.Element => {
  const styles = useStyles();
  const refElement = useScrollableContainer(null);

  return (
    <Box {...(!disabledScroll && { ref: refElement })} className={styles.libraryContainer}>
      {modules.length > 0 && (
        <>
          <Panel className={`${styles.modulesGrid} ${modulesGridStyles}`}>
            {modules.map((module) => (
              <ModuleCard key={module.id} module={module} onViewDetailsClick={onViewDetail} />
            ))}
          </Panel>
          {pageCount && pageCount > 1 && (
            <Box display="flex" justifyContent="center" p="2rem 0 4rem">
              <Pagination pageCount={pageCount} page={currentPage} onChangePage={changePage} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
