import { Box, Button } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';

import { Icon, Typography } from '@components';
import { content } from '@content';

import { Rules } from '../../MindsetPrioritization/MindsetCreator/Rules';

import { INFOS } from './MindsetDetails.const';
import { MindsetDetailsProps } from './MindsetDetails.props';
import { useStyles } from './MindsetDetails.styles';
import { ActiveDropEditKey } from './MindsetDetails.types';

export const MindsetDetails = ({
  className = '',
  list,
  entity,
  entityIndex,
  onApply,
  onDelete,
}: MindsetDetailsProps): JSX.Element => {
  const styles = useStyles();

  const entityIdRef = useRef(entity.id);

  const [active, setActive] = useState<ActiveDropEditKey>('');

  useEffect(() => {
    if (entity.id !== entityIdRef.current) {
      entityIdRef.current = entity.id;
      setActive('');
    }
  }, [setActive, entity.id]);

  return (
    <Box className={`${styles.mindsetDetails} ${className}`}>
      <Box className={styles.head}>
        <Button className={styles.delete} variant="outlined" onClick={onDelete}>
          <Icon.TrashOutline />
          {content.delete}
        </Button>
      </Box>
      <Box className={styles.body}>
        {INFOS.map((info) => (
          <Box className={styles.info} key={info.key}>
            {(() => {
              const SvgIcon = Icon[info.icn];

              return <SvgIcon className={styles.infoIcon} />;
            })()}
            <Box className={styles.infoText}>
              <Typography.SmallCaption className={styles.infoTextLabel}>{content[info.key]}</Typography.SmallCaption>
              <Typography.LargeTitle className={styles.infoTextValue}>{info.val(entity, list)}</Typography.LargeTitle>
            </Box>
          </Box>
        ))}
      </Box>
      <Rules mindsetIndex={entityIndex} onChange={onApply} />
    </Box>
  );
};
