import { TableCohort, TableMindset } from '@modules';

import { STAGES_MIN, COHORT_CODE_NULLS } from './MindsetTable.const';

/**
 * MindsetTable component utils
 * @returns {Function}
 */

export const fillUpCohorts = (cohorts: TableCohort[]): TableCohort[] => {
  const count = cohorts.length;
  const regularCohorts = cohorts.filter((cohort) => cohort.code !== COHORT_CODE_NULLS);
  const defaultCohorts = cohorts.filter((cohort) => cohort.code === COHORT_CODE_NULLS);

  if (count < STAGES_MIN) {
    const hostsCount = STAGES_MIN - count;
    const hosts: TableCohort[] = Array(hostsCount)
      .fill(null)
      .map((_, index) => ({
        name: '',
        code: `col-${index}`,
      }));

    return [...regularCohorts, ...hosts, ...defaultCohorts];
  }

  return [...regularCohorts, ...defaultCohorts];
};

export const getRegularColumnsCount = (filledUpCohorts: TableCohort[]): number =>
  filledUpCohorts.filter((cohort) => cohort.code !== COHORT_CODE_NULLS).length;

export const reformatMindset = (mindset: TableMindset, isFullwidth: boolean) => {
  return {
    id: mindset.id,
    audienceSize: mindset.audienceSize,
    audienceShare: mindset.audienceShare,
    hasContent: mindset.hasContent,
    entity: {
      name: mindset.name,
      deployment: mindset.deployment,
      coverImage: mindset.coverImage,
      tag: mindset.tag,
      updatedAt: mindset.updatedAt,
      statistics: {
        totalImpressions: mindset.statistics.totalImpressions,
        ctir: mindset.statistics.ctir,
      },
      cohort: mindset.cohort,
      conditions: mindset.conditions,
    },
    fullWidth: isFullwidth,
  };
};
