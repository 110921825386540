import { useState, useEffect, memo, useCallback, ChangeEvent } from 'react';
import { Box, TextField, Radio, Button, MenuItem } from '@material-ui/core';
import { useForm, Controller, SubmitHandler, ControllerRenderProps } from 'react-hook-form';

import { useAppDispatch } from '@store';
import { attributeLibrary, signalLibrary } from '@modules';
import { format, textTemplate } from '@utils';
import { Typography, Select, Icon } from '@components';
import { variables } from '@styles';
import { content } from '@content';

import { useStyles } from './AttributesImport.styles';
import { SignalsImportProps, OptionType, FormValues, SignalsImportError } from './AttributesImport.types';
import { SIGNAL_TYPES } from './AttributesImport.const';
import { isCompositeOrStatistic } from './AttributesImport.utils';

/**
 AttributesImport component.
 @returns {JSX.Element}
 */

export const AttributesImport = memo(
  ({ businessUnit, uniqueIdKey = '', onClose, onSuccess }: SignalsImportProps): JSX.Element => {
    const styles = useStyles();

    const dispatch = useAppDispatch();
    const signals = signalLibrary.useFieldsData();
    const attributeLibraryListData = attributeLibrary.useListData();

    const {
      control,
      register,
      getValues,
      setValue,
      handleSubmit,
      watch,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<FormValues>({
      defaultValues: {
        signalsData: signals?.signalsData,
      },
    });

    const key = watch('key');

    const [keyword, setKeyword] = useState('');
    const [attributeOpen, setAttributeOpen] = useState<number[]>([]);
    const [createAttributeFocus, setCreateAttributeFocus] = useState<boolean[]>([]);
    const [attributes, setAttributes] = useState<OptionType[]>([]);

    useEffect(() => {
      const attributesItems = attributeLibraryListData.items as OptionType[];

      setAttributes(attributesItems);
    }, [attributeLibraryListData.items]);

    useEffect(() => {
      if (signals) {
        setAttributeOpen(Object.keys(signals.signalsData).map(() => 0));
        setCreateAttributeFocus(Object.keys(signals.signalsData).map(() => false));
      }
    }, [signals]);

    const handleAttributeClose = () => setAttributeOpen((prevState) => prevState.map(() => 0));

    const handleAttributeOpen = (index: number) =>
      setAttributeOpen((prevState) =>
        prevState.map((item, ind) => {
          if (index === ind) return 1;

          return 0;
        }),
      );

    const handleCreateAttributeFocus = (index: number) => {
      setCreateAttributeFocus((prevState) => prevState.map((item, ind) => ind === index));
    };

    const isLabelDuplicate = useCallback(
      (attributeKey: string, label: string): boolean =>
        !!label &&
        Object.entries(getValues('signalsData') || {}).some(
          ([itemKey, item]) => itemKey !== attributeKey && item.label === label,
        ),
      [getValues],
    );

    const handleRadioBtnChange = useCallback(
      (attributeKey: string, field: ControllerRenderProps<FormValues, 'key'>) => {
        clearErrors(`signalsData.${attributeKey}.label` as 'signalsData.0.label');
        clearErrors(`signalsData.${attributeKey}.type` as 'signalsData.0.type');

        setValue(`signalsData.${attributeKey}.label` as 'signalsData.0.label', '', { shouldDirty: true });
        setValue(`signalsData.${attributeKey}.id` as 'signalsData.0.id', 0, { shouldDirty: true });
        setValue(field.name, attributeKey, { shouldValidate: true });
        setValue(`signalsData.${attributeKey}.type` as 'signalsData.0.type', null, { shouldDirty: true });

        const input = document.querySelector(`input[name="signalsData.${attributeKey}.label"]`);
        const inputNextElement = input?.nextElementSibling;
        const indicator = inputNextElement?.querySelector('button.MuiAutocomplete-clearIndicator');

        (indicator as HTMLButtonElement)?.click();
      },
      [setValue, clearErrors],
    );

    const handleAttributeTypeChange = useCallback(
      (event, attributeKey) => {
        setValue(`signalsData.${attributeKey}.id`, 0);
        setValue(`signalsData.${attributeKey}.type` as const, event.target.value as never, { shouldValidate: true });
      },
      [setValue],
    );

    const handleAttributeLabelChange = useCallback(
      (event, attributeKey) => {
        const attrName = event.target.value;
        const currentAttr = attributes.find((attr) => attr.name === attrName);

        if (attrName !== 'createAttribute' && currentAttr) {
          setValue(`signalsData.${attributeKey}.label` as const, currentAttr.name as never, {
            shouldValidate: true,
          });
          setValue(`signalsData.${attributeKey}.id` as const, currentAttr.id as never);
          setValue(`signalsData.${attributeKey}.type` as const, currentAttr.type || null, {
            shouldValidate: true,
          });
        }
      },
      [attributes, setValue],
    );

    const handleAttributeNameChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const {
          value: newName,
          dataset: { signalKey = '' },
        } = event.target;

        if (isLabelDuplicate(signalKey, newName)) {
          const field = content.attributeName;
          const message = textTemplate(content.fieldShouldBeUnique, { field });

          setError(`signalsData.${signalKey}.label`, { message });
        } else {
          clearErrors(`signalsData.${signalKey}.label`);
          setKeyword(newName);
        }
      },
      [isLabelDuplicate, setError, clearErrors],
    );

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
      if (signals) {
        clearErrors();

        if (
          !Object.values(signals.signalsData)
            .map((signal) => signal.name)
            .includes(uniqueIdKey)
        ) {
          setError('key', {
            type: 'manual',
            message: content.selectedKeyDoesntMatch,
          });
        } else {
          const [attributeKey] = Object.entries(data.signalsData).find(
            ([k, value]) => value.label === uniqueIdKey,
          ) as any;

          const result = await dispatch(
            signalLibrary.thunk.signalsImport({
              businessUnit,
              payload: {
                signalImportFileData: signals.signalImportFileData,
                signalsData: {
                  ...data.signalsData,
                  [attributeKey]: {
                    ...data.signalsData[attributeKey],
                    key: true,
                  },
                },
              },
            }),
          );

          if (signalLibrary.thunk.signalsImport.fulfilled.match(result)) {
            onSuccess();
          } else if (signalLibrary.thunk.signalsImport.rejected.match(result)) {
            const error = result.payload as SignalsImportError;

            if (error.errorType === 'signal_import' && Object.keys(error.errors.signal_import).length > 0) {
              Object.values(error.errors.signal_import).forEach((k) => {
                setError(`signalsData.${k}.label`, { type: 'manual', message: content.attributeNameAlreadyUsed });
              });
            }
          }
        }
      }
    };

    useEffect(() => {
      dispatch(
        attributeLibrary.thunk.search({
          businessUnit,
          include: ['signal'],
        }),
      );
    }, [businessUnit, dispatch]);

    useEffect(
      () => () => {
        dispatch(signalLibrary.actions.setFieldsData(null));
      },
      [dispatch],
    );

    const handleAddAttribute = useCallback(
      (event, k) => {
        const keywordLowercased = keyword.toLowerCase();
        const siblingsEntries = Object.entries(getValues('signalsData')).filter(([itemKey]) => itemKey !== k);
        const siblings = Object.values(Object.fromEntries(siblingsEntries)) as OptionType[];
        const attrs = [...attributes, ...siblings];
        const sameNameAttr = attrs.find((attr) => attr.name.toLowerCase() === keywordLowercased);
        const sameLabelAttr = attrs.find((attr) => attr.label?.toLowerCase() === keywordLowercased);
        const sameFieldAttr = sameNameAttr || sameLabelAttr;
        const isSameFieldAttrCompOrStat = !!sameFieldAttr && isCompositeOrStatistic(sameFieldAttr);

        if (sameFieldAttr) {
          setValue(`signalsData.${k}.label`, '');
          setError(`signalsData.${k}.label`, {
            message: isSameFieldAttrCompOrStat
              ? content.attributeNameAlreadyUsed
              : textTemplate(content.fieldShouldBeUnique, { field: content.name }),
          });
        } else {
          clearErrors(`signalsData.${k}.label`);
          setAttributes((prevState) => [{ label: keyword, name: keyword }, ...prevState]);

          setValue(`signalsData.${k}.label` as const, keyword as never, {
            shouldValidate: true,
          });
          setValue(`signalsData.${k}.id` as const, 0 as never);
        }

        event.stopPropagation();
        handleAttributeClose();
      },
      [attributes, clearErrors, setError, setValue, getValues, keyword],
    );

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={styles.header}>
          <Box>
            <Typography.SmallCaption className={styles.topTitle}>{`${content.importData} /`}</Typography.SmallCaption>
            <Typography.LargeTitle>{content.mapData}</Typography.LargeTitle>
          </Box>
        </Box>
        <Box className={styles.body}>
          <Typography.Caption className={styles.description}>
            {content.pleaseMapDataFromUploadedFile}
          </Typography.Caption>
          <Box className={styles.attributeRow}>
            <Box className={styles.key}>
              <Typography.SmallTag>{content.key}</Typography.SmallTag>
            </Box>
            <Box className={styles.fieldName}>
              <Typography.SmallTag>{content.fieldName}</Typography.SmallTag>
            </Box>
            <Box className={styles.attributeType}>
              <Typography.SmallTag>{content.attributeType}</Typography.SmallTag>
            </Box>
            <Box className={styles.attributeName}>
              <Typography.SmallTag>{content.attributeName}</Typography.SmallTag>
            </Box>
          </Box>

          {signals?.signalsData &&
            Object.entries(signals.signalsData).map(([k, val], index) => (
              <Box key={k} className={styles.attributeRow}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input type="hidden" {...register(`signalsData.${k}.key` as const, { value: false as never })} />
                <Box className={`${styles.key} ${styles.keyCol}`}>
                  <Controller
                    control={control}
                    name="key"
                    render={({ field }) => (
                      <Radio
                        color="primary"
                        disabled={true}
                        checked={val.label === uniqueIdKey}
                        className={styles.radioBtn}
                        onChange={() => handleRadioBtnChange(k, field)}
                      />
                    )}
                  />
                </Box>
                <Box className={`${styles.fieldName} ${styles.fieldNameCol}`}>
                  <Controller
                    control={control}
                    name={`signalsData.${k}.name` as const}
                    render={({ field }) => (
                      <Typography.Caption className={styles.name}>{field.value}</Typography.Caption>
                    )}
                  />
                </Box>
                <Box className={styles.attributeType}>
                  <Controller
                    control={control}
                    name={`signalsData.${k}.type` as const}
                    rules={{
                      required:
                        k !== key && val.label !== uniqueIdKey
                          ? textTemplate(content.pleaseSelect, { field: content.type })
                          : false,
                    }}
                    render={({ field: { ref, value, ...field }, fieldState }) => (
                      <Select
                        {...field}
                        placeholder={content.select}
                        value={value || ''}
                        inputRef={ref}
                        disabled={val.label === uniqueIdKey}
                        displayEmpty
                        error={fieldState.invalid}
                        errorMsg={fieldState.error?.message}
                        options={SIGNAL_TYPES.map((item) => ({ id: item, label: item }))}
                        onChange={(event: any) => handleAttributeTypeChange(event, k)}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.attributeName}>
                  <Controller
                    control={control}
                    name={`signalsData.${k}.label` as const}
                    rules={{
                      required: k !== key ? textTemplate(content.pleaseEnter, { field: content.attributeName }) : false,
                    }}
                    render={({ field: { ref, value, ...field }, fieldState }) => (
                      <Select
                        {...field}
                        value={value || ''}
                        inputRef={ref}
                        placeholder={content.select}
                        error={fieldState.invalid}
                        open={attributeOpen[index] === 1}
                        onClose={handleAttributeClose}
                        onOpen={() => handleAttributeOpen(index)}
                        disabled={val.label === uniqueIdKey}
                        errorMsg={fieldState.error?.message}
                        onBlur={() => setKeyword('')}
                        className={styles.attrSelect}
                        onChange={(event: any) => handleAttributeLabelChange(event, k)}
                      >
                        <MenuItem
                          className={styles.topRowMenuItem}
                          value="createAttribute"
                          onClick={() => handleAttributeOpen(index)}
                        >
                          <Box className={styles.topRow}>
                            <Icon.AddOutline
                              className={`${styles.topRowAddIcon} ${createAttributeFocus[index] ? 'hide' : ''}`}
                              stroke={variables.color.primary.mediumGray}
                            />
                            <TextField
                              className={`${styles.topRowInput} ${createAttributeFocus[index] ? 'focus' : ''}`}
                              placeholder={
                                createAttributeFocus[index]
                                  ? textTemplate(content.enterValue, { value: content.name })
                                  : textTemplate(content.createValue, { value: content.name })
                              }
                              inputProps={{ 'data-signal-key': k }}
                              onChange={handleAttributeNameChange}
                              onFocus={() => handleCreateAttributeFocus(index)}
                              onKeyDown={(event) => event.stopPropagation()}
                            />
                            <Button
                              className={`${styles.topRowCheckBtn} ${createAttributeFocus[index] ? 'show' : ''}`}
                              onClick={(event) => handleAddAttribute(event, k)}
                            >
                              <Icon.Checkmark />
                            </Button>
                          </Box>
                        </MenuItem>
                        {attributes &&
                          attributes.length > 0 &&
                          attributes
                            .filter((attribute) => !isCompositeOrStatistic(attribute))
                            .map((attribute) => (
                              <MenuItem key={attribute.id} value={attribute.label} className={styles.attributeMenuItem}>
                                <Typography.Body className={styles.optionName}>{attribute.label}</Typography.Body>
                                <Typography.Body className={styles.optionText}>
                                  {attribute.totalSubscribers ? `${format.number(attribute.totalSubscribers)}` : '0'}
                                </Typography.Body>
                              </MenuItem>
                            ))}
                      </Select>
                    )}
                  />
                </Box>
              </Box>
            ))}

          {errors.key?.message && (
            <Typography.Caption className={styles.errorMsg}>{errors.key?.message}</Typography.Caption>
          )}
        </Box>
        <Box className={styles.footer}>
          <Button variant="outlined" color="primary" className={styles.cancelBtn} onClick={onClose}>
            {content.cancel}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {content.import}
          </Button>
        </Box>
      </form>
    );
  },
);

AttributesImport.displayName = 'AttributesImport';
