import { useEffect, useState } from 'react';

import { FullPageLoader } from '@components';
import { Routing } from '@utils';
import { history } from '@store';
import { page } from '@content';

export const RoutesCollector = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const resp = await fetch(process.env.REACT_APP_ROUTES_URL || '');

        if (!resp.ok) {
          setIsLoading(false);
          history.push(page.login);
          return;
        }

        const routes = await resp.json();
        Routing.setRoutingData(routes);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
        history.push(page.login);
      }
    })();
  }, []);

  if (isLoading) {
    return <FullPageLoader blockRender />;
  }

  return children;
};
