import { Ref, forwardRef, useCallback, useState, useImperativeHandle } from 'react';
import { Tooltip as MuiTooltip, ClickAwayListener } from '@material-ui/core';

import {
  ALIGN_DEFAULT,
  GAP_DEFAULT,
  PLACEMENT_BY_ALIGN,
  POS_DEFAULT,
  SHIFT_DEFAULT,
  SIZE_DEFAULT,
} from './Tooltip.const';
import { TooltipProps, TooltipHandle } from './Tooltip.props';
import { useStyles } from './Tooltip.styles';

/**
 * Tooltip component
 * @returns {JSX}
 */

export const Tooltip = forwardRef(
  (
    {
      align = ALIGN_DEFAULT,
      gap = GAP_DEFAULT,
      shift = SHIFT_DEFAULT,
      size = SIZE_DEFAULT,
      pos = POS_DEFAULT,
      text = '',
      children,
      tooltipOnClick,
      handleTooltipHover,
      tooltipId,
    }: TooltipProps,
    ref: Ref<TooltipHandle>,
  ): JSX.Element => {
    const styles = useStyles({
      align,
      gap,
      shift,
      size,
      pos,
    });

    const [shown, setShown] = useState(false);

    const show = useCallback(() => !shown && setShown(true), [setShown, shown]);

    const hide = useCallback(() => {
      if (shown) {
        setShown(false);
        if (handleTooltipHover) handleTooltipHover();
      }
    }, [setShown, shown, handleTooltipHover]);

    useImperativeHandle(ref, () => ({
      showTooltip: () => {
        if (shown) {
          hide();
        } else {
          show();
        }
      },
    }));

    let tooltip: JSX.Element;

    if (tooltipOnClick) {
      tooltip = (
        <ClickAwayListener onClickAway={hide}>
          <div className="tooltip-button-wrapper">
            <MuiTooltip
              id={tooltipId}
              PopperProps={{ disablePortal: pos === 'rel' }}
              classes={styles}
              open={shown}
              arrow
              placement={PLACEMENT_BY_ALIGN[align]}
              title={text}
              onClose={hide}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              {children}
            </MuiTooltip>
          </div>
        </ClickAwayListener>
      );
    } else {
      tooltip = (
        <MuiTooltip
          classes={styles}
          open={shown}
          arrow
          placement={PLACEMENT_BY_ALIGN[align]}
          title={text}
          PopperProps={{ disablePortal: pos === 'rel' }}
          onOpen={show}
          onClose={hide}
        >
          {children}
        </MuiTooltip>
      );
    }

    return tooltip;
  },
);

Tooltip.displayName = 'Tooltip';
