import { SelectOption } from '@components';
import { ModulePersonalizationInfo, UserClientsFieldsMappingData, ClientFieldsMap } from '@modules';

import { ModuleDataRecord } from './ModuleCreator.types';

const isObj = (value?: unknown): boolean =>
  !!value && typeof value === 'object' && !(value instanceof File) && !Array.isArray(value);

export const merge = (
  target: ModuleDataRecord | unknown,
  ...sources: (ModuleDataRecord | unknown)[]
): ModuleDataRecord | unknown => {
  const result = { ...(target as ModuleDataRecord) };

  if (sources.length === 0) {
    return result;
  }

  const source = sources.shift() as ModuleDataRecord;

  if (isObj(result) && isObj(source) && source) {
    Object.keys(source).forEach((key) => {
      const value = source[key];

      result[key] = isObj(value) ? merge(result[key] || {}, value) : value;
    });
  }

  return merge(result, ...sources);
};

export const getNextModuleUpdates = (
  fresh: ModuleDataRecord | null,
  pending: ModuleDataRecord,
  init: ModuleDataRecord | null,
): ModuleDataRecord | null => (fresh ? { ...(Object.entries(fresh).length === 0 ? init : pending), ...fresh } : null);

export const getFieldNames = (fields: UserClientsFieldsMappingData): SelectOption[] => [
  { id: '', label: ' ' },
  ...(fields || []).map((field) => ({
    id: field.fieldName,
    label: field.fieldName,
  })),
];

export const generatePznInfos = (map?: ClientFieldsMap): ModulePersonalizationInfo[] =>
  map
    ? map.values.map((value) => ({
        value: { ...value },
        defaultContent: '',
        dynamicDisclosure: '',
        imagePath: '',
        trackingUrl: '',
        trackingDisclosureUrl: '',
      }))
    : [];
