import { memo, useMemo, useEffect, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';

import { Icon, Typography } from '@components';
import { content } from '@content';
import { BetweenField, TimesField, DateField, BetweenFieldValue } from '@views';
import { format, func, textTemplate } from '@utils';
import { Mindset as MindsetType, global, Mindset } from '@modules';
import { useAppDispatch } from '@store';

import { ExperienceMindsetCondition } from '../../../ExperienceEditor.types';

import { Condition, ArgumentType, ConditionSignalValue, RequiredField, SIGNAL_ARGUMENTS } from './Condition';
import { MetCondition } from './MetCondition';
import { RuleValue } from './RuleValue';

import { useStyles } from './Rules.styles';
import { SignalType } from './Rules.types';
import { FieldsLabel } from './Rules.const';
import { RulesProps } from './Rules.props';

/**
 * Rules component
 * @returns {JSX.Element}
 */

export const Rules = memo(({ mindsetIndex, onChange = func.nop }: RulesProps) => {
  const styles = useStyles();
  const { trigger, setValue, watch, getValues, setError, clearErrors, formState } = useFormContext();
  const [errorMsg, setErrorMsg] = useState('');
  const conditionsInit = watch(`mindsets.${mindsetIndex}.conditions`);
  const conditions: ExperienceMindsetCondition[] = useMemo(() => conditionsInit || [], [conditionsInit]);
  const condition = watch(`mindsets.${mindsetIndex}.condition`);
  const metConditionCount = watch(`mindsets.${mindsetIndex}.metConditionCount`);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!condition) {
      setValue(`mindsets.${mindsetIndex}.condition`, {
        signal: {
          id: undefined,
        },
        operator: '',
        value: '',
        required: false,
      });
    }
  }, [setValue, condition, mindsetIndex]);

  function validate() {
    const requiredFields = conditions.reduce(
      (acc) => [
        ...acc,
        `mindsets.${mindsetIndex}.condition.signal`,
        `mindsets.${mindsetIndex}.condition.operator`,
        `mindsets.${mindsetIndex}.condition.value`,
      ],
      [
        `mindsets.${mindsetIndex}.cohort`,
        `mindsets.${mindsetIndex}.product`,
        `mindsets.${mindsetIndex}.metConditionCount`,
      ],
    );

    if (conditions && !conditions.length && !condition.value) {
      setError(`mindsets.${mindsetIndex}.condition.signal`, { type: 'value' });
      setError(`mindsets.${mindsetIndex}.condition.operator`, { type: 'value' });
      setError(`mindsets.${mindsetIndex}.condition.value`, { type: 'value' });
    }
    const cohortProductPair = `${getValues(`mindsets.${mindsetIndex}.cohort.id`)}-${getValues(
      `mindsets.${mindsetIndex}.product.id`,
    )}`;

    const isCohortProductPairUnique = getValues('mindsets')
      .filter((i: MindsetType, index: number) => index !== mindsetIndex)
      .map((i: string) => i === cohortProductPair)
      .every((i: boolean) => !i);

    if (!isCohortProductPairUnique) {
      dispatch(
        global.actions.enqueueNotification({
          message: content.combinationCohortProductMustUnique,
          options: { variant: 'error' },
        }),
      );
      setError(`mindsets.${mindsetIndex}.cohort`, { type: 'value' });
      setError(`mindsets.${mindsetIndex}.product`, { type: 'value' });
      return false;
    }
    clearErrors([`mindsets.${mindsetIndex}.cohort`, `mindsets.${mindsetIndex}.product`]);

    return trigger(requiredFields);
  }

  useEffect(() => {
    if (formState.errors && !formState.isValid && conditions && !conditions.length) {
      const fieldsWithErrors = (formState.errors.mindsets as any)?.filter((i: Mindset) => i)[0]?.condition;
      if (fieldsWithErrors) {
        let msg = '';
        Object.keys(fieldsWithErrors).map((key) => (msg = msg + `• ${FieldsLabel[key]} \n`));
        setErrorMsg(msg);
      } else {
        setErrorMsg('');
      }
    } else {
      setErrorMsg('');
    }
  }, [formState, conditions]);

  const clearErrorMsg = () => {
    setErrorMsg('');
    clearErrors();
  };

  const addCondition = async () => {
    const isValid = await validate();
    if (isValid && condition.signal?.name) {
      setValue(`mindsets.${mindsetIndex}.conditions`, conditions.concat(condition));
      setValue(`mindsets.${mindsetIndex}.condition`, {
        signal: {
          id: undefined,
        },
        operator: '',
        value: '',
        required: false,
      });

      setValue(`mindsets.${mindsetIndex}.metConditionCount`, (metConditionCount || 0) + 1);

      onChange();
    }
  };

  const deleteCondition = (idx: number) => () => {
    const nextConditions = [...conditions];

    nextConditions.splice(idx, 1);

    setValue(`mindsets.${mindsetIndex}.conditions`, nextConditions);

    if (metConditionCount > conditions.length - 1)
      setValue(`mindsets.${mindsetIndex}.metConditionCount`, conditions.length - 1);

    onChange();
  };

  const getOperatorValue = (item: ExperienceMindsetCondition) => {
    if (item.signal.signalType === 'composite') {
      return content.has;
    }

    return SIGNAL_ARGUMENTS[item.signal.type as SignalType]?.find(
      (argument: ArgumentType) => argument.value === item.operator,
    )?.label;
  };

  return (
    <Box>
      <Typography.Title className={styles.rulesText}>{content.rules}</Typography.Title>
      <Box className={styles.content}>
        {conditions.length > 1 && (
          <Box className={styles.mindsetMatch}>
            <Typography.Body className={styles.metConditionText}>{content.useAtLeast}</Typography.Body>
            <MetCondition defaultValue={conditions.length} mindsetIndex={mindsetIndex} onChange={onChange} />
            <Typography.Body className={styles.metConditionText}>{content.ofFollowingNonRules}</Typography.Body>
          </Box>
        )}
        <Box>
          {conditions.length > 0 ? (
            <Box>
              {conditions.map((item, index) => {
                if (item) {
                  return (
                    <Box key={item.id} className={styles.ruleContainer}>
                      <Box className={styles.row}>
                        <RuleValue
                          type="signal"
                          value={item.signal?.name || content.addOrDragAttribute}
                          className={styles.draggableContainer}
                          onSave={onChange}
                        >
                          <Box className={styles.signalBox}>
                            <Droppable
                              droppableId={`mindsets.${mindsetIndex}.conditions.${index}.signal`}
                              type="SIGNAL"
                            >
                              {(provided) => (
                                <Box
                                  className={styles.signalFieldContainer}
                                  {...{ ref: provided.innerRef }}
                                  {...provided.droppableProps}
                                >
                                  <Box className={styles.signalField} data-signal={!!item.signal}>
                                    {item.signal?.name ? (
                                      <>
                                        <Typography.Body
                                          className={styles.inputText}
                                          title={item.signal?.name}
                                          display="inline"
                                          noWrap
                                          component="span"
                                        >
                                          {item.signal?.name}
                                        </Typography.Body>
                                      </>
                                    ) : (
                                      <Typography.Body className={styles.inputText}>
                                        {content.addOrDragAttribute}
                                      </Typography.Body>
                                    )}
                                  </Box>
                                  {provided.placeholder}
                                </Box>
                              )}
                            </Droppable>
                          </Box>
                        </RuleValue>
                        <RuleValue
                          className={styles.operator}
                          value={getOperatorValue(item) || content.condition}
                          options={
                            item.signal?.type
                              ? SIGNAL_ARGUMENTS[item.signal?.type as SignalType].map((argument) => ({
                                  id: argument.label,
                                  label: argument.label,
                                  value: argument.value,
                                }))
                              : []
                          }
                          onSave={(newValue) => {
                            setValue(`mindsets.${mindsetIndex}.conditions.${index}.operator`, newValue, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            onChange();
                          }}
                        />
                        {item.signal?.signalType === 'statistic' && item.operator === 'between' && (
                          <RuleValue value={item.value ? item.value : content.enterAValue} onSave={onChange}>
                            <BetweenField
                              mindsetIndex={mindsetIndex}
                              defaultValue={item.value as unknown as BetweenFieldValue}
                              fieldName={`mindsets.${mindsetIndex}.conditions.${index}.value`}
                            />
                          </RuleValue>
                        )}
                        {item.signal?.signalType === 'statistic' && item.operator === '=' && (
                          <RuleValue value={item.value ? item.value : content.enterAValue} onSave={onChange}>
                            <TimesField
                              defaultValue={item.value}
                              mindsetIndex={mindsetIndex}
                              fieldName={`mindsets.${mindsetIndex}.conditions.${index}.value`}
                            />
                          </RuleValue>
                        )}
                        {item.signal?.signalType === 'statistic' &&
                          ['on', 'before', 'after'].includes(item.operator) && (
                            <RuleValue value={item.value ? item.value : content.enterAValue} onSave={onChange}>
                              <DateField
                                mindsetIndex={mindsetIndex}
                                operator={item.operator}
                                fieldName={`mindsets.${mindsetIndex}.conditions.${index}.value`}
                              />
                            </RuleValue>
                          )}
                        {item.signal?.type && item.signal?.signalType !== 'statistic' && (
                          <RuleValue
                            value={
                              item.value
                                ? typeof item.value === 'object'
                                  ? item.value
                                  : format.capitalize(item.value)
                                : content.enterAValue
                            }
                            onSave={onChange}
                          >
                            <ConditionSignalValue
                              defaultValue={item.value}
                              freeSolo={item.signal?.type === 'numeric'}
                              signalId={item.signal?.id}
                              disabled={!item.signal}
                              mindsetIndex={mindsetIndex}
                              fieldName={`mindsets.${mindsetIndex}.conditions.${index}.value`}
                            />
                          </RuleValue>
                        )}
                        <RuleValue value={item.required} type="required" onSave={onChange}>
                          <RequiredField
                            condition={condition}
                            mindsetIndex={mindsetIndex}
                            fieldName={`mindsets.${mindsetIndex}.conditions.${index}.required`}
                          />
                        </RuleValue>
                      </Box>
                      <IconButton onClick={deleteCondition(index)}>
                        <Icon.TrashOutline width={18} height={18} />
                      </IconButton>
                    </Box>
                  );
                }
              })}
            </Box>
          ) : (
            <Typography.Body className={styles.bodyText}>{content.noRulesAdded}</Typography.Body>
          )}
        </Box>
        <Typography.Title className={styles.rulesText}>
          {textTemplate(content.addValue, { value: content.rule })}
        </Typography.Title>
        <Box className={styles.conditionsList}>
          {condition && (
            <Condition
              className={styles.condition}
              condition={condition}
              addCondition={addCondition}
              mindsetIndex={mindsetIndex}
              errorMsg={errorMsg}
              clearErrorMsg={clearErrorMsg}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
});

Rules.displayName = 'Rules';
