import { ReactElement, ChangeEvent, useEffect, useState } from 'react';
import { Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { content } from '@content';
import { PieChart, PieChartData, Typography } from '@components';
import { generateColors } from '@components/BarStackedChart/BarStackedChart.utils';
import {
  analytics,
  ImpressionByOverallDataItemSchema,
  ImpressionByProductDataItemSchema,
  ImpressionByCohortDataItemSchema,
} from '@modules';
import { useAppDispatch } from '@store';
import { useLoader } from '@utils';

import { useStyles } from './ModulePerformanceByPosition.styles';
import { BarGroupedChart, BarGroupedChartData } from './BarGroupedChart';

export function AccordionDetailsContent({ currentDeployment }: { currentDeployment: string }): ReactElement {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const [sortValue, setSortValue] = useState('cohort');

  const handleChangeSort = (event: ChangeEvent<HTMLInputElement>) => {
    setSortValue((event.target as HTMLInputElement).value);
  };

  const impressionByCohortData = analytics.useImpressionByCohortData();
  const impressionByCohortMeta = analytics.useImpressionByCohortMeta();

  const impressionByProductData = analytics.useImpressionByProductData();
  const impressionByProductMeta = analytics.useImpressionByProductMeta();

  const impressionByOverallData = analytics.useImpressionByOverallData();
  const impressionByOverallMeta = analytics.useImpressionByOverallMeta();

  useLoader(impressionByCohortMeta, impressionByProductMeta, impressionByOverallMeta);

  useEffect(() => {
    if (!currentDeployment) {
      return undefined;
    }

    if (sortValue === 'cohort') {
      const promise = dispatch(
        analytics.thunk.getImpressionByCohort({
          deployment: currentDeployment,
          sort: sortValue,
        }),
      );
      return () => {
        promise.abort();
      };
    }
    return () => {
      dispatch(analytics.actions.resetImpressionByCohort());
    };
  }, [dispatch, currentDeployment, sortValue]);

  useEffect(() => {
    if (!currentDeployment) {
      return undefined;
    }

    if (sortValue === 'product') {
      const promise = dispatch(
        analytics.thunk.getImpressionByProduct({
          deployment: currentDeployment,
          sort: sortValue,
        }),
      );
      return () => {
        promise.abort();
      };
    }
    return () => {
      dispatch(analytics.actions.resetImpressionByProduct());
    };
  }, [dispatch, currentDeployment, sortValue]);

  useEffect(() => {
    if (!currentDeployment) {
      return undefined;
    }

    if (sortValue === 'overall') {
      const promise = dispatch(
        analytics.thunk.getImpressionByOverall({
          deployment: currentDeployment,
          sort: sortValue,
        }),
      );
      return () => {
        promise.abort();
      };
    }
    return () => {
      dispatch(analytics.actions.resetImpressionByOverall());
    };
  }, [dispatch, currentDeployment, sortValue]);

  const allElementsId: string[] = [];

  impressionByCohortData.forEach((dataItem) =>
    dataItem?.bar?.cohorts?.forEach((cohort) =>
      cohort?.items?.forEach((item) => {
        if (!allElementsId.includes(item.elementId)) {
          allElementsId.push(item.elementId);
        }
      }),
    ),
  );

  impressionByCohortData.forEach((dataItem) =>
    dataItem?.pie?.items?.forEach((item) => {
      if (!allElementsId.includes(item.elementId)) {
        allElementsId.push(item.elementId);
      }
    }),
  );

  impressionByProductData.forEach((dataItem) =>
    dataItem?.bar?.products?.forEach((product) =>
      product?.items?.forEach((item) => {
        if (!allElementsId.includes(item.elementId)) {
          allElementsId.push(item.elementId);
        }
      }),
    ),
  );

  impressionByProductData.forEach((dataItem) =>
    dataItem?.pie?.items?.forEach((item) => {
      if (!allElementsId.includes(item.elementId)) {
        allElementsId.push(item.elementId);
      }
    }),
  );

  impressionByOverallData.forEach((dataItem) =>
    dataItem?.bar?.items?.forEach((item) => {
      if (!allElementsId.includes(item.elementId)) {
        allElementsId.push(item.elementId);
      }
    }),
  );

  impressionByOverallData.forEach((dataItem) =>
    dataItem?.pie?.items?.forEach((item) => {
      if (!allElementsId.includes(item.elementId)) {
        allElementsId.push(item.elementId);
      }
    }),
  );

  const colors = generateColors(allElementsId.length);

  const itemsWithColors = allElementsId.map((id, index) => ({ elementId: id, color: colors[index] }));

  const renderItem = (
    item: ImpressionByCohortDataItemSchema | ImpressionByProductDataItemSchema | ImpressionByOverallDataItemSchema,
    index: number,
  ) => (
    <Box key={item.positionName}>
      <Box>
        <Typography.Tag>{`${content.position} ${index + 1}`}</Typography.Tag>
        <Typography.Title>{item.positionName}</Typography.Title>
      </Box>
      <Box className={styles.chartsContainer}>
        <PieChart
          data={item.pie as PieChartData}
          itemsWithColors={itemsWithColors}
          centerText={content.totalOfImpressions}
          title={content.moduleImpressionVolume}
        />
        <BarGroupedChart
          data={item.bar as BarGroupedChartData}
          itemsWithColors={itemsWithColors}
          sortValue={sortValue}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <FormControl className={styles.radioRow}>
        <FormLabel>
          <Typography.Tag className={styles.radioLabel}>{`${content.sortResultsBy}:`}</Typography.Tag>
        </FormLabel>
        <RadioGroup row value={sortValue} onChange={handleChangeSort}>
          <FormControlLabel
            value="cohort"
            className={styles.radioControl}
            label={<Typography.Body>{content.cohort}</Typography.Body>}
            control={<Radio color="primary" />}
          />
          <FormControlLabel
            value="product"
            className={styles.radioControl}
            label={<Typography.Body>{content.product}</Typography.Body>}
            control={<Radio color="primary" />}
          />
          <FormControlLabel
            value="overall"
            className={styles.radioControl}
            label={<Typography.Body>{content.overall}</Typography.Body>}
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </FormControl>
      {sortValue === 'cohort' && impressionByCohortData.map((item, index) => renderItem(item, index))}
      {sortValue === 'product' && impressionByProductData.map((item, index) => renderItem(item, index))}
      {sortValue === 'overall' && impressionByOverallData.map((item, index) => renderItem(item, index))}
    </>
  );
}
