import { Select } from '@components';
import { Box, Button } from '@material-ui/core';

import { content } from '@content';
import { dataVisualization } from '@modules';
import { getDataVisualizationImages } from '@modules/DataVisualization/DataVisualization.thunk';
import { useAppDispatch } from '@store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DVCenterModalProps } from './DVCenterModal.props';
import { useStyles } from './DVCenterModal.styles';

/**
 ImageModal component
 @returns {JSX.Element}
 */

export const DVCenterModal = ({
  chosenDV,
  curDeployment,
  curSegment,
  setCurSegment,
  onClose,
}: DVCenterModalProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { defaultImagePath } = chosenDV;
  const DVDeployments = dataVisualization.useDataVisualizationDeploymentsData();
  const DVImages = dataVisualization.useDataVisualizationImagesData();
  const [curImage, setCurImage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);
  const [segmentOptions, setSegmentOptions] = useState<any[]>([]);
  const [isSegmentVisible, setIsSegmentVisible] = useState(true);

  const handleSegmentChange = (e: any) => {
    setCurSegment(e.target.value);
  };

  const handleCurImageChange = useCallback(
    (direction: 'prev' | 'next') => async () => {
      switch (direction) {
        case 'prev':
          if (imageIndex !== 0) {
            setImageIndex(imageIndex - 1);
            setCurImage(DVImages.items[imageIndex - 1]);
          }
          break;
        case 'next':
          if (imageIndex + 1 === DVImages.items.length && curSegment) {
            const resp: any = await dispatch(
              getDataVisualizationImages({ segment: curSegment, continuationToken: DVImages.continuationToken }),
            );

            setImageIndex(imageIndex + 1);
            setCurImage(resp.payload.items[0]);
            return;
          }

          setImageIndex(imageIndex + 1);
          setCurImage(DVImages.items[imageIndex + 1]);
          break;
        default:
          break;
      }
    },
    [DVImages, curImage, imageIndex, curSegment, setCurImage, setImageIndex, dispatch],
  );

  useEffect(() => {
    dispatch(dataVisualization.actions.resetDataVisualizationImages());
    setCurImage('');
    if (curSegment) {
      dispatch(getDataVisualizationImages({ segment: curSegment }));
    }
  }, [curSegment, setCurImage, dispatch]);

  useEffect(() => {
    setCurSegment(undefined);
    setCurImage('');
    dispatch(dataVisualization.actions.resetDataVisualizationImages());
  }, [chosenDV, setCurImage, setCurSegment, dispatch]);

  useEffect(() => {
    if (DVImages.items.length) {
      setCurImage(DVImages.items[0]);
    }
  }, [DVImages, setCurImage]);

  useEffect(() => {
    const options = Object.values(DVDeployments)
      .reduce<any>((accum, segments: any) => {
        return accum.concat(segments);
      }, [])
      .filter((segment: any) => segment.deployment.id === curDeployment)
      .map((segment: any) => ({
        id: segment.id,
        label: segment.name,
        value: segment.id,
      }));

    if (options[0]) {
      options[0].checked = true;
    }

    setSegmentOptions(options);

    return () => {
      dispatch(dataVisualization.actions.resetDataVisualizationImages());
    };
  }, [DVDeployments, curDeployment, setSegmentOptions, dispatch]);

  useEffect(() => {
    setIsSegmentVisible(false);
    setTimeout(() => setIsSegmentVisible(true));
    setCurImage('');
  }, [chosenDV, curDeployment, setCurImage]);

  return (
    <Box
      style={{
        paddingTop: `0`,
        height: 'calc(150vh + 24.2rem)',
      }}
      className={styles.DVModalWrapper}
    >
      <Box className={styles.moduleContent}>
        <Box className={styles.imageWrapper}>
          <Box className={styles.DVImageLabel}>{content.dataVisualizationImage}</Box>
          {!!curImage && <img className={styles.image} src={`${curImage}?t=${Date.now()}`} alt="dv-image" />}
        </Box>
        <Box className={styles.actions}>
          <Button
            className={styles.actionBtn}
            variant="outlined"
            size="medium"
            color="primary"
            onClick={handleCurImageChange('prev')}
            disabled={!DVImages.items.length || imageIndex === 0}
          >
            {content.previousImage}
          </Button>
          {isSegmentVisible ? (
            <Select
              className={styles.segmentSelect}
              options={segmentOptions}
              placeholder={content.selectSegment}
              onChange={handleSegmentChange}
              value={curSegment}
            />
          ) : (
            <Box></Box>
          )}
          <Button
            className={styles.actionBtn}
            variant="outlined"
            size="medium"
            color="primary"
            onClick={handleCurImageChange('next')}
            disabled={
              !DVImages.items.length || (imageIndex === DVImages.items.length - 1 && !DVImages.continuationToken)
            }
          >
            {content.nextImage}
          </Button>
        </Box>
        <Box className={styles.defaultImageWrapper}>
          <Box className={styles.DVImageLabel}>{content.defaultImage}</Box>
          <img className={styles.defaultImage} src={defaultImagePath} alt="default image" />
        </Box>
      </Box>
    </Box>
  );
};
