import React, { FormEvent } from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@material-ui/core';

import { Link, Panel, Typography, Alert } from '@components';
import { content, image } from '@content';
import { FormFieldProps, FormProps, FormValues } from './Form.props';
import { useStyles } from './Form.styles';
import { FORM_FIELD_TYPE, FORM_FIELD_DEFAULT_VALUE } from './Form.const';

export const Form: React.FC<FormProps> = ({
  className,
  buttonsClassName = '',
  status = 'idle',
  requiredWarn = '',
  invalidWarn = '',
  successMessage = '',
  title,
  subtitle,
  description,
  fields,
  buttons,
  links,
  keepSucceedEnabled = false,
  onSubmit,
}): React.ReactElement => {
  const styles = useStyles();
  const isLoading = React.useMemo(() => status === 'loading', [status]);
  const isError = React.useMemo(() => status === 'error', [status]);
  const isSuccess = React.useMemo(() => status === 'success', [status]);
  const isSucceedDisabled = React.useMemo(() => isSuccess && !keepSucceedEnabled, [isSuccess, keepSucceedEnabled]);

  const [values, setValues] = React.useState<FormValues>(
    fields?.reduce(
      (map, field) => ({
        ...map,
        [field.id]: field.value || FORM_FIELD_DEFAULT_VALUE[field.type || 'txt'],
      }),
      {},
    ) || {},
  );

  const dirty = React.useMemo(() => Object.values(values).some((value) => !!value), [values]);

  const [alert, setAlert] = React.useState<string>('');

  const requiredFields = React.useMemo<FormFieldProps[] | undefined>(
    () => fields?.filter((field) => field.required),
    [fields],
  );

  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>(
    requiredFields?.reduce((errs, field) => ({ ...errs, [field.id]: false }), {}) || {},
  );

  const clearAlert = React.useCallback(() => setAlert(''), [setAlert]);
  const clearError = React.useCallback((id: string) => setErrors({ ...errors, [id]: false }), [errors, setErrors]);

  const handleFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | unknown>) => {
      const {
        type,
        value,
        checked,
        dataset: { id },
      } = event.currentTarget as HTMLInputElement;

      clearError(id as string);
      clearAlert();

      setValues({
        ...values,
        [id as string]: type === 'checkbox' ? checked : value,
      });
    },
    [values, setValues, clearError, clearAlert],
  );

  const handleButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { label } = (event.currentTarget as HTMLButtonElement).dataset;
      const button = buttons?.find((btn) => btn.label === label);

      if (button && button.onClick) {
        button.onClick();
      }
    },
    [buttons],
  );

  const handleSubmit = React.useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      const emptyRequiredFields =
        requiredFields?.filter((field) => values[field.id] === FORM_FIELD_DEFAULT_VALUE[field.type || 'txt']) || [];

      const noEmptyRequiredFields = emptyRequiredFields.length === 0;

      const invalidFields =
        fields?.filter(
          (field) =>
            !emptyRequiredFields.find((f) => f.id === field.id) &&
            field.validate &&
            !field.validate(values[field.id], values),
        ) || [];

      if (noEmptyRequiredFields && invalidFields.length === 0) {
        onSubmit(values);
      } else {
        const nextErrors = [...emptyRequiredFields, ...invalidFields].reduce(
          (errs, field) => ({ ...errs, [field.id]: true }),
          {},
        );

        setErrors(nextErrors);
        setAlert(noEmptyRequiredFields ? invalidWarn : requiredWarn);
      }
    },
    [requiredFields, values, fields, onSubmit, setErrors, setAlert, invalidWarn, requiredWarn],
  );

  const prevStatus = React.useRef<typeof status>(status);

  React.useEffect(() => {
    if (prevStatus.current !== status) {
      prevStatus.current = status;
      if (dirty) {
        setAlert(isError ? invalidWarn : (isSuccess && successMessage) || ''); // eslint-disable-line no-mixed-operators
      }
    }
  }, [setAlert, prevStatus, status, isError, invalidWarn, successMessage, isSuccess, dirty]);

  return (
    <Panel className={className}>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <img src={image.composefyPurpleLogo} alt={content.company} className={styles.logo} />
          <Typography.LargeTitle className={styles.title}>{title}</Typography.LargeTitle>
          {subtitle && <Typography.Headline>{subtitle}</Typography.Headline>}
          {description && <Typography.Body className={styles.description}>{description}</Typography.Body>}
          <Alert className={styles.alert} type={isSuccess ? 'success' : 'error'} message={alert} onClose={clearAlert} />
          {fields && (
            <Box>
              {fields.map((field) =>
                field.type === 'cbx' ? (
                  <FormControlLabel
                    className={styles.cbx}
                    key={field.id}
                    label={<Typography.Body>{field.label}</Typography.Body>}
                    checked={values[field.id] as boolean}
                    disabled={isLoading || isSucceedDisabled || !!field.disabled}
                    control={<Checkbox inputProps={{ 'data-id': field.id } as any} />} // eslint-disable-line @typescript-eslint/no-explicit-any
                    onChange={handleFieldChange}
                  />
                ) : (
                  <Box className={styles[field.type === 'pwd' ? 'pwd' : 'txt']} key={field.id}>
                    <Typography.Label className={styles.label}>{field.label}</Typography.Label>
                    <TextField
                      fullWidth
                      type={FORM_FIELD_TYPE[field.type || 'txt']}
                      value={values[field.id]}
                      disabled={isLoading || isSucceedDisabled || !!field.disabled}
                      error={errors[field.id]}
                      placeholder={field.placeholder}
                      autoComplete="new-password"
                      inputProps={{ 'data-id': field.id }}
                      onChange={handleFieldChange}
                    />
                  </Box>
                ),
              )}
            </Box>
          )}
          <Box className={styles.bottomContainer}>
            {links && (
              <Box>
                {links.map((link) => (
                  <Link key={link.label} {...link} className={styles.link} />
                ))}
              </Box>
            )}
            {buttons && (
              <Box className={`${styles.buttons} ${buttonsClassName}`}>
                {buttons?.map((button) => (
                  <Button
                    className={styles.button}
                    {...(button.color ? { color: button.color } : {})}
                    key={button.label}
                    type={button.isSubmit ? 'submit' : 'button'}
                    variant={button.variant || 'contained'}
                    disabled={isLoading || (button.isSubmit && isSucceedDisabled)}
                    data-label={button.label}
                    onClick={button.isSubmit ? undefined : handleButtonClick}
                  >
                    {button.iconLeft}
                    {button.label}
                    {button.iconRight}
                  </Button>
                ))}
              </Box>
            )}
          </Box>
        </FormGroup>
      </form>
    </Panel>
  );
};
