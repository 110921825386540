import { SVGProps } from 'react';

export const LockedOutline = ({
  width = 16,
  height = 16,
  stroke = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.5 6.5V3.53125C10.5 2.86821 10.2366 2.23232 9.76777 1.76348C9.29893 1.29464 8.66304 1.03125 8 1.03125C7.33696 1.03125 6.70107 1.29464 6.23223 1.76348C5.76339 2.23232 5.5 2.86821 5.5 3.53125V6.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 6.5H4.5C3.67157 6.5 3 7.17157 3 8V13.5C3 14.3284 3.67157 15 4.5 15H11.5C12.3284 15 13 14.3284 13 13.5V8C13 7.17157 12.3284 6.5 11.5 6.5Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
