import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * DeploymentPicker component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    deploymentPicker: {
      maxWidth: `calc(50% - ${theme.spacing(2)}px)`,
      padding: theme.spacing(1, 0),
      display: 'flex',
      flexGrow: 1,
    },
    select: {
      maxWidth: `calc(50% - ${theme.spacing(0.5)}px)`,
      margin: theme.spacing(0, 1.5),

      '&:first-child': {
        marginLeft: 0,
      },

      ['@media (max-width: 1250px)']: {
        margin: theme.spacing(0, 1),
      },
    },
  }),
);
