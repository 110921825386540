import { network } from '@utils';
import { ApiError } from '@modules';
import { createAsyncThunk } from '@store';

import { keys } from './ModuleDetails.keys';
import { ModuleInfoPayload, ModuleSavePayload } from './ModuleDetails.schema';

export const get = createAsyncThunk(keys.get, (module: number, { signal, rejectWithValue }) => {
  try {
    return network.get<ModuleInfoPayload>({ key: 'get_module_info', params: { module } }, { signal });
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});

export const save = createAsyncThunk(
  [keys.update, keys.image].join(' & '),
  async ({ details, isDirty }: ModuleSavePayload, { signal, rejectWithValue }) => {
    const { imageFile, coverImage, id, ...data } = details;
    const params = { module: id };
    const imgRouting = { key: 'update_module_cover', params };
    const init = { signal };
    let response;

    try {
      if (imageFile) {
        const body = new FormData();

        body.append('imageFile', imageFile as Blob);

        response = await network.post<ModuleInfoPayload>(imgRouting, { ...init, body });
      } else if (imageFile === null) {
        response = await network.remove<ModuleInfoPayload>(imgRouting, init);
      }

      // TODO: rewrite these please
      const payload = JSON.parse(JSON.stringify(data));

      if (payload.moduleDisclosureStation?.id && response?.moduleDisclosureStation?.id) {
        payload.moduleDisclosureStation.id = response.moduleDisclosureStation.id;
      }

      if (payload.moduleDataVisualizationStation?.id && response?.moduleDisclosureStation?.id) {
        payload.moduleDataVisualizationStation.id = response.moduleDataVisualizationStation?.id;
      }

      return isDirty
        ? await network.put<ModuleInfoPayload>(
            { key: 'update_module', params },
            { ...init, body: JSON.stringify(payload) },
          )
        : response;
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const personalize = createAsyncThunk(keys.personalize, (module: number, { signal, rejectWithValue }) => {
  try {
    return network.put<ModuleInfoPayload>({ key: 'update_module_personalization', params: { module } }, { signal });
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});
