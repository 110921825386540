import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';

import { LinkProps } from './Link.props';
import { useStyles } from './Link.styles';

export const Link = ({
  className = '',
  uiType = 'default',
  size = 'large',
  isExternal = false,
  label = '',
  children = null,
  to,
  target = 'self',
  title = '',
  onHover,
}: LinkProps): JSX.Element => {
  const styles = useStyles();
  const props = isExternal ? { href: to } : { to, component: RouterLink };

  const handleHover = React.useCallback((value: boolean) => onHover && onHover(value), [onHover]);

  const handleMouseEnter = React.useCallback(() => handleHover(true), [handleHover]);

  const handleMouseLeave = React.useCallback(() => handleHover(false), [handleHover]);

  return (
    <MuiLink
      className={`${styles[size]} ${className}`}
      {...props}
      target={`_${target}`}
      title={title}
      data-ui-type={uiType}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children || label}
    </MuiLink>
  );
};
