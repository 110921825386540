import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * AppleMailPrivacyCard component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      background: variables.color.primary.white,
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
    },

    appleMailReaderPercent: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(2),
      color: variables.color.primary.mainPurple,
      fontSize: variables.font.size.xxxxl,
      fontWeight: variables.font.weight.medium,
      lineHeight: '3.8rem',
      letterSpacing: '-0.04em',
    },

    bodyText: {
      display: 'inline',
      color: variables.color.primary.darkestGray,
      fontSize: variables.font.size.lg,
      fontWeight: variables.font.weight.medium,
      lineHeight: '2.1rem',
      '& span': {
        color: variables.color.semantic.negative,
        fontWeight: variables.font.weight.bold,
      },
    },
  }),
);
