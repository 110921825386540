import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * TreatmentCard component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    treatmentCard: {
      display: 'flex',
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      paddingRight: theme.spacing(1),
      borderRadius: variables.borderRadius.sm,
      height: '7.4rem',
      transition: 'all 0.15s',

      '&[data-grouped=false]': {
        marginBottom: theme.spacing(2),
        cursor: 'pointer',
      },

      '&[data-grouped=true]': {
        border: 'none',
        borderRadius: 'unset',
      },

      '&.dragging-over': {
        opacity: variables.opacity.half,
      },

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        height: 'auto',
        paddingRight: 0,
      },
    },

    indicatorWrapper: {
      width: '0.4rem',
      marginRight: theme.spacing(2),

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        marginRight: '9px',
      },
    },

    indicator: {
      width: '100%',
      height: '100%',
      background: variables.color.primary.mainPurple,
      borderRadius: `${variables.borderRadius.sm} 0 0 ${variables.borderRadius.sm}`,

      '&[data-grouped=true]': {
        borderRadius: 'unset',
      },
    },

    cardContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    labelWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1.75),
      minWidth: '190px',

      ['& svg']: {
        ['@media (min-width: 1200px) and (max-width: 1580px)']: {
          marginTop: '6px',
        },
      },
    },

    treatmentLeft: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3px',

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        flexDirection: 'row',
        gap: '11px',
        paddingTop: '7px',
        alignItems: 'center',
      },
    },

    treatmentName: {
      fontSize: `${variables.font.size.lg}!important`,
      fontWeight: `700!important` as any,
    },

    treatmentTag: {
      fontSize: `${variables.font.size.sm}!important`,
      color: variables.color.primary.gray,
    },

    treatmentInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        justifyContent: 'space-between',
        width: '100%',
      },
    },

    treatmentRight: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      alignItems: 'flex-end',

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    treatmentBtnSave: {
      minWidth: 'auto',
      cursor: 'pointer',
      width: '34px',
      padding: '6px',
    },

    contentName: {
      display: 'flex',
      gap: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      textAlign: 'right',

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        textAlign: 'left',
      },
    },

    treatmentValues: {
      background: variables.color.semantic.positive,
      borderRadius: '1.6rem',
      padding: theme.spacing(0.25, 1),
      color: variables.color.primary.white,
      display: 'flex',
      gap: theme.spacing(0.5),
      border: `${variables.borderWidth.md} solid transparent`,
      width: 'fit-content',

      '&[data-indicated=false]': {
        background: variables.color.primary.white,
        border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,

        '& $value': {
          color: `${variables.color.primary.darkestGray}!important`,
        },

        '& $percent': {
          color: `${variables.color.primary.darkestGray}!important`,
        },
      },

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        marginBottom: '8px',
      },
    },

    value: {
      color: `${variables.color.primary.white}!important`,
      fontWeight: variables.font.weight.semiBold,
    },

    percent: {
      color: `${variables.color.primary.white}!important`,
      fontWeight: variables.font.weight.regular,
      lineHeight: variables.lineHeight.sm,
    },

    menu: {
      marginLeft: theme.spacing(0.5),
      padding: theme.spacing(1),
      border: 'none',

      '&:hover, &[data-opened="true"]': {
        border: 'none',
      },
    },
  }),
);
