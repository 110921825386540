import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { getList, getEntity, create, update } from './Enterprise.thunk';
import { initialState } from './Enterprise.state';
import { EnterpriseSchema, Enterprise } from './Enterprise.schema';
import { EnterpriseUpdateEntityClientProgramArg, EnterpriseUpdateListItemClientArg } from './Enterprise.types';
import { group } from 'console';

export const reducers = {
  findById: (state: EnterpriseSchema, action: PayloadAction<Enterprise['id']>): void => {
    state.entity.data = state.list.data.find((item) => item.id === action.payload);
  },
  replaceListItem: (state: EnterpriseSchema, action: PayloadAction<Enterprise | undefined>): void => {
    if (action.payload) {
      const { id } = action.payload;
      const index = state.list.data.findIndex((item) => item.id === id);

      if (index !== -1) {
        state.list.data[index] = action.payload;
      }
    }
  },
  appendListItem: (state: EnterpriseSchema, action: PayloadAction<Enterprise | undefined>): void => {
    if (action.payload) {
      state.list.data.push(action.payload);
    }
  },
  updateListItemClient: (state: EnterpriseSchema, action: PayloadAction<EnterpriseUpdateListItemClientArg>): void => {
    const { sourceEnterpriseId: srcId, enterpriseId: dstId, businessUnit } = action.payload;
    const srcIdx = srcId === dstId ? -1 : state.list.data.findIndex((item) => item.id === srcId);
    const dstIdx = state.list.data.findIndex((item) => item.id === dstId);

    if (dstIdx !== -1) {
      const dst = state.list.data[dstIdx];
      const dstBusinessUnit = [...dst.businessUnits];
      const dstbusinessUnitIdx = dstBusinessUnit.findIndex((item) => item.id === businessUnit.id);
      const nextList = [...state.list.data];

      if (dstbusinessUnitIdx === -1) {
        dstBusinessUnit.push(businessUnit);
      } else {
        dstBusinessUnit[dstbusinessUnitIdx] = businessUnit;
      }

      if (srcIdx !== -1) {
        const src = state.list.data[srcIdx];
        const srcBusinessUnit = src.businessUnits.filter((item) => item.id !== businessUnit.id);
        nextList[srcIdx] = { ...src, businessUnits: srcBusinessUnit };
      }

      nextList[dstIdx] = { ...dst, businessUnits: dstBusinessUnit };

      state.list.data = nextList;
    }
  },
  resetEntity: (state: EnterpriseSchema): void => {
    state.entity.data = undefined;
  },
  appendEntityClientProgram: (
    state: EnterpriseSchema,
    action: PayloadAction<EnterpriseUpdateEntityClientProgramArg>,
  ): void => {
    const { businessUnitId, program } = action.payload;

    state.entity.data?.businessUnits
      .find((businessUnit) => businessUnit.id === businessUnitId)
      ?.programs?.push(program);
  },
  updateEntityClientProgram: (
    state: EnterpriseSchema,
    action: PayloadAction<EnterpriseUpdateEntityClientProgramArg>,
  ): void => {
    const { businessUnitId, program } = action.payload;
    const programs =
      state.entity.data?.businessUnits.find((businessUnit) => businessUnit.id === businessUnitId)?.programs || [];
    const programIndex = programs.findIndex((prog) => prog.id === program.id);

    programs[programIndex] = program;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<EnterpriseSchema>): void => {
  builder.addCase(getList.pending, (state, action) => {
    if (state.list.status !== 'loading') {
      state.list.requestId = action.meta.requestId;
      state.list.data = initialState.list.data;
      state.list.status = 'loading';
    }
  });

  builder.addCase(getList.fulfilled, (state, action) => {
    if (state.list.status === 'loading' && state.list.requestId === action.meta.requestId) {
      state.list.requestId = initialState.list.requestId;
      state.list.data = initialState.list.data;
      if (action.payload) {
        state.list.data = action.payload.map((enterpriseGroup) => {
          const businessUnits = enterpriseGroup.businessUnits.sort((a, b) => {
            if (a.id < b.id) {
              return -1;
            }
            if (a.id > b.id) {
              return 1;
            }
            return 0;
          });
          return {
            ...enterpriseGroup,
            businessUnits,
          };
        });
        state.list.status = 'success';
      }
    }
  });

  builder.addCase(getList.rejected, (state, action) => {
    if (state.list.status === 'loading' && state.list.requestId === action.meta.requestId) {
      state.list.requestId = initialState.list.requestId;
      state.list.error = action.error;
      state.list.status = 'error';
    }
  });

  builder.addCase(getEntity.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.data = initialState.entity.data;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(getEntity.fulfilled, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.data = action.payload;
      state.entity.status = 'success';
    }
  });

  builder.addCase(getEntity.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = action.error;
      state.entity.status = 'error';
    }
  });

  builder.addCase(create.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.data = initialState.entity.data;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(create.fulfilled, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.data = action.payload;
      reducers.appendListItem(state, action);
      state.entity.status = 'success';
    }
  });

  builder.addCase(create.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = action.error;
      state.entity.status = 'error';
    }
  });

  builder.addCase(update.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.data = initialState.entity.data;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(update.fulfilled, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.data = action.payload;
      reducers.replaceListItem(state, action);
      state.entity.status = 'success';
    }
  });

  builder.addCase(update.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = action.error;
      state.entity.status = 'error';
    }
  });
};
