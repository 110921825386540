import { base, ImageLibrarySchema } from '@modules';

export const initialState: ImageLibrarySchema = {
  all: { ...base.state, data: { ...base.paginagedState } },
  entity: {
    ...base.state,
    data: null,
  },
  search: {
    ...base.state,
    data: null,
  },
  itemContent: { ...base.state, data: null },
};
