import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector as use } from '@store';
import { MetaSchema } from '@modules';

import * as thunk from './Template.thunk';
import { initialState } from './Template.state';
import { reducers, extraReducers } from './Template.reducer';
import { TemplateAllData, TemplateEntityData } from './Template.schema';

export * from './Template.schema';

export const template = {
  thunk,
  slice: createSlice({
    name: 'template',
    initialState,
    reducers,
    extraReducers,
  }),
  useAllMeta: (): MetaSchema =>
    use(
      ({
        template: {
          all: { data, ...meta },
        },
      }) => meta,
    ),
  useAllData: (): TemplateAllData => use((root) => root.template.all.data),
  useEntityMeta: (): MetaSchema =>
    use(
      ({
        template: {
          entity: { data, ...meta },
        },
      }) => meta,
    ),
  useEntityData: (): TemplateEntityData => use((root) => root.template.entity.data),
};
