import { content } from '@content';
import { Box } from '@material-ui/core';
import { SignalsSearchItem, attributeLibrary } from '@modules';
import { useEffect, useState } from 'react';
import { DVAttributeProps } from './DVAttribute.props';
import { useStyles } from './DVAttribute.styles';

export const DVAttribute: React.FC<DVAttributeProps> = ({ value }) => {
  const styles = useStyles();
  const { items: attributes } = attributeLibrary.useListData();
  const [selectedSignal, setSelectedSignal] = useState<SignalsSearchItem | null>(null);
  const filteredAttributes = attributes.filter((attribute) => ['textual', 'numeric'].includes(attribute.type));

  useEffect(() => {
    const initialSignal = filteredAttributes.find((attr) => attr.name === value);

    setSelectedSignal(initialSignal!);
  }, [filteredAttributes, value]);

  return (
    <Box className={styles.DVAttribute}>
      {selectedSignal ? (
        <Box className={styles.selectedAttribute}>{selectedSignal.name}</Box>
      ) : (
        <span>{content.selectAnAttr}</span>
      )}
    </Box>
  );
};
