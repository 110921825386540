export const SPLITTER_DATASET_ID_KEY = 'sampleTemplateSplitter';

export const SPLITTER_DATASET_JOURNEY_ID_KEY = 'sampleTemplateJourney';

export const GLOBAL_STYLES_ID = 'sampleTemplateGlobalStyles';

export const SPLITTER_INSERTION_WIDTH_RATIO = 0.5; // element to parent widths ratio

export const SPLITTER_BORDER_DEFAULT = '2px dashed #53565a';

export const SPLITTER_BORDER_ACTIVE = '2px dashed #f50057';

export const EMPTY_MODULE_NOTE = `
  <table align="center">
    <tr>
      <td>Please fill module html.<td>
    </tr>
  </table>
`;
