import { SVGProps } from 'react';

export const DraggableVertically = ({
  width = 16,
  height = 16,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="12" cy="6" r="1" transform="rotate(90 12 6)" fill={fill} />
    <circle cx="8" cy="6" r="1" transform="rotate(90 8 6)" fill={fill} />
    <circle cx="4" cy="6" r="1" transform="rotate(90 4 6)" fill={fill} />
    <circle cx="12" cy="10" r="1" transform="rotate(90 12 10)" fill={fill} />
    <circle cx="8" cy="10" r="1" transform="rotate(90 8 10)" fill={fill} />
    <circle cx="4" cy="10" r="1" transform="rotate(90 4 10)" fill={fill} />
  </svg>
);
