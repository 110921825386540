import { makeStyles, createStyles } from '@material-ui/core/styles';

import { theme, variables } from '@styles';

/**
 * TemplateItem component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    templateItem: {
      height: '20.3rem',
      backgroundColor: variables.color.primary.white,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      border: ({ selected }: { selected: boolean }) =>
        selected
          ? `${variables.borderWidth.lg} solid ${variables.color.primary.mainPurple}`
          : `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      cursor: 'pointer',

      '&:hover': {
        border: `${variables.borderWidth.lg} solid ${variables.color.primary.lightPurple}`,
      },
    },

    content: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    leftContent: {
      width: '90%',
    },

    image: {
      objectFit: 'cover',
      height: '12.6rem',
      width: '100%',
      marginBottom: theme.spacing(0.5),
    },

    iconListItem: {
      stroke: variables.color.primary.mediumGray,
      minWidth: 'unset',
    },

    name: {
      color: variables.color.primary.darkestGray,
    },

    experience: {
      color: variables.color.primary.mediumGray,
    },
  }),
);
