import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * TreatmentPanel component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    treatmentPanel: {},

    closeBtn: {
      position: 'absolute',
      right: 0
    }
  }),
);
