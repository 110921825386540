import { useRef, useState, useEffect, useMemo, useCallback, ChangeEvent, useContext } from 'react';
import { Box, Button, Divider, Grid, MenuItem } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { Icon, Typography, Menu, Accordion, OptionItem, Select, Alert } from '@components';
import {
  deployment,
  userClients,
  experience,
  template,
  DeploymentStatus,
  PublishActions,
  global,
  auth,
} from '@modules';
import { useAppDispatch } from '@store';
import { content } from '@content';
import { variables } from '@styles';
import { format, useLoader, useHistory, isRoleSystemAdmin, isRoleAdmin, isRoleEditor, func } from '@utils';

import { ModulesList } from './ModulesList';

import { useStyles } from './Publish.styles';
import { MENU_OPTIONS, menuKey } from './Publish.const';
import { getGroupedModulesList, getPublishMessage } from './Publish.utils';
import { TreatmentBuilderContext } from '@routes/TemplateView';
import { PublishMessage } from './Publish.types';

/**
 Publish component.
 @returns {JSX.Element}
 */

export const Publish = (): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { closeSnackbar } = useSnackbar();

  const { experiencesIds } = useContext(TreatmentBuilderContext);

  const channelRef = useRef<EventSource | null>(null);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [showMailSelect, setShowMailSelect] = useState<boolean>(false);
  const [selectedMailFile, setSelectedMailFile] = useState<number>();
  const [publishResults, setPublishResults] = useState<PublishActions | null>(null);
  const [otherTemplatePublished, setOtherTemplatePublished] = useState(false);
  const [publishing, setPublishing] = useState<boolean>(false);
  const [cannotConnect, setCannotConnect] = useState<boolean>(false);
  const [alertDescription, setAlertDescription] = useState<string>();
  const [actionLink, setActionLink] = useState<JSX.Element | null>(null);
  const experienceItemMeta = experience.useItemMeta();
  const expItemSuccessStatus = experienceItemMeta.status === 'success';
  const tmplEntitySuccessStatus = template.useEntityMeta().status === 'success';
  const [menuOptions, setMenuOptions] = useState(MENU_OPTIONS);
  const userData = auth.useData();

  const { enterprise: enterpriseId, businessUnit: businessUnitId, deployment: deploymentId } = history.query;

  const { data: modulesList, ...modulesListMeta } = deployment.useModulesList();

  const currentClientData = userClients.useEntityData();
  const currentDeployment = deployment.useDeploymentData();
  const isLoading = useMemo(
    () =>
      menuOptions.reduce(
        (acc, item) =>
          acc ||
          item.loading ||
          !!currentDeployment?.downloadFilesRequests.find((request) => request.user.id === userData.id),
        false,
      ),
    [menuOptions, userData, currentDeployment],
  );
  const notifications = global.useNotifications();
  const isAdmin = isRoleAdmin();
  const isSystemAdmin = isRoleSystemAdmin();
  const isEditor = isRoleEditor();

  const deploymentMeta = deployment.useMeta();

  // useLoader(deploymentMeta, modulesListMeta, experienceItemMeta);

  const handleCloseAlert = () => setCannotConnect(false);

  const handleChangePage = useCallback(() => {
    const params = {
      enterprise: enterpriseId,
      businessUnit: businessUnitId,
      deployment: null,
      mailFile: null,
      template: null,
      program: null,
      id: businessUnitId,
    };

    history.push('adminClients', params);
  }, [enterpriseId, businessUnitId, history]);

  useEffect(() => {
    const notConnectNotification = notifications.find((notification) => notification.code === '422');

    if (notConnectNotification) {
      setCannotConnect(true);

      if (isSystemAdmin || isAdmin) {
        setAlertDescription(content.notPublishDeploymentAdmin);
        setActionLink(
          <Button onClick={handleChangePage} variant="text" className={styles.changeLink}>
            {content.settingsPage}
          </Button>,
        );
      } else if (isEditor) {
        setAlertDescription(content.notPublishDeploymentEditor);
      }

      setTimeout(() => closeSnackbar(notConnectNotification.key), 1500);
    }
  }, [isSystemAdmin, isAdmin, isEditor, closeSnackbar, notifications, styles, handleChangePage]);

  useEffect(() => {
    if (currentDeployment && currentDeployment.lastPublishDetails) {
      setPublishResults(currentDeployment.lastPublishDetails);

      if (currentDeployment.currentEmailTemplate.id !== currentDeployment.lastPublishDetails.publishedTemplate.id) {
        setOtherTemplatePublished(true);
      }
    } else {
      setPublishResults(null);
      setOtherTemplatePublished(false);
    }
  }, [currentDeployment]);

  const mailFiles = currentClientData?.mailFile;
  const [mailFileOptions, setMailFileOptions] = useState<OptionItem[]>([
    {
      id: '-1',
      label: content.noTreatmentRequestFile,
    },
  ]);

  const currentMailFile = useMemo(
    () => mailFileOptions.find((mailFileOption) => mailFileOption.id === selectedMailFile?.toString()),
    [mailFileOptions, selectedMailFile],
  );

  useEffect(() => {
    setMailFileOptions(
      [
        {
          id: '-1',
          label: content.noTreatmentRequestFile,
        },
      ].concat(
        mailFiles?.map((mailFile) => ({
          id: mailFile.id.toString(),
          label: mailFile.name,
        })) ?? [],
      ),
    );
  }, [mailFiles]);

  useEffect(() => {
    const currentMailFileOptions = [...mailFileOptions];
    const curMailFileOption = currentMailFileOptions.find(
      (mailFileOption) => mailFileOption.id === selectedMailFile?.toString() && !mailFileOption.selected,
    );

    if (curMailFileOption) {
      currentMailFileOptions.forEach((mailFileOption) => {
        mailFileOption.selected = false;
      });

      curMailFileOption.selected = true;

      setMailFileOptions(currentMailFileOptions);
    }
  }, [mailFileOptions, currentMailFile]);

  useEffect(() => {
    setSelectedMailFile(currentDeployment?.mailFile?.id || -1);
  }, [currentDeployment]);

  const hasNoContentInModules = useMemo(() => {
    return modulesList.every((item) => item.contentStatus === 'none');
  }, [modulesList]);

  const isDeploymentInMarket = useMemo(
    () => currentDeployment?.status === DeploymentStatus.inMarket,
    [currentDeployment?.status],
  );

  useEffect(() => {
    let promise: CancelablePromise<unknown> | undefined;

    if (deploymentId) {
      promise = dispatch(deployment.thunk.getModulesList(deploymentId));
    }

    return () => {
      promise?.abort();
      channelRef.current?.close();
      dispatch(deployment.actions.resetModulesList());
    };
  }, [dispatch, deploymentId, expItemSuccessStatus, tmplEntitySuccessStatus]);

  const toggleAccordion = () => setExpanded((prevState) => !prevState);

  const publish = useCallback(async () => {
    if (deploymentId) {
      channelRef.current?.close();

      const channel = (channelRef.current = new EventSource(`${process.env.REACT_APP_SSE_PUBLISH_URL}${deploymentId}`));

      channel.onmessage = (event) => {
        let publishMessage: PublishMessage | null = null;

        try {
          const { publishProgress } = (publishMessage = JSON.parse(event.data) as PublishMessage);
          dispatch(deployment.actions.updateEntityPublishSummary(publishProgress));
        } catch (exception) {
          // no action required
        } finally {
          if (publishMessage && Object.values(publishMessage.publishProgress).every((item) => item !== 'ongoing')) {
            channelRef.current?.close();
          }
        }
      };

      channel.onerror = () => {
        channelRef.current?.close();
        setPublishing(false);
      };

      setPublishing(true);

      const result = await dispatch(deployment.thunk.publishDeployment({ deployment: deploymentId }));

      if (deployment.thunk.publishDeployment.fulfilled.match(result)) {
        setPublishing(false);
      } else {
        setPublishing(false);
      }
    }
  }, [dispatch, deploymentId]);

  const handleSSEPublishFilesDownloadConnect = useCallback(() => {
    const SSEUrl =
      process.env.REACT_APP_SSE_DOWNLOAD_URL?.replace('%d', deploymentId?.toString() ?? '').replace(
        '%d',
        userData.id.toString(),
      ) ?? '';

    const publishFilesDownloadChannel = new EventSource(SSEUrl);

    publishFilesDownloadChannel.onmessage = (e: any) => {
      try {
        const data = JSON.parse(e.data);

        if (data.message === 'error') {
          dispatch(
            global.actions.enqueueNotification({
              message: content.SomethingWentWrongTryAgain,
              options: { variant: 'error' },
            }),
          );
        }

        setMenuOptions((prev) => {
          const curMenuOptions = [...prev];
          const loadingOption = curMenuOptions.find((menuOption) => menuOption.loading);

          if (loadingOption) {
            loadingOption.loading = false;
          }

          return curMenuOptions;
        });

        publishFilesDownloadChannel.close();
      } catch (err) {
        console.log(err);
      }
    };
  }, [deploymentId, userData, dispatch]);

  const onMenuClick = useCallback(
    (option: OptionItem) => {
      if (!deploymentId) {
        return;
      }
      switch (option.id) {
        case menuKey.entryPackage:
          dispatch(deployment.thunk.exportEntirePackageFile(deploymentId));
          break;
        case menuKey.smart:
          dispatch(deployment.thunk.exportSmartFile(deploymentId));
          break;
        case menuKey.module:
          dispatch(
            deployment.thunk.exportModulesFile({ deploymentId, moduleIds: modulesList.map((module) => module.id) }),
          );
          break;
        case menuKey.template:
          dispatch(deployment.thunk.exportTemplateFile(deploymentId));
          break;
      }

      handleSSEPublishFilesDownloadConnect();

      setMenuOptions((prev) => {
        const curMenuOptions = [...prev];
        const curMenuOption = curMenuOptions.find((menuOption) => menuOption.id === option.id);
        const hasLoading = !!curMenuOptions.find((menuOption) => menuOption.loading);

        if (curMenuOption && !hasLoading) {
          curMenuOption.loading = true;
        }

        return curMenuOptions;
      });
    },
    [deploymentId, modulesList, dispatch, setMenuOptions],
  );

  const handleMailStateChange = () => setShowMailSelect((prevState) => !prevState);

  const handleMailFileChange = useCallback(
    async (mailFile: number | undefined) => {
      if (deploymentId && mailFile) {
        const result = await dispatch(deployment.thunk.attachMailFile({ deploymentId, mailFileId: mailFile }));

        handleMailStateChange();

        if (deployment.thunk.attachMailFile.fulfilled.match(result)) {
          if (experiencesIds.length > 0) {
            dispatch(experience.thunk.getItemsMindsetsBatch(experiencesIds));
          }
        }
      }
    },
    [dispatch, deploymentId, selectedMailFile, experiencesIds],
  );

  const handleMailFileSet = useCallback(
    (option: OptionItem) => {
      setSelectedMailFile(Number(option.id));
      handleMailFileChange(Number(option.id));

      setMailFileOptions((prev) => {
        const curMailFileOptions = [...prev];
        const curOption = curMailFileOptions.find((mailFileOption) => mailFileOption.id === option.id);

        curMailFileOptions.forEach((fileOption) => {
          fileOption.selected = false;
        });

        if (curOption) {
          curOption.selected = true;
        }

        return curMailFileOptions;
      });
    },
    [setMailFileOptions, handleMailFileChange, setSelectedMailFile],
  );

  const getPublishBtnDisabled = () =>
    isDeploymentInMarket ||
    (typeof currentDeployment?.businessUnit.enableContentPublishing !== 'undefined' &&
      !currentDeployment?.businessUnit.enableContentPublishing) ||
    (typeof currentDeployment?.businessUnit.isESPConfigured !== 'undefined' &&
      !currentDeployment.businessUnit.isESPConfigured) ||
    (publishResults && !publishResults?.isCompleted) ||
    hasNoContentInModules ||
    publishing ||
    publishResults?.publishSummary.mailTemplatePublish === 'ongoing' ||
    publishResults?.publishSummary.smartFilePublish === 'ongoing' ||
    publishResults?.publishSummary.modulesPublish === 'ongoing';

  useEffect(() => {
    if (currentDeployment?.downloadFilesRequests.find((request) => request.user.id === userData.id)) {
      handleSSEPublishFilesDownloadConnect();
    }
  }, [currentDeployment, userData]);

  return (
    <Box className={styles.publish}>
      {cannotConnect && (
        <Alert
          message={content.publishFailed}
          description={alertDescription}
          onClose={handleCloseAlert}
          align="left"
          className={styles.alert}
          actionLink={actionLink || null}
        />
      )}
      <Box className={styles.header}>
        <Box className={styles.rowDirectionColumn}>
          <Typography.Title>{currentDeployment?.name}</Typography.Title>
          <Typography.SuperSmallCaption>
            {!publishResults && isDeploymentInMarket
              ? content.published
              : publishResults && publishResults.isCompleted && isDeploymentInMarket
              ? `${content.publishedOn} ${format.date(publishResults.updatedAt, 'PP')}`
              : content.notPublishedYet}
          </Typography.SuperSmallCaption>
        </Box>
        <Box className={styles.headerRight}>
          <Menu
            options={menuOptions}
            onClick={onMenuClick}
            title={isLoading ? content.downloading : content.download}
            loading={isLoading}
            enableHover={true}
            capitalizedLabel
          />
          <Typography.SuperSmallCaption>
            {isLoading && content.inProgressYouWillReceiveAnEmailWhenComplete}
          </Typography.SuperSmallCaption>
        </Box>
      </Box>
      <Box>
        <Grid container className={styles.grid}>
          <Grid item xs={3}>
            {[content.enterpriseGroup, content.businessUnit, content.program, content.subscriberList].map((label) => (
              <Typography.Caption className={styles.label} title={label} key={label}>
                {label}
              </Typography.Caption>
            ))}
          </Grid>
          <Grid item xs={9} className={styles.gridLeft}>
            <Typography.Caption className={styles.value}>
              {currentClientData?.enterprise?.name || content.dash}
            </Typography.Caption>
            <Typography.Caption className={styles.value}>{currentClientData?.name || content.dash}</Typography.Caption>
            <Typography.Caption className={styles.value}>
              {currentDeployment?.program.name || content.dash}
            </Typography.Caption>
            {!showMailSelect && (
              <Box className={styles.row}>
                <Typography.Caption className={styles.value}>
                  {currentDeployment?.mailFile?.name || '-'}
                </Typography.Caption>
                {mailFiles && mailFiles.length > 0 && (
                  <Button
                    className={styles.change}
                    variant="text"
                    disabled={isDeploymentInMarket}
                    onClick={isDeploymentInMarket ? func.nop : handleMailStateChange}
                  >
                    {currentDeployment?.mailFile?.name ? content.change : content.addAction}
                  </Button>
                )}
              </Box>
            )}
            {showMailSelect && mailFiles && mailFiles.length > 0 && (
              <Box className={styles.mailSelect}>
                <Menu
                  options={mailFileOptions}
                  showCheckmark={false}
                  onClick={handleMailFileSet}
                  title={currentMailFile?.label}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Accordion expanded={expanded} onChange={toggleAccordion} title={content.contents} uiType="quaternary">
          <Grid container>
            <Grid item xs={3}>
              <Typography.Caption className={styles.label}>{content.template}</Typography.Caption>
              <Typography.Caption className={styles.label}>{content.smartContentFile}</Typography.Caption>
              <Typography.Caption className={styles.label}>{content.modules}</Typography.Caption>
            </Grid>
            <Grid item xs={6} className={styles.gridCenter}>
              <Typography.Caption className={styles.value}>
                {currentDeployment?.currentEmailTemplate?.name || content.dash}
              </Typography.Caption>
              <Typography.Caption className={styles.value}>
                {currentDeployment?.smartContentFileOriginalName || content.dash}
              </Typography.Caption>

              {modulesList && modulesList.length > 0 ? (
                <Typography.Caption
                  className={styles.value}
                >{`${modulesList.length} ${content.modules}`}</Typography.Caption>
              ) : (
                <Typography.Caption
                  className={styles.value}
                >{`${content.no} ${content.contentAdded}`}</Typography.Caption>
              )}
            </Grid>
            <Grid item xs={3} className={styles.gridRight}>
              {getPublishMessage({ publishing, publishResults, otherTemplatePublished, key: 'mailTemplatePublish' })}
              {getPublishMessage({ publishing, publishResults, otherTemplatePublished, key: 'smartFilePublish' })}
              {getPublishMessage({ publishing, publishResults, otherTemplatePublished, key: 'modulesPublish' })}
            </Grid>
          </Grid>
          {modulesList.length > 0 && (
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={9} className={styles.gridCenter}>
                <ModulesList list={getGroupedModulesList(modulesList)} />
              </Grid>
            </Grid>
          )}
        </Accordion>
        <Divider />
        <Box className={styles.buttonContainer}>
          <Box className={styles.warnings}>
            {deploymentMeta.status !== 'loading' && publishResults && !publishResults?.isCompleted && (
              <Box className={styles.warning}>
                <Icon.AlertCircleOutlineV2 width="1.35rem" height="1.35rem" fill={variables.color.semantic.highlight} />
                <Typography.SuperSmallCaption className={styles.info}>
                  {content.deploymentHasActivePublishing}
                </Typography.SuperSmallCaption>
              </Box>
            )}
            {deploymentMeta.status !== 'loading' && hasNoContentInModules && (
              <Box className={styles.warning}>
                <Icon.AlertCircleOutlineV2 width="1.35rem" height="1.35rem" fill={variables.color.semantic.highlight} />
                <Typography.SuperSmallCaption className={styles.info}>
                  {content.addAtLeastOneContentModule}
                </Typography.SuperSmallCaption>
              </Box>
            )}
          </Box>
          <Button variant="contained" color="primary" onClick={publish} disabled={getPublishBtnDisabled()}>
            {publishing ||
            publishResults?.publishSummary.mailTemplatePublish === 'ongoing' ||
            publishResults?.publishSummary.smartFilePublish === 'ongoing' ||
            publishResults?.publishSummary.modulesPublish === 'ongoing'
              ? `${content.publishing}...`
              : content.publish}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
