import { Box, Radio, RadioGroup } from '@material-ui/core';
import { useContext } from 'react';

import { Typography } from '@components';
import { TreatmentBuilderContext } from '@routes';

import { useStyles } from './EditMode.styles';

/**
 EditMode component.
 @returns {JSX.Element}
 */

export const EditMode = () => {
  const styles = useStyles();
  const { editMode, setEditMode } = useContext(TreatmentBuilderContext);

  const handleEditModeChange = (e: any) => {
    setEditMode(e.target.value);
  };

  return (
    <Box className={styles.editMode}>
      <Typography.Label className={styles.radioLabel}>{'Edit mode'}</Typography.Label>
      <RadioGroup className={styles.radioGroup} value={editMode} onChange={handleEditModeChange}>
        <Typography.Label className={styles.radioLabel}>
          <Radio value={'no-code'} className={styles.checkbox} color="primary" />
          {'No-Code'}
        </Typography.Label>
        <Typography.Label className={styles.radioLabel}>
          <Radio value={'html'} className={styles.checkbox} color="primary" />
          {'HTML'}
        </Typography.Label>
      </RadioGroup>
    </Box>
  );
};
