import { base } from '@modules';
import { AttributeLibrarySchema } from './AttributeLibrary.schema';

export const initialState: AttributeLibrarySchema = {
  list: { ...base.state, data: { ...base.paginagedState } },
  feedList: { ...base.state, data: { ...base.paginagedState } },
  dataList: { ...base.state, data: { ...base.paginagedState } },
  importFile: { ...base.state },
  exportFile: { ...base.state },
};
