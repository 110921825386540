import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Typography.Display component styles
 * @returns {Function}
 */
export const useDisplayStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.xxl,
      fontWeight: variables.font.weight.extraBold,
      lineHeight: variables.lineHeight.xxxl,
      color: variables.color.primary.darkestGray,
    },
  }),
);

/**
 * Typography.LargeTitle component styles
 * @returns {Function}
 */
export const useLargeTitleStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.xl,
      fontWeight: variables.font.weight.extraBold,
      lineHeight: variables.lineHeight.xxl,
      letterSpacing: variables.letterSpacing.xs,
      color: variables.color.primary.darkestGray,
    },
  }),
);

/**
 * Typography.Title component styles
 * @returns {Function}
 */
export const useTitleStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.lg,
      fontWeight: variables.font.weight.extraBold,
      lineHeight: variables.lineHeight.xl,
      letterSpacing: variables.letterSpacing.xs,
      color: variables.color.primary.darkestGray,
    },
  }),
);

/**
 * Typography.Headline component styles
 * @returns {Function}
 */
export const useHeadlineStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.darkestGray,
    },
  }),
);

/**
 * Typography.Label component styles
 * @returns {Function}
 */
export const useLabelStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.darkestGray,
    },
  }),
);

/**
 * Typography.Body component styles
 * @returns {Function}
 */
export const useBodyStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.mediumGray,
    },
  }),
);

/**
 * Typography.Caption component styles
 * @returns {Function}
 */
export const useCaptionStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.sm,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.sm,
      color: variables.color.primary.mediumGray,
    },
  }),
);

/**
 * Typography.SmallCaption component styles
 * @returns {Function}
 */
export const useSmallCaptionStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.xs,
      color: variables.color.primary.mediumGray,
    },
  }),
);

/**
 * Typography.SuperSmallCaption component styles
 * @returns {Function}
 */
export const useSuperSmallCaptionStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.xxs,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.xxs,
      color: variables.color.primary.mediumGray,
    },
  }),
);

/**
 * Typography.SmallTag component styles
 * @returns {Function}
 */
export const useSmallTagStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.xxs,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.xxxs,
      letterSpacing: variables.letterSpacing.lg,
      textTransform: 'uppercase',
      color: variables.color.primary.mediumGray,
    },
  }),
);

/**
 * Typography.Tag component styles
 * @returns {Function}
 */
export const useTagStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.xs,
      letterSpacing: variables.letterSpacing.sm,
      textTransform: 'uppercase',
      color: variables.color.primary.mediumGray,
    },
  }),
);

/**
 * Typography.Code component styles
 * @returns {Function}
 */
export const useCodeStyles = makeStyles(() =>
  createStyles({
    root: {
      fontFamily: variables.font.family.code,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.md,
      color: variables.color.primary.mediumGray,
    },
  }),
);
