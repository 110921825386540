import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { DeploymentCreatePayload, userClients, deployment } from '@modules';
import { textTemplate, useHistory, useWebTitleDelayed, validate } from '@utils';
import {
  Confirm,
  FileUpload,
  FileUploadProps,
  Select,
  SelectOption,
  TextField,
  TextFieldProps,
  Typography,
} from '@components';
import { content } from '@content';
import { useAppDispatch } from '@store';

import { NewDeploymentProps } from './NewDeployment.props';
import debounce from 'lodash.debounce';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, RadioGroupProps } from '@material-ui/core';
import { useStyles } from './NewDeployment.styles';

/**
 * New Deployment creation modal
 */
export const NewDeployment = memo(({ isCloneQuestionVisible, onCancel, onSubmit }: NewDeploymentProps): JSX.Element => {
  const { businessUnit: businessUnitId, program: programId } = useHistory().query;
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const [mailFiles, setMailFiles] = useState<SelectOption[] | undefined>(undefined);
  const [isMailFileDisabled, setIsMailFileDisabled] = useState(true);
  const [isUploadFileDisabled, setIsUploadFileDisabled] = useState(true);
  const [isMailFileShown, setIsMailFileShown] = useState(true);
  const [isUploadFileShown, setIsUplaodFileShown] = useState(true);

  useWebTitleDelayed({
    parts: [
      textTemplate(content.entityManager, { entity: content.deployment }),
      textTemplate(content.createValue, { value: content.deployment }),
    ],
  });

  // getting user businessUnits (mail files) information by the businessUnit id
  useEffect(() => {
    (async () => {
      if (businessUnitId) {
        const result = await dispatch(userClients.thunk.getById(businessUnitId));

        if (userClients.thunk.getById.fulfilled.match(result) && result.payload && result.payload.mailFile.length) {
          setMailFiles(
            result.payload.mailFile.map((item) => {
              return {
                id: item.id.toString(),
                label: item.name,
              };
            }),
          );
        }
      }
    })();
  }, [businessUnitId, dispatch]);

  const { getValues, setValue, reset, clearErrors, handleSubmit, setError, control } = useForm<DeploymentCreatePayload>(
    {
      defaultValues: {
        businessUnitId: businessUnitId,
        programId,
        isCloning: 'true',
      },
    },
  );

  const [nameSuggestions, setNameSuggestions] = useState<TextFieldProps['suggestions']>([]);

  // setting name suggestions by businessUnit id and entered name
  const fetchSuggestions = debounce(
    useCallback(
      async (name: string) => {
        if (businessUnitId) {
          const result = await dispatch(
            deployment.thunk.search({
              businessUnit: `${businessUnitId}`,
              name: name.toLowerCase(),
            }),
          );

          if (deployment.thunk.search.fulfilled.match(result) && result.payload) {
            setNameSuggestions(
              result.payload.map((item) => {
                return item.name;
              }),
            );
          }
        }
      },
      [setNameSuggestions, businessUnitId, dispatch],
    ),
    500,
  );

  const handleNameChange: TextFieldProps['onChange'] = useCallback(
    (name, suggested) => {
      if (name) {
        clearErrors('name');
      }

      setValue('name', name);

      if (suggested || name.length < 2) {
        setNameSuggestions([]);
      } else {
        fetchSuggestions(name);
      }
    },
    [clearErrors, setNameSuggestions, setValue, fetchSuggestions],
  );

  const handleMailFileChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const id = Number(event.target.value);

      if (id) {
        clearErrors('mailFile');
      }

      setValue('mailFile', { id });
      setValue('uploadFile', undefined);
      setIsUploadFileDisabled(true);
      setIsUplaodFileShown(false);
      setTimeout(() => {
        setIsUplaodFileShown(true);
      });
    },
    [clearErrors, setValue],
  );

  const handleIsCloningChange: RadioGroupProps['onChange'] = useCallback(
    (e) => {
      setValue('isCloning', e.target.value);
    },
    [setValue],
  );

  const handleUploadFileChange: FileUploadProps['onChange'] = useCallback(
    (file) => {
      clearErrors('uploadFile');

      const isCsvValid = validate.isCsvFile((file as File)?.type);

      if (isCsvValid) {
        setValue('uploadFile', file as File);
        setValue('mailFile', undefined);
        setIsMailFileDisabled(true);
        setIsMailFileShown(false);
        setTimeout(() => {
          setIsMailFileShown(true);
        });
      } else if (file) {
        setError('uploadFile', { message: content.invalidFileFormat });
      }
    },
    [clearErrors, setError, setValue],
  );

  const handleMailFileMouseEnter = () => {
    const { mailFile } = getValues();

    if (!mailFile) {
      setIsMailFileDisabled(false);
    }
  };

  const handleMailFileMouseLeave = () => {
    const { mailFile } = getValues();

    if (!mailFile) {
      setIsMailFileDisabled(true);
    }
  };

  const handleUploadFileMouseEnter = () => {
    const { uploadFile } = getValues();

    if (!uploadFile) {
      setIsUploadFileDisabled(false);
    }
  };

  const handleUploadFileMouseLeave = () => {
    const { uploadFile } = getValues();

    if (!uploadFile) {
      setIsUploadFileDisabled(true);
    }
  };

  useEffect(
    () => () => {
      reset({});
      clearErrors();
    },
    [reset, clearErrors],
  );

  return (
    <Confirm
      open
      padding="0"
      maxWidth="41.6rem"
      title={textTemplate(content.createValue, { value: content.deployment })}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: content.deploymentName }}
        render={({ field: { ref, ...field }, fieldState }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            label={content.deploymentName}
            placeholder={content.deploymentName}
            error={fieldState.invalid}
            hint={fieldState.error?.message}
            suggestions={nameSuggestions}
            onChange={handleNameChange}
          />
        )}
      />
      {isCloneQuestionVisible && (
        <Controller
          name="isCloning"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <FormControl>
              <FormLabel>
                <Typography.Tag className={styles.radioTitle}>{`${content.wouldYouCloneDeployment}`}</Typography.Tag>
              </FormLabel>
              <RadioGroup defaultValue={field.value} value={field.value} row onChange={handleIsCloningChange}>
                <FormControlLabel
                  value={'true'}
                  label={<Typography.Body className={styles.radioLabel}>{content.yes}</Typography.Body>}
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value={'false'}
                  label={<Typography.Body className={styles.radioLabel}>{content.no}</Typography.Body>}
                  control={<Radio color="primary" />}
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      )}
      <div className={styles.separator} />
      <Controller
        name="mailFile"
        control={control}
        render={({ field: { ref, ...field }, fieldState }) =>
          isMailFileShown ? (
            <Select
              {...field}
              inputRef={ref}
              label={content.subscriberList}
              placeholder={content.select}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              options={mailFiles}
              value={getValues().mailFile}
              disabled={isMailFileDisabled}
              onMouseEnter={handleMailFileMouseEnter}
              onMouseLeave={handleMailFileMouseLeave}
              onChange={handleMailFileChange}
            />
          ) : (
            <></>
          )
        }
      />
      <Controller
        name="uploadFile"
        control={control}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) =>
          isUploadFileShown ? (
            <FileUpload
              {...field}
              id="image_upload.png"
              extensions={['csv']}
              className={styles.fileUpload}
              hint={error?.message || `*${content.required}. ${content.csvFileFormatOnly}`}
              error={invalid}
              disabled={isUploadFileDisabled}
              multiple={false}
              onMouseEnter={handleUploadFileMouseEnter}
              onMouseLeave={handleUploadFileMouseLeave}
              onChange={handleUploadFileChange}
            />
          ) : (
            <></>
          )
        }
      />
      <div className={styles.separator} />
    </Confirm>
  );
});

NewDeployment.displayName = 'NewDeployment';
