import { Box } from '@material-ui/core';

import { useStyles } from './Loader.styles';
import { memo } from 'react';

type TProps = {
  width?: number;
  height?: number;
};
export const Loader: React.FC<TProps> = memo(({ width = 35, height = 35 }): JSX.Element | null => {
  const styles = useStyles();

  return <Box className={styles.loaderIcon} style={{ width: `${width}px`, height: `${height}px` }}></Box>;
});

Loader.displayName = 'Loader';
