import { SerializedError } from '@reduxjs/toolkit';

import { NetworkApiError } from './network.types';

export const parseApiError = (apiError: NetworkApiError): Partial<SerializedError> => ({
  ...apiError,
  code: `${apiError.code}`,
  message:
    Object.entries(apiError.errors || [])
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n') || apiError.message,
});
