import { useCallback } from 'react';
import { Box } from '@material-ui/core';

import { content, page } from '@content';
import { auth } from '@modules';
import { Form } from '@views';
import { variables } from '@styles';
import { useLoader, validate, textTemplate, useWebTitle } from '@utils';
import { useAppDispatch, history } from '@store';
import { UserAgreement, Icon } from '@components';

import { useStyles } from './Login.styles';

export const Login = (): JSX.Element => {
  const styles = useStyles();
  const meta = auth.useMeta();
  const { isAgreementAccepted, id } = auth.useData();
  const dispatch = useAppDispatch();

  useWebTitle(content.logIn);

  useLoader(meta);

  const handleSubmit = useCallback(
    async (query) => {
      const result = await dispatch(auth.thunk.login(query));

      if (!auth.thunk.login.fulfilled.match(result)) {
        return;
      }

      if (result.payload?.user.isAgreementAccepted) {
        const lastRoute = localStorage.getItem('lastRoute');

        history.push(lastRoute ?? page.dashboard);
      }
    },
    [dispatch],
  );

  const handleAcceptTerms = useCallback(() => dispatch(auth.thunk.acceptTerms(id)), [dispatch, id]);

  return (
    <Box className={styles.login}>
      <Form
        className={styles.form}
        status={meta.status}
        requiredWarn={content.fillAllRequiredFieldsPlease}
        invalidWarn={meta.error?.message || content.invalidCredentials}
        title={''}
        description={content.accessYourAccount}
        fields={[
          {
            type: 'txt',
            id: 'email',
            label: content.email,
            required: true,
            validate: validate.email,
            placeholder: textTemplate(content.enterYour, { value: content.email.toLowerCase() }),
          },
          {
            type: 'pwd',
            id: 'password',
            label: content.password,
            required: true,
            validate: validate.password,
            placeholder: textTemplate(content.enterYour, { value: content.password.toLowerCase() }),
          },
        ]}
        buttons={[
          {
            isSubmit: true,
            label: content.logIn,
            color: 'primary',
            iconRight: <Icon.ArrowRightOutline stroke={variables.color.primary.white} className={styles.arrowIcon} />,
          },
        ]}
        links={[{ label: content.forgotYourPassword, to: page.passwordResetRequest }]}
        onSubmit={handleSubmit}
      />
      {meta.status === 'success' && !isAgreementAccepted && <UserAgreement onAcceptTerms={handleAcceptTerms} />}
    </Box>
  );
};
