import { Box, Button, Divider } from '@material-ui/core';

import { content } from '@content';
import { Typography } from '@components';

import { useStyles } from './Settings.styles';

/**
 Settings component
 @returns {JSX.Element}
 */

export const Settings = (): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={styles.settings}>
      <Box className={styles.content}>
        <Box className={styles.settingsElem}>
          <Typography.Caption className={styles.elemLabel}>{content.deploymentDate}</Typography.Caption>
          <Typography.Body className={styles.elemValue}>{content.notSet}</Typography.Body>
          <Button className={styles.editBtn} type="button" variant="outlined" color="primary">
            {content.edit}
          </Button>
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.settingsElem}>
          <Typography.Caption className={styles.elemLabel}>{content.recurringSchedule}</Typography.Caption>
          <Typography.Body className={styles.elemValue}>{content.nonRecurring}</Typography.Body>
          <Button className={styles.editBtn} type="button" variant="outlined" color="primary">
            {content.edit}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
