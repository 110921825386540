import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    warning: {
      maxWidth: '34rem',
    },
    message: {
      margin: '2.2rem 0 2.5rem',
    },
    footer: {
      display: 'flex',
    },
    deleteBtn: {
      marginRight: '1rem',
    },
  }),
);
