import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const pwdReset = {
  display: 'flex',
  'flex-direction': 'column',
  alignItems: 'center',
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    pwdReset,
    form: {
      width: '42.9rem',
      background: variables.color.primary.white,
      padding: theme.spacing(6),
    },
    buttons: {
      flex: 1,
      justifyContent: 'flex-end',
    },
  }),
);
