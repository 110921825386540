import { variables } from '@styles';
import { parse } from '@utils';

export const ITEMS_PER_ROW = 3;

export const ROWS_PER_VIEW = 3;

export const ITEMS_PER_VIEW = ITEMS_PER_ROW * ROWS_PER_VIEW;

export const ITEMS_PER_VIEW_INIT = ITEMS_PER_VIEW - 1;

export const ITEMS_PER_VIEW_MAX = ITEMS_PER_VIEW + ITEMS_PER_ROW;

export const GRID_GAP_X = parse.remToPx(variables.gap.sm);

export const GRID_GAP_Y = parse.remToPx(variables.gap.lg);

export const ITEM_HEIGHT = 90; // px

export const ITEM_WITH_GAP_HEIGHT = ITEM_HEIGHT + GRID_GAP_Y;

export const ITEM_BOX_SHADOW_OFFSET = 1; // px

export const ITEM_BOX_SHADOW_BLUR = 7; // px

export const ITEM_BOX_SHADOW_OVERLAP_PRE = ITEM_BOX_SHADOW_BLUR - ITEM_BOX_SHADOW_OFFSET;

export const ITEM_BOX_SHADOW_OVERLAP_POST = ITEM_BOX_SHADOW_OFFSET + ITEM_BOX_SHADOW_BLUR;

export const DRAFT_HOLDER_MARKER_KEY_CC = 'sampleTemplateSplitter';

export const DRAFT_HOLDER_WIDTH_RATIO = 0.5; // child to parent widths ratio

export const DRAFT_HOLDER_CELL_SPAN = 99;

export const ITEM_DECORATION_DELAY = 200; // ms
