import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * RemoveExperienceModal component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    message: {
      color: variables.color.primary.darkestGray,
    },
  }),
);
