import { ForwardedRef, forwardRef } from 'react';
import { Typography } from '@material-ui/core';

import { useBodyStyles } from './Typography.styles';
import { TypographyProps } from './Typography.props';

export const Body = forwardRef(
  ({ className = '', ...props }: TypographyProps, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const styles = useBodyStyles();

    return <Typography classes={styles} className={className} {...props} {...{ ref }} />;
  },
);

Body.displayName = 'Typography.Body';
