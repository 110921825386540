import { ChangeEvent, MouseEvent, memo, useCallback } from 'react';
import { Box, InputBase } from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import { Droppable } from 'react-beautiful-dnd';
import debounce from 'lodash.debounce';

import { Panel, Icon, Typography } from '@components';
import { content } from '@content';
import { Signal } from '@views';
import { useAppDispatch } from '@store';
import { signalLibrary } from '@modules';
import { func, searchQuery, useLoader } from '@utils';

import { SignalLibraryRow } from './SignalLibraryRow';

import { useStyles } from './SignalLibrary.styles';
import { SignalLibraryProps } from './SignalLibrary.props';

export const SignalLibrary = memo(
  ({
    className = '',
    titleClassName = '',
    searchClassName = '',
    height = 460,
    onEdit = func.nop,
  }: SignalLibraryProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const styles = useStyles();

    const [businessUnit] = searchQuery.useMutualParams('businessUnit');

    const meta = signalLibrary.useListMeta();

    const signals = signalLibrary.useListData();

    const runSearch = debounce(
      useCallback(
        (keyword: string) =>
          dispatch(
            signalLibrary.thunk.search({
              businessUnit,
              keyword,
            }),
          ),
        [dispatch, businessUnit],
      ),
      500,
    );

    const handleSearchChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        runSearch(event.target.value);
      },
      [runSearch],
    );

    const handleEditClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        const { id } = event.currentTarget.dataset;

        if (id) {
          onEdit(+id);
        }
      },
      [onEdit],
    );

    useLoader(meta);

    return (
      <Panel className={`SignalLibrary ${styles.panel} ${className}`}>
        <Box className={styles.row}>
          <Typography.Title className={`${styles.title} ${titleClassName}`}>
            {content.attributeLibrary}
          </Typography.Title>
          <Typography.Caption>{signals.items.length}</Typography.Caption>
        </Box>
        <Typography.SmallCaption>{content.dragIntoRule}</Typography.SmallCaption>
        <InputBase
          className={`${styles.searchField} ${searchClassName}`}
          placeholder={content.search}
          onChange={handleSearchChange}
          endAdornment={<Icon.SearchOutline />}
        />
        <Box>
          {signals.items.length === 0 && <Typography.Body align="center">{content.noItemsFound}</Typography.Body>}
          <Droppable
            droppableId="signalLibrary"
            type="SIGNAL"
            mode="virtual"
            isDropDisabled
            renderClone={(provided, snapshot, rubric) => (
              <Signal provided={provided} item={signals.items[rubric.source.index]} isDragging={snapshot.isDragging} />
            )}
          >
            {(provided) => (
              <FixedSizeList
                height={height}
                width="100%"
                itemCount={signals.items.length}
                itemSize={43}
                outerRef={provided.innerRef}
                itemData={signals.items.map((item) => ({ ...item, handleEditClick }))}
              >
                {SignalLibraryRow}
              </FixedSizeList>
            )}
          </Droppable>
        </Box>
      </Panel>
    );
  },
);

SignalLibrary.displayName = 'SignalLibrary';
