import { FC, ReactElement, MouseEvent } from 'react';
import { area } from 'd3-shape';
import { content } from '@content';
import { BarChartTooltip } from './BarChartTooltip';
import { BarChartAreaLayerProps } from './BarChart.props';

export const BarChartAreaLayer: FC<BarChartAreaLayerProps> = ({
  bars,
  yScale,
  showTooltip,
  hideTooltip,
  average,
  className,
  unit,
}): ReactElement => {
  const areaGenerator = area().x0(0).x1(410).y(yScale(average));

  const d: string = areaGenerator(bars) || '';

  const onHoverIn = (event: MouseEvent<SVGElement>) => {
    const nodes = document.getElementsByClassName(className);
    for (let item = 0; item < nodes.length; item += 1) {
      const node = nodes[item] as HTMLElement;
      if (node) {
        const values = node.getAttribute('values');
        if (Number(values) < average) {
          node.style.opacity = '0.5';
        }
      }
    }
    showTooltip(<BarChartTooltip indexValue={content.average} value={average} unit={unit} />, event);
  };

  const onHoverOut = () => {
    const nodes = document.getElementsByClassName(className);
    for (let item = 0; item < nodes.length; item += 1) {
      const node = nodes[item] as HTMLElement;
      if (node) {
        node.style.opacity = '1';
      }
    }
    hideTooltip();
  };

  return (
    <path
      d={d}
      onMouseEnter={(event) => onHoverIn(event)}
      onMouseOut={() => onHoverOut()}
      onMouseLeave={() => onHoverOut()}
      onMouseMove={(event) => onHoverIn(event)}
      strokeWidth="10px"
      stroke="transparent"
    />
  );
};
