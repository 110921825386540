import { format } from 'date-fns';
import { ToWords } from 'to-words';

export const DATE_PATTERN = 'MM/dd/yyyy';

const MS_PER_SEC = 1000;

const SEC_PER_MIN = 60;

const MS_PER_MIN = MS_PER_SEC * SEC_PER_MIN;

const LOCALE = 'en-US';

const intlNumber = new Intl.NumberFormat(LOCALE);

const intlNumberTwoFracts = new Intl.NumberFormat(LOCALE, { minimumFractionDigits: 2 });

const intlNumberFracts = new Intl.NumberFormat(LOCALE, { maximumFractionDigits: 1, minimumFractionDigits: 1 });

const toWords = new ToWords({
  localeCode: 'en-US',
});

export const numberTwoFracts = (value = 0, placeholder: string | undefined = undefined): string =>
  !value && typeof placeholder !== 'undefined' ? placeholder : intlNumberTwoFracts.format(value);

export const numberFracts = (value = 0, placeholder: string | undefined = undefined): string =>
  !value && typeof placeholder !== 'undefined' ? placeholder : intlNumberFracts.format(value);

export const number = (value = 0, placeholder: string | undefined = undefined): string =>
  !value && typeof placeholder !== 'undefined' ? placeholder : intlNumber.format(value);

export const percent = (value: number | string = 0, placeholder: string | undefined = undefined): string =>
  !value && typeof placeholder !== 'undefined' ? placeholder : `${intlNumberFracts.format(Number(value))}%`;

export const date = (value: string | number, pattern = 'MM-dd-yyyy'): string => {
  let targetInit = typeof value === 'number' ? value * MS_PER_SEC : value;
  let targetDate = new Date(targetInit);

  if (typeof value === 'string') {
    targetInit = targetDate.valueOf() + targetDate.getTimezoneOffset() * MS_PER_MIN;
    targetDate = new Date(targetInit);
  }

  return format(targetDate, pattern);
};

export const convertEstTZ = (value: Date | string) => {
  const curDate = new Date(
    (typeof value === 'string' ? new Date(value) : value).toLocaleString('en-US', { timeZone: 'America/New_York' }),
  );
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'long', timeZone: 'America/New_York' });
  const curMonth = formatter.format(curDate);
  const hours = curDate.getHours() < 10 ? `0${curDate.getHours()}` : curDate.getHours();
  const minutes = curDate.getMinutes() < 10 ? `0${curDate.getMinutes()}` : curDate.getMinutes();

  return `${curMonth} ${curDate.getDate()}, ${curDate.getFullYear()} : ${hours}:${minutes} EST`;
}

export const capitalize = (value: string, delimeter = ' ', resultDelimeter?: string): string =>
  value
    .split(delimeter)
    .map((word) => (word.length ? `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}` : word))
    .join(resultDelimeter || delimeter);

export const numberToWord = (value: number): string => toWords.convert(value);

export const roundByRank = (value: number, rank: number): number => {
  return Math.round(value * rank) / rank;
};