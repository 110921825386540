import { useEffect, useContext, useMemo } from 'react';
import { Box } from '@material-ui/core';

import { useAppDispatch } from '@store';
import { content } from '@content';
import { dashboard, businessUnitPrograms, analytics, BenchmarkingReportDataSchema, enterprise } from '@modules';
import { TopBarContext, TOP_BAR_CTX_VALUE } from '@layouts';
import { textTemplate, useHistory, useLoader, useWebTitle } from '@utils';

import { AppleMailPrivacyCard } from './AppleMailPrivacyCard';
import { BenchmarkingReport } from './BenchmarkingReport';
import { PerformingContentReport } from './PerformingContentReport';

import { useStyles } from './Dashboard.styles';

/**
 Dashboard route component.
 @returns {JSX.Element}
 */

export const Dashboard = (): JSX.Element => {
  const styles = useStyles();

  const dispatch = useAppDispatch();

  const enterpriseList = enterprise.useListData();

  const { enterprise: enterpriseId, businessUnit } = useHistory().query;

  const enterpriseEntity = useMemo(
    () => enterpriseList.find((item) => item.id === enterpriseId),
    [enterpriseList, enterpriseId],
  );

  const businessUnitName = useMemo(
    () => enterpriseEntity?.businessUnits.find((item) => item.id === businessUnit)?.name || '',
    [enterpriseEntity, businessUnit],
  );

  const [, setTopBarCtx] = useContext(TopBarContext);

  const enterpriseStatistics = analytics.useBenchmarkingReportEnterpriseData();
  const statistics = analytics.useBenchmarkingReportBUData();

  const topPerformingContentData = analytics.useTopPerformingContentData();
  const lowPerformingContentData = analytics.useLowPerformingContentData();

  useWebTitle(content.overview);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      sectionName: content.businessUnitOverview,
      variant: 'picker',
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
    };
  }, [setTopBarCtx]);

  useLoader(
    enterprise.useListMeta(),
    analytics.useLowPerformingContentMeta(),
    analytics.useTopPerformingContentMeta(),
    analytics.useBenchmarkingReportBUMeta(),
  );

  useEffect(() => {
    const promises: (CancelablePromise<unknown> | null)[] = [];

    if (businessUnit && enterpriseId) {
      promises.push(
        dispatch(dashboard.thunk.getStatistics(businessUnit)),
        dispatch(businessUnitPrograms.thunk.getAll(businessUnit)),
        dispatch(analytics.thunk.getBenchmarkingReportBU({ businessUnit })),
        dispatch(analytics.thunk.getBenchmarkingReportEnterprise({ enterprise: enterpriseId })),
        dispatch(analytics.thunk.getTopPerformingContentReport({ businessUnit })),
        dispatch(analytics.thunk.getLowPerformingContentReport({ businessUnit })),
      );
    }

    return () => promises.forEach((promise) => promise?.abort());
  }, [dispatch, businessUnit, enterpriseId]);

  return (
    <Box className={styles.dashboard}>
      {businessUnitName && (
        <Box className={styles.benchmarkingRow}>
          <BenchmarkingReport
            title={enterpriseEntity?.name || textTemplate(content.entityName, { entity: content.enterprise })}
            icon="GlobeOutline"
            data={enterpriseStatistics}
          />
          <BenchmarkingReport title={businessUnitName} icon="GolfOutline" data={statistics} />
        </Box>
      )}
      <Box className={styles.benchmarkingRow}>
        <AppleMailPrivacyCard />
      </Box>
      <Box className={styles.benchmarkingRow}>
        <PerformingContentReport
          icon="BarChartUp"
          title={content.highestPerformingContent}
          data={topPerformingContentData}
          businessUnitName={businessUnitName}
          impressionText={
            topPerformingContentData.length === 0
              ? textTemplate(content.limitPerformanceReport, { limit: content.highest })
              : content.theBest
          }
        />
        <PerformingContentReport
          icon="BarChartDown"
          title={content.lowestPerformingContent}
          data={lowPerformingContentData}
          businessUnitName={businessUnitName}
          impressionText={
            topPerformingContentData.length === 0
              ? textTemplate(content.limitPerformanceReport, { limit: content.lowest })
              : content.theLowest
          }
        />
      </Box>
      <Box className={styles.benchmarkingRow}>
        <BenchmarkingReport
          title={content.allVerticals}
          icon="GlobeOutline"
          data={{} as BenchmarkingReportDataSchema}
          hasChevronIcon
        />
        <BenchmarkingReport
          title={content.financialServices}
          icon="GolfOutline"
          data={{} as BenchmarkingReportDataSchema}
        />
      </Box>
    </Box>
  );
};
