import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { content } from '@content';
import { Confirm, FileUpload, FileUploadProps, TextField, TextArea } from '@components';
import { textTemplate, useLoader, validate } from '@utils';
import { modules } from '@modules';

import { UploadModuleModalProps } from './UploadModuleModal.props';
import { FormValues } from './UploadModuleModal.types';
import { UNIVERSAL_FILE_NAME } from './UploadModuleModal.const';

/**
 * UploadModuleModal component
 * @returns {JSX.Element}
 */

export const UploadModuleModal = ({ onSubmit, onClose }: UploadModuleModalProps): JSX.Element => {
  useLoader(modules.useNewModuleMeta());

  const { handleSubmit, control, setValue, clearErrors, setError, watch } = useForm<FormValues>({
    defaultValues: { name: '', description: '', htmlFile: null },
  });

  const uploadFile = watch('htmlFile');
  const fileName = watch('name');

  const isNameEqualUniversal = useMemo(() => fileName === UNIVERSAL_FILE_NAME, [fileName]);

  const handleFileChange: FileUploadProps['onChange'] = useCallback(
    (htmlFile) => {
      clearErrors('htmlFile');

      if (!Array.isArray(htmlFile)) {
        if (validate.isHtmlFile(htmlFile?.type ?? '')) {
          setValue('htmlFile', htmlFile);
        } else {
          setError('htmlFile', { message: content.invalidFileFormat });
        }
      } else {
        setValue('htmlFile', null);
      }
    },
    [clearErrors, setError, setValue],
  );

  const handleNameChange = useCallback(
    (name: string) => {
      clearErrors('name');

      if (name === 'Universal') {
        setError('name', { message: content.universalIsIncorrectName });
        setValue('name', name);
      } else {
        setValue('name', name);
      }
    },
    [clearErrors, setError, setValue],
  );

  return (
    <Confirm
      open
      submitDisabled={!uploadFile || isNameEqualUniversal}
      title={content.uploadContentModule}
      submitLabel={content.upload}
      skipSubmitArrow
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: content.contentModuleName }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            label={content.contentModuleName}
            placeholder={content.contentModuleName}
            hint={error?.message}
            error={invalid}
            onChange={handleNameChange}
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field: { ref, ...field } }) => (
          <TextArea
            {...field}
            resize={false}
            textAreaRef={ref}
            templated
            label={content.description}
            placeholder={content.description}
          />
        )}
      />
      <Controller
        name="htmlFile"
        control={control}
        rules={{ required: textTemplate(content.pleaseSelect, { field: content.HtmlFile }) }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <FileUpload
            {...field}
            id="image_upload.html"
            extensions={['htm', 'html']}
            label={textTemplate(content.requiredField, { field: content.HtmlFile })}
            hint={error?.message || `*${content.required}`}
            error={invalid}
            value=""
            onChange={handleFileChange}
          />
        )}
      />
    </Confirm>
  );
};
