import { DefaultDraftBlockRenderMap } from 'draft-js';
import { Map } from 'immutable';

import { Icon } from '@components';

export const BLOCK_RENDER_MAP = DefaultDraftBlockRenderMap.merge(
  Map({
    unstyled: { element: 'span' },
    paragraph: { element: 'span' },
  }),
);

export const TOOLS = [
  { style: 'BOLD', Icon: Icon.Bold },
  { style: 'ITALIC', Icon: Icon.Italic },
  { style: 'CODE', Icon: Icon.SuperScript },
  { style: 'UNDERLINE', Icon: Icon.Link },
];
