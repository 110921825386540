import { useEffect, useState, useRef, Dispatch, SetStateAction, useCallback } from 'react';

import { history } from '@store';
import { func } from '@utils';

export type UseLeaveWarnHook = {
  warn: boolean;
  setWarn: Dispatch<SetStateAction<boolean>>;
  navNext: () => void;
  unblock: () => void;
};

export const useLeaveWarn = (forceBlock: boolean): UseLeaveWarnHook => {
  const nextHistoryState = useRef('');
  const [warn, setWarn] = useState(false);

  const navNext = useCallback(() => {
    const { current: nextPath } = nextHistoryState;

    if (nextPath) {
      nextHistoryState.current = '';
      history.push(nextPath);
    }
  }, []);

  const unblock = useCallback(() => history.block(func.nop), []);

  useEffect(() => {
    const globalConfirm = window.confirm;
    window.confirm = func.getNop(false);

    if (forceBlock) {
      history.block((prompt) => {
        nextHistoryState.current = `${prompt.pathname}${prompt.search}`;
        setWarn(true);
        return 'true';
      });
    } else {
      unblock();
    }

    return () => {
      unblock();
      window.confirm = globalConfirm;
    };
  }, [setWarn, unblock, forceBlock]);

  return {
    warn,
    setWarn,
    navNext,
    unblock,
  };
};
