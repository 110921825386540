import { base } from '@modules';

import { RecommendedModulesSchema } from './RecommendedModules.schema';

export const initialState: RecommendedModulesSchema = {
  ...base.state,
  data: {
    items: [],
    counts: [0, 0],
  },
};
