import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      display: 'block',
      margin: '0 0 0.6rem',
    },
  }),
);
