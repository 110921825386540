import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';

import { content } from '@content';
import { Icon, UploadImageModal } from '@components';
import { TopBarContext, TOP_BAR_CTX_VALUE } from '@layouts';
import { dataVisualization, Image, imageLibrary } from '@modules';
import {
  UploadVisualizationModal,
  DeleteImageModal,
  Disclosure,
  UploadVisualizationModalProps,
  ImageLibrary,
} from '@routes/ContentCenter';
import { useAppDispatch } from '@store';
import { searchQuery, textTemplate, useLoader, useWebTitle } from '@utils';
import { DuplicateModuleModal, AddModuleGroupModal } from '@views';

import { useStyles } from './MyAccount.styles';

/**
 MyAccount route component.
 @returns {JSX.Element}
 */

export function MyAccount(): JSX.Element {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const [newUploadFile, setNewUploadFile] = useState(false);
  const [image, setImage] = useState<Image | null>(null);
  const [deleteImage, setDeleteImage] = useState(false);
  const [newUploadVisual, setNewUploadVisual] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [addModuleGroup, setAddModuleGroup] = useState(false);
  const [duplicateModule, setDuplicateModule] = useState(false);
  const [imageLibraryOpen, setImageLibraryOpen] = useState(false);

  const [, setTopBarCtx] = useContext(TopBarContext);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      variant: 'picker',
      sectionName: content.myAccount,
    }));

    return () => setTopBarCtx(TOP_BAR_CTX_VALUE);
  }, [setTopBarCtx]);

  const [businessUnitId] = searchQuery.useMutualParams('businessUnit');
  const libraryImages = imageLibrary.useAllData();
  const meta = imageLibrary.useAllMeta();

  useWebTitle(content.myAccount);

  useLoader(meta);

  const handleUploadFileModalOpen = useCallback(() => {
    setNewUploadFile(true);
  }, [setNewUploadFile]);

  const handleUploadFileModalClose = useCallback(() => {
    setNewUploadFile(false);
  }, [setNewUploadFile]);

  const handleUploadFileFormSubmit = useCallback(() => {
    //TODO: make upload modal functionality
    handleUploadFileModalClose();
  }, [handleUploadFileModalClose]);

  const handleDeleteImageModalOpen = useCallback(() => {
    setDeleteImage(true);
  }, [setDeleteImage]);

  const handleDeleteImageModalClose = useCallback(() => {
    setDeleteImage(false);
  }, [setDeleteImage]);

  const handleConfirmDeleteImage = useCallback(() => {
    handleDeleteImageModalClose();
  }, [handleDeleteImageModalClose]);

  const handleUploadVisualModalOpen = useCallback(() => {
    setNewUploadVisual(true);
  }, [setNewUploadVisual]);

  const handleUploadVisualModalClose = useCallback(() => {
    setNewUploadVisual(false);
  }, [setNewUploadVisual]);

  const handleFormVisualSubmit: UploadVisualizationModalProps['onSubmit'] = useCallback(
    async (values) => {
      const valuesWithClient = { ...values, 'businessUnit[id]': businessUnitId };

      const formData = new FormData();

      Object.keys(valuesWithClient).forEach((key) => {
        formData.append(key, valuesWithClient[key as keyof typeof valuesWithClient] as string | Blob);
      });

      const result = await dispatch(dataVisualization.thunk.create(formData));

      if (dataVisualization.thunk.create.fulfilled.match(result)) {
        handleUploadVisualModalClose();
      }
    },
    [businessUnitId, dispatch, handleUploadVisualModalClose],
  );

  const handleUploadImageModalOpen = useCallback(() => {
    setUploadImage(true);
  }, [setUploadImage]);

  const handleUploadImageModalClose = useCallback(() => {
    setUploadImage(false);
  }, [setUploadImage]);

  const handleUploadImageFormSubmit = useCallback(() => {
    //TODO: make upload image modal functionality
    handleUploadImageModalClose();
  }, [handleUploadImageModalClose]);

  const handleAddModuleGroupModalOpen = useCallback(() => {
    setAddModuleGroup(true);
  }, [setAddModuleGroup]);

  const handleAddModuleGroupModalClose = useCallback(() => {
    setAddModuleGroup(false);
  }, [setAddModuleGroup]);

  const handleConfirmAddModuleGroup = useCallback(() => {
    handleAddModuleGroupModalClose();
  }, [handleAddModuleGroupModalClose]);

  const handleDuplicateModuleModalOpen = useCallback(() => {
    setDuplicateModule(true);
  }, [setDuplicateModule]);

  const handleDuplicateModuleModalClose = useCallback(() => {
    setDuplicateModule(false);
  }, [setDuplicateModule]);

  const handleImageLibraryOpen = useCallback(() => {
    setImageLibraryOpen(true);
  }, [setImageLibraryOpen]);

  const handleImageLibraryClose = useCallback(() => {
    setImageLibraryOpen(false);
  }, [setImageLibraryOpen]);

  useEffect(() => {
    const promise = dispatch(imageLibrary.thunk.search({ keyword: '', businessUnit: businessUnitId, page: 1 }));

    return () => promise.abort();
  }, [businessUnitId, dispatch, setImage]);

  return (
    <Box className={styles.myAccount}>
      <Box>
        <Disclosure error />
        <Button
          className={styles.upload}
          variant="outlined"
          color="primary"
          startIcon={<Icon.CloudUploadOutline />}
          onClick={handleUploadFileModalOpen}
        >
          {content.uploadImage}
        </Button>
        {newUploadFile && (
          <UploadImageModal onSubmit={handleUploadFileFormSubmit} onClose={handleUploadFileModalClose} />
        )}
        <Button className={styles.delete} variant="outlined" color="primary" onClick={handleDeleteImageModalOpen}>
          {content.deleteImage}
        </Button>
        {deleteImage && (
          <DeleteImageModal image={image} onClose={handleDeleteImageModalClose} onConfirm={handleConfirmDeleteImage} />
        )}
        <Button
          className={styles.upload}
          variant="outlined"
          color="primary"
          startIcon={<Icon.CloudUploadOutline />}
          onClick={handleUploadVisualModalOpen}
        >
          {content.uploadVisualization}
        </Button>
        {newUploadVisual && (
          <UploadVisualizationModal onSubmit={handleFormVisualSubmit} onCancel={handleUploadVisualModalClose} />
        )}
      </Box>
      <Box className={styles.row}>
        <Button
          className={styles.upload}
          variant="outlined"
          color="primary"
          startIcon={<Icon.CloudUploadOutline />}
          onClick={handleUploadImageModalOpen}
        >
          {content.uploadImage}
        </Button>
        {uploadImage && (
          <UploadImageModal onSubmit={handleUploadImageFormSubmit} onClose={handleUploadImageModalClose} />
        )}
        <Button
          className={styles.upload}
          variant="outlined"
          color="primary"
          startIcon={<Icon.AddCircleOutline />}
          onClick={handleAddModuleGroupModalOpen}
        >
          {textTemplate(content.addValue, { value: content.treatment })}
        </Button>
        {addModuleGroup && (
          <AddModuleGroupModal onSubmit={handleConfirmAddModuleGroup} onClose={handleAddModuleGroupModalClose} />
        )}
        <Button className={styles.upload} variant="outlined" color="primary" onClick={handleDuplicateModuleModalOpen}>
          {content.duplicateContentModule}
        </Button>
        {duplicateModule && (
          <DuplicateModuleModal hasWarning detailModuleId={0} onClose={handleDuplicateModuleModalClose} />
        )}
        <Button className={styles.upload} variant="outlined" color="primary" onClick={handleImageLibraryOpen}>
          {content.imageLibrary}
        </Button>
        {imageLibraryOpen && <ImageLibrary libraryImages={libraryImages.items} onCancel={handleImageLibraryClose} />}
      </Box>
    </Box>
  );
}
