import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ContentSlidesheet component styles
 * @returns {Function}
 */

const smallViewWidth = '1250px';

export const useStyles = makeStyles((theme) =>
  createStyles({
    contentSlidesheet: {
      background: variables.color.primary.white,
      width: `calc(25vw + ${variables.gap.xs})`,
      position: 'absolute',
      left: 0,
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
      zIndex: theme.zIndex.speedDial,
    },

    header: {
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      padding: theme.spacing(4, 7, 4, 4),
      position: 'relative',

      [`@media (max-width: ${smallViewWidth})`]: {
        padding: theme.spacing(2, 5, 2, 2),
      },
    },

    title: {
      fontSize: variables.font.size.xxxl,
      fontWeight: variables.font.weight.extraBold,
      lineHeight: '2.9rem',
      color: variables.color.primary.lightBlack,
      marginBottom: theme.spacing(1),
    },

    helperText: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.darkGray,
    },

    closeBtn: {
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(4),

      [`@media (max-width: ${smallViewWidth})`]: {
        right: theme.spacing(2),
      },
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3, 2, 2),
      maxHeight: 'calc(100% - 12rem)',

      [`@media (max-width: ${smallViewWidth})`]: {
        padding: theme.spacing(2, 0, 1),
      },
    },

    searchBar: {
      display: 'flex',
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(3),
    },

    searchField: {
      marginRight: theme.spacing(1),
    },

    modulesList: {
      overflowY: 'auto',

      '& > div:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },

    draggableCard: {
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
    },
  }),
);
