import { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { DragDropContext, DragDropContextProps } from 'react-beautiful-dnd';

import { Accordion, Icon, Typography } from '@components';
import { content } from '@content';
import { textTemplate } from '@utils';
import { SignalLibrary, SignalLibraryRowItem } from '@views';

import { FormValues } from '../ExperienceEditor.types';

import { MindsetDetails } from './MindsetDetails';

import { MindsetLogicProps } from './MindsetLogic.props';
import { useStyles } from './MindsetLogic.styles';

/**
 * MindsetLogic - view/edit rules for single mindset
 * @returns {JSX.Element}
 */
export const MindsetLogic = ({
  open = false,
  entityId,
  onToggle,
  onDrop,
  ...detailsProps
}: MindsetLogicProps): JSX.Element => {
  const styles = useStyles();

  const { watch } = useFormContext<FormValues>();

  const list = watch('mindsets');

  const entity = entityId && list.find((item) => item.id === entityId);

  const entityIndex = useMemo(() => (entity ? list.indexOf(entity) : -1), [list, entity]);

  const handleDragEnd: DragDropContextProps['onDragEnd'] = useCallback(
    (result) => {
      const { reason, destination: dst, draggableId: signalJson } = result;
      const signalNameKey = dst?.droppableId;

      if (reason === 'DROP' && signalNameKey?.includes('signal')) {
        let signal;

        try {
          signal = JSON.parse(signalJson) as SignalLibraryRowItem;
        } finally {
          if (signal) {
            onDrop(signalNameKey, signal);
          }
        }
      }
    },
    [onDrop],
  );

  return (
    <Accordion expanded={open} onChange={onToggle} icon={<Icon.Mindset />} title={content.treatmentLogic}>
      {entity ? (
        <Box className={styles.content}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <MindsetDetails className={styles.details} {...{ list, entity, entityIndex }} {...detailsProps} />
            <SignalLibrary className={styles.signals} />
          </DragDropContext>
        </Box>
      ) : (
        <Box className={styles.placeholder}>
          <Icon.PartlySunny />
          <Typography.Display>
            {textTemplate(content.noValueSelected, { value: content.treatment.toLowerCase() })}
          </Typography.Display>
          <Typography.Caption>{content.noTreatmentSelectedText}</Typography.Caption>
        </Box>
      )}
    </Accordion>
  );
};
