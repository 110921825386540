import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';
import { image } from '@content';

const tabsWidth = '30.4rem';

const sectionHeightLimitMixin = {
  minHeight: '100%',
  height: '100%',
  maxHeight: '100%',
};

/**
 * Admin component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    settings: {
      display: 'flex',
      height: 'calc(100vh - 11.9rem)',
    },

    tabs: {
      ...sectionHeightLimitMixin,
      width: tabsWidth,
      padding: theme.spacing(2),
      backgroundColor: variables.color.primary.backgroundLightestGray,
      borderTopLeftRadius: variables.borderRadius.sm,
      borderBottomLeftRadius: variables.borderRadius.sm,
      borderRight: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,

      ['@media (max-width: 1300px)']: {
        width: '22.5rem',
      },
    },

    tab: {
      display: 'inline-block',
      borderRadius: variables.borderRadius.sm,
      maxWidth: 'unset',
      minHeight: 'unset',
      padding: theme.spacing(1, 1.3),
      textTransform: 'capitalize',
      opacity: 1,
      transition: 'all 0.4s',

      '&:hover': {
        backgroundColor: variables.color.primary.lightestPurple,
      },

      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        position: 'relative',
      },

      '& svg': {
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
      },

      '&.Mui-selected': {
        backgroundColor: variables.color.primary.lighterPurple,
        position: 'relative',

        '&:after': {
          content: '""',
          backgroundImage: `url(${image.chevronRight})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: '1.2rem',
          width: '0.6rem',
          position: 'absolute',
          right: theme.spacing(1.3),
          top: '50%',
          transform: 'translateY(-50%)',
        },

        '& $label': {
          color: variables.color.primary.mainPurple,
        },

        '& $tabIcon': {
          '& path': {
            fill: variables.color.primary.mainPurple,
          },
        },

        '& $tabIconStroke': {
          '& path': {
            stroke: variables.color.primary.mainPurple,
          },
        },
      },

      '&.MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
        marginBottom: 0,
      },
    },

    label: {
      padding: theme.spacing(0, 2, 0, 4),
      textAlign: 'left',
    },

    tabIcon: {
      '& path': {
        fill: variables.color.primary.mediumGray,
      },
    },

    tabIconStroke: {
      '& path': {
        stroke: variables.color.primary.mediumGray,
      },
    },

    content: {
      ...sectionHeightLimitMixin,
      width: `calc(100% - ${tabsWidth})`,

      '& > *': {
        ...sectionHeightLimitMixin,
      },
    },
  }),
);
