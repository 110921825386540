import { useState, ChangeEvent, useEffect, useCallback, forwardRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Button, InputBase } from '@material-ui/core';
import debounce from 'lodash.debounce';

import { Typography, Menu, Icon, ListItem, OptionItem } from '@components';
import { content } from '@content';
import { experience } from '@modules';
import { format, searchQuery, textTemplate, useLoader } from '@utils';
import { useAppDispatch } from '@store';

import { useStyles } from './LibraryNavigation.styles';
import {
  LibraryNavigationProps,
  LibraryNavigationQuery,
  SortByType,
  ExperienceItem,
  LibraryView,
} from './LibraryNavigation.types';
import {
  initialOptions,
  OptionsTypes,
  SortValues,
  SortExperienceValues,
  SortLabels,
  SEARCH_DEBOUNCE_DELAY,
} from './LibraryNavigation.const';

/**
 * LibraryNavigation component.
 * @returns {JSX.Element}
 */
export const LibraryNavigation = forwardRef(
  (
    {
      onExperienceSelect,
      createExperience,
      handleDragExperience,
      type = LibraryView.experiencesView,
      usedExperiences,
    }: LibraryNavigationProps,
    ref,
  ): JSX.Element => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const [businessUnitId] = searchQuery.useMutualParams('businessUnit');
    const [query, setQuery] = useState<LibraryNavigationQuery>({
      page: 1,
      lastUsed: 0,
      sortBy: SortValues.CREATED,
    });
    const { items, currentPage, pageCount, total } = experience.useListData();
    const [options, setOptions] = useState<OptionItem[]>(initialOptions);
    const [secondaryLabel, setSecondaryLabel] = useState<string>(content.createdOn);

    useLoader(experience.useListMeta());

    const loadMoreExperiences = useCallback(() => {
      setQuery((prevQuery) => ({
        ...prevQuery,
        page: prevQuery.page + 1,
      }));
    }, []);

    const onChangeSortBy = (item: OptionItem) => {
      const newOptions = options.map((elem) => {
        if (elem.id === item.id) {
          return { ...elem, active: true };
        } else {
          if (elem.subOptions?.length) {
            const subOptions = elem.subOptions?.map((sub) => {
              if (sub.id === item.id) {
                return { ...sub, active: true };
              } else if (sub.type === item.type) {
                return { ...sub, active: false };
              }
              return sub;
            });
            return { ...elem, subOptions };
          }
          if (elem.type === item.type) {
            return { ...elem, active: false };
          }
          return elem;
        }
      });
      setOptions(newOptions);
      setQuery((prevQuery) => ({
        lastUsed: item.type === OptionsTypes.LAST_USED ? (item.value as 0 | 1) : prevQuery.lastUsed,
        page: 1,
        sortBy: item.type === OptionsTypes.SORT_BY ? (item.value as SortByType) : prevQuery.sortBy,
      }));
      if (item.type === OptionsTypes.SORT_BY) {
        let itemLabel = '';
        if (item.label === SortLabels.CREATED_DATE) itemLabel = content.createdOn;
        if (item.label === SortLabels.EDITED_DATE) itemLabel = content.editedOn;
        if (item.label === SortLabels.LAST_DEPLOYMENT) itemLabel = content.deployedOn;
        if (item.label === SortLabels.AUTHOR) itemLabel = content.author;
        setSecondaryLabel(itemLabel as string);
      }
    };

    useEffect(() => {
      if (!businessUnitId) {
        return undefined;
      }
      const promise = dispatch(
        experience.thunk.getList({
          businessUnit: businessUnitId,
          page: query.page,
          lastUsed: query.lastUsed,
          sortBy: query.sortBy,
        }),
      );

      return () => {
        promise.abort();
      };
    }, [businessUnitId, dispatch, query]);

    useEffect(
      () => () => {
        setQuery(() => ({
          page: 1,
          lastUsed: 0,
          sortBy: SortValues.CREATED,
        }));
        dispatch(experience.actions.resetListData());
        dispatch(experience.actions.resetJourney());
      },
      [dispatch],
    );

    const handleSearchChange = debounce(
      useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          dispatch(
            experience.thunk.search({
              businessUnitId,
              searchTerm: event.target.value,
            }),
          );
        },
        [dispatch, businessUnitId],
      ),
      SEARCH_DEBOUNCE_DELAY,
    );

    const setSecondaryValue = (value: string, item: ExperienceItem) => {
      switch (value) {
        case SortExperienceValues.CREATED_ON:
          return format.date(item.createdAt as string, 'MM/dd/yyyy');
        case SortExperienceValues.EDITED_ON:
          return format.date(item.updatedAt as string, 'MM/dd/yyyy');
        case SortExperienceValues.LAST_DEPLOYMENT:
          return format.date(item.lastUsed as string, 'MM/dd/yyyy');
        case SortExperienceValues.AUTHOR:
          return `${item.author?.firstname.slice(0, 1)}.${item.author?.lastname}` as string;
        default:
          return format.date(item.createdAt as string, 'MM/dd/yyyy');
      }
    };

    let itemsToShow = items;
    if (usedExperiences) {
      itemsToShow = items.filter((el) => {
        return usedExperiences?.every((id) => id !== el.id);
      });
    }

    return (
      <Box className={styles.libraryNavigation} id="experienceListWrapper" {...{ ref }}>
        <Box className={styles.header}>
          {type === LibraryView.experiencesView ? (
            <Button variant="outlined" color="primary" onClick={createExperience} startIcon={<Icon.AddCircleOutline />}>
              {textTemplate(content.createValue, { value: content.moduleGroup })}
            </Button>
          ) : (
            <Typography.Caption>{`${content.moduleGroupsLibrary} (${items.length})`}</Typography.Caption>
          )}
          <Menu options={options} onClick={onChangeSortBy} />
        </Box>
        <InputBase
          className={styles.searchField}
          placeholder={content.search}
          onChange={handleSearchChange}
          endAdornment={<Icon.SearchOutline />}
        />
        <Box>
          {total > 0 ? (
            <InfiniteScroll
              hasMore={currentPage < pageCount}
              next={loadMoreExperiences}
              dataLength={items.length}
              loader={<Typography.Body className={styles.loader}>{content.loading}...</Typography.Body>}
              scrollableTarget="experienceListWrapper"
            >
              {itemsToShow.map((item) => {
                const secondaryValue = setSecondaryValue(secondaryLabel, item);

                return (
                  <ListItem
                    stylesListItem={styles.listItem}
                    id={item.id}
                    key={item.id}
                    label={item.name}
                    secondaryLabel={secondaryLabel}
                    secondaryText={secondaryValue}
                    draggable={type === LibraryView.deploymentsView}
                    onDragStart={type === LibraryView.deploymentsView ? handleDragExperience : undefined}
                    onClick={type === LibraryView.experiencesView ? onExperienceSelect : undefined}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <Typography.Body className={styles.loader}>{content.noItemsFound}</Typography.Body>
          )}
        </Box>
      </Box>
    );
  },
);

LibraryNavigation.displayName = 'LibraryNavigation';
