import { createAsyncThunk } from '@store';
import { global, ApiError } from '@modules';
import { network } from '@utils';

import { DataVisualizationUpdateArg } from './DataVisualization.types';
import {
  DataVisualizationImagesResponseSchema,
  DataVisualizationItemSchema,
  DataVisualizationListDataSchema,
  DataVisualizationListItemSchema,
  SignalListDataSchema,
} from './DataVisualization.schema';

export const create = createAsyncThunk(
  'dataVisualization/create',
  async (body: FormData, { signal, rejectWithValue, dispatch }) => {
    try {
      return await network.post<DataVisualizationListItemSchema>(
        { key: 'data_visualization_create' },
        { signal, body },
      );
    } catch (exception) {
      dispatch(global.actions.enqueueError(exception as ApiError));
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const update = createAsyncThunk(
  'dataVisualization/update',
  async ({ dataVisualization, body }: DataVisualizationUpdateArg, { rejectWithValue, dispatch }) => {
    try {
      return await network.post<DataVisualizationListItemSchema>(
        {
          key: 'data_visualization_update',
          params: { dataVisualization },
        },
        { body },
      );
    } catch (exception) {
      dispatch(global.actions.enqueueError(exception as ApiError));
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const getListDataVisualization = createAsyncThunk<
  DataVisualizationListDataSchema | void,
  {
    businessUnit: number;
    keyword?: string;
  }
>('dataVisualization/getListDataVisualization', async ({ businessUnit, keyword }, { rejectWithValue }) => {
  try {
    return await network.post({ key: 'search_data_visualizations' }, {
      body: JSON.stringify({ businessUnit: { id: businessUnit }, keyword }),
    } as any);
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});

export const getDataVisualization = createAsyncThunk<
  DataVisualizationItemSchema | void,
  {
    dataVisualization: number;
  }
>('dataVisualization/getDataVisualization', async ({ dataVisualization }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_data_visualization', params: { dataVisualization } });
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});

export const generateDeploymentDataVisualizationImages = createAsyncThunk<
  DataVisualizationListDataSchema | void,
  {
    dataVisualization: number;
    deployment: number;
  }
>(
  'dataVisualization/generateDeploymentDataVisualizationImages',
  async ({ dataVisualization, deployment }, { rejectWithValue }) => {
    try {
      return await network.post({
        key: 'generate_deployment_data_visualization_images',
        params: { dataVisualization, deployment },
      });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const getDataVisualizationDeployments = createAsyncThunk<
  any | void,
  {
    dataVisualization: number;
  }
>('dataVisualization/getDataVisualizationDeployments', async ({ dataVisualization }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_data_visualization_deployments', params: { dataVisualization } });
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});

export const getListSignal = createAsyncThunk<
  SignalListDataSchema | void,
  {
    businessUnit: number;
  }
>('dataVisualization/getSignalsList', async ({ businessUnit }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'signals_list_by_business_unit', params: { businessUnit: businessUnit } });
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});

export const getDataVisualizationImages = createAsyncThunk<
  DataVisualizationImagesResponseSchema | void,
  {
    segment: number;
    continuationToken?: string;
  }
>('dataVisualization/getDataVisualizationImages', async ({ segment, continuationToken }, { rejectWithValue }) => {
  try {
    const params: {
      segment: number;
      continuationToken?: string;
    } = {
      segment,
    };

    if (continuationToken) {
      params.continuationToken = continuationToken;
    }

    return await network.get({ key: 'list_data_visualization_images', params });
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});
