import { useCallback, useEffect, useState } from 'react';
import { Box, Collapse, Typography } from '@material-ui/core';

import { Icon } from '@components';

import { AlertProps } from './Alert.props';
import { useStyles } from './Alert.styles';
import { PRIMARY_COLORS } from './Alert.const';

/**
 * Alert component
 * @returns {JSX.Element}
 */

export const Alert = ({
  className = '',
  type = 'error',
  align = 'center',
  message = '',
  description = '',
  onClose,
  actionLink = null,
}: AlertProps): JSX.Element => {
  const styles = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    setOpen(false);
  }, [onClose, setOpen]);

  useEffect(() => {
    setOpen(!!(message || description));
  }, [message, description]);

  return (
    <Collapse in={open}>
      <Box className={`${styles.alert} ${styles[type]} ${className}`}>
        <Box className={styles.row} data-align={align}>
          {onClose && (
            <button className={styles.close} type="button" onClick={handleClose}>
              <Icon.CloseOutline stroke={PRIMARY_COLORS[type]} width={24} height={24} />
            </button>
          )}
          {message && <Typography className={`${styles.title} ${styles[type]}`}>{message}</Typography>}
          {description && (
            <Typography className={`${styles.description} ${styles[type]}`}>
              {description} {actionLink ? actionLink : ''}
            </Typography>
          )}
        </Box>
      </Box>
    </Collapse>
  );
};
