import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * ItalicV2 component
 * @returns {JSX.Element}
 */

export const ItalicV2 = ({
  className = '',
  width = 8,
  height = 10,
  fill = variables.color.primary.mediumGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.66667 0V2.14286H4.14L1.86 7.85714H0V10H5.33333V7.85714H3.86L6.14 2.14286H8V0H2.66667Z" fill={fill} />
  </svg>
);
