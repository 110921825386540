import { content } from '@content';

/**
 * Publish component constants
 * @returns {Variables}
 */

export const menuKey = {
  entryPackage: 'entry-package-file',
  smart: 'smart-file',
  module: 'module-file',
  template: 'template-file',
};

export const MENU_OPTIONS = [
  {
    id: menuKey.entryPackage,
    label: content.entirePackage,
    loading: false,
  },
  {
    id: menuKey.smart,
    label: content.smartContentFile,
    loading: false,
  },
  {
    id: menuKey.module,
    label: content.modules,
    loading: false,
  },
  {
    id: menuKey.template,
    label: content.template,
    loading: false,
  },
];
