/**
 * TrendCurve component constants
 * @returns {Variables}
 */

export const TREND_WIDTH = 60;

export const TREND_HEIGHT = 25;

export const MAX_RANGE = 18;

export const STROKE_WIDTH = 3;
