import { FC, ReactElement, SVGProps } from 'react';

export const ArrowUp: FC<SVGProps<SVGElement>> = ({ className, strokeWidth = '1.5' }): ReactElement => (
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M11.6836 6.3418L6.34191 1.00011L1.00023 6.3418"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
