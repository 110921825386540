import { Box, Button, MenuItem, Radio, TextField } from '@material-ui/core';
import { memo, useEffect, useState } from 'react';

import { Icon, Select, Table, Typography } from '@components';
import { content } from '@content';
import { attributeLibrary, signalLibrary, userClients } from '@modules';
import { useAppDispatch } from '@store';
import { variables } from '@styles';
import { format, textTemplate } from '@utils';

import { SIGNAL_TYPES } from './AttributesFeed.const';
import { useStyles } from './AttributesFeed.styles';
import { SignalsImportProps } from './AttributesFeed.types';
import { isCompositeOrStatistic } from './AttributesFeed.utils';

/**
 AttributesFeed component.
 @returns {JSX.Element}
 */

export const AttributesFeed = memo(
  ({ businessUnit, uniqueIdKey = '', onClose, onSuccess }: SignalsImportProps): JSX.Element => {
    const styles = useStyles();

    const dispatch = useAppDispatch();
    const attributeLibraryListData = attributeLibrary.useListData();
    const feedItems = attributeLibrary.useFeedData().items;
    const keyAttribute = {
      attributeName: uniqueIdKey,
      attributeType: 'boolean',
      createdAt: '2024-05-20T15:00:24-04:00',
      fieldName: uniqueIdKey,
      id: 0,
      key: true,
      updatedAt: '2024-05-20T15:00:24-04:00',
      usedAsKey: false,
    };
    const attributesDataList = [keyAttribute, ...attributeLibrary.useAttributeDataList().items];
    const [attributeOpen, setAttributeOpen] = useState<number[]>([]);
    const [createAttributeFocus, setCreateAttributeFocus] = useState<boolean[]>([]);

    const handleAttributeOpen = (index: number) =>
      setAttributeOpen((prevState) =>
        prevState.map((item, ind) => {
          if (index === ind) return 1;

          return 0;
        }),
      );

    const handleCreateAttributeFocus = (index: number) => {
      setCreateAttributeFocus((prevState) => prevState.map((item, ind) => ind === index));
    };

    useEffect(() => {
      dispatch(attributeLibrary.thunk.getDataAttributeFeeds(businessUnit));
      dispatch(attributeLibrary.thunk.getDataAttributeList(businessUnit));
    }, [businessUnit, dispatch]);

    useEffect(
      () => () => {
        dispatch(signalLibrary.actions.setFieldsData(null));
      },
      [dispatch],
    );

    const handleImportClick = () => {
      onSuccess();
    };

    const handleExportClick = () => {
      dispatch(attributeLibrary.thunk.exportDataAttribute(businessUnit));
    };

    return (
      <form>
        <Box className={styles.header}>
          <Box>
            <Typography.SmallCaption className={styles.topTitle}>{`${content.import} /`}</Typography.SmallCaption>
            <Typography.LargeTitle>{content.mappedData}</Typography.LargeTitle>
          </Box>
        </Box>

        <Box className={styles.feedHistory}>
          <Typography.Caption className={styles.description}>{content.dataAttributesFeedHistory}</Typography.Caption>
          <Table
            uiType="tertiary"
            autofit
            columns={[
              {
                id: 'name',
                title: content.fileName,
                className: styles.fileNameColumn,
              },
              {
                id: 'date',
                title: content.dateUploaded,
                className: styles.dateColumn,
              },
              {
                id: 'fileType',
                title: content.fileType,
                className: styles.fileType,
              },
              {
                id: 'status',
                title: content.uploadStatus,
                className: styles.statusColumn,
              },
            ]}
            records={feedItems.map((item) => ({
              name: item.fileName,
              id: `${item.id}`,
              date: format.date(item.createdAt, 'MM/dd/yyyy hh:mm:ss'),
              fileType: item.fileType,
              status:
                item.uploadStatus !== 'Failure' ? (
                  <Icon.CheckmarkCircleOutline className={styles.statusIcon} stroke={variables.color.primary.green} />
                ) : (
                  <Icon.RemoveCircleOutline className={styles.statusIcon} stroke={variables.color.primary.red} />
                ),
            }))}
          />
        </Box>
        <Box className={styles.body}>
          <Typography.Caption className={styles.description}>
            {content.pleaseMapDataFromUploadedFile}
          </Typography.Caption>
          <Box className={styles.attributeRow}>
            <Box className={styles.key}>
              <Typography.SmallTag>{content.key}</Typography.SmallTag>
            </Box>
            <Box className={styles.fieldName}>
              <Typography.SmallTag>{content.fieldName}</Typography.SmallTag>
            </Box>
            <Box className={styles.attributeType}>
              <Typography.SmallTag>{content.attributeType}</Typography.SmallTag>
            </Box>
            <Box className={styles.attributeName}>
              <Typography.SmallTag>{content.attributeName}</Typography.SmallTag>
            </Box>
          </Box>

          {attributesDataList.map((item, index) => (
            <Box key={`attr-item-${index}`} className={styles.attributeRow}>
              <Box className={`${styles.key} ${styles.keyCol}`}>
                <Radio checked={!!item.key} color="primary" className={styles.radioBtn} disabled={true} />
              </Box>
              <Box className={`${styles.fieldName} ${styles.fieldNameCol}`}>
                <Typography.Caption className={styles.name}>{item.fieldName}</Typography.Caption>
              </Box>
              <Box className={styles.attributeType}>
                <Select
                  placeholder={content.select}
                  value={item.attributeType}
                  displayEmpty
                  disabled={true}
                  options={SIGNAL_TYPES.map((signalType) => ({ id: signalType, label: signalType }))}
                />
              </Box>
              <Box className={styles.attributeName}>
                <Select
                  value={item.attributeName}
                  placeholder={content.select}
                  open={attributeOpen[index] === 1}
                  disabled={true}
                  className={styles.attrSelect}
                >
                  <MenuItem
                    className={styles.topRowMenuItem}
                    value="createAttribute"
                    onClick={() => handleAttributeOpen(index)}
                  >
                    <Box className={styles.topRow}>
                      <Icon.AddOutline
                        className={`${styles.topRowAddIcon} ${createAttributeFocus[index] ? 'hide' : ''}`}
                        stroke={variables.color.primary.mediumGray}
                      />
                      <TextField
                        className={`${styles.topRowInput} ${createAttributeFocus[index] ? 'focus' : ''}`}
                        placeholder={
                          createAttributeFocus[index]
                            ? textTemplate(content.enterValue, { value: content.name })
                            : textTemplate(content.createValue, { value: content.name })
                        }
                        inputProps={{ 'data-signal-key': index }}
                        onFocus={() => handleCreateAttributeFocus(index)}
                        onKeyDown={(event) => event.stopPropagation()}
                      />
                      <Button className={`${styles.topRowCheckBtn} ${createAttributeFocus[index] ? 'show' : ''}`}>
                        <Icon.Checkmark />
                      </Button>
                    </Box>
                  </MenuItem>
                  {attributeLibraryListData.items &&
                    attributeLibraryListData.items.length > 0 &&
                    attributeLibraryListData.items
                      .filter((attribute) => !isCompositeOrStatistic(attribute))
                      .map((attribute) => (
                        <MenuItem key={attribute.id} value={attribute.label} className={styles.attributeMenuItem}>
                          <Typography.Body className={styles.optionName}>{attribute.label}</Typography.Body>
                          <Typography.Body className={styles.optionText}>
                            {attribute.totalSubscribers ? `${format.number(attribute.totalSubscribers)}` : '0'}
                          </Typography.Body>
                        </MenuItem>
                      ))}
                </Select>
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={styles.footer}>
          <Box>
            <Button variant="contained" color="primary" type="button" onClick={handleExportClick}>
              {content.export}
            </Button>
          </Box>
          <Box className={styles.rightActionBtns}>
            <Button variant="outlined" color="primary" className={styles.cancelBtn} onClick={onClose}>
              {content.cancel}
            </Button>
            <Button variant="contained" color="primary" type="button" onClick={handleImportClick}>
              {content.import}
            </Button>
          </Box>
        </Box>
      </form>
    );
  },
);

AttributesFeed.displayName = 'AttributesFeed';
