/* Application styles variables */

import { color } from './color';

export const variables = {
  color,
  font: {
    family: {
      base: 'Manrope',
      code: 'IBM Plex Mono',
      fallback: 'sans-serif',
    },
    size: {
      xxxxl: '2.8rem',
      xxxl: '2.1rem',
      xxl: '1.8rem',
      xl: '1.6rem',
      lg: '1.4rem',
      md: '1.3rem',
      sm: '1.2rem',
      xs: '1.1rem',
      xxs: '1rem',
      xxxs: '0.9rem',
    },
    weight: {
      extraBold: 800,
      bold: 700,
      semiBold: 600,
      medium: 500,
      regular: 400,
      light: 300,
    },
  },
  lineHeight: {
    xxxxl: '2.9rem',
    xxxl: '2.5rem',
    xxl: '2.2rem',
    xl: '1.9rem',
    lg: '1.8rem',
    md: '1.7rem',
    sm: '1.6rem',
    xs: '1.5rem',
    xxs: '1.4rem',
    xxxs: '1.2rem',
  },
  letterSpacing: {
    lg: '0.02rem',
    sm: '0.01rem',
    xs: '-0.01rem',
  },
  borderRadius: {
    half: '50%',
    lg: '5rem',
    md: '0.8rem',
    sm: '0.6rem',
    xs: '0.3rem',
    xxs: '0.2rem',
  },
  borderWidth: {
    xl: '0.4rem',
    lg: '0.2rem',
    md: '0.1rem',
    sm: 'thin',
  },
  opacity: {
    full: 1,
    high: 0.7,
    half: 0.5,
    medium: 0.3,
    low: 0.15,
    none: 0,
  },
  gap: {
    xxxxxl: '25.6rem',
    xxxxl: '12.8rem',
    xxxl: '9.6rem',
    xxl: '6.4rem',
    xl: '4.8rem',
    lg: '3.2rem',
    md: '2.4rem',
    sm: '1.6rem',
    xs: '0.8rem',
    xxs: '0.4rem',
  },
  scrollbar: {
    width: '0.6rem',
    height: '0.6rem',
  },
  topBar: {
    height: '7rem',
  },
  sideBar: {
    width: '7.2rem',
  },
};
