import { memo, useCallback } from 'react';
import { useController, useWatch, useFormContext } from 'react-hook-form';

import { OptionItem } from '@components';
import { array, format, func } from '@utils';
import { Condition, FormValues } from '@routes';

import { RuleValueProps } from './Conditions.types';
import { ConditionValue } from './ConditionValue';

import { MetConditionProps } from './MetCondition.props';

export const MetCondition = memo(
  ({ defaultValue, mindsetIndex, onChange = func.nop }: MetConditionProps): JSX.Element => {
    const { setValue } = useFormContext<FormValues>();
    const { field } = useController({
      name: `mindsets.${mindsetIndex}.metConditionCount`,
      defaultValue,
      rules: {
        required: true,
        validate: (val) => val !== 0,
      },
    });
    const conditions: Condition[] = useWatch({
      name: `mindsets.${mindsetIndex}.conditions`,
    });

    const requiredConditions = conditions?.filter((condition) => condition.required);

    const options: Required<RuleValueProps>['options'] =
      conditions?.length > 1
        ? array
            .range(requiredConditions.length, conditions.length)
            .filter((count) => count !== 0)
            .map((count) => ({
              id: count.toString(),
              label: format.numberToWord(count),
              active: count.toString() === field.value,
            }))
        : [];

    const handleMenuClick = useCallback(
      (option: OptionItem) => {
        setValue(field.name, +option.id, { shouldValidate: true, shouldDirty: true });
      },
      [setValue, field.name],
    );

    return (
      <ConditionValue
        options={options}
        value={format.numberToWord(defaultValue === 0 ? 1 : field.value)}
        handleMenuClick={handleMenuClick}
        onSave={onChange}
      />
    );
  },
);

MetCondition.displayName = 'MetCondition';
