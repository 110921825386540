import { memo, forwardRef } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';

import { deployment } from '@modules';
import { Typography } from '@components';
import { content } from '@content';
import { Position } from './Position';
import { useStyles } from './DeploymentBuilder.styles';
import { DeploymentBuilderProps } from './DeploymentBuilder.props';

const DeploymentBuilderWithRef = forwardRef(
  ({ addPosition, disabled = false, isTemplateView, usedJourneys }: DeploymentBuilderProps, ref): JSX.Element => {
    const styles = useStyles();
    const currentDeployment = deployment.useDeploymentData();
    const {
      data: { positions },
    } = deployment.useInfoEmailTemplate();

    const handleDragJourney = (e: React.BaseSyntheticEvent) => {
      const nativeEvent = e.nativeEvent as DragEvent;
      const journeyId = Number.parseInt(e.target.dataset.journeyId, 10);

      const modules = positions.find((pos) => pos.experience.id === journeyId)?.experience.modules;
      nativeEvent.dataTransfer?.setData('application/json', JSON.stringify({ cursor: 0, journeyId, modules }));
    };

    return (
      <Box className={styles.deploymentBuilder} {...{ ref }}>
        <Box className={styles.header}>
          <Typography.Title className={styles.title}>{content.deploymentBuilder}</Typography.Title>
        </Box>
        <Box>
          <Box className={styles.infoBox}>
            <Typography.Caption className={styles.infoTitle}>{content.positions}</Typography.Caption>
            <Box className={styles.positions}>
              {currentDeployment && (
                <Typography.Title className={styles.positionsCount}>
                  {currentDeployment?.positions?.length}
                </Typography.Title>
              )}
              <ButtonBase
                className={styles.addPosition}
                onClick={addPosition}
                disabled={!currentDeployment || disabled || isTemplateView}
              >
                +
              </ButtonBase>
            </Box>
          </Box>
        </Box>
        {isTemplateView ? (
          <Box className={styles.positionsList}>
            {currentDeployment?.positions?.map(
              (position, index) =>
                position.experience?.id &&
                !usedJourneys?.includes(position.experience.id) && (
                  <Position.TemplateBuilder
                    name={position.experience.name || ''}
                    index={index}
                    key={position.id}
                    disabled={disabled}
                    journeyId={position.experience.id}
                    onDragStart={handleDragJourney}
                  />
                ),
            )}
          </Box>
        ) : (
          <Droppable droppableId="positions" type="DEPLOYMENT_POSITION" isDropDisabled={disabled}>
            {(provided) => (
              <Box {...{ ref: provided.innerRef }} {...provided.droppableProps} className={styles.positionsList}>
                {currentDeployment?.positions?.map((position, index) => (
                  <Position.DeploymentBuilder
                    id={position.id}
                    name={position.experience?.name || ''}
                    index={index}
                    key={position.id}
                    disabled={disabled}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        )}
      </Box>
    );
  },
);

DeploymentBuilderWithRef.displayName = 'DeploymentBuilderWithRef';

export const DeploymentBuilder = memo(DeploymentBuilderWithRef);
