import { Box } from '@material-ui/core';

import { SectionProps } from './Section.props';
import { useStyles } from './Section.styles';

/**
 * Section component as column container for content within Private layout
 */
export const Section = ({ className = '', range, gap, children }: SectionProps): JSX.Element => {
  const styles = useStyles({ range, gap });

  return <Box className={`${styles.section} ${className}`}>{children}</Box>;
};
