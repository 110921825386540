import { memo, useMemo } from 'react';
import { line, curveNatural } from 'd3-shape';
import { scaleLinear } from 'd3-scale';

import { variables } from '@styles';

import { TrendCurveProps } from './TrendCurve.props';
import { TREND_WIDTH, TREND_HEIGHT, MAX_RANGE, STROKE_WIDTH } from './TrendCurve.const';

/**
 TrendCurve component.
 @returns {JSX.Element}
 */

export const TrendCurve = memo(({ data, trend }: TrendCurveProps): JSX.Element => {
  const maxValue = Math.max(...data);

  let maxRange = MAX_RANGE;

  const isStraightLine = data.every((item) => item === data[0]);
  if (isStraightLine) maxRange = TREND_HEIGHT / 2;

  // function that scaled value from 0 to 18
  const scaleValue = scaleLinear().domain([0, maxValue]).range([0, maxRange]);

  // function that transfromed data to [x, y]
  const transformedData = useMemo(() => {
    if (data.length > 1) {
      return data
        .filter((value) => value)
        .map((item, index) => [index * (TREND_WIDTH / (data.length - 1)), scaleValue(item)]);
    } else {
      return [
        [0, maxRange],
        [STROKE_WIDTH, maxRange],
      ];
    }
  }, [data, scaleValue, maxRange]);

  const curvePath = useMemo(
    () => line().curve(curveNatural)(transformedData as [number, number][]) || '',
    [transformedData],
  );

  const color = useMemo(() => {
    if (trend === 0) {
      return variables.color.primary.amber;
    }
    if (trend > 0) {
      return variables.color.semantic.positive;
    }
    return variables.color.semantic.negative;
  }, [trend]);

  return (
    <svg width={TREND_WIDTH} height={TREND_HEIGHT} xmlns="http://www.w3.org/2000/svg" transform="scale(1, -1)">
      <path d={curvePath} stroke={color} fill="transparent" strokeWidth={STROKE_WIDTH} />
    </svg>
  );
});

TrendCurve.displayName = 'TrendCurve';
