import accordionCollapse from './accordion-collapse.svg';
import accordionExpand from './accordion-expand.svg';
import bubble from './bubble.svg';
import chevronRight from './chevron-right.svg';
import checkWhite from './check-white.svg';
import checkboxChecked from './checkbox-checked.svg';
import checkboxCheckedDisabled from './checkbox-checked-disabled.svg';
import grabIcon from './grab-icon.svg';
import logoHorizontalPurple from './logo-horizontal-purple.png';
import iconeChargementViolet from './icone-chargement-violet.png';
import logoVerticalWhite from './logo-vertical-white.svg';
import composefyLogo from './composef.ai-logo.svg';
import composefyPurpleLogo from './сomposef.ai-logo-purple.svg';
import composefyWhiteLogo from './сomposef.ai-logo-white.svg';
import minus from './minus.svg';
import plusAccordion from './plus-accordion.svg';

export default {
  accordionCollapse,
  accordionExpand,
  bubble,
  chevronRight,
  checkWhite,
  checkboxChecked,
  checkboxCheckedDisabled,
  iconeChargementViolet,
  grabIcon,
  logoHorizontalPurple,
  logoVerticalWhite,
  composefyLogo,
  composefyPurpleLogo,
  composefyWhiteLogo,
  minus,
  plusAccordion,
};
