import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    betweenField: {
      flexBasis: '15rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: '15rem',
      marginRight: theme.spacing(1),
    },

    andLabel: {
      margin: theme.spacing(0.875, 0),
    },
  }),
);
