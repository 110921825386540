import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

const ROOT_TOP_PADDING = '2.7rem';
const HEADER_HEIGHT = '8.8rem';
const HEADER_MARGIN_BOTTOM = variables.gap.xl;
const RECOMMENDER_MARGIN_TOP = variables.gap.sm;

export const useStyles = makeStyles(() =>
  createStyles({
    journeyBuilder: {
      padding: `${ROOT_TOP_PADDING} 2.4rem 0`,
      height: `calc(100vh - ${variables.topBar.height})`,
      '&[data-view="item"]': {
        height: 'auto',
        minHeight: `calc(100vh - ${variables.topBar.height})`,
      },
      '&[data-recommender-view="true"]': {
        position: 'relative',
        overflow: 'hidden',
        height: `calc(100vh - ${variables.topBar.height})`,
        '& .RulesEngineContent': {
          opacity: 0,
        },
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: HEADER_MARGIN_BOTTOM,
      height: HEADER_HEIGHT,
    },
    content: {
      display: 'flex',
      height: `calc(100% - ${variables.topBar.height} - ${ROOT_TOP_PADDING} - ${HEADER_HEIGHT} - ${HEADER_MARGIN_BOTTOM} + ${RECOMMENDER_MARGIN_TOP})`,
      '&[data-view="item"]': {
        height: 'auto',
      },
    },
    recommenderBar: {
      marginTop: RECOMMENDER_MARGIN_TOP,
      background: 'rgba(255, 255, 255, 0.7)',
      padding: '0.4rem 0 0.7rem',
      borderRadius: variables.borderRadius.sm,
      maxHeight: '7.4rem',
      '&[data-view="item"]': {
        marginLeft: variables.gap.md,
        marginRight: '25.8rem',
        position: 'sticky',
        bottom: 0,
      },
      '&[data-collapsed="false"]': {
        position: 'absolute',
        top: `calc(${ROOT_TOP_PADDING} + ${HEADER_HEIGHT} + ${HEADER_MARGIN_BOTTOM})`,
        left: 0,
        bottom: 0,
        right: 0,
        paddingTop: '0.8rem',
        marginTop: 0,
        marginLeft: '2.4rem',
        marginRight: '2.4rem',
        maxHeight: 'initial',
      },
    },
    chevronBtn: {
      transform: 'rotate(-90deg)',
      marginRight: variables.gap.lg,
      marginLeft: variables.gap.sm,
      '&[data-collapsed="false"]': {
        transform: 'rotate(90deg)',
      },
    },
    recommenderBarTop: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    recommenderBarContent: {
      marginTop: '0.6rem',
      '&[data-collapsed="true"]': {
        display: 'none',
      },
      '& [data-area="legend"]': {
        marginTop: variables.gap.sm,
        marginBottom: variables.gap.sm,
        marginLeft: variables.gap.lg,
      },
    },
  }),
);
