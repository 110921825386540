import { createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from '@store';
import { MetaSchema, Counts } from '@modules';

import * as thunk from './Signal.thunk';
import { initialState } from './Signal.state';
import { reducers, extraReducers } from './Signal.reducer';
import { CompositeSignal } from './Signal.schema';

export * from './Signal.types';

const slice = createSlice({
  name: 'signal',
  initialState,
  reducers,
  extraReducers,
});

export const signal = {
  thunk,
  slice,
  actions: slice.actions,
  useItemMeta: (): MetaSchema =>
    useAppSelector(
      ({
        signal: {
          item: { data, ...meta },
        },
      }) => meta,
    ),
  useItemData: (): CompositeSignal =>
    useAppSelector(
      ({
        signal: {
          item: { data },
        },
      }) => data,
    ),
  useCountsData: (): Counts =>
    useAppSelector(
      ({
        signal: {
          counts: { data },
        },
      }) => data,
    ),
  useCountsMeta: (): MetaSchema =>
    useAppSelector(
      ({
        signal: {
          counts: { data, ...meta },
        },
      }) => meta,
    ),
};
