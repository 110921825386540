import { FC, SVGProps } from 'react';

export const SmallClose: FC<SVGProps<SVGElement>> = ({
  width = 20,
  height = 20,
  strokeWidth = 3,
  stroke = '#FFFFFF',
}): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.03784 6.14648L9.81498 9.92363L13.5921 13.7008"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.811 6.14062L10.0339 9.91776L6.25676 13.6949"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
