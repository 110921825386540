import { Box } from '@material-ui/core';

import { Badge, Typography } from '@components';

import { LegendProps } from './Legend.props';
import { useStyles } from './Legend.styles';

export const Legend = ({ className = '', items, size, title }: LegendProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={`${styles.legend} ${className}`}>
      {title && <Typography.Tag>{title}</Typography.Tag>}
      {items.map((item) => (
        <Box key={item.label} className={styles.item} title={item.label}>
          <Badge color={item.color} size={size} />
          <Typography.Caption data-variant="label" className={styles.label}>
            {item.label}
          </Typography.Caption>
          <Typography.Tag data-variant="count" className={styles.count}>
            {item.count}
          </Typography.Tag>
        </Box>
      ))}
    </Box>
  );
};
