import { FC, ReactElement } from 'react';
import { Box, BoxProps } from '@material-ui/core';

import { useStyles } from './Panel.styles';

const Panel: FC<BoxProps> = ({ className = '', children, ...rest }): ReactElement => {
  const styles = useStyles();

  return (
    <Box className={`${styles.panel} ${className}`} {...rest}>
      {children}
    </Box>
  );
};

export default Panel;
