import { SVGProps } from 'react';

export const DeploymentsOutline = ({
  width = 24,
  height = 24,
  stroke = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M18.0638 11.6192H17C16.9796 11.6192 16.96 11.6111 16.9456 11.5967C16.9312 11.5822 16.9231 11.5627 16.9231 11.5423V8.57147C16.9231 8.17507 16.7656 7.79491 16.4853 7.51462C16.205 7.23432 15.8249 7.07685 15.4285 7.07685H12.4577C12.4373 7.07685 12.4177 7.06875 12.4033 7.05432C12.3889 7.03989 12.3808 7.02033 12.3808 6.99993V5.93607C12.3808 4.89222 11.5538 4.01837 10.51 4.00029C10.2571 3.99585 10.0058 4.04182 9.77089 4.13552C9.53594 4.22921 9.322 4.36876 9.14155 4.54602C8.96111 4.72328 8.81777 4.9347 8.7199 5.16794C8.62204 5.40119 8.5716 5.65159 8.57154 5.90454V6.99993C8.57154 7.02033 8.56343 7.03989 8.54901 7.05432C8.53458 7.06875 8.51502 7.07685 8.49462 7.07685H5.52385C5.12007 7.07807 4.73318 7.23901 4.44767 7.52452C4.16216 7.81004 4.00122 8.19693 4 8.6007V11.3076C4 11.328 4.0081 11.3476 4.02253 11.362C4.03696 11.3765 4.05652 11.3846 4.07692 11.3846H5.11192C6.24154 11.3846 7.17654 12.3646 7.19231 13.4942C7.20846 14.643 6.28808 15.6923 5.14269 15.6923H4.07692C4.05652 15.6923 4.03696 15.7004 4.02253 15.7148C4.0081 15.7292 4 15.7488 4 15.7692V18.4761C4.00122 18.8799 4.16216 19.2668 4.44767 19.5523C4.73318 19.8378 5.12007 19.9988 5.52385 20H8.23077C8.25117 20 8.27074 19.9919 8.28516 19.9775C8.29959 19.963 8.30769 19.9435 8.30769 19.9231V19.1181C8.30769 17.9535 9.25962 16.9508 10.4231 16.9234C11.5808 16.8965 12.6154 17.7046 12.6154 18.8573V19.9231C12.6154 19.9435 12.6235 19.963 12.6379 19.9775C12.6523 19.9919 12.6719 20 12.6923 20H15.4285C15.8249 20 16.205 19.8425 16.4853 19.5622C16.7656 19.2819 16.9231 18.9018 16.9231 18.5054V15.5054C16.9231 15.485 16.9312 15.4654 16.9456 15.451C16.96 15.4365 16.9796 15.4284 17 15.4284H18.0954C19.1581 15.4284 20 14.5565 20 13.49C20 12.4234 19.1077 11.6192 18.0638 11.6192Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
