import { lightFormat } from 'date-fns';
import { Box, Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';

import { Badge, Link, Typography } from '@components';

import { STATUSES } from './PublishToESP.const';
import { ColTip, ColRdr, ColId, Sort, Col, Rec } from './PublishToESP.types';

export const getPckdRender =
  (onChange: CheckboxProps['onChange']): ColRdr =>
  // eslint-disable-next-line react/display-name
  (value, recordId) =>
    (
      <FormControlLabel
        label=""
        control={
          <Checkbox
            inputProps={{ 'data-id': recordId } as Record<string, string>}
            checked={Boolean(value)}
            onChange={onChange}
          />
        }
      />
    );

export const statTooltip: ColTip = (rec, col) => {
  if (rec) {
    const stat = rec[col.id] as Rec['stat'];

    return STATUSES[stat].label;
  }

  return `${col.title}`;
};

export const statRender: ColRdr = (value) => <Badge color={STATUSES[value as Rec['stat']].color} />;

export const mtagTooltip: ColTip = (rec, col) => {
  if (rec) {
    const [tag] = rec[col.id] as Rec['mtag'];

    return tag;
  }

  return `${col.title}`;
};

export const mtagRender: ColRdr = (value) => {
  const [tag, path] = value as Rec['mtag'];

  return <Link size="tiny" label={tag} to={path} />;
};

export const dscrTooltip: ColTip = (rec, col) => {
  if (rec) {
    const [name] = rec[col.id] as Rec['dscr'];

    return name;
  }

  return `${col.title}`;
};

export const dscrRender: ColRdr = (value) => {
  const [name, product, cohort] = value as Rec['dscr'];

  return (
    <Box>
      <Typography.Body>{name}</Typography.Body>
      <Typography.Caption>{product}</Typography.Caption>
      <Typography.Caption>{cohort}</Typography.Caption>
    </Box>
  );
};

export const posnRender: ColRdr = (value) => <Typography.Caption>{value}</Typography.Caption>;

const dateFormat = (date: number, noSs = false): string =>
  lightFormat(date * 1000, `MM/dd/yyyy hh:mm${noSs ? '' : ':ss'} a`);

export const dateRender: ColRdr = (value, recId, colId) => {
  const [at, by] = value as Rec['pubd'];
  const [date, time12, ampm] = at ? dateFormat(at, colId === ColId.modd).split(' ') : ['', '', ''];

  return (
    <Box>
      {[date, `${time12} ${ampm}`, by].map((part, index) => (
        <Typography.Caption key={index}>{part}&nbsp;</Typography.Caption>
      ))}
    </Box>
  );
};

export const getColSort = (colId: ColId, sort: Sort): Col['sort'] => (sort.by === colId ? sort.order : null);

export const getSortedRecs = (recs: Rec[], sort: Sort): Rec[] =>
  recs.sort((a, b) => {
    if (sort.order === null) {
      return 0;
    }

    let less;
    let aVal;
    let bVal;

    switch (sort.by) {
      case ColId.stat:
        less = a.stat < b.stat;
        break;
      case ColId.mtag:
        [aVal] = a.mtag;
        [bVal] = b.mtag;

        less = aVal < bVal;
        break;
      case ColId.dscr:
        [aVal] = a.dscr;
        [bVal] = b.dscr;

        less = aVal < bVal;
        break;
      case ColId.posn:
        less = a.posn < b.posn;
        break;
      case ColId.pubd:
        [aVal] = a.pubd;
        [bVal] = b.pubd;

        less = aVal < bVal;
        break;
      case ColId.modd:
        [aVal] = a.modd;
        [bVal] = b.modd;

        less = aVal < bVal;
        break;
      default:
        return 0;
    }

    return sort.order === 'ASC' ? (less && -1) || 1 : (less && 1) || -1;
  });
