import { FC, ReactElement } from 'react';
import { Typography as MuiTypography } from '@material-ui/core';

import { Typography } from '@components';
import { content } from '@content';
import { format } from '@utils';

import { useStyles } from './BarStackedChartTooltip.styles';
import { BarStackedChartTooltipProps } from './BarStackedChart.props';

export const BarStackedChartTooltip: FC<BarStackedChartTooltipProps> = ({ id, data }): ReactElement => {
  const styles = useStyles();

  const filtered = Object.fromEntries(Object.entries(data).filter(([key]) => key.includes(id)));

  const ctir = filtered[`${id}Ctir`];
  const volume = filtered[`${id}Percent`];
  const productName = filtered[`${id}ProductName`];
  const cohortName = filtered[`${id}CohortName`];

  return (
    <div className={styles.tooltip}>
      <Typography.Caption className={styles.caption}>{`${productName} / ${cohortName}`}</Typography.Caption>
      <MuiTypography className={styles.text}>{`${content.volume}: ${format.percent(volume)}`}</MuiTypography>
      <MuiTypography className={styles.text}>{`${content.ctir}: ${format.percent(ctir)}`}</MuiTypography>
    </div>
  );
};
