import { memo } from 'react';
import { Box, Typography as MuiTypography } from '@material-ui/core';

import { Icon } from '@components';
import { DashboardCard } from '@views';
import { content } from '@content';
import { textTemplate, format } from '@utils';

import { ModulePopularCard } from './ModulePopularCard';

import { useStyles } from './PerformingContentReport.styles';
import { PerformingContentReportProps } from './PerformingContentReport.props';

/**
 * PerformingContentReport component
 * @returns {JSX.Element}
 */

export const PerformingContentReport = memo(
  ({ title, icon, data, businessUnitName, impressionText }: PerformingContentReportProps): JSX.Element => {
    const styles = useStyles();

    const ReportIcon = Icon[icon];

    const descriptionTemplate = data.length === 0 ? content.performingContentEmpty : content.performingContentText;

    return (
      <DashboardCard className={styles.performingContentReport} icon={<ReportIcon />} title={title}>
        <Box className={styles.content}>
          <MuiTypography
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: textTemplate(descriptionTemplate, {
                businessUnitName,
                impressionText,
                moduleCount: format.numberToWord(data.length).toLowerCase(),
              }),
            }}
          />
          {data.length > 0 && (
            <Box className={styles.cards}>
              {data.map((item) => (
                <ModulePopularCard key={item.id} item={item} />
              ))}
            </Box>
          )}
        </Box>
      </DashboardCard>
    );
  },
);

PerformingContentReport.displayName = 'PerformingContentReport';
