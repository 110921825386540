import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * DisclosureWarningModal component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    disclosureWarningModal: {
      margin: -theme.spacing(3),
    },
    header: {
      padding: theme.spacing(3.4, 3, 3, 3),
    },
    content: {
      padding: theme.spacing(3),
    },
    footer: {
      textAlign: 'right',
      padding: theme.spacing(2, 3),
    },
  }),
);
