import { Box, ButtonBase } from '@material-ui/core';
import { memo, useContext } from 'react';

import { Icon, Typography } from '@components';
import { content } from '@content';
import { variables } from '@styles';
import { format } from '@utils';

import { SignalBuilderContext } from '../SignalBuilder.const';

import { AttributeProps } from './Attribute.props';
import { useStyles } from './Attribute.styles';
import { getStyle } from './Attribute.utils';

/**
 Attribute route component.
 @returns {JSX.Element}
 */

export const Attribute = memo(
  ({
    className,
    provided,
    item,
    style,
    isClicable,
    isCloudShown = false,
    isDragging,
    editMode,
    onClick,
    onDblClick,
  }: AttributeProps): JSX.Element => {
    const styles = useStyles();
    const { chosenAttributeId, onDoubleClick } = useContext(SignalBuilderContext);

    const handleSignalClick = () => {
      if (onClick) {
        onClick(item);
      }
    };

    return (
      <div
        /* eslint-disable react/jsx-props-no-spreading */
        {...(provided ?? {}).draggableProps}
        {...(provided ?? {}).dragHandleProps}
        {...{ ref: (provided ?? {}).innerRef }}
        /* eslint-enable react/jsx-props-no-spreading */
        style={getStyle({ provided: provided ?? ({ draggableProps: { style: {} } } as any), style, isDragging })}
        className={`${className} ${styles.attribute}`}
        data-chosen={item.signalType === 'composite' && item.id === chosenAttributeId}
        onDoubleClick={
          item.signalType === 'composite'
            ? async () => {
                onDoubleClick(item.id);

                if (onDblClick) {
                  onDblClick(item);
                }
              }
            : async () => {
                if (onDblClick) {
                  onDblClick(item);
                }

                return false;
              }
        }
        onClick={handleSignalClick}
      >
        <Box className={styles.leftContent} style={{ cursor: isClicable ? 'pointer' : 'default' }}>
          <ButtonBase>{!isClicable && <Icon.DraggableV2 fill={variables.color.primary.halfMediumGray} />}</ButtonBase>
          <Box className={styles.content}>
            <Box className={styles.contentWrapper}>
              <Typography.Headline title={item.label} display="inline" noWrap component="span" className={styles.name}>
                {item.label}
                {((isCloudShown && !editMode) || !item.updatedAt) && (
                  <Icon.CloudOutline className={styles.attributeIcon} />
                )}
              </Typography.Headline>
              <Box className={styles.row}>
                {item.error ? (
                  <Typography.Caption className={styles.inProgress}>{content.error}</Typography.Caption>
                ) : item.isUploading ? (
                  <Typography.Caption className={styles.inProgress}>{content.inProgress}</Typography.Caption>
                ) : (
                  <Typography.Caption>
                    {item.updatedAt ? (
                      item.signalType === 'foreign' ? (
                        `${format.date(item.updatedAt, 'MM/dd/yy')}`
                      ) : (
                        `${format.number(item.totalSubscribers)} | ${format.date(item.updatedAt, 'MM/dd/yy')} | ${
                          item.type
                        }`
                      )
                    ) : (
                      <div style={{ opacity: '0' }}>Test</div>
                    )}
                  </Typography.Caption>
                )}
              </Box>
            </Box>
            {editMode && (
              <button className={styles.saveBtn} type="button">
                <Icon.Edit className={styles.pencilIcon} />
              </button>
            )}
          </Box>
        </Box>
        {item.id === chosenAttributeId && <Icon.Edit fill={variables.color.primary.mainPurple} />}
      </div>
    );
  },
);

Attribute.displayName = 'Attribute';
