import { Route, RouteProps } from 'react-router-dom';

import { Base } from '@layouts';

import { useStyles } from './Public.styles';

export const Public = ({ children, ...route }: RouteProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Route {...route}>
      <Base className={styles.public}>{children}</Base>
    </Route>
  );
};
