import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    mindset: {
      background: 'rgba(255, 255, 255, 0.9)',
      backdropFilter: 'blur(20px)',
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.secondary.white}`,
      '&[data-isdragging="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.charts.blue}`,
      },
      '&:not(:last-child)': {
        marginBottom: variables.gap.sm,
      },
    },
    header: {
      padding: '1.3rem 2rem 1.2rem',
      height: '8.4rem',
      display: 'flex',
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.secondary.white}`,
      position: 'relative',
      '&[data-collapsed="true"]': {
        borderRadius: variables.borderRadius.sm,
        borderBottom: `${variables.borderWidth.sm} solid transparent`,
      },
      '& > *[data-collapsed="true"]': {
        visibility: 'hidden',
      },
    },
    dragIcon: {
      marginRight: '2rem',
    },
    settings: {
      display: 'grid',
      flexGrow: 1,
    },
    settingsControls: {
      display: 'flex',
    },
    stageSelect: {
      flex: '0 1 16.2rem',
      marginRight: '0.9rem',
    },
    productLineSelect: {
      flex: '0 1 16.2rem',
    },
    toggle: {
      display: 'flex',
      flex: '0 0 auto',
    },
    content: {
      background: 'rgba(255, 255, 255, 0.7)',
      backdropFilter: 'blur(20px)',
      borderBottomLeftRadius: variables.borderRadius.sm,
      borderBottomRightRadius: variables.borderRadius.sm,
      padding: '1.9rem 0.9rem 2rem 2rem',
    },
    mindsetTop: {
      display: 'flex',
      marginBottom: '2.4rem',
    },
    mindsetMatch: {
      flexGrow: 1,
      paddingTop: '1.1rem',
    },
    metConditionCount: {
      color: variables.color.primary.lightPurple,
      textTransform: 'lowercase',
    },
    mindsetStats: {
      display: 'grid',
      textAlign: 'right',
      marginRight: '8rem',
      gridTemplateColumns: 'repeat(2, 11rem)',
      '&[data-collapsed="true"]': {
        display: 'none',
      },
    },
    mindsetStatBox: {
      flex: '0 0 12rem',
    },
    deleteBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    deleteBtn: {
      '&[data-collapsed="true"]': {
        display: 'none',
      },
    },
    conditionsList: {
      marginBottom: variables.gap.lg,
    },
    condition: {
      '&:not(:last-of-type)': {
        marginBottom: variables.gap.sm,
      },
    },
    collapseButton: {
      borderRadius: variables.borderRadius.sm,
      padding: '0.4rem',
      '& svg': {
        transform: 'rotate(180deg)',
        transition: 'transform ease-out 0.3s',
      },
      '&[data-rotate="true"] svg': {
        transform: 'rotate(0deg)',
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    conditionsControl: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    textBefore: {
      marginRight: '1rem',
    },
    textAfter: {
      marginLeft: '1rem',
    },
  }),
);

export const useProductStyles = makeStyles(() =>
  createStyles({
    productInput: {
      '& .MuiInput-root': {
        paddingBottom: '0.3rem',
        paddingLeft: variables.gap.lg,
        letterSpacing: variables.letterSpacing.sm,
      },
    },
    productInputOption: {
      color: variables.color.primary.gray,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.regular,
      lineHeight: variables.lineHeight.md,
      letterSpacing: variables.letterSpacing.sm,
      minHeight: 'auto',
      paddingTop: '0.8rem',
      paddingBottom: '0.8rem',
    },
  }),
);

export const useMetConditionStyles = makeStyles(() =>
  createStyles({
    conditionSelect: {
      maxWidth: '10.5rem',
      '& .MuiInputBase-input': {
        padding: '0.4rem 1rem 0.8rem 1rem',
        fontSize: variables.font.size.lg,
        fontWeight: variables.font.weight.bold,
        lineHeight: variables.lineHeight.lg,
        letterSpacing: variables.letterSpacing.sm,
        color: variables.color.primary.lightPurple,
        textTransform: 'lowercase',
      },
    },
  }),
);
