import { content } from '@content';
import { SelectOption } from '@components';
import { ClientEspDataKey, ClientEspType } from '@modules';
import { array, textTemplate } from '@utils';

import { hourToDailyUpdate } from './ClientEditor.utils';
import {
  ClientEditorEspSalesforceKeys,
  ClientEditorEspSalesforceScheduleField,
  ClientEditorEspSalesforceTextField,
  ClientEditorEspSelligentKeys,
  ClientEditorEspSelligentTextField,
  ClientEditorEspSwitchCleanupKeys,
} from './ClientEditor.types';

const CLIENT_UPDATE_SCHEDULE_HOURS = [12, ...array.range(1, 11)];

export const CLIENT_UPDATE_SCHEDULE_OPTIONS = [
  ...CLIENT_UPDATE_SCHEDULE_HOURS.map((hour) => hourToDailyUpdate(hour, 'AM')),
  ...CLIENT_UPDATE_SCHEDULE_HOURS.map((hour) => hourToDailyUpdate(hour, 'PM')),
];

export const CLIENT_CODE_LENGTH = 4;

export const CLIENT_UNIQUE_ID_MIN = 2;

export const CLIENT_UNIQUE_ID_LIMIT = 255;

export const CLIENT_ESP_TYPE_OPTIONS: SelectOption<ClientEspType>[] = [
  { id: 'salesforce', label: content.salesforce },
  { id: 'selligent', label: content.selligent, disabled: true },
];

const CLIENT_ESP_SALESFORCE_KEYS: ClientEditorEspSalesforceKeys = [
  'sfAuthUrl',
  'sfClientId',
  'sfClientSecret',
  'sftpHost',
  'sftpPassword',
  'sftpScheduleFirstUpdate',
  'sftpScheduleSecondUpdate',
  'sftpUsername',
];

const CLIENT_ESP_SELLIGENT_KEYS: ClientEditorEspSelligentKeys = [
  'selligentInstanceUrl',
  'selligentOrganizationName',
  'selligentPassword',
  'selligentUsername',
];

export const CLIENT_ESP_KEYS: ClientEspDataKey[] = [
  'espType',
  ...CLIENT_ESP_SALESFORCE_KEYS,
  ...CLIENT_ESP_SELLIGENT_KEYS,
];

export const CLIENT_ESP_SWITCH_CLEANUP_KEYS: ClientEditorEspSwitchCleanupKeys = {
  salesforce: CLIENT_ESP_SELLIGENT_KEYS,
  selligent: CLIENT_ESP_SALESFORCE_KEYS,
};

export const CLIENT_ESP_SALESFORCE_SCHEDULE_FIELDS: ClientEditorEspSalesforceScheduleField[] = [
  { key: 'sftpScheduleFirstUpdate', label: 'firstDailyUpdate' },
  { key: 'sftpScheduleSecondUpdate', label: 'secondDailyUpdate' },
];

export const CLIENT_ESP_SALESFORCE_AWS_PRIVATE_SCHEDULE_FIELDS: ClientEditorEspSalesforceScheduleField[] = [
  { key: 'awsImport1thAt', label: 'firstDailyUpdate' },
  { key: 'awsImport2thAt', label: 'secondDailyUpdate' },
];

export const CLIENT_ESP_SALESFORCE_API: ClientEditorEspSalesforceTextField[] = [
  { key: 'sfAuthUrl', label: 'authorizationBaseUrl', type: 'url' },
  { key: 'sfClientId', label: 'businessUnitId' },
  { key: 'sfClientSecret', label: 'businessUnitSecret' },
];

export const CLIENT_ESP_SALESFORCE_FTP: ClientEditorEspSalesforceTextField[] = [
  { key: 'sftpUsername', label: 'ftpUserLogin' },
  { key: 'sftpPassword', label: 'ftpUserPassword' },
  { key: 'sftpHost', label: 'ftpPath' },
];

export const CLIENT_ESP_SELLIGENT_TEXT_FIELDS: ClientEditorEspSelligentTextField[] = [
  { key: 'selligentOrganizationName', label: 'organizationName' },
  { key: 'selligentUsername', label: 'usernameMono' },
  { key: 'selligentPassword', label: 'userPassword' },
  { key: 'selligentInstanceUrl', label: { template: 'sourceDomain', key: 'source', value: 'selligent' }, type: 'url' },
];

export const ENTERPRISE_HINT = textTemplate(content.entitiesContainerDefinition, {
  container: content.enterprise.toLowerCase(),
  entity: content.businessUnit.toLowerCase(),
});

export const CLIENT_DATA_VIS_CLEAN_PERIOD_MIN = 0;

export const CLIENT_DATA_VIS_CLEAN_PERIOD_STEP = 1;

export const CLIENT_DATA_VIS_CLEAN_PERIOD_MAX = 365;
