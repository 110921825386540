import { ForwardedRef, forwardRef } from 'react';
import { Typography } from '@material-ui/core';

import { useLargeTitleStyles } from './Typography.styles';
import { TypographyProps } from './Typography.props';

export const LargeTitle = forwardRef(
  ({ className = '', ...props }: TypographyProps, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const styles = useLargeTitleStyles();

    return <Typography classes={styles} className={className} {...props} {...{ ref }} />;
  },
);

LargeTitle.displayName = 'Typography.LargeTitle';
