import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  getPreDeployment,
  getInMarket,
  getAll,
  getNonArchived,
  getInMarketSortByDate,
} from './ClientDeployments.thunk';
import { ClientDeploymentsSchema } from './ClientDeployments.schema';
import { initialState } from './ClientDeployments.state';

export const reducers = {
  resetAll: ({ all }: ClientDeploymentsSchema): void => {
    Object.assign(all, initialState.all);
  },
  resetNonArchived: ({ nonArchived }: ClientDeploymentsSchema): void => {
    Object.assign(nonArchived, initialState.nonArchived);
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<ClientDeploymentsSchema>): void => {
  builder.addCase(getPreDeployment.pending, ({ preDeployment }, action) => {
    if (preDeployment.status !== 'loading') {
      preDeployment.requestId = action.meta.requestId;
      preDeployment.status = 'loading';
      preDeployment.data = initialState.preDeployment.data;
    }
  });

  builder.addCase(getPreDeployment.fulfilled, ({ preDeployment }, action) => {
    if (preDeployment.status === 'loading' && preDeployment.requestId === action.meta.requestId) {
      preDeployment.status = 'success';
      preDeployment.data = action.payload ?? initialState.preDeployment.data;
    }
  });

  builder.addCase(getPreDeployment.rejected, ({ preDeployment }, action) => {
    if (preDeployment.status === 'loading' && preDeployment.requestId === action.meta.requestId) {
      preDeployment.status = 'error';
      preDeployment.error = action.error;
    }
  });

  builder.addCase(getInMarket.pending, ({ inMarket }, action) => {
    if (inMarket.status !== 'loading') {
      inMarket.requestId = action.meta.requestId;
      inMarket.status = 'loading';
      inMarket.data = initialState.inMarket.data;
    }
  });

  builder.addCase(getInMarket.fulfilled, ({ inMarket }, action) => {
    if (inMarket.status === 'loading' && inMarket.requestId === action.meta.requestId) {
      inMarket.status = 'success';
      inMarket.data = action.payload ?? initialState.inMarket.data;
    }
  });

  builder.addCase(getInMarket.rejected, ({ inMarket }, action) => {
    if (inMarket.status === 'loading' && inMarket.requestId === action.meta.requestId) {
      inMarket.status = 'error';
      inMarket.error = action.error;
    }
  });

  builder.addCase(getAll.pending, ({ all }, action) => {
    if (all.status !== 'loading') {
      all.requestId = action.meta.requestId;
      all.status = 'loading';
      all.data = initialState.all.data;
    }
  });

  builder.addCase(getAll.fulfilled, ({ all }, action) => {
    if (all.status === 'loading' && all.requestId === action.meta.requestId) {
      all.status = 'success';
      all.data = action.payload ?? initialState.all.data;
    }
  });

  builder.addCase(getAll.rejected, ({ all }, action) => {
    if (all.status === 'loading' && all.requestId === action.meta.requestId) {
      all.requestId = '';
      all.status = 'error';
      all.error = action.error;
    }
  });

  builder.addCase(getNonArchived.pending, ({ nonArchived }, action) => {
    if (nonArchived.status !== 'loading') {
      nonArchived.requestId = action.meta.requestId;
      nonArchived.status = 'loading';
      nonArchived.data = initialState.nonArchived.data;
    }
  });

  builder.addCase(getNonArchived.fulfilled, ({ nonArchived }, action) => {
    if (nonArchived.status === 'loading' && nonArchived.requestId === action.meta.requestId) {
      nonArchived.status = 'success';
      nonArchived.data = action.payload ?? initialState.nonArchived.data;
    }
  });

  builder.addCase(getNonArchived.rejected, ({ nonArchived }, action) => {
    if (nonArchived.status === 'loading' && nonArchived.requestId === action.meta.requestId) {
      nonArchived.requestId = '';
      nonArchived.status = 'error';
      nonArchived.error = action.error;
    }
  });

  builder.addCase(getInMarketSortByDate.pending, ({ inMarketSortByDate }, action) => {
    if (inMarketSortByDate.status !== 'loading') {
      inMarketSortByDate.requestId = action.meta.requestId;
      inMarketSortByDate.status = 'loading';
      inMarketSortByDate.data = initialState.inMarketSortByDate.data;
    }
  });

  builder.addCase(getInMarketSortByDate.fulfilled, ({ inMarketSortByDate }, action) => {
    if (inMarketSortByDate.status === 'loading' && inMarketSortByDate.requestId === action.meta.requestId) {
      inMarketSortByDate.status = 'success';
      inMarketSortByDate.data = action.payload ?? initialState.inMarketSortByDate.data;
    }
  });

  builder.addCase(getInMarketSortByDate.rejected, ({ inMarketSortByDate }, action) => {
    if (inMarketSortByDate.status === 'loading' && inMarketSortByDate.requestId === action.meta.requestId) {
      inMarketSortByDate.requestId = '';
      inMarketSortByDate.status = 'error';
      inMarketSortByDate.error = action.error;
    }
  });
};
