import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@material-ui/core';

import { Alert, Icon, Link, Table, Typography } from '@components';
import { content } from '@content';
import { useAppDispatch } from '@store';
import { enterprise, Role, roles, UserItemPayload, users, UserStatus, UserUpdatePayload } from '@modules';
import { format, isRoleAdmin, textTemplate, useLoader, useWebTitle } from '@utils';
import { variables } from '@styles';

import { UserPage } from './UserPage';

import { useStyles } from './AdminUsers.styles';

/**
 AdminUsers route component.
 @returns {JSX.Element}
 */

export const AdminUsers = (): JSX.Element => {
  const styles = useStyles();

  const [page, setPage] = useState(1);
  const [userPage, setUserPage] = useState(false);
  const [showUserAdded, setShowUserAdded] = useState<boolean>(false);
  const [rolesList, setRolesList] = useState<Role[]>([]);

  const isAdmin = isRoleAdmin();

  const dispatch = useAppDispatch();

  const listData = users.useListData();
  const listMeta = users.useListMeta();
  const entityData = users.useEntityData();
  const rolesMeta = roles.useAllMeta();
  const rolesData = roles.useAllData();
  const enterprisesData = enterprise.useFullListData();
  const enterprisesMeta = enterprise.useListMeta();

  const webTitleDetail = useMemo(
    () =>
      userPage &&
      (entityData
        ? textTemplate(content.username, entityData)
        : textTemplate(content.addValue, { value: content.user })),
    [userPage, entityData],
  );

  useWebTitle(!webTitleDetail && content.settings, content.users, webTitleDetail);

  useLoader(listMeta, rolesMeta, enterprisesMeta);

  const fetchSublists = useCallback(() => {
    dispatch(roles.thunk.getAll());
  }, [dispatch]);

  const fetchList = useCallback(() => {
    dispatch(
      users.thunk.getAll({
        page,
        itemsPerPage: 15,
      }),
    );
  }, [dispatch, page]);

  const resetEntity = useCallback(() => {
    dispatch(users.actions.resetEntity());
  }, [dispatch]);

  const findEntity = useCallback(
    (id: number) => {
      dispatch(users.actions.findById(id));
    },
    [dispatch],
  );

  const updateEntity = useCallback(
    async (id: number, payload: UserUpdatePayload): Promise<boolean> => {
      const result = await dispatch(users.thunk.update({ id, user: payload }));
      return users.thunk.update.fulfilled.match(result);
    },
    [dispatch],
  );

  const createEntity = useCallback(
    async (payload: UserUpdatePayload) => {
      const result = await dispatch(users.thunk.create(payload));
      return users.thunk.create.fulfilled.match(result);
    },
    [dispatch],
  );

  const handleAddClick = useCallback(() => {
    resetEntity();

    setUserPage(true);
  }, [resetEntity, setUserPage]);

  const handleEditClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      findEntity(+(event.currentTarget.dataset.id as string));

      setUserPage(true);
    },
    [findEntity, setUserPage],
  );

  const handleUserPageClose = useCallback(() => {
    setUserPage(false);

    resetEntity();
  }, [setUserPage, resetEntity]);

  const handleUserPageSubmit = useCallback(
    async (values: UserItemPayload) => {
      const payload: UserUpdatePayload = {
        email: values.email,
        firstName: values.firstname,
        lastName: values.lastname,
        businessUnits: values.businessUnits,
        enterprises: values.enterprises,
        role: values.role && { id: values.role.id, name: values.role.name },
        status: values.status ? values.status : UserStatus.active,
      };

      let succeed;

      if (entityData) {
        payload.status = values.status;
        succeed = await updateEntity(entityData.id, payload);
      } else {
        succeed = await createEntity(payload);
        setShowUserAdded(true);
      }

      if (succeed) {
        handleUserPageClose();
        fetchList();
      }
    },
    [updateEntity, createEntity, handleUserPageClose, fetchList, entityData],
  );

  useEffect(() => {
    fetchSublists();
    fetchList();
  }, [fetchSublists, fetchList]);

  // filtering roles which available for the admin user
  useEffect(() => {
    if (isAdmin) {
      setRolesList(rolesData.filter((role) => role.name !== 'ROLE_SUPER_ADMIN'));
    } else {
      setRolesList(rolesData);
    }
  }, [isAdmin, rolesData]);

  const handleCloseAlert = () => setShowUserAdded(false);

  return (
    <Box className={styles.adminUsers}>
      {!userPage && (
        <>
          <Box className={styles.header}>
            <Box>
              <Typography.LargeTitle className={styles.topTitle}>{content.users}</Typography.LargeTitle>
              <Typography.Body>{content.manageYourUsers}</Typography.Body>
            </Box>
            <Box className={styles.headerRight}>
              <Button startIcon={<Icon.AddCircleOutline />} variant="outlined" color="primary" onClick={handleAddClick}>
                {textTemplate(content.addValue, { value: content.user })}
              </Button>
            </Box>
          </Box>
          {showUserAdded && (
            <Alert
              className={styles.alert}
              onClose={handleCloseAlert}
              type="success"
              message={content.userSuccessfullyAdded}
              align="left"
            />
          )}
          <Table
            uiType="tertiary"
            autofit
            columns={[
              {
                id: 'name',
                title: content.name,
                className: styles.nameColumn,
              },
              {
                id: 'mail',
                title: content.userEmail,
                className: styles.mailColumn,
              },
              {
                id: 'id',
                title: content.id,
                className: styles.idColumn,
              },
              {
                id: 'role',
                title: content.role,
                className: styles.roleColumn,
              },
              {
                id: 'date',
                title: content.dateAdded,
                className: styles.dateColumn,
              },
              {
                id: 'stat',
                title: content.state,
                className: styles.statColumn,
              },
              {
                id: 'edit',
                className: styles.editColumn,
                align: 'right',
              },
            ]}
            records={listData.items.map((user) => ({
              name: textTemplate(content.username, user),
              mail: (
                <Link
                  isExternal
                  uiType="pure"
                  size="small"
                  label={user.email}
                  target="blank"
                  to={`mailto:${user.email}`}
                />
              ),
              stat: user.status && user.status === 'active' ? content.enabled : content.archived,
              id: `${user.id}`,
              date: format.date(user.createdAt, 'PP'),
              role: user.role && roles.utils.nameToLabel(user.role),
              edit: (
                <Button
                  startIcon={<Icon.Edit fill={variables.color.primary.darkestGray} />}
                  variant="text"
                  data-id={user.id}
                  onClick={handleEditClick}
                >
                  {content.edit}
                </Button>
              ),
            }))}
            page={page}
            pages={listData.pageCount}
            onPageChange={setPage}
          />
        </>
      )}
      {userPage && (
        <UserPage
          entity={entityData}
          enterprises={enterprisesData}
          roles={rolesList}
          onClose={handleUserPageClose}
          onSubmit={handleUserPageSubmit}
        />
      )}
    </Box>
  );
};
