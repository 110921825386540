import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ListItem component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      '&.MuiListItem-draggable': {
        '& $root': {
          padding: theme.spacing(2, 1.5),
          cursor: 'grab',
        },
      },

      '&:hover': {
        '& $secondaryLabelRoot': {
          color: variables.color.primary.mainPurple,
        },

        '& $secondaryTextRoot': {
          color: variables.color.primary.mainPurple,
        },

        '& $iconListItem': {
          '& svg path': {
            stroke: variables.color.primary.mainPurple,
          },
        },
      },

      '&&': {
        '&.dragging-source': {
          '& $listItem': {
            backgroundColor: 'transparent',
            border: `${variables.borderWidth.lg} solid ${variables.color.primary.lightGray}`,
          },

          '& $iconListItem': {
            '& svg': {
              fill: 'transparent',
            },
          },

          '& $primaryText': {
            color: 'transparent',
          },

          '& $secondaryLabelRoot': {
            color: 'transparent',
          },

          '& $secondaryTextRoot': {
            color: 'transparent',
          },
        },

        '&.dragging': {
          '& $listItem': {
            opacity: 1,
            backgroundColor: 'transparent',
            border: `${variables.borderWidth.lg} solid ${variables.color.primary.mainPurple}`,
          },

          '& $primaryText': {
            color: variables.color.primary.darkestGray,
          },

          '& $secondaryLabelRoot': {
            color: variables.color.primary.mediumGray,
          },

          '& $secondaryTextRoot': {
            color: variables.color.primary.mediumGray,
          },
        },
      },
    },
    listItem: {
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.03)',
      padding: theme.spacing(2, 1.5),

      '&$selected, &$selected:hover': {
        borderColor: variables.color.primary.mainPurple,
        backgroundColor: variables.color.primary.mainPurple,

        '& $primaryText': {
          color: variables.color.primary.white,
        },

        '& $secondaryText': {
          color: 'rgba(255, 255, 255, 0.6)',
        },

        '& + $secondaryActionRoot': {
          '& $secondaryTextRoot': {
            color: 'rgba(255, 255, 255, 0.6)',
            marginRight: theme.spacing(0.5),
          },
        },
      },

      '&:hover': {
        borderColor: variables.color.primary.lightPurple,
        backgroundColor: variables.color.primary.lighterPurple,

        '& $iconRoot': {
          fill: variables.color.primary.mainPurple,
        },

        '& $primaryText': {
          color: variables.color.primary.mainPurple,
        },

        '& $secondaryText': {
          color: variables.color.primary.mainPurple,
        },
      },
    },
    selected: {},
    iconListItem: {
      minWidth: 'auto',
      marginRight: theme.spacing(1),
    },
    iconChevronRight: {
      stroke: variables.color.primary.white,
    },
    listItemText: {
      margin: 0,
    },
    multilineText: {
      '& .MuiListItemText-primary': {
        marginBottom: theme.spacing(0.5),
      },
    },
    primaryText: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      color: variables.color.primary.darkestGray,
      lineHeight: variables.lineHeight.lg,
      textTransform: 'capitalize',
      width: '55%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    secondaryText: {
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.medium,
      color: variables.color.primary.mediumGray,
      lineHeight: variables.lineHeight.xs,
    },
    secondaryActionRoot: {
      display: 'flex',
      flexDirection: 'column',
      pointerEvents: 'none',
      textAlign: 'right',
      width: '35%',
    },
    secondaryLabelRoot: {
      fontWeight: variables.font.weight.medium,
      fontSize: variables.font.size.xs,
      color: variables.color.primary.mediumGray,
      lineHeight: variables.lineHeight.xs,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    secondaryTextRoot: {
      fontWeight: variables.font.weight.medium,
      fontSize: variables.font.size.xs,
      color: variables.color.primary.mediumGray,
      lineHeight: variables.lineHeight.xs,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
);
