import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2.8rem',
    },

    tab: {
      backgroundClip: 'padding-box',
      border: '0.4rem solid rgba(255, 255, 255, 0.8)',
      borderRadius: '0.4rem 0.4rem 0 0',
      height: '7.3rem',
      padding: '0.6rem 1.8rem',
      minWidth: '31.8rem',
      maxWidth: '32rem',
      borderBottom: 'none',

      '&.Mui-selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },

      '&:not(:last-child)': {
        marginRight: variables.gap.md,
      },
    },

    tabLabelBox: {
      display: 'flex',
      alignItems: 'center',
    },

    tabMarkSuccess: {
      fill: variables.color.primary.mainPurple,
    },

    tabLabel: {
      textTransform: 'none',
      marginLeft: variables.gap.lg,
    },
  }),
);
