import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * SignalLibrary component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    panel: {
      '&&': {
        padding: theme.spacing(2, 0, 0, 2),
      },
    },

    title: {
      fontSize: variables.font.size.xl,
      fontWeight: variables.font.weight.extraBold,
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },

    searchField: {
      backgroundColor: variables.color.primary.white,
      display: 'flex',
      marginBottom: theme.spacing(2),
      width: '19.1rem',
    },

    dataAttributeSignal: {
      width: '18.9rem !important',
    },
  }),
);
