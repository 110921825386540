import { forwardRef } from 'react';
import { Switch, Grid } from '@material-ui/core';

import { Typography } from '@components';
import { useSwitchStyles, useStyles } from './Toggle.styles';
import { ToggleProps } from './Toggle.props';

export const Toggle = forwardRef(({ labelLeft, labelRight, className, ...rest }: ToggleProps, ref): JSX.Element => {
  const styles = useStyles();
  const switchClasses = useSwitchStyles();

  return (
    <Grid component="label" container alignItems="center" spacing={0} className={className}>
      <Grid item>
        <Typography.Caption className={styles.caption}>{labelLeft}</Typography.Caption>
      </Grid>
      <Grid item>
        <Switch inputRef={ref} classes={switchClasses} {...rest} />
      </Grid>
      <Grid item>
        <Typography.Caption className={styles.caption}>{labelRight}</Typography.Caption>
      </Grid>
    </Grid>
  );
});

Toggle.displayName = 'Toggle';
