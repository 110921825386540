import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { MetaSchema } from '@modules';

import * as thunk from './Mailer.thunk';
import { initialState } from './Mailer.state';
import { MailerAuthData } from './Mailer.schema';
import { reducers, extraReducers } from './Mailer.reducer';

export * from './Mailer.schema';

const slice = createSlice({
  name: 'mailer',
  initialState,
  reducers,
  extraReducers,
});

export const mailer = {
  thunk,
  slice,
  actions: slice.actions,
  useAuthMeta: (): MetaSchema =>
    useAppSelector(
      ({
        mailer: {
          auth: { data, ...meta },
        },
      }) => meta,
    ),
  useAuthData: (): MailerAuthData => useAppSelector((root) => root.mailer.auth.data),
  useEntityMeta: (): MetaSchema =>
    useAppSelector(
      ({
        mailer: {
          entity: { data, ...meta },
        },
      }) => meta,
    ),
};
