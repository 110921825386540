type AppEnv = 'dev' | 'stage' | 'prod';

export interface ProcessEnv extends NodeJS.ProcessEnv {
  REACT_APP_ENV: AppEnv;
}

const { REACT_APP_ENV } = process.env as ProcessEnv;

export const isApp = (env: AppEnv): boolean => REACT_APP_ENV === env;

export const isAppNot = (env: AppEnv): boolean => REACT_APP_ENV !== env;
