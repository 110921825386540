import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector as use } from '@store';
import { MetaSchema } from '@modules';

import * as thunk from './RecommendedModules.thunk';
import { initialState } from './RecommendedModules.state';
import { reducers, extraReducers } from './RecommendedModules.reducer';
import { RecommendedModulesData } from './RecommendedModules.schema';

export * from './RecommendedModules.schema';

const name = 'recommendedModules';

export const recommendedModules = {
  thunk,
  slice: createSlice({
    name,
    initialState,
    reducers,
    extraReducers,
  }),
  useMeta: (): MetaSchema => use(({ [name]: { data, ...meta } }) => meta),
  useData: (): RecommendedModulesData => use(({ [name]: { data } }) => data),
};
