import { PerformingContentItemModuleData } from '@modules';
import { content } from '@content';
import { format } from '@utils';

/**
 ModulePopularPopover utils.
 @returns {variable}
 */

export const getModuleInfo = (item: PerformingContentItemModuleData) => [
  {
    label: content.product,
    value: item.topic,
  },
  {
    label: content.cohort,
    value: item.cohort,
  },
  {
    label: content.deployment,
    value: item.deploymentName,
  },
  {
    label: content.totalImpressions,
    value: format.number(item.totalImpressions),
  },
  {
    label: content.moduleTag,
    value: item.tag,
  },
];
