import { ActionReducerMapBuilder, SliceCaseReducers } from '@reduxjs/toolkit';

import {
  search,
  fileUpload,
  searchOptions,
  create,
  updateComposite,
  createComposite,
  update,
} from './SignalLibrary.thunk';
import { SignalLibrarySchema } from './SignalLibrary.schema';
import { initialState } from './SignalLibrary.state';

export const reducers: SliceCaseReducers<SignalLibrarySchema> = {
  setFieldsData: ({ fields }, { payload }) => {
    fields.data = payload;
  },
  resetOptions: ({ options }) => {
    options.data = initialState.options.data;
  },
  setSignalsData: ({ list }, { payload: { signalId, data } }) => {
    list.data = {
      ...list.data,
      items: list.data.items.map((item) => (item.id === signalId ? { ...item, ...data } : item)),
    };
  },
  resetListData: ({ list }) => {
    list.data = initialState.list.data;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<SignalLibrarySchema>): void => {
  builder.addCase(search.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
    }
  });

  builder.addCase(search.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      if (action.payload) {
        list.data = {
          ...action.payload,
          items: action.payload.items.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          }),
        };
      }
    }
  });

  builder.addCase(search.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(searchOptions.pending, ({ options }, action) => {
    if (options.status !== 'loading') {
      options.requestId = action.meta.requestId;
      options.status = 'loading';
    }
  });

  builder.addCase(searchOptions.fulfilled, ({ options }, action) => {
    if (options.status === 'loading' && options.requestId === action.meta.requestId) {
      options.requestId = '';
      options.status = 'success';
      if (action.payload) {
        options.data = action.payload;
      }
    }
  });

  builder.addCase(searchOptions.rejected, ({ options }, action) => {
    if (options.status === 'loading' && options.requestId === action.meta.requestId) {
      options.requestId = '';
      options.status = 'error';
      options.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(fileUpload.pending, ({ fields }, action) => {
    if (fields.status !== 'loading') {
      fields.requestId = action.meta.requestId;
      fields.status = 'loading';
    }
  });

  builder.addCase(fileUpload.fulfilled, ({ fields }, action) => {
    if (fields.status === 'loading' && fields.requestId === action.meta.requestId) {
      fields.requestId = '';
      fields.status = 'success';
      if (action.payload) {
        fields.data = action.payload;
      }
    }
  });

  builder.addCase(fileUpload.rejected, ({ fields }, action) => {
    if (fields.status === 'loading' && fields.requestId === action.meta.requestId) {
      fields.requestId = '';
      fields.status = 'error';
      fields.data = null;
    }
  });

  builder.addCase(create.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      list.error = undefined;
    }
  });

  builder.addCase(create.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      if (action.payload) {
        list.data = { ...list.data, items: [...list.data.items, action.payload] };
      }
    }
  });

  builder.addCase(create.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(update.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      list.error = undefined;
    }
  });

  builder.addCase(update.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      const signal = action.payload;
      if (signal) {
        list.data = { ...list.data, items: list.data.items.map((item) => (item.id === signal.id ? signal : item)) };
      }
    }
  });

  builder.addCase(update.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(createComposite.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      list.error = undefined;
    }
  });

  builder.addCase(createComposite.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      if (action.payload) {
        list.data = { ...list.data, items: [...list.data.items, action.payload] };
      }
    }
  });

  builder.addCase(createComposite.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(updateComposite.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      list.error = undefined;
    }
  });

  builder.addCase(updateComposite.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      const signal = action.payload;
      if (signal) {
        list.data = { ...list.data, items: list.data.items.map((item) => (item.id === signal.id ? signal : item)) };
      }
    }
  });

  builder.addCase(updateComposite.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });
};
