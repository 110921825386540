import { createSlice } from '@reduxjs/toolkit';

import { MetaSchema } from '@modules';
import { useAppSelector as useRoot } from '@store';
import * as thunk from './ClientPrograms.thunk';
import { initialState } from './ClientPrograms.state';
import { reducers, extraReducers } from './ClientPrograms.reducer';
import { Program, ClientProgramsListData, ProgramItem } from './ClientPrograms.schema';

export * from './ClientPrograms.schema';

export const businessUnitPrograms = {
  thunk,
  slice: createSlice({
    name: 'businessUnitPrograms',
    initialState,
    reducers,
    extraReducers,
  }),
  useAllMeta: (): MetaSchema =>
    useRoot(
      ({
        businessUnitPrograms: {
          all: { data, ...meta },
        },
      }) => meta,
    ),
  useAllData: (): Program[] => useRoot((root) => root.businessUnitPrograms.all.data),
  useListMeta: (): MetaSchema =>
    useRoot(
      ({
        businessUnitPrograms: {
          list: { data, ...meta },
        },
      }) => meta,
    ),
  useListData: (): ClientProgramsListData => useRoot((root) => root.businessUnitPrograms.list.data),
  useEntity: (): Program | undefined => useRoot((root) => root.businessUnitPrograms.entity),
  useItemMeta: (): MetaSchema =>
    useRoot(
      ({
        businessUnitPrograms: {
          item: { data, ...meta },
        },
      }) => meta,
    ),
  useItemData: (): ProgramItem =>
    useRoot(
      ({
        businessUnitPrograms: {
          item: { data },
        },
      }) => data,
    ),
};
