import { SVGProps } from 'react';

import { variables } from '@styles';

export const Logout = ({
  className = '',
  width = 24,
  height = 24,
  color = variables.color.primary.mediumPurple,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.875 5.25C4.57663 5.25 4.29048 5.36853 4.0795 5.5795C3.86853 5.79048 3.75 6.07663 3.75 6.375V17.625C3.75 17.9234 3.86853 18.2095 4.0795 18.4205C4.29048 18.6315 4.57663 18.75 4.875 18.75H12.375C12.6734 18.75 12.9595 18.6315 13.1705 18.4205C13.3815 18.2095 13.5 17.9234 13.5 17.625V15.75C13.5 15.3358 13.8358 15 14.25 15C14.6642 15 15 15.3358 15 15.75V17.625C15 18.3212 14.7234 18.9889 14.2312 19.4812C13.7389 19.9734 13.0712 20.25 12.375 20.25H4.875C4.17881 20.25 3.51113 19.9734 3.01884 19.4812C2.52656 18.9889 2.25 18.3212 2.25 17.625V6.375C2.25 5.67881 2.52656 5.01113 3.01884 4.51884C3.51113 4.02656 4.17881 3.75 4.875 3.75H12C12.7042 3.75 13.4264 4.02832 13.9759 4.45883C14.5225 4.88709 15 5.55241 15 6.375V8.25C15 8.66421 14.6642 9 14.25 9C13.8358 9 13.5 8.66421 13.5 8.25V6.375C13.5 6.16213 13.3703 5.88994 13.0507 5.63957C12.7341 5.39144 12.3312 5.25 12 5.25H4.875ZM16.7197 7.71967C17.0126 7.42678 17.4874 7.42678 17.7803 7.71967L21.5303 11.4697C21.8232 11.7626 21.8232 12.2374 21.5303 12.5303L17.7803 16.2803C17.4874 16.5732 17.0126 16.5732 16.7197 16.2803C16.4268 15.9874 16.4268 15.5126 16.7197 15.2197L19.1893 12.75H8.25C7.83579 12.75 7.5 12.4142 7.5 12C7.5 11.5858 7.83579 11.25 8.25 11.25H19.1893L16.7197 8.78033C16.4268 8.48744 16.4268 8.01256 16.7197 7.71967Z"
      fill={color}
    />
  </svg>
);
