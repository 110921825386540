import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const legend = {
  display: 'flex',
  'flex-direction': 'column',
};

const item = {
  display: 'flex',
  alignItems: 'center',
  height: '2.4rem',
};

const label = {
  color: variables.color.primary.gray,
  margin: `0 5.4rem 0 ${variables.gap.sm}`,
  'white-space': 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const count = {
  width: '4.1rem',
  minWidth: '4.1rem',
  maxWidth: '4.1rem',
  marginLeft: 'auto',
  'text-align': 'end',
};

export const useStyles = makeStyles(() =>
  createStyles({
    legend,
    item,
    label,
    count,
  }),
);
