import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

const cbx = {
  margin: '1.8rem 0 0',
  '& > *:nth-child(2)': { marginLeft: variables.gap.sm },
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      alignSelf: 'flex-start',
      height: '40px',
    },

    title: {
      maxWidth: '34rem',
      whiteSpace: 'pre-wrap',
      margin: theme.spacing(3, 0),
    },

    description: {
      marginBottom: theme.spacing(2),
    },

    link: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.darkestGray,
      textDecoration: 'none !important',
      letterSpacing: 0,
    },

    bottomContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    label: {
      marginBottom: theme.spacing(1),
    },

    txt: {
      '&:not(:first-child)': {
        marginTop: '4rem',
      },
    },

    pwd: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },

    cbx,

    alert: {
      marginBottom: theme.spacing(2),
    },

    buttons: {
      display: 'flex',
      justifyContent: ' space-between',
    },

    button: {
      '&.MuiButton-text': {
        textTransform: 'none',
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.bold,
        color: variables.color.primary.darkestGray,
        padding: theme.spacing(0.75, 0.375),
      },
      '&.MuiButton-containedPrimary': {
        padding: theme.spacing(1, 1.5),
      },
    },
  }),
);
