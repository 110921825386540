import { memo } from 'react';
import { Box } from '@material-ui/core';

import { Typography } from '@components';

import { TableLegendProps } from './TableLegend.props';
import { useStyles } from './TableLegend.styles';

/**
 * Table Legend component.
 * Gets an array of legend items and outputs Legends.
 * @returns {JSX}
 */

export const TableLegend = memo(({ className = '', items }: TableLegendProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={`${styles.legend} ${className}`}>
      {items.map((item) => (
        <Box key={item.label} className={styles.item} title={item.label}>
          <Box className={`${styles.badge} ${styles[item.color]}`} data-highlighted={item.highlighted} />
          <Typography.Caption data-variant="label" className={styles.label}>
            {item.label}
          </Typography.Caption>
          <Typography.Tag data-variant="count" className={styles.count}>
            {`(${item.count})`}
          </Typography.Tag>
        </Box>
      ))}
    </Box>
  );
});

TableLegend.displayName = 'Table Legend';
