import { useCallback, useMemo, MouseEvent } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';

import { Alert, Table, Icon, Typography } from '@components';

import { textTemplate } from '@utils';
import { variables } from '@styles';
import { content } from '@content';

import { REC_EXTRA_POSTFIX, REC_FILLER_POSTFIX } from './List.const';
import { Col, RecNtps, RecEdit, Rec } from './List.types';
import { useStyles } from './List.styles';
import { ListProps } from './List.props';

export const List = ({
  alert = false,
  items,
  onAlertClose,
  onClientAdd,
  onClientEdit,
  onEnterpriseAdd,
  onEnterpriseEdit,
  onArchivedView,
}: ListProps): JSX.Element => {
  const styles = useStyles();

  const handleEnterpriseEditClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { ntpsId } = event.currentTarget.dataset;

      if (ntpsId) {
        onEnterpriseEdit(+ntpsId);
      }
    },
    [onEnterpriseEdit],
  );

  const handleClientEditClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { ntpsId, id } = event.currentTarget.dataset;

      if (ntpsId && id) {
        onClientEdit(+ntpsId, +id);
      }
    },
    [onClientEdit],
  );

  const handleClientAddClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { ntpsId } = event.currentTarget.dataset;

      if (ntpsId) {
        onClientAdd(+ntpsId);
      }
    },
    [onClientAdd],
  );

  const cols = useMemo<Col[]>(
    () => [
      {
        id: 'ntps',
        title: content.enterpriseGroup,
        className: styles.ntps,
        render: (val) => {
          const ntps = val as RecNtps;

          return (
            <Box className={styles.ntpsValue}>
              {ntps?.name && <Typography.Headline className={styles.ntpsName}>{ntps.name}</Typography.Headline>}
              {ntps?.id && (
                <IconButton
                  className={styles.ntpsEdit}
                  size="small"
                  data-ntps-id={ntps.id}
                  onClick={handleEnterpriseEditClick}
                >
                  <Icon.Edit fill={variables.color.primary.darkestGray} />
                </IconButton>
              )}
            </Box>
          );
        },
      },
      { id: 'idty', title: content.id, width: '6%' },
      {
        id: 'name',
        title: textTemplate(content.entityName, { entity: content.businessUnit }),
        render: (val, recId) =>
          recId.endsWith(REC_FILLER_POSTFIX) ? (
            <Typography.Body className={styles.filler}>
              {textTemplate(content.noEntityAddedIntoContainer, {
                entity: content.businessUnit.toLowerCase(),
                container: content.enterprise.toLowerCase(),
              })}
            </Typography.Body>
          ) : (
            val
          ),
      },
      { id: 'code', title: content.uniqueCode, width: '11%' },
      { id: 'espt', title: content.esp, width: '13%' },
      {
        id: 'edit',
        title: content.state,
        width: '16.2rem',
        className: styles.edit,
        render: (val) => {
          const edit = val as RecEdit;

          return edit?.subId ? (
            <>
              {edit.archived ? content.archived : content.enabled}
              <Button
                className={styles.editClient}
                startIcon={<Icon.Edit fill={variables.color.primary.darkestGray} />}
                variant="text"
                size="small"
                data-ntps-id={edit.id}
                data-id={edit.subId}
                onClick={handleClientEditClick}
              >
                {content.edit}
              </Button>
            </>
          ) : edit?.id ? (
            <Button
              className={styles.addClient}
              startIcon={<Icon.AddCircleOutline fill={variables.color.primary.darkestGray} />}
              variant="outlined"
              data-ntps-id={edit.id}
              onClick={handleClientAddClick}
            >
              {textTemplate(content.addValue, { value: content.businessUnit })}
            </Button>
          ) : null;
        },
      },
    ],
    [handleEnterpriseEditClick, handleClientEditClick, handleClientAddClick, styles],
  );

  const recs = useMemo<Rec[]>(
    () =>
      items.reduce<Rec[]>(
        (acc, item) => [
          ...acc,
          ...(item.businessUnits && item.businessUnits.length > 0
            ? (item.businessUnits.map((subitem, idx) => ({
                id: `${subitem.id}`,
                ntps: {
                  ...(idx === 0 ? { id: item.id, name: item.name } : null),
                  united: true,
                } as RecNtps,
                idty: subitem.id,
                name: subitem.name,
                code: subitem.code.toUpperCase(),
                espt: content[subitem.espType],
                edit: { id: item.id, subId: subitem.id, archived: !!subitem.isArchived } as RecEdit,
              })) as typeof acc)
            : ([
                {
                  id: `${item.id}${REC_FILLER_POSTFIX}`,
                  ntps: { id: item.id, name: item.name },
                },
              ] as typeof acc)),
          {
            id: `${item.id}${REC_EXTRA_POSTFIX}`,
            edit: { id: item.id } as RecEdit,
          } as (typeof acc)[0],
        ],
        [],
      ),
    [items],
  );

  return (
    <>
      <Box className={styles.header}>
        <Box>
          <Typography.LargeTitle>{content.enterpriseAndBusinessUnits}</Typography.LargeTitle>
          <Typography.Body>{content.manageYourBusinessUnits}</Typography.Body>
        </Box>
        <Box className={styles.actions}>
          <Button
            className={styles.viewArchived}
            startIcon={<Icon.Archive />}
            variant="text"
            disabled={!onArchivedView}
            onClick={onArchivedView}
          >
            <Typography.Headline>{textTemplate(content.viewValue, { value: content.archived })}</Typography.Headline>
          </Button>
          <Button startIcon={<Icon.AddCircleOutline />} variant="outlined" color="primary" onClick={onEnterpriseAdd}>
            {textTemplate(content.addValue, { value: content.enterprise })}
          </Button>
        </Box>
      </Box>
      {alert && (
        <Alert
          className={styles.alert}
          type="success"
          align="left"
          message={content.yourBusinessUnitRestored}
          onClose={onAlertClose}
        />
      )}
      <Table uiType="tertiary" autofit measures={{ recordHeight: '' }} columns={cols} records={recs} />
    </>
  );
};
