import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { MetaSchema } from '@modules';

import * as thunk from './Analytics.thunk';
import { initialState } from './Analytics.state';
import { reducers, extraReducers } from './Analytics.reducer';
import {
  NumberModulesPerPositionDataSchema,
  CtirPerPositionDataSchema,
  PositionVolumeDataSchema,
  ImpressionByCohortDataSchema,
  ImpressionByProductDataSchema,
  ImpressionByOverallDataSchema,
  CommonCtirByDeploymentDataSchema,
  SummaryPerformanceDataSchema,
  ModulePerformanceDataSchema,
  ModuleVolumeDataSchema,
  ClickToOpenReportDataSchema,
  OverallMetricsDataSchema,
  ClickRateDataSchema,
  OptOutRateDataSchema,
  OpenRateDataSchema,
  AmriReportDataSchema,
  BenchmarkingReportDataSchema,
  PerformingContentDataSchema,
} from './Analytics.schema';

export * from './Analytics.schema';

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers,
  extraReducers,
});

export const analytics = {
  thunk,
  slice,
  actions: slice.actions,
  useNumberModulesPerPositionMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          numberModulesPerPosition: { data, ...meta },
        },
      }) => meta,
    ),
  useNumberModulesPerPositionData: (): NumberModulesPerPositionDataSchema =>
    useAppSelector(
      ({
        analytics: {
          numberModulesPerPosition: { data },
        },
      }) => data,
    ),
  useCtirPerPositionMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          ctirPerPosition: { data, ...meta },
        },
      }) => meta,
    ),
  useCtirPerPositionData: (): CtirPerPositionDataSchema =>
    useAppSelector(
      ({
        analytics: {
          ctirPerPosition: { data },
        },
      }) => data,
    ),
  usePositionVolumeMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          positionVolume: { data, ...meta },
        },
      }) => meta,
    ),
  usePositionVolumeData: (): PositionVolumeDataSchema =>
    useAppSelector(
      ({
        analytics: {
          positionVolume: { data },
        },
      }) => data,
    ),
  useImpressionByCohortMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          impressionByCohort: { data, ...meta },
        },
      }) => meta,
    ),
  useImpressionByCohortData: (): ImpressionByCohortDataSchema =>
    useAppSelector(
      ({
        analytics: {
          impressionByCohort: { data },
        },
      }) => data,
    ),
  useImpressionByProductMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          impressionByProduct: { data, ...meta },
        },
      }) => meta,
    ),
  useImpressionByProductData: (): ImpressionByProductDataSchema =>
    useAppSelector(
      ({
        analytics: {
          impressionByProduct: { data },
        },
      }) => data,
    ),
  useImpressionByOverallMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          impressionByOverall: { data, ...meta },
        },
      }) => meta,
    ),
  useImpressionByOverallData: (): ImpressionByOverallDataSchema =>
    useAppSelector(
      ({
        analytics: {
          impressionByOverall: { data },
        },
      }) => data,
    ),
  useCommonCtirByDeploymentMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          commonCtirByDeployment: { data, ...meta },
        },
      }) => meta,
    ),
  useCommonCtirByDeploymentData: (): CommonCtirByDeploymentDataSchema[] =>
    useAppSelector(
      ({
        analytics: {
          commonCtirByDeployment: { data },
        },
      }) => data,
    ),
  useSummaryPerformanceMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          summaryPerformance: { data, ...meta },
        },
      }) => meta,
    ),
  useSummaryPerformanceData: (): SummaryPerformanceDataSchema =>
    useAppSelector(
      ({
        analytics: {
          summaryPerformance: { data },
        },
      }) => data,
    ),
  useModulePerformanceMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          modulePerformanceOverTime: { data, ...meta },
        },
      }) => meta,
    ),
  useModulePerformanceData: (): ModulePerformanceDataSchema =>
    useAppSelector(
      ({
        analytics: {
          modulePerformanceOverTime: { data },
        },
      }) => data,
    ),
  useModuleVolumeMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          moduleVolumeOverTime: { data, ...meta },
        },
      }) => meta,
    ),
  useModuleVolumeData: (): ModuleVolumeDataSchema =>
    useAppSelector(
      ({
        analytics: {
          moduleVolumeOverTime: { data },
        },
      }) => data,
    ),
  useClickToOpenReportMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          clickToOpenReport: { data, ...meta },
        },
      }) => meta,
    ),
  useClickToOpenReportData: (): ClickToOpenReportDataSchema =>
    useAppSelector(
      ({
        analytics: {
          clickToOpenReport: { data },
        },
      }) => data,
    ),
  useOverallMetricsMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          overallMetrics: { data, ...meta },
        },
      }) => meta,
    ),
  useOverallMetricsData: (): OverallMetricsDataSchema =>
    useAppSelector(
      ({
        analytics: {
          overallMetrics: { data },
        },
      }) => data,
    ),
  useClickRateMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          clickRate: { data, ...meta },
        },
      }) => meta,
    ),
  useClickRateData: (): ClickRateDataSchema =>
    useAppSelector(
      ({
        analytics: {
          clickRate: { data },
        },
      }) => data,
    ),
  useOptOutRateMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          optOutRate: { data, ...meta },
        },
      }) => meta,
    ),
  useOptOutRateData: (): OptOutRateDataSchema =>
    useAppSelector(
      ({
        analytics: {
          optOutRate: { data },
        },
      }) => data,
    ),
  useOpenRateMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          openRate: { data, ...meta },
        },
      }) => meta,
    ),
  useOpenRateData: (): OpenRateDataSchema =>
    useAppSelector(
      ({
        analytics: {
          openRate: { data },
        },
      }) => data,
    ),
  useAmriReportMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          amriReport: { data, ...meta },
        },
      }) => meta,
    ),
  useAmriReportData: (): AmriReportDataSchema =>
    useAppSelector(
      ({
        analytics: {
          amriReport: { data },
        },
      }) => data,
    ),
  useBenchmarkingReportBUMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          benchmarkingReportBU: { data, ...meta },
        },
      }) => meta,
    ),
  useBenchmarkingReportEnterpriseData: (): BenchmarkingReportDataSchema =>
    useAppSelector(
      ({
        analytics: {
          benchmarkingReportEnterprise: { data },
        },
      }) => data,
    ),
  useBenchmarkingReportBUData: (): BenchmarkingReportDataSchema =>
    useAppSelector(
      ({
        analytics: {
          benchmarkingReportBU: { data },
        },
      }) => data,
    ),

  useTopPerformingContentMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          topPerformingContent: { data, ...meta },
        },
      }) => meta,
    ),
  useTopPerformingContentData: (): PerformingContentDataSchema =>
    useAppSelector(
      ({
        analytics: {
          topPerformingContent: { data },
        },
      }) => data,
    ),
  useLowPerformingContentMeta: (): MetaSchema =>
    useAppSelector(
      ({
        analytics: {
          lowPerformingContent: { data, ...meta },
        },
      }) => meta,
    ),
  useLowPerformingContentData: (): PerformingContentDataSchema =>
    useAppSelector(
      ({
        analytics: {
          lowPerformingContent: { data },
        },
      }) => data,
    ),
};
