import { memo } from 'react';
import { Box } from '@material-ui/core';

import { Chip as BaseChip, Icon } from '@components';
import { variables } from '@styles';
import { content } from '@content';

import { useStyles } from './Chip.styles';
import { ChipProps } from './Chip.props';

export const Chip = memo(({ status }: ChipProps): JSX.Element | null => {
  const styles = useStyles();

  switch (status) {
    case 'missing':
      return <BaseChip label={content.missing} color={variables.color.secondary.orange} />;
    case 'complete':
      return (
        <Box className={styles.tickIcon}>
          <Icon.Tick width={10.26} height={9} />
        </Box>
      );
    case 'off':
      return <BaseChip label={content.off} color={variables.color.secondary.gray} />;
    default:
      return null;
  }
});

Chip.displayName = 'Chip';
