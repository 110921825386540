import { Box, Popover, Grid } from '@material-ui/core';

import { Typography } from '@components';

import { ModulePopularPopoverProps } from './ModulePopularCard.props';
import { usePopoverStyles } from './ModulePopularCard.styles';
import { getModuleInfo } from './ModulePopularPopover.utils';

/**
 ModulePopularPopover component.
 @returns {JSX.Element}
 */

export const ModulePopularPopover = ({ open, anchorEl, onClose, module }: ModulePopularPopoverProps): JSX.Element => {
  const styles = usePopoverStyles();

  const moduleInfos = getModuleInfo(module.moduleData);

  return (
    <Popover
      id={`mouse-over-popover-${module.id}`}
      className={styles.popover}
      classes={{
        paper: styles.popoverContent,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box className={styles.popoverContainer}>
        {moduleInfos.map((item) => (
          <Grid key={item.label} container className={styles.popoverRow}>
            <Grid item xs={6}>
              <Typography.Caption>{item.label}</Typography.Caption>
            </Grid>
            <Grid item xs={6}>
              <Typography.Headline noWrap>{item.value}</Typography.Headline>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Popover>
  );
};
