import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    sampleTemplate: {
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(255, 255, 255, 0.7)',
      flex: '1 1 auto',
      borderRadius: variables.borderRadius.sm,
      padding: `${variables.gap.lg} ${variables.gap.sm}`,
    },
    header: {
      display: 'flex',
      marginBottom: variables.gap.sm,
    },
    collapseBtn: {
      marginRight: variables.gap.md,
    },
    content: {
      height: '100%',
      position: 'relative',
      overflowY: 'auto',
    },

    iframe: {
      height: '100%',
      marginLeft: '2.1rem',
      marginRight: '2.1rem',
      transition: 'opacity 0.4s',

      '&[data-loaded="false"]': {
        opacity: variables.opacity.medium,
        pointerEvents: 'none',
      },

      '&[data-loaded="true"]': {
        opacity: variables.opacity.full,
        pointerEvents: 'initial',
      },
      '*::-webkit-scrollbar': {
        width: variables.scrollbar.width,
        height: variables.scrollbar.height,
      },
      '*::-webkit-scrollbar-thumb': {
        background: '#dfe5eb',
        borderRadius: variables.borderRadius.md,
      },
    },

    arrows: {
      position: 'absolute',
      marginTop: '-1rem',
      '&[data-arrow="prev"]': {
        left: 0,
      },
      '&[data-arrow="next"]': {
        right: 0,
      },
    },
  }),
);
