import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Tree component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    circle: {
      width: '0.9rem',
      height: '0.9rem',
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: '2.4rem',
    },

    row: {
      display: 'flex',
      alignItems: 'center',
    },

    horizonatalLine: {
      width: '0.8rem',
      height: '0.1rem',
      background: variables.color.primary.lightGray,
      marginRight: theme.spacing(1),
    },

    verticalLine: {
      width: '0.1rem',
      background: variables.color.primary.lightGray,
    },

    smallVerticalLine: {
      width: '0.1rem',
      height: '1.6rem',
      background: variables.color.primary.lightGray,
      marginLeft: ({ level }: { level: number }) => (level ? `${level * 28}px` : '28px'),
    },

    iconDocument: {
      width: '2.4rem',
      height: '2.4rem',
      background: variables.color.primary.lightGray,
      borderRadius: '2.4rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(1, 1, 1, 2.625),
    },

    innerContainer: {
      borderLeft: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      marginLeft: '0.4rem',
    },

    subtitleRow: {
      display: 'flex',
      alignItems: 'flex-end',
    },

    tag: {
      marginLeft: theme.spacing(1),
    },

    error: {
      marginLeft: theme.spacing(1),
      '&&': {
        color: variables.color.semantic.negative,
      },
    },

    item: {
      marginLeft: ({ level }: { level: number }) => (level ? `${level * 24}px` : ''),
    },

    link: {
      textDecoration: 'none',
    },

    editText: {
      marginLeft: theme.spacing(1),
      '&&': {
        color: variables.color.primary.mainPurple,
      },
    },
  }),
);
