import { DataAttributeLibraryWidth } from './DataAttributeLibrary.types';

export const DEFAULT_MODAL_WIDTH = '42.7rem';

export const MODAL_WIDTH: DataAttributeLibraryWidth = {
  emailActivity: '65.2rem',
  attributesImport: '86.6rem',
};

export const SEARCH_ATTRIBUTE_DELAY = 500;
