import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InputBase } from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import { Droppable } from 'react-beautiful-dnd';
import debounce from 'lodash.debounce';

import { Panel, Icon, Typography, Dropdown } from '@components';
import { content } from '@content';
import { useAppDispatch } from '@store';
import {
  attributeLibrary,
  ClientFilesUploadDates,
  ClientFileUpdateKey,
  ClientFileUploadedAt,
  signalLibrary,
  userClients,
} from '@modules';
import { func, searchQuery, textTemplate, useLoader } from '@utils';
import { UploadEmailActivity, Signal, SignalLibraryRow, UploadCSVFileModal, AttributesFeed } from '@routes';

import { Attribute } from '../Attribute';
import { AttributeLibraryRow } from '../AttributeLibraryRow';
import { AttributesImportModal } from '../AttributesImportModal';

import { DataAttributeLibraryProps } from './DataAttributeLibrary.props';
import { useSignalStyles, useTreatmentStyles } from './DataAttributeLibrary.styles';
import { getImportMenuOptions } from './DataAttributeLibrary.utils';
import { SEARCH_ATTRIBUTE_DELAY } from './DataAttributeLibrary.const';
import { AttributesFeedModal } from '../AttributesFeedModal';
import { AttributesMapModal } from '../AttributesMapModal';
import { ForeignAttributesModal } from '../ForeignAttributesModal';

/**
 DataAttributeLibrary route component.
 @returns {JSX.Element}
 */

export const DataAttributeLibrary = memo(
  ({
    businessUnit,
    treatmentPanelType,
    uiType,
    onAttributesImportSuccess = func.nop,
  }: DataAttributeLibraryProps): JSX.Element => {
    const signalStyles = useSignalStyles();
    const treatmentStyles = useTreatmentStyles();

    const dispatch = useAppDispatch();
    const [showImportDataModal, setShowImportDataModal] = useState(false);
    const [showAttributesImportData, setShowAttributesImportData] = useState(false);
    // const [showAttributesMapData, setShowAttributesMapData] = useState(false);
    const [showMailFileModal, setShowMailFileModal] = useState(false);
    const [showUploadEmailActivity, setShowUploadEmailActivity] = useState(false);
    const [showDataAttributesFeed, setShowDataAttributesFeed] = useState(false);
    const [showForeignAttributes, setShowForeignAttributes] = useState(false);
    const [showImportMappingFileModal, setShowImportMappingFileModal] = useState(false);
    const [styles, setStyles] = useState(signalStyles);

    const { items: attributes } = attributeLibrary.useListData();
    const filteredAttributes = useMemo(
      () =>
        treatmentPanelType === 'externalScoring'
          ? attributes.filter((attr) => ['textual', 'numeric'].includes(attr.type) && attr.signalType !== 'foreign')
          : attributes.filter((attr) => attr.signalType !== 'foreign'),
      [attributes, treatmentPanelType],
    );

    const businessUnitEntity = userClients.useEntityData();
    const [businessUnitId] = searchQuery.useMutualParams('businessUnit', 'program', 'deployment');

    const attributesCount = useMemo(() => filteredAttributes.length, [filteredAttributes.length]);

    const meta = attributeLibrary.useListMeta();

    useLoader(meta);

    const runSearch = debounce(
      useCallback(
        (keyword: string) => {
          dispatch(
            attributeLibrary.thunk.search({
              businessUnit,
              keyword,
            }),
          );
        },
        [dispatch, businessUnit],
      ),
      SEARCH_ATTRIBUTE_DELAY,
    );

    const handleSearchChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        runSearch(event.target.value);
      },
      [runSearch],
    );

    const handleImportDataModalOpen = useCallback(() => {
      setShowImportDataModal(true);
    }, [setShowImportDataModal]);

    const handleImportDataModalClose = useCallback(() => {
      setShowImportDataModal(false);
    }, [setShowImportDataModal]);

    const handleSubmitImportData = useCallback(
      async (data) => {
        const { file } = data;
        const payload = new FormData();
        payload.append('signalFile', file);

        const result = await dispatch(signalLibrary.thunk.fileUpload({ businessUnit: businessUnitId, payload }));

        if (signalLibrary.thunk.fileUpload.fulfilled.match(result)) {
          handleImportDataModalClose();
          setShowAttributesImportData(true);
        }
      },
      [businessUnitId, dispatch, handleImportDataModalClose],
    );

    const handleMailFileModalOpen = useCallback(() => {
      setShowMailFileModal(true);
    }, [setShowMailFileModal]);

    const handleMailFileModalClose = useCallback(() => {
      setShowMailFileModal(false);
    }, [setShowMailFileModal]);

    const handleSubmitMailFile = useCallback(
      async (data) => {
        const { file } = data;
        const body = new FormData();

        body.append('mailFile', file);

        const result = await dispatch(
          userClients.thunk.clientUploadMailFile({
            businessUnit: businessUnitId,
            body,
          }),
        );

        if (userClients.thunk.clientUploadMailFile.fulfilled.match(result)) {
          handleMailFileModalClose();
        }
      },
      [businessUnitId, dispatch, handleMailFileModalClose],
    );

    const fetchClient = useCallback(
      () => dispatch(userClients.thunk.getById(businessUnitId)),
      [dispatch, businessUnitId],
    );

    const handleFileUploadSuccess = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async (uploadDateKey: keyof ClientFilesUploadDates, prevUploadDate?: ClientFileUploadedAt) => {
        const result = await fetchClient();

        if (userClients.thunk.getById.fulfilled.match(result) && result.payload) {
          // const currUploadDate = result.payload[uploadDateKey];

          // if (prevUploadDate === currUploadDate) {
          //   handleFileUploadSuccess(uploadDateKey, prevUploadDate);
          // } else {
          setShowAttributesImportData(false);
          // }
        }
      },
      [fetchClient, setShowAttributesImportData],
    );

    const handleEmailActivityModalOpen = useCallback(() => {
      setShowUploadEmailActivity(true);
    }, [setShowUploadEmailActivity]);

    const handleEmailActivityModalClose = useCallback(() => {
      setShowUploadEmailActivity(false);
    }, [setShowUploadEmailActivity]);

    const handleForeignAttributesModalOpen = useCallback(() => {
      setShowForeignAttributes(true);
    }, [setShowUploadEmailActivity]);

    const handleForeignAttributesModalClose = useCallback(() => {
      setShowForeignAttributes(false);
    }, [setShowForeignAttributes]);

    const handleForeignAttributesModalSuccess = useCallback(() => {
      setShowForeignAttributes(false);
    }, [setShowUploadEmailActivity]);

    const handleDataAttributesFeedModalOpen = useCallback(() => {
      setShowDataAttributesFeed(true);
    }, [setShowUploadEmailActivity]);

    const handleDataAttributesFeedModalClose = useCallback(() => {
      setShowDataAttributesFeed(false);
    }, [setShowUploadEmailActivity]);

    const handleDataAttributesFeedModalSuccess = useCallback(() => {
      setShowDataAttributesFeed(false);
      setShowImportMappingFileModal(true);
    }, [setShowUploadEmailActivity]);

    const handleImportMappingFileModalClose = useCallback(() => {
      setShowImportMappingFileModal(false);
    }, [setShowUploadEmailActivity]);

    const handleImportMappingMailFile = useCallback(
      async ({ file }: any) => {
        const formData = new FormData();

        formData.append('dataAttributeFile', file! as Blob);

        await dispatch(attributeLibrary.thunk.importDataAttribute({ businessUnit, body: formData }));

        setShowImportMappingFileModal(false);
        setShowDataAttributesFeed(true);

        setTimeout(() => {
          dispatch(
            signalLibrary.thunk.search({
              businessUnit,
              keyword: '',
            }),
          );
          dispatch(
            attributeLibrary.thunk.search({
              businessUnit,
              keyword: '',
            }),
          );
        }, 5000);
      },
      [setShowUploadEmailActivity],
    );

    const handleSubmitEmailActivity = useCallback(
      async (key: ClientFileUpdateKey, body: FormData) => {
        const dateKey = key === 'emailActivity' ? 'emailActivityUploadedAt' : 'mailPlanTemplateFileUploadedAt';
        const prevDate =
          key === 'emailActivity'
            ? businessUnitEntity?.emailActivityUploadedAt
            : businessUnitEntity?.mailPlanTemplateFileUploadedAt;

        const result = await dispatch(userClients.thunk.updateFile({ id: businessUnitId, key, body }));

        if (userClients.thunk.updateFile.fulfilled.match(result)) {
          handleFileUploadSuccess(dateKey, prevDate);
        }
      },
      [businessUnitEntity, dispatch, businessUnitId, handleFileUploadSuccess],
    );

    const handleAttributesImportDataClose = useCallback(() => {
      setShowAttributesImportData(false);
    }, [setShowAttributesImportData]);

    const handleAttributesImportSuccess = useCallback(() => {
      const prevDate = businessUnitEntity?.signalFileUploadedAt;
      handleFileUploadSuccess('signalFileUploadedAt', prevDate);
      onAttributesImportSuccess();
    }, [businessUnitEntity?.signalFileUploadedAt, handleFileUploadSuccess, onAttributesImportSuccess]);

    useEffect(() => {
      if (businessUnitId) {
        fetchClient();
      }
    }, [dispatch, fetchClient, businessUnitId]);

    useEffect(() => {
      if (uiType === 'treatment') {
        setStyles(treatmentStyles);
      } else {
        setStyles(signalStyles);
      }
    }, [signalStyles, treatmentStyles, uiType]);

    useEffect(() => {
      return () => {
        dispatch(signalLibrary.actions.resetListData(null));
      };
    }, []);

    return (
      <Panel className={styles.dataAttributeLibrary}>
        {uiType === 'signal' && (
          <Typography.Title className={styles.title}>{content.attributeLibrary.toUpperCase()}</Typography.Title>
        )}
        {uiType === 'treatment' && (
          <Box className={styles.header}>
            <Typography.Title className={styles.title}>
              {textTemplate(content.attributeLibraryValue, { value: `(${filteredAttributes.length})` })}
            </Typography.Title>
            <Typography.Caption>{content.dragToAddOrReplace}</Typography.Caption>
          </Box>
        )}
        <Box className={styles.listWrapper}>
          <Box className={styles.inputContainer}>
            <InputBase
              className={styles.searchField}
              placeholder={content.search}
              onChange={handleSearchChange}
              endAdornment={<Icon.SearchOutline />}
            />
            {uiType === 'signal' && (
              <Dropdown
                buttonClassName={styles.dropdown}
                title={content.import}
                variant="outlined"
                options={getImportMenuOptions(
                  handleImportDataModalOpen,
                  handleMailFileModalOpen,
                  handleEmailActivityModalOpen,
                  handleDataAttributesFeedModalOpen,
                  handleForeignAttributesModalOpen,
                )}
              />
            )}
          </Box>
          <Box className={styles.list}>
            {attributesCount === 0 && <Typography.Body align="center">{content.noItemsFound}</Typography.Body>}
            <Droppable
              droppableId="attributeLibrary"
              type="SIGNAL"
              mode="virtual"
              isDropDisabled
              renderClone={(provided, snapshot, rubric) =>
                uiType === 'signal' ? (
                  <Attribute
                    className={styles.signal}
                    provided={provided}
                    item={filteredAttributes[rubric.source.index]}
                    isDragging={snapshot.isDragging}
                    isCloudShown={false}
                  />
                ) : (
                  <Signal
                    className={styles.signal}
                    provided={provided}
                    item={filteredAttributes[rubric.source.index]}
                    isDragging={snapshot.isDragging}
                  />
                )
              }
            >
              {(provided) => (
                <FixedSizeList
                  height={attributesCount ? 480 : 460}
                  width="100%"
                  itemCount={attributesCount}
                  itemSize={uiType === 'signal' ? 70 : 40}
                  outerRef={provided.innerRef}
                  itemData={filteredAttributes}
                >
                  {uiType === 'signal' ? AttributeLibraryRow : SignalLibraryRow}
                </FixedSizeList>
              )}
            </Droppable>
          </Box>
        </Box>
        {showImportDataModal && (
          <UploadCSVFileModal
            title={content.importData}
            label={content.uploadCSVFile}
            onClose={handleImportDataModalClose}
            onConfirm={handleSubmitImportData}
          />
        )}
        {showMailFileModal && (
          <UploadCSVFileModal
            title={content.importTreatmentRequestFile}
            label={content.uploadFile}
            onClose={handleMailFileModalClose}
            onConfirm={handleSubmitMailFile}
          />
        )}
        {showImportMappingFileModal && (
          <UploadCSVFileModal
            title={content.importMappingFile}
            label={content.uploadFile}
            onClose={handleImportMappingFileModalClose}
            onConfirm={handleImportMappingMailFile}
          />
        )}
        {showUploadEmailActivity && (
          <UploadEmailActivity
            entity={businessUnitEntity}
            onClose={handleEmailActivityModalClose}
            onSubmit={handleSubmitEmailActivity}
          />
        )}
        <AttributesFeedModal
          businessUnit={businessUnitEntity}
          businessUnitId={businessUnitId}
          isModalOpen={showDataAttributesFeed}
          onClose={handleDataAttributesFeedModalClose}
          onSuccess={handleDataAttributesFeedModalSuccess}
        />
        <ForeignAttributesModal
          businessUnit={businessUnitEntity}
          businessUnitId={businessUnitId}
          isModalOpen={showForeignAttributes}
          onClose={handleForeignAttributesModalClose}
          onSuccess={handleForeignAttributesModalSuccess}
        />
        <AttributesImportModal
          businessUnit={businessUnitEntity}
          businessUnitId={businessUnitId}
          isModalOpen={showAttributesImportData}
          onClose={handleAttributesImportDataClose}
          onSuccess={handleAttributesImportSuccess}
        />
        {/* <AttributesMapModal
          businessUnit={businessUnitEntity}
          businessUnitId={businessUnitId}
          isModalOpen={showAttributesMapData}
          onClose={handleAttributesMapDataClose}
          onSuccess={handleAttributesMapDataSuccess}
        /> */}
      </Panel>
    );
  },
);

DataAttributeLibrary.displayName = 'SignalLibrary';
