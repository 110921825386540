import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { content } from '@content';
import { textTemplate } from '@utils';

import {
  getAll,
  getList,
  getFullList,
  getById,
  getSubject,
  getFieldsMapping,
  create,
  update,
  validateEspCreds,
  toggleArchived,
  testSftpConnection,
  checkS3PublicBucketAvailability,
  checkS3PrivateBucketAvailability,
  checkS3ImportBucketAvailability,
} from './UserClients.thunk';
import { initialState } from './UserClients.state';
import { UserClientsSchema, Client } from './UserClients.schema';

export const reducers = {
  findById: (state: UserClientsSchema, action: PayloadAction<number>): void => {
    state.entity.data = [
      ...state.list.data.items,
      ...(state.all.data as unknown as Client[]),
      ...state.fullList.data,
    ].find((item) => item.id === action.payload);
  },
  resetEntity: (state: UserClientsSchema): void => {
    state.entity.data = undefined;
  },
  resetSubject: (state: UserClientsSchema): void => {
    state.subject = initialState.subject;
  },
  resetFieldsMapping: (state: UserClientsSchema): void => {
    state.fieldsMapping.data = undefined;
  },
  resetEspCredsValidation: (state: UserClientsSchema): void => {
    state.espCredsValidation.data = initialState.espCredsValidation.data;
  },
  resetSftpTestConnection: (state: UserClientsSchema): void => {
    state.sftpTestConnection.data = initialState.sftpTestConnection.data;
  },
  resetS3BucketAvailability: (state: UserClientsSchema): void => {
    state.s3BucketAvailability.data = initialState.s3BucketAvailability.data;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<UserClientsSchema>): void => {
  builder.addCase(getAll.pending, (state, action) => {
    if (state.all.status !== 'loading') {
      state.all.requestId = action.meta.requestId;
      state.all.data = initialState.all.data;
      state.all.status = 'loading';
    }
  });

  builder.addCase(getAll.fulfilled, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.data = action.payload || initialState.all.data;
      state.all.status = 'success';
    }
  });

  builder.addCase(getAll.rejected, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.error = action.error;
      state.all.status = 'error';
    }
  });

  builder.addCase(getList.pending, (state, action) => {
    if (state.list.status !== 'loading') {
      state.list.requestId = action.meta.requestId;
      state.list.data = initialState.list.data;
      state.list.status = 'loading';
    }
  });

  builder.addCase(getList.fulfilled, (state, action) => {
    if (state.list.status === 'loading' && state.list.requestId === action.meta.requestId) {
      state.list.requestId = initialState.list.requestId;
      state.list.data = action.payload || initialState.list.data;
      state.list.status = 'success';
    }
  });

  builder.addCase(getList.rejected, (state, action) => {
    if (state.list.status === 'loading' && state.list.requestId === action.meta.requestId) {
      state.list.requestId = initialState.list.requestId;
      state.list.error = action.error;
      state.list.status = 'error';
    }
  });

  builder.addCase(getFullList.pending, (state, action) => {
    if (state.fullList.status !== 'loading') {
      state.fullList.requestId = action.meta.requestId;
      state.fullList.data = initialState.fullList.data;
      state.fullList.status = 'loading';
    }
  });

  builder.addCase(getFullList.fulfilled, (state, action) => {
    if (state.fullList.status === 'loading' && state.fullList.requestId === action.meta.requestId) {
      state.fullList.requestId = initialState.fullList.requestId;
      state.fullList.data = action.payload || initialState.fullList.data;
      state.fullList.status = 'success';
    }
  });

  builder.addCase(getFullList.rejected, (state, action) => {
    if (state.fullList.status === 'loading' && state.fullList.requestId === action.meta.requestId) {
      state.fullList.requestId = initialState.fullList.requestId;
      state.fullList.error = action.error;
      state.fullList.status = 'error';
    }
  });

  builder.addCase(getById.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.data = initialState.entity.data;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(getById.fulfilled, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.data = action.payload;
      state.entity.status = 'success';
    }
  });

  builder.addCase(getById.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = action.error;
      state.entity.status = 'error';
    }
  });

  builder.addCase(getSubject.pending, (state, action) => {
    if (state.subject.status !== 'loading') {
      state.subject.requestId = action.meta.requestId;
      state.subject.data = initialState.subject.data;
      state.subject.status = 'loading';
    }
  });

  builder.addCase(getSubject.fulfilled, (state, action) => {
    if (state.subject.status === 'loading' && state.subject.requestId === action.meta.requestId) {
      state.subject.requestId = initialState.subject.requestId;
      state.subject.data = action.payload;
      state.subject.status = 'success';
    }
  });

  builder.addCase(getSubject.rejected, (state, action) => {
    if (state.subject.status === 'loading' && state.subject.requestId === action.meta.requestId) {
      state.subject.requestId = initialState.subject.requestId;
      state.subject.error = action.error;
      state.subject.status = 'error';
    }
  });

  builder.addCase(create.pending, (state, action) => {
    if (state.subject.status !== 'loading') {
      state.subject.requestId = action.meta.requestId;
      state.subject.data = initialState.subject.data;
      state.subject.status = 'loading';
    }
  });

  builder.addCase(create.fulfilled, (state, action) => {
    if (state.subject.status === 'loading' && state.subject.requestId === action.meta.requestId) {
      state.subject.requestId = initialState.subject.requestId;
      state.subject.data = action.payload;
      state.subject.status = 'success';
    }
  });

  builder.addCase(create.rejected, (state, action) => {
    if (state.subject.status === 'loading' && state.subject.requestId === action.meta.requestId) {
      state.subject.requestId = initialState.subject.requestId;
      state.subject.error = action.error;
      state.subject.status = 'error';
    }
  });

  builder.addCase(update.pending, (state, action) => {
    if (state.subject.status !== 'loading') {
      state.subject.requestId = action.meta.requestId;
      state.subject.status = 'loading';
    }
  });

  builder.addCase(update.fulfilled, (state, action) => {
    if (state.subject.status === 'loading' && state.subject.requestId === action.meta.requestId) {
      state.subject.requestId = initialState.subject.requestId;
      state.subject.data = action.payload;
      state.subject.status = 'success';
    }
  });

  builder.addCase(update.rejected, (state, action) => {
    if (state.subject.status === 'loading' && state.subject.requestId === action.meta.requestId) {
      state.subject.requestId = initialState.subject.requestId;
      state.subject.error = action.error;
      state.subject.status = 'error';
    }
  });

  builder.addCase(toggleArchived.pending, (state, action) => {
    if (state.subject.status !== 'loading') {
      state.subject.requestId = action.meta.requestId;
      state.subject.status = 'loading';
    }
  });

  builder.addCase(toggleArchived.fulfilled, (state, action) => {
    if (state.subject.status === 'loading' && state.subject.requestId === action.meta.requestId) {
      state.subject.requestId = initialState.subject.requestId;
      if (action.payload) {
        state.subject.data = {
          ...state.subject.data,
          ...action.payload,
        };
      }
      state.subject.status = 'success';
    }
  });

  builder.addCase(toggleArchived.rejected, (state, action) => {
    if (state.subject.status === 'loading' && state.subject.requestId === action.meta.requestId) {
      state.subject.requestId = initialState.subject.requestId;
      state.subject.error = action.error;
      state.subject.status = 'error';
    }
  });

  builder.addCase(getFieldsMapping.pending, (state, action) => {
    if (state.fieldsMapping.status !== 'loading') {
      state.fieldsMapping.requestId = action.meta.requestId;
      state.fieldsMapping.data = initialState.fieldsMapping.data;
      state.fieldsMapping.status = 'loading';
    }
  });

  builder.addCase(getFieldsMapping.fulfilled, (state, action) => {
    if (state.fieldsMapping.status === 'loading' && state.fieldsMapping.requestId === action.meta.requestId) {
      state.fieldsMapping.requestId = initialState.fieldsMapping.requestId;
      state.fieldsMapping.data = action.payload;
      state.fieldsMapping.status = 'success';
    }
  });

  builder.addCase(getFieldsMapping.rejected, (state, action) => {
    if (state.fieldsMapping.status === 'loading' && state.fieldsMapping.requestId === action.meta.requestId) {
      state.fieldsMapping.requestId = initialState.fieldsMapping.requestId;
      state.fieldsMapping.error = action.error;
      state.fieldsMapping.status = 'error';
    }
  });

  builder.addCase(validateEspCreds.pending, (state, action) => {
    if (state.espCredsValidation.status !== 'loading') {
      state.espCredsValidation.requestId = action.meta.requestId;
      state.espCredsValidation.data = initialState.espCredsValidation.data;
      state.espCredsValidation.status = 'loading';
    }
  });

  builder.addCase(validateEspCreds.fulfilled, (state, action) => {
    if (state.espCredsValidation.status === 'loading' && state.espCredsValidation.requestId === action.meta.requestId) {
      state.espCredsValidation.requestId = initialState.espCredsValidation.requestId;
      state.espCredsValidation.status = 'success';
      state.espCredsValidation.data = {
        isValid: !!action.payload,
        message: action.payload?.message || content.espCredsInvalid,
      };
    }
  });

  builder.addCase(validateEspCreds.rejected, (state, action) => {
    if (state.espCredsValidation.status === 'loading' && state.espCredsValidation.requestId === action.meta.requestId) {
      state.espCredsValidation.requestId = initialState.espCredsValidation.requestId;
      state.espCredsValidation.error = action.error;
      state.espCredsValidation.status = 'error';
      state.espCredsValidation.data = {
        isValid: false,
        message: content.espCredsInvalid,
      };
    }
  });

  builder.addCase(testSftpConnection.pending, (state, action) => {
    if (state.sftpTestConnection.status !== 'loading') {
      state.sftpTestConnection.requestId = action.meta.requestId;
      state.sftpTestConnection.data = initialState.sftpTestConnection.data;
      state.sftpTestConnection.status = 'loading';
    }
  });

  builder.addCase(testSftpConnection.fulfilled, (state, action) => {
    if (state.sftpTestConnection.status === 'loading' && state.sftpTestConnection.requestId === action.meta.requestId) {
      state.sftpTestConnection.requestId = initialState.sftpTestConnection.requestId;
      state.sftpTestConnection.status = 'success';
      state.sftpTestConnection.data = {
        isValid: true,
        message: textTemplate(content.processCredsAreValid, {
          process: textTemplate(content.entitySuccessfullyProceed, {
            entity: textTemplate(content.connectionToProtocol, { protocol: content.secureFtp }),
            proceed: content.established.toLowerCase(),
          }),
        }),
      };
    }
  });

  builder.addCase(testSftpConnection.rejected, (state, action) => {
    if (state.sftpTestConnection.status === 'loading' && state.sftpTestConnection.requestId === action.meta.requestId) {
      state.sftpTestConnection.requestId = initialState.sftpTestConnection.requestId;
      state.sftpTestConnection.error = action.error;
      state.sftpTestConnection.status = 'error';
      state.sftpTestConnection.data = {
        isValid: false,
        message: content.espCredsInvalid,
      };
    }
  });

  builder.addCase(checkS3PublicBucketAvailability.pending, (state, action) => {
    if (state.s3BucketAvailability.status !== 'loading') {
      state.s3BucketAvailability.requestId = action.meta.requestId;
      state.s3BucketAvailability.data = initialState.s3BucketAvailability.data;
      state.s3BucketAvailability.status = 'loading';
    }
  });

  builder.addCase(checkS3PublicBucketAvailability.fulfilled, (state, action) => {
    if (
      state.s3BucketAvailability.status === 'loading' &&
      state.s3BucketAvailability.requestId === action.meta.requestId
    ) {
      state.s3BucketAvailability.requestId = initialState.s3BucketAvailability.requestId;
      state.s3BucketAvailability.status = 'success';
      state.s3BucketAvailability.data = {
        ...state.s3BucketAvailability.data,
        public: {
          isValid: true,
          message: content.connectionToPublicAWSEstableshed,
        },
      };
    }
  });

  builder.addCase(checkS3PublicBucketAvailability.rejected, (state, action) => {
    if (
      state.s3BucketAvailability.status === 'loading' &&
      state.s3BucketAvailability.requestId === action.meta.requestId
    ) {
      state.s3BucketAvailability.requestId = initialState.s3BucketAvailability.requestId;
      state.s3BucketAvailability.error = action.error;
      state.s3BucketAvailability.status = 'error';
      state.s3BucketAvailability.data = {
        ...state.s3BucketAvailability.data,
        public: {
          isValid: false,
          message: content.awsBucketCredsInvalid,
        },
      };
    }
  });

  builder.addCase(checkS3PrivateBucketAvailability.pending, (state, action) => {
    if (state.s3BucketAvailability.status !== 'loading') {
      state.s3BucketAvailability.requestId = action.meta.requestId;
      state.s3BucketAvailability.data = initialState.s3BucketAvailability.data;
      state.s3BucketAvailability.status = 'loading';
    }
  });

  builder.addCase(checkS3PrivateBucketAvailability.fulfilled, (state, action) => {
    if (
      state.s3BucketAvailability.status === 'loading' &&
      state.s3BucketAvailability.requestId === action.meta.requestId
    ) {
      state.s3BucketAvailability.requestId = initialState.s3BucketAvailability.requestId;
      state.s3BucketAvailability.status = 'success';
      state.s3BucketAvailability.data = {
        ...state.s3BucketAvailability.data,
        private: {
          isValid: true,
          message: content.connectionToPrivateAWSEstableshed,
        },
      };
    }
  });

  builder.addCase(checkS3PrivateBucketAvailability.rejected, (state, action) => {
    if (
      state.s3BucketAvailability.status === 'loading' &&
      state.s3BucketAvailability.requestId === action.meta.requestId
    ) {
      state.s3BucketAvailability.requestId = initialState.s3BucketAvailability.requestId;
      state.s3BucketAvailability.error = action.error;
      state.s3BucketAvailability.status = 'error';
      state.s3BucketAvailability.data = {
        ...state.s3BucketAvailability.data,
        private: {
          isValid: false,
          message: content.awsBucketCredsInvalid,
        },
      };
    }
  });

  builder.addCase(checkS3ImportBucketAvailability.pending, (state, action) => {
    if (state.s3BucketAvailability.status !== 'loading') {
      state.s3BucketAvailability.requestId = action.meta.requestId;
      state.s3BucketAvailability.data = initialState.s3BucketAvailability.data;
      state.s3BucketAvailability.status = 'loading';
    }
  });

  builder.addCase(checkS3ImportBucketAvailability.fulfilled, (state, action) => {
    if (
      state.s3BucketAvailability.status === 'loading' &&
      state.s3BucketAvailability.requestId === action.meta.requestId
    ) {
      state.s3BucketAvailability.requestId = initialState.s3BucketAvailability.requestId;
      state.s3BucketAvailability.status = 'success';
      state.s3BucketAvailability.data = {
        ...state.s3BucketAvailability.data,
        import: {
          isValid: true,
          message: content.connectionToPrivateAWSEstableshed,
        },
      };
    }
  });

  builder.addCase(checkS3ImportBucketAvailability.rejected, (state, action) => {
    if (
      state.s3BucketAvailability.status === 'loading' &&
      state.s3BucketAvailability.requestId === action.meta.requestId
    ) {
      state.s3BucketAvailability.requestId = initialState.s3BucketAvailability.requestId;
      state.s3BucketAvailability.error = action.error;
      state.s3BucketAvailability.status = 'error';
      state.s3BucketAvailability.data = {
        ...state.s3BucketAvailability.data,
        import: {
          isValid: false,
          message: content.awsBucketCredsInvalid,
        },
      };
    }
  });
};
