import { useEffect, useRef, memo, useState } from 'react';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { Panel, Alert, TableLegend, Accordion, Icon } from '@components';
import { searchQuery, useLoader } from '@utils';
import { useAppDispatch } from '@store';
import { recommendedModules } from '@modules';
import { BADGE_COLORS, MindsetTable } from '@views';

import { useStyles } from './RecommenderTable.styles';

/**
 * RecommenderTable component
 * @returns {JSX.Element}
 */

export const RecommenderTable = memo((): JSX.Element => {
  const styles = useStyles();
  const prevDeploymentId = useRef<void | number>();
  const dispatch = useAppDispatch();
  const recModsMeta = recommendedModules.useMeta();
  const { items, counts } = recommendedModules.useData();
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const [deploymentId] = searchQuery.useMutualParams('deployment');

  const toggleAccordion = (index: number) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  let tableLegendItems = null;

  if (counts) {
    tableLegendItems = [
      {
        label: content.noContent,
        count: counts ? counts[0] : 0,
        color: BADGE_COLORS.none,
      },
      {
        label: content.contentAdded,
        count: counts ? counts[1] : 0,
        color: BADGE_COLORS.html,
      },
    ];
  }

  useLoader(recModsMeta);

  useEffect(() => {
    if (!deploymentId) {
      return;
    }

    if (deploymentId !== prevDeploymentId.current) {
      dispatch(recommendedModules.thunk.get(deploymentId));
      prevDeploymentId.current = deploymentId;
    }
  }, [dispatch, deploymentId]);

  return (
    <Box>
      {recModsMeta.status === 'loading' && <Panel />}
      {recModsMeta.status === 'error' && (
        <Panel>
          <Alert message={content.failedToLoadItems} />
        </Panel>
      )}
      {recModsMeta.status === 'success' && items.length === 0 && (
        <Panel>
          <Alert type="info" message={content.noItemsFound} />
        </Panel>
      )}
      {recModsMeta.status === 'success' && items.length !== 0 && (
        <>
          {tableLegendItems && counts && <TableLegend className={styles.legend} items={tableLegendItems} />}
          {items.length !== 0 &&
            items.map((item, index) => {
              const { cohorts, defaultMindset, experienceId, experienceName, products } = item;
              return (
                <Accordion
                  key={experienceId}
                  expanded={expanded[index] ? !expanded[index] : true}
                  onChange={() => toggleAccordion(index)}
                  icon={<Icon.Mindset />}
                  title={experienceName}
                  uiType="secondary"
                >
                  <MindsetTable
                    cohorts={cohorts}
                    defaultMindset={defaultMindset}
                    products={products}
                    experienceId={experienceId}
                  />
                </Accordion>
              );
            })}
        </>
      )}
    </Box>
  );
});

RecommenderTable.displayName = 'MindsetView';
