import { Client } from '@modules';
import { validate } from '@utils';
import { content } from '@content';

import { FormValues, FieldKey, FormValue } from './UploadEmailActivity.types';
import { FIELD_ID_BY_KEY } from './UploadEmailActivity.const';

export const getDefaultValues = (entity?: Client): FormValues =>
  Object.entries(entity?.statisticFiles || {}).reduce<FormValues>((names, [key, name]) => {
    const id = FIELD_ID_BY_KEY[key as FieldKey];

    return {
      ...names,
      ...(id && name ? { [id]: new File([], name) } : null),
    };
  }, {});

export const isValuePseudoFile = (file: FormValue | null, pseudoFiles: FormValue[]): boolean =>
  !!file &&
  ((Array.isArray(file) && !!file.reduce((accum, fileItem) => accum && pseudoFiles.includes(fileItem), true)) ||
    pseudoFiles.includes(file));

export const isValueNonPseudoFile = (file: FormValue | null, pseudoFiles: FormValue[]): boolean =>
  !!file &&
  !(
    (Array.isArray(file) && !!file.reduce((accum, fileItem) => accum && pseudoFiles.includes(fileItem), true)) ||
    pseudoFiles.includes(file)
  );

export const getValueValidationMessage = (file: FormValue | null, pseudoFiles: FormValue[]): string | undefined => {
  if (!file || isValuePseudoFile(file, pseudoFiles)) {
    return undefined;
  }

  if (
    (Array.isArray(file) && file.reduce((accum, fileItem) => accum && !validate.isCsvFile(fileItem.type), true)) ||
    (!Array.isArray(file) && file && !validate.isCsvFile(file.type))
  ) {
    return content.invalidFileFormat;
  }
};
