import { SVGProps } from 'react';

export const People = ({ width = 16, height = 16, fill = '#494949', className }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.5 8C9.85747 8 9.23622 7.71313 8.74997 7.1925C8.27716 6.68469 7.98841 6.0075 7.93747 5.28625C7.88309 4.51688 8.11778 3.80938 8.59809 3.29375C9.07841 2.77813 9.74997 2.5 10.5 2.5C11.2447 2.5 11.9181 2.78312 12.3968 3.2975C12.8803 3.81687 13.1156 4.52312 13.0612 5.28594C13.009 6.00812 12.7206 6.685 12.2487 7.19219C11.7637 7.71312 11.1428 8 10.5 8ZM14.6197 13.5H6.38059C6.24813 13.5007 6.11726 13.471 5.99808 13.4132C5.87889 13.3554 5.77455 13.271 5.69309 13.1666C5.60669 13.0533 5.54702 12.922 5.51855 12.7824C5.49009 12.6429 5.49356 12.4987 5.52872 12.3606C5.79184 11.3041 6.44372 10.4278 7.41372 9.82687C8.27466 9.29375 9.37059 9 10.5 9C11.6515 9 12.7187 9.28125 13.5847 9.81406C14.5568 10.4119 15.2097 11.2931 15.4715 12.3625C15.5063 12.5006 15.5094 12.6448 15.4806 12.7843C15.4518 12.9238 15.3919 13.055 15.3053 13.1681C15.2239 13.2721 15.1198 13.3561 15.001 13.4136C14.8821 13.4711 14.7517 13.5007 14.6197 13.5ZM4.59372 8.125C3.49403 8.125 2.52715 7.1025 2.43747 5.84594C2.39309 5.20219 2.59372 4.60687 2.99997 4.17031C3.40184 3.73812 3.96872 3.5 4.59372 3.5C5.21872 3.5 5.78122 3.73938 6.18528 4.17406C6.59465 4.61406 6.79465 5.20812 6.74778 5.84656C6.65809 7.10281 5.69153 8.125 4.59372 8.125ZM6.64559 9.10781C6.0959 8.83906 5.38247 8.70469 4.59403 8.70469C3.6734 8.70469 2.77934 8.94469 2.07622 9.38031C1.27903 9.875 0.742777 10.5953 0.526214 11.465C0.494522 11.5901 0.491525 11.7207 0.517446 11.8471C0.543368 11.9736 0.597537 12.0925 0.675902 12.195C0.750261 12.2905 0.845522 12.3676 0.95436 12.4205C1.0632 12.4733 1.18271 12.5006 1.30371 12.5H4.77247C4.83101 12.5 4.8877 12.4794 4.93264 12.4419C4.97759 12.4044 5.00794 12.3523 5.0184 12.2947C5.02184 12.275 5.02622 12.2553 5.03122 12.2359C5.29622 11.1716 5.91715 10.2722 6.83465 9.61656C6.8684 9.59224 6.89554 9.55988 6.91362 9.52241C6.9317 9.48495 6.94015 9.44356 6.93819 9.40201C6.93624 9.36046 6.92394 9.32005 6.90243 9.28445C6.88091 9.24884 6.85085 9.21917 6.81497 9.19812C6.7659 9.16937 6.70965 9.13906 6.64559 9.10781Z"
      fill={fill}
    />
  </svg>
);
