import { Icon } from '@components';
import { content } from '@content';

import { DeploymentInfoField } from './DeploymentInfo.types';

export const DEP_INFO_FIELDS: DeploymentInfoField[] = [
  {
    label: content.smartContentFileName,
    icon: <Icon.File />,
  },
  {
    label: content.sfmcDirectory,
    icon: <Icon.Directory />,
    copyable: true,
  },
  {
    label: content.smartContentFilePublishDate,
    icon: <Icon.Calendar />,
  },
  {
    label: content.htmlEmailShellPublishDate,
    icon: <Icon.Phone />,
  },
];

export const DEP_INFO_FIELD_VALUE_PLACEHOLDER = '-';
