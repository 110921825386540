import { useCallback, useMemo } from 'react';

import { Client } from '@modules';

import { getDefaultValues, getValueValidationMessage, isValueNonPseudoFile } from './UploadEmailActivity.utils';
import { FormValue, FormValues } from './UploadEmailActivity.types';

export const useFormValues = (entity?: Client) => {
  const defaultValues = useMemo(() => getDefaultValues(entity), [entity]);

  const pseudoFiles = useMemo(() => Object.values(defaultValues).filter((value) => !!value), [defaultValues]);

  const validateValue = useCallback(
    (file: FormValue | null) => getValueValidationMessage(file, pseudoFiles),
    [pseudoFiles],
  );

  const getFreshValues = useCallback(
    (values: FormValues) => {
      const freshValues = new FormData();

      Object.entries(values)
        .filter(([...[, value]]) => isValueNonPseudoFile(value, pseudoFiles))
        .forEach(([id, value]) => freshValues.append(id, value as File));

      return freshValues;
    },
    [pseudoFiles],
  );

  return { defaultValues, validateValue, getFreshValues };
};
