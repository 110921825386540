const copySync = (value: string): boolean => {
  let success = false;
  const node = document.createElement('textarea');
  node.value = value;
  node.style.top = '0';
  node.style.left = '0';
  node.style.position = 'fixed';

  document.body.appendChild(node);

  node.focus();
  node.select();

  try {
    success = document.execCommand('copy');
  } catch (err) {
    success = false;
  } finally {
    document.body.removeChild(node);
  }

  return success;
};

export const copy = async (value: string): Promise<boolean> => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(value);
      return true;
    } catch (exc) {} // eslint-disable-line no-empty
  }

  return copySync(value);
};
