import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: 'none',
    },
    text: {
      color: variables.color.primary.lightPurple,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
      letterSpacing: variables.letterSpacing.sm,
      textDecoration: 'underline',
      '&:hover': {
        color: variables.color.secondary.blue,
      },
      '&[data-disabled="true"]': {
        color: variables.color.tertiary.gray,
      },
    },
    label: {
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
    },
    icon: {
      marginLeft: '0.7rem',
      fill: variables.color.primary.green,
    },
    error: {
      color: variables.color.secondary.orange,
      fontWeight: variables.font.weight.bold,
      fontSize: variables.font.size.sm,
      marginLeft: '2.8rem',
    },
    filename: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);
