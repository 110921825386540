import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * DivContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    topSection: {
      marginBottom: theme.spacing(1),
    },

    caption: {
      color: variables.color.primary.darkestGray,
    },

    titleWrapper: {
      padding: theme.spacing(2),
    },

    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '16px!important',
      lineHeight: '20px!important',
      color: `${variables.color.primary.darkPurple}!important`,
    },

    divContainer: {
      backgroundColor: variables.color.primary.white,

      '&[data-ui-type=primary]': {
        paddingBottom: theme.spacing(2),

        '& $topSection': {
          marginBottom: theme.spacing(2),
        },

        '& $caption': {
          marginBottom: theme.spacing(1),
        },
      },
    },

    imageSrcWrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    imageSrc: {
      width: '100%',
    },

    selectBtn: {
      marginLeft: theme.spacing(1),
      width: '6.5rem',
      height: '3.2rem',
    },

    deleteButton: {
      padding: theme.spacing(0.25),
      margin: theme.spacing(0, 1),
    },
  }),
);
