import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, Breadcrumbs, Button } from '@material-ui/core';

import { Icon, Menu, OptionItem } from '@components';
import { content } from '@content';
import { textTemplate, useHistory } from '@utils';

import { TopBarProps } from './TopBar.props';
import { TopBarContext } from './TopBar.context';
import { useStyles } from './TopBar.styles';
import { MENU_OPTIONS, MenuKeys, TOP_BAR_CTX_VALUE } from './TopBar.const';
import { businessUnitDeployments, deployment, enterprise, template, userClients } from '@modules';
import { useParams } from '@utils/searchQuery/searchQuery';
import { useAppDispatch } from '@store';
import { ChangeTemplateModal } from './ChangeTemplateModal';
import { updateTemplateExperienceIds } from '@routes/Deployments/Deployments.helpers';
import { TreatmentBuilderContext } from '@routes';

/**
 * TopBar component
 * @param onDeploymentChange - action on change deployment in picker
 * @param onBackClick - action on click back button. 'breadcrumbs' view only
 * @param onClientChange - action on change businessUnit in picker
 * @returns {JSX.Element}
 */
export const TopBar = ({
  onDeploymentChange,
  onExperienceChange,
  onBackClick,
  onClientChange,
  onMailFileChange,
  onTemplateChange,
}: TopBarProps): JSX.Element => {
  const styles = useStyles();

  const {
    sectionName,
    moduleType,
    moduleName,
    chosenTreatment,
    contentModuleName,
    editableImageName,
    moduleDeployment,
    currentEmailTemplate,
    lastEmailTemplateId,
    isTemplateIdChanged,
    isTemplateListVisible,
  } = {
    ...TOP_BAR_CTX_VALUE,
    ...useContext(TopBarContext)[0],
  };
  const [, setTopBarCtx] = useContext(TopBarContext);
  const dispatch = useAppDispatch();
  const [curbusinessUnitId, curEnterpriseId] = useParams('businessUnit', 'enterprise');
  const history = useHistory();
  const enterprises = enterprise.useListData();
  const curEnterprise = enterprises.find((enterpriseItem) => enterpriseItem.id === Number(curEnterpriseId));
  const curDeployment = deployment.useDeploymentData();
  const deployments = businessUnitDeployments.useData();
  const businessUnit = curEnterprise?.businessUnits.find(
    (businessUnitItem) => businessUnitItem.id === Number(curbusinessUnitId),
  );
  const [isChangeTemplateVisible, setIsChangeTemplateVisible] = useState(false);
  const { deployment: deploymentId, mailFile: mailFileId, template: templateId } = history.query;
  const currentClientData = userClients.useEntityData();
  const {
    data: { deploymentEmailTemplates },
  } = deployment.useInfoEmailTemplate();
  const isSaveTemplateDisabled = lastEmailTemplateId === templateId && !isTemplateIdChanged;

  useEffect(() => {
    dispatch(businessUnitDeployments.thunk.getAll(Number(curbusinessUnitId)));
  }, [curbusinessUnitId]);

  const onMenuClick = useCallback((option: OptionItem) => {
    const moduleModalClose = document.getElementById('module-modal-close');

    moduleModalClose?.click();

    switch (option.id) {
      case MenuKeys.CHANGE_EMAIL_TEMPLATE:
        setTopBarCtx((prev) => ({
          ...prev,
          isTemplateListVisible: true,
        }));
        break;
      case MenuKeys.MANAGE_DEPLOYMENT:
        setTopBarCtx((prev) => ({
          ...prev,
          isTemplateListVisible: false,
        }));
        break;
    }
  }, []);

  let breadCrumbsArray = [
    {
      label: 'Enterprise',
      value: curEnterprise?.name,
    },
    {
      label: 'Business Unit',
      value: businessUnit?.name,
    },
  ];

  if (sectionName) {
    breadCrumbsArray = breadCrumbsArray.concat([
      {
        label: 'Tool',
        value: sectionName,
      },
    ]);
  }

  if (curDeployment && sectionName === textTemplate(content.entityManager, { entity: content.deployment })) {
    breadCrumbsArray = breadCrumbsArray.concat([
      {
        label: 'Program',
        value: curDeployment?.program.name,
      },
      {
        label: 'Deployment',
        value: curDeployment?.name,
      },
    ]);
  }

  if (moduleDeployment && sectionName === textTemplate(content.entityManager, { entity: content.content })) {
    const curModuleDeployment = deployments.find((dep) => dep.name === moduleDeployment);

    breadCrumbsArray = breadCrumbsArray.concat([
      {
        label: 'Program',
        value: curModuleDeployment?.program.name,
      },
      {
        label: 'Deployment',
        value: moduleDeployment,
      },
    ]);
  }

  if (
    curDeployment &&
    sectionName === textTemplate(content.entityManager, { entity: content.deployment }) &&
    history.query.template
  ) {
    breadCrumbsArray = breadCrumbsArray.concat([
      {
        label: 'Template',
        value: curDeployment?.currentEmailTemplate?.name,
      },
    ]);
  }

  if (currentEmailTemplate && sectionName === textTemplate(content.entityManager, { entity: content.content })) {
    breadCrumbsArray = breadCrumbsArray.concat([
      {
        label: 'Template',
        value: currentEmailTemplate,
      },
    ]);
  }

  if (moduleType && moduleType !== content.singleModule && moduleName) {
    breadCrumbsArray.push({
      label: `${moduleType}`,
      value: moduleName,
    });
  }

  if (editableImageName) {
    breadCrumbsArray = breadCrumbsArray.concat([
      {
        label: 'Edit image',
        value: editableImageName,
      },
    ]);
  }

  if (chosenTreatment) {
    breadCrumbsArray.push({
      label: 'Segment',
      value: chosenTreatment.name,
    });
  }

  if (contentModuleName) {
    breadCrumbsArray.push({
      label: 'Content Module',
      value: contentModuleName,
    });
  }

  const handleShowChangeTemplate = () => {
    setIsChangeTemplateVisible(true);
  };

  const handleChangeTemplateClose = async () => {
    if (lastEmailTemplateId) {
      const firstMailFile = currentClientData?.mailFile[0];

      const selectionResult = await dispatch(template.thunk.selectTemplateToDeployment(lastEmailTemplateId));

      if (template.thunk.selectTemplateToDeployment.fulfilled.match(selectionResult)) {
        const tmplUpdated = await updateTemplateExperienceIds(lastEmailTemplateId, dispatch, deploymentEmailTemplates);

        if (tmplUpdated) {
          history.push('', { template: lastEmailTemplateId });
        }
      }

      if (deploymentId && !mailFileId && firstMailFile) {
        dispatch(deployment.thunk.attachMailFile({ deploymentId, mailFileId: firstMailFile.id }));
      }
      setIsChangeTemplateVisible(false);

      setTopBarCtx((prev) => ({
        ...prev,
        isTemplateIdChanged: false,
      }));
    }
  };

  const handleChangeTemplateConfirm = () => {
    setIsChangeTemplateVisible(false);
    setTopBarCtx((prev) => ({
      ...prev,
      isTemplateListVisible: false,
      isTemplateIdChanged: false,
    }));
  };

  return (
    <Box className={styles.topBar}>
      {breadCrumbsArray.length > 0 && (
        <Breadcrumbs separator={<Icon.ChevronRight />} classes={{ root: styles.crumbs }}>
          {breadCrumbsArray.map((crumb, index) => {
            const last = index === breadCrumbsArray.length - 1;

            return (
              <Box key={`crumb-${index}`} className={styles.crumbsActive}>
                <Typography classes={{ root: styles.crumbsLabel }}>{crumb.label}</Typography>
                <Typography classes={{ root: styles.crumbsValue }}>{crumb.value}</Typography>
              </Box>
            );
          })}
        </Breadcrumbs>
      )}
      {sectionName === 'Deployment Manager' && (
        <Box className={styles.actions}>
          {isTemplateListVisible && (
            <Button
              variant="contained"
              color="primary"
              disabled={isSaveTemplateDisabled}
              onClick={handleShowChangeTemplate}
            >
              {content.saveSelectedTemplate}
            </Button>
          )}
          <Menu buttonClassName={styles.menu} onClick={onMenuClick} options={MENU_OPTIONS} />
        </Box>
      )}
      {isChangeTemplateVisible && (
        <ChangeTemplateModal
          title="Are you sure you want to change the template?"
          submitLabel="Confirm changes"
          cancelLabel="Discard changes"
          onClose={handleChangeTemplateClose}
          onSubmit={handleChangeTemplateConfirm}
        />
      )}
    </Box>
  );
};
