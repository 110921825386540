import { createSlice } from '@reduxjs/toolkit';
import { MetaSchema } from '@modules';
import { useAppSelector } from '@store';

import * as thunk from './SignalLibrary.thunk';
import { initialState } from './SignalLibrary.state';
import { reducers, extraReducers } from './SignalLibrary.reducer';
import { SignalsSearchPayload, SignalLibraryFieldsData, SignalOptionsData } from './SignalLibrary.schema';

export * from './SignalLibrary.schema';
export * from './SignalLibrary.types';

const slice = createSlice({
  name: 'signalLibrary',
  initialState,
  reducers,
  extraReducers,
});

export const signalLibrary = {
  thunk,
  slice,
  actions: slice.actions,
  useFieldsMeta: (): MetaSchema =>
    useAppSelector(
      ({
        signalLibrary: {
          fields: { data, ...meta },
        },
      }) => meta,
    ),
  useFieldsData: (): SignalLibraryFieldsData =>
    useAppSelector(
      ({
        signalLibrary: {
          fields: { data },
        },
      }) => data,
    ),
  useListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        signalLibrary: {
          list: { data, ...meta },
        },
      }) => meta,
    ),
  useListData: (): SignalsSearchPayload =>
    useAppSelector(
      ({
        signalLibrary: {
          list: { data },
        },
      }) => data,
    ),
  useOptionsMeta: (): MetaSchema =>
    useAppSelector(
      ({
        signalLibrary: {
          options: { data, ...meta },
        },
      }) => meta,
    ),
  useOptionsData: (): SignalOptionsData =>
    useAppSelector(
      ({
        signalLibrary: {
          options: { data },
        },
      }) => data,
    ),
};
