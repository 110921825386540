import React from 'react';
import { Box, Button } from '@material-ui/core';

import { Typography } from '@components';

import { SwitchProps } from './Switch.props';
import { useStyles } from './Switch.styles';

export const Switch: React.FC<SwitchProps> = ({
  className = '',
  value,
  labels,
  disabled = false,
  onChange,
}): React.ReactElement => {
  const styles = useStyles();

  const handleClick = React.useCallback(() => onChange && onChange(!value), [onChange, value]);

  return (
    <Button
      className={`${styles.switch} ${className}`}
      variant="contained"
      color="secondary"
      data-labeled={!!labels}
      disabled={disabled}
      onClick={disabled ? undefined : handleClick}
    >
      <Box className={styles.content}>
        {labels && (
          <>
            <Typography.Body className={styles.backLabel} data-type="off">
              {labels.off}
            </Typography.Body>
            <Typography.Body className={styles.backLabel} data-type="on">
              {labels.on}
            </Typography.Body>
          </>
        )}
        <Box className={styles.thumb} data-on={value} data-labeled={!!labels}>
          {labels && <Typography.Body className={styles.thumbLabel}>{value ? labels.on : labels.off}</Typography.Body>}
        </Box>
      </Box>
    </Button>
  );
};
