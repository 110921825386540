import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';
import { image } from '@content';

import { SELECT_OPTION_HEIGHT } from './SearchSelect.const';
import { SelectProps } from './SearchSelect.props';

/**
 * Select component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    captionText: {
      marginBottom: '0.5rem',
    },

    container: {
      marginTop: '20px'
    },

    box: {
      position: 'relative',
      width: '200px',
    },

    icon: {
      position: 'absolute',
      right: '10px',
      top: '10px',
    },

    checkbox: {
      display: 'flex',
      alignItems: 'center',
      width: '16px',
    },

    validationMessage: {
      '&.MuiFormHelperText-root': {
        color: variables.color.semantic.negative,
        fontSize: variables.font.size.xs,
        fontWeight: variables.font.weight.medium,
        lineHeight: variables.lineHeight.xs,
        paddingLeft: theme.spacing(1),
      },
    },

    label: {
      '&.MuiFormLabel-root': {
        color: variables.color.primary.darkestGray,
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.semiBold,
        lineHeight: variables.lineHeight.sm,
        position: 'static',
        transform: 'none',
      },
      '&.Mui-focused': {
        color: variables.color.primary.gray,
      },
      '&.MuiFormLabel-filled + div > p[class*="placeholder"]': {
        display: 'none',
      },
      '&.Mui-disabled': {
        color: variables.color.tertiary.gray,
      },

      '&.Mui-error': {
        color: variables.color.semantic.negative,
      },
    },

    required: {
      padding: theme.spacing(0.5, 0, 0, 1.5),
    },

    dropdown: {
      background: '#fff',
      height: '200px',
      width: '100%',
      padding: '10px 0',
      overflow: 'scroll'
    },

    dropdownItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      '&:hover': {
        background: '#f3f3f3',
      },
    }
  }),
);

export const usePopoverStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      boxShadow: '1px 1px 7px rgba(208, 208, 206, 0.3)',
      marginTop: theme.spacing(0.5),
    },
  }),
);
