import { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';

import { Select } from '@components';
import { content } from '@content';

import { DeploymentPickerProps } from './DeploymentPicker.props';
import { useStyles } from './DeploymentPicker.styles';
import { filterDepsByProgram, findDepById, getDateOptions, getProgramOptions } from './DeploymentPicker.utils';

/**
 * DeploymentPicker component
 * @param className - class name for style overrides
 * @param value - selected deployment id
 * @param items - list of deployments in picker
 * @param onChange - action on change deployment in picker
 * @returns {JSX.Element}
 */
export const DeploymentPicker = ({
  className = '',
  value = 0,
  items = [],
  onChange,
}: DeploymentPickerProps): JSX.Element => {
  const styles = useStyles();

  const picked = useMemo(() => findDepById(items, value), [items, value]);

  const programDeps = useMemo(() => filterDepsByProgram(items, picked?.program.id || 0), [items, picked]);

  const handleProgramsChange = useCallback(
    (event) => {
      const programId = +event.target.value;
      const [nextPicked] = filterDepsByProgram(items, programId);

      onChange(nextPicked?.id || 0);
    },
    [onChange, items],
  );

  const handleDatesChange = useCallback(
    (event) => {
      onChange(+event.target.value || 0);
    },
    [onChange],
  );

  return (
    <Box className={`${styles.deploymentPicker} ${className}`}>
      <Select
        className={styles.select}
        helperText={content.program}
        value={`${picked?.program.id || ''}`}
        displayEmpty
        disabled={items.length === 0}
        options={getProgramOptions(items)}
        onChange={handleProgramsChange}
        uiType="tertiary"
      />
      <Select
        className={styles.select}
        helperText={content.deployment}
        value={`${picked?.id || ''}`}
        displayEmpty
        disabled={programDeps.length === 0}
        options={getDateOptions(programDeps)}
        onChange={handleDatesChange}
        uiType="tertiary"
      />
    </Box>
  );
};
