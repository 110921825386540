import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { Deployment, MetaSchema, BaseSchema } from '@modules';
import * as thunk from './ClientDeployments.thunk';
import { initialState } from './ClientDeployments.state';
import { reducers, extraReducers } from './ClientDeployments.reducer';

export * from './ClientDeployments.schema';

const slice = createSlice({
  name: 'businessUnitDeployments',
  initialState,
  reducers,
  extraReducers,
});

export const businessUnitDeployments = {
  thunk,
  slice,
  actions: slice.actions,
  usePreDeploymentMeta: (): MetaSchema =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          preDeployment: { data, ...meta },
        },
      }) => meta,
    ),
  useInMarketMeta: (): MetaSchema =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          inMarket: { data, ...meta },
        },
      }) => meta,
    ),
  useNonArchivedMeta: (): MetaSchema =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          nonArchived: { data, ...meta },
        },
      }) => meta,
    ),
  useMeta: (): MetaSchema =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          all: { data, ...meta },
        },
      }) => meta,
    ),
  useInMarketSortByDateMeta: (): MetaSchema =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          inMarketSortByDate: { data, ...meta },
        },
      }) => meta,
    ),
  usePreDeploymentData: (): Deployment[] =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          preDeployment: {
            data: { items },
          },
        },
      }) => items,
    ),
  useInMarketData: (): Deployment[] =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          inMarket: {
            data: { items },
          },
        },
      }) => items,
    ),
  useNonArchivedData: (): Deployment[] =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          nonArchived: { data },
        },
      }) => data,
    ),
  useData: (): Deployment[] =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          all: { data },
        },
      }) => data,
    ),
  useNonArchived: (): BaseSchema<Deployment[]> => useAppSelector((state) => state.businessUnitDeployments.nonArchived),
  useInMarketSortByDateData: (): Deployment[] =>
    useAppSelector(
      ({
        businessUnitDeployments: {
          inMarketSortByDate: { data },
        },
      }) => data,
    ),
};
