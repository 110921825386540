import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, IconButton, ModalProps as MuiModalProps } from '@material-ui/core';

import { Alert, Icon, Typography } from '@components';
import { content } from '@content';

import { ConfirmProps } from './Confirm.props';
import { useStyles } from './Confirm.styles';

/**
 * Confirm component
 * @returns {JSX.Element}
 */
export const Confirm = ({
  title = '',
  children,
  open,
  maxWidth = '41.6rem',
  borderRadius,
  padding = '0',
  bodyClassName = '',
  submitLabel = content.continue,
  cancelLabel = content.cancel,
  submitDisabled = false,
  skipSubmitArrow = false,
  alert,
  onCancel,
  onSubmit,
  ...rest
}: ConfirmProps): JSX.Element => {
  const styles = useStyles({ maxWidth, borderRadius, padding });
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    setShowAlert(!!alert);
  }, [alert]);

  const handleClose: MuiModalProps['onClose'] = useCallback(
    (event, reason) => {
      if (reason !== 'backdropClick') {
        onCancel();
      }
    },
    [onCancel],
  );

  const handleCloseAlert = () => setShowAlert(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{ className: styles.backdrop }}
      PaperProps={{ className: styles.paper }}
      {...rest}
    >
      <Box className={styles.header}>
        {title && <Typography.Display>{title}</Typography.Display>}
        <IconButton className={styles.close} onClick={onCancel}>
          <Icon.CloseOutline />
        </IconButton>
      </Box>
      {alert && showAlert && (
        <Box className={styles.alert}>
          <Alert description={alert} type="warning" onClose={handleCloseAlert} />
        </Box>
      )}
      <form onSubmit={onSubmit}>
        <Box className={`${styles.body} ${bodyClassName}`}>{children}</Box>
        <Box className={styles.footer}>
          <Button className={styles.action} variant="outlined" size="large" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button
            disabled={submitDisabled}
            className={styles.action}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {submitLabel}
            {!skipSubmitArrow && <Icon.ArrowRightOutline className={styles.arrow} />}
          </Button>
        </Box>
      </form>
    </Dialog>
  );
};
