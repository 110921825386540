import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { ImageLibraryPayload, MetaSchema } from '@modules';

import { initialState } from './ImageLibrary.state';
import { reducers, extraReducers } from './ImageLibrary.reducer';
import * as thunk from './ImageLibrary.thunk';
export * from './ImageLibrary.schema';

const slice = createSlice({
  name: 'imageLibrary',
  initialState,
  reducers,
  extraReducers,
});

export const imageLibrary = {
  thunk,
  slice,
  actions: slice.actions,
  useAllMeta: (): MetaSchema =>
    useAppSelector(
      ({
        imageLibrary: {
          all: { data, ...meta },
        },
      }) => meta,
    ),
  useAllData: (): ImageLibraryPayload => useAppSelector((root) => root.imageLibrary.all.data),
  useEntityMeta: (): MetaSchema =>
    useAppSelector(
      ({
        imageLibrary: {
          entity: { data, ...meta },
        },
      }) => meta,
    ),
  useSearchQuery: (): string | null => useAppSelector((root) => root.imageLibrary.search.data),
};
