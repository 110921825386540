import { memo } from 'react';
import { ButtonBase, IconButton } from '@material-ui/core';

import { Typography, Icon, Label } from '@components';
import { format } from '@utils';
import { content } from '@content';
import { useStyles } from './Signal.styles';
import { getStyle } from './Signal.utils';
import { SignalProps } from './Signal.props';

export const Signal = memo(({ provided, item, style, isDragging }: SignalProps): JSX.Element => {
  const styles = useStyles();

  return (
    <div
      /* eslint-disable react/jsx-props-no-spreading */
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      {...{ ref: provided.innerRef }}
      /* eslint-enable react/jsx-props-no-spreading */
      style={getStyle({ provided, style, isDragging })}
      className={styles.signal}
    >
      <div className={styles.leftContainer}>
        <ButtonBase>
          <Icon.DraggableV2 />
        </ButtonBase>
        <div className={styles.content}>
          <div className={styles.row}>
            <Typography.Headline title={item.name} display="inline" noWrap component="span" className={styles.text}>
              {item.name}
            </Typography.Headline>
            {item.error ? (
              <Label variant="badge" color="negative">
                {content.error}
              </Label>
            ) : item.isUploading ? (
              <Label variant="badge" color="positive">
                {content.inProgress}
              </Label>
            ) : (
              <Typography.Caption className={styles.text}>{`${format.number(
                item.totalSubscribers,
              )}`}</Typography.Caption>
            )}
          </div>
        </div>
      </div>
      {item.handleEditClick && item.signalType === 'composite' && (
        <IconButton data-id={item.id} onClick={item.handleEditClick}>
          <Icon.Pencil />
        </IconButton>
      )}
    </div>
  );
});

Signal.displayName = 'Signal';
