import { Box } from '@material-ui/core';

import { RightPanelLayoutProps } from './RightPanelLayout.props';
import { useStyles } from './RightPanelLayout.styles';

/**
 RightPanelLayout component
 @returns {JSX.Element}
 */

export const RightPanelLayout = ({ children }: RightPanelLayoutProps): JSX.Element => {
  const styles = useStyles();

  return <Box className={styles.rightPanel}>{children}</Box>;
};
