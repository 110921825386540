import { SVGProps } from 'react';

type TProps = {
  className?: string | undefined;
};
export const Pencil: React.FC<TProps> = ({ className }): React.ReactElement => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#pencil)">
      <path
        d="M14.7933 1.2069C14.5662 0.980771 14.2966 0.801879 14 0.680557C13.7033 0.559234 13.3856 0.497886 13.0651 0.500056C12.7447 0.502225 12.4278 0.56787 12.1328 0.693197C11.8379 0.818524 11.5707 1.00105 11.3467 1.23023L1.68 10.8969L0.5 15.5002L5.10333 14.3196L14.77 4.6529C14.9992 4.42899 15.1818 4.16186 15.3072 3.86695C15.4326 3.57204 15.4982 3.25521 15.5004 2.93477C15.5026 2.61432 15.4412 2.29663 15.3198 2.00005C15.1985 1.70347 15.0195 1.43389 14.7933 1.2069V1.2069Z"
        stroke="#003B5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0708 1.50586L14.4935 4.92853"
        stroke="#003B5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.68066 10.8965L5.10666 14.3165"
        stroke="#003B5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="pencil">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
