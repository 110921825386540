import { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';

import { content } from '@content';
import { useHistory } from '@utils';
import { useAppDispatch } from '@store';
import { template, deployment } from '@modules';
import { Typography, Icon, EmptyPlaceholder } from '@components';

import { TemplateItem } from './TemplateItem';

import { useStyles } from './TemplateList.styles';
import { TemplateListProps } from './TemplateList.props';

/**
 TemplateList component.
 @returns {JSX.Element}
 */
export const TemplateList = ({ items, selected, onSelect, onUpload }: TemplateListProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={styles.templateList}>
      <Box className={styles.header}>
        <Box className={styles.row}>
          <Typography.Title className={styles.title}>{content.selectATemplate}</Typography.Title>
          <Typography.Caption>{items.length}</Typography.Caption>
        </Box>
        <Button variant="outlined" color="primary" onClick={onUpload} startIcon={<Icon.AddCircleOutline />}>
          {content.upload}
        </Button>
      </Box>
      {items.length === 0 ? (
        <Box className={styles.emptyContainer}>
          <EmptyPlaceholder title={content.youDontHaveAnyTemplate} className={styles.emptyPlaceholder} />
        </Box>
      ) : (
        items.map((item) => (
          <TemplateItem key={item.id} item={item} selected={selected === item.id} onClick={onSelect} />
        ))
      )}
    </Box>
  );
};
