import { memo } from 'react';
import { Box } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';

import { Typography, Icon } from '@components';
import { content } from '@content';
import { DeploymentEmptyPosition } from './DeploymentEmptyPosition';
import { usePositionStyles } from './DeploymentBuilder.styles';

type DeploymentBuilderProps = {
  id: number | string;
  index: number;
  name: string;
  disabled: boolean;
};

const DeploymentBuilder = memo(({ id, name, index, disabled }: DeploymentBuilderProps): JSX.Element => {
  const styles = usePositionStyles();

  return (
    <Box className={styles.positionsListBox}>
      <Typography.Caption>{`${content.position} ${index + 1}`}</Typography.Caption>
      <Draggable draggableId={`position-${id}`} index={index} isDragDisabled={!name || disabled}>
        {(provided) => (
          <Box
            {...{ ref: provided.innerRef }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={styles.positionsListItem}
            data-empty={!name}
          >
            {name ? (
              <>
                <Icon.SmallDots className={styles.positionsListItemIcon} />
                <Typography.Body noWrap className={styles.positionsListItemLabel}>
                  {name}
                </Typography.Body>
              </>
            ) : (
              <DeploymentEmptyPosition index={index} />
            )}
          </Box>
        )}
      </Draggable>
    </Box>
  );
});

DeploymentBuilder.displayName = 'DeploymentBuilder';

type TemplateBuilderProps = {
  index: number;
  name: string;
  disabled: boolean;
  journeyId: number;
  onDragStart: (e: React.BaseSyntheticEvent) => void;
};

const TemplateBuilder = ({ name, index, disabled, journeyId, onDragStart }: TemplateBuilderProps): JSX.Element => {
  const styles = usePositionStyles();

  return (
    <Box className={styles.positionsListBox}>
      <Typography.Caption>{`${content.position} ${index + 1}`}</Typography.Caption>
      <Box
        className={styles.positionsListItem}
        draggable={!disabled}
        data-journey-id={journeyId}
        onDragStart={onDragStart}
      >
        <Icon.SmallDots className={styles.positionsListItemIcon} />
        <Typography.Body noWrap className={styles.positionsListItemLabel}>
          {name}
        </Typography.Body>
      </Box>
    </Box>
  );
};

export const Position = {
  DeploymentBuilder,
  TemplateBuilder,
};
