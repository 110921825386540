import { Field, FieldIdByKey } from './UploadEmailActivity.types';

export const FIELDS: Field[] = [
  {
    id: 'sendJobsMappingFile',
    key: 'sendJobsFileOriginalName',
    event: 'sendjobs',
  },
  {
    id: 'sendsFile',
    key: 'sendsFileOriginalName',
    event: 'sent',
  },
  {
    id: 'opensFile',
    key: 'opensFileOriginalName',
    event: 'open',
  },
  {
    id: 'clicksFile',
    key: 'clicksFileOriginalName',
    event: 'click',
  },
  {
    id: 'bouncesFile',
    key: 'bounceFileOriginalName',
    event: 'bounce',
  },
  {
    id: 'unsubscribesFile',
    key: 'unsubscribeFileOriginalName',
    event: 'unsubs',
  },
];

export const FIELD_ID_BY_KEY = FIELDS.reduce<FieldIdByKey>(
  (map, field) => ({
    ...map,
    [field.key]: field.id,
  }),
  {} as FieldIdByKey,
);
