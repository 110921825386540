import { memo } from 'react';
import { Box } from '@material-ui/core';

import { Icon, Typography } from '@components';
import { format } from '@utils';

import { TrendProps } from './Trend.props';
import { useStyle } from './Trend.styles';

/**
 * Trend component.
 @returns {JSX.Element}
 */

export const Trend = memo(({ trend }: TrendProps): JSX.Element => {
  const styles = useStyle();

  if (trend === 0) {
    return <></>;
  }

  return (
    <Box className={styles.trend}>
      {trend > 0 ? <Icon.TrendUp /> : <Icon.TrendDown />}
      <Typography.Tag className={`${styles.text} ${trend > 0 ? styles.textTrendUp : styles.textTrendDown}`}>
        {format.percent(Math.abs(trend))}
      </Typography.Tag>
    </Box>
  );
});

Trend.displayName = 'Trend';
