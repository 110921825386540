import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { experience } from '@modules';

import { RecommendedModulesSchema } from './RecommendedModules.schema';
import { initialState } from './RecommendedModules.state';
import { get } from './RecommendedModules.thunk';

export const reducers = {
  reset: (state: RecommendedModulesSchema): void => {
    Object.assign(state, initialState);
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<RecommendedModulesSchema>): void => {
  builder.addCase(get.pending, (state, action) => {
    if (state.status !== 'loading') {
      state.requestId = action.meta.requestId;
      state.status = 'loading';
    }
  });

  builder.addCase(get.fulfilled, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = initialState.requestId;
      state.status = 'success';

      if (action.payload) {
        const counts = experience.utils.contentCount(action.payload);

        state.data.items = action.payload;
        state.data.counts = counts;
      }
    }
  });

  builder.addCase(get.rejected, (state, action) => {
    if (state.status === 'loading' && state.requestId === action.meta.requestId) {
      state.requestId = initialState.requestId;
      state.status = 'error';
      state.error = action.error;
    }
  });
};
