import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Condition component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    signalTop: {
      display: 'flex',
      width: '100%',
    },
    treatmentTop: {
      display: 'flex',
      width: '100%',
    },
    label: {
      marginBottom: theme.spacing(1),
      '&[data-invalid="true"]': {
        color: variables.color.semantic.negative,
      },
    },
    signalBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '21.6rem',
      maxWidth: `calc(100% / 4 - ${theme.spacing(1)}px)`,
      flexShrink: 0,
      marginRight: theme.spacing(1),
    },
    treatmentBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '21.6rem',
      maxWidth: `200px`,
      flexShrink: 0,
      marginRight: theme.spacing(1),
    },
    signalFieldContainer: {
      maxHeight: '6.5rem',
    },
    placeholder: {
      color: variables.color.primary.halfMediumGray,
      '&[data-invalid="true"]': {
        color: variables.color.semantic.negative,
      },
    },
    signalField: {
      background: variables.color.primary.white,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: '1rem 0.4rem',
      height: '3.2rem',
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(1, 1.5),
      '&[data-invalid="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.semantic.negative}`,
        background: variables.color.semantic.backgroundNegative,
        color: variables.color.semantic.negative,
      },
    }
  }),
);
