import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { MetaSchema } from '@modules';
import * as thunk from './ArticleLibrary.thunk';
import { initialState } from './ArticleLibrary.state';
import { reducers, extraReducers } from './ArticleLibrary.reducer';
import { ArticleLibraryPayload } from './ArticleLibrary.schema';

export * from './ArticleLibrary.schema';

const slice = createSlice({
  name: 'articleLibrary',
  initialState,
  reducers,
  extraReducers,
});

export const articleLibrary = {
  slice,
  actions: slice.actions,
  thunk,
  useListData: (): ArticleLibraryPayload => useAppSelector((state) => state.articleLibrary.list.data),
  useListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        articleLibrary: {
          list: { data, ...meta },
        },
      }) => meta,
    ),
};
