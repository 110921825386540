import { memo } from 'react';
import { MenuItem } from '@material-ui/core';
import { useController, useWatch, useFormContext } from 'react-hook-form';

import { Select } from '@components';
import { array, format } from '@utils';
import { Condition } from '@routes';
import { useMetConditionStyles } from './Mindset.styles';
import { MindsetMetConditionProps } from './Mindset.props';

export const MindsetMetCondition = memo(({ index, defaultValue }: MindsetMetConditionProps): JSX.Element => {
  const styles = useMetConditionStyles();
  const { setValue } = useFormContext();
  const { field, fieldState } = useController({
    name: `mindsets.${index}.metConditionCount`,
    defaultValue,
    rules: {
      required: true,
      validate: (val) => val !== 0,
    },
  });

  const conditions: Condition[] = useWatch({
    name: `mindsets.${index}.conditions`,
  });

  const options =
    conditions.length > 1
      ? array.range(conditions.filter((i) => i.required).length, conditions.length).filter((i) => i !== 0)
      : [];

  return (
    <Select
      uiType="tertiary"
      className={styles.conditionSelect}
      displayEmpty
      value={field.value === 0 ? '' : field.value}
      inputRef={field.ref}
      error={fieldState.invalid}
      onChange={({ target: { value } }: any) => {
        setValue(field.name, value, { shouldValidate: true, shouldDirty: true });
      }}
      onBlur={field.onBlur}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {format.numberToWord(option)}
        </MenuItem>
      ))}
    </Select>
  );
});

MindsetMetCondition.displayName = 'MindsetMetCondition';
