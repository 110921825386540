import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    journeyContainer: {
      background: 'rgba(255, 255, 255, 0.7)',
      borderRadius: variables.borderRadius.sm,
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      padding: '2rem 1rem',
    },
    titleBar: {
      display: 'flex',
      padding: '0 1rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2.1rem',
      height: '3.4rem',
    },
    title: {
      fontWeight: variables.font.weight.semiBold,
    },
    journey: {
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: variables.borderRadius.sm,
      padding: '2rem 1rem',
      position: 'relative',
    },
    header: {
      paddingBottom: variables.gap.lg,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    menuBtn: {
      padding: variables.gap.sm,
    },
    journeyName: {
      flex: '1 1 auto',
      display: 'grid',
    },
    nameField: {
      flex: '0 1 25.7rem',
      maxWidth: '25.7rem',
    },
    collapseBtn: {
      padding: '0.9rem',
      transition: 'transform 0.3s',
      '&[data-collapsed="true"]': {
        transform: 'rotate(180deg)',
      },
    },
    mindsetsList: {
      marginBottom: '2.3rem',
    },
  }),
);

export const useMenuStyles = makeStyles(() =>
  createStyles({
    paper: {
      boxShadow: '1px 1px 5px rgba(0, 59, 92, 0.2)',
    },
    menuList: {
      paddingTop: '1.4rem',
      paddingBottom: '0.7rem',
      minWidth: '10.8rem',
    },
    menuItem: {
      paddingTop: 0,
      minHeight: '3.1rem',
      '&:hover': {
        backgroundColor: 'transparent',
        '& .MuiTypography-root': {
          fontWeight: variables.font.weight.bold,
          borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightPurple}`,
        },
      },
    },
  }),
);
