import { SVGProps } from 'react';

export const CloudDownloadOutline = ({
  width = 16,
  height = 16,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 22.5C11.8 22.5 11.6 22.4 11.5 22.3L8.5 19.3C8.2 19 8.2 18.5 8.5 18.2C8.8 17.9 9.3 17.9 9.6 18.2L11.3 19.9V10.5C11.3 10.1 11.6 9.7 12.1 9.7C12.6 9.7 12.9 10 12.9 10.5V19.9L14.6 18.2C14.9 17.9 15.4 17.9 15.7 18.2C16 18.5 16 19 15.7 19.3L12.7 22.3C12.4 22.4 12.2 22.5 12 22.5ZM18.6 16.5H15C14.6 16.5 14.2 16.2 14.2 15.7C14.2 15.2 14.6 15 15 15H18.6C20.4 15 22.5 14.5 22.5 12.2C22.5 9.8 19.6 9.4 18.7 9.4C18.3 9.4 18 9.1 18 8.7C17.6 5.3 15.2 3 12 3C9 3 7.3 5 6.7 6.8C6.6 7 6.4 7.2 6.1 7.3C4.7 7.4 1.5 8 1.5 11.1C1.5 13.8 3.9 15 6.4 15H9C9.4 15 9.8 15.3 9.8 15.8C9.8 16.3 9.4 16.5 9 16.5H6.4C2.6 16.5 0 14.3 0 11.1C0 8.3 2.1 6.3 5.5 5.8C6.4 3.6 8.7 1.5 12 1.5C15.8 1.5 18.7 4.1 19.4 8C21.6 8.3 24 9.5 24 12.2C24 14.9 22 16.5 18.6 16.5Z"
      fill={fill}
    />
  </svg>
);
