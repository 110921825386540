import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    moduleHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: variables.gap.lg,
    },
    productDropdown: {
      width: '35rem',
      marginRight: '6rem',
    },
    stageDropdown: {
      width: '35rem',
    },
  }),
);
