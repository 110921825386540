import { useState, useImperativeHandle, forwardRef, memo } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { content } from '@content';
import { Typography } from '@components';

import { useMenuStyles } from './Journey.styles';

type JourneyMenuProps = {
  setJourneyEdit: (() => void) | null;
  deleteJourney: () => void;
  isDeleteAvailable: boolean;
};

export type JourneyMenuHandle = {
  setAnchorEl: (v: EventTarget) => void;
};

const JourneyMenuWithRef = forwardRef(({ setJourneyEdit, deleteJourney, isDeleteAvailable }: JourneyMenuProps, ref) => {
  const styles = useMenuStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    if (setJourneyEdit) {
      setJourneyEdit();
      setAnchorEl(null);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      setAnchorEl,
    }),
    [],
  );

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      keepMounted
      onClose={handleCloseMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        className: styles.paper,
      }}
      MenuListProps={{
        className: styles.menuList,
      }}
    >
      <MenuItem disabled={!setJourneyEdit} onClick={handleEditClick} className={styles.menuItem}>
        <Typography.Body>{content.edit}</Typography.Body>
      </MenuItem>
      {isDeleteAvailable ? (
        <MenuItem onClick={deleteJourney} className={styles.menuItem}>
          <Typography.Body>{content.delete}</Typography.Body>
        </MenuItem>
      ) : null}
    </Menu>
  );
});

JourneyMenuWithRef.displayName = 'JourneyMenu';

export const JourneyMenu = memo(JourneyMenuWithRef);
