import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      padding: theme.spacing(0.25),
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2.3),
    },

    message: {
      fontWeight: variables.font.weight.medium,
      color: variables.color.primary.mediumGray,
      paddingLeft: 0,
      fontSize: variables.font.size.md,
    },
  }),
);
