import { TooltipProps as MuiTooltipProps } from '@material-ui/core';

import { TooltipAlign, TooltipGap, TooltipShift, TooltipSize, TooltipPos } from './Tooltip.props';

/**
 * Tooltip component constants variables
 * @returns {Variables}
 */

export const ALIGN_DEFAULT: TooltipAlign = 'start';

export const PLACEMENT_BY_ALIGN: Record<TooltipAlign, MuiTooltipProps['placement']> = {
  start: 'bottom-start',
  center: 'bottom',
  end: 'bottom-end',
  top: 'top',
};

export const GAP_DEFAULT: TooltipGap = 'xl';

export const SHIFT_BY_GAP: Record<TooltipGap, TooltipShift> = {
  xxxs: 'xxxl',
  xxs: 'xxl',
  xs: 'xl',
  sm: 'lg',
  md: 'md',
  lg: 'sm',
  xl: 'xs',
};

export const SHIFT_DEFAULT = undefined;

export const SIZE_DEFAULT: TooltipSize = 'md';

export const POS_DEFAULT: TooltipPos = 'abs';
