import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { TextAreaSize } from './TextArea.props';

export type DatasetSize = TextAreaSize;

export type DatasetState = 'default' | 'disabled' | 'error';

export enum DatasetKey {
  size = 'data-size',
  state = 'data-state',
}

export type Dataset = {
  [DatasetKey.size]: DatasetSize;
  [DatasetKey.state]: DatasetState;
};

export type ChildKey = 'label' | 'body' | 'textArea' | 'textAreaInput' | 'hint';

export type UseStyles = () => ClassNameMap<'textArea' | ChildKey>;
