import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const itemNameWidth = '14rem';

export const useStyles = makeStyles(() =>
  createStyles({
    deploymentsGrid: {
      paddingTop: variables.gap.xs,
      paddingBottom: variables.gap.sm,

      '&[data-bordered="true"]': {
        paddingLeft: '2.5rem',
        marginLeft: variables.gap.lg,
        borderLeft: `thin solid ${variables.color.tertiary.gray}`,
      },
    },

    grid: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateRows: '1fr 1fr 1fr',
      gridRowGap: variables.gap.sm,
      gridColumnGap: '4rem',
      marginTop: variables.gap.md,

      '&[data-wide="true"]': {
        gridTemplateColumns: 'repeat(2, minmax(20rem, 1fr))',
      },
    },

    item: {
      display: 'flex',
      alignItems: 'center',
    },

    itemName: {
      flex: `1 1 ${itemNameWidth}`,
      width: itemNameWidth,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    itemDate: {
      width: '7rem',
      textAlign: 'end',
    },

    itemView: {
      width: '6.9rem',
      marginLeft: variables.gap.lg,
    },
  }),
);
