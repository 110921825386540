import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * PerformingContentReport component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    performingContentReport: {
      width: '49%',
    },

    content: {
      background: variables.color.primary.white,
      padding: theme.spacing(3),
    },

    description: {
      display: 'inline-block',
      fontSize: variables.font.size.lg,
      fontWeight: variables.font.weight.medium,
      color: variables.color.primary.darkestGray,
      lineHeight: '2.1rem',

      '&:not(:only-child)': {
        marginBottom: theme.spacing(2.5),
      },

      '&:only-child': {
        marginBottom: 0,
      },
    },

    cards: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  }),
);
