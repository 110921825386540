import { SVGProps } from 'react';

export const ArrowLeftOutline = ({
  width = 16,
  height = 16,
  stroke = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.625 12.5L3.125 8L7.625 3.5M3.75 8H12.875"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
