import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    overallSummary: {
      padding: `${variables.gap.xl} ${variables.gap.sm}`,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '2rem',
      overflowX: 'hidden',
      paddingLeft: '2.2rem',
    },
    title: {
      fontSize: variables.font.size.xxxl,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.xxxl,
      letterSpacing: variables.letterSpacing.sm,
      color: variables.color.primary.gray,
      padding: `${variables.gap.lg}`,
    },
    overallMetrics: {
      width: '45%',
    },
    verticalDivider: {
      alignSelf: 'stretch',
      height: 'auto',
    },
    overallMetricsContainer: {
      flexGrow: 1,
      padding: `${variables.gap.xl} ${variables.gap.xl} 5.5rem`,
    },
    gridContainer: {
      padding: `${variables.gap.xs} 0 ${variables.gap.xs} ${variables.gap.xs}`,
      alignItems: 'center',
    },
    metricTitle: {
      width: '55%',
    },
    metricTrend: {
      width: '18%',
      textAlign: 'right',
      alignItems: 'center',
      paddingLeft: '2.1rem',
    },
    metricValue: {
      width: '27%',
      textAlign: 'right',
      fontSize: '2.4rem',
      fontWeight: variables.font.weight.light,
      letterSpacing: variables.letterSpacing.sm,
    },
  }),
);
