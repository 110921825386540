import { SVGProps } from 'react';

export const Apple = ({ width = 18, height = 21, fill = 'white', className }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.6227 16.3654C17.2996 17.0991 16.917 17.7744 16.4738 18.3953C15.8697 19.2418 15.3751 19.8277 14.9938 20.153C14.4029 20.6871 13.7698 20.9606 13.0917 20.9762C12.605 20.9762 12.018 20.8401 11.3348 20.564C10.6492 20.2891 10.0192 20.153 9.44321 20.153C8.83907 20.153 8.19115 20.2891 7.49811 20.564C6.80402 20.8401 6.24486 20.9839 5.81735 20.9982C5.16718 21.0254 4.51913 20.7441 3.87226 20.153C3.45939 19.7992 2.94297 19.1925 2.32433 18.3331C1.66057 17.4153 1.11488 16.3511 0.687366 15.1378C0.229518 13.8273 0 12.5583 0 11.3297C0 9.92233 0.309453 8.70852 0.929283 7.69134C1.41642 6.87431 2.06447 6.22981 2.87557 5.75668C3.68666 5.28355 4.56305 5.04244 5.50684 5.02702C6.02326 5.02702 6.70047 5.18399 7.54203 5.4925C8.38123 5.80205 8.92006 5.95903 9.15631 5.95903C9.33293 5.95903 9.93153 5.77548 10.9463 5.40954C11.9059 5.07018 12.7158 4.92967 13.3793 4.98502C15.1772 5.12761 16.5279 5.82408 17.4262 7.07886C15.8183 8.03628 15.0229 9.37726 15.0387 11.0975C15.0532 12.4375 15.5479 13.5525 16.52 14.4378C16.9606 14.8488 17.4526 15.1663 18 15.3919C17.8813 15.7302 17.756 16.0543 17.6227 16.3654ZM13.4993 0.42012C13.4993 1.47036 13.1089 2.45097 12.3306 3.3586C11.3915 4.43761 10.2555 5.06111 9.02361 4.96272C9.00792 4.83673 8.99881 4.70412 8.99881 4.56477C8.99881 3.55654 9.44545 2.47754 10.2386 1.59531C10.6346 1.14862 11.1382 0.777199 11.7489 0.480913C12.3583 0.189048 12.9348 0.0276404 13.4769 0C13.4927 0.140401 13.4993 0.28081 13.4993 0.420106V0.42012Z"
      fill={fill}
    />
  </svg>
);
