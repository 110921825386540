import { createSlice } from '@reduxjs/toolkit';

import { MetaSchema } from '@modules';
import { useAppSelector } from '@store';

import * as thunk from './Enterprise.thunk';
import { initialState } from './Enterprise.state';
import { reducers, extraReducers } from './Enterprise.reducer';
import { EnterpriseListData, EnterpriseEntityData } from './Enterprise.schema';

export * from './Enterprise.schema';
export * from './Enterprise.types';

export const enterprise = {
  thunk,

  slice: createSlice({
    name: 'enterprise',
    initialState,
    reducers,
    extraReducers,
  }),

  useListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        enterprise: {
          list: { data, ...meta },
        },
      }) => meta,
    ),

  useListData: (): EnterpriseListData =>
    useAppSelector((root) => {
      const all = root.enterprise.list.data as EnterpriseListData;

      return all.map((ntps) => ({
        ...ntps,
        businessUnits: ntps.businessUnits
          .filter((cli) => !cli.isArchived)
          .map((cli) => ({
            ...cli,
            programs: cli.programs?.filter((prg) => !prg.isArchived),
          })),
      }));
    }),

  useFullListData: (): EnterpriseListData => useAppSelector((root) => root.enterprise.list.data),

  useEntityMeta: (): MetaSchema =>
    useAppSelector(
      ({
        enterprise: {
          entity: { data, ...meta },
        },
      }) => meta,
    ),

  useEntityData: (): EnterpriseEntityData => useAppSelector((root) => root.enterprise.entity.data),
};
