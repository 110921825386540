import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { variables } from '@styles';

export interface StyleProps {
  maxWidth?: string;
  borderRadius?: string;
  padding?: string;
}

/**
 * Modal component styles
 * @returns {Function}
 */
export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    paper: {
      boxSizing: 'border-box',
      boxShadow: '0 0.4rem 0.4rem rgba(0, 0, 0, 0.25)',
      borderRadius: ({ borderRadius }) => borderRadius || variables.borderRadius.sm,
      padding: ({ padding }) => padding || theme.spacing(3),
      maxWidth: ({ maxWidth }) => maxWidth || '102.4rem',
      width: '100%',
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
      padding: 0,
      width: theme.spacing(4),
      height: theme.spacing(4),
      '& svg': {
        stroke: variables.color.primary.darkestGray,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          stroke: variables.color.primary.lightPurple,
        },
      },
    },
  }),
);
