import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { ExperienceMindsetTable, MetaSchema, MindsetData } from '@modules';

import * as utils from './Experience.utils';
import * as thunk from './Experience.thunk';
import { initialState } from './Experience.state';
import { reducers, extraReducers } from './Experience.reducer';
import { ExperienceListPayload, ExperienceItemState, Counts } from './Experience.schema';

export * from './Experience.schema';
export * from './Experience.types';

const slice = createSlice({
  name: 'experience',
  initialState,
  reducers,
  extraReducers,
});

export const experience = {
  utils,
  thunk,
  slice,
  actions: slice.actions,
  useItemMeta: (): MetaSchema =>
    useAppSelector(
      ({
        experience: {
          item: { data, ...meta },
        },
      }) => meta,
    ),
  useItemData: (): ExperienceItemState =>
    useAppSelector(
      ({
        experience: {
          item: { data },
        },
      }) => data,
    ),
  useListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        experience: {
          list: { data, ...meta },
        },
      }) => meta,
    ),
  useListData: (): ExperienceListPayload =>
    useAppSelector(
      ({
        experience: {
          list: { data },
        },
      }) => data,
    ),
  useCountsData: (): Counts[] =>
    useAppSelector(
      ({
        experience: {
          counts: { data },
        },
      }) => data,
    ),
  useCountsMeta: (): MetaSchema =>
    useAppSelector(
      ({
        experience: {
          counts: { data, ...meta },
        },
      }) => meta,
    ),
  useOverviewData: (): ExperienceMindsetTable =>
    useAppSelector(
      ({
        experience: {
          overview: { data },
        },
      }) => data,
    ),
  useOverviewMeta: (): MetaSchema =>
    useAppSelector(
      ({
        experience: {
          overview: { data, ...meta },
        },
      }) => meta,
    ),
  useMindsetsData: (): MindsetData[] =>
    useAppSelector(
      ({
        experience: {
          mindsets: { data },
        },
      }) => data,
    ),
  useMindsetsMeta: (): MetaSchema =>
    useAppSelector(
      ({
        experience: {
          mindsets: { data, ...meta },
        },
      }) => meta,
    ),
};
