import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const imageMaxSize = '47rem';

/**
 * MindsetPreviewModal component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    previewModal: {
      width: '100%',
      padding: '1.6rem',
      cursor: 'pointer',
    },
    imageContainer: {
      background: variables.color.primary.backgroundLightGray,
      borderRadius: variables.borderRadius.sm,
      height: '19rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
    },
    image: {
      border: `0.05rem solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.xs,
      maxWidth: '90%',
      maxHeight: imageMaxSize,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    resize: {
      padding: 0,
    },
    date: {
      color: variables.color.primary.darkGray,
    },
  }),
);
