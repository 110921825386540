import { SVGProps } from 'react';

export const ArrowPrev = ({ className, stroke, width = 40, height = 40 }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.0898 9.31445L12.4065 19.9978L23.0898 30.6812"
      stroke={stroke}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
