import { SVGProps } from 'react';

export const SearchOutline = ({
  width = 16,
  height = 16,
  stroke = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 3C6.20888 3 5.43552 3.2346 4.77772 3.67412C4.11992 4.11365 3.60723 4.73836 3.30448 5.46927C3.00173 6.20017 2.92252 7.00444 3.07686 7.78036C3.2312 8.55628 3.61216 9.26902 4.17157 9.82843C4.73098 10.3878 5.44372 10.7688 6.21964 10.9231C6.99556 11.0775 7.79983 10.9983 8.53073 10.6955C9.26164 10.3928 9.88635 9.88008 10.3259 9.22228C10.7654 8.56448 11 7.79112 11 7C10.9999 5.93915 10.5785 4.92178 9.82835 4.17165C9.07822 3.42152 8.06085 3.00007 7 3V3Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M10 10L13 13" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>
);
