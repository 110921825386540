import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

import { DataCardButtonType } from './DataCards.types';

const cardsGapX = variables.gap.sm;

const cardsGapY = variables.gap.sm;

const cardsPerRow = 3;

const cardInnerGapY = variables.gap.sm;

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: `-${cardsGapY} 0 0 -${cardsGapX}`,
    },

    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      width: `calc((100% - ${cardsGapX} * ${cardsPerRow}) / ${cardsPerRow})`,
      height: '21.7rem',
      margin: `${cardsGapY} 0 0 ${cardsGapX}`,
    },

    itemTitle: {
      display: 'flex',
      alignItems: 'flex-end',
      height: '45%',
    },

    itemButton: {
      width: '20.8rem',
      marginTop: cardInnerGapY,

      [`&[data-type="${DataCardButtonType.config}"]`]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },

    itemSubtitle: {
      marginTop: cardInnerGapY,
    },
  }),
);
