import { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Divider, Button, TextField } from '@material-ui/core';

import { Modal, Typography, Icon } from '@components';
import { content } from '@content';
import { variables } from '@styles';
import { textTemplate } from '@utils';

import { useStyles } from './ExperienceNameModal.styles';
import { ExperienceNameModalProps } from './ExperienceNameModal.props';
import { FormValues } from './ExperienceNameModal.types';

/**
 ExperienceNameModal route component.
 @returns {JSX.Element}
 */

export const ExperienceNameModal = ({
  open,
  onClose,
  onSubmit,
  defaultValue = '',
}: ExperienceNameModalProps): JSX.Element => {
  const styles = useStyles();
  const { control, handleSubmit, clearErrors, reset } = useForm<FormValues>({
    defaultValues: {
      name: defaultValue,
    },
  });

  const handleClose = useCallback(() => {
    onClose();
    clearErrors();
    reset({});
  }, [onClose, clearErrors, reset]);

  return (
    <Modal open={open} onClose={handleClose} maxWidth={'41.6rem'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={styles.experienceNameModal}>
          <Box className={styles.header}>
            {!defaultValue && (
              <Box className={styles.icon}>
                <Icon.Experience fill={variables.color.primary.darkestGray} />
              </Box>
            )}
            <Typography.Display className={styles.title}>
              {defaultValue ? content.renameModuleGroup : content.createModuleGroup}
            </Typography.Display>
          </Box>
          <Divider />
          <Box className={styles.content}>
            <Typography.Label className={styles.label}>{content.moduleGroupName}</Typography.Label>
            <Controller
              name="name"
              control={control}
              defaultValue={defaultValue}
              rules={{
                required: textTemplate(`*${content.enterValue}`, {
                  value: content.moduleGroupName,
                }),
              }}
              render={({ field: { ref, ...rest }, fieldState }) => (
                <TextField
                  autoFocus={false}
                  autoComplete="off"
                  fullWidth
                  placeholder={textTemplate(`${content.enterValue}`, {
                    value: content.moduleGroupName.toLowerCase(),
                  })}
                  inputRef={ref}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  {...rest}
                />
              )}
            />
          </Box>
          <Divider />
          <Box className={styles.footer}>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              {content.cancel}
            </Button>
            <Button type="submit" variant="contained" color="primary" className={styles.continueButton}>
              {defaultValue ? (
                content.save
              ) : (
                <>
                  {content.continue}
                  <Icon.ArrowRightOutline stroke={variables.color.primary.white} className={styles.arrowIcon} />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};
