import { useEffect, useState, useCallback, useContext } from 'react';
import { Link, Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { Box, MenuItem, AppBar, Tabs, Tab, Button } from '@material-ui/core';

import { businessUnitPrograms, businessUnitDeployments } from '@modules';
import { Select } from '@components';
import { useAppDispatch, history } from '@store';
import { content, page } from '@content';
import { searchQuery, useLoader, useWebTitle } from '@utils';
import { PositionLevelMetrics, ModulePerformanceByPosition, ModuleLevel, OverallSummary } from '@views';
import { TopBarContext, TOP_BAR_CTX_VALUE } from '@layouts';

import { useStyles, useTabStyles } from './Analytics.styles';

const tabs = [
  {
    label: content.overallSummary,
    url: page.overallSummary,
    Component: OverallSummary,
  },
  {
    label: content.modulePerformanceByPosition,
    url: page.modulePerformanceByPosition,
    Component: ModulePerformanceByPosition,
  },
  {
    label: content.positionLevelMetrics,
    url: page.positionLevelMetrics,
    Component: PositionLevelMetrics,
  },
  {
    label: content.moduleLevel,
    url: page.moduleLevel,
    Component: ModuleLevel,
  },
];

export function Analytics(): JSX.Element {
  const [, setTopBarCtx] = useContext(TopBarContext);

  const styles = useStyles();
  const tabClasses = useTabStyles();
  const dispatch = useAppDispatch();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [businessUnitId, deployment] = searchQuery.useMutualParams('businessUnit', 'deployment');
  const programs = businessUnitPrograms.useAllData();
  const deployments = businessUnitDeployments.useNonArchivedData();

  useLoader(businessUnitDeployments.useNonArchivedMeta(), businessUnitPrograms.useAllMeta());

  useWebTitle(content.analytics);

  const [programId, setProgramId] = useState(0);

  useEffect(() => {
    if (!businessUnitId) {
      return undefined;
    }
    const promise = dispatch(businessUnitDeployments.thunk.getNonArchived(businessUnitId));

    return () => {
      promise.abort();
      dispatch(businessUnitDeployments.actions.resetNonArchived());
    };
  }, [dispatch, businessUnitId]);

  useEffect(() => {
    if (!businessUnitId) {
      return history.push(page.dashboard);
    }
    const promise = dispatch(businessUnitPrograms.thunk.getAll(businessUnitId));

    return () => {
      promise.abort();
    };
  }, [dispatch, businessUnitId]);

  useEffect(() => {
    if (deployment && !programId) {
      const currentDeployment = deployments.find((item) => item.id === deployment);
      if (currentDeployment) {
        setProgramId(currentDeployment.program.id);
      }
    }
  }, [deployment, programId, deployments, setProgramId]);

  const [defaultPosition, setDefaultPosition] = useState('');

  const handleChange = () => {
    if (defaultPosition) {
      setDefaultPosition('');
    }
  };

  const onClickInPosition = (position: string) => {
    history.push(`${page.analytics}${page.positionLevelMetrics}${history.location.search}`);
    setDefaultPosition(position);
  };

  const handleChangeProgram = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const nextValue = event.target.value as number;
      setProgramId(nextValue);
    },
    [setProgramId],
  );

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      variant: 'picker',
      sectionName: content.analytics,
    }));

    return () => setTopBarCtx(TOP_BAR_CTX_VALUE);
  }, [setTopBarCtx]);

  return (
    <>
      {!history.location.pathname.includes(page.moduleLevel) && (
        <Box style={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
          <Select
            uiType="primary"
            captionText={content.programName}
            defaultValue={programId}
            value={programId || ''}
            onChange={handleChangeProgram}
            displayEmpty
            disabled={!programs.length}
          >
            {programs.map((program) => (
              <MenuItem key={program.id} value={program.id}>
                {program.name}
              </MenuItem>
            ))}
          </Select>
          {/* <Link to={page.myAccount2}> */}
          <Button color="default">My account</Button>
          {/* </Link> */}
        </Box>
      )}
      <Box>
        <AppBar position="static" className={styles.appBar}>
          <Tabs
            classes={{ indicator: styles.indicator }}
            variant="fullWidth"
            value={pathname}
            onChange={handleChange}
            TabIndicatorProps={{ children: <span /> }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.url}
                component={Link}
                label={tab.label}
                value={`${path}${tab.url}`}
                to={`${path}${tab.url}${history.location.search}`}
                classes={tabClasses}
              />
            ))}
          </Tabs>
        </AppBar>
        <Switch>
          {tabs.map(({ url, Component }) => (
            <Route key={url} path={`${path}${url}`}>
              <Box className={styles.tabPanel}>
                <Component
                  programId={programId}
                  defaultPosition={defaultPosition}
                  onClickInPosition={onClickInPosition}
                />
              </Box>
            </Route>
          ))}
        </Switch>
      </Box>
    </>
  );
}
