import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    moduleHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: variables.gap.lg,
    },

    content: {
      display: 'flex',
      'flex-direction': 'column',
      alignItems: 'flex-start',

      '&[data-wide="false"]': {
        width: '69.6rem',
      },

      '&[data-wide="true"]': {
        width: '100%',
      },
    },

    name: {
      maxWidth: '100%',
    },
  }),
);
