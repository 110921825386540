import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * ExperienceOverview styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    btnActions: {
      marginBottom: theme.spacing(3),

      '& > button:last-child': {
        marginLeft: theme.spacing(1),
      },
    },
    selectActions: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    mailFileSelectWrapper: {
      display: 'flex',
      alignItems: 'center',
      flex: '1',
    },
    mailFileSelect: {
      marginLeft: theme.spacing(1),
      maxWidth: '18rem',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }),
);
