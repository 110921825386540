import { Icon } from '@components';
import { content } from '@content';

import { Libraries } from '@routes/TemplateView/TemplateView.const';

/**
 LibraryPanel component variables
 @returns {variables}
 */

export const LIBRARIES = [
  {
    id: 'images',
    name: content.standard,
    svg: <Icon.ImageOutline />,
    library: Libraries.IMAGES,
  },
  {
    id: 'data-visualization',
    name: content.dynamic,
    svg: <Icon.DataVisualization />,
    library: Libraries.DATA_VISUAL,
  },
];
