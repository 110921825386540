import { AccordionProps as MuiAccordionProps } from '@material-ui/core';

/**
 * Accordion component props
 */

export enum UiTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
}

type AccordionUiType = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

export type AccordionProps = {
  expanded: boolean;
  onChange?: MuiAccordionProps['onChange'];
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  children: NonNullable<React.ReactNode>;
  uiType?: AccordionUiType;
  onEdit?: () => void;
};
