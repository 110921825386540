import { textTemplate } from '@utils';
import { content } from '@content';
import { MetaStatus } from '@modules';
import { MenuOptions } from '@components';

import { SideBarItems } from './SideBar';
import { PrivateNavItemValue } from './Private.types';

export const NAV_DEP_OPT_SPLITTER = '|';

export const NAV_DEP_PROGRAM_PREFIX = 'program';

export const NAV_DEP_PREFIX = 'deployment';

export const NAV_DEP_PROG_SUBS: MenuOptions = [
  {
    id: 'title',
    label: textTemplate(content.selectValue, { value: content.deployment }),
    disabled: true,
  },
  {
    id: 'title.divider',
    type: 'divider',
  },
  {
    id: 'create_deployment',
    value: PrivateNavItemValue.newDeployment,
    label: textTemplate(content.createValue, { value: content.deployment }),
    icon: 'AddCircleOutline',
  },
  {
    id: 'create_deployment.divider',
    type: 'divider',
  },
];

export const NAV_DEP_OPTS: MenuOptions = [
  {
    id: 'title',
    label: textTemplate(content.selectValue, { value: content.program }),
    disabled: true,
  },
  {
    id: 'title.divider',
    type: 'divider',
  },
  {
    id: 'create_program',
    value: PrivateNavItemValue.newProgram,
    label: textTemplate(content.createValue, { value: content.program }),
    icon: 'AddCircleOutline',
  },
  {
    id: 'create_program.divider',
    type: 'divider',
  },
];

export const NAV_ITEMS: SideBarItems = [
  {
    text: 'overview',
    icon: 'HomeOutline',
    page: 'dashboard',
    colr: 'fill',
  },
  {
    text: 'workflow',
  },
  {
    text: 'entityManager',
    data: { entity: 'deployment' },
    icon: 'DeploymentsOutline',
    subPage: 'deployments',
  },
  {
    text: 'entityManager',
    data: { entity: 'content' },
    icon: 'ContentOutline',
    page: 'contentCenter',
  },
  {
    text: 'moduleGroups',
    icon: 'ExperiencesOutline',
    page: 'experiences',
  },
  {
    text: 'entityManager',
    data: { entity: 'data' },
    icon: 'DataOutline',
    page: 'dataCenter',
  },
  {
    text: 'analytics',
    icon: 'ResultsOutline',
    page: 'analyticsOverall',
    colr: 'both',
  },
  {
    text: 'other',
  },
  {
    text: 'myAccount',
    icon: 'PersonCircle',
    page: 'myAccount',
    colr: 'fill',
  },
  {
    text: 'settings',
    icon: 'SettingsOutline',
    page: 'admin',
  },
  {
    text: 'logOut',
    icon: 'Logout',
    actn: 'logout',
    colr: 'fill',
  },
];

export const AUTHENTICATING_STATUSES: MetaStatus[] = ['idle', 'loading'];

export const THUNK_BLOCKING_STATUSES: MetaStatus[] = ['loading', 'success'];
