import { ReactElement, useCallback, ChangeEvent, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { variables } from '@styles';
import { content } from '@content';
import { BarChart, BarStackedChart } from '@components';
import { MailPartDropdown } from '@views';
import { analytics, mailParts, PositionVolumeDeploymentsDataSchema } from '@modules';
import { useAppDispatch } from '@store';
import { useLoader, useScrollableContainer } from '@utils';

import { useStyles } from './PositionLevelMetrics.styles';

export function PositionLevelMetrics({
  programId,
  defaultPosition,
}: {
  programId: number;
  defaultPosition: string;
}): ReactElement {
  const styles = useStyles();
  const ref = useScrollableContainer(null);
  const dispatch = useAppDispatch();
  const [currentMailPart, setMailPart] = useState('');
  const [tranformedVolumeData, setVolumeData] = useState<PositionVolumeDeploymentsDataSchema[]>([]);
  const [keysVolumeData, setKeysVolumeData] = useState<string[]>([]);

  const { deployments: numberModulesPerPositionData, average: numberModulesPerPositionAverage } =
    analytics.useNumberModulesPerPositionData();
  const numberModulesPerPositionMeta = analytics.useNumberModulesPerPositionMeta();

  const { deploymentsData: ctirPerPositionData, average: ctirPerPositionAverage } = analytics.useCtirPerPositionData();
  const ctirPerPositionMeta = analytics.useCtirPerPositionMeta();

  const { deploymentsData: positionVolumeData } = analytics.usePositionVolumeData();
  const positionVolumeMeta = analytics.usePositionVolumeMeta();

  const mailPartsData = mailParts.useMailPartsByProgramData();
  const mailPartsMeta = mailParts.useMailPartsByProgramMeta();

  useLoader(mailPartsMeta, numberModulesPerPositionMeta, ctirPerPositionMeta, positionVolumeMeta);

  const handleChangeMailPart = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const nextValue = event.target.value as string;
      setMailPart(nextValue);
      setKeysVolumeData([]);
      setVolumeData([]);
    },
    [setMailPart],
  );

  useEffect(() => {
    if (!programId) {
      return undefined;
    }

    setMailPart('');

    const promise = dispatch(
      mailParts.thunk.getMailPartsByProgram({
        programId,
      }),
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promise.then((res: any) => {
      if (defaultPosition) {
        setMailPart(defaultPosition);
      } else {
        setMailPart(res.payload[0]);
      }
    });
    return () => {
      dispatch(mailParts.actions.resetMailPartsByProgram());
      promise.abort();
    };
  }, [dispatch, programId, defaultPosition]);

  useEffect(() => {
    if (!programId || !currentMailPart) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getNumberOfModulesPerPosition({
        program: programId,
        mailPart: currentMailPart,
      }),
    );

    return () => {
      dispatch(analytics.actions.resetNumberModulesPerPosition());
      promise.abort();
    };
  }, [dispatch, currentMailPart, programId]);

  useEffect(() => {
    if (!programId || !currentMailPart) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getCtirPerPosition({
        program: programId,
        mailPart: currentMailPart,
      }),
    );

    return () => {
      dispatch(analytics.actions.resetCtirPerPosition());
      promise.abort();
    };
  }, [dispatch, currentMailPart, programId]);

  useEffect(() => {
    if (!positionVolumeData) {
      return undefined;
    }

    const tempData = [] as PositionVolumeDeploymentsDataSchema[];
    const tempKeys = [] as string[];

    positionVolumeData.map((item: PositionVolumeDeploymentsDataSchema, index) => {
      const { label, value, deploymentId } = item;
      for (let i = 0; i < item.modulesData.length; i += 1) {
        if (tempKeys.indexOf(item.modulesData[i].moduleName) === -1) {
          tempKeys.push(item.modulesData[i].moduleName);
        }
        const { moduleName } = item.modulesData[i];
        const modulePercent = `${moduleName}Percent`;
        const moduleCtir = `${moduleName}Ctir`;
        const moduleCohortName = `${moduleName}CohortName`;
        const moduleProductName = `${moduleName}ProductName`;
        tempData[index] = {
          ...tempData[index],
          label,
          value,
          deploymentId,
          [moduleName]: item.value * (Number(item.modulesData[i].volume) / 100),
          [modulePercent]: item.modulesData[i].volume,
          [moduleCtir]: item.modulesData[i].ctir,
          [moduleCohortName]: item.modulesData[i].cohortName,
          [moduleProductName]: item.modulesData[i].productName,
        };
        setKeysVolumeData(tempKeys);
        setVolumeData(tempData);
      }
      return null;
    });

    return () => {};
  }, [positionVolumeData]);

  useEffect(() => {
    if (!programId || !currentMailPart) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getPositionVolume({
        program: programId,
        mailPart: currentMailPart,
      }),
    );

    return () => {
      dispatch(analytics.actions.resetPositionVolume());
      promise.abort();
    };
  }, [dispatch, programId, currentMailPart]);

  return (
    <Box {...{ ref }} className={styles.positionLevelMetrics}>
      <Box className={styles.topContainer}>
        <Typography className={styles.title}>{content.positionLevelMetrics}</Typography>
        <Box>
          <MailPartDropdown
            mailParts={mailPartsData}
            currentMailPart={currentMailPart}
            onChange={handleChangeMailPart}
            status={mailPartsMeta.status}
          />
        </Box>
      </Box>
      <Box className={styles.row}>
        <BarChart
          className="modulesPerPosition"
          data={numberModulesPerPositionData}
          average={numberModulesPerPositionAverage}
          color={variables.color.charts.blueLight}
          title={content.numberOfModulesUsedPerPosition}
          unit={content.modules}
          legendLeft={content.numberOfModules}
          roundYAxeValues
        />
        <BarChart
          className="ctirPerPosition"
          data={ctirPerPositionData}
          average={ctirPerPositionAverage}
          color={variables.color.charts.purple}
          title={content.ctirPerPosition}
          unit={content.percentageUnit}
          legendLeft={`${content.ctir} %`}
        />
      </Box>
      <BarStackedChart title={content.positionVolumeOverTime} data={tranformedVolumeData} keys={keysVolumeData} />
    </Box>
  );
}
