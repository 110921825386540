import { memo } from 'react';
import { Box } from '@material-ui/core';

import { Typography } from '@components';
import { content } from '@content';
import { format } from '@utils';
import { useStyles } from './JourneyInfo.styles';
import { JourneyInfoProps } from './JourneyInfo.props';

export const JourneyInfo = memo(({ author, createdAt, updatedAt, lastUsed }: JourneyInfoProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={styles.journeyInfo}>
      <Box className={styles.journeyInfoBox}>
        <Typography.Caption className={styles.journeyInfoLabel}>{content.author}</Typography.Caption>
        <Typography.Caption className={styles.journeyInfoValue}>{author ?? '--'}</Typography.Caption>
      </Box>
      <Box className={styles.journeyInfoBox}>
        <Typography.Caption className={styles.journeyInfoLabel}>{content.created}</Typography.Caption>
        <Typography.Caption className={styles.journeyInfoValue}>
          {createdAt ? format.date(createdAt, 'MM/dd/yyyy') : '--'}
        </Typography.Caption>
      </Box>
      <Box className={styles.journeyInfoBox}>
        <Typography.Caption className={styles.journeyInfoLabel}>{content.edited}</Typography.Caption>
        <Typography.Caption className={styles.journeyInfoValue}>
          {updatedAt ? format.date(updatedAt, 'MM/dd/yyyy') : '--'}
        </Typography.Caption>
      </Box>
      <Box className={styles.journeyInfoBox}>
        <Typography.Caption className={styles.journeyInfoLabel}>{content.lastUsed}</Typography.Caption>
        <Typography.Caption className={styles.journeyInfoValue}>
          {lastUsed ? format.date(lastUsed, 'MM/dd/yyyy') : '--'}
        </Typography.Caption>
      </Box>
    </Box>
  );
});

JourneyInfo.displayName = 'JourneyInfo';
