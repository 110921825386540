import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ColorPicker component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    colorPicker: {
      position: 'relative',
    },

    colorField: {
      border: 'none',
      userSelect: 'none',
      outline: 'none',
      maxWidth: '80px',
    },

    button: {
      backgroundColor: variables.color.primary.white,
      marginBottom: theme.spacing(0.5),
      borderWidth: variables.borderWidth.sm,
      textTransform: 'uppercase',
      fontSize: variables.font.size.sm,
      fontWeight: variables.font.weight.medium,
      padding: theme.spacing(1),
      minWidth: '12.7rem',
      display: 'flex',
      justifyContent: 'space-between',

      '&&': {
        '&.Mui-disabled': {
          backgroundColor: variables.color.primary.lightGray,
          color: variables.color.primary.halfMediumGray,

          '& $currentColor': {
            backgroundColor: variables.color.primary.lightGray,
          },
        },
      },

      '&:hover': {
        borderWidth: variables.borderWidth.sm,
      },
    },

    currentColor: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: variables.borderRadius.xxs,
      marginLeft: theme.spacing(4),
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
    },

    container: {
      position: 'absolute',
      top: '3.9rem',
      zIndex: 1,
      width: 'fit-content',
      backgroundColor: variables.color.primary.white,
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
      padding: theme.spacing(1, 0.5, 0.5),

      '& .react-colorful__saturation': {
        borderRadius: `${variables.borderRadius.xs} ${variables.borderRadius.xs} 0 0`,
      },

      '& .react-colorful__last-control': {
        borderRadius: `0 0 ${variables.borderRadius.xs} ${variables.borderRadius.xs}`,
      },
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(0.75),
    },

    title: {
      textTransform: 'uppercase',
      color: variables.color.primary.mediumGray,
    },

    placeholder: {
      color: variables.color.primary.halfMediumGray,
      fontSize: '12px',
      textTransform: 'initial',
    },

    close: {
      padding: theme.spacing(0.1),
    },
  }),
);
