import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * AlertCircleOutlineV2 component
 * @returns {JSX.Element}
 */

export const AlertCircleOutlineV2 = ({
  className = '',
  width = 16,
  height = 16,
  fill = variables.color.primary.darkestGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00006 9.75C7.80789 9.75027 7.62296 9.67676 7.4834 9.54465C7.34384 9.41253 7.26031 9.23191 7.25006 9.04001L7.07007 5.22C7.0704 5.09427 7.09065 4.9694 7.13007 4.85001C7.17881 4.73615 7.2464 4.63135 7.33005 4.54001C7.41557 4.45578 7.51335 4.38496 7.62006 4.32999C7.73271 4.28808 7.85039 4.2612 7.97006 4.25C8.10247 4.25698 8.23347 4.2805 8.36005 4.32001C8.47297 4.37468 8.57742 4.44541 8.67007 4.53C8.75182 4.62085 8.81619 4.72592 8.86005 4.84C8.90342 4.96214 8.92705 5.09042 8.93005 5.22L8.75006 9.04001C8.73981 9.23191 8.65628 9.41253 8.51672 9.54465C8.37717 9.67676 8.19223 9.75027 8.00006 9.75Z"
      fill={fill}
    />
    <path
      d="M7.64989 11.3902C7.75382 11.4592 7.87518 11.4973 7.9999 11.5002C8.08541 11.4955 8.16891 11.4725 8.2448 11.4328C8.3207 11.3932 8.38722 11.3377 8.4399 11.2702C8.49878 11.2133 8.54517 11.1449 8.57616 11.0691C8.60715 10.9934 8.62205 10.912 8.61989 10.8302C8.62114 10.7063 8.5864 10.5847 8.51989 10.4802C8.45097 10.3802 8.35333 10.3035 8.23989 10.2602C8.12316 10.2206 7.99663 10.2206 7.8799 10.2602C7.75735 10.2798 7.64478 10.3396 7.5599 10.4302C7.47107 10.5164 7.41161 10.6283 7.38991 10.7502C7.36348 10.8706 7.37417 10.9963 7.42058 11.1105C7.467 11.2248 7.54694 11.3223 7.64989 11.3902Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.75C6.66498 14.75 5.35994 14.3541 4.24991 13.6124C3.13988 12.8707 2.27472 11.8165 1.76382 10.5831C1.25293 9.3497 1.11925 7.99251 1.3797 6.68314C1.64015 5.37376 2.28302 4.17102 3.22702 3.22702C4.17102 2.28302 5.37376 1.64015 6.68314 1.3797C7.99251 1.11925 9.3497 1.25293 10.5831 1.76382C11.8165 2.27472 12.8707 3.13988 13.6124 4.24991C14.3541 5.35994 14.75 6.66498 14.75 8C14.7474 9.7894 14.0353 11.5048 12.7701 12.7701C11.5048 14.0353 9.7894 14.7474 8 14.75ZM8 2.75C6.96165 2.75 5.94661 3.05792 5.08325 3.6348C4.21989 4.21167 3.54699 5.03159 3.14963 5.99091C2.75227 6.95022 2.64832 8.00583 2.85089 9.02423C3.05346 10.0426 3.55346 10.9781 4.28769 11.7123C5.02191 12.4465 5.95737 12.9465 6.97577 13.1491C7.99417 13.3517 9.04978 13.2477 10.0091 12.8504C10.9684 12.453 11.7883 11.7801 12.3652 10.9167C12.9421 10.0534 13.25 9.03835 13.25 8C13.2474 6.60843 12.6934 5.27461 11.7094 4.29062C10.7254 3.30662 9.39157 2.75264 8 2.75Z"
      fill={fill}
    />
  </svg>
);
