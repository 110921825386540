import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * EmptyPlaceholder component styles
 * @returns {Function}
 */
export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      paddingTop: variables.gap.xxs,
      marginBottom: variables.gap.xxs,
    },
  }),
);
