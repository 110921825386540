import { content } from '@content';
import { format } from '@utils';

import {
  FontFamilyOptions,
  FontCaseOptions,
  NumberFormatOptions,
  DateFormatOptions,
  UniversalContainerState,
} from './UniversalContainer.props';

/**
 * UniversalContainer component constants
 * @returns {Variables}
 */

export const FONT_FAMILY_OPTIONS: FontFamilyOptions = [
  {
    id: '1',
    label: content.arial,
    value: 'arial',
  },
  {
    id: '2',
    label: content.arialRounded,
    value: 'arialRounded',
  },
  {
    id: '3',
    label: content.helvetica,
    value: 'helvetica',
  },
  {
    id: '4',
    label: content.calibri,
    value: 'calibri',
  },
  {
    id: '5',
    label: content.timesNewRoman,
    value: 'timesNewRoman',
  },
  {
    id: '6',
    label: content.courier,
    value: 'courier',
  },
  {
    id: '7',
    label: content.courierNew,
    value: 'courierNew',
  },
];

export const FONT_CASE_OPTIONS: FontCaseOptions = [
  {
    id: '1',
    label: `${content.original} ${content.case}`,
    value: 'original',
  },
  {
    id: '2',
    label: `${content.upper} ${content.case}`,
    value: 'upper',
  },
  {
    id: '3',
    label: `${format.capitalize(content.lower)} ${content.case}`,
    value: 'lower',
  },
  {
    id: '4',
    label: `${content.sentence} ${content.case}`,
    value: 'sentence',
  },
  {
    id: '5',
    label: `${content.title} ${content.case}`,
    value: 'title',
  },
];

export const NUMBER_FORMAT_OPTIONS: NumberFormatOptions = [
  {
    id: '1',
    label: content.noSpecificFormat,
    value: 'noSpecificFormat',
  },
  {
    id: '2',
    label: content.currency,
    value: 'currency',
  },
  {
    id: '3',
    label: content.percentage,
    value: 'percentage',
  },
];

export const DATE_FORMAT_OPTIONS: DateFormatOptions = [
  {
    id: '1',
    label: '',
    value: 'MMMM dd',
  },
  {
    id: '2',
    label: '',
    value: 'MMMM dd, yyyy',
  },
  {
    id: '3',
    label: '',
    value: 'MM/dd',
  },
  {
    id: '4',
    label: '',
    value: 'MM/dd/yyyy',
  },
  {
    id: '5',
    label: '',
    value: 'EEEE, MMMM dd, yyyy',
  },
];

export const DEFAULT_VALUES: UniversalContainerState = {
  fontSize: 14,
};
