import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * RenameModuleModal component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    deployments: {
      '&&': {
        marginTop: theme.spacing(2),
      },
    },

    deploymentsTitle: {
      marginBottom: theme.spacing(1),
    },

    cards: {
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
    },

    card: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,

      '&:last-of-type': {
        borderBottom: 'none',
      },
    },

    cardInfo: {
      display: 'flex',
      alignItems: 'center',
    },

    cardStatus: {
      marginLeft: theme.spacing(0.625),
      color: variables.color.semantic.positive,
    },
  }),
);
