import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';
/**
 * ExperienceNameModal component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    experienceNameModal: {
      margin: -theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
    content: {
      padding: theme.spacing(3),
    },
    continueButton: {
      marginLeft: theme.spacing(1.25),
    },
    arrowIcon: {
      marginLeft: theme.spacing(1.25),
    },
    icon: {
      display: 'flex',
      padding: theme.spacing(1),
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      marginRight: theme.spacing(1.25),
    },
    label: {
      marginBottom: theme.spacing(1),
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
  }),
);
