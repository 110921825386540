import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * AlignLeft component
 * @returns {JSX.Element}
 */

export const AlignLeft = ({
  className = '',
  width = 16,
  height = 16,
  stroke = variables.color.primary.mediumGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="2" y1="7.75" x2="10" y2="7.75" stroke={stroke} strokeWidth="1.5" />
    <line x1="2" y1="4.75" x2="14" y2="4.75" stroke={stroke} strokeWidth="1.5" />
    <line x1="2" y1="10.75" x2="14" y2="10.75" stroke={stroke} strokeWidth="1.5" />
  </svg>
);
