import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    panel: {
      padding: `${theme.spacing(0, 0, 0, 3)} !important`,
    },

    row: {
      display: 'flex',
      alignItems: 'center',
    },

    title: {
      fontSize: variables.font.size.xl,
      fontWeight: variables.font.weight.extraBold,
      marginBottom: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },

    searchField: {
      backgroundColor: variables.color.primary.white,
      display: 'flex',
      margin: theme.spacing(3, 1.25, 1, 0),
    },
  }),
);
