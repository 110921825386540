import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    positionLevelMetrics: {
      padding: `${variables.gap.xl} ${variables.gap.sm}`,
    },
    row: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'flex',
      padding: `0 ${variables.gap.md} 0 4.5rem`,
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '2.5rem',
    },
    title: {
      display: 'inline',
      fontSize: variables.font.size.xxxl,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.xxxl,
      letterSpacing: variables.letterSpacing.sm,
      color: variables.color.primary.gray,
    },
  }),
);
