/*
  Menu Option (item) component styles.
*/

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    divider: {
      backgroundColor: variables.color.primary.lightGrayDarker,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    spinner: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(0.625, 0),

      '& > .MuiCircularProgress-root': {
        width: '2.4rem !important',
        height: '2.4rem !important',
        color: variables.color.semantic.neutral,
      },
    },
    paperChild: {
      marginLeft: theme.spacing(1.5),
      padding: theme.spacing(1),
      minWidth: '24rem',
      boxShadow: '0 0.4rem 1.6rem rgba(0, 0, 0, 0.2)',
      borderRadius: variables.borderRadius.md,
    },
    menuList: {
      padding: '0',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      color: variables.color.primary.darkestGray,
      fontWeight: variables.font.weight.bold,
      cursor: 'pointer',
      position: 'relative',
      fontSize: variables.font.size.md,
      padding: theme.spacing(1),
      paddingRight: theme.spacing(4),
      borderRadius: variables.borderRadius.sm,
      backgroundColor: 'transparent',
      minHeight: 'auto',
      '&.Mui-selected': {
        backgroundColor: variables.color.primary.mainPurple,
        '& svg path': {
          stroke: variables.color.primary.white,
        },
        '& > span': {
          color: variables.color.primary.white,
        },
        '&:hover': {
          backgroundColor: variables.color.primary.mainPurple,
          '& > span': {
            color: variables.color.primary.white,
          },
          '& svg path': {
            stroke: variables.color.primary.white,
          },
        },
      },
      '&.Mui-selected.no-background': {
        backgroundColor: variables.color.primary.lighterPurple,
        '& > span': {
          color: variables.color.primary.mainPurple,
        },
        '&:hover': {
          backgroundColor: variables.color.primary.lighterPurple,
          '& > span': {
            color: variables.color.primary.mainPurple,
          },
          '& svg path': {
            color: variables.color.primary.mainPurple,
          },
        },
      },
      '&.menu-item-with-loading': {
        backgroundColor: '#F7F0FF',
      },
      '&.menu-item-with-loading span': {
        color: '#6B11C9!important',
      },
      '&.menu-item-with-loading:hover': {
        backgroundColor: '#F7F0FF!important',
      },
      '&:hover': {
        backgroundColor: variables.color.primary.lighterPurple,
        color: variables.color.primary.mainPurple,
        '& > span': {
          color: variables.color.primary.mainPurple,
        },
        '& svg path': {
          stroke: variables.color.primary.mainPurple,
        },
      },
      '& svg': {
        position: 'absolute',
        right: theme.spacing(1),
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
    menuItemTop: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    menuItemLabel: {
      '&&': {
        width: '100%',
        fontWeight: variables.font.weight.bold,
        color: variables.color.primary.darkestGray,

        '&.capitalized': {
          textTransform: 'capitalize',
        },
      },
      '.Mui-selected &': {
        color: variables.color.primary.white,
      },
    },
    subtitle: {
      marginTop: theme.spacing(0.25),
    },
    description: {
      marginLeft: theme.spacing(1.5),
    },
    iconColorless: {
      '& path': {
        stroke: `${variables.color.primary.mediumGray} !important`,
      },
    },
  }),
);
