import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    moduleCreator: {
      display: 'flex',
      flexDirection: 'column',
    },

    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      margin: `${variables.gap.lg} -${variables.gap.sm} 0 0`,
    },

    contentModuleCreator: {
      position: 'relative',
      flexGrow: 1,
    },

    contentWrap: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },

    content: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: variables.gap.sm,
      flexGrow: 1,
    },

    stations: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',

      '&[data-wide="true"]': {
        width: '100%',
      },

      '&[data-wide="false"]': {
        width: 'calc(100% - 25.8rem)',
      },
    },

    station: {
      '&:not(:first-child)': {
        marginTop: variables.gap.sm,
      },
    },

    substation: {
      margin: `0 ${variables.gap.lg}`,
      backgroundColor: variables.color.tertiary.gray,
    },

    htmlWrap: {
      '& [contenteditable]': {
        maxHeight: '15rem',
      },
    },

    template: {
      marginLeft: variables.gap.sm,
    },
  }),
);
