import { ModulesSortBy, ModulesSearchType } from '@modules';

/**
 * ContentSlidesheet component constants
 * @returns {Variable}
 */

const currentSearchType: ModulesSearchType = 'deployment';

const currentSortBy: ModulesSortBy = 'name';

export const initialQuery = {
  keyword: '',
  page: 1,
  businessUnitId: '',
  itemsPerPage: 20,
  onlyRecent: true,
  sortBy: currentSortBy,
  searchType: currentSearchType,
};
