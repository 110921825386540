import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * DataAttributeLibrary component styles
 * @returns {Function}
 */

const SMALL_VIEW_WIDTH = '1530px';

export const useSignalStyles = makeStyles((theme) =>
  createStyles({
    dataAttributeLibrary: {
      width: '29.2rem',
      display: 'flex',
      flexDirection: 'column',
      backdropFilter: 'unset',
      padding: 0,
      minWidth: '30.8rem',
      flexBasis: '30.8rem',
      alignSelf: 'flex-start',
      position: 'sticky',
      top: 0,
    },
    header: {},
    title: {
      fontWeight: variables.font.weight.bold,
      padding: theme.spacing(1, 1, 1, 2),
      fontSize: variables.font.size.xxxs,
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGrayDarker}`,
      color: variables.color.primary.mediumGray,
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    label: {
      color: variables.color.primary.gray,
      width: '20%',
    },
    searchField: {
      width: '14.5rem',

      '& > div': {
        height: '3rem',
      },
    },
    dropdown: {
      width: '9.1rem',
    },
    listWrapper: {
      padding: theme.spacing(3),
      width: '29.2rem',
    },
    list: {
      marginRight: `-${variables.gap.sm}`,
    },
    signal: {
      width: '26.6rem !important',
    },
  }),
);

export const useTreatmentStyles = makeStyles((theme) =>
  createStyles({
    dataAttributeLibrary: {
      display: 'flex',
      flexDirection: 'column',
      backdropFilter: 'unset',
      padding: 0,
      minWidth: '30.8rem',
      flexBasis: '30.8rem',
      alignSelf: 'flex-start',
      position: 'sticky',
      top: 0,

      [`@media (max-width: ${SMALL_VIEW_WIDTH})`]: {
        minWidth: '100%',
        width: '100%',
      },
    },
    header: {
      padding: theme.spacing(3, 0, 0.5, 3),
    },
    title: {
      fontWeight: variables.font.weight.bold,
      marginBottom: theme.spacing(0.5),
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    label: {
      color: variables.color.primary.gray,
      width: '20%',
    },
    searchField: {
      width: '100%',

      '& > div': {
        height: '3rem',
      },
    },
    dropdown: {
      width: '9.1rem',
    },
    listWrapper: {
      padding: theme.spacing(3),
      width: '31.5rem',

      [`@media (max-width: ${SMALL_VIEW_WIDTH})`]: {
        padding: theme.spacing(1.5),
        maxWidth: '100%',
      },
    },
    list: {
      marginRight: `-${variables.gap.sm}`,

      [`@media (max-width: ${SMALL_VIEW_WIDTH})`]: {
        marginRight: 0,
      },
    },
    signal: {
      width: '26.6rem !important',
    },
  }),
);
