import { BadgeColorByContentStatus } from './MindsetView.types';

/**
 * MindsetView component constants
 */

export const BADGE_COLORS: BadgeColorByContentStatus = {
  none: 'transparent',
  html: 'green',
};
