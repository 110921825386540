import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import debounce from 'lodash.debounce';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { Icon, Menu, TextField, TextFieldProps } from '@components';
import { variables } from '@styles';
import { IconDirections } from '@components/Menu/Menu.constants';
import { Image } from '@modules';
import { useAppDispatch } from '@store';

import { useOnClickOutside } from './useOnClickOutside';
import { ImageLibraryContent } from './ImageLibraryContent';

import { ImageLibraryProps } from './ImageLibrary.props';
import { DividedImages, FormValues } from './ImageLibrary.types';
import { useStyles } from './ImageLibrary.styles';
import { headerMenuOptions, ImageLibraryLayout } from './ImageLibrary.constants';
import { divideImagesIntoTwoGroups, getConvertedImagesByWidth } from './ImageLibrary.utils';

/**
 * ImageLibrary component
 * @returns {JSX.Element}
 */

export const ImageLibrary = ({ libraryImages, onCancel }: ImageLibraryProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const contentRef = useRef<HTMLDivElement | null>(null);

  const { control, setValue } = useForm<FormValues>({
    defaultValues: { name: '' },
  });

  const [nameSuggestions, setNameSuggestions] = useState<TextFieldProps['suggestions']>([]);
  const [layout, setLayout] = useState(ImageLibraryLayout.GRID);
  const [images, setImages] = useState<Image[] | DividedImages | null>(null);

  useOnClickOutside(contentRef, onCancel);

  const fetchSuggestions = debounce(
    useCallback(async () => {
      setNameSuggestions(['name1', 'name2']);
    }, [setNameSuggestions]),
    500,
  );

  const handleChangeImageName: TextFieldProps['onChange'] = useCallback(
    (name, suggested) => {
      setValue('name', name);

      if (suggested || name.length < 2) {
        setNameSuggestions([]);
      } else {
        fetchSuggestions();
      }
    },
    [setValue, fetchSuggestions],
  );

  const handleLayoutChange = useCallback(() => {
    setLayout((prevState) => {
      const sizedImages = getConvertedImagesByWidth(libraryImages);

      if (prevState === ImageLibraryLayout.GRID) {
        setImages(sizedImages);

        return ImageLibraryLayout.LIST;
      }

      setImages(divideImagesIntoTwoGroups(sizedImages));

      return ImageLibraryLayout.GRID;
    });
  }, [setImages, setLayout, libraryImages]);

  const handleMenuOpen = useCallback(() => {
    //TODO: write logic for Browser Image Library
  }, []);

  useEffect(() => {
    const sizedImages = getConvertedImagesByWidth(libraryImages);

    setImages(divideImagesIntoTwoGroups(sizedImages));
  }, [libraryImages, setImages, dispatch]);

  return (
    <Box className={styles.imageLibraryWrapper}>
      <Box className={styles.imageLibrary} {...{ ref: contentRef }}>
        <Box className={styles.header}>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                className={styles.search}
                size="small"
                inputRef={ref}
                placeholder={content.search}
                suggestions={nameSuggestions}
                onChange={handleChangeImageName}
                icon="SearchOutline"
              />
            )}
          />
          <ToggleButtonGroup
            className={styles.toggle}
            value={layout}
            exclusive
            onChange={handleLayoutChange}
            aria-label="Library Type"
          >
            <ToggleButton
              className={styles.tab}
              data-left-tab
              value={ImageLibraryLayout.GRID}
              aria-label="Your Library"
            >
              <Icon.GridOutline
                stroke={
                  layout === ImageLibraryLayout.GRID
                    ? variables.color.primary.mainPurple
                    : variables.color.primary.mediumGray
                }
              />
            </ToggleButton>
            <ToggleButton className={styles.tab} data-right-tab value={ImageLibraryLayout.LIST} aria-label="Templates">
              <Icon.ListOutline
                stroke={
                  layout === ImageLibraryLayout.GRID
                    ? variables.color.primary.mediumGray
                    : variables.color.primary.mainPurple
                }
              />
            </ToggleButton>
          </ToggleButtonGroup>
          <Menu onClick={handleMenuOpen} iconDirection={IconDirections.VERTICAL} options={headerMenuOptions} />
        </Box>
        <ImageLibraryContent images={images} layout={layout} />
      </Box>
    </Box>
  );
};
