import { ExperienceItemMindset, ExperienceItemPayload, ExperienceItemState, ExperiencePayload } from '@modules';
import { DATA_FILLED_ATTRIBUTE, DATA_SPLITTER_ATTRIBUTE, HTMLElementWithDocument } from '@routes/TemplateView';

/**
 * Treatments component utils
 */

export const preparePayload = (
  businessUnitId: number,
  data: ExperienceItemState,
  mindsetsList: ExperienceItemMindset[],
  experienceName?: string,
) => ({
  businessUnit: {
    id: businessUnitId,
  },
  name: experienceName || data?.name,
  mindsets: mindsetsList.map(({ id: mindsetId, name, metConditionCount, conditions }, mindsetIndex) => ({
    ...(mindsetId && { id: mindsetId }),
    index: mindsetIndex,
    name,
    metConditionCount,
    conditions: conditions?.map(({ id: conditionId, signal, operator, value, required }, conditionIndex) => ({
      ...(conditionId && { id: conditionId }),
      index: conditionIndex,
      signal: {
        id: signal?.id as number,
      },
      operator,
      value,
      required,
    })),
  })),
  index: 0,
});

export const prepareRenamePayload = (
  businessUnitId: number,
  data: ExperienceItemState,
  mindsetsList: ExperienceItemMindset[],
  deployment: { id: number },
  createFromExperience?: { id: number },
  experienceName?: string,
): ExperiencePayload => ({
  businessUnit: {
    id: businessUnitId,
  },
  name: experienceName || data?.name,
  deployment,
  mindsets: mindsetsList.map(({ id: mindsetId, name, metConditionCount, conditions }, mindsetIndex) => ({
    ...(mindsetId && { id: mindsetId }),
    index: mindsetIndex,
    name,
    metConditionCount,
    conditions: conditions?.map(({ id: conditionId, signal, operator, value, required }, conditionIndex) => ({
      ...(conditionId && { id: conditionId }),
      index: conditionIndex,
      signal: {
        id: signal?.id as number,
      },
      operator,
      value,
      required,
    })),
  })),
  index: 0,
  createFromExperience,
});

export const getExperienceElement = (payload: ExperienceItemPayload, chosenExperienceId: string) => {
  const { contentDocument } = document.getElementById('template') as HTMLElementWithDocument;
  const experienceElements = contentDocument.querySelectorAll(`[${DATA_FILLED_ATTRIBUTE}]`);
  const splittersArray = Array.from(experienceElements);

  const experienceElem = splittersArray.find((elem) => {
    const elemDataFilledAttr = elem.getAttribute(DATA_FILLED_ATTRIBUTE);

    if (elemDataFilledAttr === chosenExperienceId) {
      return elem;
    }
  });

  experienceElem?.setAttribute(DATA_FILLED_ATTRIBUTE, String(payload.id));

  return experienceElem?.getAttribute(DATA_SPLITTER_ATTRIBUTE);
};
