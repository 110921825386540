import { createAsyncThunk } from '@store';
import { network } from '@utils';
import { ApiError, global } from '@modules';
import { ArticleLibraryPayload } from './ArticleLibrary.schema';

export const getList = createAsyncThunk('articleLibrary/getList', async (arg, { rejectWithValue, dispatch }) => {
  try {
    dispatch(global.actions.isLoading(true));
    return await network.get<ArticleLibraryPayload>({ key: 'upcontent_topics' });
  } catch (err) {
    return rejectWithValue(err as ApiError);
  } finally {
    dispatch(global.actions.isLoading(false));
  }
});
