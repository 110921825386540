import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { TemplateSchema } from './Template.schema';
import { initialState } from './Template.state';
import { getAll, create, copy, update } from './Template.thunk';

export const reducers = {
  resetAll: (state: TemplateSchema): void => {
    state.all = initialState.all;
  },
  resetEntity: (state: TemplateSchema): void => {
    state.entity = initialState.entity;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<TemplateSchema>): void => {
  builder.addCase(getAll.pending, (state, action) => {
    if (state.all.status !== 'loading') {
      state.all.requestId = action.meta.requestId;
      state.all.data = initialState.all.data;
      state.all.status = 'loading';
    }
  });

  builder.addCase(getAll.fulfilled, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.data = action.payload || initialState.all.data;
      state.all.status = 'success';
    }
  });

  builder.addCase(getAll.rejected, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.error = action.error;
      state.all.status = 'error';
    }
  });

  builder.addCase(create.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(create.fulfilled, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.data = action.payload || initialState.entity.data;
      if (action.payload) {
        state.all.data.push(action.payload);
      }
      state.entity.status = 'success';
    }
  });

  builder.addCase(create.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = action.error;
      state.entity.status = 'error';
    }
  });

  builder.addCase(copy.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(copy.fulfilled, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.data = action.payload || initialState.entity.data;
      state.entity.status = 'success';
    }
  });

  builder.addCase(copy.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = action.error;
      state.entity.status = 'error';
    }
  });

  builder.addCase(update.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(update.fulfilled, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.data = action.payload || initialState.entity.data;
      state.entity.status = 'success';
    }
  });

  builder.addCase(update.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = action.error;
      state.entity.status = 'error';
    }
  });
};
