import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const imageMaxSize = '47rem';

/**
 * MindsetFullModal component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    mindsetFullModal: {
      margin: -theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(0.5),
      fontSize: variables.font.size.xxxl,
      lineHeight: variables.lineHeight.xxxxl,
      letterSpacing: '-0.02rem',
      color: variables.color.primary.lightBlack,
    },
    date: {
      color: variables.color.primary.darkGray,
    },
    gridTitle: {
      fontWeight: variables.font.weight.bold,
      marginBottom: theme.spacing(0.5),
    },
    gridInfo: {
      fontWeight: variables.font.weight.medium,
    },
    header: {
      padding: theme.spacing(3),
    },
    content: {
      padding: theme.spacing(3),
    },
    imageContainer: {
      background: variables.color.primary.backgroundLightGray,
      borderRadius: variables.borderRadius.sm,
      height: '30rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
    },
    image: {
      border: `0.06rem solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.xs,
      maxWidth: '90%',
      maxHeight: imageMaxSize,
    },
    rulesItem: {
      height: '4.9rem',
      border: `0.1rem solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 3),
    },
    rulesBody: {
      display: 'flex',
      alignItems: 'center',
    },
    counter: {
      marginRight: theme.spacing(3),
    },
    argument: {
      margin: theme.spacing(0, 1),
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
  }),
);
