import { Box, Button, InputBase, Tab, Tabs } from '@material-ui/core';
import { useCallback, useContext, useEffect, useState } from 'react';

import { Typography } from '@components';
import { content } from '@content';

import { DisclosureText } from './DisclosureText';

import { EditMode } from '@components/Wysiwyg/EditMode';
import { TopBarContext } from '@layouts';
import { ApiError, experience, global, modules } from '@modules';
import { useAppDispatch } from '@store';
import { useStyles } from './ModuleSettings.styles';

/**
 ModuleSettings component
 @returns {JSX.Element}
 */

type TProps = {
  module?: any;
  onEditorChange?: (value: string) => void;
};
export const ModuleSettings: React.FC<TProps> = ({ module, onEditorChange }): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const [topBarCtx, setTopBarCtx] = useContext(TopBarContext);
  const [tab, setTab] = useState(0);
  const [isEditTitleModeOn, setIsEditTitleModeOn] = useState(false);
  const [panelTitleValue, setPanelTitleValue] = useState(module.name);
  const handleChangeTab = useCallback((event: React.ChangeEvent<unknown>, value: number) => setTab(value), []);

  const handleTriggerPanelTitle = () => {
    setIsEditTitleModeOn((prev) => !prev);
  };

  const cancelEditPanelTitle = () => {
    handleTriggerPanelTitle();
    setPanelTitleValue(module.name);
  };

  const handlePanelTitleValueChange = (e: any) => {
    setPanelTitleValue(e.target.value);
  };

  const handleModuleRename = async () => {
    const resp = await dispatch(
      modules.thunk.renameModuleTemplate({
        module: module.id,
        templateName: panelTitleValue,
      }),
    );

    if (resp.meta.requestStatus === 'rejected') {
      await dispatch(
        global.actions.enqueueNotification({
          message: 'To rename the content module, please, provide a unique name across the Business Unit',
          options: { variant: 'warning' },
        }),
      );
      handleTriggerPanelTitle();

      return;
    }

    await dispatch(experience.actions.updateModule(resp.payload as any));

    setTopBarCtx((prev) => ({
      ...prev,
      contentModuleName: panelTitleValue,
      chosenTreatment: {
        ...prev.chosenTreatment,
        moduleTemplateName: panelTitleValue,
      } as any,
    }));

    handleTriggerPanelTitle();
  };

  useEffect(() => {
    setPanelTitleValue(module.name);
  }, [module]);

  return (
    <Box className={styles.moduleSettings}>
      <Box className={styles.header}>
        <Box className={styles.titleWrapper}>
          {isEditTitleModeOn ? (
            <Box className={styles.renameBar}>
              <InputBase
                onDoubleClick={handleTriggerPanelTitle}
                value={panelTitleValue}
                onChange={handlePanelTitleValueChange}
              />
              <Box className={styles.renameBarButtons}>
                <Button className={styles.editModule} variant="outlined" color="primary" onClick={cancelEditPanelTitle}>
                  {content.cancel}
                </Button>
                <Button className={styles.editModule} variant="contained" color="primary" onClick={handleModuleRename}>
                  {content.save}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box onDoubleClick={handleTriggerPanelTitle}>
              <Typography.Title className={styles.title}>{module.name}</Typography.Title>
            </Box>
          )}
          <EditMode />
        </Box>
        <Box className={styles.tabsWrapper}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            classes={{ root: styles.tabs }}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <Tab
              label={content.disclosureText}
              classes={{ root: styles.tab, selected: styles.selectedTab, wrapper: styles.tabWrapper }}
            />
          </Tabs>
        </Box>
      </Box>
      <Box className={styles.content}>{tab === 0 && <DisclosureText module={module} />}</Box>
    </Box>
  );
};
