import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import {
  create,
  update,
  getListDataVisualization,
  getListSignal,
  getDataVisualizationDeployments,
  generateDeploymentDataVisualizationImages,
  getDataVisualization,
  getDataVisualizationImages,
} from './DataVisualization.thunk';
import { initialState } from './DataVisualization.state';
import { DataVisualizationSchema } from './DataVisualization.schema';

export const reducers = {
  resetDataVisualizationList: (state: DataVisualizationSchema): void => {
    state.dataVisualizationList = initialState.dataVisualizationList;
  },
  setDVIsImageGenerationRunning: (
    state: DataVisualizationSchema,
    action: PayloadAction<{ deployment: any; segment?: any; isImageGenRunning: boolean }>,
  ): void => {
    const deployments = JSON.parse(JSON.stringify(state.deployments.data));
    const curDeployment = deployments[action.payload.deployment?.label];

    if (!action.payload.segment) {
      curDeployment?.forEach((segmentItem: any) => {
        segmentItem.isImageGenerationRunning = action.payload.isImageGenRunning;
        segmentItem.channelOpened = true;
      });
    } else {
      const curSegment = curDeployment.find((segmentItem: any) => segmentItem.id === action.payload.segment.id);

      curSegment.isImageGenerationRunning = action.payload.isImageGenRunning;
      curSegment.channelOpened = true;
      curSegment.transferChannelOpened = true;
      curSegment.handled = action.payload.segment.handled;
      curSegment.total = curSegment.total && action.payload.segment.total > 0 ? action.payload.segment.total : 0;
    }

    state.deployments.data = deployments;
  },
  setDVIsImageTransferRunning: (
    state: DataVisualizationSchema,
    action: PayloadAction<{
      deployment: any;
      segment: any;
      isImagetransferRunning: boolean;
      shouldShiftGenHistory?: boolean;
    }>,
  ): void => {
    const deployments = JSON.parse(JSON.stringify(state.deployments.data));
    const curDeployment = deployments[action.payload.deployment?.label];
    const curSegment = curDeployment.find((segmentItem: any) => segmentItem.id === action.payload.segment.id);

    curSegment.imageTransferRunning = action.payload.isImagetransferRunning;
    curSegment.handled = action.payload.segment.handled;
    curSegment.total = curSegment.total && action.payload.segment.total > 0 ? action.payload.segment.total : 0;

    if (!action.payload.isImagetransferRunning) {
      curSegment.channelOpened = false;
      curSegment.transferChannelOpened = false;
    }

    if (!action.payload.shouldShiftGenHistory) {
      state.deployments.data = deployments;

      return;
    }

    if (action.payload.isImagetransferRunning && curSegment.dataVisualizationGenerationHistory.length === 3) {
      curSegment.dataVisualizationGenerationHistory.shift();

      curSegment.dataVisualizationGenerationHistory.push({
        id: -1,
        generatedImagesCount: action.payload.segment.total,
        imageGenerationStartedAt: '',
        imageGenerationFinishedAt: new Date() as any,
      });
    } else if (curSegment.dataVisualizationGenerationHistory.length < 3 && action.payload.isImagetransferRunning) {
      curSegment.dataVisualizationGenerationHistory.push({
        id: 0,
        generatedImagesCount: action.payload.segment.total,
        imageGenerationStartedAt: '',
        imageGenerationFinishedAt: new Date() as any,
      });
    }

    state.deployments.data = deployments;
  },
  resetEntity: (state: DataVisualizationSchema): void => {
    state.entity = initialState.entity;
  },
  resetDataVisualizationImages: (state: DataVisualizationSchema): void => {
    state.dataVisualizationimages = initialState.dataVisualizationimages;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<DataVisualizationSchema>): void => {
  builder.addCase(create.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(create.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(create.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(update.pending, ({ entity }, action) => {
    if (entity.status !== 'loading') {
      entity.requestId = action.meta.requestId;
      entity.data = initialState.entity.data;
      entity.error = initialState.entity.error;
      entity.status = 'loading';
    }
  });

  builder.addCase(update.fulfilled, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.data = action.payload || initialState.entity.data;
      entity.status = 'success';
    }
  });

  builder.addCase(update.rejected, ({ entity }, action) => {
    if (entity.status === 'loading' && entity.requestId === action.meta.requestId) {
      entity.requestId = initialState.entity.requestId;
      entity.error = {
        message: action.payload?.message || action.error.message,
      };
      entity.status = 'error';
    }
  });

  builder.addCase(getListDataVisualization.pending, ({ dataVisualizationList }, action) => {
    if (dataVisualizationList.status !== 'loading') {
      dataVisualizationList.requestId = action.meta.requestId;
      dataVisualizationList.status = 'loading';
      dataVisualizationList.error = initialState.dataVisualizationList.error;
    }
  });

  builder.addCase(getListDataVisualization.fulfilled, ({ dataVisualizationList }, action) => {
    if (dataVisualizationList.status === 'loading' && dataVisualizationList.requestId === action.meta.requestId) {
      dataVisualizationList.requestId = '';
      dataVisualizationList.status = 'success';
      if (action.payload) {
        dataVisualizationList.data = action.payload
          ? {
              ...action.payload,
              items: (Array.isArray(dataVisualizationList.data)
                ? (dataVisualizationList.data as unknown as any[])
                : dataVisualizationList.data.items
              )
                .filter((item) => !action.payload?.items.find((payloadItem) => payloadItem.id === item.id))
                .concat(action.payload.items),
            }
          : initialState.dataVisualizationList.data;
      }
    }
  });

  builder.addCase(getDataVisualization.pending, ({ dataVisualization }, action) => {
    if (dataVisualization.status !== 'loading') {
      dataVisualization.requestId = action.meta.requestId;
      dataVisualization.status = 'loading';
      dataVisualization.error = initialState.dataVisualizationList.error;
    }
  });

  builder.addCase(getDataVisualization.fulfilled, ({ dataVisualization }, action) => {
    if (dataVisualization.status === 'loading' && dataVisualization.requestId === action.meta.requestId) {
      dataVisualization.requestId = '';
      dataVisualization.status = 'success';
      if (action.payload) {
        dataVisualization.data = action.payload as any;
      }
    }
  });

  builder.addCase(getListDataVisualization.rejected, ({ dataVisualizationList }, action) => {
    if (dataVisualizationList.status === 'loading' && dataVisualizationList.requestId === action.meta.requestId) {
      dataVisualizationList.requestId = '';
      dataVisualizationList.status = 'error';
      dataVisualizationList.error = {
        message: action.payload?.message || action.error.message,
      };
      dataVisualizationList.data = initialState.dataVisualizationList.data;
    }
  });

  builder.addCase(getListSignal.pending, ({ signalList }, action) => {
    if (signalList.status !== 'loading') {
      signalList.requestId = action.meta.requestId;
      signalList.status = 'loading';
      signalList.error = initialState.signalList.error;
    }
  });

  builder.addCase(getListSignal.fulfilled, ({ signalList }, action) => {
    if (signalList.status === 'loading' && signalList.requestId === action.meta.requestId) {
      signalList.requestId = '';
      signalList.status = 'success';
      if (action.payload) {
        signalList.data = action.payload;
      }
    }
  });

  builder.addCase(getListSignal.rejected, ({ signalList }, action) => {
    if (signalList.status === 'loading' && signalList.requestId === action.meta.requestId) {
      signalList.requestId = '';
      signalList.status = 'error';
      signalList.error = {
        message: action.payload?.message || action.error.message,
      };
      signalList.data = initialState.signalList.data;
    }
  });

  builder.addCase(getDataVisualizationDeployments.pending, ({ deployments }, action) => {
    if (deployments.status !== 'loading') {
      deployments.requestId = action.meta.requestId;
      deployments.status = 'loading';
      deployments.error = initialState.signalList.error;
    }
  });

  builder.addCase(getDataVisualizationDeployments.fulfilled, ({ deployments }, action) => {
    if (deployments.status === 'loading' && deployments.requestId === action.meta.requestId) {
      deployments.requestId = '';
      deployments.status = 'success';
      if (action.payload) {
        deployments.data = action.payload;
      }
    }
  });

  builder.addCase(getDataVisualizationDeployments.rejected, ({ deployments }, action) => {
    if (deployments.status === 'loading' && deployments.requestId === action.meta.requestId) {
      deployments.requestId = '';
      deployments.status = 'error';
      deployments.error = {
        message: action.payload?.message || action.error.message,
      };
      deployments.data = initialState.signalList.data;
    }
  });

  builder.addCase(generateDeploymentDataVisualizationImages.pending, ({ deployments }, action) => {
    if (deployments.status !== 'loading') {
      deployments.requestId = action.meta.requestId;
      deployments.error = initialState.entity.error;
      deployments.status = 'loading';
    }
  });

  builder.addCase(generateDeploymentDataVisualizationImages.fulfilled, ({ deployments }, action) => {
    if (deployments.status === 'loading' && deployments.requestId === action.meta.requestId) {
      deployments.requestId = initialState.entity.requestId;
      deployments.status = 'success';
    }
  });

  builder.addCase(generateDeploymentDataVisualizationImages.rejected, ({ deployments }, action) => {
    if (deployments.status === 'loading' && deployments.requestId === action.meta.requestId) {
      deployments.requestId = initialState.entity.requestId;
      deployments.error = {
        message: action.payload?.message || action.error.message,
      };
      deployments.status = 'error';
    }
  });

  builder.addCase(getDataVisualizationImages.pending, ({ dataVisualizationimages }, action) => {
    if (dataVisualizationimages.status !== 'loading') {
      dataVisualizationimages.requestId = action.meta.requestId;
      dataVisualizationimages.status = 'loading';
      dataVisualizationimages.error = initialState.dataVisualizationList.error;
    }
  });

  builder.addCase(getDataVisualizationImages.fulfilled, ({ dataVisualizationimages }, action) => {
    if (dataVisualizationimages.status === 'loading' && dataVisualizationimages.requestId === action.meta.requestId) {
      dataVisualizationimages.requestId = '';
      dataVisualizationimages.status = 'success';
      if (action.payload) {
        dataVisualizationimages.data = {
          ...action.payload,
          items: [...dataVisualizationimages.data.items, ...action.payload.items],
        };
      }
    }
  });

  builder.addCase(getDataVisualizationImages.rejected, ({ dataVisualizationimages }, action) => {
    if (dataVisualizationimages.status !== 'loading') {
      dataVisualizationimages.requestId = action.meta.requestId;
      dataVisualizationimages.status = 'loading';
      dataVisualizationimages.error = initialState.dataVisualizationList.error;
    }
  });
};
