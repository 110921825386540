import { Icon } from '@components';
import { Box, IconButton } from '@material-ui/core';

import { MODULE_MODAL_Y_OFFSET_CORREC_NUM } from '../SampleTemplate.const';

import { useWrappModalHtmlElems } from './ModuleModal.hooks';
import { ModuleModalProps } from './ModuleModal.props';
import { useStyles } from './ModuleModal.styles';
import { useContext, useEffect, useRef, useState } from 'react';
import { variables } from '@styles';
import { HTMLElementWithDocument } from '../SampleTemplate.types';
import { TreatmentBuilderContext } from '@routes/TemplateView/TemplateView.const';
import { useAppDispatch } from '@store';
import { modules } from '@modules';

/**
 ModuleModal component
 @returns {JSX.Element}
 */

export const ModuleModal = ({
  modalTopPosition,
  moduleHtmlElem,
  isTemplateEditor = false,
  isEditorChanged,
  setIsEditorChanged,
  setRightPanelElem,
  onChange,
  onClose,
  onRemove,
}: ModuleModalProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { redefinedDoc } = useWrappModalHtmlElems(
    moduleHtmlElem,
    isEditorChanged,
    'module-modal',
    setRightPanelElem,
    onChange,
  );
  const templateIframe = document.getElementById('template') as HTMLElementWithDocument;
  // const moduleModalIframe = document.getElementById('module-modal') as HTMLElementWithDocument;
  const templateHeight = templateIframe?.getBoundingClientRect().height;
  const { setDVItems, setModuleDataVisualizationStation } = useContext(TreatmentBuilderContext);
  const iframeRef = useRef(null);

  useEffect(() => {
    return () => {
      setModuleDataVisualizationStation({});
      setDVItems([]);
      setIsEditorChanged(false);
      dispatch(modules.actions.resetModuleInfo());
    };
  }, [setModuleDataVisualizationStation, setDVItems, dispatch]);

  return (
    <Box
      style={{
        paddingTop: `calc(24.7rem + ${modalTopPosition - MODULE_MODAL_Y_OFFSET_CORREC_NUM}px)`,
        height: templateHeight ? `${templateHeight + 50}px` : 'calc(150vh + 24.2rem)',
        backgroundColor: isTemplateEditor ? variables.color.primary.backgroundLighterGray : '#f6f7f999',
      }}
      className={styles.moduleModalWrapper}
    >
      <Box id="module-content" className={styles.moduleContent}>
        <iframe ref={iframeRef} id="module-modal" className={styles.iframe} srcDoc={redefinedDoc} />
        <IconButton id="module-modal-close" className={styles.closeBtn} onClick={onClose}>
          <Icon.CloseOutline stroke={variables.color.primary.white} />
        </IconButton>
        {!isTemplateEditor && (
          <IconButton className={styles.removeBtn} onClick={onRemove}>
            <Icon.TrashOutline color={variables.color.primary.white} fill={variables.color.primary.white} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
