import { content } from '@content';
import { ExperienceItemMindset, ExperienceItemState, ExperiencePayload } from '@modules';

import { SignalBuilderUIType } from './SignalLibraryBuilder.props';

/**
 * SignalLibraryBuilder component utils
 * @returns {function}
 */

export const prepareRenamePayloadBuilder = (
  businessUnitId: number,
  data: ExperienceItemState,
  mindsetsList: ExperienceItemMindset[],
  deployment: { id: number },
  experienceName: string,
  newTreatmentName: string,
  createFromExperience: { id: number },
): ExperiencePayload => ({
  businessUnit: {
    id: businessUnitId,
  },
  name: experienceName,
  deployment,
  mindsets: mindsetsList.map(({ id: mindsetId, name, metConditionCount, conditions }, mindsetIndex) => ({
    ...(mindsetId && { id: mindsetId }),
    index: mindsetIndex,
    name: name ?? newTreatmentName,
    metConditionCount,
    conditions: conditions?.map(({ id: conditionId, signal, operator, value, required }, conditionIndex) => ({
      ...(conditionId && { id: conditionId }),
      index: conditionIndex,
      signal: {
        id: signal?.id as number,
      },
      operator,
      value,
      required,
    })),
  })),
  index: 0,
  createFromExperience,
});

export const preparePayload = (
  businessUnitId: number,
  experienceName: string,
  newTreatmentName: string,
  mindsetsList: ExperienceItemMindset[],
) => ({
  businessUnit: {
    id: businessUnitId,
  },
  name: experienceName,
  mindsets: mindsetsList.map(({ id: mindsetId, name, metConditionCount, conditions }, mindsetIndex) => ({
    ...(mindsetId && { id: mindsetId }),
    index: mindsetIndex,
    name: name ?? newTreatmentName,
    metConditionCount,
    conditions: conditions?.map(({ id: conditionId, signal, operator, value, required }, conditionIndex) => ({
      ...(conditionId && { id: conditionId }),
      index: conditionIndex,
      signal: {
        id: signal?.id as number,
      },
      operator,
      value,
      required,
    })),
  })),
  index: 0,
});

export const getSaveButtonLabel = (uiType: SignalBuilderUIType, chosenAttributeId: number | null) => {
  if (uiType === 'signal') {
    return chosenAttributeId ? content.save : content.scoreAttribute;
  }

  return content.save;
};
