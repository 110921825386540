import { SVGProps } from 'react';

export const Close = ({ width = 40, height = 41, strokeWidth = 3, stroke }: SVGProps<SVGElement>): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0759 12.5781L19.6302 20.1324L27.1845 27.6867"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.6221 12.5664L20.0678 20.1207L12.5135 27.675"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
