import { Box, IconButton } from '@material-ui/core';

import { Icon, Typography } from '@components';
import { experience } from '@modules';
import { SignalBuilder } from '@routes';
import { useLoader } from '@utils';

import { useStyles } from './TreatmentPanel.styles';
import { TreatmentPanelProps } from './TreatmentPanel.props';

/**
 TreatmentPanel component
 @returns {JSX.Element}
 */

export const TreatmentPanel = ({ treatmentPanelType, onClose, selectTreatment }: TreatmentPanelProps): JSX.Element => {
  const styles = useStyles();

  // useLoader(experience.useItemMeta());

  return (
    <Box id="treatment-panel" className={styles.treatmentPanel}>
      <IconButton onClick={onClose} className={styles.closeBtn}>
        <Icon.CloseOutline strokeWidth={1} width={24} height={24} />
      </IconButton>
      <SignalBuilder
        onClose={onClose}
        selectTreatment={selectTreatment}
        treatmentPanelType={treatmentPanelType}
        uiType="treatment"
      />
    </Box>
  );
};
