import { Box, Button, Divider } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';

import { Icon, Modal, Typography } from '@components';
import { content } from '@content';
import { Experience, experience, signalLibrary } from '@modules';
import { useAppDispatch } from '@store';
import { variables } from '@styles';
import { searchQuery, textTemplate } from '@utils';
import { SignalLibrary } from '@views';

import { MindsetCreatorProps } from './MindsetCreator.props';
import { useStyles } from './MindsetCreator.styles';
import { Rules } from './Rules';

/**
 * MindsetCreator modal
 * @returns {JSX.Element}
 */

export const MindsetCreator = ({ index, open, onClose }: MindsetCreatorProps) => {
  const styles = useStyles();
  const [businessUnitId] = searchQuery.useMutualParams('businessUnit');

  const { handleSubmit, reset, setValue, getValues, clearErrors } = useFormContext<Experience>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!businessUnitId) {
      return undefined;
    }
    const promises = [dispatch(signalLibrary.thunk.search({ businessUnit: businessUnitId }))];

    return () => {
      promises.forEach((promise) => promise.abort());
    };
  }, [dispatch, businessUnitId]);

  const handleClose = () => {
    onClose();
    clearErrors();
    reset();
  };

  const preparePayload = useCallback(
    (data: Omit<Experience, 'id'>, withIds = true) => ({
      businessUnit: {
        id: businessUnitId,
      },
      name: data.name,
      mindsets: data.mindsets.map(({ id: mindsetId, metConditionCount, conditions }, mindsetIndex) => ({
        ...(mindsetId && withIds && { id: mindsetId }),
        index: mindsetIndex,
        name: ``,
        metConditionCount,
        conditions: conditions.map(({ id: conditionId, signal, operator, value, required }, conditionIndex) => ({
          ...(conditionId && withIds && { id: conditionId }),
          index: conditionIndex,
          signal: {
            id: signal?.id as number,
          },
          operator,
          value,
          required,
        })),
      })),
    }),
    [businessUnitId],
  );

  const onCreate = async () => {
    const { id, ...rest } = getValues();
    const exp = preparePayload(rest, false);
    if (id) {
      const result = await dispatch(experience.thunk.update([exp, id]));
      if (experience.thunk.update.fulfilled.match(result)) {
        handleClose();
        (async () => {
          const updatedExp = await dispatch(experience.thunk.getItem(id));
          if (experience.thunk.getItem.fulfilled.match(updatedExp) && updatedExp.payload) {
            reset(updatedExp.payload as unknown as Experience);
          }
        })();
      }
    }
  };

  function onDragEnd(result: DropResult) {
    if (result.reason === 'DROP' && result.destination?.droppableId.includes('signal')) {
      let signalItem = null;
      try {
        signalItem = JSON.parse(result.draggableId);
      } finally {
        setValue(result.destination.droppableId as 'mindsets.0.conditions.0.signal', signalItem as never, {
          shouldTouch: true,
          shouldValidate: true,
        });
        setValue(
          result.destination.droppableId.replace('signal', 'operator') as 'mindsets.0.conditions.0.operator',
          '' as never,
          { shouldDirty: true, shouldTouch: true },
        );
        setValue(
          result.destination.droppableId.replace('signal', 'value') as 'mindsets.0.conditions.0.value',
          '' as never,
          { shouldDirty: true, shouldTouch: true },
        );
      }
    }
  }

  return (
    <Modal open={open} onClose={handleClose} maxWidth={'99.3rem'}>
      <form onSubmit={handleSubmit(onCreate)}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box className={styles.mindsetCreator}>
            <Box className={styles.header}>
              <Box className={styles.icon}>
                <Icon.Mindset fill={variables.color.primary.darkestGray} />
              </Box>
              <Typography.Display className={styles.title}>
                {textTemplate(content.addValue, { value: content.treatment })}
              </Typography.Display>
            </Box>
            <Divider />
            <Box className={styles.content}>
              <Box className={styles.leftContainer}>
                <Rules mindsetIndex={index} />
              </Box>
              <Box className={styles.rightContainer}>
                <SignalLibrary height={200} />
              </Box>
            </Box>
            <Divider />
            <Box className={styles.footer}>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                {content.cancel}
              </Button>
              <Button type="submit" variant="contained" color="primary" className={styles.continueButton}>
                {textTemplate(content.addValue, { value: content.treatment })}
              </Button>
            </Box>
          </Box>
        </DragDropContext>
      </form>
    </Modal>
  );
};
