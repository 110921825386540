import { SVGProps } from 'react';

export const Check = ({ className, fill = '' }: SVGProps<SVGElement>): JSX.Element => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      d="M10 0C4.47691 0 0 4.47691 0 10C0 15.5231 4.47691 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47691 15.5231 0 10 0ZM10 18.4375C5.33996 18.4375 1.5625 14.66 1.5625 10C1.5625 5.33996 5.33996 1.5625 10 1.5625C14.66 1.5625 18.4375 5.33996 18.4375 10C18.4375 14.66 14.66 18.4375 10 18.4375Z"
    />
    <path
      fill={fill}
      d="M8.09776 15C8.00881 15 7.91909 14.9909 7.83014 14.9734C7.4713 14.9019 7.16187 14.6959 6.95888 14.3925L5.10384 11.7605C4.9229 11.5035 4.98448 11.147 5.24145 10.966C5.338 10.8976 5.45128 10.8619 5.56988 10.8619C5.75538 10.8619 5.92949 10.9524 6.03668 11.1036L7.89933 13.7471C7.94038 13.8087 7.99056 13.8429 8.05214 13.855C8.06735 13.8581 8.08255 13.8596 8.09776 13.8596C8.14185 13.8596 8.18519 13.8459 8.22244 13.8208C8.24373 13.8064 8.26197 13.7889 8.27718 13.7691L14.2468 6.21591C14.3547 6.07907 14.5174 6 14.693 6C14.8208 6 14.9462 6.04333 15.0458 6.1224C15.1652 6.21667 15.2412 6.352 15.2587 6.50329C15.2762 6.65459 15.2344 6.8036 15.1401 6.92296L9.17733 14.4671C9.09142 14.5803 8.98194 14.683 8.85574 14.7681C8.62994 14.9202 8.36765 15 8.09776 15Z"
    />
  </svg>
);
