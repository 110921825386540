import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';

import * as thunk from './DeploymentsByProgram.thunk';
import { initialState } from './DeploymentsByProgram.state';
import { reducers, extraReducers } from './DeploymentsByProgram.reducer';
import { DeploymentsByProgramSchema, DeploymentsByProgramDataSchema } from './DeploymentsByProgram.schema';

const slice = createSlice({
  name: 'deploymentsByProgram',
  initialState,
  reducers,
  extraReducers,
});

export const deploymentsByProgram = {
  thunk,
  slice,
  actions: slice.actions,
  useDeploymentsByProgramMeta: (): Omit<DeploymentsByProgramSchema, 'data'> =>
    useAppSelector(({ deploymentsByProgram: { data, ...meta } }) => meta),
  useDeploymentsByProgramData: (): DeploymentsByProgramDataSchema =>
    useAppSelector(({ deploymentsByProgram: { data } }) => data),
};
