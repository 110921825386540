import { SVGProps } from 'react';

export const PersonCircleOutline = ({
  width = 24,
  height = 24,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.1359 2.24993C6.65243 2.17587 2.17587 6.65243 2.24993 12.1359C2.32305 17.3948 6.60508 21.6768 11.864 21.7499C17.3484 21.8249 21.824 17.3484 21.749 11.8649C21.6768 6.60508 17.3948 2.32305 12.1359 2.24993ZM18.0618 17.5898C18.0431 17.61 18.0202 17.6258 17.9948 17.6362C17.9693 17.6465 17.9418 17.6511 17.9144 17.6497C17.8869 17.6483 17.8601 17.6408 17.8358 17.6279C17.8116 17.6149 17.7905 17.5968 17.774 17.5748C17.3547 17.0262 16.8413 16.5566 16.2576 16.1877C15.0641 15.4218 13.552 14.9999 11.9999 14.9999C10.4479 14.9999 8.93571 15.4218 7.74227 16.1877C7.15862 16.5564 6.64516 17.0259 6.22587 17.5743C6.20939 17.5963 6.18828 17.6145 6.16402 17.6274C6.13975 17.6404 6.11294 17.6478 6.08547 17.6492C6.05801 17.6507 6.03056 17.646 6.00509 17.6357C5.97961 17.6253 5.95673 17.6095 5.93805 17.5893C4.56259 16.1045 3.78344 14.1643 3.74993 12.1406C3.67352 7.57915 7.41649 3.76118 11.9798 3.74993C16.543 3.73868 20.2499 7.44415 20.2499 11.9999C20.2515 14.0725 19.4699 16.0691 18.0618 17.5898Z"
      fill={color}
    />
    <path
      d="M12 6.75C11.0756 6.75 10.2398 7.09641 9.6459 7.72594C9.05199 8.35547 8.75527 9.22594 8.82231 10.1602C8.95824 12 10.3837 13.5 12 13.5C13.6162 13.5 15.0389 12 15.1776 10.1606C15.247 9.23531 14.9526 8.37281 14.3489 7.73156C13.7526 7.09875 12.9182 6.75 12 6.75Z"
      fill={color}
    />
  </svg>
);
