import { useEffect, MutableRefObject } from 'react';

import { SingleModulesWrapper } from '@routes';

export function useOnClickOutside(
  ref: MutableRefObject<HTMLElement | HTMLDivElement | null>,
  secondRef: SingleModulesWrapper,
  handler: (value: Event) => void,
): void {
  useEffect(
    () => {
      const listener = (event: Event): void => {
        // Do nothing if clicking ref's element or descendent elements
        const target = event.target as HTMLElement;

        const dropDown = document.querySelector(
          '[style="z-index: -1; position: fixed; inset: 0px; background-color: transparent; -webkit-tap-highlight-color: transparent;"]',
        );
        const modalWindow = document.getElementsByClassName('MuiDialog-container MuiDialog-scrollPaper');
        const treatmentPanel = document.getElementById('treatment-panel');

        if (
          (ref.current && ref.current.contains(target)) ||
          (secondRef && secondRef.contains(target)) ||
          !!dropDown ||
          !!modalWindow[0] ||
          !!treatmentPanel
        ) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
      };
    },
    // Hook checks if ref.current contains event target,
    // and if it contains, then the handler executing.
    [ref, secondRef, handler],
  );
}
