import { DisclosureAuditItem } from '@modules';

import { Col, Rec, Styles } from './Disclosure.types';
import { COLUMNS } from './Disclosure.const';

export const getColumns = (styles: Styles): Col[] =>
  COLUMNS.map((column) => ({
    ...column,
    className: styles[column.id as keyof typeof styles] || styles.column,
  }));

export const itemToRecord = (item: DisclosureAuditItem, getEditValue: (id: number) => string): Rec => ({
  id: `${item.id}`,
  name: item.name,
  copy: item.moduleCopy,
  disc: item.auditDisclosureStatus,
  cont: item.auditContentStatus,
  html: item.auditHtmlStatus,
  edit: getEditValue(item.id),
});
