import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

import { BenchmarkItemProps } from './BenchmarkItem.props';

/**
 * BenchmarkItem component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    valueText: {
      display: 'inline',
      margin: theme.spacing(0.5, 0),
      color: variables.color.primary.mediumGray,
      fontSize: variables.font.size.xxxxl,
      fontWeight: variables.font.weight.regular,
      lineHeight: '3.8rem',
      letterSpacing: '-0.04em',
    },

    row: {
      display: 'flex',
      alignItems: 'center',
    },

    trend: ({ trend }: Partial<BenchmarkItemProps>) => ({
      margin: theme.spacing(0, 0.5),
      color: trend && trend > 0 ? variables.color.semantic.positive : variables.color.semantic.negative,
      fontWeight: variables.font.weight.bold,
    }),

    percent: {
      display: 'inline',
      color: variables.color.primary.mediumGray,
      fontSize: variables.font.size.lg,
      fontWeight: variables.font.weight.regular,
      lineHeight: variables.lineHeight.xl,
      letterSpacing: '-0.4em',
    },
  }),
);
