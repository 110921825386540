import { makeStyles, createStyles, StyleRules } from '@material-ui/core/styles';

import { COLUMNS } from './Subscriber.const';
import { ColumnId } from './Subscriber.types';

const { posn, edit } = ColumnId;

const posnRealWidth = '32.3%';
const posnLeftGap = '4rem';
const editIconSize = '4rem';

const colsWidths = {
  [posn]: `${posnRealWidth} + ${posnLeftGap}`,
  [edit]: '18.7%',
};

const colsMixin: StyleRules<ColumnId> = Object.entries(colsWidths).reduce(
  (acc, [key, width]) => ({ ...acc, [key]: { width } }),
  {} as StyleRules<ColumnId>,
);

const colsCommonWidth = `(${Object.values(colsWidths).join(' + ')})`;

const regularColumnsCount = COLUMNS.length - Object.keys(colsWidths).length;

export const useStyles = makeStyles(() =>
  createStyles({
    ...colsMixin,

    column: {
      width: `calc((100% - ${colsCommonWidth}) / ${regularColumnsCount})`,
    },

    [posn]: {
      width: posnRealWidth,
      marginLeft: posnLeftGap,
    },

    [edit]: {
      ...colsMixin[edit],

      '& > .MuiIconButton-root': {
        width: editIconSize,
        height: editIconSize,
      },
    },
  }),
);
