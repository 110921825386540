import { SVGProps } from 'react';

export const PeopleOutline = ({
  width = 16,
  height = 16,
  stroke = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.5625 5.25C12.4709 6.52094 11.5281 7.5 10.5 7.5C9.47187 7.5 8.5275 6.52125 8.4375 5.25C8.34374 3.92781 9.26156 3 10.5 3C11.7384 3 12.6563 3.95187 12.5625 5.25Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5001 9.5C8.46356 9.5 6.50512 10.5116 6.0145 12.4816C5.9495 12.7422 6.11293 13 6.38074 13H14.6198C14.8876 13 15.0501 12.7422 14.9861 12.4816C14.4954 10.48 12.537 9.5 10.5001 9.5Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M6.25005 5.81063C6.17692 6.82563 5.41505 7.625 4.5938 7.625C3.77255 7.625 3.00943 6.82594 2.93755 5.81063C2.86287 4.75469 3.60443 4 4.5938 4C5.58317 4 6.32473 4.77406 6.25005 5.81063Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.43756 9.56289C5.8735 9.30445 5.25225 9.20508 4.59381 9.20508C2.96881 9.20508 1.40319 10.0129 1.011 11.5863C0.959437 11.7945 1.09006 12.0004 1.30381 12.0004H4.81256"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
