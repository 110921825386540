import { ReactNode } from 'react';

import { TableColumn, TableRecord } from '@components';

export enum ColumnId {
  posn = 'posn',
  name = 'name',
  edit = 'edit',
}

export type Col = TableColumn<ColumnId>;

export type Rec = TableRecord & Record<ColumnId, ReactNode>;
