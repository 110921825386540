import { useCallback, useEffect } from 'react';

import { useAppDispatch } from '@store';
import { Table } from '@components';
import { audit } from '@modules';

import { getColumns, itemToRecord } from './Subscriber.utils';
import { SubscriberProps } from './Subscriber.props';
import { useStyles } from './Subscriber.styles';

export const Subscriber = ({ deployment, itemsPerPage, getEditValue }: SubscriberProps): JSX.Element => {
  const styles = useStyles();

  const { data } = audit.useNullSubscriber();

  const dispatch = useAppDispatch();

  const fetchData = useCallback(
    (page = 1) => dispatch(audit.thunk.getNullSubscriber({ deployment, page, itemsPerPage })),
    [dispatch, deployment, itemsPerPage],
  );

  const convertItemToRecord = useCallback((item) => itemToRecord(item, getEditValue), [getEditValue]);

  useEffect(() => {
    if (deployment) {
      const promise = fetchData();

      return () => promise.abort();
    }

    return undefined;
  }, [fetchData, deployment]);

  return (
    <Table
      uiType="secondary"
      autofit
      columns={getColumns(styles)}
      records={data.items.map(convertItemToRecord)}
      page={data.currentPage}
      pages={data.pageCount}
      onPageChange={fetchData}
    />
  );
};
