import { base } from '@modules';

import { DeploymentSchema, DataVizGenerationStatus } from './Deployment.schema';

export const initialState: DeploymentSchema = {
  entity: {
    ...base.state,
    data: null,
  },
  dataVizGenerationStatus: {
    ...base.state,
    data: {
      status: DataVizGenerationStatus.init,
      failedModules: [],
    },
  },
  modulesList: {
    ...base.state,
    data: [],
  },
  infoEmailTemplate: {
    ...base.state,
    data: {
      deploymentEmailTemplates: [],
      positions: [],
    },
  },
  deploymentsList: {
    ...base.state,
    data: null,
  },
};
