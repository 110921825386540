import { Box } from '@material-ui/core';

import { Icon, Typography } from '@components';
import { variables } from '@styles';

import { WarningMessageProps } from './WarningMessage.props';
import { useStyles } from './WarningMessage.styles';

export const WarningMessage = ({
  className = '',
  message,
  description,
  actionLinks,
  onClose,
}: WarningMessageProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={`${styles.warningMessage} ${className}`}>
      <Box className={styles.icon}>
        <Icon.AlertCircleOutlineV3 fill={variables.color.primary.darkestGray} />
      </Box>
      <Box>
        <Typography.Headline className={styles.message}>{message}</Typography.Headline>
        {description && <Typography.Body>{description}</Typography.Body>}
        {actionLinks && <Box className={styles.buttons}>{actionLinks.map((link) => link)}</Box>}
      </Box>
      {onClose && (
        <button className={styles.button} type="button" onClick={onClose}>
          <Icon.CloseOutline />
        </button>
      )}
    </Box>
  );
};
