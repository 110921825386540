import { useState, useCallback, useRef, useEffect, useImperativeHandle, forwardRef, memo, useMemo } from 'react';
import { Box, Button, ButtonBase, TextField } from '@material-ui/core';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { Droppable } from 'react-beautiful-dnd';

import { Typography, Icon } from '@components';
import { content } from '@content';
import { JourneyInfo } from '@views';
import { experience, global, Experience } from '@modules';
import { useAppDispatch } from '@store';
import { textTemplate } from '@utils';

import { Mindset } from './Mindset';
import { MindsetDeleteModal } from './MindsetDeleteModal';
import { JourneyMenu, JourneyMenuHandle } from './JourneyMenu';

import { useStyles } from './Journey.styles';
import { JourneyProps } from './Journey.types';

const JourneyWithRef = forwardRef(
  ({ disabled = false, selectJourney, addJourney, businessUnit }: JourneyProps, ref): JSX.Element => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const [mindsetIndex, setMindsetIndex] = useState<number | undefined>();
    const menuRef = useRef<JourneyMenuHandle>(null);
    const { reset, getValues, setValue, watch } = useFormContext<Experience>();
    const { fields, prepend, move, remove } = useFieldArray<Experience, 'mindsets', 'mindsetId'>({
      name: 'mindsets',
      keyName: 'mindsetId',
    });
    const countsData = experience.useCountsData();
    const experienceData = experience.useItemData();

    const mindsetsCounts = useMemo(() => {
      if (experienceData?.isFileBased) {
        const sum = experienceData.mindsets.reduce((prev, curr) => prev + curr.audienceSize, 0);

        return experienceData.mindsets.map((m) => ({
          targetCount: m.audienceSize,
          audience: (m.audienceSize / sum) * 100,
        }));
      }
      return countsData.map(experience.utils.calcMindsetCounts);
    }, [countsData, experienceData?.isFileBased, experienceData?.mindsets]);

    const data = useMemo(
      () =>
        experienceData
          ? {
              ...experienceData,
              mindsets: experienceData.mindsets.map((mindset, index) => ({
                ...mindset,
                ...mindsetsCounts[index],
              })),
            }
          : experienceData,
      [experienceData, mindsetsCounts],
    );

    const watchMindsetsArray = watch('mindsets');

    const mindsets = fields.map((field, i) => ({
      ...field,
      ...watchMindsetsArray[i],
      ...mindsetsCounts[i],
    }));
    const journeyIsEdit = watch('isEdit');
    const hasMindsetIsEdit = getValues('mindsets').some((i) => i.isEdit);

    function addMindset() {
      if (hasMindsetIsEdit) {
        dispatch(
          global.actions.enqueueNotification({
            message: content.treatmentIsInEdit,
            options: { variant: 'error' },
          }),
        );
        return;
      }
      mindsetsCounts.unshift({ targetCount: 0, audience: 0 });
      prepend(
        {
          isEdit: true,
          cohort: {
            name: '',
            code: '',
          },
          product: {
            name: '',
            code: '',
          },
          metConditionCount: 1,
          conditions: [
            {
              signal: null,
              operator: '',
              value: '',
              required: false,
            },
          ],
        } as any,
        { shouldFocus: false },
      );
    }

    const deleteMindset = useCallback(async () => {
      if (mindsetIndex === undefined) {
        return;
      }
      mindsetsCounts.splice(mindsetIndex, 1);
      remove(mindsetIndex);
      setMindsetIndex(undefined);
      dispatch(experience.thunk.getSubscriberCount(getValues()));
    }, [dispatch, getValues, mindsetIndex, remove, mindsetsCounts]);

    const moveMindset = useCallback(
      (from: number, to: number) => {
        const tmp = mindsetsCounts[to];
        mindsetsCounts[to] = mindsetsCounts[from];
        mindsetsCounts[from] = tmp;
        move(from, to);
      },
      [move, mindsetsCounts],
    );

    const closeDeleteMindsetModal = useCallback(() => {
      setMindsetIndex(undefined);
    }, []);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
      menuRef.current?.setAnchorEl(event.currentTarget);
    };

    const setJourneyEdit = useCallback(() => {
      setValue('isEdit', true);
    }, [setValue]);

    const deleteJourney = useCallback(async () => {
      const result = await dispatch(experience.thunk.remove());
      if (experience.thunk.remove.fulfilled.match(result)) {
        selectJourney(null);
      }
    }, [dispatch, selectJourney]);

    useEffect(() => {
      if (data?.id) {
        reset({
          id: data.id,
          isEdit: false,
          businessUnit: {
            id: data.businessUnit?.id,
          },
          name: data.name,
          mindsets: (data.mindsets || []).map((m) => ({
            id: m.id,
            isEdit: false,
            collapsed: true,
            metConditionCount: m.metConditionCount,
            conditions: (m.conditions || []).map((c) => ({
              id: c.id,
              signal: c.signal,
              operator: c.operator,
              value: c.value,
              required: c.required,
            })),
          })),
        });
      }

      return () => {
        dispatch(experience.actions.resetCounts());
        reset({
          name: '',
          businessUnit: {
            id: businessUnit,
          },
          isEdit: true,
          mindsets: [],
        });
      };
    }, [dispatch, reset, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(
      ref,
      () => ({
        moveMindset,
      }),
      [moveMindset],
    );

    return (
      <Box className={styles.journeyContainer}>
        <Box className={styles.titleBar}>
          <Typography.Title noWrap className={styles.title}>
            {content.moduleGroupLibrary}
          </Typography.Title>
          <Button variant="contained" color="secondary" onClick={addJourney} disabled={disabled}>
            {content.addModuleGroup}
          </Button>
        </Box>
        <Box className={styles.journey}>
          <Box className={styles.header}>
            <ButtonBase className={styles.menuBtn} onClick={handleOpenMenu} disabled={disabled}>
              <Icon.ThreeDots />
            </ButtonBase>
            <JourneyMenu
              ref={menuRef}
              setJourneyEdit={data?.isFileBased ? null : setJourneyEdit}
              deleteJourney={deleteJourney}
              isDeleteAvailable
            />
            <Box className={styles.journeyName}>
              <Typography.Caption>{content.moduleGroupName}</Typography.Caption>
              {journeyIsEdit ? (
                <Controller
                  name="name"
                  rules={{
                    required: true,
                  }}
                  defaultValue={data?.name || ''}
                  render={({ field: { ref: fieldRef, ...rest }, fieldState }) => (
                    <TextField className={styles.nameField} inputRef={fieldRef} error={fieldState.invalid} {...rest} />
                  )}
                />
              ) : (
                <Typography.Title noWrap>{data?.name}</Typography.Title>
              )}
            </Box>
            <JourneyInfo
              author={data?.author?.id}
              createdAt={data?.createdAt || ''}
              updatedAt={data?.updatedAt || ''}
              lastUsed={data?.lastUsed || ''}
            />
            <ButtonBase className={styles.collapseBtn} data-collapsed="true" onClick={selectJourney}>
              <Icon.ArrowDown width={22} height={22} stroke="#53565A" />
            </ButtonBase>
          </Box>
          <Droppable droppableId="experience" type="MINDSET" isDropDisabled={hasMindsetIsEdit || disabled}>
            {(provided) => (
              <Box className={styles.mindsetsList} {...{ ref: provided.innerRef }} {...provided.droppableProps}>
                {mindsets.length > 0 &&
                  mindsets.map((item, index) => (
                    <Mindset
                      key={item.mindsetId}
                      mindset={item}
                      index={index}
                      onDelete={setMindsetIndex}
                      journeyIsEdit={!!journeyIsEdit}
                      isOnlyMindset={mindsets.length === 1}
                    />
                  ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
          <Button variant="contained" color="secondary" disabled={disabled || !journeyIsEdit} onClick={addMindset}>
            {textTemplate(content.addValue, { value: content.treatment })}
          </Button>
          <MindsetDeleteModal
            open={mindsetIndex !== undefined}
            onClose={closeDeleteMindsetModal}
            onClickDelete={deleteMindset}
          />
        </Box>
      </Box>
    );
  },
);

JourneyWithRef.displayName = 'JourneyWithRef';

export const Journey = memo(JourneyWithRef);
