import { SVGProps } from 'react';

export const ContentOutline = ({
  width = 24,
  height = 24,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16 18.1485V4.92308C15.9993 4.67848 15.9029 4.4441 15.7318 4.27115C15.5607 4.09819 15.3289 4.00071 15.087 4H4.91304C4.67111 4.00071 4.43928 4.09819 4.2682 4.27115C4.09712 4.4441 4.0007 4.67848 4 4.92308V18.4615C4.0012 18.8692 4.16192 19.2598 4.44704 19.5481C4.73216 19.8363 5.11852 19.9988 5.52174 20H18"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M18 20C17.4696 20 16.9609 19.8043 16.5858 19.4561C16.2107 19.1078 16 18.6354 16 18.1429V7H19C19.2652 7 19.5196 7.09783 19.7071 7.27197C19.8946 7.44611 20 7.6823 20 7.92857V18.1429C20 18.6354 19.7893 19.1078 19.4142 19.4561C19.0391 19.8043 18.5304 20 18 20Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M8 15H12" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 12H12" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 9H10" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
