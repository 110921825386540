import { SVGProps, FC } from 'react';

type Props = SVGProps<SVGElement> & {
  withoutDate?: boolean;
};

export const Calendar: FC<Props> = ({ className, width = 28, height = 28, withoutDate = false }): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {!withoutDate && (
      <>
        <path
          d="M7.875 22.75C7.392 22.75 7 22.358 7 21.875C7 21.392 7.392 21 7.875 21H8.75V15.75H7.875C7.392 15.75 7 15.358 7 14.875C7 14.392 7.392 14 7.875 14H8.75C9.71483 14 10.5 14.7852 10.5 15.75V21H11.375C11.858 21 12.25 21.392 12.25 21.875C12.25 22.358 11.858 22.75 11.375 22.75H7.875Z"
          fill="#8C8F96"
        />
        <path
          d="M17.0104 22.75C16.9042 22.75 16.8004 22.7313 16.7024 22.694C16.4842 22.6123 16.3115 22.4502 16.2147 22.2367C16.1179 22.0243 16.1097 21.7863 16.1925 21.567L18.3742 15.75H16.1377C15.6547 15.75 15.2627 15.358 15.2627 14.875C15.2627 14.392 15.6547 14 16.1377 14H18.3754C18.8467 14.0023 19.2865 14.1867 19.6155 14.5192C19.9445 14.8517 20.1254 15.2927 20.123 15.7593C20.1219 15.967 20.0845 16.1712 20.0122 16.3648L17.8305 22.183C17.7022 22.5213 17.3732 22.75 17.0104 22.75Z"
          fill="#8C8F96"
        />
      </>
    )}
    <path
      d="M2.625 28C1.17717 28 0 26.8228 0 25.375V6.125C0 4.67717 1.17717 3.5 2.625 3.5H7V0.875C7 0.392 7.392 0 7.875 0C8.358 0 8.75 0.392 8.75 0.875V3.5H19.25V0.875C19.25 0.392 19.642 0 20.125 0C20.608 0 21 0.392 21 0.875V3.5H25.375C26.8228 3.5 28 4.67717 28 6.125V25.375C28 26.8228 26.8228 28 25.375 28H2.625ZM1.75 25.375C1.75 25.858 2.142 26.25 2.625 26.25H25.375C25.858 26.25 26.25 25.858 26.25 25.375V12.25H1.75V25.375ZM26.25 10.5V6.125C26.25 5.642 25.858 5.25 25.375 5.25H21V7C21 7.483 20.608 7.875 20.125 7.875C19.642 7.875 19.25 7.483 19.25 7V5.25H8.75V7C8.75 7.483 8.358 7.875 7.875 7.875C7.392 7.875 7 7.483 7 7V5.25H2.625C2.142 5.25 1.75 5.642 1.75 6.125V10.5H26.25Z"
      fill="#8C8F96"
    />
  </svg>
);
