import { TableColumn, TableColumnSort, TableRecord, TableColumnId } from './Table.props';

export function getCellClassName<ColumnId extends TableColumnId>(
  column: TableColumn<ColumnId>,
  record?: TableRecord<ColumnId>,
): string {
  if (typeof column.className === 'string') {
    return column.className;
  }

  if (typeof column.className === 'function') {
    return column.className(record?.id || '');
  }

  return '';
}

export const getNextSort = (curr: TableColumnSort): TableColumnSort => {
  let next: TableColumnSort;

  switch (curr) {
    case null:
      next = 'ASC';
      break;
    case 'ASC':
      next = 'DESC';
      break;
    case 'DESC':
    default:
      next = null;
      break;
  }

  return next;
};
