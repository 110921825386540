import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1.3rem',
    },

    picker: {
      '&&': {
        width: '60rem',
      },
    },

    legend: {
      width: '29.6rem',
    },
  }),
);
