import React from 'react';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { Typography } from '@components';
import { format } from '@utils';

import { ModuleLevelStatisticsProps } from './ModuleLevel.props';
import { useStyles } from './ModuleLevel.styles';

export const ModuleLevelStatistics: React.FC<ModuleLevelStatisticsProps> = ({ moduleInfo }): React.ReactElement => {
  const styles = useStyles();
  return (
    <Box className={styles.statisticsRow}>
      <Box>
        <Typography.Tag className={styles.statisticsText}>{content.numberOfDeployments}</Typography.Tag>
        <Typography.Caption className={styles.statisticsText}>
          {format.number(moduleInfo?.deploymentsCount, '-')}
        </Typography.Caption>
      </Box>
      <Box>
        <Typography.Tag className={styles.statisticsText}>{content.totalNumberOfClicks}</Typography.Tag>
        <Typography.Caption className={styles.statisticsText}>
          {format.number(moduleInfo?.statistics?.totalClicks, '-')}
        </Typography.Caption>
      </Box>
      <Box>
        <Typography.Tag className={styles.statisticsText}>{content.totalNumberOfImpressions}</Typography.Tag>
        <Typography.Caption className={styles.statisticsText}>
          {format.number(moduleInfo?.statistics?.totalImpressions, '-')}
        </Typography.Caption>
      </Box>
      <Box>
        <Typography.Tag className={styles.statisticsText}>{content.totalClickToImpression}</Typography.Tag>
        <Typography.Caption className={styles.statisticsText}>
          {format.percent(moduleInfo?.statistics?.ctir, '-')}
        </Typography.Caption>
      </Box>
    </Box>
  );
};
