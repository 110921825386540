import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { page as pages } from '@content';
import { history } from '@store';
import { parse, searchQuery } from '@utils';

export type HistoryPathReal = keyof typeof pages;

export type HistoryPath = '' | HistoryPathReal;

const TOKEN_QUERY_PARAM_KEY = 'token';

export type HistoryQueryParamKey =
  | typeof TOKEN_QUERY_PARAM_KEY
  | 'enterprise'
  | 'businessUnit'
  | 'program'
  | 'deployment'
  | 'module'
  | 'mailFile'
  | 'template';

export type HistoryParamKey = 'id' | HistoryQueryParamKey;

export type HistoryParamValue = number | string | null;

export type HistoryParams<Value = HistoryParamValue> = Partial<Record<HistoryParamKey, Value>>;

export type HistoryQuery = HistoryParams<number>;

export const useHistory = () => {
  const { pathname, search } = useLocation();

  const currQuery = useMemo<HistoryQuery>(
    () =>
      Object.fromEntries(
        Object.entries({ ...searchQuery.parseParams<HistoryParamKey>(search), id: pathname.replace(/^\D+/g, '') })
          .map(([key, value]) => [key, key === TOKEN_QUERY_PARAM_KEY ? value : parse.integer(value)])
          .filter(([, value]) => value),
      ),
    [pathname, search],
  );

  const currPath = useMemo(() => {
    const [, pathRoot] = pathname.split('/');
    const currPageEntry = Object.entries(pages).find(([, path]) => path.startsWith(`/${pathRoot}`));
    const [currPageKey] = (currPageEntry || ['dashboard']) as [HistoryPath];

    return currPageKey;
  }, [pathname]);

  const getUrl = useCallback(
    (path: HistoryPath = currPath, params: HistoryParams = {}) => {
      const { id, ...queryParams } = params;
      let nextPath = path ? pages[path] : pathname;
      if (!id) {
        nextPath = nextPath.replace(/\d+/g, '');
      }
      const nextSlug = id ? `/${id}` : '';
      const nextQuery = searchQuery.updateParams<HistoryQueryParamKey>(search, queryParams);

      return `${nextPath}${nextSlug}${nextQuery}`;
    },
    [currPath, pathname, search],
  );

  const push = useCallback(
    (path: HistoryPath = currPath, params: HistoryParams = {}) => {
      const nextUrl = getUrl(path, params);

      history.push(nextUrl);
    },
    [currPath, getUrl],
  );

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  return {
    path: currPath,
    query: currQuery,
    getUrl,
    push,
    goBack,
  };
};
