import { Routing } from '@utils';
import { page } from '@content';

import { RoutingData } from './network.types';
import { token } from './token';
import { parseApiError } from './network.utils';

export { token };

export const send = async <T>({ key, params }: RoutingData, init?: RequestInit): Promise<T | undefined> => {
  const isBodyFormData = init?.body instanceof FormData;
  const accessToken = token.get();

  const response = await fetch(Routing.generate(key, params, true), {
    ...init,
    credentials: 'include',
    headers: {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(init?.body && !isBodyFormData && { 'Content-Type': 'application/json' }),
      ...init?.headers,
    },
    ...(init?.body && { body: init.body }),
  });

  let data;

  if (response.headers.get('content-type')?.includes('json')) {
    data = await response.json();
  }

  if (response.status === 401 && accessToken) {
    if (data?.message === 'Invalid JWT Token') {
      window.location.href = page.login;
      return undefined;
    }

    const authResponse = await fetch(Routing.generate('jwt_refresh_token', null, true), {
      method: 'POST',
      credentials: 'include',
    });

    if (!authResponse.ok) {
      window.location.href = page.login;
      return undefined;
    }

    const userData = await authResponse.json();

    token.set(userData.token);

    return send({ key, params }, init);
  }

  if (response.ok) {
    return data || response;
  }

  throw parseApiError(data) as Error;
};

export const get = <T>({ key, params }: RoutingData, init?: RequestInit): Promise<T | undefined> =>
  send({ key, params }, { ...init, method: 'GET' });

export const post = <T>({ key, params }: RoutingData, init?: RequestInit): Promise<T | undefined> =>
  send({ key, params }, { ...init, method: 'POST' });

export const put = <T>({ key, params }: RoutingData, init?: RequestInit): Promise<T | undefined> =>
  send({ key, params }, { ...init, method: 'PUT' });

export const remove = <T>({ key, params }: RoutingData, init?: RequestInit): Promise<T | undefined> =>
  send({ key, params }, { ...init, method: 'DELETE' });

export const postWithCreds = <T>({ key, params }: RoutingData, init?: RequestInit): Promise<T | undefined> =>
  send({ key, params }, { ...init, method: 'POST', credentials: 'include' });
