import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Editor, RichUtils } from 'draft-js';

import { func, format } from '@utils';
import { Tooltip } from '@components';

import { RichTextEditorProps } from './RichTextEditor.props';
import { deserialize, isStyleActive, serialize } from './RichTextEditor.utils';
import { useStyles, code as codeCssProps } from './RichTextEditor.styles';
import { BLOCK_RENDER_MAP, TOOLS } from './RichTextEditor.const';

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  className = '',
  label = '',
  placeholder = '',
  value: valueInit,
  disabled = false,
  decorators = [],
  onChange,
}): React.ReactElement => {
  const styles = useStyles();

  const editor = React.useRef<Editor>(null);

  const valueOld = React.useRef(valueInit);

  const [state, setState] = React.useState(serialize(valueInit, decorators));

  const tooltip = React.useMemo(() => state.getCurrentContent().getPlainText(), [state]);

  const handleBlur = React.useCallback(() => !disabled && onChange(deserialize(state)), [onChange, disabled, state]);

  const handleFocus = React.useCallback(() => !disabled && editor.current?.focus(), [disabled, editor]);

  const handleTab = React.useCallback(() => editor.current?.blur(), [editor]);

  const handleKeyCommand = React.useCallback(
    (command) => {
      const nextState = RichUtils.handleKeyCommand(state, command);

      if (nextState) {
        setState(nextState);
      }

      return nextState ? 'handled' : 'not-handled';
    },
    [setState, state],
  );

  const handleToolClick = React.useCallback(
    (event) => {
      if (!disabled) {
        event.stopPropagation();
        event.preventDefault();

        const { style } = event.currentTarget.dataset;
        const nextState = RichUtils.toggleInlineStyle(state, style);

        setState(nextState);
      }
    },
    [setState, disabled, state],
  );

  React.useEffect(() => {
    if (valueOld.current !== valueInit) {
      valueOld.current = valueInit;
      setState(serialize(valueInit, decorators));
    }
  }, [setState, valueOld, valueInit, decorators]);

  return (
    <Box className={`${styles.richTextEditor} ${className}`} onFocus={handleFocus} onBlur={handleBlur}>
      <Box className={styles.head}>
        {label}
        <Box className={styles.toolbar}>
          <Box className={styles.tools}>
            {TOOLS.map((tool) => (
              <Tooltip text={format.capitalize(tool.style)} size="sm" key={tool.style}>
                <IconButton
                  className={styles.tool}
                  data-style={tool.style}
                  disabled={disabled}
                  onMouseDown={handleToolClick}
                >
                  <tool.Icon className={isStyleActive(state, tool.style) ? styles.toolIconActive : styles.toolIcon} />
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        </Box>
      </Box>
      <Tooltip text={tooltip}>
        <Box className={styles.area} data-disabled={disabled}>
          <Editor
            key="editor"
            ref={editor}
            placeholder={placeholder}
            readOnly={disabled}
            editorState={state}
            preserveSelectionOnBlur
            blockRenderMap={BLOCK_RENDER_MAP}
            customStyleMap={{ CODE: codeCssProps }}
            handleKeyCommand={disabled ? func.getNop('not-handled') : handleKeyCommand}
            onTab={disabled ? func.nop : handleTab}
            onChange={disabled ? func.nop : setState}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
