import { DocumentWithSelection } from '@routes';

import { HighlightedElement, IEHTMLTextAreaElement } from './ModuleModal.types';

/**
 * ModuleModal component utils
 */

export const pasteTextIntoTextarea = (elem: HTMLInputElement, text: string) => {
  elem.focus();

  if (typeof elem.selectionStart == 'number' && typeof elem.selectionEnd == 'number') {
    const val = elem.value;
    const selStart = elem.selectionStart;
    elem.value = val.slice(0, selStart) + text + val.slice(elem.selectionEnd);
    elem.selectionEnd = elem.selectionStart = selStart + text.length;
  } else if (typeof (document as DocumentWithSelection).selection != 'undefined') {
    const textRange = (document as DocumentWithSelection).selection.createRange();
    textRange.text = text;
    textRange.collapse(false);
    textRange.select();
  }
};

export const setCaretPosition = (ctrl: IEHTMLTextAreaElement, pos: number | null) => {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

    // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
};

export class Cursor {
  static getCurrentCursorPosition(parentElement: any) {
    const doc = parentElement.ownerDocument;
    const win = doc.defaultView;
    const selection = win.getSelection();
    let charCount = -1, node;

    if (selection?.focusNode) {
      if (Cursor._isChildOf(selection.focusNode, parentElement)) {
        node = selection.focusNode;
        charCount = selection.focusOffset;

        // if (selection.focusNode.parentElement.tagName === 'PERSONEFY-TOKEN') {
        //   charCount += selection.focusNode.parentElement.outerHTML.length + 1;
        //   Cursor.setCurrentCursorPosition(charCount, parentElement);
        //   return charCount;
        // }

        while (node) {
          if (node === parentElement) {
            break;
          }

          if (node.previousSibling) {
            node = node.previousSibling;
            if (node.tagName === 'PERSONEFY-TOKEN' || node.tagName === 'SUP') {
              charCount += node.outerHTML.length;
            } else {
              charCount += node.textContent.length;
            }
          } else {
            node = node.parentNode;
            if (node === null) {
              break;
            }
          }
        }
      }
    }

    return charCount;
  }

  static setCurrentCursorPosition(chars: number, element: any) {
    if (chars >= 0) {
      const doc = element.ownerDocument;
      const win = doc.defaultView;
      const selection = win.getSelection();

      const range = Cursor._createRange(element, { count: chars });

      if (range) {
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }
  }

  static _createRange(node: any, chars: { count: number }, range: Range | undefined = undefined): Range {
    if (!range) {
      const doc = node.ownerDocument;
      range = doc.createRange() as Range;
      range.selectNode(node);
      range.setStart(node, 0);
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count);
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length;
        } else {
          range.setEnd(node, chars.count);
          chars.count = 0;
        }
      } else {
        for (let lp = 0; lp < node.childNodes.length; lp++) {
          range = Cursor._createRange(node.childNodes[lp], chars, range);

          if (chars.count === 0) {
            break;
          }
        }
      }
    }

    return range;
  }

  static _isChildOf(node: any, parentElement: any) {
    while (node !== null) {
      if (node === parentElement) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }
}

export const getHighlightedElems = (e: MouseEvent, elemId: string, prevState: HighlightedElement[]) => {
  const elements = [...prevState];

  elements.unshift({
    id: elemId,
    event: e,
  });

  if (elements.length > 2) {
    elements.length = 2;
  }

  return elements;
};
