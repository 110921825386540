import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

import {
  DatasetKey,
  DatasetSize,
  DatasetState,
  DatasetIcon,
  ChildKey,
  UseStyles,
  DatasetKeyName,
  DatasetValue,
  DatasetDirection,
} from './TextField.types';

const pair = (datasetKeyName: DatasetKeyName, value: DatasetValue): string =>
  `&[${DatasetKey[datasetKeyName]}="${value}"]`;

const size = (value: DatasetSize): string => pair('size', value);

const direction = (value: DatasetDirection): string => pair('direction', value);

const state = (value: DatasetState): string => pair('state', value);

const icon = (value: DatasetIcon): string => pair('icon', value);

const child = (key: ChildKey): string => `& > $${key}`;

export const useStyles: UseStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      display: 'flex',

      [direction('vertical')]: {
        flexDirection: 'column',

        [child('meta')]: {
          marginBottom: theme.spacing(1),
        },
      },

      [direction('horizontal')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',

        ...(() => {
          const mainWidth = '28rem';
          const gap = theme.spacing(3);

          return {
            [child('meta')]: {
              width: `calc(100% - ${mainWidth} - ${gap}px)`,
              marginRight: gap,
            },

            [child('main')]: {
              width: mainWidth,
            },
          };
        })(),
      },

      [direction('horizontalV2')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',

        [child('meta')]: {
          width: '17.9rem',
          marginRight: theme.spacing(3),
        },

        [child('main')]: {
          width: '28rem',
        },
      },

      [size('medium')]: {
        [direction('horizontal')]: {
          [child('meta')]: {
            marginTop: theme.spacing(1),
          },
        },

        [direction('horizontalV2')]: {
          [child('meta')]: {
            marginTop: theme.spacing(1),
          },
        },

        [child('main')]: {
          [child('body')]: {
            [child('input')]: {
              padding: theme.spacing(1, 1.5),
            },

            [`${child('input')}, ${child('input')}::placeholder`]: {
              fontSize: variables.font.size.md,
              lineHeight: variables.lineHeight.lg,
            },
          },
        },

        [icon('start')]: {
          [child('main')]: {
            [child('body')]: {
              [child('input')]: {
                paddingLeft: theme.spacing(4.5),
              },

              [child('icon')]: {
                left: theme.spacing(1.5),
              },
            },
          },
        },

        [icon('end')]: {
          [child('main')]: {
            [child('body')]: {
              [child('input')]: {
                paddingRight: theme.spacing(4.5),
              },

              [child('icon')]: {
                right: theme.spacing(1.5),
              },
            },
          },
        },
      },

      [size('small')]: {
        [direction('horizontal')]: {
          [child('meta')]: {
            marginTop: theme.spacing(0.875),
          },
        },

        [direction('horizontalV2')]: {
          [child('meta')]: {
            marginTop: theme.spacing(0.875),
          },
        },

        [child('main')]: {
          [child('body')]: {
            [child('input')]: {
              padding: theme.spacing(0.875, 1),
            },

            [`${child('input')}, ${child('input')}::placeholder`]: {
              fontSize: variables.font.size.sm,
              lineHeight: variables.lineHeight.md,
            },
          },
        },

        [icon('start')]: {
          [child('main')]: {
            [child('body')]: {
              [child('input')]: {
                paddingLeft: theme.spacing(4),
              },

              [child('icon')]: {
                left: theme.spacing(1),
              },
            },
          },
        },

        [icon('end')]: {
          [child('main')]: {
            [child('body')]: {
              [child('input')]: {
                paddingRight: theme.spacing(4),
              },

              [child('icon')]: {
                right: theme.spacing(1),
              },
            },
          },
        },
      },

      [state('default')]: {
        [child('main')]: {
          [child('body')]: {
            borderColor: variables.color.primary.lightGrayDarker,

            [child('input')]: {
              color: variables.color.primary.darkestGray,
            },

            [`${child('icon')} > path`]: {
              stroke: variables.color.semantic.neutral,
            },
          },
        },
      },

      [state('disabled')]: {
        [child('main')]: {
          [child('body')]: {
            borderColor: variables.color.primary.lightGrayDarker,
            backgroundColor: variables.color.semantic.neutralLight,

            [child('input')]: {
              color: variables.color.primary.halfMediumGray,
            },

            [`${child('icon')} > path`]: {
              stroke: variables.color.primary.halfMediumGray,
            },
          },

          [child('hint')]: {
            color: variables.color.primary.halfMediumGray,
          },
        },
      },

      [state('error')]: {
        [child('meta')]: {
          [child('label')]: {
            color: variables.color.semantic.negative,
          },

          [child('subLabel')]: {
            color: variables.color.semantic.negative,
            opacity: variables.opacity.high,
          },
        },

        [child('main')]: {
          [child('body')]: {
            borderColor: variables.color.semantic.negative,
            backgroundColor: variables.color.semantic.negativeLight,

            [child('input')]: {
              color: variables.color.semantic.negative,

              '&::placeholder': {
                color: variables.color.semantic.negative,
              },
            },

            [`${child('icon')} > path`]: {
              stroke: variables.color.semantic.negative,
            },
          },

          [child('hint')]: {
            color: variables.color.semantic.negative,
          },
        },
      },
    },

    meta: {
      display: 'flex',
      flexDirection: 'column',
    },

    label: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    subLabel: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    main: {
      display: 'flex',
      flexDirection: 'column',
    },

    body: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      border: `${variables.borderWidth.sm} solid`,
      borderRadius: variables.borderRadius.sm,
    },

    input: {
      width: '100%',
      fontFamily: variables.font.family.base,
      fontWeight: variables.font.weight.medium,
      padding: 0,
      border: 0,
      outline: 0,
      background: 'transparent',

      '&:focus::placeholder': {
        visibility: 'hidden',
      },

      '&::placeholder': {
        fontSize: variables.font.size.sm,
        color: variables.color.primary.halfMediumGray,
      },

      '&:placeholder-shown': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },

    icon: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1.6rem',
      height: '1.6rem',
      pointerEvents: 'none',
    },

    sugs: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      top: `calc(100% + ${variables.gap.xxs})`,
      width: '100%',
      maxHeight: '18.6rem',
      overflow: 'auto',
      padding: theme.spacing(1),
      background: variables.color.primary.white,
      boxShadow: '0 0.4rem 1.6rem rgba(0, 0, 0, 0.2)',
      borderRadius: variables.borderRadius.md,
    },

    sugn: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderRadius: variables.borderRadius.sm,
      cursor: 'pointer',

      '&:hover': {
        background: variables.color.primary.lighterPurple,
        color: variables.color.primary.mainPurple,
      },
    },

    hint: {
      padding: theme.spacing(0.5, 0, 0, 1.5),
    },
  }),
);
