import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * AdminUsers component styles
 * @returns {Function}
 */

const columnsCount = 7;

const specificColumnsWidths = {
  name: '23.5%',
  mail: '23.5%',
  id: '7.5%',
  role: '13%',
  date: '13.5%',
  stat: '13.5%',
};

const specificColumnsWidth = `(${Object.values(specificColumnsWidths).join(' + ')})`;

const regularColumnsCount = columnsCount - Object.keys(specificColumnsWidths).length;

export const useStyles = makeStyles((theme) =>
  createStyles({
    adminUsers: {
      padding: theme.spacing(3),
      flex: '1 1 auto',
      backgroundColor: variables.color.primary.white,
      borderTopRightRadius: variables.borderRadius.sm,
      borderBottomRightRadius: variables.borderRadius.sm,
    },

    header: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(4),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    alert: {
      marginBottom: theme.spacing(1),
    },

    topTitle: {
      marginBottom: theme.spacing(0.5),
    },

    headerRight: {
      display: 'flex',
      alignItems: 'center',
    },

    cancel: {
      marginRight: theme.spacing(1),
    },

    footer: {
      paddingTop: theme.spacing(3),
      marginTop: theme.spacing(4),
      borderTop: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      textAlign: 'right',
    },

    nameColumn: {
      width: specificColumnsWidths.name,

      '& > p': {
        fontWeight: variables.font.weight.bold,
      },
    },

    idColumn: {
      width: specificColumnsWidths.id,
    },

    statColumn: {
      width: specificColumnsWidths.stat,

      '&:not(:last-child)': {
        paddingRight: '4rem',
      },
    },

    mailColumn: {
      width: specificColumnsWidths.mail,

      '& > a': {
        fontWeight: variables.font.weight.medium,
      },
    },

    dateColumn: {
      width: specificColumnsWidths.date,
    },

    roleColumn: {
      width: specificColumnsWidths.role,
    },

    editColumn: {
      width: `calc((100% - ${specificColumnsWidth}) / ${regularColumnsCount})`,
    },

    email: {
      fontWeight: variables.font.weight.medium,
    },

    required: {
      marginTop: theme.spacing(0.5),
    },

    error: {
      color: variables.color.semantic.negative,
    },
  }),
);
