import { createAsyncThunk } from '@store';
import { userClients, businessUnitDeployments, modules } from '@modules';

export const getStatistics = createAsyncThunk(
  'dashboard/getStatistics',
  async (businessUnit: number, { dispatch, signal }) => {
    dispatch(userClients.actions.findById(businessUnit));
    const promises: CancelablePromise<unknown>[] = [];

    promises.push(dispatch(businessUnitDeployments.thunk.getPreDeployment(businessUnit)));
    promises.push(dispatch(businessUnitDeployments.thunk.getInMarket(businessUnit)));
    promises.push(dispatch(modules.thunk.getPopular(businessUnit)));

    function abort() {
      promises.forEach((promise) => promise.abort());
      signal.removeEventListener('abort', abort);
    }

    signal.addEventListener('abort', abort);
  },
);
