import { Box, IconButton } from '@material-ui/core';

import { Icon, Typography } from '@components';
import { useStyles } from './ContentModuleCard.styles';
import { ContentModuleCardProps } from './ContentModuleCard.types';
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { variables } from '@styles';
import { content } from '@content';
import { modules } from '@modules';
import { broadcaster } from '@utils/network/broadcast';
import { useAppDispatch } from '@store';

/**
 * ContentModuleCard component
 * @returns {JSX.Element}
 */

export const ContentModuleCard = ({
  id,
  coverImage,
  name,
  index,
  deploymentName,
  instances,
  ctir,
  type,
  isActive = false,
  onClick,
  onDoubleClick,
}: ContentModuleCardProps) => {
  const styles = useStyles();
  const [tag, setTag] = useState(index);
  const [imageIsHigher, setImageIsHigher] = useState(false);
  const coverImageRef = useRef(null);
  const dispatch = useAppDispatch();
  const formattedName = name.length > 38 ? `...${name.slice(name.length - 38, name.length)}` : name;

  const handlePurge = useCallback(async () => {
    const cloneResult = await dispatch(modules.thunk.resetModuleTemplateContent(id));

    if (modules.thunk.resetModuleTemplateContent.fulfilled.match(cloneResult)) {
      setTimeout(() => {
        broadcaster.postMessage('refresh');
      }, 1000);
    }
  }, [id]);

  useEffect(() => {
    const image = new Image();

    image.onload = function () {
      setImageIsHigher((coverImageRef.current as any)?.getBoundingClientRect().height > 163);
    };

    image.src = coverImage ?? '';
  }, [coverImageRef, coverImage]);

  return (
    <Box
      className={`${styles.contentModuleCard} ${isActive ? 'active' : ''}`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {!['image', 'dataVisual'].includes(type) ? (
        <Box className={styles.coverImageWrapper}>
          <img
            ref={coverImageRef}
            src={coverImage}
            alt="cover-image"
            className={styles.coverImage}
            style={{ bottom: imageIsHigher ? 'none' : '0', top: imageIsHigher ? '0' : 'none' }}
          />
        </Box>
      ) : (
        <Box className={styles.contentImage} style={{ backgroundImage: `url(${coverImage})` }} />
      )}
      <Box className={styles.content}>
        <Box className={styles.moduleRow}>
          <Typography.Headline className={styles.name} title={name}>
            {formattedName}
          </Typography.Headline>
          {!['image', 'dataVisual'].includes(type) && (
            <Typography.SmallCaption>
              CTIR: <span className={styles.contentValue}>{ctir}%</span>
            </Typography.SmallCaption>
          )}
        </Box>
        <Box className={styles.moduleRow}>
          <Box className={styles.infoGroup}>
            <Typography.SmallCaption className={styles.index}>{tag}</Typography.SmallCaption>
          </Box>
          {!['image', 'dataVisual'].includes(type) && (
            <Typography.SmallCaption className={styles.deploymentName}>
              {deploymentName === content.notInUse && type === 'instance' && (
                <IconButton className={styles.purgeBtn} size="medium" onClick={handlePurge}>
                  <Icon.TrashOutline width={18} height={18} color={variables.color.primary.mainPurple} />
                </IconButton>
              )}
              {type === 'instance' ? (
                <span className={styles.contentValue}>{deploymentName}</span>
              ) : (
                <>
                  Instances: <span className={styles.contentValue}>{instances}</span>
                </>
              )}
            </Typography.SmallCaption>
          )}
        </Box>
      </Box>
    </Box>
  );
};
