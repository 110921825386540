export const Tag: React.FC = (): React.ReactElement => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 2.85107V10.5725C0.999882 11.5541 1.38961 12.4956 2.0835 13.1899L16.3503 27.458C16.6975 27.8051 17.1682 28 17.6591 28C18.1499 28 18.6206 27.8051 18.9678 27.458L27.4568 18.9678C27.8038 18.6206 27.9988 18.1499 27.9988 17.6591C27.9988 17.1682 27.8038 16.6975 27.4568 16.3503L13.1899 2.0835C12.4956 1.38961 11.5541 0.999882 10.5725 1H2.85107C2.36014 1 1.88931 1.19502 1.54217 1.54217C1.19502 1.88931 1 2.36014 1 2.85107V2.85107Z"
      stroke="#8C8F96"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.479 9.3291C8.50132 9.3291 9.33008 8.50035 9.33008 7.47803C9.33008 6.45571 8.50132 5.62695 7.479 5.62695C6.45668 5.62695 5.62793 6.45571 5.62793 7.47803C5.62793 8.50035 6.45668 9.3291 7.479 9.3291Z"
      stroke="#8C8F96"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
