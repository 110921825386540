import { parse } from '@utils';
import { ContentState, EditorState, convertFromHTML, ContentBlock, CompositeDecorator } from 'draft-js';
import { convertToHTML } from 'draft-convert';

import { RichTextEditorDecorator } from './RichTextEditor.props';

const getStrategy =
  (regex: RegExp) =>
  (block: ContentBlock, callback: (start: number, end: number) => void): void =>
    Array.from(block.getText().matchAll(regex))
      .reverse()
      .forEach((match) => {
        const { 0: substring, index } = match;
        const start = parse.integer(`${index}`);

        if (!Number.isNaN(start)) {
          callback(start, start + substring.length);
        }
      });

export const serialize = (markup: string, decorators: RichTextEditorDecorator[]): EditorState => {
  const decoratedMarkup = markup
    .replaceAll(/<\/sup>/g, '</code>')
    .replaceAll(/<sup>/g, '<code>')
    .replaceAll(/<\/span>/g, '</p>')
    .replaceAll(/<span>/g, '<p>');
  const { contentBlocks, entityMap } = convertFromHTML(decoratedMarkup);
  const content = ContentState.createFromBlockArray(contentBlocks, entityMap);

  const decorator =
    decorators.length === 0
      ? undefined
      : new CompositeDecorator(
          decorators.map((rteDecorator) => ({
            strategy: getStrategy(rteDecorator.regexp),
            component: rteDecorator.wrapper,
          })),
        );

  return EditorState.createWithContent(content, decorator);
};

export const deserialize = (state: EditorState): string => {
  const content = state.getCurrentContent();
  const dirtyMarkup = convertToHTML({})(content);

  return dirtyMarkup
    .replaceAll(/<p><\/p>|<p\/>/g, '')
    .replaceAll(/<p>/g, '<span>')
    .replaceAll(/<\/p>/g, '</span>')
    .replaceAll(/<code>/g, '<sup>')
    .replaceAll(/<\/code>/g, '</sup>');
};

export const isStyleActive = (state: EditorState, style: string): boolean =>
  state.getCurrentInlineStyle().includes(style);
