import { SVGProps } from 'react';

export const DocumentsOutline = ({ width = 16, height = 16 }: SVGProps<SVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 8.25406V13.625C10.5 14.3844 9.90469 15 9.15781 15H3.34375C2.59531 15 2 14.3844 2 13.625V5.375C2.00213 5.01099 2.14768 4.66249 2.40508 4.40508C2.66249 4.14768 3.01099 4.00213 3.375 4H6.31625C6.41806 4.00015 6.51881 4.02057 6.61265 4.06006C6.70649 4.09955 6.79153 4.15732 6.86281 4.23L10.2738 7.69875C10.4189 7.84715 10.5001 8.0465 10.5 8.25406V8.25406Z"
      stroke="#494949"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 4V7.375C6.25276 7.60621 6.34583 7.82717 6.50933 7.99067C6.67283 8.15417 6.89379 8.24724 7.125 8.25H10.5"
      stroke="#494949"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 4V2.375C5.50213 2.01099 5.64768 1.66249 5.90508 1.40508C6.16249 1.14768 6.51099 1.00213 6.875 1H9.8125C9.91487 1.00014 10.0162 1.02054 10.1106 1.06001C10.2051 1.09949 10.2908 1.15726 10.3628 1.23L13.7738 4.69875C13.9188 4.84557 14.0001 5.04364 14 5.25V10.625C14 11.3844 13.4047 12 12.6578 12H10.75"
      stroke="#494949"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 1V4.375C9.75276 4.60621 9.84583 4.82717 10.0093 4.99067C10.1728 5.15417 10.3938 5.24724 10.625 5.25H14"
      stroke="#494949"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
