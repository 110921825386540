import { base } from '@modules';

import { ModulesSchema } from './Modules.schema';

export const initialState: ModulesSchema = {
  popular: { ...base.state, data: { ...base.paginagedState } },
  library: { ...base.state, data: { ...base.paginagedState } },
  clone: { ...base.state },
  duplicate: { ...base.state },
  moduleInfo: {
    ...base.state,
    data: null,
  },
  newModule: {
    ...base.state,
    data: null,
  },
  refreshArticles: {
    ...base.state,
  },
  renameModuleTemplate: {
    ...base.state,
  },
  resetModuleTemplateContent: {
    ...base.state,
  },
  moduleName: {
    ...base.state,
  },
  replaceModuleDataVisualizationStation: {
    ...base.state,
  },
  replaceModuleDisclosureStation: {
    ...base.state,
  },
};
