import { makeStyles, createStyles } from '@material-ui/core';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    mindsetDetails: {
      display: 'flex',
      flexDirection: 'column',
    },

    head: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    topic: {
      maxWidth: '23.5rem',
    },

    cohort: {
      marginLeft: theme.spacing(4),
      maxWidth: '14rem',
    },

    delete: {
      marginLeft: 'auto',
    },

    body: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: '8.9rem',
      marginTop: theme.spacing(3),
      border: `${variables.borderWidth.sm} solid ${variables.color.semantic.neutralLight}`,
      borderRadius: variables.borderRadius.sm,
      paddingLeft: theme.spacing(3),
    },

    info: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '4.1rem',
      paddingRight: theme.spacing(4),

      '&:not(:first-child)': {
        borderLeft: `${variables.borderWidth.sm} solid ${variables.color.semantic.neutralLight}`,
        paddingLeft: theme.spacing(4),
      },
    },

    infoIcon: {
      width: '2.4rem',
      height: '2.4rem',
    },

    infoText: {
      marginLeft: theme.spacing(2),
    },

    infoTextLabel: {},

    infoTextValue: {
      marginTop: theme.spacing(0.5),
    },
  }),
);
