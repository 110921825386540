import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * UniversalContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    addSignalDropdown: {
      width: '100%',
    },
    addButton: {
      marginBottom: '10px',
      color: '#494949',
      border: '1px solid #e8ecf0',
      padding: '0.8rem 1.2rem',
      position: 'relative',
      fontSize: '1.3rem',
      fontWeight: 600,
      borderRadius: '0.6rem',
      background: 'none',
      cursor: 'pointer',
      '& svg': {
        width: '10px',
        marginLeft: '4px',
      },

      '&.active svg': {
        transform: 'rotate(180deg )',
      },

      '& svg path': {
        stroke: '#494949',
      },
    },
    menuList: {
      padding: '0 10px',
      marginLeft: '-10px',
      boxShadow: '1px 1px 7px #dadee2',
    },
    search: {
      width: '100%',
      marginBottom: '10px',
    },
    signalsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    signalItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '8px 20px 8px 10px',
      border: '1px solid #dadee2',
      borderRadius: '1rem 0.4rem',
      position: 'relative',
      width: 'fit-content',
      cursor: 'pointer',
    },
    closeButton: {
      position: 'absolute',
      top: '4px',
      right: '3px',
      padding: 0,
      width: theme.spacing(2),
      height: theme.spacing(2),
      '& svg': {
        stroke: variables.color.primary.darkestGray,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          stroke: variables.color.primary.lightPurple,
        },
      },
    },
    editingItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    formatDropdown: {
      width: '180px',
    },
    formatItem: {
      display: 'flex',
      minWidth: '240px',
      justifyContent: 'space-between',
    },
    editingSignal: {
      gap: '5px',
      minWidth: '180px',
      border: '1px solid #dadee2',
      display: 'flex',
      padding: '5px 20px 5px 10px',
      position: 'relative',
      borderRadius: '1rem 0.4rem',
      alignItems: 'center',
    },
    removeBtn: {
      border: '1px solid #dadee2',
      padding: '0.85rem 0.8rem',
      position: 'relative',
      height: '34px',
      width: '34px',
      minWidth: '34px',
      borderRadius: '0.6rem',
      background: 'none',
      cursor: 'pointer',
      boxSizing: 'border-box',
    },
  }),
);
