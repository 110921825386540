import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      background: 'transparent',
      boxShadow: 'none',
      marginTop: '2.5rem',
    },
    indicator: {
      top: 0,
      height: '0.1875rem',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: '97%',
        width: '100%',
        backgroundColor: variables.color.primary.lightPurple,
      },
    },
    tabPanel: {
      backgroundColor: variables.color.primary.lightGreen,
      marginRight: variables.gap.md,
      marginTop: '-0.4rem',
      padding: '3rem 1rem',
      '& > .MuiBox-root': {
        padding: '0 0.9rem',
      },
    },
  }),
);

export const useTabStyles = makeStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      color: variables.color.primary.gray,
      fontSize: variables.font.size.md,
      border: `0.4rem solid ${variables.color.primary.lightGreen}`,
      boxSizing: 'border-box',
      borderTopLeftRadius: variables.borderRadius.sm,
      borderTopRightRadius: variables.borderRadius.sm,
      marginRight: variables.gap.md,
      letterSpacing: variables.letterSpacing.sm,
      padding: '2.8rem 0',
      '&:focus': {
        fontWeight: variables.font.weight.bold,
        background: variables.color.primary.lightGreen,
      },
      '&:selected': {
        fontWeight: variables.font.weight.bold,
        background: variables.color.primary.lightGreen,
      },
    },
    selected: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      background: variables.color.primary.lightGreen,
    },
  }),
);
