import { SelectOption } from '@components';
import { content } from '@content';
import { Client, Enterprise } from '@modules';
import { validate } from '@utils';

import { ClientEditorFieldType } from './ClientEditor.types';

export const enterpriseToOption = (enterprise: Enterprise): SelectOption => ({
  id: `${enterprise.id}`,
  label: enterprise.name,
});

export const hourToDailyUpdate = (hour: number, period: 'AM' | 'PM'): SelectOption => ({
  id: `${`0${hour}`.slice(-2)}:00 ${period}`,
  label: `${hour} ${period} (ET)`,
});

export const shouldWarn = (values: Client, entity?: Client): boolean =>
  !!(
    entity?.experiences?.length &&
    entity.cohorts.some((srcCohort) => !values.cohorts.find((dstCohort) => dstCohort.name === srcCohort.name))
  );

export const getValidationRule =
  (type: ClientEditorFieldType) =>
    (value: unknown): string | undefined => {
      if (type === 'url' && value && !validate.url(value, true)) {
        return content.invalidUrl;
      }
    };
