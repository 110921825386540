import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * DVAttribute component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    DVAttribute: {
      color: variables.color.primary.halfMediumGray,
      border: '1px solid #dadee2',
      letterSpacing: '0.2px',
      padding: '0.85rem 0.8rem',
      position: 'relative',
      fontSize: '1.3rem',
      fontWeight: 400,
      height: '35px',
      width: '112px',
      borderRadius: '0.6rem',
      background: 'none',
      cursor: 'pointer',
      overflow: 'hidden',

      '&.selected': {
        borderRadius: '10px 4px 10px 4px',
      },
    },
    selectedAttribute: {
      textTransform: 'uppercase',
      color: variables.color.primary.darkestGray,
      textAlign: 'left',
      paddingLeft: '3px',
      fontSize: '12px',
    },
  }),
);
