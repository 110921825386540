import { Box } from '@material-ui/core';

import { Typography } from '@components';

import { GreetingProps } from './Greeting.props';

export const Greeting = ({ message, target }: GreetingProps): JSX.Element => (
  <Box display="flex" flexDirection="column" flex="1 1 19rem">
    <Typography.Caption component="span">{message}</Typography.Caption>
    <Typography.Display component="span">{target}</Typography.Display>
  </Box>
);
