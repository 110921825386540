import { SortValues } from './LibraryNavigation.const';
import { User } from '@modules';
import { SyntheticEvent, BaseSyntheticEvent } from 'react';

/**
 * LibraryNavigation component types
 */
export type SortByType = SortValues.AUTHOR | SortValues.CREATED | SortValues.UPDATED | SortValues.LAST_USED;

export type LibraryNavigationView = 'experiencesView' | 'deploymentsView';

export enum LibraryView {
  experiencesView = 'experiencesView',
  deploymentsView = 'deploymentsView',
}

export type LibraryNavigationProps = Partial<LibraryNavigationCallbacks> & {
  type?: LibraryNavigationView;
  usedExperiences?: number[];
};

export type LibraryNavigationCallbacks = {
  onExperienceSelect: (event: SyntheticEvent | null) => void;
  handleDragExperience: (event: BaseSyntheticEvent) => void;
  createExperience: () => void;
};

export type LibraryNavigationQuery = {
  page: number;
  lastUsed: 1 | 0;
  sortBy: SortByType;
};

export type ExperienceItem = {
  isFileBased: boolean;
  id: number;
  name: string;
  subscribersNumber: number;
  author: Pick<User, 'firstname' | 'id' | 'lastname'> | null;
  createdAt: string;
  updatedAt: string | null;
  lastUsed: string | null;
};
