import { ApiError } from '@modules';
import { createAsyncThunk } from '@store';
import { network } from '@utils';

export const getMailPartsByProgram = createAsyncThunk<
  string[] | void,
  {
    programId: number;
  }
>('audit/getMailPartsByProgram', async ({ programId }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'mail_parts_by_program', params: { program: programId } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});
