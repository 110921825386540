import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

import { DatasetKey, DatasetSize, DatasetState, ChildKey, UseStyles } from './TextArea.types';

const pair = (datasetKey: keyof typeof DatasetKey, value: DatasetSize | DatasetState): string =>
  `&[${DatasetKey[datasetKey]}="${value}"]`;

const size = (value: DatasetSize): string => pair('size', value);

const state = (value: DatasetState): string => pair('state', value);

const child = (key: ChildKey): string => `& > $${key}`;

export const useStyles: UseStyles = makeStyles((theme) =>
  createStyles({
    textArea: {
      display: 'flex',
      flexDirection: 'column',

      [size('medium')]: {
        [child('body')]: {
          [child('textAreaInput')]: {
            padding: theme.spacing(1, 1.5),
          },

          [`${child('textAreaInput')}, ${child('textAreaInput')}::placeholder`]: {
            fontSize: variables.font.size.md,
            lineHeight: variables.lineHeight.lg,
          },
        },
      },

      [size('small')]: {
        [child('body')]: {
          [child('textAreaInput')]: {
            padding: theme.spacing(0.875, 1),
          },

          [`${child('textAreaInput')}, ${child('textAreaInput')}::placeholder`]: {
            fontSize: variables.font.size.sm,
            lineHeight: variables.lineHeight.md,
          },
        },
      },

      [state('default')]: {
        [child('body')]: {
          borderColor: variables.color.primary.lightGrayDarker,

          [child('textAreaInput')]: {
            color: variables.color.primary.darkestGray,
          },
        },
      },

      [state('disabled')]: {
        [child('body')]: {
          borderColor: variables.color.primary.lightGrayDarker,
          backgroundColor: variables.color.semantic.neutralLight,

          [child('textAreaInput')]: {
            color: variables.color.primary.halfMediumGray,
          },
        },

        [child('hint')]: {
          color: variables.color.primary.halfMediumGray,
        },
      },

      [state('error')]: {
        [child('label')]: {
          color: variables.color.semantic.negative,
        },

        [child('body')]: {
          borderColor: variables.color.semantic.negative,
          backgroundColor: variables.color.semantic.negativeLight,

          [child('textAreaInput')]: {
            color: variables.color.semantic.negative,
          },
        },

        [child('hint')]: {
          color: variables.color.semantic.negative,
        },
      },
    },

    label: {
      marginBottom: theme.spacing(1),
    },

    body: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      border: `${variables.borderWidth.sm} solid`,
      borderRadius: variables.borderRadius.sm,
    },

    textAreaInput: {
      width: '100%',
      minHeight: '6.8rem',
      fontFamily: variables.font.family.base,
      fontWeight: variables.font.weight.medium,
      padding: 0,
      border: 0,
      outline: 0,
      background: 'transparent',

      '&:focus::placeholder': {
        color: 'transparent',
      },

      '&::placeholder': {
        fontSize: variables.font.size.sm,
        color: variables.color.primary.mediumGray,
      },

      '&[data-has-resize=false]': {
        resize: 'none',
      },
    },

    hint: {
      padding: theme.spacing(0.5, 0, 0, 1.5),
    },
  }),
);
