import { useMemo, useRef, useState, useCallback, useEffect, ChangeEvent } from 'react';
import { Box, IconButton, TextField } from '@material-ui/core';

import { content } from '@content';
import { func, clipboard } from '@utils';
import { HtmlEditor, Icon, RichTextEditor, Select, Tooltip, Typography } from '@components';
import { ModuleStation, ModuleStationProps } from '@views';

import { ArticleLibrary } from './ArticleLibrary';
import { MailBuilder } from './MailBuilder';

import { ModuleFieldProps, ModuleFieldValue } from './ModuleField.props';
import { useStyles } from './ModuleField.styles';
import { MODULE_FIELD_COMPLEX_TYPES, MODULE_FIELD_ROWS_COUNT, RTE_VAR_REGEXP } from './ModuleField.const';
import { decorate, undecorate, getModuleFieldDataset, validateField } from './MofuleField.utils';

export const ModuleField = ({
  className = '',
  rootKey = '',
  placeholder = content.enterText,
  value: valueInit = '',
  options = [],
  onChange = func.nop,
  onError = func.nop,
  onCopy = func.nop,
  ...props
}: ModuleFieldProps): JSX.Element => {
  const {
    id = '',
    type = '',
    style = '',
    label = '',
    query = undefined,
    disabled = false,
    readonly = false,
    clipboardCopy = false,
  } = props;

  const styles = useStyles();

  const dataset = useMemo(() => getModuleFieldDataset(props, ['clipboardCopy']), [props]);

  const pure = useMemo(() => !MODULE_FIELD_COMPLEX_TYPES.includes(type), [type]);

  const basicDisabled = useMemo(() => disabled || readonly, [disabled, readonly]);

  const pureDisabled = useMemo(() => basicDisabled || type === 'var', [basicDisabled, type]);

  const labelNode = useMemo(() => {
    if (label) {
      const Label = type === 'sel' ? Typography.Caption : Typography.Tag;

      return (
        <Label className={styles.label} {...dataset}>
          {label}
        </Label>
      );
    }

    return null;
  }, [dataset, label, styles.label, type]);

  const Text = useMemo(() => {
    if (type === 'lbl') {
      switch (style) {
        case 'body':
          return Typography.Body;
        case 'title':
          return Typography.Title;
        default:
      }
    }

    return null;
  }, [type, style]);

  const valueRef = useRef(valueInit);

  const [value, setValue] = useState(decorate(type, valueInit, query));

  const [error, setError] = useState('');

  const errorOld = useRef(error);

  const handleFocus = useCallback(() => {
    const nextValue = undecorate(type, value, query);

    if (nextValue !== value) {
      setValue(nextValue);
    }
  }, [setValue, type, value, query]);

  const runCallback = useCallback(
    (nextValue: ModuleFieldValue) => {
      if (nextValue !== valueInit) {
        onChange(id, nextValue, rootKey);
      }
    },
    [onChange, valueInit, id, rootKey],
  );

  const handleBlur = useCallback(() => {
    const nextValue = decorate(type, value, query);

    setError(validateField(type, nextValue));
    setValue(nextValue);
    runCallback(nextValue);
  }, [runCallback, setError, setValue, type, value, query]);

  const handlePureChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const handleSelChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const nextValue = event.target.value as string;
      setValue(nextValue);
      runCallback(nextValue);
    },
    [setValue, runCallback],
  );

  const handleCopy = useCallback(async () => {
    if (typeof value === 'string') {
      const copied = await clipboard.copy(value);

      onCopy(label, value, copied);
    }
  }, [label, value, onCopy]);

  const handleVisualLibraryChange = useCallback(
    (embedCodeFull, embedCode, libraryId) => {
      onChange('embedCodeFull', embedCodeFull, 'moduleDataVisualizationStation');
      onChange('embedCode', embedCode, 'moduleDataVisualizationStation');
      onChange('desktopEmbedCode', '', 'moduleDataVisualizationStation');
      onChange('mobileEmbedCode', '', 'moduleDataVisualizationStation');
      onChange('dataVisualization', { id: Number(libraryId) }, 'moduleDataVisualizationStation');
    },
    [onChange],
  );

  const handleBeeChange = useCallback(
    (jsonTemplate, htmlTemplate) => {
      const doc = new DOMParser().parseFromString(htmlTemplate, 'text/html');

      const links = doc.querySelectorAll('a');
      links.forEach((link) => {
        const url = new URL(link.href);
        if (query?.id && link.href.search(/^mailto/) === -1) {
          url.searchParams.set('id', query.id);
        }
        link.href = url.toString(); // eslint-disable-line no-param-reassign
      });

      const images = doc.querySelectorAll('img');
      images.forEach((image) => {
        const url = new URL(image.src);
        if (query?.id) {
          url.searchParams.set('id', query.id);
        }
        image.src = url.toString(); // eslint-disable-line no-param-reassign
      });

      onChange(id, jsonTemplate);
      onChange('moduleHtml', doc.body.innerHTML.replaceAll('&amp;', '&'));
      onChange('html', htmlTemplate);
    },
    [onChange, id, query],
  );

  const RteVariable = useCallback(
    (variable) => (
      <Typography.Body className={`${variable.className || ''} ${styles.variable}`}>
        {variable.children}
      </Typography.Body>
    ),
    [styles.variable],
  );

  useEffect(() => {
    if (valueRef.current !== valueInit) {
      valueRef.current = valueInit;
      setValue(decorate(type, valueInit, query));
    }
  }, [setValue, valueRef, type, valueInit, query]);

  useEffect(() => {
    if (errorOld.current !== error) {
      errorOld.current = error;
      onError(!error);
    }
  }, [onError, errorOld, error]);

  return (
    <Box className={`${styles.moduleField} ${className}`} {...dataset}>
      {type && (
        <>
          {(type === 'var' || type === 'url' || clipboardCopy) && (
            <Tooltip text={content.copy} size="sm">
              <IconButton className={styles.copy} {...dataset} disabled={!value} onClick={handleCopy}>
                <Icon.Copy />
              </IconButton>
            </Tooltip>
          )}
          {type !== 'wsg' && labelNode}
          {type === 'htm' && (
            <Box onFocus={handleFocus} onBlur={handleBlur}>
              <HtmlEditor
                value={value as string}
                readonly={basicDisabled}
                onUpdate={basicDisabled ? func.nop : setValue}
              />
              {error && <Typography.Caption className={styles.error}>{error}</Typography.Caption>}
            </Box>
          )}
          {type === 'sel' && (
            <Select
              className={styles.input}
              inputProps={dataset}
              uiType="secondary"
              placeholder={placeholder}
              value={value}
              options={options}
              disabled={basicDisabled}
              onChange={basicDisabled ? func.nop : handleSelChange}
            />
          )}
          {type === 'wsg' && (
            <RichTextEditor
              className={styles.input}
              label={labelNode}
              placeholder={placeholder}
              value={value as string}
              disabled={basicDisabled}
              decorators={[
                {
                  regexp: RTE_VAR_REGEXP,
                  wrapper: RteVariable,
                },
              ]}
              onChange={basicDisabled ? func.nop : runCallback}
            />
          )}
          {type === 'visualLibrary' && (
            // <DataVisualizationLibrary
            //   value={value as DataVisualizationLibraryValue}
            //   disabled={basicDisabled}
            //   onChange={basicDisabled ? func.nop : handleVisualLibraryChange}
            // />
            <></>
          )}
          {type === 'bee' && (
            <MailBuilder
              className={styles.input}
              disabled={basicDisabled}
              value={value as string}
              onChange={handleBeeChange}
            />
          )}
          {type === 'stn' && <ModuleStation className={styles.input} {...(value as ModuleStationProps)} />}
          {type === 'articleLibrary' && <ArticleLibrary disabled={basicDisabled} />}
          {Text && (
            <Tooltip text={value as string}>
              <Text className={styles.text} {...dataset}>
                {value}
              </Text>
            </Tooltip>
          )}
          {pure && (
            <Tooltip text={value as string} shift={type === 'var' ? 'xxl' : undefined}>
              <TextField
                className={styles.input}
                inputProps={{
                  className: styles.muiInput,
                  ...dataset,
                  'data-filled': !!value,
                }}
                disabled={pureDisabled}
                rows={MODULE_FIELD_ROWS_COUNT[type]}
                placeholder={placeholder}
                value={value}
                error={!!error}
                helperText={error}
                onChange={pureDisabled ? func.nop : handlePureChange}
                onFocus={pureDisabled ? func.nop : handleFocus}
                onBlur={pureDisabled ? func.nop : handleBlur}
              />
            </Tooltip>
          )}
        </>
      )}
    </Box>
  );
};
