import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

import { Icon } from '@components';
import { content } from '@content';

import { Col, ColumnId } from './Subscriber.types';

export const renderLink = (value: ReactNode): JSX.Element => (
  <IconButton>
    <Link to={value as string}>
      <Icon.Pencil />
    </Link>
  </IconButton>
);

export const COLUMNS: Col[] = [
  {
    id: ColumnId.posn,
    title: content.position,
  },
  {
    id: ColumnId.name,
    title: content.module,
  },
  {
    id: ColumnId.edit,
    title: content.addContent,
    align: 'middle',
    tooltip: (rec, col) => (rec ? '' : (col.title as string)),
    render: renderLink,
  },
];
