import React, { ReactElement, useEffect } from 'react';
import { Box, Typography as MuiTypography, Divider } from '@material-ui/core';
import { content } from '@content';
import { PieChart, PieChartData, BarChart } from '@components';
import { useAppDispatch } from '@store';
import { analytics } from '@modules';
import { useLoader, useScrollableContainer } from '@utils';
import { variables } from '@styles';

import { useStyles } from './OverallSummary.styles';
import { SummaryPerformanceTooltip } from './SummaryPerformanceTooltip';
import { OverallMetrics } from './OverallMetrics';

export function OverallSummary({
  programId,
  onClickInPosition,
}: {
  programId: number;
  onClickInPosition: (position: string) => void;
}): ReactElement {
  const styles = useStyles();
  const ref = useScrollableContainer(null);
  const dispatch = useAppDispatch();

  const overallMetricsData = analytics.useOverallMetricsData();
  const overallMetricsMeta = analytics.useOverallMetricsMeta();

  const summaryPerformanceData = analytics.useSummaryPerformanceData();

  const summaryPerformanceMeta = analytics.useSummaryPerformanceMeta();

  const {
    deploymentsData: clickToOpenData,
    average: clickToOpenAverage,
    trend: clickToOpenTrend,
  } = analytics.useClickToOpenReportData();
  const clickToOpenReportMeta = analytics.useClickToOpenReportMeta();

  const { deploymentsData: openRateData, average: openRateAverage, trend: openRateTrend } = analytics.useOpenRateData();
  const openRateMeta = analytics.useOpenRateMeta();

  const { deploymentsData: clickRateData, average: clickRateAverage } = analytics.useClickRateData();
  const clickRateMeta = analytics.useClickRateMeta();

  const {
    deploymentsData: optOutRateData,
    average: optOutRateAverage,
    trend: optOutRateTrend,
  } = analytics.useOptOutRateData();
  const optOutRateMeta = analytics.useOptOutRateMeta();

  useLoader(
    overallMetricsMeta,
    summaryPerformanceMeta,
    clickToOpenReportMeta,
    clickRateMeta,
    optOutRateMeta,
    openRateMeta,
  );

  const isLoadedBarCharts = React.useMemo(
    () =>
      clickToOpenReportMeta.status === 'success' ||
      clickRateMeta.status === 'success' ||
      optOutRateMeta.status === 'success' ||
      openRateMeta.status === 'success',
    [clickToOpenReportMeta, clickRateMeta, optOutRateMeta, openRateMeta],
  );

  useEffect(() => {
    if (!programId) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getOverallMetrics({
        program: programId,
      }),
    );
    return () => {
      dispatch(analytics.actions.resetOverallMetrics());
      promise.abort();
    };
  }, [dispatch, programId]);

  useEffect(() => {
    if (!programId) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getSummaryPerformance({
        program: programId,
      }),
    );
    return () => {
      dispatch(analytics.actions.resetSummaryPerformance());
      promise.abort();
    };
  }, [dispatch, programId]);

  useEffect(() => {
    if (!programId) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getClicksToOpenReport({
        program: programId,
      }),
    );
    return () => {
      dispatch(analytics.actions.resetClickToOpenReport());
      promise.abort();
    };
  }, [dispatch, programId]);

  useEffect(() => {
    if (!programId) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getOpenRate({
        program: programId,
      }),
    );
    return () => {
      dispatch(analytics.actions.resetOpenRate());
      promise.abort();
    };
  }, [dispatch, programId]);

  useEffect(() => {
    if (!programId) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getClickRate({
        program: programId,
      }),
    );
    return () => {
      dispatch(analytics.actions.resetClickRate());
      promise.abort();
    };
  }, [dispatch, programId]);

  useEffect(() => {
    if (!programId) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getOptOutRate({
        program: programId,
      }),
    );
    return () => {
      dispatch(analytics.actions.resetOptOutRate());
      promise.abort();
    };
  }, [dispatch, programId]);

  return (
    <Box {...{ ref }} className={styles.overallSummary}>
      {overallMetricsMeta.status === 'success' || summaryPerformanceMeta.status === 'success' ? (
        <>
          <Box className={styles.row}>
            <Box className={styles.overallMetrics}>
              <MuiTypography className={styles.title}>{content.overallMetrics}</MuiTypography>
              <OverallMetrics data={overallMetricsData} />
            </Box>
            <Divider orientation="vertical" className={styles.verticalDivider} />
            <div>
              <MuiTypography className={styles.title}>{content.summaryPerformanceByPosition}</MuiTypography>
              <PieChart
                id="position"
                hasLegends
                width={496}
                margin={{
                  top: 60,
                  right: 150,
                  bottom: 60,
                  left: 30,
                }}
                data={summaryPerformanceData as PieChartData}
                Tooltip={SummaryPerformanceTooltip}
                onClickInPosition={onClickInPosition}
                centerText={content.totalClicks}
              />
            </div>
          </Box>
          <Divider />
        </>
      ) : null}
      {isLoadedBarCharts ? (
        <MuiTypography className={styles.title}>{content.programLevelComparison}</MuiTypography>
      ) : null}
      <Box className={styles.row}>
        <BarChart
          className="openRateByDeployment"
          data={openRateData}
          average={openRateAverage}
          trend={openRateTrend}
          color={variables.color.charts.blue}
          title={content.openRateByDeployment}
          unit={content.percentageUnit}
          legendLeft={`${content.openRate} %`}
        />
        <BarChart
          className="clickToOpenReport"
          data={clickToOpenData}
          average={clickToOpenAverage}
          trend={clickToOpenTrend}
          color={variables.color.charts.green}
          title={content.clickToOpenReport}
          unit={content.percentageUnit}
          legendLeft={`${content.ctor} %`}
        />
      </Box>
      <Box className={styles.row}>
        <BarChart
          className="clickRate"
          data={clickRateData}
          average={clickRateAverage}
          color={variables.color.charts.purpleDark}
          title={content.clickRateTitle}
          unit={content.percentageUnit}
          legendLeft={`${content.clickRate} %`}
        />
        <BarChart
          className="optoutRate"
          data={optOutRateData}
          average={optOutRateAverage}
          trend={optOutRateTrend}
          color={variables.color.secondary.orange}
          title={content.optOutRateTitle}
          unit={content.percentageUnit}
          legendLeft={`${content.optOutRate} %`}
        />
      </Box>
    </Box>
  );
}
