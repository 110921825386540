import { FC, ReactElement } from 'react';
import { Trend } from '@components';

import { BarChartTrendProps } from './BarChart.props';
import { useTrendStyle } from './BarChart.styles';

export const BarChartTrend: FC<BarChartTrendProps> = ({ trend }): ReactElement => {
  const styles = useTrendStyle();
  return (
    <div className={styles.barChartTrend}>
      <Trend trend={trend} />
    </div>
  );
};
