import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * RecommenderTable component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    legend: {
      marginBottom: variables.gap.md,
    },

    cell: {
      display: 'flex',
      flexDirection: 'column',
      padding: `${variables.gap.lg} 0`,

      '&&': {
        justifyContent: 'flex-start',
      },
    },

    item: {
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      alignSelf: 'flex-end',

      '&:nth-child(2)': {
        marginTop: variables.gap.lg,
      },

      '&:not(&:first-child, &:nth-child(2))': {
        marginTop: variables.gap.sm,
      },

      '&[data-key]': {
        alignSelf: 'flex-start',
        width: '100%',
      },
    },
  }),
);
