import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    caption: {
      color: variables.color.primary.gray,
      display: 'block',
      margin: `0 0 ${variables.gap.sm}`,
    },
  }),
);
