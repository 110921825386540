import { createSlice } from '@reduxjs/toolkit';

import { MetaSchema } from '@modules';
import { useAppSelector } from '@store';
import * as thunk from './UserClients.thunk';
import { initialState } from './UserClients.state';
import { reducers, extraReducers } from './UserClients.reducer';
import {
  UserClientsListData,
  UserClientsEntityData,
  UserClientsPayload,
  UserClientsFullListData,
  UserClientsFieldsMappingData,
  UserClientsEspCredsValidationData,
  UserClientsS3BucketAvailabilityData,
} from './UserClients.schema';

export * from './UserClients.schema';
export * from './UserClients.types';

const slice = createSlice({
  name: 'userClients',
  initialState,
  reducers,
  extraReducers,
});

export const userClients = {
  thunk,
  slice,
  actions: slice.actions,
  useAllMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          all: { data, ...meta },
        },
      }) => meta,
    ),
  useAllData: (): UserClientsPayload => useAppSelector((root) => root.userClients.all.data),
  useListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          list: { data, ...meta },
        },
      }) => meta,
    ),
  useListData: (): UserClientsListData => useAppSelector((root) => root.userClients.list.data),
  useFullListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          fullList: { data, ...meta },
        },
      }) => meta,
    ),
  useFullListData: (): UserClientsFullListData => useAppSelector((root) => root.userClients.fullList.data),
  useEntityMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          entity: { data, ...meta },
        },
      }) => meta,
    ),
  useEntityData: (): UserClientsEntityData => useAppSelector((root) => root.userClients.entity.data),
  useSubjectMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          subject: { data, ...meta },
        },
      }) => meta,
    ),
  useSubjectData: (): UserClientsEntityData => useAppSelector((root) => root.userClients.subject.data),
  useFieldsMappingMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          fieldsMapping: { data, ...meta },
        },
      }) => meta,
    ),
  useFieldsMappingData: (): UserClientsFieldsMappingData =>
    useAppSelector((root) => root.userClients.fieldsMapping.data),
  useEspCredsValidationMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          espCredsValidation: { data, ...meta },
        },
      }) => meta,
    ),
  useEspCredsValidationData: (): UserClientsEspCredsValidationData =>
    useAppSelector((root) => root.userClients.espCredsValidation.data),
  useSftpTestConnectionMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          sftpTestConnection: { data, ...meta },
        },
      }) => meta,
    ),
  useSftpTestConnectionData: (): UserClientsEspCredsValidationData =>
    useAppSelector((root) => root.userClients.sftpTestConnection.data),
  useS3BucketAvailabilityMeta: (): MetaSchema =>
    useAppSelector(
      ({
        userClients: {
          s3BucketAvailability: { data, ...meta },
        },
      }) => meta,
    ),
  useS3BucketAvailabilityData: (): UserClientsS3BucketAvailabilityData =>
    useAppSelector((root) => root.userClients.s3BucketAvailability.data),
};
