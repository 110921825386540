import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ContentLibrary component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    contentLibrary: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    panel: {
      '&&': {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: variables.gap.md,
        padding: theme.spacing(1.5, 3, 3, 3),

        '&.two-grid': {
          gridTemplateColumns: 'repeat(auto-fill, minmax(calc(100%/3), auto))',
        },

        ['@media (max-width: 1250px)']: {
          gap: variables.gap.sm,
          padding: theme.spacing(2),
        },
      },
    },

    pagination: {
      padding: theme.spacing(1, 3, 4),
      display: 'flex',
      justifyContent: 'center',
    },

    loading: {
      padding: theme.spacing(4),
      height: '100%',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
