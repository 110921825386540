import { Box } from '@material-ui/core';
import { useCallback, useContext, useEffect, useState } from 'react';

import { content } from '@content';
import { Section, TOP_BAR_CTX_VALUE, TopBarContext } from '@layouts';
import { useAppDispatch } from '@store';
import { textTemplate, useHistory } from '@utils';

import { LibrariesPanelWrapper } from '../../TemplateView/LibrariesPanel/LibrariesPanelWrapper';
import { Libraries } from '../../TemplateView/TemplateView.const';
import { getLibrariesWrapperTitle, getLibraryPanelComponent } from '../../TemplateView/TemplateView.utils';
import { DVEditorProps } from './DVEditor.props';
import { useStyles } from './DVEditor.styles';
import { DVCenterModal } from './DVCenterModal';
import { Wysiwyg } from '@components';
import { dataVisualization } from '@modules';

/**
 Viewing Data Visualization -
 DVEditor component
 @returns {JSX.Element}
 */

export const DVEditor = ({ chosenDV, query, setChosenDV, setIsDVEditorVisible }: DVEditorProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { businessUnit: businessUnitId = 0 } = history.query;
  const [chosenLibrary, setChosenLibrary] = useState<Libraries | null>(Libraries.DATA_VISUAL);
  const [isModuleChanged, setIsModuleChanged] = useState(false);
  const [topBarCtx, setTopBarCtx] = useContext(TopBarContext);
  const [curDeployment, setCurDeployment] = useState<number>();
  const [curSegment, setCurSegment] = useState<number>();

  const handleChooseLibrary = useCallback(
    (libraryName: Libraries | null) => {
      setChosenLibrary(libraryName);
    },
    [setChosenLibrary],
  );

  const handleChooseDV = useCallback(
    (item: any) => {
      setChosenDV(item);
      setIsModuleChanged(false);
    },
    [setChosenDV],
  );

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      variant: 'section',
      sectionName: textTemplate(content.entityManager, { entity: content.content }),
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
    };
  }, [setTopBarCtx]);

  const handleModuleClose = useCallback(() => {
    setIsDVEditorVisible(false);
  }, [setIsDVEditorVisible]);

  const handleModuleSave = (code: string) => async () => {
    const { id, embedCodeFull, embedCode, width, height, defaultImagePath, thumbnailPath, isMaxScaleEnabled, ...rest } =
      chosenDV;
    const valuesWithClient = { ...rest, codeBlock: code, 'businessUnit[id]': businessUnitId };
    const formData = new FormData();

    Object.keys(valuesWithClient).forEach((key) => {
      formData.append(key, valuesWithClient[key as keyof typeof valuesWithClient] as string | Blob);
    });

    const result = await dispatch(dataVisualization.thunk.update({ dataVisualization: chosenDV.id, body: formData }));

    if (dataVisualization.thunk.update.fulfilled.match(result)) {
      await dispatch(dataVisualization.actions.resetDataVisualizationList());
      setIsModuleChanged(false);
      dispatch(
        dataVisualization.thunk.getListDataVisualization({
          ...query,
          businessUnit: query.businessUnitId,
        }),
      );
    }
  };

  return (
    <>
      <Box className={styles.templateView}>
        <Section className={styles.leftPanel} range={2} gap={0}>
          <LibrariesPanelWrapper onChooseLibrary={handleChooseLibrary} isImageLibrary={true}>
            {getLibraryPanelComponent(Libraries.DATA_VISUAL, true, handleChooseDV, chosenDV, setChosenDV)}
          </LibrariesPanelWrapper>
        </Section>
        <Section className={styles.centralPanel} range={6} gap={0}>
          <DVCenterModal
            curDeployment={curDeployment}
            chosenDV={chosenDV}
            curSegment={curSegment}
            setCurSegment={setCurSegment}
            onClose={handleModuleClose}
          />
        </Section>
        <Section className={styles.rightPanel} range={4} gap={0}>
          <Box className={styles.rightPanelContent}>
            <Wysiwyg.DVContainer
              isModuleChanged={isModuleChanged}
              query={query}
              dirty={isModuleChanged}
              chosenDV={chosenDV}
              curDeployment={curDeployment}
              curSegment={curSegment}
              setCurSegment={setCurSegment}
              setChosenDV={setChosenDV}
              setCurDeployment={setCurDeployment}
              setIsModuleChanged={setIsModuleChanged}
              onSave={handleModuleSave}
              onClose={handleModuleClose}
            />
          </Box>
        </Section>
      </Box>
    </>
  );
};
