import { memo, useState, useEffect } from 'react';
import { TextField, Box, Button, Link } from '@material-ui/core';

import { Alert, Modal, Typography, Chip } from '@components';
import { content } from '@content';
import { textTemplate } from '@utils';
import { variables } from '@styles';
import { DeploymentStatus } from '@modules';
import { useStyles } from './JourneyChangeWarningModal.style';
import { JourneyChangeWarningModalProps } from './JourneyChangeWarningModal.props';

export const JourneyChangeWarningModal = memo(
  ({ name: nameInit, deployments, onClose, onConfirm }: JourneyChangeWarningModalProps): JSX.Element => {
    const styles = useStyles();
    const [name, setName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
      setErrorMsg('');
    };

    const handleUpdate = () => {
      if (!name) {
        setErrorMsg(textTemplate(content.pleaseEnter, { field: content.name }));
        return;
      }
      if (nameInit === name) {
        setErrorMsg(content.enterDifferentName);
        return;
      }
      onConfirm(name);
    };

    useEffect(() => {
      if (nameInit) {
        setName(nameInit);
      }
    }, [nameInit]);

    return (
      <Modal open onClose={onClose} maxWidth="42.7rem">
        <Alert message={content.warning} className={styles.warning} />
        <Typography.Body
          className={styles.message}
          dangerouslySetInnerHTML={{
            __html: textTemplate(content.changeModuleGroupWarning, { name: nameInit }),
          }}
        />
        <TextField
          defaultValue={name}
          onChange={handleChangeName}
          className={styles.input}
          error={!!errorMsg}
          helperText={errorMsg}
        />
        <Box className={styles.content}>
          <Box className={styles.list}>
            {deployments?.map((deployment) => (
              <Box className={styles.item} key={deployment.id}>
                <Typography.Caption className={styles.name}>{deployment.name}</Typography.Caption>
                {deployment.status === DeploymentStatus.inMarket && (
                  <Chip label={content.published} color={variables.color.primary.green} />
                )}
              </Box>
            ))}
          </Box>
          <Box>
            <Button variant="contained" color="primary" className={styles.confirm} onClick={handleUpdate}>
              {content.update}
            </Button>
            <Link component={Button} className={styles.close} onClick={onClose}>
              {content.cancel}
            </Link>
          </Box>
        </Box>
      </Modal>
    );
  },
);

JourneyChangeWarningModal.displayName = 'JourneyChangeWarningModal';
