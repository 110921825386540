import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const specificColumnsWidths = {
  fileName: '25%',
  date: '25%',
  fileType: '25%',
  status: '25%',
  id: '25%',
};

const divider = `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`;

/**
 * AttributesImport component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(2, 3),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    espForm: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    formContainer: {
      height: `100%`,
      overflowY: 'auto',
      borderTop: divider,
      borderBottom: divider,
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 25px',
      gap: '10px',
    },

    topTitle: {
      marginBottom: theme.spacing(0.5),
    },

    body: {
      padding: theme.spacing(3, 3, 4),
      maxHeight: '35rem',
      overflowY: 'auto',
      overflowX: 'hidden',
    },

    description: {
      paddingBottom: theme.spacing(3),
    },

    attributeRow: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: theme.spacing(1, 0),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,

      '&:last-child': {
        borderBottom: 'unset',
      },
    },

    attributesWrapper: {},

    title: {
      marginBottom: variables.gap.lg,
    },

    textField: {
      '& > div:first-child': {
        width: '22rem!important',
      },
    },

    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      padding: theme.spacing(2, 3),
    },

    rightActionBtns: {
      display: 'flex',
      textAlign: 'right',
      width: '100%',
      justifyContent: 'flex-end',
    },

    cancelBtn: {
      marginRight: theme.spacing(1.25),
    },
  }),
);
