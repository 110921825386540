import { useEffect, useCallback, useState, useContext, useRef } from 'react';
import { Box } from '@material-ui/core';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '@content';
import { useAppDispatch } from '@store';
import { textTemplate, useHistory, useWebTitle } from '@utils';
import { signal, CompositeSignalPayload, attributeLibrary, experience, UpdateAttributeArg } from '@modules';
import { TreatmentBuilderContext } from '@routes/TemplateView';

import { DataAttributeLibrary } from './DataAttributeLibrary';
import { SignalLibraryBuilder } from './SignalLibraryBuilder';

import { SignalBuilderProps } from './SignalBuilder.props';
import { useStyles } from './SignalBuilder.styles';
import { SignalBuilderContext, DEFAULT_CONDITIONS } from './SignalBuilder.const';
import {
  ExtendedCompositeSignalPayload,
  UploadingAttributesMap,
  SseMap,
  UploadingAttributes,
} from './SignalBuilder.types';
import { getChosenTreatment, getChosenTreatmentResetData, getResetData } from './SignalBuilder.utils';
import { TOP_BAR_CTX_VALUE, TopBarContext } from '@layouts';

export function SignalBuilder({
  uiType = 'signal',
  treatmentPanelType,
  onClose,
  selectTreatment,
}: SignalBuilderProps): JSX.Element {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const sseChannel = useRef<EventSource | null>(null);

  const sseRef = useRef<SseMap>({});

  const purgeSseItem = useCallback((id: string) => {
    const { current: sse } = sseRef;

    sse[id].close();

    delete sse[id];
  }, []);

  const vanishSse = useCallback(
    (shouldItemBePurged = () => true) => {
      const { current: sse } = sseRef;

      for (const id in sse) {
        if (shouldItemBePurged(id)) {
          purgeSseItem(id);
        }
      }
    },
    [purgeSseItem],
  );

  const [chosenAttributeId, setChosenAttributeId] = useState<number | null>(null);

  const { businessUnit } = useHistory().query;

  const { currentExperienceId, editableTreatmentId } = useContext(TreatmentBuilderContext);

  const signalData = signal.useItemData();
  const { items: attributes } = attributeLibrary.useListData();
  const mindsetsData = experience.useMindsetsData();
  const [, setTopBarCtx] = useContext(TopBarContext);

  const methods = useForm<CompositeSignalPayload>({
    defaultValues: {
      id: undefined,
      name: '',
      type: 'mixed',
      isEdit: true,
      businessUnit: {
        id: businessUnit,
      },
      metConditionCount: 1,
      conditions: DEFAULT_CONDITIONS,
    },
  });

  useWebTitle(textTemplate(content.entityManager, { entity: content.data }));

  useEffect(() => {
    if (signalData?.id) {
      methods.reset({
        id: signalData.id,
        businessUnit: {
          id: businessUnit,
        },
        name: signalData.name,
        type: signalData.type,
        isEdit: true,
        metConditionCount: signalData.metConditionCount,
        conditions: signalData.conditions,
        mindsets: [null, { conditions: signalData.conditions, metConditionCount: signalData.metConditionCount }],
      } as ExtendedCompositeSignalPayload);
    }
  }, [signalData, methods, businessUnit]);

  useEffect(() => {
    if (businessUnit) {
      const chosenTreatment = getChosenTreatment(mindsetsData, currentExperienceId, editableTreatmentId);

      if (chosenTreatment) {
        methods.reset(
          getChosenTreatmentResetData(chosenTreatment, businessUnit) as unknown as ExtendedCompositeSignalPayload,
        );
      } else {
        methods.reset(getResetData(businessUnit) as unknown as ExtendedCompositeSignalPayload);
      }
    }
  }, [mindsetsData, currentExperienceId, editableTreatmentId, businessUnit, methods]);

  const runSearch = useCallback(() => {
    if (businessUnit) {
      return dispatch(attributeLibrary.thunk.search({ businessUnit }));
    }
  }, [dispatch, businessUnit]);

  useEffect(() => {
    if (!businessUnit) {
      return undefined;
    }

    const promise = runSearch();

    return () => {
      vanishSse();
      sseChannel.current?.close();

      dispatch(signal.actions.resetCounts());
      dispatch(signal.actions.resetSignal());
      promise?.abort();
    };
  }, [dispatch, vanishSse, runSearch, businessUnit]);

  const updateAttributeData = useCallback(
    (attributeData: UpdateAttributeArg) => {
      dispatch(attributeLibrary.actions.updateAttribute(attributeData));
    },
    [dispatch],
  );

  const runSseObserver = useCallback(
    (uploadingAttrs: UploadingAttributes) => {
      if (uploadingAttrs) {
        const { current: sse } = sseRef;

        uploadingAttrs.forEach((attr) => {
          const { id } = attr;

          let sseItem = sse[id];

          if (!sseItem) {
            dispatch(attributeLibrary.actions.appendAttribute({ ...attr, isUploading: true }));

            sseItem = sse[id] = new EventSource(`${process.env.REACT_APP_SSE_URL}${id}`);

            sseItem.onopen = () => {
              // no action
            };

            sseItem.onmessage = (e: { data: string }) => {
              try {
                const { totalSubscribers } = JSON.parse(e.data);
                updateAttributeData({ id, totalSubscribers, isUploading: false, error: false });
              } catch (err) {
                updateAttributeData({ id, error: true });
              } finally {
                purgeSseItem(String(id));
              }
            };

            sseItem.onerror = () => {
              updateAttributeData({ id, error: true });
              purgeSseItem(String(id));
            };
          }
        });
      }
    },
    [dispatch, purgeSseItem, updateAttributeData],
  );

  useEffect(() => {
    runSseObserver(attributes?.filter((item) => item.isUploading && !sseRef.current[item.id]) || []);

    return () => {};
  }, [runSseObserver, attributes]);

  function onDragEnd(result: DropResult) {
    if (result.reason === 'DROP' && result.destination?.droppableId.includes('signal')) {
      let signalItem = null;
      try {
        signalItem = JSON.parse(result.draggableId);
      } finally {
        methods.setValue(result.destination.droppableId as 'conditions.0.signal', signalItem as never, {
          shouldTouch: true,
          shouldValidate: true,
        });
        methods.setValue(
          result.destination.droppableId.replace('signal', 'operator') as 'conditions.0.operator',
          '' as never,
          { shouldDirty: true, shouldTouch: true },
        );
        methods.setValue(
          result.destination.droppableId.replace('signal', 'value') as 'conditions.0.value',
          '' as never,
          { shouldDirty: true, shouldTouch: true },
        );
      }
    }
  }

  const handleAttributeDoubleClick = useCallback(
    (id: number) => {
      setChosenAttributeId(id);
    },
    [setChosenAttributeId],
  );

  const handleAttributesImportSuccess = useCallback(() => {
    if (businessUnit) {
      sseChannel.current?.close();

      const channel = (sseChannel.current = new EventSource(`${process.env.REACT_APP_SSE_CLIENT_URL}${businessUnit}`));

      channel.onmessage = (e: { data: string }) => {
        let uploadingAttrs: UploadingAttributes = [];

        try {
          const { processingSignals } = JSON.parse(e.data);
          const uploadingAttrsMap = JSON.parse(processingSignals) as UploadingAttributesMap;

          uploadingAttrs = Object.values(uploadingAttrsMap);

          runSseObserver(uploadingAttrs);
        } catch (exception) {
          // no action required
        } finally {
          sseChannel.current?.close();
        }
      };

      channel.onerror = () => {
        sseChannel.current?.close();
      };
    }
  }, [runSseObserver, businessUnit]);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      sectionName: textTemplate(content.entityManager, { entity: content.data }),
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
    };
  }, [setTopBarCtx]);

  return (
    <Box style={uiType === 'treatment' ? { padding: 0 } : {}} className={styles.signalBuilder} data-ui-type={uiType}>
      <SignalBuilderContext.Provider
        value={{
          chosenAttributeId,
          setChosenAttributeId,
          onDoubleClick: handleAttributeDoubleClick,
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Box className={styles.content}>
            {businessUnit && (
              <DataAttributeLibrary
                uiType={uiType}
                treatmentPanelType={treatmentPanelType}
                businessUnit={businessUnit}
                onAttributesImportSuccess={handleAttributesImportSuccess}
              />
            )}
          </Box>
          <Box className={styles.contentLibrary}>
            <FormProvider {...methods}>
              <SignalLibraryBuilder
                uiType={uiType}
                treatmentPanelType={treatmentPanelType}
                onClose={onClose}
                selectTreatment={selectTreatment}
              />
            </FormProvider>
          </Box>
        </DragDropContext>
      </SignalBuilderContext.Provider>
    </Box>
  );
}
