import { content } from '@content';
import { validate } from '@utils';

import { ModuleFieldQuery, ModuleFieldType, ModuleFieldProps, ModuleFieldValue } from './ModuleField.props';

export const getModuleFieldDataset = (
  props: Partial<ModuleFieldProps>,
  exclude: string[] = [],
): Record<string, string> =>
  Object.entries(props)
    .filter(([key]) => !exclude.includes(key))
    .reduce((dataset, [key, value]) => ({ ...dataset, [`data-${key}`]: `${value || ''}` }), {});

export const validateField = (type: ModuleFieldType, value: ModuleFieldValue): string => {
  if (type === 'url' && value && !validate.url(value)) {
    return content.invalidUrl;
  }

  if (type === 'htm' && value && !validate.html(value)) {
    return content.invalidCode;
  }

  return '';
};

const mixinQuery = (
  type: ModuleFieldType,
  value: ModuleFieldValue,
  query?: ModuleFieldQuery,
  append?: boolean,
): ModuleFieldValue => {
  if (type === 'url' && query && typeof value === 'string' && !validateField(type, value)) {
    try {
      const url = new URL(value);

      if (append) {
        Object.entries(query).forEach(([key, val]) => url.searchParams.set(key, val));
      } else {
        Object.keys(query).forEach((key) => url.searchParams.delete(key));
      }

      return `${url.origin}${url.search}`;
    } catch (exc) {} // eslint-disable-line no-empty
  }

  return value;
};

export const decorate = (type: ModuleFieldType, value: ModuleFieldValue, query?: ModuleFieldQuery): ModuleFieldValue =>
  mixinQuery(type, value, query, true);

export const undecorate = (
  type: ModuleFieldType,
  value: ModuleFieldValue,
  query?: ModuleFieldQuery,
): ModuleFieldValue => mixinQuery(type, value, query, false);
