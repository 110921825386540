import { FC, ReactElement } from 'react';
import { variables } from '@styles';
import { BarChartTitleProps } from './BarChart.props';

export const BarChartTitle: FC<BarChartTitleProps> = ({ title, x }): ReactElement => {
  const style = {
    fontSize: variables.font.size.lg,
    fontWeight: variables.font.weight.bold,
    lineHeight: variables.lineHeight.lg,
    letterSpacing: variables.letterSpacing.sm,
    fill: variables.color.primary.gray,
  };

  return (
    <text x={x} y={-30} style={style}>
      {title}
    </text>
  );
};
