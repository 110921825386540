import { image } from '@content';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    fullPageLoader: {
      position: 'fixed',
      display: 'flex',
      paddingLeft: '72px',
      background: `rgba(246, 246, 246, ${variables.opacity.half})`,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1500,
    },
    loaderIcon: {
      width: '35px',
      height: '35px',
      backgroundSize: 'cover',
      backgroundImage: `url(${image.iconeChargementViolet})`,
      animation: 'spin 1s cubic-bezier(0.2, 0.61, 0.71, 0.61) infinite',
    },
  }),
);
