import { makeStyles, createStyles } from '@material-ui/core/styles';

import { theme, variables } from '@styles';

/**
 * LibraryPanel component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    libraryPanel: {
      position: 'relative',
      background: variables.color.primary.backgroundLightestGray,

      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 0,
        width: '100%',
        height: variables.borderWidth.md,
        background: variables.color.primary.lightGray,
        left: 0,
        bottom: 0,
      },
    },

    library: {
      cursor: 'pointer',
      height: '11rem',
      width: '11rem',
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontSize: variables.font.size.xs,

      '&:hover': {
        backgroundColor: variables.color.primary.backgroundLightGray,
      },
    },

    libraryName: {
      color: variables.color.primary.darkestGray,
    },

    tabs: {
      display: 'inline-flex',
      minHeight: 'unset',
    },

    tab: {
      minHeight: 'unset',
      textTransform: 'none',
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.md,
      color: variables.color.primary.mediumGray,
      borderRight: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      padding: '11px 10px',
    },

    selectedTab: {
      overflow: 'visible',
      background: variables.color.primary.white,
      textTransform: 'none',
    },

    tabWrapper: {
      flexDirection: 'row',

      '&&': {
        '& > *:first-child': {
          marginRight: theme.spacing(1),
          marginBottom: '0',
        },
      },
    },
  }),
);
