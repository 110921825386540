import { cloneElement, HTMLProps, MouseEvent, useCallback, useState } from 'react';
import { Button, Menu as MaterialMenu } from '@material-ui/core';

import { Icon, Loader, Typography } from '@components';

import { Option, OptionItem } from './Option';

import { MenuProps } from './Menu.props';
import { useStyles } from './Menu.styles';
import { func } from '@utils';

/*
  Menu component with the possibility to create submenus.
  Gets an array of options (menu items) and on click callback.
  Based on Material UI Menu.
*/

export const Menu = ({
  options,
  children,
  title,
  capitalizedLabel = false,
  enableHover = false,
  buttonClassName,
  appearance = {},
  loading = false,
  showCheckmark = true,
  onToggle = func.nop,
  onClick,
}: MenuProps): JSX.Element => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      onToggle(true);
    },
    [setAnchorEl, onToggle],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onToggle(false);
  }, [setAnchorEl, onToggle]);

  const handleItemClick = useCallback(
    (option: OptionItem) => {
      onClick(option);
      handleClose();
    },
    [onClick, handleClose],
  );

  const handleHover = (e: any) => {
    setAnchorEl(e?.currentTarget);
    onToggle(true);
  };

  const handleCustomAnchorClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      handleClick(event);
      const customAnchorProps = (children?.props || {}) as HTMLProps<HTMLElement>;

      if (customAnchorProps.onClick) {
        customAnchorProps.onClick(event);
      }
    },
    [handleClick, children?.props],
  );

  return (
    <>
      {children ? (
        cloneElement(children, { onClick: handleCustomAnchorClick })
      ) : (
        <Button
          className={`${buttonClassName} ${styles.button}`}
          data-opened={Boolean(anchorEl)}
          style={loading ? { borderColor: '#6B11C9', borderWidth: 'thin', backgroundColor: '#f6f7f9' } : {}}
          variant="outlined"
          color="secondary"
          onClick={handleClick}
          onMouseEnter={enableHover ? handleHover : func.nop}
        >
          {title ? (
            <Typography.Headline className={styles.menuTitle} style={loading ? { color: '#6B11C9' } : {}}>
              {title}
              {loading ? (
                <span style={{ marginLeft: '10px' }}>
                  <Loader width={15} height={15} />
                </span>
              ) : (
                <Icon.ChevronDown />
              )}
            </Typography.Headline>
          ) : (
            <Icon.EllipsisHorizontal />
          )}
        </Button>
      )}
      {options.length !== 0 && (
        <MaterialMenu
          elevation={0}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          keepMounted
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: appearance.anchor?.y || 'bottom',
            horizontal: appearance.anchor?.x || 'left',
          }}
          transformOrigin={{
            vertical: appearance.transform?.y || 'top',
            horizontal: appearance.transform?.x || 'left',
          }}
          MenuListProps={{
            className: styles.menuList,
          }}
          PaperProps={{
            className: styles.paper,
            ...({ 'data-anchor-y': appearance.anchor?.y || 'bottom' } as any), // eslint-disable-line @typescript-eslint/no-explicit-any
          }}
        >
          {options.map((option) => (
            <Option
              {...option}
              onClick={handleItemClick}
              active={option.selected}
              showCheckmark={showCheckmark}
              key={option.id}
              capitalizedLabel={capitalizedLabel}
            />
          ))}
        </MaterialMenu>
      )}
    </>
  );
};
