import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

import { content } from '@content';
import { Icon, Typography } from '@components';

import { DisclosureInfo } from './DisclosureInfo';

import { DisclosureProps } from './Disclosure.props';
import { useStyles } from './Disclosure.styles';
import { FormValues } from './Disclosure.types';

/**
 * Disclosure component
 * @returns {JSX.Element}
 */

export const Disclosure = ({ error }: DisclosureProps): JSX.Element => {
  const styles = useStyles();
  const formMethods = useForm<FormValues>({
    defaultValues: {
      disclosureInfo: content.disclosureEditInfo,
    },
  });

  const { setValue, watch } = formMethods;

  const [agreed, setAgreed] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const disclosureInfo = watch('disclosureInfo');

  const handleEditMode = useCallback(() => {
    if (!disclosureInfo) {
      setValue('disclosureInfo', content.disclosureEditInfo);
    }

    setEditMode(!isEditMode);
  }, [disclosureInfo, isEditMode, setValue]);

  const handleAgreedToggle = useCallback(() => setAgreed((prev) => !prev), [setAgreed]);

  return (
    <Box className={styles.disclosure}>
      <FormProvider {...formMethods}>
        {error && (
          <Box className={styles.mustReview}>
            <Icon.AlertCircleOutline />
            <Typography.SuperSmallCaption className={styles.mustReviewText}>
              {content.disclosureMustReview}
            </Typography.SuperSmallCaption>
          </Box>
        )}
        <Box className={styles.content}>
          <Box data-has-error={error} className={styles.borderTop} />
          <Box className={styles.checkboxWrapper}>
            <FormGroup row>
              <FormControlLabel
                name="hasDisclosure"
                control={<Checkbox checked={agreed} onChange={handleAgreedToggle} />}
                label={content.disclosureLabel}
              />
            </FormGroup>
          </Box>
        </Box>
        {agreed && (
          <DisclosureInfo
            error={error}
            isEditMode={isEditMode}
            disclosureInfo={disclosureInfo}
            onEditMode={handleEditMode}
          />
        )}
      </FormProvider>
    </Box>
  );
};
