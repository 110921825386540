import { SVGProps } from 'react';

export const PublishOutline = ({
  width = 24,
  height = 24,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.9157 4.21679C19.9092 4.1852 19.8938 4.15613 19.8713 4.13301C19.8488 4.1099 19.8202 4.09369 19.7888 4.08629C17.6972 3.57432 12.8655 5.39856 10.2451 8.01925C9.77804 8.48281 9.35219 8.98616 8.97233 9.5236C8.16462 9.45046 7.3569 9.51205 6.66919 9.81231C4.72721 10.6669 4.1622 12.8988 4.0045 13.8569C3.99596 13.9094 3.99961 13.9632 4.01517 14.014C4.03073 14.0649 4.05778 14.1115 4.09423 14.1502C4.13068 14.1889 4.17554 14.2187 4.22535 14.2373C4.27516 14.2558 4.32856 14.2627 4.38144 14.2572L7.4996 13.9146C7.50176 14.15 7.51588 14.3851 7.54191 14.6191C7.55818 14.7808 7.63027 14.9317 7.74576 15.046L8.9531 16.2543C9.06735 16.37 9.21834 16.4421 9.38004 16.4583C9.61238 16.4843 9.8459 16.4984 10.0797 16.5007L9.73774 19.6187C9.7324 19.6716 9.73928 19.7249 9.75783 19.7747C9.77639 19.8244 9.80613 19.8693 9.84476 19.9057C9.88338 19.9421 9.92986 19.9692 9.9806 19.9848C10.0313 20.0003 10.085 20.004 10.1374 19.9956C11.0951 19.8416 13.3298 19.2761 14.1786 17.3329C14.4786 16.6439 14.5402 15.8397 14.4709 15.0352C15.0095 14.6554 15.5139 14.2292 15.9783 13.7614C18.6084 11.1438 20.4196 6.41442 19.9157 4.21679Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.293 9.70677C14.1531 9.56696 14.0578 9.38882 14.0192 9.19488C13.9806 9.00094 14.0004 8.79991 14.0761 8.61722C14.1518 8.43452 14.28 8.27837 14.4444 8.16851C14.6089 8.05864 14.8022 8 15 8C15.1978 8 15.3911 8.05864 15.5556 8.16851C15.72 8.27837 15.8482 8.43452 15.9239 8.61722C15.9996 8.79991 16.0194 9.00094 15.9808 9.19488C15.9422 9.38882 15.8469 9.56696 15.707 9.70677C15.6142 9.79972 15.504 9.87347 15.3827 9.92378C15.2614 9.9741 15.1313 10 15 10C14.8687 10 14.7386 9.9741 14.6173 9.92378C14.496 9.87347 14.3858 9.79972 14.293 9.70677Z"
      fill={color}
    />
  </svg>
);
