import { ForwardedRef, forwardRef } from 'react';
import { Typography } from '@material-ui/core';

import { useLabelStyles } from './Typography.styles';
import { TypographyProps } from './Typography.props';

/**
 * Typography.Label component
 * @returns {JSX.Element}
 */
export const Label = forwardRef(
  ({ className = '', ...props }: TypographyProps, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const styles = useLabelStyles();

    return <Typography classes={styles} className={className} {...props} {...{ ref }} />;
  },
);

Label.displayName = 'Typography.Label';
