export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const URL_REGEXP =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const URL_SECURE_REGEXP =
  /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const HTML_COMMENT_REGEXP = /<!--(.*?)-->/g;

export const NUMERIC_REGEXP = /^\d*\.?\d*$/;

export const PASSWORD_MIN_LENGTH = 10;

export const CSV_FILE_TYPES = [
  'text/csv',
  'text/x-comma-separated-values',
  'text/x-csv',
  'application/csv',
  'application/vnd.ms-excel',
];

export const ARCHIVE_FILE_TYPES = [
  'application/zip',
  'application/gzip',
  'application/x-gzip',
  'application/x-zip-compressed',
];

export const HTML_FILE_TYPES = ['text/html'];

export const IMAGE_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

export const JS_FILE_TYPES = ['text/javascript', 'application/json'];
