import { useEffect } from 'react';
import { Box, Typography as MuiTypography } from '@material-ui/core';
import { Pie } from '@nivo/pie';

import { useAppDispatch } from '@store';
import { analytics, businessUnitDeployments } from '@modules';
import { Icon } from '@components';
import { DashboardCard } from '@views';
import { content } from '@content';
import { searchQuery, format } from '@utils';
import { variables } from '@styles';

import { useStyles } from './AppleMailPrivacyCard.styles';
import { DATE_PATTERN } from './AppleMailPrivacyCard.const';

/**
 * AppleMailPrivacyCard component
 * @returns {JSX.Element}
 */

export const AppleMailPrivacyCard = (): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const [businessUnitId] = searchQuery.useMutualParams('businessUnit');

  const data = analytics.useAmriReportData();
  const inMarketDeployments = businessUnitDeployments.useInMarketSortByDateData();
  const lastDeployment = inMarketDeployments.find((item) => item.appleMailReaderPercent);

  useEffect(() => {
    if (!businessUnitId) {
      return undefined;
    }
    const promises = [
      dispatch(analytics.thunk.getAmriChangeReport({ businessUnit: businessUnitId })),
      dispatch(businessUnitDeployments.thunk.getInMarketSortByDate(businessUnitId)),
    ];

    return () => {
      promises.forEach((promise) => promise.abort());
    };
  }, [dispatch, businessUnitId]);

  if (lastDeployment) {
    const { appleMailReaderPercent, commonOpenRate, refinedOpenRate, name, program, waveDate } = lastDeployment;
    const openRates = commonOpenRate - refinedOpenRate;
    const mmpSubscribersPercent = data.currentMonthDeploymentValue - data.previousMonthValue;

    return (
      <DashboardCard icon={<Icon.Apple />} title={content.appleMailPrivacyProtection}>
        <Box className={styles.content}>
          <Pie
            width={72}
            height={72}
            data={[
              {
                id: '1',
                value: appleMailReaderPercent,
              },
              {
                id: '2',
                value: 100 - appleMailReaderPercent,
              },
            ]}
            isInteractive={false}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            colors={[variables.color.primary.mainPurple, variables.color.primary.halfMediumGray]}
            innerRadius={0.7}
          />
          <MuiTypography className={styles.appleMailReaderPercent}>
            {format.percent(appleMailReaderPercent)}
          </MuiTypography>
          <Box>
            <MuiTypography className={styles.bodyText}>
              <b>{format.percent(appleMailReaderPercent)}</b> {content.ofYourSubscribersAppearsToOptedInto}
              <b> {content.appleMailPrivacyProtection}</b> {content.inYourMostRecentDeploymentNamed}
              <b>{` ${name} - ${program.name} `}</b>
              {content.on.toLowerCase()} <b>{waveDate ? format.date(waveDate, DATE_PATTERN) : '--'}.</b>
            </MuiTypography>
            {openRates ? (
              <MuiTypography className={styles.bodyText}>
                {content.openRatesBeingOverstated}
                <b> {format.percent(openRates)}.</b>
              </MuiTypography>
            ) : null}
            {mmpSubscribersPercent ? (
              <MuiTypography className={styles.bodyText}>
                {content.thisPercentageOfMMPSubscribers}
                <span>
                  {mmpSubscribersPercent > 0
                    ? ` ${format.percent(mmpSubscribersPercent)} ${content.higher} `
                    : ` ${format.percent(Math.abs(mmpSubscribersPercent))} ${content.lower} `}
                </span>
                {content.thanYourPriorMonth}
              </MuiTypography>
            ) : null}
          </Box>
        </Box>
      </DashboardCard>
    );
  }
  return <></>;
};
