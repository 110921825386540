import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    warning: {
      color: variables.color.primary.darkestGray,
    },
  }),
);
