import { useState, useEffect, useCallback, memo, ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useController, useFormContext } from 'react-hook-form';
import debounce from 'lodash.debounce';

import { network, format } from '@utils';
import { content } from '@content';
import { Typography } from '@components';
import { SignalOptionType, ConditionSignalValueProps } from './Condition.props';
import { useStyles } from './Condition.styles';

export const ConditionSignalValue = memo(
  ({ mindsetIndex, index, defaultValue, freeSolo, signalId, disabled }: ConditionSignalValueProps): JSX.Element => {
    const [keyword, setKeyword] = useState('');
    const [options, setOptions] = useState([]);
    const [isNumericValues, setIsNumericValues] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setValue } = useFormContext();
    const { field, fieldState } = useController({
      name: `mindsets.${mindsetIndex}.conditions.${index}.value`,
      defaultValue,
      rules: { required: true },
    });

    const styles = useStyles();

    const handleChangeKeyword = debounce(setKeyword, 500);

    const handleChangeOption = useCallback(
      (e: React.ChangeEvent<unknown>, newValue: string | null | SignalOptionType) => {
        if (typeof newValue === 'string') {
          setValue(`mindsets.${mindsetIndex}.conditions.${index}.value`, newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue(`mindsets.${mindsetIndex}.conditions.${index}.value`, newValue.inputValue, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else if (newValue && newValue.value) {
          setValue(`mindsets.${mindsetIndex}.conditions.${index}.value`, newValue.value.toString(), {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else if (newValue && Number(newValue.value) === 0) {
          setValue(`mindsets.${mindsetIndex}.conditions.${index}.value`, newValue?.value, { shouldValidate: true });
        } else {
          setValue(`mindsets.${mindsetIndex}.conditions.${index}.value`, newValue, { shouldValidate: true });
        }
      },
      [index, mindsetIndex, setValue],
    );

    useEffect(() => {
      if (!signalId) {
        return;
      }

      (async () => {
        try {
          setIsLoading(true);
          const resp = await network.get<{
            currentPage: number;
            items: [];
            pageCount: number;
            total: number;
          }>({
            key: 'api_rules_engine_signals_options_search',
            params: {
              signal: signalId,
              keyword,
            },
          });
          if (resp) {
            setOptions(resp.items);
            if (resp.items.length) {
              const isNumberValues = resp.items.some(
                (opt: { value: string | number }) => typeof opt.value === 'number',
              );
              setIsNumericValues(isNumberValues);
            }
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }, [signalId, keyword, setIsNumericValues]);

    return (
      <Autocomplete
        value={field.value}
        getOptionSelected={(opt, val) => opt.value === val}
        onChange={handleChangeOption}
        getOptionLabel={(option) => {
          if (!option) {
            return '';
          }
          if (typeof option === 'string') {
            return option;
          }
          return option.inputValue ? option.inputValue : `${option.value}`;
        }}
        options={options}
        loading={isLoading}
        freeSolo={freeSolo}
        clearOnBlur
        filterOptions={(opts, { inputValue }): SignalOptionType[] => {
          if (isLoading) {
            return [];
          }
          const allOptions = [...opts];

          if (inputValue !== '') {
            allOptions.push({
              inputValue,
              value: `${content.create} "${inputValue}"`,
            });
          }

          return allOptions;
        }}
        disabled={disabled}
        renderOption={(option) => (
          <Typography.Body className={isNumericValues ? styles.numericOption : undefined}>
            {typeof option.value === 'number' ? format.numberTwoFracts(option.value) : option.value}
          </Typography.Body>
        )}
        debug
        fullWidth
        classes={{
          input: isNumericValues ? styles.numericInput : styles.stringInput,
        }}
        onInputChange={(e, value, reason) => {
          if (reason === 'clear' || reason === 'reset') {
            setKeyword('');
          }
        }}
        renderInput={(params) => {
          const { value } = params.inputProps as { value: string };
          const number = +value.replace(',', '');
          const isOptionValue = options.some((opt: { value: number }) => opt.value === number);
          const result = isOptionValue ? format.numberTwoFracts(number) : value;
          return (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                value: result,
              }}
              error={fieldState.invalid}
              onChange={(e) => handleChangeKeyword(e.target.value)}
              onInput={(e: ChangeEvent<HTMLInputElement>) => {
                if (isNumericValues) {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                }
              }}
            />
          );
        }}
      />
    );
  },
);

ConditionSignalValue.displayName = 'ConditionSignalValue';
