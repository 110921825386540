import { FC, ReactElement, SVGProps } from 'react';

export const File: FC<SVGProps<SVGElement>> = ({ className }): ReactElement => (
  <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M2.625 29.6709C1.17717 29.6709 0 28.4492 0 26.9466V3.33566C0 1.83304 1.17717 0.611328 2.625 0.611328H20.125C20.8133 0.611328 21.4632 0.886183 21.9578 1.38504L25.4532 4.87098C25.9607 5.38436 26.25 6.09632 26.25 6.82644V26.9466C26.25 28.4492 25.0728 29.6709 23.625 29.6709H2.625ZM2.625 2.42755C2.142 2.42755 1.75 2.83439 1.75 3.33566V26.9466C1.75 27.4478 2.142 27.8547 2.625 27.8547H23.625C24.108 27.8547 24.5 27.4478 24.5 26.9466V6.82644C24.5 6.58307 24.4032 6.34575 24.2352 6.17624L20.7398 2.6903C20.5718 2.51957 20.3548 2.42755 20.125 2.42755H2.625Z"
      fill="#8C8F96"
    />
    <path
      d="M5.83236 25.1311C5.02852 25.1311 4.37402 24.4518 4.37402 23.6176V10.2987C4.37402 9.46442 5.02852 8.78516 5.83236 8.78516H20.4157C21.2195 8.78516 21.874 9.46442 21.874 10.2987V23.6176C21.874 24.4518 21.2195 25.1311 20.4157 25.1311H5.83236ZM20.124 23.3149V19.6825H11.374V23.3149H20.124ZM9.62402 23.3149V19.6825H6.12402V23.3149H9.62402ZM20.124 17.8662V14.2338H11.374V17.8662H20.124ZM9.62402 17.8662V14.2338H6.12402V17.8662H9.62402ZM20.124 12.4176V10.6014H6.12402V12.4176H20.124Z"
      fill="#8C8F96"
    />
  </svg>
);
