import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Settings component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    settings: {
      height: 'calc(100vh - 23.5rem)',
    },

    content: {
      height: '100%',
      textAlign: 'left',
      padding: theme.spacing(2),
    },

    settingsElem: {
      display: 'grid',
      gridTemplateColumns: '13.2rem 23.4rem 4.9rem',
      alignItems: 'center',
    },

    elemLabel: {},

    elemValue: {
      fontSize: variables.font.size.sm,
      fontWeight: variables.font.weight.semiBold,
      color: variables.color.primary.darkestGray,
    },

    divider: {
      margin: theme.spacing(2, 0),
    },

    editBtn: {
      minWidth: '4.9rem',
    },
  }),
);
