import { content } from '@content';

const NUMERIC_SIGNAL_ARGUMENTS = [
  {
    label: content.is,
    value: '=',
  },
  {
    label: content.isnt,
    value: '<>',
  },
  {
    label: content.isGreaterThan,
    value: '>',
  },
  {
    label: content.isLessThan,
    value: '<',
  },
];

const TEXTUAL_SIGNAL_ARGUMENTS = [
  {
    label: content.has,
    value: '=',
  },
  {
    label: content.hasNot,
    value: '<>',
  },
];

const BOOLEAN_SIGNAL_ARGUMENTS = [
  {
    label: content.has,
    value: '=',
  },
];

const MIXED_SIGNAL_ARGUMENTS = [
  {
    label: content.between,
    value: 'between',
  },
  {
    label: content.happened,
    value: '=',
  },
  {
    label: content.on.toLowerCase(),
    value: 'on',
  },
  {
    label: content.before,
    value: 'before',
  },
  {
    label: content.after,
    value: 'after',
  },
];

export const SIGNAL_ARGUMENTS = {
  numeric: NUMERIC_SIGNAL_ARGUMENTS,
  textual: TEXTUAL_SIGNAL_ARGUMENTS,
  mixed: MIXED_SIGNAL_ARGUMENTS,
  foreign: [],
  boolean: BOOLEAN_SIGNAL_ARGUMENTS,
};
