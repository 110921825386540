import { useCallback, useEffect, useMemo, useRef, useState, UIEvent } from 'react';

import { parse } from '@utils';

import { VIRTUAL_DELAY, VIRTUAL_EDGE } from './Table.const';
import { TableRecord, TableMeasures, TableColumnId, TableColumn } from './Table.props';
import { TableVirtualRenderHook } from './Table.types';

export function useDecoratedColumns<ColumnId extends TableColumnId>(
  columnsInit: TableColumn<ColumnId>[],
): TableColumn<ColumnId>[] {
  return useMemo(() => {
    const columnsCount = columnsInit.length;

    const specificColumnsWidths = columnsInit.reduce<string[]>(
      (acc, column) => [...acc, ...(column.width ? [column.width] : [])],
      [],
    );

    const specificColumnsCount = specificColumnsWidths.length;

    const specificColumnsWidth = specificColumnsCount === 0 ? '' : `(${specificColumnsWidths.join(' + ')})`;

    const regularColumnsCount = columnsCount - specificColumnsCount;

    const regularColumnsWidth = specificColumnsWidth ? `(100% - ${specificColumnsWidth})` : '100%';

    const regularColumnWidth = `calc(${regularColumnsWidth} / ${regularColumnsCount})`;

    return columnsInit.map((column) => ({ ...column, width: column.width || regularColumnWidth }));
  }, [columnsInit]);
}

export function useVirtualRender<ColumnId extends TableColumnId>(
  recordsInit: TableRecord<ColumnId>[],
  measures: TableMeasures,
): TableVirtualRenderHook<ColumnId> {
  const { recordHeight, recordGap, virtualBuffer } = measures;

  const [top, setTop] = useState(0);

  const delay = useRef(0);

  const isVirtual = useMemo(
    () => !!recordHeight && recordsInit.length > VIRTUAL_EDGE,
    [recordHeight, recordsInit.length],
  );

  const bottom = useMemo(() => top + virtualBuffer, [top, virtualBuffer]);

  const space = useMemo(() => `(${recordHeight} + ${recordGap})`, [recordHeight, recordGap]);

  const spacePx = useMemo(() => parse.remToPx(recordHeight) + parse.remToPx(recordGap), [recordHeight, recordGap]);

  const update = useCallback((scrollY) => setTop(Math.max(0, Math.floor(scrollY / spacePx))), [setTop, spacePx]);

  const preholder = useMemo(
    () => (isVirtual && top > 0 ? { minHeight: `calc(${space} * ${top} - ${recordGap})` } : null),
    [isVirtual, space, top, recordGap],
  );

  const postholder = useMemo(
    () =>
      isVirtual && bottom < recordsInit.length - 1
        ? { minHeight: `calc(${space} * ${recordsInit.length - bottom} - ${recordGap})` }
        : null,
    [isVirtual, space, recordsInit.length, bottom, recordGap],
  );

  const records = useMemo(
    () => (isVirtual ? recordsInit.slice(top, bottom) : recordsInit),
    [isVirtual, recordsInit, top, bottom],
  );

  const handleScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      if (isVirtual) {
        window.clearTimeout(delay.current);
        delay.current = window.setTimeout(update, VIRTUAL_DELAY, event.currentTarget.scrollTop);
      }
    },
    [update, isVirtual],
  );

  useEffect(
    () => () => {
      window.clearTimeout(delay.current);
      delay.current = 0;
    },
    [],
  );

  return {
    preholder,
    records,
    postholder,
    handleScroll,
  };
}
