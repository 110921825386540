import { memo } from 'react';
import { Box, Button } from '@material-ui/core';

import { Modal, Typography, Alert } from '@components';
import { content } from '@content';
import { useStyles } from './MindsetDeleteModal.styles';
import { MindsetDeleteModalProps } from './MindsetDeleteModal.props';

export const MindsetDeleteModal = memo(({ open, onClose, onClickDelete }: MindsetDeleteModalProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Modal open={open} maxWidth="42.7rem" onClose={onClose}>
      <Alert className={styles.warning} message={content.warning} />
      <Typography.Body className={styles.message}>{content.youWantDeleteTreatment}</Typography.Body>
      <Box className={styles.footer}>
        <Button variant="contained" color="primary" className={styles.deleteBtn} onClick={onClickDelete}>
          {content.delete}
        </Button>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {content.noCancel}
        </Button>
      </Box>
    </Modal>
  );
});

MindsetDeleteModal.displayName = 'MindsetDeleteModal';
