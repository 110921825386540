import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Rules component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      overflowY: 'scroll',
    },
    conditionsList: {
      marginBottom: theme.spacing(4),
    },
    condition: {
      '&:not(:last-of-type)': {
        marginBottom: variables.gap.sm,
      },
    },
    mindsetMatch: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    metConditionText: {
      color: variables.color.primary.darkestGray,
      marginRight: theme.spacing(1),
    },
    ruleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    operator: {
      margin: theme.spacing(0, 1),
    },
    rulesText: {
      fontSize: variables.font.size.xl,
      margin: theme.spacing(5, 0, 1.5, 0),
    },
    bodyText: {
      color: variables.color.primary.darkestGray,
    },
    draggableContainer: {
      maxWidth: '18rem',
      flexBasis: '18rem',
      flexShrink: 0,
    },
    inputText: {
      color: variables.color.primary.darkestGray,
      marginRight: theme.spacing(1.25),
    },
    signalBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexBasis: '15rem',
      flexShrink: 0,
      maxWidth: '15rem',
    },
    signalFieldContainer: {
      maxHeight: '3.4rem',
    },
    signalField: {
      background: variables.color.primary.white,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
      height: '3.4rem',
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(1, 1.5),
      '&[data-invalid="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.semantic.negative}`,
      },
    },
  }),
);

export const useRuleValueStyles = makeStyles((theme) =>
  createStyles({
    ruleValue: {
      marginRight: theme.spacing(1),
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    hasRule: {
      padding: theme.spacing(0.75, 1),
      color: variables.color.primary.darkestGray,
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.xs,
      '&:hover': {
        background: variables.color.primary.backgroundLighterGray,
        color: variables.color.primary.mainPurple,
      },
    },
    notRule: {
      padding: theme.spacing(0.75, 1),
      color: variables.color.primary.halfMediumGray,
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.xs,
      '&:hover': {
        background: variables.color.primary.backgroundLighterGray,
        color: variables.color.primary.mainPurple,
      },
    },
    icon: {
      marginLeft: theme.spacing(0.5),
      display: 'flex',
      padding: theme.spacing(0.875),
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.75, 1),
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
    },
    inputText: {
      color: variables.color.primary.darkestGray,
      marginRight: theme.spacing(1.25),
    },
    pill: {
      display: 'flex',
      padding: theme.spacing(0.75, 1),
      border: `${variables.borderWidth.sm} solid transparent`,
      '&:hover': {
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.mainPurple}}`,
      },
    },
  }),
);
