import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { content } from '@content';
import { Confirm, TextField } from '@components';
import { textTemplate } from '@utils';

import { EnterpriseEditorProps } from './EnterpriseEditor.props';
import { FormValues } from './EnterpriseEditor.types';

/**
 * EnterpriseEditor component
 * @returns {JSX.Element}
 */

export const EnterpriseEditor = ({ enterprise, onSubmit, onCancel }: EnterpriseEditorProps): JSX.Element => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: enterprise,
  });

  return (
    <Confirm
      open
      title={textTemplate(enterprise ? content.editValue : content.addValue, { value: content.enterprise })}
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      submitLabel={enterprise ? content.save : textTemplate(content.addValue, { value: content.enterprise })}
      skipSubmitArrow
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: content.enterpriseName }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            label={content.enterpriseName}
            placeholder={content.enterpriseName}
            hint={error?.message}
            error={invalid}
          />
        )}
      />
    </Confirm>
  );
};
