import { memo } from 'react';

import { content } from '@content';
import { textTemplate } from '@utils';
import { deployment, DataVizGenerationStatus } from '@modules';
import { DeploymentProcessingAlert } from '@views';

const alertType: {
  [key in Exclude<DataVizGenerationStatus, DataVizGenerationStatus.init>]: 'info' | 'error' | 'success';
} = {
  [DataVizGenerationStatus.processing]: 'info',
  [DataVizGenerationStatus.completed]: 'success',
  [DataVizGenerationStatus.failed]: 'error',
};

const alertText = {
  [DataVizGenerationStatus.processing]: content.dataVisualizationProcessing,
  [DataVizGenerationStatus.completed]: content.dataVisualizationCompleted,
  [DataVizGenerationStatus.failed]: content.dataVisualizationFailed,
};

export const DataVisualizationProcessAlert = memo((): JSX.Element | null => {
  const { status, failedModules } = deployment.useDataVizGenerationStatusData();

  if (status === DataVizGenerationStatus.init) {
    return null;
  }

  return (
    <DeploymentProcessingAlert
      type={alertType[status]}
      text={textTemplate(alertText[status], { value: failedModules.join(', ') })}
    />
  );
});

DataVisualizationProcessAlert.displayName = 'DataVisualizationProcessAlert';
