export const nop = (): void => {};

export const getNop =
  <T>(value: T) =>
  (): T =>
    value;

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
