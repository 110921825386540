import { Box, Tab, Tabs } from '@material-ui/core';

import { LibraryPanelProps } from './LibraryPanel.props';
import { useStyles } from './LibraryPanel.styles';
import { LIBRARIES } from './LibraryPanel.const';
import { ChangeEvent, useCallback, useState } from 'react';
import { Libraries } from '@routes/TemplateView/TemplateView.const';

/**
 LibraryPanel component
 @returns {JSX.Element}
 */

export const LibraryPanel = ({ onChooseLibrary }: LibraryPanelProps): JSX.Element => {
  const styles = useStyles();
  const [tab, setTab] = useState(0);
  const handleChangeTab = useCallback((event: ChangeEvent<unknown>, value: number) => {
    setTab(value);
    switch (value) {
      case 0:
        onChooseLibrary(Libraries.IMAGES);
        break;
      case 1:
        onChooseLibrary(Libraries.DATA_VISUAL);
        break;
      default:
        onChooseLibrary(Libraries.IMAGES);
        break;
    }
  }, []);

  return (
    <Box className={styles.libraryPanel}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        classes={{ root: styles.tabs }}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        {LIBRARIES.map((item) => (
          <Tab
            key={item.id}
            label={item.name}
            classes={{ root: styles.tab, selected: styles.selectedTab, wrapper: styles.tabWrapper }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
