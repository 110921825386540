import { SVGProps } from 'react';

export const Edit = ({ width = 16, height = 16, fill = '#89939C', className }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.06194 10.1756C3.10066 9.98198 3.19581 9.80419 3.33541 9.66459L8.77817 4.22183L11.6066 7.05025L6.16384 12.493C6.02424 12.6326 5.84644 12.7278 5.65285 12.7665L3.88508 13.12C3.18536 13.26 2.56844 12.6431 2.70839 11.9433L3.06194 10.1756Z"
      fill={fill}
    />
    <path
      d="M9.48535 3.51465L10.1925 2.80754C10.583 2.41702 11.2161 2.41702 11.6067 2.80754L13.0209 4.22176C13.4114 4.61228 13.4114 5.24544 13.0209 5.63597L12.3138 6.34308L9.48535 3.51465Z"
      fill={fill}
    />
  </svg>
);
