import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * MindsetCreator component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    mindsetCreator: {
      margin: -theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(0.5),
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
    content: {
      padding: theme.spacing(3),
      display: 'flex',
    },
    continueButton: {
      marginLeft: theme.spacing(1.25),
    },
    topic: {
      width: '50%',
    },
    icon: {
      display: 'flex',
      padding: theme.spacing(1),
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      marginRight: theme.spacing(1.25),
    },
    label: {
      marginBottom: theme.spacing(1),
    },
    leftContainer: {
      width: '62%',
      marginRight: theme.spacing(3),
    },
    rightContainer: {
      width: '38%',
    },
    rowCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
  }),
);

export const useProductStyles = makeStyles((theme) =>
  createStyles({
    productInputOption: {
      color: variables.color.primary.darkestGray,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.regular,
      lineHeight: variables.lineHeight.md,
      letterSpacing: variables.letterSpacing.sm,
      minHeight: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    productInput: {
      '&[data-invalid="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.semantic.negative}`,
        background: variables.color.semantic.backgroundNegative,
        color: variables.color.semantic.negative,
        borderRadius: variables.borderRadius.sm,
        '& input::placeholder': {
          color: variables.color.semantic.negative,
          opacity: 1,
        },
      },
    },
    label: {
      marginBottom: theme.spacing(1),
      '&[data-invalid="true"]': {
        color: variables.color.semantic.negative,
      },
    },
    loading: {
      color: variables.color.primary.darkestGray,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.regular,
      lineHeight: variables.lineHeight.md,
      letterSpacing: variables.letterSpacing.sm,
    },
    endAdornment: {
      right: theme.spacing(1),
    },
    topRowMenuItem: {
      '&&': {
        padding: theme.spacing(0, 2),
        cursor: 'default',
      },
    },
    topRow: {
      width: '100%',
      display: 'flex',
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      padding: theme.spacing(0.5, 0),
    },
    topRowBtn: {
      padding: 0,
      minWidth: 'unset',
      transition: 'opacity 0.25s',

      '&:hover': {
        backgroundColor: 'transparent',
        opacity: '0.6',
      },
    },
    topRowInput: {
      flexGrow: 1,
      '& .MuiBox-root': {
        border: 'none',
      },
      '& input': {
        padding: theme.spacing(1),
      },
      '& input::placeholder': {
        color: variables.color.primary.darkestGray,
        fontWeight: variables.font.weight.bold,
      },
    },
  }),
);

export const useCohortStyles = makeStyles((theme) =>
  createStyles({
    label: {
      marginBottom: theme.spacing(1),
      '&[data-invalid="true"]': {
        color: variables.color.semantic.negative,
      },
    },
    allCohorts: {
      position: 'relative',

      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: theme.spacing(2),
        height: variables.borderWidth.md,
        width: `calc(100% - ${theme.spacing(4)}px)`,
        borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      },
    },
  }),
);
