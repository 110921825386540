import { FC, SVGProps } from 'react';

export const SixDots: FC<SVGProps<SVGElement>> = (): JSX.Element => (
  <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.29487" cy="2.29487" r="2.29487" fill="#8C8F96" />
    <circle cx="10.4936" cy="2.29487" r="2.29487" fill="#8C8F96" />
    <circle cx="2.29487" cy="10.5292" r="2.29487" fill="#8C8F96" />
    <circle cx="10.5009" cy="10.5292" r="2.29487" fill="#8C8F96" />
    <circle cx="2.29487" cy="18.705" r="2.29487" fill="#8C8F96" />
    <circle cx="10.4997" cy="18.705" r="2.29487" fill="#8C8F96" />
  </svg>
);
