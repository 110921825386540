import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ImageLibrary component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    imageLibraryWrapper: {
      position: 'fixed',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },

    imageLibrary: {
      backgroundColor: variables.color.primary.backgroundLighterGray,
      margin: '5% auto',
      padding: theme.spacing(3),
      border: `${variables.borderWidth.md} solid ${variables.color.primary.mediumGray}`,
      width: '31rem',
    },

    header: {
      display: 'flex',
    },

    search: {
      marginRight: theme.spacing(2),
    },

    toggle: {
      marginRight: theme.spacing(1),
    },

    tab: {
      width: '3.3rem',
      height: '3.3rem',
      padding: 0,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.darkestGray,
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      backgroundColor: variables.color.primary.white,

      '&[data-left-tab]': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '&[data-right-tab]': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '&.Mui-selected': {
        color: variables.color.primary.white,
        border: `solid ${variables.borderWidth.lg} ${variables.color.primary.mainPurple}`,
        backgroundColor: variables.color.primary.white,
      },
    },

    menuBtn: {
      padding: variables.gap.sm,
    },

    imageLibraryImages: {
      marginTop: theme.spacing(2),
      display: 'flex',
      maxHeight: '46rem',
      overflow: 'scroll',

      '& > :nth-child(2n)': {
        marginLeft: theme.spacing(1.75),
      },
    },

    imageColumn: {
      flex: '50%',
      maxWidth: '12rem',
      fontSize: 0,
      lineHeight: 0,
    },

    image: {
      marginBottom: theme.spacing(2),

      '&:hover': {
        cursor: 'pointer',
        boxShadow: `0.1rem 0.1rem 0.7rem ${variables.color.primary.gray}`,
      },
    },
  }),
);
