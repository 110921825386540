import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * Underline component
 * @returns {JSX.Element}
 */

export const Underline = ({
  className = '',
  width = 10,
  height = 12,
  fill = variables.color.primary.mediumGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 9.33333C7.36429 9.33333 9.28571 7.54 9.28571 5.33333V0H7.5V5.33333C7.5 6.62 6.37857 7.66667 5 7.66667C3.62143 7.66667 2.5 6.62 2.5 5.33333V0H0.714286V5.33333C0.714286 7.54 2.63571 9.33333 5 9.33333ZM0 10.6667V12H10V10.6667H0Z"
      fill={fill}
    />
  </svg>
);
