import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const size = (value: number) => {
  const strVal = `${value}rem`;
  return {
    minWidth: strVal,
    width: strVal,
    maxWidth: strVal,
    minHeight: strVal,
    height: strVal,
    maxHeight: strVal,
  };
};

const transition = (attr: string) => `${attr} 0.4s`;

const innerGapX = variables.gap.lg;

export const useStyles = makeStyles(() =>
  createStyles({
    collapse: {
      display: 'flex',
      flexDirection: 'column',

      '&&': {
        padding: `${variables.gap.md} 0`,

        '&[data-thin="true"]': {
          padding: `${variables.gap.xs} 0`,
        },
      },
    },

    head: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '3rem',
      transition: transition('margin-bottom'),
      margin: `0 ${innerGapX}`,

      '&[data-open="false"]': {
        marginBottom: 0,
      },

      '&[data-open="true"]': {
        marginBottom: '4rem',

        '&[data-reduce-gap="true"]': {
          marginBottom: variables.gap.sm,
        },
      },
    },

    hat: {
      overflow: 'hidden',
    },

    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '&[data-small="true"]': {
        fontSize: variables.font.size.lg,
      },
    },

    center: {
      margin: `0 ${variables.gap.lg} 0 auto`,
    },

    toggle: {
      ...size(3),
      padding: 0,
      transition: transition('all'),

      '&[data-up="true"]': {
        transform: 'rotate(180deg)',
      },

      '&[data-small="true"]': {
        ...size(2.25),
      },

      '&:disabled': {
        opacity: variables.opacity.medium,
      },
    },

    arrow: {
      ...size(2),
      stroke: variables.color.primary.mainPurple,
    },

    arrowSmall: size(1.5),

    body: {
      position: 'relative',
      overflowY: 'hidden',
      transition: transition('height'),

      '&[data-skip-inner-gap-x="false"]': {
        margin: `0 ${innerGapX}`,
      },
    },

    content: {
      position: 'absolute',
      width: '100%',
      transition: transition('opacity'),
      overflowY: 'auto',
      maxHeight: '50vh',
      marginRight: `-${variables.scrollbar.width}`,

      '&[data-shown="false"]': {
        opacity: variables.opacity.none,
      },

      '&[data-shown="true"]': {
        opacity: variables.opacity.full,
      },
    },
  }),
);
