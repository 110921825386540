import { forwardRef, ForwardedRef } from 'react';
import { Box, Typography as MuiTypography } from '@material-ui/core';

import { func } from '@utils';
import { Icon, Typography } from '@components';
import { useStyles } from './FileInput.styles';
import { FileInputProps } from './FileInput.props';

export const FileInput = forwardRef(
  (
    {
      name = '',
      label,
      accept = '',
      errorMsg = '',
      isFileUploaded = false,
      useRoundedTick = false,
      disabled = false,
      onChange,
      onBlur,
      filename = '',
      className = '',
    }: FileInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const styles = useStyles();

    return (
      <Box className={className}>
        <label className={styles.label}>
          <input
            ref={ref}
            disabled={disabled}
            className={styles.input}
            name={name}
            type="file"
            accept={accept}
            onChange={onChange || func.nop}
            onBlur={onBlur || func.nop}
          />
          <MuiTypography className={styles.text} data-disabled={disabled} component="span">
            {label}
          </MuiTypography>
          {isFileUploaded && !useRoundedTick && (
            <Box className={styles.icon} component="span">
              <Icon.Tick />
            </Box>
          )}
          {isFileUploaded && useRoundedTick && (
            <Box className={styles.icon} component="span">
              <Icon.RoundedTick />
            </Box>
          )}
          {errorMsg && <MuiTypography className={styles.error}>{errorMsg}</MuiTypography>}
        </label>
        <Typography.Caption className={styles.filename} title={filename}>
          {filename}
        </Typography.Caption>
      </Box>
    );
  },
);

FileInput.displayName = 'FileInput';
