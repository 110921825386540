import { useCallback, useState, useEffect, useRef, useImperativeHandle, Fragment, forwardRef, memo } from 'react';
import { Box, ButtonBase } from '@material-ui/core';

import { Icon, Typography } from '@components';
import { doc } from '@utils';
import { variables } from '@styles';
import { deployment } from '@modules';
import { content } from '@content';
import {
  SPLITTER_DATASET_ID_KEY,
  SPLITTER_DATASET_JOURNEY_ID_KEY,
  SPLITTER_BORDER_ACTIVE,
  GLOBAL_STYLES_ID,
  EMPTY_MODULE_NOTE,
} from './SampleTemplate.const';
import { Rows, EmailTemplateSplitter, SampleTemplateProps } from './SampleTemplate.types';
import { useStyles } from './SampleTemplate.styles';
import { fixFrameScale } from './SampleTemplate.utils';

let splitters: { [splitterId: string]: EmailTemplateSplitter } = {};

let listeners: { [event: string]: EventListener } = {};

const emptySplitter = (splitter: EmailTemplateSplitter) => {
  splitter.removeAttribute('data-sample-template-journey');
  splitter.removeAttribute('draggable');
  // eslint-disable-next-line no-param-reassign
  splitter.innerHTML = '';
  // eslint-disable-next-line no-param-reassign
  splitter.journeyData = {
    cursor: 0,
    modules: [],
  };
};

const SampleTemplateWithRef = forwardRef(
  ({ sidebarRef, setLibraryView, setUsedJourneys }: SampleTemplateProps, ref): JSX.Element => {
    const styles = useStyles();
    const {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: { emailTemplateDeployment, positions },
      status,
    } = deployment.useInfoEmailTemplate();
    const [loaded, setLoaded] = useState(false);
    const [rows, setRows] = useState<Rows>({});
    const htmlOld = useRef(emailTemplateDeployment?.html);

    useEffect(() => {
      if (htmlOld.current !== emailTemplateDeployment?.html) {
        htmlOld.current = emailTemplateDeployment?.html;
        setLoaded(false);
      }
    }, [setLoaded, emailTemplateDeployment?.html]);

    const rowsCount = useRef(0);
    const frameWrapper = useRef<HTMLDivElement>(null);
    const frame = useRef<HTMLIFrameElement>(null);

    const attachSplitterEvents = (splitter: HTMLElement) => {
      Object.entries(listeners).forEach(([type, listener]) => {
        splitter.addEventListener(type, listener);
      });
    };

    const detachSplitterEvents = useCallback((splitter: HTMLElement) => {
      Object.entries(listeners).forEach(([type, listener]) => {
        splitter.removeEventListener(type, listener);
      });
    }, []);

    const handleSplitterDragOver = (e: DragEvent) => {
      e.preventDefault();
    };

    const getLastSplitterId = () => Number.parseInt(Object.keys(splitters).pop() || '0', 10);

    const appendSplitter = (splitter: EmailTemplateSplitter) => {
      let insertedNextElement;
      if (frame.current?.contentDocument) {
        const nextSplitter = frame.current.contentDocument.createElement(splitter.tagName);
        const nextSplitterId = getLastSplitterId() + 1;
        nextSplitter.dataset[SPLITTER_DATASET_ID_KEY] = nextSplitterId.toString();
        insertedNextElement = splitter.parentNode?.insertBefore(
          nextSplitter,
          splitter.nextSibling,
        ) as EmailTemplateSplitter;
        if (insertedNextElement) {
          attachSplitterEvents(insertedNextElement);
          splitters[nextSplitterId] = insertedNextElement as EmailTemplateSplitter;
        }
      }

      return insertedNextElement as EmailTemplateSplitter;
    };

    const handleSplitterDrop = (e: DragEvent) => {
      const data = e.dataTransfer?.getData('application/json');
      const { cursor, modules, journeyId, sourceSplitterId } = JSON.parse(data || '{}');
      let splitter = e.currentTarget as EmailTemplateSplitter;

      if (splitter.dataset[SPLITTER_DATASET_JOURNEY_ID_KEY]) {
        splitter = appendSplitter(splitter);
        (e.currentTarget as HTMLElement).style.setProperty('border', '');
      }

      if (modules?.length && splitter) {
        const destSplitterId = splitter.dataset[SPLITTER_DATASET_ID_KEY] as string;

        // skip if drop in same position
        if (destSplitterId === sourceSplitterId) return;

        splitter.innerHTML = modules[cursor].moduleHtml ?? EMPTY_MODULE_NOTE;
        splitter.style.border = '';
        splitter.setAttribute('draggable', 'true');
        splitter.dataset[SPLITTER_DATASET_JOURNEY_ID_KEY] = journeyId;
        splitter.journeyData = {
          modules,
          cursor,
        };

        if (sourceSplitterId) {
          const sourceSplitter = splitters[sourceSplitterId];
          if (
            sourceSplitter.nextElementSibling?.hasAttribute('data-sample-template-splitter') ||
            sourceSplitter.previousElementSibling?.hasAttribute('data-sample-template-splitter')
          ) {
            sourceSplitter.remove();
          } else {
            emptySplitter(sourceSplitter);
          }
        }

        setRows(({ [sourceSplitterId]: remove, ...nextState }) =>
          Object.keys(nextState).reduce(
            (acc, key) => {
              const currSplitter = splitters[key];

              return {
                ...acc,
                [key]: {
                  ...nextState[key],
                  top: currSplitter.getBoundingClientRect().top + currSplitter.offsetHeight / 2,
                },
              };
            },
            {
              [destSplitterId]: {
                top: splitter.getBoundingClientRect().top + splitter.offsetHeight / 2,
                journeyId,
              },
            },
          ),
        );
      }
    };

    const handleSplitterDragStart = (e: DragEvent) => {
      const target = e.target as EmailTemplateSplitter;
      const splitterId = target.dataset[SPLITTER_DATASET_ID_KEY] as string;
      const journeyId = Number.parseInt(target.dataset[SPLITTER_DATASET_JOURNEY_ID_KEY] || '', 10);
      const { cursor, modules } = target.journeyData;

      e.dataTransfer?.setData(
        'application/json',
        JSON.stringify({
          cursor,
          journeyId,
          modules,
          sourceSplitterId: splitterId,
        }),
      );
      e.dataTransfer?.setData(`${splitterId}`, '');
    };

    const handleSplitterDragEnter = (e: DragEvent) => {
      const sourceSplitterId = e.dataTransfer?.types[1];
      const target = e.target as HTMLElement;

      if (target.dataset[SPLITTER_DATASET_ID_KEY] !== sourceSplitterId) {
        target.style.border = SPLITTER_BORDER_ACTIVE;
      }
    };

    const handleSplitterDragLeave = (e: DragEvent) => {
      const target = e.target as HTMLElement;

      if (target.dataset[SPLITTER_DATASET_ID_KEY]) {
        target.style.border = '';
      }
    };

    const handleLoad = useCallback(() => setLoaded(true), [setLoaded]);

    const handleChangeModule = (splitterId: string, direction: 'prev' | 'next') => () => {
      if (!frame.current?.contentDocument) return;
      const splitter = splitters[splitterId];
      if (!splitter) return;
      const { cursor, modules } = splitter.journeyData;
      const arrayLength = modules.length;

      const newCursor = direction === 'next' ? (cursor + 1) % arrayLength : (cursor + arrayLength - 1) % arrayLength;
      const module = modules[newCursor];

      splitter.journeyData.cursor = newCursor;
      splitter.innerHTML = module.moduleHtml ?? EMPTY_MODULE_NOTE;

      setRows((prev) =>
        Object.keys(prev).reduce((acc, key) => {
          const target = splitters[key];
          return {
            ...acc,
            [key]: {
              ...prev[key],
              top: target.getBoundingClientRect().top + target.offsetHeight / 2,
            },
          };
        }, {}),
      );
    };

    const handleSidebarDragOver = (e: DragEvent) => {
      e.preventDefault();

      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'move';
      }
    };

    const handleSidebarDrop = (e: DragEvent) => {
      const sourceSplitterId = e.dataTransfer?.types[1];

      if (sourceSplitterId) {
        const splitter = splitters[sourceSplitterId];
        if (
          splitter.nextElementSibling?.hasAttribute('data-sample-template-splitter') ||
          splitter.previousElementSibling?.hasAttribute('data-sample-template-splitter')
        ) {
          splitter.remove();
        } else {
          emptySplitter(splitter);
        }

        setRows((prevState) => {
          const { [sourceSplitterId]: remove, ...nextState } = prevState;

          return Object.keys(nextState).reduce((acc, splitterId) => {
            const currSplitter = splitters[splitterId];

            return {
              ...acc,
              [splitterId]: {
                ...nextState[splitterId],
                top: currSplitter.getBoundingClientRect().top + currSplitter.offsetHeight / 2,
              },
            };
          }, {});
        });
      }
    };

    const getTemplateHtml = (): string => {
      if (!frame.current?.contentDocument) return '';
      const newFrame = document.createElement('iframe');
      document.body.appendChild(newFrame);

      const frameDocument = newFrame.contentDocument;
      if (!frameDocument) return '';

      frameDocument.open();
      frameDocument.writeln(
        `${doc.getDoctype(frame.current.contentDocument)}${frame.current.contentDocument.documentElement.innerHTML}`,
      );
      frameDocument.close();

      frameDocument.head.querySelector(`style#${GLOBAL_STYLES_ID}`)?.remove();
      frameDocument.querySelectorAll('*[data-sample-template-splitter]').forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.innerHTML = '';
      });

      const html = `${doc.getDoctype(frameDocument)}${frameDocument.documentElement.innerHTML}`;
      newFrame.remove();
      return html;
    };

    useImperativeHandle(
      ref,
      () => ({
        getTemplateHtml,
      }),
      [],
    );

    useEffect(() => {
      listeners = {
        dragover: handleSplitterDragOver as EventListener,
        dragstart: handleSplitterDragStart as EventListener,
        drop: handleSplitterDrop as EventListener,
        dragenter: handleSplitterDragEnter as EventListener,
        dragleave: handleSplitterDragLeave as EventListener,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const frameDoc = frame.current?.contentDocument;
      const frameBody = frameDoc?.body;
      const noStylesYet = !frameDoc?.querySelector(`#${GLOBAL_STYLES_ID}`);

      if (loaded && frameDoc && frameBody && noStylesYet) {
        const wrapper = frameWrapper.current;

        if (wrapper) {
          wrapper.style.height = `${frameDoc.documentElement.offsetHeight}px`;
        }

        const globalStyles = frameDoc.createElement('style');
        globalStyles.setAttribute('type', 'text/css');
        globalStyles.id = GLOBAL_STYLES_ID;
        globalStyles.innerHTML = `
      *[data-sample-template-splitter] {
        background: #ccc;
        border: 2px dashed #53565a;
        min-height: 16px;
      }
      *[data-sample-template-journey] {
        background: initial;
      }
      *[data-sample-template-splitter] * {
        pointer-events: none;
      }
      `;

        frameDoc.head.appendChild(globalStyles);

        if (frameBody.children) {
          const placeholders = frameDoc.querySelectorAll<HTMLElement>('*[data-sample-template-splitter]');

          placeholders.forEach((placeholder) => {
            const id = Number.parseInt(placeholder.dataset[SPLITTER_DATASET_ID_KEY] || '', 10);

            if (id) {
              attachSplitterEvents(placeholder);
              splitters[id] = placeholder as EmailTemplateSplitter;
            }
          });
        }
      }
    }, [loaded, emailTemplateDeployment?.html]);

    useEffect(
      () => () => {
        Object.values(splitters).forEach((splitter) => {
          detachSplitterEvents(splitter);
        });
        splitters = {};
      },
      [detachSplitterEvents, loaded],
    );

    useEffect(() => {
      if (frame.current?.contentDocument) {
        const scale = fixFrameScale(frame.current);
        const frameHeight = frame.current.contentDocument.documentElement.offsetHeight * scale;

        frameWrapper.current?.style.setProperty('height', `${frameHeight}px`); // recalculate iframe wrapper height on drop
      }
    }, [rows, loaded]);

    useEffect(() => {
      const sidebar = sidebarRef.current;

      sidebar?.addEventListener('dragover', handleSidebarDragOver);
      sidebar?.addEventListener('drop', handleSidebarDrop);

      return () => {
        sidebar?.removeEventListener('dragover', handleSidebarDragOver);
        sidebar?.removeEventListener('drop', handleSidebarDrop);
      };
    }, [sidebarRef]);

    // update used journeys list for sidebar filter
    useEffect(() => {
      const currentPositionsCount = Object.keys(rows).length;

      if (currentPositionsCount !== rowsCount.current) {
        const usedJourneys = Object.values(rows).map((row) => row.journeyId);
        setUsedJourneys(usedJourneys);
      }

      rowsCount.current = Object.keys(rows).length;
    }, [rows, setUsedJourneys]);

    useEffect(() => {
      const frameDoc = frame.current?.contentDocument;

      if (status === 'success' && loaded && frameDoc) {
        const filledSplitters = frameDoc.querySelectorAll<HTMLElement>('*[data-sample-template-journey]');

        if (filledSplitters.length) {
          const newRows: Rows = {};
          filledSplitters.forEach((filledSplitter) => {
            const el = filledSplitter as EmailTemplateSplitter;
            const journeyId = Number.parseInt(el.dataset[SPLITTER_DATASET_JOURNEY_ID_KEY] || '', 10);
            const splitterId = el.dataset[SPLITTER_DATASET_ID_KEY];
            const modules = positions.find((pos) => pos.experience.id === journeyId)?.experience.modules;

            if (splitterId) {
              newRows[splitterId] = {
                top: 0,
                journeyId,
              };
            }

            if (modules) {
              el.innerHTML = modules[0].moduleHtml ?? EMPTY_MODULE_NOTE;
              el.journeyData = {
                modules,
                cursor: 0,
              };
            }
          });

          const scale = fixFrameScale(frame.current);
          const frameHeight = frameDoc.documentElement.offsetHeight * scale;

          frameWrapper.current?.style.setProperty('height', `${frameHeight}px`);

          setRows(() =>
            Object.keys(newRows).reduce((acc, key) => {
              const currSplitter = splitters[key];

              return {
                ...acc,
                [key]: {
                  journeyId: newRows[key].journeyId,
                  top: currSplitter.getBoundingClientRect().top + currSplitter.offsetHeight / 2,
                },
              };
            }, {}),
          );
        }
      }
    }, [status, loaded]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box className={styles.sampleTemplate}>
        <Box className={styles.header}>
          <ButtonBase onClick={setLibraryView} className={styles.collapseBtn}>
            <Icon.ChevronRight />
          </ButtonBase>
          <Typography.Title>{content.emailTemplate}</Typography.Title>
        </Box>
        <Box className={styles.content}>
          {Object.keys(rows).map((splitterId: string) => (
            <Fragment key={splitterId}>
              <ButtonBase
                onClick={handleChangeModule(splitterId, 'prev')}
                className={styles.arrows}
                data-arrow="prev"
                style={{ top: `${rows[splitterId].top}px` }}
              >
                <Icon.ArrowPrev width={20} height={20} stroke={variables.color.primary.lightPurple} />
              </ButtonBase>
              <ButtonBase
                onClick={handleChangeModule(splitterId, 'next')}
                className={styles.arrows}
                data-arrow="next"
                style={{ top: `${rows[splitterId].top}px` }}
              >
                <Icon.ArrowNext width={20} height={20} stroke={variables.color.primary.lightPurple} />
              </ButtonBase>
            </Fragment>
          ))}
          {status === 'success' && (
            <Box {...{ ref: frameWrapper }} className={styles.iframe} data-loaded={loaded}>
              <iframe
                srcDoc={emailTemplateDeployment?.html}
                onLoad={handleLoad}
                ref={frame}
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                title="SampleTemplate"
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  },
);

SampleTemplateWithRef.displayName = 'SampleTemplateWithRef';

export const SampleTemplate = memo(SampleTemplateWithRef);
