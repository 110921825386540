import { useForm } from 'react-hook-form';

import { Confirm, Typography } from '@components';
import { content } from '@content';

import { RemoveExperienceModalProps } from './RemoveExperienceModal.props';
import { useStyles } from './RemoveExperienceModal.styles';

/**
 * RemoveExperienceModal component
 * @returns {JSX.Element}
 */

export const RemoveExperienceModal = ({ label, onClose, onSubmit }: RemoveExperienceModalProps): JSX.Element => {
  const styles = useStyles();

  const { handleSubmit } = useForm();

  return (
    <Confirm
      maxWidth="48rem"
      open
      title={label}
      submitLabel={content.yesDelete}
      skipSubmitArrow
      onCancel={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography.Body className={styles.message}>{content.itsNotPossibleUndoThisAction}</Typography.Body>
    </Confirm>
  );
};
