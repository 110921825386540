import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    deploymentDropdown: {
      width: '24rem',
      zIndex: 1,
      '& .MuiInputBase-input': {
        fontWeight: variables.font.weight.bold,
        padding: `${variables.gap.lg} 2.5rem 1.3em ${variables.gap.lg}`,
      },
    },
    placeholder: {
      zIndex: 1,
      color: variables.color.primary.gray,
      fontWeight: variables.font.weight.bold,
      opacity: variables.opacity.full,
      padding: `${variables.gap.lg} 2.5rem 1.3em ${variables.gap.lg}`,
    },
  }),
);
