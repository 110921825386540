let value = '';

function get(): string {
  return value;
}

function set(newValue: string): void {
  value = newValue;
}

export const token = {
  get,
  set,
};
