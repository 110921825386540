import { useMemo } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';

import { content, page } from '@content';
import {
  Deployment,
  DeploymentSubscribersStatus,
  DeploymentStatus,
  DeploymentMailfileStatus,
  DeploymentEntityData,
} from '@modules';

import { useHistory } from './useHistory';

type Alert = { id: string; type: 'error' | 'info'; text: JSX.Element | string };
type DeploymentType = {
  id: number;
  marking: Deployment['marking'];
  businessUnit: { mailPlanTemplateFilePath: string | null };
};

export function useDeploymentAlerts(
  deployment: DeploymentEntityData | DeploymentType,
  businessUnitId: number,
  currentPage: string | undefined,
  displayUploadLink = true,
): Alert[] {
  const history = useHistory();

  const uploadLink = useMemo(
    () =>
      displayUploadLink &&
      deployment && (
        <>
          &nbsp;
          <Link to={history.getUrl('dataCenter', { businessUnit: businessUnitId, deployment: deployment.id })}>
            {content.upload}
          </Link>
        </>
      ),
    [businessUnitId, deployment, displayUploadLink, history],
  );

  if (!deployment || !deployment.marking) {
    return [];
  }

  return Object.keys(deployment.marking).reduce((acc, elem) => {
    switch (elem) {
      case DeploymentStatus.new:
        if (currentPage === page.publishToEsp) {
          acc.push({ id: nanoid(), type: 'info', text: content.deploymentNew });
        }
        break;
      case DeploymentStatus.updating:
        if (currentPage === page.experienceBuilder || currentPage === page.moduleRecommender) {
          acc.push({ id: nanoid(), type: 'info', text: content.deploymentUpdating });
        }
        break;
      case DeploymentStatus.publishing:
        if (currentPage === page.publishToEsp) {
          acc.push({ id: nanoid(), type: 'info', text: content.deploymentPublishing });
        }
        break;
      case DeploymentStatus.cloning:
        if (currentPage === page.experienceBuilder || currentPage === page.moduleRecommender) {
          acc.push({ id: nanoid(), type: 'info', text: content.deploymentCloning });
        }
        break;
      case DeploymentSubscribersStatus.loading:
        acc.push({ id: nanoid(), type: 'info', text: content.subscribersLoading });
        break;
      case DeploymentSubscribersStatus.loadFailed:
        acc.push({
          id: nanoid(),
          type: 'error',
          text: (
            <>
              {content.subscribersLoadFailed}
              {uploadLink}
            </>
          ),
        });
        break;
      case DeploymentMailfileStatus.uploading:
        if (currentPage === page.experienceBuilder || currentPage === page.moduleRecommender) {
          acc.push({ id: nanoid(), type: 'info', text: content.treatmentRequestFileUploading });
        }
        break;
      case DeploymentMailfileStatus.uploaded:
        if (currentPage === page.experienceBuilder || currentPage === page.moduleRecommender) {
          acc.push({ id: nanoid(), type: 'info', text: content.treatmentRequestFileUploaded });
        }
        break;
      case DeploymentMailfileStatus.uploadFailed:
        if (currentPage === page.experienceBuilder || currentPage === page.moduleRecommender) {
          acc.push({
            id: nanoid(),
            type: 'error',
            text: (
              <>
                {content.treatmentRequestFileUploadFailed}
                {uploadLink}
              </>
            ),
          });
        }
        break;
      default:
        break;
    }

    return acc;
  }, [] as Alert[]);
}
