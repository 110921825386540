import { useState, useCallback, useMemo } from 'react';
import { Box, Button } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { useFormContext, useWatch } from 'react-hook-form';

import { content } from '@content';
import { Icon, Typography } from '@components';
import { textTemplate } from '@utils';

import { FormValues } from '../ExperienceEditor.types';

import { MindsetItem } from './MindsetItem';
import { MindsetCreator } from './MindsetCreator';

import { useStyles } from './MindsetPrioritization.styles';
import { MindsetPrioritizationProps } from './MindsetPrioritization.props';

/**
 * MindsetPrioritization - view/reorder list of experience's mindsets.
 * @returns {JSX.Element}
 */
export const MindsetPrioritization = ({ entityId, onChange }: MindsetPrioritizationProps): JSX.Element => {
  const styles = useStyles();

  const { getValues } = useFormContext<FormValues>();

  const droppable = !getValues('isFileBased');

  const fullList = useWatch<FormValues, 'mindsets'>({ name: 'mindsets' });

  const list = useMemo(() => fullList.filter((item) => item.id), [fullList]);

  const [mindsetCreatorOpen, setMindsetCreatorOpen] = useState(false);

  const toggleMindsetCreator = useCallback(() => {
    setMindsetCreatorOpen((prev) => !prev);
  }, [setMindsetCreatorOpen]);

  return (
    <Box className={styles.mindsetPrioritization}>
      <Box className={styles.header}>
        <Box className={styles.icon}>
          <Icon.Mindset />
        </Box>
        <Box className={styles.action}>
          <Typography.Display className={styles.actionTitle}>{content.treatmentPrioritization}</Typography.Display>
          <Typography.SmallCaption className={styles.actionSubTitle}>
            {content.treatmentPrioritizationHelp}
          </Typography.SmallCaption>
          <Button variant="outlined" startIcon={<Icon.AddCircleOutline />} onClick={toggleMindsetCreator}>
            {textTemplate(content.addValue, { value: content.treatment })}
          </Button>
        </Box>
      </Box>
      <Box className={styles.content}>
        {list?.length === 0 ? (
          <Box className={styles.noItems}>
            <Icon.PartlySunny />
            <Typography.Title>
              {textTemplate(content.noValueCreated, { value: content.treatment.toLowerCase() })}
            </Typography.Title>
          </Box>
        ) : (
          <Droppable droppableId="mindsets" isDropDisabled={!droppable} type="MINDSET">
            {(provided) => (
              <Box {...{ ref: provided.innerRef }} {...provided.droppableProps}>
                {list?.map((item, index) => (
                  <MindsetItem
                    key={item.id}
                    index={index}
                    entity={item}
                    disabled={!droppable}
                    selected={item.id === entityId}
                    onClick={onChange}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        )}
      </Box>
      <MindsetCreator open={mindsetCreatorOpen} index={list?.length} onClose={toggleMindsetCreator} />
    </Box>
  );
};
