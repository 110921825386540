import { SVGProps } from 'react';

export const DraggableV2 = ({
  width = 16,
  height = 16,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="6" cy="4" r="1" />
    <circle cx="6" cy="8" r="1" />
    <circle cx="6" cy="12" r="1" />
    <circle cx="10" cy="4" r="1" />
    <circle cx="10" cy="8" r="1" />
    <circle cx="10" cy="12" r="1" />
  </svg>
);
