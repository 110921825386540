import { Button, Box, Divider } from '@material-ui/core';

import { Modal, Typography } from '@components';
import { content } from '@content';
import { useStyles } from './DisclosureWarningModal.styles';
import { DisclosureWarningModalProps } from './DisclosureWarningModal.props';

/**
 * DisclosureWarningModal component
 * @returns {JSX.Element}
 */

export const DisclosureWarningModal = ({ onClose, onConfirm }: DisclosureWarningModalProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Modal open onClose={onClose} maxWidth="41.6rem">
      <Box className={styles.disclosureWarningModal}>
        <Typography.Display className={styles.header}>{content.disclosureCopyAdded}</Typography.Display>
        <Divider />
        <Typography.Label className={styles.content}>{content.disclosureWarningText}</Typography.Label>
        <Divider />
        <Box className={styles.footer}>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            {content.gotIt}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
