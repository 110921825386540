import { Box } from '@material-ui/core';

import { SignalLibrary } from './SignalLibrary';

/**
 DataAttributesPanel component
 @returns {JSX.Element}
 */

export const DataAttributesPanel = (): JSX.Element => (
  <Box>
    <SignalLibrary />
  </Box>
);
