import { Box } from '@material-ui/core';
import { DashboardCardProps } from './DashboardCard.props';
import { DashboardHeader } from './DashboardHeader';

/**
 * DashboardCard component
 * @returns {JSX.Element}
 */

export const DashboardCard = ({
  icon,
  title,
  subtitle,
  children,
  hasChevronIcon,
  className = '',
}: DashboardCardProps): JSX.Element => {
  return (
    <Box className={className}>
      <DashboardHeader icon={icon} title={title} subtitle={subtitle} hasChevronIcon={hasChevronIcon} />
      {children}
    </Box>
  );
};
