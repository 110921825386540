import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ModulesList component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    modulesList: {
      padding: theme.spacing(1, 0),
    },

    title: {
      color: variables.color.primary.darkestGray,
    },

    section: {
      margin: theme.spacing(1, 0, 1, 1),
      paddingLeft: theme.spacing(1),
      borderLeft: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGrayDarker}`,
    },

    row: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0.5, 0),
      gap: '15px',
    },

    moduleName: {
      minWidth: '55px',
      width: '55px',
    },
  }),
);
