import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * ImageContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    editMode: {
      display: 'flex',
      gap: '10px',
      width: '100%',
      justifyContent: 'flex-end',
    },
    radioGroup: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',

      ['@media (min-width: 1200px) and (max-width: 1580px)']: {
        flexWrap: 'nowrap',
      },
    },
    radioLabel: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '62px',
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
