import { array, network, Routing, sort, textTemplate } from '@utils';
import { ApiError, ExperienceSearchArgs, global, SignalOption } from '@modules';
import { createAsyncThunk } from '@store';
import { content } from '@content';

import {
  Experience,
  ExperienceListPayload,
  ExperienceItemPayload,
  ExperienceItemMindset,
  Counts,
  ExperienceMindsetTable,
  MindsetTableElement,
  ExperienceSortBy,
} from './Experience.schema';
import { ExperiencePayload, GetSubscriberCountArg } from './Experience.types';
import { convertStringToBool } from './Experience.utils';

export const getSubscriberCount = createAsyncThunk<(Counts | void)[], GetSubscriberCountArg>(
  'experience/getSubscriberCount',
  async (data, { rejectWithValue, signal }) => {
    try {
      const promises = data.mindsets?.map((mindset, index) => {
        const body = JSON.stringify({
          type: 'mindset',
          index,
          metConditionCount: mindset.metConditionCount,
          conditions: mindset.conditions.map((condition, ind) => ({
            index: ind,
            signal: {
              id: condition.signal?.id,
            },
            operator: condition.operator,
            value: convertStringToBool(condition.value),
            required: condition.required,
          })),
          experience: {
            businessUnit: {
              id: data.businessUnit?.id,
            },
            name: data.name,
            ...(data.mailFile ? { mailFileId: data.mailFile } : null),
            mindsets: data.mindsets
              .filter((item) => item)
              .map((item, idx) => ({
                type: 'mindset',
                index: idx,
                metConditionCount: item.metConditionCount,
                conditions: item.conditions.map((cond, condInd) => ({
                  index: condInd,
                  signal: {
                    id: cond.signal?.id,
                  },
                  operator: cond.operator,
                  value: convertStringToBool(cond.value),
                  required: cond.required,
                })),
              })),
          },
        });

        return network.post<Counts>(
          {
            key: 'api_rules_engine_subscriber_match_count_by_business_unit_post',
            params: { businessUnit: data.businessUnit?.id },
          },
          { body, signal },
        );
      });

      return await Promise.all(
        (promises ?? []).map(
          (promise) => Promise.resolve(promise).then((value) => value),
          (err: ApiError) => err,
        ),
      );
    } catch (error) {
      return rejectWithValue(error as ApiError);
    }
  },
);

export const create = createAsyncThunk(
  'experience/create',
  async (data: ExperiencePayload, { rejectWithValue, dispatch, signal }) => {
    try {
      const resp = await network.post<ExperienceItemPayload>(
        { key: 'api_rules_engine_experience_create' },
        {
          body: JSON.stringify(data),
          signal,
        },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.createdSuccessfully, { value: content.moduleGroup }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return resp;
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    }
  },
);

export const createWithFile = createAsyncThunk<Experience | void, FormData>(
  'experience/createWithFile',
  async (body, { rejectWithValue, dispatch }) => {
    try {
      // dispatch(global.actions.isLoading(true));
      const resp = await network.post<Experience>(
        { key: 'experience_create_with_file' },
        {
          body,
        },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.createdSuccessfully, { value: content.moduleGroup }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return resp;
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const update = createAsyncThunk<ExperienceItemPayload | void, [ExperiencePayload, number]>(
  'experience/update',
  async ([data, experience], { rejectWithValue, dispatch }) => {
    try {
      // dispatch(global.actions.isLoading(true));

      const resp = await network.put<ExperienceItemPayload>(
        { key: 'api_rules_engine_experience_update', params: { experience } },
        {
          body: JSON.stringify(data),
        },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.updatedSuccessfully, { value: content.moduleGroup }),
      //     options: { variant: 'success' },
      //   }),
      // );

      if (resp) {
        dispatch(getSubscriberCount(resp as unknown as Experience));
      }

      return resp;
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const createExternalScoreTreatment = createAsyncThunk<ExperienceItemPayload | void, [ExperiencePayload, number]>(
  'experience/createExternalScoreTreatment',
  async ([data, experience], { rejectWithValue, dispatch }) => {
    try {
      // dispatch(global.actions.isLoading(true));

      const resp = await network.post<ExperienceItemPayload>(
        { key: 'api_rules_engine_experience_external_score_treatments_create', params: { experience } },
        {
          body: JSON.stringify(data),
        },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.updatedSuccessfully, { value: content.moduleGroup }),
      //     options: { variant: 'success' },
      //   }),
      // );

      if (resp) {
        dispatch(getSubscriberCount(resp as unknown as Experience));
      }

      return resp;
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    } finally {
      // dispatch(global.actions.isLoading(false));
    }
  },
);

export const updateWithFile = createAsyncThunk<
  Experience | void,
  {
    body: FormData;
    experience: number;
  }
>('experience/updateWithFile', async ({ body, experience }, { dispatch, rejectWithValue }) => {
  try {
    // dispatch(global.actions.isLoading(true));
    const resp = await network.post<Experience>(
      { key: 'experience_update_with_file', params: { experience } },
      {
        body,
      },
    );

    // dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.updatedSuccessfully, { value: content.moduleGroup }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return resp;
  } catch (e) {
    dispatch(global.actions.enqueueError(e as ApiError));

    return rejectWithValue(e as ApiError);
  } finally {
    // dispatch(global.actions.isLoading(false));
  }
});

export const remove = createAsyncThunk('experience/remove', async (arg, { dispatch, rejectWithValue, getState }) => {
  try {
    const { id } = getState().experience.item.data;
    if (id) {
      // dispatch(global.actions.isLoading(true));
      await network.remove({ key: 'api_rules_engine_experience_delete', params: { experience: id } });
      // dispatch(global.actions.isLoading(false));
    }

    // dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.deletedSuccessfully, { value: content.moduleGroup }),
    //     options: { variant: 'success' },
    //   }),
    // );
    return true;
  } catch (e) {
    // dispatch(global.actions.isLoading(false));
    dispatch(global.actions.enqueueError(e as ApiError));

    return rejectWithValue(e as ApiError);
  }
});

export const search = createAsyncThunk(
  'experience/search',
  async ({ businessUnitId, ...rest }: ExperienceSearchArgs, { rejectWithValue, signal }) => {
    try {
      return await network.get<ExperienceListPayload | void>(
        { key: 'experience_search', params: { businessUnitId: businessUnitId, ...rest } },
        { signal },
      );
    } catch (e) {
      return rejectWithValue(e as ApiError);
    }
  },
);

export const getItem = createAsyncThunk<ExperienceItemPayload | void, number>(
  'experience/getItem',
  async (experience, { rejectWithValue, dispatch }) => {
    try {
      const resp = await network.get<ExperienceItemPayload>({
        key: 'api_rules_engine_experience_item',
        params: { experience },
      });

      if (resp && !resp.isFileBased) {
        dispatch(getSubscriberCount(resp as unknown as Experience));
      }

      return resp;
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    }
  },
);

export const getItemMindsets = createAsyncThunk(
  'experience/getItemMindsets',
  async (experience: number, { rejectWithValue, dispatch, signal }) => {
    try {
      return await network.get<ExperienceItemMindset[]>(
        {
          key: 'api_rules_engine_experience_mindsets',
          params: { experience },
        },
        { signal },
      );
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const getItemsMindsetsBatch = createAsyncThunk(
  'experience/getItemsMindsetsBatch',
  async (experiences: number[], { rejectWithValue, dispatch, signal }) => {
    try {
      const modulesArr = await Promise.all(
        experiences.map((experience) =>
          network.get<ExperienceItemMindset[]>(
            {
              key: 'api_rules_engine_experience_mindsets',
              params: { experience },
            },
            { signal },
          ),
        ),
      );

      return experiences.map((expId, index) => ({
        expId,
        modules: modulesArr[index],
      }));
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const getExperienceOverview = createAsyncThunk<
  ExperienceMindsetTable | void,
  {
    experience: string;
    mailFileId?: string;
  }
>('experience/getExperienceOverview', async (params, { rejectWithValue, dispatch, signal }) => {
  try {
    return await network.get(
      {
        key: 'get_experience_module_recommender',
        params,
      },
      { signal },
    );
  } catch (exception) {
    const error = exception as ApiError;

    dispatch(global.actions.enqueueError(error));

    return rejectWithValue(error);
  }
});

export const getExperienceOverviewBatch = createAsyncThunk(
  'experience/getExperienceOverviewBatch',
  async (
    paramsBatch: {
      experience: string;
    }[],
    { rejectWithValue, dispatch, signal },
  ) => {
    try {
      return await Promise.all(
        paramsBatch.map((params) =>
          network.get<MindsetTableElement>(
            {
              key: 'get_experience_module_recommender',
              params,
            },
            { signal },
          ),
        ),
      );
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const getList = createAsyncThunk<
  ExperienceListPayload | void,
  {
    businessUnit: number;
    page?: number;
    itemsPerPage?: number;
    lastUsed: 1 | 0;
    sortBy: ExperienceSortBy;
    sort?: sort.Order;
  }
>('experience/getList', async ({ businessUnit, ...rest }, { rejectWithValue, dispatch, signal }) => {
  try {
    return await network.get(
      { key: 'api_rules_engine_experience_list_by_business_unit', params: { businessUnit: businessUnit, ...rest } },
      { signal },
    );
  } catch (e) {
    dispatch(global.actions.enqueueError(e as ApiError));

    return rejectWithValue(e as ApiError);
  }
});

export const getSignalsOptions = createAsyncThunk(
  'experience/getSignalsOptions',
  async ({ signals }: { signals: number[]; mindsetIndex: number }, { rejectWithValue }) => {
    const promises = signals.map((signal) => ({
      method: 'Get',
      uri: Routing.generate('api_rules_engine_signals_options_search', { signal }).substring(1),
    }));

    const batches = array.chunk(promises, 200);

    try {
      const resp = (
        await Promise.all(
          batches
            .map((batch) =>
              network.send({ key: 'bulk_action' }, { method: 'PATCH', body: JSON.stringify({ actions: batch }) }),
            )
            .map((p) =>
              Promise.resolve(p).then(
                (val) => val,
                (err) => err,
              ),
            ),
        )
      ).flat(1);

      const result: Record<number, SignalOption[]> = {};
      resp.forEach((i) => {
        if (i.status === 200 && i.body.items.length > 0) {
          result[i.body.items[0].signal.id] = i.body.items;
        }
      });

      return result;
    } catch (e) {
      return rejectWithValue(e as ApiError);
    }
  },
);
