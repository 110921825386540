import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

import { TOOLS } from './RichTextEditor.const';

const position = (value: string) => ({
  ...({ position: value } as any), // eslint-disable-line @typescript-eslint/no-explicit-any
});

const fixedHeight = (value: string) => ({
  height: value,
  minHeight: value,
  maxHeight: value,
});

const richTextEditor = {
  display: 'flex',
  'flex-direction': 'column',
};

const head = {
  display: 'flex',
  justifyContent: 'space-between',
};

const toolSize = '1.8rem';

const toolIconSize = '1.6rem';

const toolGap = variables.gap.sm;

const toolsCount = TOOLS.length;

const toolbar = {
  ...position('relative'),
  width: `calc((${toolSize} + ${toolGap}) * ${toolsCount} - ${toolGap})`,
  maxHeight: toolSize,
  'overflow-y': 'visible',
};

const tools = {
  ...position('absolute'),
  display: 'flex',
};

const tool = {
  width: toolSize,
  height: toolSize,
  padding: 0,
  borderRadius: variables.borderRadius.sm,

  '&:not(:first-child)': {
    marginLeft: toolGap,
  },

  '&.Mui-disabled': {
    opacity: variables.opacity.medium,
  },
};

const toolIcon = {
  width: toolIconSize,
  height: toolIconSize,
};

const toolIconActive = {
  ...toolIcon,

  '&, & *': {
    strokeWidth: '2.5',
  },
};

const area = {
  ...position('relative'),

  '&[data-disabled="true"]': {
    cursor: 'default',
  },

  '& .public-DraftEditorPlaceholder-root': {
    ...position('absolute'),
    opacity: variables.opacity.half,
    lineHeight: variables.lineHeight.xxxl,
    pointerEvents: 'none',
  },

  '& .public-DraftEditor-content': {
    ...fixedHeight('3rem'),
    'overflow-y': 'hidden',
    width: '100%',
    lineHeight: variables.lineHeight.xxxl,
    outline: 'none',
    backgroundColor: 'transparent',
    borderBottom: `${variables.borderWidth.sm} solid ${variables.color.tertiary.gray}`,

    '&:focus': {
      backgroundColor: variables.color.secondary.white,
      borderBottomColor: variables.color.primary.green,
    },

    '&:not(:focus) > div[data-contents="true"]': {
      textOverflow: 'ellipsis',
    },

    '& > div[data-contents="true"]': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      '& *:not(br)': {
        display: 'initial',
        whiteSpace: 'nowrap',
      },

      '& br': {
        display: 'none',
      },
    },
  },
};

export const code = {
  verticalAlign: 'super',
  fontSize: variables.font.size.xxxs,
};

export const useStyles = makeStyles(() =>
  createStyles({
    richTextEditor,
    head,
    toolbar,
    tools,
    tool,
    toolIcon,
    toolIconActive,
    area,
  }),
);
