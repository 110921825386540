import { memo } from 'react';
import { ButtonBase } from '@material-ui/core';
import { Pagination as MuiPagination, PaginationItem } from '@material-ui/lab';

import { Icon, Typography } from '@components';
import { content } from '@content';

import { useStyles } from './Pagination.styles';
import { PaginationProps } from './Pagination.props';

/**
 * Pagination component
 * @returns {JSX.Element}
 */

export const Pagination = memo(({ className = '', page, pageCount, onChangePage }: PaginationProps): JSX.Element => {
  const styles = useStyles();

  return (
    <MuiPagination
      className={className}
      page={page}
      count={pageCount}
      onChange={onChangePage}
      renderItem={(item) => {
        if (item.type === 'previous') {
          return (
            <ButtonBase className={styles.prev} disabled={item.disabled} onClick={item.onClick}>
              <Icon.ChevronLeft />
              <Typography.Headline component="span">{content.prev}</Typography.Headline>
            </ButtonBase>
          );
        }

        if (item.type === 'next') {
          return (
            <ButtonBase className={styles.next} disabled={item.disabled} onClick={item.onClick}>
              <Typography.Headline component="span">{content.next}</Typography.Headline>
              <Icon.ChevronRight />
            </ButtonBase>
          );
        }

        if (item.type === 'end-ellipsis' || item.type === 'start-ellipsis') {
          return (
            <Typography.Headline className={styles.item} component="span">
              ...
            </Typography.Headline>
          );
        }

        return <PaginationItem className={styles.item} {...item} />;
      }}
    />
  );
});

Pagination.displayName = 'Pagination';
