import { MouseEvent, useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';

import { Icon, Typography } from '@components';

import { DropdownProps } from './Dropdown.props';
import { useStyles } from './Dropdown.styles';

export const Dropdown = ({
  buttonClassName,
  title,
  options,
  variant = 'contained',
  color = 'primary',
  disabled = false,
}: DropdownProps): JSX.Element => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (callback: () => void) => {
    callback();
    handleClose();
  };

  return (
    <>
      <Button
        className={`${styles.button} ${buttonClassName}`}
        data-opened={Boolean(anchorEl)}
        variant={variant}
        color={color}
        onClick={handleClick}
        disabled={disabled}
      >
        <span className={styles.buttonTitle}>{title}</span>
        <Icon.ArrowDown />
      </Button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        getContentAnchorEl={null}
        MenuListProps={{
          className: styles.menuList,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: styles.paper,
        }}
      >
        {options.map((option) => (
          <MenuItem
            className={styles.menuItem}
            disabled={!!option.disabled}
            onClick={() => handleClickItem(option.onClick)}
            key={option.label}
          >
            <Typography.Body component="span" className={styles.menuItemLabel}>
              {option.label}
            </Typography.Body>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
