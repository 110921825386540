import { memo } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';

import { Typography, Icon } from '@components';
import { content } from '@content';
import { JourneyInfo } from '@views';
import { useItemStyles } from './JourneyLibrary.styles';
import { JourneyLibraryItemProps } from './JourneyLibrary.types';

export const JourneyLibraryItem = memo(
  ({
    index,
    id,
    name,
    author,
    createdAt,
    updatedAt,
    lastUsed,
    selectJourney,
  }: JourneyLibraryItemProps): JSX.Element => {
    const styles = useItemStyles();

    return (
      <Draggable draggableId={JSON.stringify({ id, name })} index={index}>
        {(provided, snapshot) => (
          <Box
            {...{ ref: provided.innerRef }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={styles.journeyListItem}
            data-isdragging={snapshot.isDragging}
          >
            <Box className={styles.dragIcon}>
              <Icon.SixDots />
            </Box>
            <Box className={styles.journeyName}>
              <Typography.Caption className={styles.journeyNameLabel}>{content.moduleGroupName}</Typography.Caption>
              <Typography.Title noWrap title={name}>
                {name}
              </Typography.Title>
            </Box>
            <JourneyInfo author={author} createdAt={createdAt} updatedAt={updatedAt} lastUsed={lastUsed} />
            <ButtonBase className={styles.collapseBtn} data-collapsed="true" data-id={id} onClick={selectJourney}>
              <Icon.ArrowDown width={22} height={22} stroke="#53565A" />
            </ButtonBase>
          </Box>
        )}
      </Draggable>
    );
  },
);

JourneyLibraryItem.displayName = 'JourneyLibraryItem';
