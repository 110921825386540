export const integer = (value: string): number => {
  const parsed = Number.parseInt(value, 10);
  const isCompatible = parsed.toString() === value;
  const isSafe = Number.isSafeInteger(parsed);

  return isCompatible && isSafe ? parsed : Number.NaN;
};

const PX_PER_REM = 10;

export const remToPx = (rem: string): number => Number.parseFloat(rem) * PX_PER_REM;

export const pxToRem = (px: number): string => `${px / PX_PER_REM}rem`;

/**
 * @example
 * // returns '07/28/2022'
 * dateToMui('2022-07-28');
 * @example
 * // returns null
 * dateToMui('');
 */
export const dateToMui = (dashedDate?: string): string | null => {
  if (dashedDate) {
    const [year, month, date] = dashedDate.split('-');

    return [month, date, year].join('/');
  }

  return null;
};

export const node = (outerHtml: string, doc = document): Element | null => {
  if (outerHtml) {
    const root = doc.createElement('div');

    root.innerHTML = outerHtml;

    return root.firstElementChild;
  }

  return null;
};

export const doc = (outerHtml: string) => {
  const parser = new DOMParser();

  return parser.parseFromString(outerHtml, 'text/html');
};
