import { createAsyncThunk } from '@store';
import { ApiError } from '@modules';
import { network } from '@utils';

import { MailerAuthResponse, MailerCreateResourceArg } from './Mailer.types';

export const authenticate = createAsyncThunk('mailer/authenticate', (businessUnit: number, thunkApi) => {
  try {
    return network.post<MailerAuthResponse>(
      { key: 'api_bee_auth_token', params: { businessUnit: businessUnit } },
      { signal: thunkApi.signal },
    );
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const createResource = createAsyncThunk(
  'mailer/createResource',
  async ({ businessUnit, html }: MailerCreateResourceArg, thunkApi) => {
    const payload = { type: 'image', data: { file_type: 'png', html, width: 640 } };

    try {
      const response = await network.post<Response>(
        { key: 'api_bee_rest_resource_create', params: { businessUnit: businessUnit } },
        { body: JSON.stringify(payload), signal: thunkApi.signal },
      );

      if (response) {
        const blob = await response.blob();

        return new File([blob], 'img.png', { type: blob.type, lastModified: Date.now() });
      }

      return null;
    } catch (exception) {
      return thunkApi.rejectWithValue(exception as ApiError);
    }
  },
);
