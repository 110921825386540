import { FC, ReactElement, useMemo } from 'react';
import { IconButton } from '@material-ui/core';

import { Icon } from '@components';
import { useStyles } from './SortButton.styles';
import { SortButtonProps } from './SortButton.props';

export const SortButton: FC<SortButtonProps> = ({ className, order, onClick, ...extraProps }): ReactElement => {
  const styles = useStyles();

  const [upStrokeWidth, downStrokeWidth] = useMemo(() => {
    const strokeWidth = [];
    switch (order) {
      case 'ASC':
        strokeWidth[0] = '2.5';
        strokeWidth[1] = '0';
        break;
      case 'DESC':
        strokeWidth[0] = '0';
        strokeWidth[1] = '2.5';
        break;
      default:
        strokeWidth[0] = '1.5';
        strokeWidth[1] = '1.5';
    }

    return strokeWidth;
  }, [order]);

  return (
    <IconButton {...extraProps} className={`${className} ${styles.button}`} onClick={onClick}>
      <Icon.ArrowUp strokeWidth={upStrokeWidth} />
      <Icon.ArrowDown strokeWidth={downStrokeWidth} />
    </IconButton>
  );
};
