import { useEffect, useState, useRef, memo, ChangeEvent, KeyboardEvent } from 'react';
import { Box, Slide, Typography, ButtonBase, TextField } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { Icon, Menu, ModuleCard, OptionItem } from '@components';
import { content } from '@content';
import { useAppDispatch } from '@store';
import { modules, ModulesSearchParams, SearchModulesPayload, ModulesSortBy } from '@modules';
import { useStyles } from './ContentSlidesheet.styles';
import { initialQuery } from './ContentSlidesheet.const';
import { ContentSlidesheetProps } from './ContentSlidesheet.types';
import { textTemplate } from '@utils';

/**
 * ContentSlidesheet component
 * (using in the Map Content page)
 * @returns {JSX.Element}
 */

export const ContentSlidesheet = memo(({ open = false, onClose, businessUnitId }: ContentSlidesheetProps) => {
  const styles = useStyles();
  const wrapperElem = useRef<null | HTMLDivElement>(null);
  const [query, setQuery] = useState<ModulesSearchParams>({
    ...initialQuery,
    businessUnitId,
  });
  const [items, setItems] = useState<SearchModulesPayload['items']>([]);
  const data = modules.useLibraryData();
  const { status, error } = modules.useLibraryMeta();
  const dispatch = useAppDispatch();

  //changing menu filters
  const handleMenuItemClick = (option: OptionItem) => {
    const [action, parameter] = option.id.split('.');

    setItems([]);

    let onlyRecentState: boolean;

    switch (action) {
      case 'filter':
        onlyRecentState = parameter === 'onlyRecent';
        return setQuery((prevState) => ({
          ...prevState,
          page: 1,
          onlyRecent: onlyRecentState,
        }));
      case 'sortBy':
        return setQuery((prevState) => ({
          ...prevState,
          page: 1,
          sortBy: parameter as ModulesSortBy,
        }));
    }
  };

  // search by keyword
  const handleChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery((prevState) => ({
      ...prevState,
      keyword: event.target.value,
    }));
  };

  // getting modules by the query
  const getModules = async () => {
    const result = await dispatch(modules.thunk.search(query));

    if (!modules.thunk.search.fulfilled.match(result) || !result.payload?.items) {
      return;
    }

    setItems((prevState) => [...prevState, ...(result.payload?.items || [])]);
  };

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    setItems([]);
    getModules();
  };

  const loadMore = () => setQuery((prevState) => ({ ...prevState, page: query.page + 1 }));

  const clearItems = () => {
    setItems([]);
    setQuery({
      ...initialQuery,
      businessUnitId,
    });
    dispatch(modules.actions.resetLibrary());
  };

  const setHeight = () => {
    if (!wrapperElem.current || !open) {
      return;
    }
    window.scrollTo(0, 0);
    wrapperElem.current.style.height = window.innerHeight - wrapperElem.current.getBoundingClientRect().top + 'px';
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    (async () => {
      const result = await dispatch(modules.thunk.search(query));

      if (!modules.thunk.search.fulfilled.match(result) || !result.payload?.items) {
        return;
      }
      setItems((prevState) => [...prevState, ...(result.payload?.items || [])]);
    })();
  }, [dispatch, query.onlyRecent, query.page, query.sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Slide
      direction="right"
      in={open}
      onEnter={getModules}
      onEntered={setHeight}
      onExited={clearItems}
      mountOnEnter
      unmountOnExit
    >
      <Box className={styles.contentSlidesheet} {...{ ref: wrapperElem }}>
        <Box className={styles.header}>
          <Typography classes={{ root: styles.title }}>
            {textTemplate(content.selectValue, { value: content.contentModule })}
          </Typography>
          <Typography classes={{ root: styles.helperText }}>{content.selectContentHelp}</Typography>
          <ButtonBase classes={{ root: styles.closeBtn }} onClick={onClose}>
            <Icon.CloseOutline width={24} height={24} />
          </ButtonBase>
        </Box>
        <Box className={styles.content}>
          <Box className={styles.searchBar}>
            <TextField
              fullWidth
              variant="standard"
              placeholder={content.search}
              classes={{ root: styles.searchField }}
              value={query.keyword}
              onChange={handleChangeKeyword}
              onKeyPress={handleSearch}
              InputProps={{ disableUnderline: true, endAdornment: <Icon.SearchOutline /> }}
            />
            <Menu
              onClick={handleMenuItemClick}
              options={[
                {
                  id: 'filter.onlyRecent',
                  label: content.onlyRecent,
                  active: query.onlyRecent,
                },
                {
                  id: 'filter.everything',
                  label: content.everything,
                  active: !query.onlyRecent,
                },
                {
                  id: 'divider',
                  type: 'divider',
                },
                {
                  id: 'sortBy',
                  label: content.sortBy,
                  subOptions: [
                    {
                      id: 'sortBy.name',
                      label: content.moduleName,
                      active: query.sortBy === 'name',
                    },
                    {
                      id: 'sortBy.createdAt',
                      label: content.createdDate,
                      active: query.sortBy === 'createdAt',
                    },
                    {
                      id: 'sortBy.updatedAt',
                      label: content.editedDate,
                      active: query.sortBy === 'updatedAt',
                    },
                    {
                      id: 'sortBy.lastUsed',
                      label: content.lastDeployment,
                      active: query.sortBy === 'lastUsed',
                    },
                    {
                      id: 'sortBy.author',
                      label: content.author,
                      active: query.sortBy === 'author',
                    },
                  ],
                },
              ]}
            />
          </Box>
          <Droppable droppableId="content" type="MODULE">
            {(droppableProvided) => (
              <Box
                className={styles.modulesList}
                id="modulesList"
                {...{ ref: droppableProvided.innerRef }}
                {...droppableProvided.droppableProps}
              >
                <InfiniteScroll
                  next={loadMore}
                  loader=""
                  hasMore={data.currentPage < data.pageCount}
                  dataLength={data.items.length}
                  scrollableTarget="modulesList"
                >
                  {items.map((module, index) => (
                    <Draggable key={module.id} index={index} draggableId={`module-${module.id}`}>
                      {(provided) => (
                        <Box
                          className={styles.draggableCard}
                          {...{ ref: provided.innerRef }}
                          {...provided.draggableProps}
                        >
                          <ModuleCard
                            variant="draggable"
                            name={module.name}
                            tag={module.tag.index}
                            coverImage={module.coverImage}
                            publishedAt={module.publishedAt}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                </InfiniteScroll>
                {droppableProvided.placeholder}
              </Box>
            )}
          </Droppable>
          {status === 'loading' && query.page === 1 && <Typography align="center">{content.loading}...</Typography>}
          {status === 'error' && (
            <Typography align="center">{error?.message ? error.message : content.failedToLoadItems}</Typography>
          )}
        </Box>
      </Box>
    </Slide>
  );
});

ContentSlidesheet.displayName = 'ContentSlidesheet';
