import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { getAll, getItem, getList, toggleArchived } from './ClientPrograms.thunk';
import { initialState } from './ClientPrograms.state';
import { ClientProgramsSchema, ProgramItem } from './ClientPrograms.schema';

export const reducers = {
  findById: (state: ClientProgramsSchema, action: PayloadAction<number>): void => {
    state.entity = [...state.list.data.items, ...state.all.data].find((item) => item.id === action.payload);
  },
  resetEntity: (state: ClientProgramsSchema): void => {
    state.entity = initialState.entity;
  },
  resetItem: (state: ClientProgramsSchema): void => {
    state.item = initialState.item;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<ClientProgramsSchema>): void => {
  builder.addCase(getAll.pending, (state, action) => {
    if (state.all.status !== 'loading') {
      state.all.requestId = action.meta.requestId;
      state.all.data = initialState.all.data;
      state.all.status = 'loading';
    }
  });

  builder.addCase(getAll.fulfilled, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.data = action.payload || state.all.data;
      state.all.status = 'success';
    }
  });

  builder.addCase(getAll.rejected, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.error = action.error;
      state.all.status = 'error';
    }
  });

  builder.addCase(getList.pending, (state, action) => {
    if (state.list.status !== 'loading') {
      state.list.requestId = action.meta.requestId;
      state.list.data = initialState.list.data;
      state.list.status = 'loading';
    }
  });

  builder.addCase(getList.fulfilled, (state, action) => {
    if (state.list.status === 'loading' && state.list.requestId === action.meta.requestId) {
      state.list.requestId = initialState.list.requestId;
      state.list.data = action.payload || state.list.data;
      state.list.status = 'success';
    }
  });

  builder.addCase(getList.rejected, (state, action) => {
    if (state.list.status === 'loading' && state.list.requestId === action.meta.requestId) {
      state.list.requestId = initialState.list.requestId;
      state.list.error = action.error;
      state.list.status = 'error';
    }
  });

  /**
   * Fetching program item
   */
  builder.addCase(getItem.pending, (state, action) => {
    if (state.item.status !== 'loading') {
      state.item.requestId = action.meta.requestId;
      state.item.data = initialState.item.data;
      state.item.status = 'loading';
    }
  });

  builder.addCase(getItem.fulfilled, (state, action) => {
    if (state.item.status === 'loading' && state.item.requestId === action.meta.requestId) {
      state.item.requestId = initialState.item.requestId;
      state.item.data = action.payload || initialState.item.data;
      state.item.status = 'success';
    }
  });

  builder.addCase(getItem.rejected, (state, action) => {
    if (state.item.status === 'loading' && state.item.requestId === action.meta.requestId) {
      state.item.requestId = initialState.item.requestId;
      state.item.error = {
        message: action.payload?.message || action.error.message,
      };
      state.item.status = 'error';
    }
  });

  builder.addCase(toggleArchived.pending, (state, action) => {
    if (state.item.status !== 'loading') {
      state.item.requestId = action.meta.requestId;
      state.item.status = 'loading';
    }
  });

  builder.addCase(toggleArchived.fulfilled, (state, action) => {
    if (state.item.status === 'loading' && state.item.requestId === action.meta.requestId) {
      state.item.requestId = initialState.item.requestId;
      if (action.payload) {
        state.item.data = {
          ...state.item.data,
          ...(action.payload as unknown as ProgramItem),
        };
      }
      state.item.status = 'success';
    }
  });

  builder.addCase(toggleArchived.rejected, (state, action) => {
    if (state.item.status === 'loading' && state.item.requestId === action.meta.requestId) {
      state.item.requestId = initialState.item.requestId;
      state.item.error = action.error;
      state.item.status = 'error';
    }
  });
};
