import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * BusinessOutlineV2 component
 * @returns {JSX.Element}
 */

export const BusinessOutlineV2 = ({
  width = 16,
  height = 16,
  color = variables.color.primary.darkestGray,
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M7 5H5V7H7V5Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.19995 13.0002C2.19995 13.5002 2.59995 13.8002 2.99995 13.8002H8.99995C9.04693 13.8002 9.09391 13.7947 9.14024 13.7843C9.19285 13.7948 9.24643 13.8002 9.3 13.8002H13.0001C13.4001 13.8002 13.8001 13.4002 13.8001 13.0002V9.0002C13.8001 8.0002 13.0001 7.2002 12.0001 7.2002H9.79995V4.0002C9.79995 3.0002 8.99995 2.2002 7.99995 2.2002H3.99995C2.99995 2.2002 2.19995 3.0002 2.19995 4.0002V13.0002ZM5.19995 12.2002H3.79995V4.0002C3.79995 3.9002 3.89995 3.8002 3.99995 3.8002H8.09995C8.19995 3.8002 8.29995 3.9002 8.29995 4.0002V12.2002H6.79995V9.8C6.79995 9.3 6.39995 9 5.99995 9C5.59995 9 5.19995 9.3 5.19995 9.8V12.2002C5.19995 12.2001 5.19995 12.2003 5.19995 12.2002ZM12.3001 12.2002H9.8001V8.8002H12.1001C12.2001 8.8002 12.3001 8.9002 12.3001 9.0002V12.2002Z"
      fill={color}
    />
  </svg>
);
