import { memo, useState } from 'react';
import { AppBar, Box, Button, Dialog, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { Typography } from '@components';
import { textTemplate } from '@utils';
import { content } from '@content';

import { useStyles } from './UserAgreement.styles';
import { UserAgreementProps } from './UserAgreement.props';

export const UserAgreement = memo(({ onAcceptTerms }: UserAgreementProps): JSX.Element => {
  const styles = useStyles();
  const [agreed, setAgreed] = useState(false);

  return (
    <Dialog open BackdropProps={{ className: styles.backdrop }} PaperProps={{ className: styles.paper }}>
      <AppBar position="sticky" className={styles.appBar} elevation={0}>
        <Typography.Title>{content.userAgreement}</Typography.Title>
      </AppBar>
      <Box className={styles.content}>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: textTemplate(content.userAgreementText.join('\n'), {
              company: content.company,
              companySupportEmail: content.companySupportEmail,
            }),
          }}
        />
        <Box display="flex" mb="4.3rem">
          <FormGroup row>
            <FormControlLabel
              className={styles.checkboxControl}
              control={<Checkbox className={styles.checkbox} checked={agreed} onChange={() => setAgreed(!agreed)} />}
              label={content.iHaveReadAndAgree}
            />
          </FormGroup>
          <Button variant="contained" color="primary" onClick={onAcceptTerms} disabled={!agreed}>
            {content.ok}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
});

UserAgreement.displayName = 'UserAgreement';
