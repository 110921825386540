import { createAsyncThunk } from '@store';
import { global, ApiError } from '@modules';
import { network } from '@utils';
import { DisclosureAuditPayload, NullSubscriberAuditPayload } from './Audit.schema';

export const getDisclosure = createAsyncThunk<
  DisclosureAuditPayload | void,
  {
    deployment: number;
    page: number;
    itemsPerPage: number;
  }
>('audit/getDisclosure', async ({ deployment, page, itemsPerPage }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_disclosure_audit', params: { deployment, page, itemsPerPage } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getNullSubscriber = createAsyncThunk<
  NullSubscriberAuditPayload | void,
  {
    deployment: number;
    page: number;
    itemsPerPage: number;
  }
>('audit/getNullSubscriber', async ({ deployment, page, itemsPerPage }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_null_subscriber_audit', params: { deployment, page, itemsPerPage } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const exportContentMapExcelFile = createAsyncThunk(
  'audit/exportContentMapExcelFile',
  async (deployment: number, { dispatch, rejectWithValue }) => {
    dispatch(global.actions.isLoading(true));

    try {
      const resp = await network.get<Response>({ key: 'export_deployment_content_map_file', params: { deployment } });

      if (resp) {
        const blob = await resp.blob();
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = resp.headers.get('Content-Disposition')?.split('filename=')[1] || 'content_map_file';
        anchor.click();
      }

      return undefined;
    } catch (exception) {
      dispatch(global.actions.enqueueError(exception as ApiError));

      return rejectWithValue(exception as ApiError);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);
