import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    warning: {
      maxWidth: '34rem',
      marginBottom: '2.2rem',
    },
    message: {
      marginBottom: '1.9rem',
    },
    confirm: {
      marginRight: variables.gap.sm,
    },
  }),
);
