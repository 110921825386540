import { base } from '@modules';

import { UserClientsSchema } from './UserClients.schema';

export const initialState: UserClientsSchema = {
  all: { ...base.state, data: [] },
  list: { ...base.state, data: { ...base.paginagedState } },
  fullList: { ...base.state, data: [] },
  entity: { ...base.state, data: undefined },
  subject: { ...base.state, data: undefined },
  fieldsMapping: { ...base.state, data: undefined },
  espCredsValidation: { ...base.state, data: undefined },
  sftpTestConnection: { ...base.state, data: undefined },
  s3BucketAvailability: { ...base.state, data: { public: undefined, private: undefined, import: undefined } },
};
