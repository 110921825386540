import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';

import { content } from '@content';
import { auth } from '@modules';
import { Icon } from '@components';
import { Form } from '@views';
import { useLoader, validate, textTemplate, useHistory, useWebTitle } from '@utils';
import { useStyles } from './PasswordResetRequest.styles';

export const PasswordResetRequest: React.FC = (): React.ReactElement => {
  const styles = useStyles();
  const meta = auth.usePwdResetInitMeta();
  const dispatch = useDispatch();
  const history = useHistory();

  useWebTitle(content.resetPassword);

  useLoader(meta);

  const handleSubmit = React.useCallback((query) => dispatch(auth.thunk.requestPasswordReset(query)), [dispatch]);

  const handleBackClick = React.useCallback(() => history.push('login'), [history]);

  return (
    <Box className={styles.pwdResetReq}>
      <Form
        className={styles.form}
        keepSucceedEnabled
        status={meta.status}
        requiredWarn={content.enterEmailAddressPlease}
        invalidWarn={content.incorrectEmailAddress}
        successMessage={content.pleaseCheckYourEmailInbox}
        title={content.resetYourPassword}
        fields={[
          {
            type: 'txt',
            id: 'email',
            label: content.email,
            required: true,
            validate: validate.email,
            placeholder: textTemplate(content.enterYour, { value: content.email.toLowerCase() }),
          },
        ]}
        buttonsClassName={styles.buttons}
        buttons={[
          {
            variant: 'text',
            label: content.backToLogin,
            onClick: handleBackClick,
            iconLeft: <Icon.ArrowLeftOutline className={styles.arrowIcon} />,
          },
          { isSubmit: true, label: content.resetPassword, color: 'primary' },
        ]}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};
