import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Box, IconButton } from '@material-ui/core';

import { Typography, Icon } from '@components';
import { variables } from '@styles';
import { content } from '@content';

import { useStyles } from './Accordion.styles';
import { AccordionProps, UiTypes } from './Accordion.types';

/**
 * Accordion component - base UI element
 * @returns {JSX.Element}
 */
export const Accordion = ({
  expanded,
  onChange,
  title,
  subtitle = '',
  icon,
  children,
  uiType = UiTypes.PRIMARY,
  onEdit,
}: AccordionProps) => {
  const styles = useStyles();
  let expandIcon = <span />;
  let expandText = '';
  let accordionStyle = styles.accordion;

  if (uiType === UiTypes.SECONDARY) {
    accordionStyle = styles.accordionSecondary;
    expandIcon = <Icon.ChevronDown width="2.4rem" height="2.4rem" />;
  }
  if (uiType === UiTypes.TERTIARY) {
    accordionStyle = styles.accordionTertiary;
    expandIcon = expanded ? <Icon.RemoveCircleOutline /> : <Icon.AddCircleOutline />;
    expandText = expanded ? content.collapse : content.expand;
  }
  if (uiType === UiTypes.QUATERNARY) {
    accordionStyle = styles.accordionQuaternary;
    expandIcon = <Icon.ChevronDown stroke={variables.color.primary.halfMediumGray} />;
  }

  return (
    <MuiAccordion
      elevation={0}
      expanded={expanded}
      onChange={onChange}
      square
      data-type={uiType}
      classes={{ root: accordionStyle, expanded: styles.expanded }}
    >
      <AccordionSummary className={uiType === UiTypes.PRIMARY ? '' : styles.expandIcon} expandIcon={expandIcon}>
        {icon && <Box className={uiType === UiTypes.PRIMARY ? styles.icon : styles.iconSecondary}>{icon}</Box>}
        <Box>
          <Box className={styles.titleContainer}>
            {uiType === UiTypes.PRIMARY ? (
              <Typography.Display>{title}</Typography.Display>
            ) : uiType === UiTypes.QUATERNARY ? (
              <Typography.SmallTag>{title}</Typography.SmallTag>
            ) : (
              <Typography.Headline className={styles.titleSecondary}>
                {title} {expandText}
              </Typography.Headline>
            )}
            {onEdit && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onEdit();
                }}
              >
                <Icon.Edit />
              </IconButton>
            )}
          </Box>
          {subtitle && <Typography.SmallCaption className={styles.subtitle}>{subtitle}</Typography.SmallCaption>}
        </Box>
      </AccordionSummary>
      <AccordionDetails className={uiType === UiTypes.SECONDARY ? styles.details : ''}>{children}</AccordionDetails>
    </MuiAccordion>
  );
};
