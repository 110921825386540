import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';
import { image } from '@content';

import { SELECT_OPTION_HEIGHT } from './Select.const';
import { SelectProps } from './Select.props';

/**
 * Select component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      '&&': {
        padding: 0,
        borderColor: variables.color.primary.lightGrayDarker,

        '& .MuiInputBase-input': {
          borderRadius: variables.borderRadius.xs,
        },

        '&.Mui-disabled': {
          '& .MuiInputBase-input': {
            backgroundColor: variables.color.primary.lightGrayDarker,
          },

          '& svg': {
            '& path': {
              stroke: variables.color.primary.halfMediumGray,
            },
          },
        },

        '& svg': {
          position: 'absolute',
          right: '1rem',
          top: '50%',
          transform: 'translateY(-50%)',
          pointerEvents: 'none',
        },

        '& [aria-expanded="true"] + input + svg': {
          transform: 'translateY(-50%) rotate(180deg)',
        },
      },

      '&.Mui-error': {
        borderColor: variables.color.semantic.negative,

        '& svg path': {
          stroke: variables.color.semantic.negative,
        },
      },
    },

    menuList: {
      borderRadius: variables.borderRadius.sm,
      padding: theme.spacing(1),
      maxHeight: ({ visibleOptionsLimit }: Partial<SelectProps>) =>
        `calc(${SELECT_OPTION_HEIGHT} * ${visibleOptionsLimit})`,

      '& .MuiMenuItem-root': {
        color: variables.color.primary.darkestGray,
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.bold,
        lineHeight: variables.lineHeight.md,
        minHeight: 'auto',
        padding: theme.spacing(1, 4, 1, 2),

        '&[data-hidden="true"]': {
          display: 'none',
        },
      },

      '& .MuiListItem-root.Mui-selected': {
        backgroundColor: variables.color.primary.mainPurple,
        color: variables.color.primary.white,
        position: 'relative',

        '&:after': {
          content: '""',
          position: 'absolute',
          backgroundImage: `url(${image.checkWhite})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          width: '1.15rem',
          height: '0.95rem',
          top: '50%',
          transform: 'translateY(-50%)',
          right: theme.spacing(1.5),
        },
      },
    },

    captionText: {
      marginBottom: '0.5rem',
    },

    box: {
      position: 'relative',
    },

    placeholder: {
      position: 'absolute',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      borderRadius: variables.borderRadius.sm,
      padding: `${theme.spacing(1)}px ${theme.spacing(4.5)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: variables.font.size.md,

      '&.disabled': {
        zIndex: 1,
      },

      '&&': {
        lineHeight: variables.lineHeight.sm,
        color: variables.color.primary.halfMediumGray,
      },

      '&[data-is-required=true]': {
        transform: 'translateY(-80%)',
      },

      '&[data-has-error=true]': {
        color: variables.color.semantic.negative,
        backgroundColor: variables.color.semantic.negativeLight,
        top: 'auto',
        transform: 'none',
      },
    },

    primary: {
      maxWidth: '55.6rem',
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        width: '100%',
        '&::before': {
          borderBottom: 'none',
        },
        '&::after': {
          borderBottom: 'none',
        },
        '&.Mui-disabled': {
          '& .MuiInputBase-input': {
            color: variables.color.primary.halfMediumGray,
            backgroundColor: variables.color.primary.lightGray,
            borderRadius: variables.borderRadius.sm,
            opacity: variables.opacity.high,
            border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
          },
          '&:before': {
            borderBottom: 'none',
          },
          '&:hover:before': {
            borderBottom: 'none',
          },
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
      },
      '& .MuiInputBase-input': {
        color: variables.color.primary.darkestGray,
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.semiBold,
        lineHeight: variables.lineHeight.sm,
      },
      '& .MuiInputBase-input:focus': {
        backgroundColor: 'transparent',
      },
    },

    secondary: {
      maxWidth: '55.6rem',
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        border: 'none',
        width: '100%',
        '&::before': {
          borderBottom: `${variables.borderWidth.lg} solid ${variables.color.primary.lightPurple}`,
        },
        '&::after': {
          borderBottom: `${variables.borderWidth.lg} solid ${variables.color.primary.lightPurple}`,
        },
        '&:hover:not(.Mui-disabled)::before': {
          borderBottom: `${variables.borderWidth.lg} solid ${variables.color.primary.lightPurple}`,
        },
        '& svg': {
          '& path': {
            stroke: variables.color.primary.halfMediumGray,
          },
        },
      },
      '& .MuiInputBase-input': {
        color: variables.color.primary.darkestGray,
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.semiBold,
        lineHeight: variables.lineHeight.sm,
      },
      '& .MuiInputBase-input:focus': {
        backgroundColor: 'transparent',
      },
      '& .MuiFormLabel-filled + div svg': {
        '& path': {
          stroke: variables.color.primary.darkestGray,
        },
      },
    },

    tertiary: {
      '& .MuiInputBase-root': {
        border: 0,
        padding: theme.spacing(0.5, 0),
        width: '100%',
        '&::before': {
          borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
        },
        '&::after': {
          borderBottom: `${variables.borderWidth.sm} solid ${variables.color.tertiary.gray}`,
        },
        '&:hover:not(.Mui-disabled)::before': {
          borderBottom: `${variables.borderWidth.sm} solid ${variables.color.tertiary.gray}`,
        },
        '& svg': {
          stroke: variables.color.primary.lightPurple,
          right: 0,
        },
        '&.Mui-error': {
          '&::after': {
            borderBottomColor: variables.color.semantic.negative,
          },
          '& svg': {
            stroke: variables.color.semantic.negative,
          },
        },
        '&.Mui-disabled': {
          '& svg': {
            stroke: variables.color.tertiary.gray,
          },
        },
      },
      '& .MuiInputBase-input': {
        color: variables.color.primary.darkestGray,
        lineHeight: variables.lineHeight.lg,
        padding: theme.spacing(0, 3, 0, 0),
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.medium,
        '&.Mui-disabled': {
          color: variables.color.primary.mediumGray,
        },
      },

      '& $hint': {
        paddingLeft: 0,
      },
    },

    vertical: {},

    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      maxWidth: 'initial',

      ...(() => {
        const boxWidth = '28rem';
        const gap = theme.spacing(3);

        return {
          '& > $label': {
            marginTop: theme.spacing(1),
            marginRight: gap,
            width: `calc(100% - ${boxWidth} - ${gap}px)`,

            '&.Mui-disabled': {
              color: variables.color.primary.darkestGray,
            },
          },

          '& > $box': {
            width: boxWidth,

            '& > $select': {
              height: '3.4rem',
            },

            '& > $hint': {
              whiteSpace: 'initial',
            },
          },
        };
      })(),
    },

    horizontalV2: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      maxWidth: 'initial',

      '& > $label': {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(3),
        width: '17.9rem',

        '&.Mui-disabled': {
          color: variables.color.primary.darkestGray,
        },
      },

      '& > $box': {
        width: '28rem',

        '& > $select': {
          height: '3.4rem',
        },

        '& > $hint': {
          whiteSpace: 'initial',
        },
      },
    },

    validationMessage: {
      '&.MuiFormHelperText-root': {
        color: variables.color.semantic.negative,
        fontSize: variables.font.size.xs,
        fontWeight: variables.font.weight.medium,
        lineHeight: variables.lineHeight.xs,
        paddingLeft: theme.spacing(1),
      },
    },

    label: {
      '&.MuiFormLabel-root': {
        color: variables.color.primary.darkestGray,
        fontSize: variables.font.size.md,
        fontWeight: variables.font.weight.semiBold,
        lineHeight: variables.lineHeight.sm,
        position: 'static',
        transform: 'none',
      },
      '&.Mui-focused': {
        color: variables.color.primary.gray,
      },
      '&.MuiFormLabel-filled + div > p[class*="placeholder"]': {
        display: 'none',
      },
      '&.Mui-disabled': {
        color: variables.color.tertiary.gray,
      },

      '&.Mui-error': {
        color: variables.color.semantic.negative,
      },
    },

    hint: {
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.xs,
      color: variables.color.primary.mediumGray,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.5, 0, 0, 1.5),

      '&[data-has-error=true]': {
        color: variables.color.semantic.negative,
      },

      '&[data-disabled=true]': {
        color: variables.color.primary.halfMediumGray,
      },
    },

    required: {
      padding: theme.spacing(0.5, 0, 0, 1.5),
    },
  }),
);

export const usePopoverStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      boxShadow: '1px 1px 7px rgba(208, 208, 206, 0.3)',
      marginTop: theme.spacing(0.5),
    },
  }),
);
