import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';
import { image } from '@content';

/**
 * Accordion component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    accordion: {
      '&[data-type=primary]': {
        '& .MuiAccordionSummary-expandIcon': {
          backgroundImage: `url(${image.plusAccordion})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          padding: 0,
          borderRadius: 0,
          height: '1.5rem',
          width: '1.5rem',

          '&.Mui-expanded': {
            backgroundImage: `url(${image.minus})`,
            transform: 'none',
          },
        },
      },
    },

    accordionSecondary: {
      '&&': {
        backgroundColor: 'unset',
        borderBottom: `solid 1px ${variables.color.primary.lightGray}`,
        marginBottom: '0',

        '&:last-child': {
          marginBottom: '1.6rem',
        },

        '&:before': {
          display: 'none',
        },
      },
    },

    accordionTertiary: {
      position: 'unset',
      '& .MuiAccordionSummary-root': {
        justifyContent: 'flex-start',
        minHeight: 'unset',
      },
      '& .MuiAccordionSummary-content': {
        flexGrow: 0,
        minHeight: 'unset',
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
    },

    accordionQuaternary: {
      '&&': {
        margin: 0,

        '& $expandIcon': {
          minHeight: 'unset',
          backgroundColor: variables.color.primary.backgroundLightestGray,
          padding: theme.spacing(0, 1.5, 0, 2),
          borderTop: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
          borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
        },

        '& .MuiAccordionSummary-content': {
          minHeight: '3.2rem',
        },

        '& .MuiAccordionSummary-expandIcon': {
          borderRadius: 'unset',
          padding: 0,
        },

        '& .MuiAccordionDetails-root': {
          flexDirection: 'column',
          padding: theme.spacing(2),
        },

        '&:before': {
          display: 'none',
        },
      },
    },

    expanded: {
      backgroundImage: 'none',

      '& $icon': {
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.mainPurple}`,
        boxShadow: `inset 0 0 0 1px ${variables.color.primary.mainPurple}`,
      },
    },

    expandIcon: {
      '&&': {
        padding: '0',
        borderBottom: 'none',
      },

      '& .MuiAccordionSummary-expandIcon': {
        backgroundImage: 'unset',
        transform: 'rotate(180deg)',
      },

      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(0)',
      },
    },

    icon: {
      display: 'flex',
      padding: theme.spacing(1),
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      marginRight: theme.spacing(2),
    },

    iconSecondary: {
      marginRight: '1rem',
      display: 'flex',
      alignItems: 'center',
    },

    titleSecondary: {
      textTransform: 'capitalize',
    },

    subtitle: {
      marginTop: theme.spacing(0.5),
      textTransform: 'capitalize',
    },

    details: {
      padding: 0,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
