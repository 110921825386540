import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Label component styles
 * @returns {Function}
 */
export const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      fontSize: variables.font.size.xxxs,
      fontWeight: variables.font.weight.bold,
      borderRadius: '0.2rem',
      padding: '0.2rem 0.5rem',
      lineHeight: '13px',
      letterSpacing: variables.letterSpacing.lg,
      textTransform: 'uppercase',
    },
    code: {
      fontFamily: variables.font.family.code,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.medium,
      padding: '0.4rem 0.8rem',
      lineHeight: variables.lineHeight.md,
      borderRadius: '0.4rem',
    },
    positive: {
      color: variables.color.semantic.positive,
      background: variables.color.semantic.positiveLight,
    },
    negative: {
      color: variables.color.semantic.negative,
      background: variables.color.semantic.negativeLight,
    },
    neutral: {
      color: variables.color.semantic.neutral,
      background: variables.color.semantic.neutralLight,
    },
    highlighted: {
      color: variables.color.primary.mainPurple,
      background: variables.color.primary.lighterPurple,
    },
  }),
);
