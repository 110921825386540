import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const cardWidth = '27.8rem';

const eyeSize = '3rem';

const truncated = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  'white-space': 'nowrap',
};

const warningIconSize = '1.7rem';

export const useStyles = makeStyles(() =>
  createStyles({
    card: {
      background: 'transparent',
      boxShadow: 'none',
      borderRadius: variables.borderRadius.sm,
      maxWidth: cardWidth,
      flexBasis: cardWidth,
    },

    image: {
      borderColor: 'transparent',
      border: `${variables.borderWidth.sm} solid`,
      borderRadius: variables.borderRadius.sm,
      height: 0,
      paddingBottom: '75%',
    },

    placeholder: {
      background: variables.color.secondary.white,
      borderColor: variables.color.tertiary.gray,
      boxSizing: 'border-box',
    },

    content: {
      position: 'relative',
      padding: `${variables.gap.sm} 0.2rem 0`,

      '&.MuiCardContent-root:last-child': {
        paddingBottom: variables.gap.xs,
      },
    },

    eye: {
      background: variables.color.primary.lightPurple,
      borderRadius: variables.borderRadius.half,
      width: eyeSize,
      height: eyeSize,
      position: 'absolute',
      top: '-1.6rem',
      right: variables.gap.sm,
      padding: variables.gap.xs,

      '&:hover': {
        background: variables.color.secondary.blue,
      },
    },

    pretitle: {
      ...truncated,
      lineHeight: 1.2,
    },

    title: {
      ...truncated,
      marginBottom: '0.7rem',
    },

    params: {
      display: 'flex',
      flexDirection: 'column',
    },

    param: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '&:not(:last-child)': {
        marginBottom: '0.2rem',
      },
    },

    label: {
      whiteSpace: 'nowrap',
      marginRight: variables.gap.md,
    },

    value: {
      ...truncated,

      '&&': {
        fontWeight: variables.font.weight.bold,
        color: variables.color.primary.gray,
      },
    },

    warning: {
      display: 'flex',
    },

    warningIcon: {
      width: warningIconSize,
      height: warningIconSize,
      marginRight: variables.gap.sm,
    },

    warningMessage: {
      width: `calc(100% - ${warningIconSize})`,
    },
  }),
);
