import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ModulePopularCard component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      background: 'transparent',
      boxShadow: 'none',
      borderRadius: variables.borderRadius.sm,
      width: '48%',
    },

    cardContent: {
      padding: theme.spacing(1.125, 0, 5.875, 0),
    },

    image: {
      backgroundColor: variables.color.primary.backgroundLightestGray,
      backgroundPosition: '50%',
      borderColor: variables.color.primary.lightGray,
      border: `${variables.borderWidth.sm} solid`,
      borderRadius: variables.borderRadius.xs,
      height: 0,
      paddingBottom: '75%',
      boxSizing: 'border-box',
    },

    row: {
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },

    ctir: {
      margin: theme.spacing(0, 1, 0, 0.5),
      fontSize: variables.font.size.xl,
      fontWeight: variables.font.weight.extraBold,
      color: variables.color.primary.darkestGray,
    },
  }),
);

/**
 * ModulePopularPopover component styles
 * @returns {Function}
 */

export const usePopoverStyles = makeStyles((theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },

    popoverContent: {
      pointerEvents: 'auto',
    },

    popoverContainer: {
      width: '28.8rem',
      height: '20.9rem',
      padding: theme.spacing(2),
      borderRadius: variables.borderRadius.lg,
    },

    popoverRow: {
      marginBottom: theme.spacing(1),
    },
  }),
);
