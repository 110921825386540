import { Box } from '@material-ui/core';

import { useStyles } from './Chip.styles';
import { ChipProps } from './Chip.props';

export const Chip = ({ label, color }: ChipProps): JSX.Element => {
  const styles = useStyles({ color });

  return <Box className={styles.chip}>{label}</Box>;
};
