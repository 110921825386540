import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * DisclosureText component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    disclosureText: {
      textAlign: 'left',
      '& textarea': {
        height: '420px',
      },
    },

    editorWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    editorOverlay: {
      position: 'absolute',
      background: '#505050',
      opacity: '0.6',
      width: '100%',
      height: '100%',
      zIndex: 10,
    },
  }),
);
