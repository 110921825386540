import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    articleLibrary: {
      margin: `0 ${variables.gap.lg}`,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    articleLibraryModal: {
      marginTop: '3.2rem',
    },
    articleLibraryList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(21.3rem, 1fr))',
      gridColumnGap: variables.gap.lg,
      gridRowGap: variables.gap.xl,
    },
    articleLibraryItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    articleLibraryItemImage: {
      height: '14rem',
      objectFit: 'contain',
      marginBottom: variables.gap.sm,
    },
    articleLibraryItemText: {
      flex: '1 1 auto',
      marginBottom: variables.gap.lg,
    },
    articleLibraryItemActions: {
      textAlign: 'center',
    },
  }),
);
