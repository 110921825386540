import React from 'react';
import { Box } from '@material-ui/core';
import { Bar, BarTooltipDatum } from '@nivo/bar';

import { Typography } from '@components';
import { content } from '@content';
import { variables } from '@styles';
import { format } from '@utils';

import { BarStackedChartProps, BarStackedChartTooltipProps as TooltipProps } from './BarStackedChart.props';
import { generateColors, calculatePadding, getYAxisMilestones, calculateWidth } from './BarStackedChart.utils';
import { useStyles } from './BarStackedChart.styles';
import { BarStackedChartTooltip as Tooltip } from './BarStackedChartTooltip';

export const BarStackedChart = ({ data, title, keys }: BarStackedChartProps): JSX.Element | null => {
  const styles = useStyles();

  const [barWidth, setBarWidth] = React.useState(0);

  const chartRef = React.useRef(null);

  const yAxisRef = React.useRef(null);

  const maxValue = Math.max(...data.map((item) => item.value));

  React.useLayoutEffect(() => {
    setBarWidth(calculateWidth(data, chartRef, yAxisRef));
  }, [setBarWidth, data, chartRef, yAxisRef]);

  return data && !data.length ? null : (
    <Box className={styles.barStackedChart} {...{ ref: chartRef }}>
      <Box className={styles.axisY} {...{ ref: yAxisRef }}>
        <Typography.Caption className={styles.legendY}>{content.impressions}</Typography.Caption>
        <Box className={styles.milestonesY}>
          {getYAxisMilestones(maxValue).map((milestone) => (
            <Typography.Tag key={milestone}>{format.number(milestone)}</Typography.Tag>
          ))}
        </Box>
      </Box>
      <Box className={styles.content}>
        <Typography.Headline className={styles.title}>{title}</Typography.Headline>
        <Box className={styles.bar}>
          <Bar
            width={barWidth}
            height={390}
            gridYValues={[0]}
            margin={{ right: -20, bottom: 25, left: -20 }}
            data={data}
            indexBy="deploymentId"
            keys={keys}
            colors={generateColors(keys.length)}
            padding={calculatePadding(data)}
            labelSkipWidth={16}
            labelSkipHeight={16}
            enableLabel={false}
            axisLeft={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              format: (id) => data.find((item) => item.deploymentId === id)?.label,
            }}
            tooltip={(props: BarTooltipDatum) => <Tooltip {...(props as TooltipProps)} />}
            theme={{
              tooltip: {
                container: {
                  background: 'transparent',
                  border: 0,
                  boxShadow: 'none',
                },
              },
              textColor: variables.color.secondary.gray,
            }}
            layers={['grid', 'axes', 'bars', 'markers']}
          />
        </Box>
      </Box>
    </Box>
  );
};
