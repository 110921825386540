import { MenuItem } from '@material-ui/core';

import { Select } from '@components';
import { content } from '@content';

import { ClientPickerProps } from './ClientPicker.props';

/**
 * ClientPicker component
 * @param className - class name for style overrides
 * @param value - selected businessUnit id
 * @param items - list of businessUnits in picker
 * @param onChange - action on change deployment in picker
 * @returns {JSX.Element}
 */

export const ClientPicker = ({ items, value, onChange, className = '' }: ClientPickerProps): JSX.Element => (
  <Select
    value={value}
    onChange={onChange}
    disabled={items.length === 0}
    helperText={content.businessUnit}
    uiType="tertiary"
    className={className}
  >
    {items.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ))}
  </Select>
);
