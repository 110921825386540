import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    warningMessage: {
      padding: theme.spacing(2, 2, 2, 2.3),
      backgroundColor: variables.color.primary.white,
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.16)',
      borderRadius: variables.borderRadius.sm,
      display: 'flex',
      alignItems: 'flex-start',
      maxWidth: '36.6rem',
    },

    icon: {
      fontSize: 0,
      margin: theme.spacing(0.3, 2, 0, 0),
    },

    message: {
      marginBottom: theme.spacing(0.25),
    },

    buttons: {
      marginTop: theme.spacing(2),
    },

    button: {
      cursor: 'pointer',
      border: 0,
      background: 'transparent',
      padding: 0,
      fontSize: 0,
    },
  }),
);
