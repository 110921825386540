import { memo, useState, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Box, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash.debounce';

import { content } from '@content';
import { Typography, FileInput } from '@components';
import { textTemplate, validate } from '@utils';
import { experience } from '@modules';
import { useAppDispatch } from '@store';
import { useStyles } from './UploadModuleRecommenderFile.styles';
import { FormValues, UploadModuleRecommenderFileProps, OptionType } from './UploadModuleRecommenderFile.types';

export const UploadModuleRecommenderFile = memo(
  ({ businessUnitId, onClose, onConfirm }: UploadModuleRecommenderFileProps): JSX.Element => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const [keyword, setKeyword] = useState('');
    const { handleSubmit, control, setValue } = useForm<FormValues>();
    const { items } = experience.useListData();
    const { status } = experience.useListMeta();
    const handleChangeName = debounce(setKeyword, 500);

    const onSubmit: SubmitHandler<FormValues> = (values) => {
      onConfirm(values);
    };

    useEffect(() => {
      if (!keyword) {
        return;
      }

      dispatch(
        experience.thunk.search({
          businessUnitId,
          searchTerm: keyword,
        }),
      );
    }, [businessUnitId, dispatch, keyword]);

    useEffect(
      () => () => {
        dispatch(experience.actions.resetListData());
      },
      [dispatch],
    );

    return (
      <Box className={styles.uploadModuleRecommenderFile}>
        <Typography.Title className={styles.title}>{content.uploadExternalModuleGroupMrf}</Typography.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography.Tag className={styles.fieldLabel}>{content.uploadExternalMrf}</Typography.Tag>
          <Box className={styles.fileRow}>
            <Controller
              name="file"
              control={control}
              rules={{
                required: textTemplate(content.pleaseAdd, { field: content.externalModuleRecommenderFileTitle }),
                validate: (val) =>
                  validate.isCsvFile(val.type) || validate.isArchiveFile(val.type) ? true : content.invalidFileFormat,
              }}
              render={({ field, fieldState }) => (
                <>
                  <Box className={styles.fileField}>
                    <Typography.Body noWrap>{field.value ? field.value?.name : content.uploadNewFile}</Typography.Body>
                    <FileInput
                      name={field.name}
                      label={content.upload}
                      onChange={(e) => field.onChange(e.target.files?.[0] ?? ({} as any))}
                    />
                  </Box>
                  <Typography.Caption className={styles.fileErrorMsg}>{fieldState.error?.message}</Typography.Caption>
                </>
              )}
            />
          </Box>
          <Typography.Tag className={styles.fieldLabel}>{content.moduleGroup}</Typography.Tag>
          <Controller
            name="name"
            control={control}
            rules={{
              required: textTemplate(content.pleaseEnter, { field: content.moduleGroupName }),
            }}
            render={({ fieldState }) => (
              <Autocomplete<OptionType, false, false, true>
                fullWidth
                onChange={(e, newValue) => {
                  if (typeof newValue === 'string') {
                    setValue('name', newValue, { shouldValidate: true });
                  } else if (newValue && newValue.inputValue) {
                    setValue('name', newValue.inputValue, { shouldValidate: true });
                  } else if (newValue?.name && newValue.id) {
                    setValue('name', newValue.name, { shouldValidate: true });
                    setValue('id', newValue.id);
                  } else if (newValue === null) {
                    setValue('name', '');
                    setValue('id', undefined);
                  }
                }}
                getOptionLabel={(option) => (option.inputValue ? option.inputValue : option.name)}
                options={items}
                loading={status === 'loading'}
                freeSolo
                clearOnBlur
                filterOptions={(options, { inputValue }): OptionType[] => {
                  if (status === 'loading') {
                    return [];
                  }
                  const allOptions = [...options];

                  if (inputValue !== '') {
                    allOptions.push({
                      inputValue,
                      name: `${content.create} "${inputValue}"`,
                    });
                  }

                  return allOptions;
                }}
                renderOption={(option) => <Typography.Body>{option.name}</Typography.Body>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    onChange={(e) => handleChangeName(e.target.value)}
                  />
                )}
              />
            )}
          />
          <Box className={styles.footer}>
            <Button type="submit" variant="contained" color="primary" className={styles.confirmBtn}>
              {content.save}
            </Button>
            <Button onClick={onClose} variant="outlined" color="primary">
              {content.close}
            </Button>
          </Box>
        </form>
      </Box>
    );
  },
);

UploadModuleRecommenderFile.displayName = 'UploadModuleRecommenderFile';
