import { SVGProps } from 'react';

export const Experience = ({ width = 16, height = 16, fill = '#494949' }: SVGProps<SVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5008 4.50024L8.00059 2L5.50035 4.50024L8.00059 7.00049L10.5008 4.50024ZM14.0008 8.0007L11.5001 5.5L8.9994 8.0007L11.5001 10.5014L14.0008 8.0007ZM4.49997 5.50024L7.00043 8.0007L4.49997 10.5012L1.99951 8.0007L4.49997 5.50024ZM10.5006 11.4995L8.00059 8.99951L5.50062 11.4995L8.00059 13.9995L10.5006 11.4995Z"
      fill={fill}
    />
  </svg>
);
