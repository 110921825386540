import { makeStyles, createStyles } from '@material-ui/core/styles';

import { theme } from '@styles';

/**
 * Dashboard component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    benchmarkingRow: {
      marginBottom: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
    },
    dashboard: {
      overflow: 'auto',
      padding: '24px 64px 0',
      margin: '-24px -64px',
      height: '100%'
    }
  }),
);
