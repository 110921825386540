import { global, SignalOption, ApiError, BasePaginatedData } from '@modules';
import { createAsyncThunk } from '@store';
import { network, textTemplate } from '@utils';
import { content } from '@content';
import { CompositeSignalPayload } from '@modules';

import {
  SignalLibraryFileUploadArg,
  SignalLibrarySignalsImportArg,
  SignalLibrarySearchArg,
  SignalLibrarySearchOptionsArg,
  SignalsImportApiError,
  SignalCreatePayload,
  SignalUpdatePayload,
} from './SignalLibrary.types';
import { SignalsSearchPayload, SignalLibraryFields, SignalsSearchItem } from './SignalLibrary.schema';

export const fileUpload = createAsyncThunk(
  'signalLibrary/fileUpload',
  async ({ businessUnit, payload }: SignalLibraryFileUploadArg, { dispatch, rejectWithValue }) => {
    try {
      dispatch(global.actions.isLoading(true));

      const response = await network.post<SignalLibraryFields>(
        {
          key: 'api_rules_engine_signals_upload_by_business_unit',
          params: { businessUnit: businessUnit },
        },
        { body: payload },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.uploadSuccessfullyCompleted, { value: content.attributeFile }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return response;
    } catch (exception) {
      const error = exception as ApiError;
      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const signalsImport = createAsyncThunk(
  'signalLibrary/signalsImport',
  async ({ businessUnit, payload }: SignalLibrarySignalsImportArg, { dispatch, rejectWithValue }) => {
    try {
      dispatch(global.actions.isLoading(true));

      await network.post<void>(
        { key: 'api_rules_engine_signals_import_by_business_unit', params: { businessUnit: businessUnit } },
        { body: JSON.stringify(payload) },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: content.successfullyCompleted,
      //     options: { variant: 'success' },
      //   }),
      // );

      return undefined;
    } catch (exception) {
      const error = exception as SignalsImportApiError;

      if (error.errorType === 'signal_import' && error.errors?.signal_import) {
        Object.entries(error.errors).forEach(([key, errors]) => {
          if (key !== 'signal_import') {
            errors.forEach((errorMsg) => {
              dispatch(
                global.actions.enqueueNotification({
                  message: key ? `${key}: ${errorMsg}` : errorMsg,
                  options: { variant: 'error' },
                }),
              );
            });
          }
        });
      } else {
        dispatch(global.actions.enqueueError(error));
      }

      return rejectWithValue(error);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const search = createAsyncThunk(
  'signalLibrary/search',
  ({ businessUnit, ...rest }: SignalLibrarySearchArg, { rejectWithValue, signal }) => {
    try {
      return network.get<SignalsSearchPayload>(
        {
          key: 'api_rules_engine_signals_search_by_business_unit',
          params: { businessUnit: businessUnit, ...rest },
        },
        { signal },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const searchOptions = createAsyncThunk(
  'signalLibrary/searchOptions',
  (params: SignalLibrarySearchOptionsArg, { rejectWithValue }) => {
    try {
      return network.get<BasePaginatedData<SignalOption>>({ key: 'api_rules_engine_signals_options_search', params });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const createComposite = createAsyncThunk<SignalsSearchItem | void, CompositeSignalPayload>(
  'signal/create-composite',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp = await network.post<SignalsSearchItem>(
        { key: 'api_rules_engine_signal_composite_create' },
        {
          body: JSON.stringify(data),
        },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.createdSuccessfully, { value: content.attribute }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return resp;
    } catch (e) {
      return rejectWithValue(e as ApiError);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const create = createAsyncThunk<SignalsSearchItem | void, SignalCreatePayload>(
  'signal/create',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp = await network.post<SignalsSearchItem>(
        { key: 'api_rules_engine_signal_create' },
        {
          body: JSON.stringify(data),
        },
      );

      return resp;
    } catch (e) {
      return rejectWithValue(e as ApiError);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const update = createAsyncThunk<SignalsSearchItem | void, SignalUpdatePayload>(
  'signal/update',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp = await network.put<SignalsSearchItem>(
        { key: 'api_rules_engine_signal_update', params: { signal: data.id } },
        {
          body: JSON.stringify(data),
        },
      );

      return resp;
    } catch (e) {
      return rejectWithValue(e as ApiError);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const updateComposite = createAsyncThunk<SignalsSearchItem | void, CompositeSignalPayload>(
  'signal/update-composite',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const resp = await network.put<SignalsSearchItem>(
        { key: 'api_rules_engine_signal_composite_update', params: { signal: data.id } },
        {
          body: JSON.stringify(data),
        },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.updatedSuccessfully, { value: content.attribute }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return resp;
    } catch (e) {
      return rejectWithValue(e as ApiError);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);
