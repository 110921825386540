import { Box, ButtonBase } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';

import { Label, Typography, Icon } from '@components';

import { MindsetItemProps } from './MindsetItem.props';
import { useStyles } from './MindsetItem.styles';
import { useCallback } from 'react';

/**
 * MindsetItem - single item in Mindset Prioritization list
 * @returns {JSX.Element}
 */
export const MindsetItem = ({ index, entity, disabled = false, selected = false, onClick }: MindsetItemProps) => {
  const styles = useStyles();

  const handleClick = useCallback(() => onClick(entity.id), [onClick, entity.id]);

  return (
    <Draggable draggableId={`mindset-${entity.id}`} index={index} isDragDisabled={disabled}>
      {(provided, snapshot) => (
        <Box
          className={styles.mindsetItem}
          data-dragging={snapshot.isDragging}
          {...{ ref: provided.innerRef }}
          {...provided.draggableProps}
        >
          <Box className={styles.position}>{index + 1}</Box>
          <ButtonBase classes={{ root: styles.item }} className={selected ? styles.selected : ''} onClick={handleClick}>
            <Box className={styles.dragIcon} {...provided.dragHandleProps}>
              <Icon.DraggableVerticalLines fill="" />
            </Box>
            <Box className={styles.eyeIcon}>
              <Icon.Eye stroke="" />
            </Box>
          </ButtonBase>
        </Box>
      )}
    </Draggable>
  );
};
