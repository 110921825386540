import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * DVAttribute component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    DVAttribute: {
      maxWidth: '245px',
    },
    addButton: {
      marginBottom: '10px',
      color: '#494949',
      border: '1px solid #e8ecf0',
      padding: '0.8rem 1.2rem',
      position: 'relative',
      fontSize: '1.3rem',
      fontWeight: 600,
      borderRadius: '0.6rem',
      background: 'none',
      cursor: 'pointer',
      '& svg': {
        width: '10px',
        marginLeft: '4px',
      },

      '&.active svg': {
        transform: 'rotate(180deg )',
      },

      '& svg path': {
        stroke: '#494949',
      },
    },
    selectedAttribute: {
      textTransform: 'uppercase',
      color: variables.color.primary.darkestGray,
      textAlign: 'left',
      paddingLeft: '3px',
      fontSize: '12px',
    },
    menuList: {
      padding: '0 10px',
      marginLeft: '-10px',
      boxShadow: '1px 1px 7px #dadee2',
    },
    search: {
      width: '100%',
      marginBottom: '10px',
    },
    signalsWrapper: {
      display: 'flex',
      gap: '5px',
    },
    signalItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      padding: '5px 20px 5px 10px',
      border: '1px solid #dadee2',
      borderRadius: '7px',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: '4px',
      right: '3px',
      padding: 0,
      width: theme.spacing(2),
      height: theme.spacing(2),
      '& svg': {
        stroke: variables.color.primary.darkestGray,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          stroke: variables.color.primary.lightPurple,
        },
      },
    },
  }),
);
