import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getDisclosure, getNullSubscriber } from './Audit.thunk';
import { initialState } from './Audit.state';
import { AuditSchema } from './Audit.schema';

export const reducers = {
  resetDisclosure: (state: AuditSchema): void => {
    state.disclosure = initialState.disclosure;
  },

  resetNullSubscriber: (state: AuditSchema): void => {
    state.nullSubscriber = initialState.nullSubscriber;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<AuditSchema>): void => {
  builder.addCase(getDisclosure.pending, ({ disclosure }, action) => {
    if (disclosure.status !== 'loading') {
      disclosure.requestId = action.meta.requestId;
      disclosure.status = 'loading';
      disclosure.error = initialState.disclosure.error;
    }
  });

  builder.addCase(getDisclosure.fulfilled, ({ disclosure }, action) => {
    if (disclosure.status === 'loading' && disclosure.requestId === action.meta.requestId) {
      disclosure.requestId = '';
      disclosure.status = 'success';
      if (action.payload) {
        disclosure.data = action.payload;
      }
    }
  });

  builder.addCase(getDisclosure.rejected, ({ disclosure }, action) => {
    if (disclosure.status === 'loading' && disclosure.requestId === action.meta.requestId) {
      disclosure.requestId = '';
      disclosure.status = 'error';
      disclosure.error = {
        message: action.payload?.message || action.error.message,
      };
      disclosure.data = initialState.disclosure.data;
    }
  });

  builder.addCase(getNullSubscriber.pending, ({ nullSubscriber }, action) => {
    if (nullSubscriber.status !== 'loading') {
      nullSubscriber.requestId = action.meta.requestId;
      nullSubscriber.status = 'loading';
      nullSubscriber.error = initialState.nullSubscriber.error;
    }
  });

  builder.addCase(getNullSubscriber.fulfilled, ({ nullSubscriber }, action) => {
    if (nullSubscriber.status === 'loading' && nullSubscriber.requestId === action.meta.requestId) {
      nullSubscriber.requestId = '';
      nullSubscriber.status = 'success';
      if (action.payload) {
        nullSubscriber.data = action.payload;
      }
    }
  });

  builder.addCase(getNullSubscriber.rejected, ({ nullSubscriber }, action) => {
    if (nullSubscriber.status === 'loading' && nullSubscriber.requestId === action.meta.requestId) {
      nullSubscriber.requestId = '';
      nullSubscriber.status = 'error';
      nullSubscriber.error = {
        message: action.payload?.message || action.error.message,
      };
      nullSubscriber.data = initialState.nullSubscriber.data;
    }
  });
};
