import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    tickIcon: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2rem',
      height: '2rem',
      borderRadius: variables.borderRadius.half,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.green}`,
      background: 'rgba(11, 212, 184, 0.1)',
      fill: variables.color.primary.green,
    },
  }),
);
