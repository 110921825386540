import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * GridOutline component
 * @returns {JSX.Element}
 */

export const GridOutline = ({
  width = 16,
  height = 16,
  stroke = variables.color.primary.mediumGray,
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 6.25V2.25H6.25V6.25H2.25Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 6.25V2.25H13.75V6.25H9.75Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 13.75V9.75H6.25V13.75H2.25Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 13.75V9.75H13.75V13.75H9.75Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
