import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const enterpriseIconWidth = '2.4rem';
const enterpriseArrowWidth = '1.6rem';

/**
 * EnterpriseMenu component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    enterprise: {
      background: variables.color.primary.darkPurple,
      borderRadius: variables.borderRadius.sm,
      boxShadow: '0 0.1rem 0.1rem rgba(0, 0, 0, 0.08)',
      padding: theme.spacing(0.875, 1, 0.875, 1),
      marginBottom: theme.spacing(2.875),
      border: `${variables.borderWidth.sm} solid transparent`,
      transition: 'padding 0.3s ease',

      '& > *': {
        overflow: 'hidden',
        transition: 'width 0.3s ease, margin-left 0.3s ease',
      },

      '&$expanded': {
        boxShadow: '0 0.1rem 0 rgba(0, 0, 0, 0.03)',
        padding: theme.spacing(0.875, 1.5, 0.875, 1),

        '& $enterpriseText': {
          width: `calc(100% - ${enterpriseIconWidth} - ${enterpriseArrowWidth} - ${theme.spacing(1)})`,
          marginLeft: theme.spacing(1),
        },

        '& $enterpriseArrow': {
          width: enterpriseArrowWidth,
        },
      },

      '&:hover': {
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.mainPurple}`,

        '& $enterpriseLabel': {
          color: variables.color.primary.white,
        },

        '& $enterpriseName': {
          color: variables.color.primary.white,
        },

        '& $enterpriseIcon': {
          stroke: variables.color.primary.white,
        },

        '& $enterpriseArrow': {
          stroke: variables.color.primary.white,
        },
      },
    },

    enterpriseText: {
      display: 'flex',
      width: 0,
      flex: '1 0 auto',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: 0,
    },

    enterpriseLabel: {
      fontSize: variables.font.size.xxxs,
      fontWeight: variables.font.weight.bold,
      color: 'rgba(144, 163, 200, 0.6)',
      lineHeight: variables.lineHeight.xxxs,
      textTransform: 'uppercase',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },

    enterpriseName: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.mediumPurple,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '100%',
    },

    enterpriseIcon: {
      width: enterpriseIconWidth,
      height: enterpriseIconWidth,
      stroke: variables.color.primary.mediumPurple,
    },

    enterpriseArrow: {
      display: 'inline-flex',
      width: 0,
      stroke: variables.color.primary.mediumPurple,
    },

    enterpriseMenuPaper: {
      height: '18.6rem',
      marginTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0.5),
      width: '22.4rem',
      boxShadow: 'unset',
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,

      '&.active': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 'unset',
      },
    },

    businessUnitsMenuPaper: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    menuTitle: {
      margin: theme.spacing(0, 1, 0.5),
      padding: theme.spacing(1, 0),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      borderRadius: 'unset',
      cursor: 'auto',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    menuItemTitle: {
      color: variables.color.primary.mediumGray,
      flex: '1 1 auto',
      fontSize: variables.font.size.sm,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.sm,
    },

    enterpriseMenuItem: {
      padding: theme.spacing(1),

      color: variables.color.primary.darkestGray,
      borderRadius: variables.borderRadius.sm,
      minHeight: 'auto',

      '&$selected': {
        color: variables.color.primary.white,
        background: variables.color.primary.mainPurple,

        '& $enterpriseMenuItemIcon': {
          '& svg path': {
            stroke: variables.color.primary.white,
          },
        },

        '& $businessUnitMenuItemIcon': {
          opacity: variables.opacity.full,
        },
      },

      '&$selected:hover': {
        color: variables.color.primary.white,
        background: variables.color.primary.mainPurple,
      },

      '&:hover': {
        background: variables.color.primary.lighterPurple,
        color: variables.color.primary.mainPurple,
      },
    },

    enterpriseMenuItemText: {
      flex: '1 1 auto',
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
    },

    enterpriseMenuItemIcon: {
      display: 'flex',
      alignItems: 'center',
    },

    businessUnitMenuItemIcon: {
      display: 'flex',
      alignItems: 'center',
      opacity: variables.opacity.none,
    },

    expanded: {},
    selected: {},
  }),
);
