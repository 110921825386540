import { SVGProps } from 'react';

import { variables } from '@styles';

export const DocumentOutline = ({
  className = '',
  width = 16,
  height = 16,
  fill = variables.color.primary.darkestGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.908936 14.5908C1.33105 15.0127 1.90332 15.25 2.5 15.25H9.5C10.0967 15.25 10.6689 15.0127 11.0911 14.5908C11.3083 14.373 11.4766 14.1162 11.5889 13.8359C11.6943 13.5723 11.75 13.2891 11.75 13V6.91016C11.7505 6.73828 11.7249 6.56738 11.675 6.40234C11.6587 6.34863 11.6396 6.2959 11.6182 6.24414C11.5681 6.12402 11.5049 6.00977 11.4299 5.9043C11.3733 5.82422 11.3098 5.74902 11.24 5.67969L6.82007 1.25977C6.49268 0.935547 6.05103 0.751953 5.59009 0.75H2.5C1.90332 0.75 1.33105 0.987305 0.908936 1.40918C0.487061 1.83105 0.25 2.40332 0.25 3V13C0.25 13.5967 0.487061 14.1689 0.908936 14.5908ZM1.96973 2.46973C2.11035 2.3291 2.30103 2.25 2.5 2.25H5.25V5.5C5.24878 5.73047 5.29297 5.95801 5.38062 6.1709C5.46802 6.38379 5.59692 6.57715 5.75952 6.74023C5.92236 6.90332 6.11597 7.03223 6.32886 7.11914C6.54175 7.20703 6.76978 7.25098 7 7.25H10.25V13C10.25 13.1992 10.1709 13.3896 10.0303 13.5303C9.88965 13.6709 9.69897 13.75 9.5 13.75H2.5C2.30103 13.75 2.11035 13.6709 1.96973 13.5303C1.8291 13.3896 1.75 13.1992 1.75 13V3C1.75 2.89648 1.77124 2.7959 1.81128 2.70312C1.84839 2.61621 1.90186 2.53711 1.96973 2.46973ZM9.1897 5.75L6.75 3.31055V5.5C6.74854 5.5332 6.75415 5.56641 6.76611 5.59766C6.77832 5.62793 6.79663 5.65625 6.82007 5.67969C6.83081 5.69043 6.84253 5.7002 6.85522 5.70898C6.87012 5.71875 6.88599 5.72754 6.90259 5.7334C6.93359 5.74609 6.9668 5.75098 7 5.75H9.1897Z"
      fill={fill}
    />
  </svg>
);
