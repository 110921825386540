import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    smartContentFileAlert: {
      position: 'relative',
      marginTop: 'auto',
    },

    statusAlert: {
      position: 'absolute',
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
      width: '35rem',
      right: 0,
      bottom: 0,
      display: 'flex',
      padding: `${variables.gap.lg} ${variables.gap.sm} ${variables.gap.lg} ${variables.gap.lg}`,
      boxShadow: '0 0 0.2rem #0597FF',
    },

    statusAlertText: {
      flex: 1,
      padding: `0 ${variables.gap.lg}`,
      fontSize: variables.font.size.lg,
      lineHeight: variables.lineHeight.md,
      letterSpacing: variables.letterSpacing.sm,
      color: variables.color.secondary.gray,
      '& mark': {
        background: 'transparent',
        color: variables.color.primary.lightPurple,
      },
    },
  }),
);
