import { createSlice } from '@reduxjs/toolkit';
import { MetaSchema } from '@modules';
import { useAppSelector } from '@store';

import * as thunk from './AttributeLibrary.thunk';
import { initialState } from './AttributeLibrary.state';
import { reducers, extraReducers } from './AttributeLibrary.reducer';
import { AttributesDataListPayload, AttributesFeedPayload, AttributesSearchPayload } from './AttributeLibrary.schema';

export * from './AttributeLibrary.schema';
export * from './AttributeLibrary.types';

const slice = createSlice({
  name: 'attributeLibrary',
  initialState,
  reducers,
  extraReducers,
});

export const attributeLibrary = {
  thunk,
  slice,
  actions: slice.actions,
  useListMeta: (): MetaSchema =>
    useAppSelector(
      ({
        attributeLibrary: {
          list: { data, ...meta },
        },
      }) => meta,
    ),
  useListData: (): AttributesSearchPayload =>
    useAppSelector(
      ({
        attributeLibrary: {
          list: { data },
        },
      }) => data,
    ),
  useFeedData: (): AttributesFeedPayload =>
    useAppSelector(
      ({
        attributeLibrary: {
          feedList: { data },
        },
      }) => data,
    ),
  useAttributeDataList: (): AttributesDataListPayload =>
    useAppSelector(
      ({
        attributeLibrary: {
          dataList: { data },
        },
      }) => data,
    ),
};
