import { useCallback, useState, memo } from 'react';
import { Box, ButtonBase, Collapse, Button } from '@material-ui/core';
import { Value } from '@material-ui/lab/useAutocomplete';
import { Draggable } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Icon, Typography, Toggle } from '@components';
import { content } from '@content';
import { format } from '@utils';
import { useAppDispatch } from '@store';
import { global, Mindset as MindsetType, Experience, experience } from '@modules';

import { Condition, ConditionView } from './Condition';

import { MindsetMetCondition } from './MindsetMetCondition';
import { useStyles } from './Mindset.styles';
import { MindsetProps } from './Mindset.props';

export const Mindset = memo(
  ({ className = '', index, mindset, journeyIsEdit, isOnlyMindset, onDelete }: MindsetProps): JSX.Element => {
    const styles = useStyles();
    const { trigger, setValue, getValues, setError, clearErrors, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray<Experience, 'mindsets.0.conditions', 'conditionId'>({
      name: `mindsets.${index}.conditions` as 'mindsets.0.conditions',
      keyName: 'conditionId',
    });
    const dispatch = useAppDispatch();
    const isEditMode = watch(`mindsets.${index}.isEdit`);
    const [collapsed, setCollapsed] = useState(() => !mindset.isEdit);
    const watchConditionsArray = watch(`mindsets.${index}.conditions`);

    const conditions = fields.map((field, i) => ({
      ...field,
      ...watchConditionsArray[i],
    }));

    const hasMindsetIsEdit = (() => getValues('mindsets').some((i: MindsetType) => i.isEdit))();

    function validate() {
      const requiredFields = conditions.reduce(
        (acc, val, idx) => [
          ...acc,
          `mindsets.${index}.conditions.${idx}.signal`,
          `mindsets.${index}.conditions.${idx}.operator`,
          `mindsets.${index}.conditions.${idx}.value`,
        ],
        [`mindsets.${index}.cohort`, `mindsets.${index}.product`, `mindsets.${index}.metConditionCount`],
      );

      const cohortProductPair = `${getValues(`mindsets.${index}.cohort.id`)}-${getValues(
        `mindsets.${index}.product.id`,
      )}`;

      const isCohortProductPairUnique = getValues('mindsets')
        .filter((i: MindsetType) => !i.isEdit)
        .map((i: string) => i === cohortProductPair)
        .every((i: boolean) => i === false);

      if (!isCohortProductPairUnique) {
        dispatch(
          global.actions.enqueueNotification({
            message: content.combinationCohortProductMustUnique,
            options: { variant: 'error' },
          }),
        );
        setError(`mindsets.${index}.cohort`, { type: 'value' });
        setError(`mindsets.${index}.product`, { type: 'value' });
        return false;
      }
      clearErrors([`mindsets.${index}.cohort`, `mindsets.${index}.product`]);

      // if (conditions.length > 1 && getValues(`mindsets.${index}.metConditionCount`) === 1) {
      //   const conditionsRequired = conditions.map(
      //     (i, idx) => `mindsets.${index}.conditions.${idx}.required`,
      //   );

      //   if (getValues(conditionsRequired).indexOf(true) === -1) {
      //     dispatch(global.actions.enqueueNotification({
      //       message: content.oneConditionMustRequired, options: { variant: 'error' },
      //     }));
      //     return false;
      //   }
      // }

      return trigger(requiredFields);
    }

    async function toggleEditMode() {
      if (isEditMode) {
        const isValid = await validate();
        if (!isValid) {
          return;
        }
        setValue(`mindsets.${index}.isEdit`, false);
        dispatch(experience.thunk.getSubscriberCount(getValues() as Experience));
        return;
      }
      if (hasMindsetIsEdit) {
        return;
      }

      setValue(`mindsets.${index}.isEdit`, true);
    }

    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };

    const addCondition = () => {
      append(
        {
          signal: null,
          operator: '',
          value: '',
          required: false,
        },
        { shouldFocus: false },
      );
    };

    const deleteCondition = (idx: number) => () => remove(idx);

    const deleteMindset = () => onDelete(index);

    return (
      <Draggable
        draggableId={`mindset-${mindset.mindsetId}`}
        index={index}
        isDragDisabled={hasMindsetIsEdit || !journeyIsEdit}
      >
        {(provided, snapshot) => (
          <Box
            className={`${styles.mindset} ${className}`}
            data-collapsed={collapsed}
            data-isdragging={snapshot.isDragging}
            {...{ ref: provided.innerRef }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Box className={styles.header} data-collapsed={collapsed}>
              <ButtonBase className={styles.dragIcon}>
                <Icon.SixDots />
              </ButtonBase>
              <Box className={styles.settings}>
                <Typography.Caption>{content.treatment}</Typography.Caption>
              </Box>
              <Box className={styles.mindsetStats} data-collapsed={collapsed}>
                <Box className={styles.mindsetStatBox}>
                  <Typography.Caption>{content.targetCount}</Typography.Caption>
                  <Typography.Title noWrap>{format.number(mindset.targetCount) ?? 0}</Typography.Title>
                </Box>
                <Box className={styles.mindsetStatBox}>
                  <Typography.Caption>{content.audienceShare}</Typography.Caption>
                  <Typography.Title noWrap>{format.percent(mindset.audience, '0')}</Typography.Title>
                </Box>
              </Box>
              <Box className={styles.deleteBox}>
                <ButtonBase
                  onClick={toggleCollapse}
                  className={styles.collapseButton}
                  data-rotate={collapsed}
                  disabled={isEditMode}
                >
                  <Icon.ArrowDown width={18} height={18} stroke="#6CC2FF" />
                </ButtonBase>
                {!isOnlyMindset && (
                  <ButtonBase
                    data-collapsed={collapsed}
                    className={styles.deleteBtn}
                    onClick={deleteMindset}
                    disabled={!journeyIsEdit}
                  >
                    <Icon.Bin width={20} height={20} />
                  </ButtonBase>
                )}
              </Box>
            </Box>
            <Collapse in={!collapsed}>
              <Box className={styles.content}>
                <Box className={styles.mindsetTop}>
                  <Box className={styles.mindsetMatch}>
                    {conditions.length > 1 && (
                      <Box className={styles.conditionsControl}>
                        {isEditMode ? (
                          <>
                            <Typography.Headline component="span" className={styles.textBefore}>
                              {content.when}
                            </Typography.Headline>
                            <MindsetMetCondition index={index} defaultValue={mindset.metConditionCount} />
                            <Typography.Headline component="span" className={styles.textAfter}>
                              {content.followingAreMet}
                            </Typography.Headline>
                          </>
                        ) : (
                          mindset.metConditionCount && (
                            <>
                              <Typography.Headline component="span" className={styles.textBefore}>
                                {content.when}
                              </Typography.Headline>
                              <Typography.Headline className={styles.metConditionCount}>
                                {format.numberToWord(mindset.metConditionCount)}
                              </Typography.Headline>
                              <Typography.Headline component="span" className={styles.textAfter}>
                                {content.followingAreMet}
                              </Typography.Headline>
                            </>
                          )
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box className={styles.toggle}>
                    <Toggle
                      labelLeft={content.edit}
                      labelRight={content.calculate}
                      checked={!isEditMode}
                      disabled={!journeyIsEdit}
                      onChange={toggleEditMode}
                    />
                  </Box>
                </Box>
                <Box className={styles.conditionsList}>
                  {conditions.map((condition, conditionIndex) =>
                    isEditMode ? (
                      <Condition
                        className={styles.condition}
                        key={condition.conditionId}
                        index={conditionIndex}
                        mindsetIndex={index}
                        onDelete={deleteCondition(conditionIndex)}
                        isOnlyCondition={conditions.length === 1}
                        condition={condition}
                      />
                    ) : (
                      <ConditionView
                        key={condition.conditionId}
                        name={condition.signal?.name || ''}
                        type={condition.signal?.type}
                        operator={condition.operator}
                        value={condition.value}
                        required={condition.required}
                      />
                    ),
                  )}
                </Box>
                {isEditMode && (
                  <Box className={styles.footer}>
                    <Button variant="outlined" color="secondary" onClick={addCondition}>
                      {content.addCondition}
                    </Button>
                  </Box>
                )}
              </Box>
            </Collapse>
          </Box>
        )}
      </Draggable>
    );
  },
);

Mindset.displayName = 'Mindset';
