import React, { useCallback } from 'react';

import { Confirm, Typography } from '@components';
import { content } from '@content';
import { textTemplate } from '@utils';

import { DeleteImageModalProps } from './DeleteImageModal.props';
import { useStyles } from './DeleteImageModal.styles';

/**
 * DeleteImageModal component
 * @returns {JSX.Element}
 */

export const DeleteImageModal = ({ image, onClose, onConfirm }: DeleteImageModalProps): JSX.Element => {
  const styles = useStyles();

  const handleSubmit = useCallback(() => {
    //TODO: make delete image modal functionality
    onConfirm();
  }, [onConfirm]);

  return (
    <Confirm
      maxWidth="48rem"
      open
      title={content.areYouSureYouWantToDeleteThisImage}
      submitLabel={content.yesDelete}
      skipSubmitArrow
      onCancel={onClose}
      onSubmit={handleSubmit}
    >
      <Typography.Body
        className={styles.message}
        dangerouslySetInnerHTML={{
          __html: textTemplate(content.deleteImageModalMessage, { name: image?.name }),
        }}
      />
    </Confirm>
  );
};
