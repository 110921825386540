import { ReactElement, useCallback, ChangeEvent, useEffect, useState } from 'react';
import { Box, Typography as MuiTypography, Divider } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { content } from '@content';
import { Icon } from '@components';
import { generateColors } from '@components/BarStackedChart/BarStackedChart.utils';
import { DeploymentDropdown } from '@views';
import { analytics, deploymentsByProgram } from '@modules';
import { useAppDispatch } from '@store';
import { useLoader, useScrollableContainer, searchQuery } from '@utils';

import { useStyles } from './ModulePerformanceByPosition.styles';
import { ListItemPosition } from './ListItemPosition';
import { AccordionDetailsContent } from './AccordionDetailsContent';

export function ModulePerformanceByPosition({ programId }: { programId: number }): ReactElement {
  const styles = useStyles();
  const ref = useScrollableContainer(null);
  const dispatch = useAppDispatch();
  const [currentDeployment, setDeployment] = useState('');
  const [expanded, setExpanded] = useState(false);

  const [deployment] = searchQuery.useMutualParams('deployment');

  const commonCtirData = analytics.useCommonCtirByDeploymentData();
  const commonCtirMeta = analytics.useCommonCtirByDeploymentMeta();

  const deploymentsData = deploymentsByProgram.useDeploymentsByProgramData();
  const deploymentsMeta = deploymentsByProgram.useDeploymentsByProgramMeta();

  useLoader(deploymentsMeta, commonCtirMeta);

  const handleChangeDeployment = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const nextValue = event.target.value as string;
      setDeployment(nextValue);
    },
    [setDeployment],
  );

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    if (!programId) {
      return undefined;
    }

    setDeployment('');

    const promise = dispatch(
      deploymentsByProgram.thunk.getDeploymentsByProgram({
        programId,
      }),
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promise.then((result: any) => {
      const { payload: deployments } = result;
      if (deployment && deployments.some((item: { id: number }) => item.id === deployment)) {
        setDeployment(String(deployment));
      } else if (result.payload[0]) {
        setDeployment(deployments[0].id);
      }
    });
    return () => {
      dispatch(deploymentsByProgram.actions.resetDeploymentsByProgram());
      promise.abort();
    };
  }, [dispatch, programId, deployment]);

  useEffect(() => {
    if (!currentDeployment) {
      return undefined;
    }

    const promise = dispatch(
      analytics.thunk.getCommonCtirByDeployment({
        deployment: currentDeployment,
      }),
    );
    return () => {
      dispatch(analytics.actions.resetCommonCtirByDeployment());
      promise.abort();
    };
  }, [dispatch, currentDeployment]);

  const colors = generateColors(commonCtirData.length);

  return (
    <Box {...{ ref }} className={styles.modulePerformanceByPosition}>
      <Box>
        <Accordion className={styles.accordion} expanded={expanded} onChange={handleChange}>
          <Box>
            <Box className={styles.topContainer}>
              <AccordionSummary className={styles.accordionSummaryContainer}>
                <Box className={styles.accordionSummaryContent}>
                  {expanded ? (
                    <Icon.ArrowUp className={styles.arrowDownIcon} />
                  ) : (
                    <Icon.ArrowDown className={styles.arrowDownIcon} />
                  )}
                  <MuiTypography className={styles.title}>{content.modulePerformanceByPosition}</MuiTypography>
                </Box>
              </AccordionSummary>
              <Box>
                <DeploymentDropdown
                  deployments={deploymentsData}
                  currentDeployment={currentDeployment}
                  onChange={handleChangeDeployment}
                  status={deploymentsMeta.status}
                />
              </Box>
            </Box>
            {!expanded ? (
              <Box className={styles.commonCtirContainer}>
                {commonCtirData.map((item, index) => (
                  <div key={item.label}>
                    <ListItemPosition position={item.label} value={item.value} color={colors[index]} index={index} />
                    <Divider />
                  </div>
                ))}
              </Box>
            ) : null}
          </Box>
          <AccordionDetails style={{ display: 'block' }}>
            <AccordionDetailsContent currentDeployment={currentDeployment} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
