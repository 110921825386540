import { content } from '@content';

/**
 * Rule page constants
 */

export const FieldsLabel: { [key: string]: string } = {
  signal: content.attribute,
  operator: content.argument,
  value: content.value,
};
