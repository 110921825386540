import { ChangeEvent, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, TextField as MuiTextfield } from '@material-ui/core';

import { Typography } from '@components';
import { content } from '@content';

import { FormValues } from '../Disclosure.types';

import { DisclosureInfoProps } from './DisclosureInfo.props';
import { useStyles } from './DisclosureInfo.styles';

/**
 * DisclosureInfo component
 * @returns {JSX.Element}
 */

export const DisclosureInfo = ({ error, isEditMode, disclosureInfo, onEditMode }: DisclosureInfoProps): JSX.Element => {
  const styles = useStyles();
  const { control, setValue } = useFormContext<FormValues>();

  const handleDisclosureInfoChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue('disclosureInfo', event.target.value, { shouldValidate: true });
    },
    [setValue],
  );

  return (
    <Box className={styles.disclosureInfo}>
      {isEditMode ? (
        <Controller
          control={control}
          name="disclosureInfo"
          render={({ field: { ref, ...rest }, fieldState }) => (
            <MuiTextfield
              {...rest}
              data-has-error={error}
              className={styles.disclosureInfoInput}
              inputRef={ref}
              autoFocus
              fullWidth
              multiline
              placeholder={content.enterText}
              helperText={fieldState.error?.message}
              onChange={handleDisclosureInfoChange}
              onBlur={onEditMode}
            />
          )}
        />
      ) : (
        <Typography.Caption className={styles.caption} onDoubleClick={onEditMode}>
          {disclosureInfo}
        </Typography.Caption>
      )}
    </Box>
  );
};
