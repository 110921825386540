import { useRef, useLayoutEffect, useCallback } from 'react';
import { CodeJar } from 'codejar';
import PrismJS from 'prismjs';

import 'prismjs/themes/prism.css';
import { HtmlEditorProps } from './HtmlEditor.props';
import { useStyles } from './HtmlEditor.styles';

export const HtmlEditor = ({
  className = '',
  value,
  readonly = false,
  style,
  onUpdate,
  language = 'html',
}: HtmlEditorProps): JSX.Element => {
  const styles = useStyles();

  const ref = useRef<HTMLDivElement>(null);
  const jar = useRef<CodeJar | null>(null);

  const handleUpdate = useCallback(
    (code: string) => {
      onUpdate(code.trim());
    },
    [onUpdate],
  );

  useLayoutEffect(() => {
    if (ref.current && jar.current) {
      const pos = window.document.activeElement === ref.current ? jar.current.save() : null;

      jar.current.updateCode(value);

      if (pos) {
        jar.current.restore(pos);
      }
    }
  }, [value]);

  useLayoutEffect(() => {
    if (ref.current && ref.current.isContentEditable !== !readonly) {
      ref.current.contentEditable = `${!readonly}`;
    }
  }, [readonly]);

  useLayoutEffect(() => {
    if (ref.current) {
      jar.current = CodeJar(
        ref.current,
        (editor: HTMLElement) => {
          const text = editor.textContent;

          if (text) {
            editor.innerHTML = PrismJS.highlight(
              // eslint-disable-line no-param-reassign
              text,
              PrismJS.languages[language],
              language,
            );
          }
        },
        { tab: ' '.repeat(2) },
      );
      jar.current.updateCode(value);

      if (readonly) {
        ref.current.contentEditable = 'false';
      } else {
        jar.current.onUpdate(handleUpdate);
      }
    }

    return () => jar.current?.destroy();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div ref={ref} style={style} className={`${styles.htmlEditor} ${className} language-${language}`} />;
};
