import { variables } from '@styles';
import { SVGProps } from 'react';

/**
 * ImageOutline component
 * @returns {JSX.Element}
 */

export const ImageOutline = ({
  className = '',
  width = 25,
  height = 25,
  fill = variables.color.primary.mediumGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 8.5C14.25 7.25736 15.2574 6.25 16.5 6.25C17.7426 6.25 18.75 7.25736 18.75 8.5C18.75 9.74264 17.7426 10.75 16.5 10.75C15.2574 10.75 14.25 9.74264 14.25 8.5ZM16.5 7.75C16.0858 7.75 15.75 8.08579 15.75 8.5C15.75 8.91421 16.0858 9.25 16.5 9.25C16.9142 9.25 17.25 8.91421 17.25 8.5C17.25 8.08579 16.9142 7.75 16.5 7.75Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.75 18.0565V6.31818C22.75 4.66367 21.4716 3.25 19.8077 3.25H5.19231C3.52842 3.25 2.25 4.66367 2.25 6.31818V18.6818C2.25 20.3363 3.52842 21.75 5.19231 21.75H19.8077C21.4716 21.75 22.75 20.3363 22.75 18.6818V18.0688C22.75 18.0647 22.75 18.0606 22.75 18.0565ZM5.19231 4.75C4.43464 4.75 3.75 5.4121 3.75 6.31818V15.6519L8.04859 11.8108C8.47003 11.4348 9.01918 11.2342 9.58386 11.251C10.1483 11.2678 10.6844 11.5006 11.0828 11.9006L15.0623 15.8926L16.1396 14.81C16.5291 14.4178 17.0514 14.1853 17.6036 14.1596C18.156 14.1338 18.6975 14.3166 19.1217 14.6712L19.1225 14.6719L21.25 16.455V6.31818C21.25 5.4121 20.5654 4.75 19.8077 4.75H5.19231ZM21.25 18.4122L18.1598 15.8221C18.0233 15.7082 17.8496 15.6497 17.6735 15.658C17.4972 15.6662 17.3297 15.7403 17.204 15.8669L12.8427 20.25H19.8077C20.5654 20.25 21.25 19.5879 21.25 18.6818V18.4122ZM10.7267 20.25L14.0043 16.956L10.0204 12.9596C9.89187 12.8304 9.71938 12.7557 9.53921 12.7503C9.35909 12.7449 9.18312 12.8089 9.04732 12.93L3.75 17.6635V18.6818C3.75 19.5879 4.43464 20.25 5.19231 20.25H10.7267Z"
      fill={fill}
    />
  </svg>
);
