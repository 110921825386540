import { global, Counts, ApiError } from '@modules';
import { createAsyncThunk } from '@store';
import { network } from '@utils';
import { CompositeSignalPayload, CompositeSignal } from './Signal.types';

export const getSubscriberCount = createAsyncThunk<Counts | void, CompositeSignalPayload>(
  'signal/getSubscriberCount',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(global.actions.isLoading(true));
    try {
      const promise = network.post<Counts>(
        {
          key: 'api_rules_engine_subscriber_match_count_by_business_unit_signal_post',
          params: { businessUnit: data.businessUnit?.id },
        },
        {
          body: JSON.stringify(data),
        },
      );
      const resp = await Promise.resolve(promise).then((val) => val);

      return resp;
    } catch (e) {
      return rejectWithValue(e as ApiError);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const getSubscriberCountBySignal = createAsyncThunk<Counts | void, { businessUnit: number; signal: number }>(
  'signal/getSubscriberCountBySignal',
  async ({ businessUnit, signal }, { rejectWithValue, dispatch }) => {
    dispatch(global.actions.isLoading(true));
    try {
      const promise = network.get<Counts>({
        key: 'api_rules_engine_subscriber_match_count_by_business_unit_signal',
        params: { businessUnit: businessUnit, signal },
      });
      const resp = await Promise.resolve(promise).then((val) => val);

      return resp;
    } catch (e) {
      return rejectWithValue(e as ApiError);
    } finally {
      dispatch(global.actions.isLoading(false));
    }
  },
);

export const getItem = createAsyncThunk<CompositeSignal | void, { businessUnit: number; signal: number }>(
  'signal/getItem',
  async ({ businessUnit, signal }, { rejectWithValue, dispatch }) => {
    try {
      const resp = await network.get<CompositeSignal>({ key: 'api_rules_engine_signal_item', params: { signal } });

      if (resp) {
        await dispatch(getSubscriberCountBySignal({ businessUnit, signal }));
      }

      return resp;
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    }
  },
);
