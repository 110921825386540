import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const thumbMinSize = '2.1rem';
const thumbLabeledMinSize = '3rem';
const switchPadding = '0.1rem';
const switchBorderWidth = '0.1rem';
const switchHeight = `(${switchPadding} + ${switchBorderWidth}) * 2 + ${thumbMinSize}`;

export const useStyles = makeStyles(() =>
  createStyles({
    switch: {
      position: 'relative',
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightPurple}`,
      borderRadius: `calc((${switchHeight}) / 2)`,
      height: `calc(${switchHeight})`,
      padding: 0,
      outline: 'none',
      textTransform: 'initial',
      backgroundColor: variables.color.primary.white,

      '&[data-labeled="false"]': {
        minWidth: `calc((${thumbMinSize} + ${switchPadding} + ${switchBorderWidth}) * 2)`,
      },

      '&[data-labeled="true"]': {
        minWidth: `calc((${thumbLabeledMinSize} + ${switchPadding} + ${switchBorderWidth}) * 2)`,
      },

      '&.Mui-disabled': {
        opacity: variables.opacity.medium,
      },

      '&:hover': {
        borderColor: variables.color.secondary.blue,
        backgroundColor: 'transparent',
        '& [data-on="true"]': {
          background: variables.color.secondary.blue,
        },
        '& [data-on="false"]': {
          background: variables.color.secondary.blue,
        },
      },
    },

    content: {
      display: 'flex',
      alignItems: 'center',
      padding: switchPadding,
      width: '100%',
    },

    thumb: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 0.3rem',
      height: thumbMinSize,
      borderRadius: `calc(${thumbMinSize} / 2)`,
      transition: 'transform 0.4s',
      zIndex: 0,

      '&[data-labeled="false"]': {
        minWidth: thumbMinSize,
      },

      '&[data-labeled="true"]': {
        minWidth: '3rem',
      },

      '&[data-on="true"]': {
        background: variables.color.primary.lightPurple,
        transform: 'translateX(100%)',
      },

      '&[data-on="false"]': {
        background: variables.color.primary.lightPurple,
      },
    },

    backLabel: {
      position: 'absolute',
      zIndex: 0,
      minWidth: thumbLabeledMinSize,
      textAlign: 'center',
      '&.MuiTypography-root': {
        color: variables.color.tertiary.gray,
      },

      '&[data-type="on"]': {
        right: switchPadding,
      },
    },

    thumbLabel: {
      '&&': {
        fontWeight: variables.font.weight.regular,
        color: variables.color.primary.white,
      },
    },
  }),
);
