import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useTooltipStyle = makeStyles(() =>
  createStyles({
    tooltip: {
      width: '15rem',
      padding: variables.gap.sm,
      position: 'relative',
      boxShadow: `0.1rem 0.1rem 0.7rem ${variables.color.tertiary.gray}`,
      background: variables.color.tertiary.white,
      borderRadius: variables.borderRadius.sm,
      zIndex: 1,
      '&::after': {
        content: "''",
        position: 'absolute',
        width: '1.5rem',
        height: '1.5rem',
        boxShadow: `0.1rem 0.1rem 0.7rem ${variables.color.tertiary.gray}`,
        bottom: '-0.7rem',
        left: 'calc(50% - 0.8rem)',
        background: variables.color.tertiary.white,
        transform: 'rotate(45deg)',
        zIndex: -2,
      },
      '&::before': {
        content: "''",
        width: '3rem',
        height: '1.5rem',
        position: 'absolute',
        background: variables.color.tertiary.white,
        left: 'calc(50% - 1.5rem)',
        bottom: 0,
        zIndex: -1,
      },
    },
  }),
);

export const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.regular,
      lineHeight: variables.lineHeight.sm,
      letterSpacing: variables.letterSpacing.sm,
      color: variables.color.primary.gray,
      marginLeft: 'calc(20% + 29px)',
    },
    ctirTitle: {
      flexGrow: 1,
      marginLeft: 'calc(20% + 29px)',
      marginBottom: 5,
    },
    totalCtirContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    totalCtirValue: {
      fontSize: '1.8rem',
      fontWeight: variables.font.weight.bold,
      lineHeight: '3.1rem',
      letterSpacing: variables.letterSpacing.sm,
      color: variables.color.primary.gray,
    },
    gridItem: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    gridItemBarChart: {
      display: 'flex',
    },
    gridItemTypography: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      width: '20%',
      justifyContent: 'flex-end',
      marginRight: 10,
      textAlign: 'end',
    },
    gridItemTypographyCaption: {
      color: variables.color.primary.gray,
    },
    divider: {
      flexGrow: 1,
      marginLeft: 'calc(20% + 29px)',
      marginTop: 12,
      marginBottom: 10,
    },
    innerScrollContainer: {
      height: '35rem',
      overflowY: 'auto',
      width: '53.5rem',
      paddingTop: '2.8rem',
    },
  }),
);
