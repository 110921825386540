import { FC, ReactElement } from 'react';
import { Typography as MuiTypography } from '@material-ui/core';
import { Typography } from '@components';
import { format } from '@utils';

import { content } from '@content';
import { useTooltipStyle } from '@components/PieChart/PieChart.styles';
import { PieChartTooltipProps } from '@components/PieChart/PieChart.props';

export const SummaryPerformanceTooltip: FC<PieChartTooltipProps> = ({ datum: { value, data } }): ReactElement => {
  const styles = useTooltipStyle();
  return (
    <div className={styles.tooltip}>
      <Typography.Caption className={styles.caption}>{data?.position}</Typography.Caption>
      <MuiTypography className={styles.text}>{format.percent(data?.percent)}</MuiTypography>
      <MuiTypography className={styles.text}>{`${content.clicks}: ${format.number(value)}`}</MuiTypography>
    </div>
  );
};
