import { makeStyles, createStyles } from '@material-ui/core/styles';

import { theme, variables } from '@styles';

const headerHeight = '6.5rem';

const footerHeight = '5.4rem';

export const useStyles = makeStyles(() =>
  createStyles({
    programDetails: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    header: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      height: headerHeight,

      '& > $actions': {
        height: '4.1rem',
      },
    },

    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',

      '& > *': {
        height: '3rem',

        '&:not(:first-child)': {
          marginLeft: theme.spacing(1),
        },
      },
    },

    container: {
      height: `calc(100% - ${headerHeight} - ${footerHeight})`,
      overflowY: 'auto',
      padding: theme.spacing(4, 0),
      borderTop: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,

      '&[data-alerted=true] > *:not(:first-child), &[data-alerted=false] > *': {
        minWidth: '48.3rem',
        maxWidth: 'min(55%, 60rem)',
      },

      '& > *:not(:first-child)': {
        marginTop: theme.spacing(4),
      },
    },

    footer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      height: footerHeight,
    },
  }),
);
