import { ModulesSortBy, ModulesSearchType } from '@modules';

/**
 * SingleModules component constants
 * @returns {Variable}
 */

const CURRENT_SEARCH_TYPE: ModulesSearchType = 'deployment';

const CURRENT_SORT_BY: ModulesSortBy = 'name';

export const INITIAL_QUERY = {
  keyword: '',
  page: 1,
  businessUnitId: '',
  itemsPerPage: 20,
  sortBy: CURRENT_SORT_BY,
  searchType: CURRENT_SEARCH_TYPE,
};
