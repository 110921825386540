import { SVGProps } from 'react';

export const NumberOutline = ({
  width = 24,
  height = 24,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="12" cy="12" r="8.25" stroke={color} strokeWidth="1.5" />
    <path d="M11.6474 16V9.708L10.1624 10.621V8.971L11.6474 8.08H13.1764V16H11.6474Z" fill={color} />
  </svg>
);
