import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import beeActions from './Constants';
const eitherHasConfig = (config) => !config ? E.left(new Error('Config is missing')) : E.right(config);
const eitherHasSessionId = (sessionId) => !sessionId ? E.left(new Error('SessionId is missing')) : E.right(sessionId);
const eitherHasToken = (token) => !token ? E.left(new Error('Token NOT declared, call getToken or pass token on new BEE')) : E.right(token);
const eitherHasTemplate = (template) => !template ? E.left(new Error('template is missing')) : E.right(template);
export const eitherCheckJoinParams = (config, sessionId, token) => pipe(eitherHasSessionId(sessionId), E.chain(() => eitherHasConfig(config)), E.chain(() => eitherHasToken(token)));
export const eitherCheckStartParams = (config, template, token) => pipe(eitherHasConfig(config), E.chain(() => eitherHasToken(token)), E.chain(() => eitherHasTemplate(template)));
const eitherBeeInstanceExist = (instance) => !instance ? E.left(new Error('Bee is not started')) : E.right(instance);
const eitherIsValidAction = (action) => !Object.keys(beeActions).some(x => beeActions[x] === action) ? E.left(new Error(`${action} is not a correct method`)) : E.right(action);
export const eitherCanExecuteAction = (instance, action) => pipe(eitherBeeInstanceExist(instance), E.chain(() => eitherIsValidAction(action)));
