import { FC, ReactElement, SVGProps } from 'react';

export const Phone: FC<SVGProps<SVGElement>> = ({ className }): ReactElement => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M5.8135 28C3.5735 28 1.75117 26.1788 1.75 23.94V5.8135C1.75 3.57233 3.57233 1.75 5.8135 1.75H7.875C8.358 1.75 8.75 2.142 8.75 2.625C8.75 3.108 8.358 3.5 7.875 3.5H5.8135C4.53833 3.5 3.5 4.53833 3.5 5.8135V21H15.75V14.875C15.75 14.392 16.142 14 16.625 14C17.108 14 17.5 14.392 17.5 14.875V23.9377C17.5 26.1777 15.6777 28 13.4377 28H5.8135ZM3.5 23.9377C3.5 25.2128 4.53833 26.25 5.81233 26.25H13.4365C14.7117 26.25 15.75 25.2128 15.75 23.9377V22.75H3.5V23.9377Z"
      fill="#8C8F96"
    />
    <path
      d="M13.125 12.25C11.6772 12.25 10.5 11.0728 10.5 9.625V2.625C10.5 1.17717 11.6772 0 13.125 0H23.625C25.0728 0 26.25 1.17717 26.25 2.625V9.625C26.25 11.0728 25.0728 12.25 23.625 12.25H13.125ZM12.25 9.625C12.25 10.108 12.642 10.5 13.125 10.5H23.625C24.108 10.5 24.5 10.108 24.5 9.625V4.2035L19.7657 7.15983C19.3515 7.41883 18.8697 7.5565 18.375 7.5565C17.8792 7.5565 17.3985 7.41883 16.9843 7.15983L12.25 4.2035V9.625ZM17.9107 5.67467C18.0483 5.761 18.2093 5.8065 18.3738 5.8065C18.5395 5.8065 18.7005 5.761 18.8382 5.67467L24.3927 2.20617C24.2445 1.93083 23.9528 1.75 23.625 1.75H13.125C12.7972 1.75 12.5055 1.93083 12.3562 2.20617L17.9107 5.67467Z"
      fill="#8C8F96"
    />
  </svg>
);
