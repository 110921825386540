import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Experiences component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    library: {
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
      flex: '1 1 auto',
      display: 'flex',
      padding: variables.gap.md,
    },
    experiencesRight: {
      flex: '1 1',
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    nothingSelected: {
      paddingTop: variables.gap.xxs,
      marginBottom: variables.gap.xxs,
    },
  }),
);
