import { createAsyncThunk } from '@store';
import {
  global,
  ApiError,
  ModuleCreateData,
  ModuleInfoPayload,
  deployment as deploymentModule,
  ModulesDuplicateParams,
  ModuleDisclosureStation,
} from '@modules';
import { network, array, textTemplate, Routing, formatHtmlTextElements } from '@utils';
import { content } from '@content';

import { Module, SearchModulesPayload, TModuleDVStationPayload } from './Modules.schema';
import { ModulesSearchParams, ModulesCloneParams, PublishModuleSuccess, PublishModuleError } from './Modules.types';
import { MAIL_BUILDER_TEMPLATE_ROW, MODULE_HTML_TEMPLATE } from './Modules.const';

export const getPopular = createAsyncThunk('modules/getPopular', async (businessUnitId: number, thunkApi) => {
  const { signal, rejectWithValue } = thunkApi;

  try {
    return await network.get<SearchModulesPayload>(
      {
        key: 'search_modules',
        params: {
          businessUnitId,
          itemsPerPage: 4,
          page: 1,
        },
      },
      { signal },
    );
  } catch (exception) {
    return rejectWithValue(exception as ApiError);
  }
});

export const search = createAsyncThunk('modules/search', async (params: ModulesSearchParams, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi;

  try {
    return await network.get<SearchModulesPayload>({
      key: 'search_modules',
      params: {
        keyword: params.keyword,
        businessUnitId: params.businessUnitId,
        page: params.page,
        itemsPerPage: params.itemsPerPage,
        searchType: params.searchType,
        ...(params.sortBy && { sortBy: params.sortBy }),
        ...(params.onlyRecent && { onlyRecent: params.onlyRecent }),
        ...(params.product && { product: params.product }),
        ...(params.cohort && { cohort: params.cohort }),
        ...(params.journey && { journey: params.journey }),
        ...(params.startDate && { startDate: params.startDate }),
        ...(params.endDate && { endDate: params.endDate }),
        ...(params.moduleId && { moduleId: params.moduleId }),
      },
    });
  } catch (exception) {
    dispatch(global.actions.enqueueError(exception as ApiError));

    return rejectWithValue(exception as ApiError);
  }
});

export const clone = createAsyncThunk('modules/clone', async (params: ModulesCloneParams, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi;

  try {
    return await network.post<Module>({ key: 'module_clone', params });
  } catch (exception) {
    dispatch(
      global.actions.enqueueNotification({
        message: (exception as ApiError).message,
        options: { variant: 'error' },
      }),
    );

    return rejectWithValue(exception as ApiError);
  }
});

export const duplicate = createAsyncThunk('modules/duplicate', async (payload: ModulesDuplicateParams, thunkApi) => {
  const { dispatch, rejectWithValue, signal } = thunkApi;
  const { module, name } = payload;
  const body = { name };

  try {
    return await network.post<Module>(
      { key: 'duplicate_module', params: { module } },
      { signal, body: JSON.stringify(body) },
    );
  } catch (exception) {
    dispatch(
      global.actions.enqueueNotification({
        message: (exception as ApiError).message,
        options: { variant: 'error' },
      }),
    );

    return rejectWithValue(exception as ApiError);
  }
});

export const getModuleInfo = createAsyncThunk<
  Module | void,
  {
    module: string | number;
  }
>('modules/getModuleInfo', async ({ module }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'get_module_info', params: { module } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const getSubscriberMatchCount = createAsyncThunk<
  Module | void,
  { module: string | number; businessUnit: number }
>('modules/getSubscriberMatchCount', async ({ module, businessUnit }, { rejectWithValue }) => {
  try {
    return await network.get({
      key: 'api_rules_engine_subscriber_match_count_by_business_unit_module',
      params: { module, businessUnit: businessUnit },
    });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});

export const create = createAsyncThunk(
  'modules/create',
  async (payload: ModuleCreateData, { signal, dispatch, rejectWithValue }) => {
    // const newFormattedFile = await formatHtmlTextElements(payload.htmlFile!);

    try {
      const body = new FormData();

      body.append('businessUnit[id]', `${payload.businessUnitId}`);
      body.append('name', payload.name);
      // body.append('htmlFile', newFormattedFile as Blob);
      body.append('htmlFile', payload.htmlFile as Blob);
      body.append('description', payload.description || '');

      return await network.post<ModuleInfoPayload>({ key: 'create_module' }, { signal, body });
    } catch (exception) {
      dispatch(
        global.actions.enqueueNotification({
          message: (exception as ApiError).message,
          options: { variant: 'error' },
        }),
      );

      return rejectWithValue(exception as ApiError);
    }
  },
);

export const publish = createAsyncThunk<
  void,
  {
    deploymentId: number;
    selected: number[];
  }
>('modules/publish', async ({ selected, deploymentId }, { dispatch, rejectWithValue }) => {
  const allModules = selected.map((module) => ({
    method: 'Post',
    uri: Routing.generate('publish_module', { module }).substring(1),
    body: {},
  }));

  const batches = array.chunk(allModules, 200);

  try {
    dispatch(global.actions.isLoading(true));

    const resp = (
      await Promise.all(
        batches
          .map((batch) =>
            network.send<PublishModuleSuccess[] | PublishModuleError[]>(
              { key: 'bulk_action' },
              { method: 'PATCH', body: JSON.stringify({ actions: batch }) },
            ),
          )
          .map((p) =>
            Promise.resolve(p).then(
              (val) => val,
              (err) => err,
            ),
          ),
      )
    ).flat(1);

    let successResultCount = 0;
    const errorMessages = new Set<string>();

    resp.forEach((res) => {
      if (res.status === 200) {
        successResultCount += 1;
      } else {
        errorMessages.add(res?.body?.message);
      }
    });

    if (successResultCount) {
      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.successfullyPublished, { count: successResultCount }),
      //     options: { variant: 'success' },
      //   }),
      // );
    }

    if (errorMessages.size) {
      errorMessages.forEach((message) => {
        dispatch(
          global.actions.enqueueNotification({
            message,
            options: { variant: 'error' },
          }),
        );
      });
    }

    dispatch(deploymentModule.thunk.getModulesList(deploymentId));
    dispatch(deploymentModule.thunk.getGenerationStatus(deploymentId));
  } catch (e) {
    rejectWithValue(e as ApiError);
  } finally {
    dispatch(global.actions.isLoading(false));
  }
});

export const refreshArticles = createAsyncThunk(
  'modules/refreshArticles',
  async (deployment: number, { rejectWithValue }) => {
    try {
      const data = {
        moduleHtmlTemplate: MODULE_HTML_TEMPLATE,
        mailBuilderTemplateRow: MAIL_BUILDER_TEMPLATE_ROW,
      };
      return await network.put({ key: 'refresh_articles', params: { deployment } }, { body: JSON.stringify(data) });
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const resetModuleTemplateContent = createAsyncThunk(
  'modules/resetTemplateContent',
  async (module: number, { rejectWithValue }) => {
    try {
      await network.remove<Module>({ key: 'reset_module_template_content', params: { module } });

      return module;
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const renameModuleTemplate = createAsyncThunk(
  'modules/renameModuleTemplate',
  async ({ module, templateName }: { module: number; templateName: string }, { rejectWithValue }) => {
    try {
      return await network.put(
        { key: 'rename_module_template', params: { module } },
        { body: JSON.stringify({ templateName }) },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const replaceModuleDataVisualizationStation = createAsyncThunk(
  'modules/replaceModuleDataVisualizationStation',
  async ({ module, payload }: { module: number; payload: TModuleDVStationPayload }, { rejectWithValue }) => {
    try {
      return await network.put(
        { key: 'set_module_data_visualization_station', params: { module } },
        { body: JSON.stringify(payload) },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const replaceModuleDisclosureStation = createAsyncThunk(
  'modules/replaceModuleDisclosureStation',
  async (
    { module, payload }: { module: number; payload: { moduleDisclosureStation: ModuleDisclosureStation | null } },
    { rejectWithValue },
  ) => {
    try {
      return await network.put(
        { key: 'set_module_disclosure_station', params: { module } },
        { body: JSON.stringify(payload) },
      );
    } catch (exception) {
      return rejectWithValue(exception as ApiError);
    }
  },
);

export const checkModuleName = createAsyncThunk<
  any | void,
  {
    templateName: string;
    businessUnit: number;
  }
>('modules/checkModuleName', async ({ templateName, businessUnit }, { rejectWithValue }) => {
  try {
    return await network.post(
      { key: 'check_module_name_by_business_unit', params: { businessUnit: businessUnit } },
      { body: JSON.stringify({ templateName }) },
    );
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});
