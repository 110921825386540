import { ApiError } from '@modules';
import { createAsyncThunk } from '@store';
import { network } from '@utils';
import { DeploymentsByProgramDataSchema } from './DeploymentsByProgram.schema';

export const getDeploymentsByProgram = createAsyncThunk<
  DeploymentsByProgramDataSchema | void,
  {
    programId: number;
  }
>('audit/getDeploymentsByProgram', async ({ programId }, { rejectWithValue }) => {
  try {
    return await network.get({ key: 'deployment_list_by_program', params: { program: programId } });
  } catch (e) {
    return rejectWithValue(e as ApiError);
  }
});
