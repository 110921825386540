import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getSubscriberCount, getSubscriberCountBySignal, getItem } from './Signal.thunk';
import { SignalSchema } from './Signal.schema';
import { initialState } from './Signal.state';

export const reducers = {
  resetSignal: ({ item }: SignalSchema): void => {
    item.data = null;
  },
  resetCounts: ({ counts }: SignalSchema): void => {
    counts.data = initialState.counts.data;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<SignalSchema>): void => {
  builder.addCase(getSubscriberCount.pending, ({ counts }, action) => {
    if (counts.status !== 'loading') {
      counts.requestId = action.meta.requestId;
      counts.data = initialState.counts.data;
      counts.status = 'loading';
    }
  });

  builder.addCase(getSubscriberCount.fulfilled, ({ counts }, action) => {
    if (counts.status === 'loading' && counts.requestId === action.meta.requestId) {
      counts.requestId = initialState.counts.requestId;
      counts.data = action.payload || initialState.counts.data;
      counts.status = 'success';
    }
  });

  builder.addCase(getSubscriberCount.rejected, ({ counts }, action) => {
    if (counts.status === 'loading' && counts.requestId === action.meta.requestId) {
      counts.requestId = initialState.counts.requestId;
      counts.status = 'error';
      counts.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(getSubscriberCountBySignal.pending, ({ counts }, action) => {
    if (counts.status !== 'loading') {
      counts.requestId = action.meta.requestId;
      counts.data = initialState.counts.data;
      counts.status = 'loading';
    }
  });

  builder.addCase(getSubscriberCountBySignal.fulfilled, ({ counts }, action) => {
    if (counts.status === 'loading' && counts.requestId === action.meta.requestId) {
      counts.requestId = initialState.counts.requestId;
      counts.data = action.payload || initialState.counts.data;
      counts.status = 'success';
    }
  });

  builder.addCase(getSubscriberCountBySignal.rejected, ({ counts }, action) => {
    if (counts.status === 'loading' && counts.requestId === action.meta.requestId) {
      counts.requestId = initialState.counts.requestId;
      counts.status = 'error';
      counts.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });

  builder.addCase(getItem.pending, ({ item }, action) => {
    if (item.status !== 'loading') {
      item.requestId = action.meta.requestId;
      item.status = 'loading';
    }
  });

  builder.addCase(getItem.fulfilled, ({ item }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'success';
      if (action.payload) {
        item.data = action.payload;
      }
    }
  });

  builder.addCase(getItem.rejected, ({ item }, action) => {
    if (item.status === 'loading' && item.requestId === action.meta.requestId) {
      item.requestId = '';
      item.status = 'error';
      item.error = {
        message: action.payload?.message || action.error.message,
      };
    }
  });
};
