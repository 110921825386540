import { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Alert, Confirm, TextField } from '@components';
import { modules, ModulesDuplicateParams } from '@modules';
import { useAppDispatch } from '@store';
import { content } from '@content';
import { useLoader, func, useHistory } from '@utils';

import { DuplicateModuleModalProps } from './DuplicateModuleModal.props';

/**
 DuplicateModuleModal component.
 @returns {JSX.Element}
 */

export const DuplicateModuleModal = ({
  onClose,
  detailModuleId,
  alert = false,
  hasWarning = false,
}: DuplicateModuleModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const duplicatedModuleMeta = modules.useDuplicateMeta();
  useLoader(duplicatedModuleMeta);

  const defaultValues = {
    module: detailModuleId,
    name: '',
  };

  const form = useForm<ModulesDuplicateParams>({ defaultValues });

  const handleSubmit: SubmitHandler<ModulesDuplicateParams> = useCallback(
    async (payload) => {
      const result = await dispatch(modules.thunk.duplicate(payload));

      if (modules.thunk.duplicate.fulfilled.match(result)) {
        const id = result.payload?.id;
        if (id) {
          history.push('contentCenter', { id });
        } else {
          onClose();
        }
      }
    },
    [dispatch, onClose, history],
  );

  return (
    <Confirm
      open
      title={content.duplicateContentModule}
      alert={alert ? content.duplicateContentAlert : null}
      onCancel={onClose}
      onSubmit={form.handleSubmit(handleSubmit)}
      submitLabel={content.duplicate}
      skipSubmitArrow
    >
      {hasWarning && <Alert onClose={func.nop} align="left" description={content.enterModuleName} type="warning" />}
      <Controller
        name="name"
        control={form.control}
        rules={{ required: content.contentModuleName }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            disabled={duplicatedModuleMeta.status === 'loading'}
            label={content.contentModuleName}
            placeholder={content.contentModuleName}
            hint={error?.message}
            error={invalid}
          />
        )}
      />
    </Confirm>
  );
};
