import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ContentCenterFilters component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    libraryFilters: {
      padding: theme.spacing(3, 3, 1.5, 3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    tab: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.darkestGray,
      padding: theme.spacing(0.75, 2.8),
      textTransform: 'capitalize',
      border: `solid ${variables.borderWidth.sm} ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      width: '170px',

      '&[data-left-tab]': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '&[data-right-tab]': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '&.Mui-selected': {
        backgroundColor: variables.color.primary.mainPurple,
        color: variables.color.primary.white,
        borderColor: variables.color.primary.mainPurple,
      },

      '&.Mui-selected:hover': {
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), ${variables.color.primary.mainPurple}`,
      }
    },

    rightElements: {
      display: 'flex',
      alignItems: 'center',
    },

    search: {
      marginLeft: theme.spacing(1),
      width: '250px'
    },

    uploadModule: {
      marginLeft: theme.spacing(1),
    },

    menu: {
      marginLeft: theme.spacing(1),
    },
  }),
);
