import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * MindsetItem component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    mindsetItem: {
      height: '5.5rem',
      display: 'flex',
      alignItems: 'center',

      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },

      '&:last-child, &[data-dragging="true"]': {
        '& $position::after': {
          background: 'none',
        },
      },
    },

    position: {
      width: '2.4rem',
      height: '2.4rem',
      flex: '0 0 2.4rem',
      background: variables.color.primary.white,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.875),
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.half,
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.bold,
      color: variables.color.primary.darkestGray,
      position: 'relative',
      zIndex: 20,

      '&::before': {
        display: 'block',
        content: '""',
        height: 1,
        background: variables.color.primary.lightGray,
        position: 'absolute',
        left: '100%',
        width: '100%',
        zIndex: 10,
      },

      '&::after': {
        display: 'block',
        content: '""',
        background: variables.color.primary.lightGray,
        position: 'absolute',
        top: '100%',
        zIndex: 10,
        width: 1,
        height: '5.5rem',
      },
    },

    item: {
      display: 'flex',
      alignSelf: 'stretch',
      justifyContent: 'stretch',
      flexGrow: 1,
      background: variables.color.primary.white,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.lg,
      padding: theme.spacing(1, 2),
      zIndex: 20,
      maxWidth: 'calc(100% - 3.5rem)',

      '&:hover': {
        background: variables.color.primary.lighterPurple,
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.mainPurple}`,

        '& $dragIcon': {
          fill: variables.color.primary.mainPurple,
        },

        '& $product': {
          color: variables.color.primary.mainPurple,
        },

        '& $cohort': {
          color: variables.color.primary.white,
          background: variables.color.primary.mainPurple,
        },

        '& $eyeIcon': {
          stroke: variables.color.primary.mainPurple,
        },
      },
    },

    selected: {
      '&, &:hover': {
        background: variables.color.primary.mainPurple,
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.mainPurple}`,

        '& $dragIcon': {
          fill: variables.color.primary.lightPurple,
        },

        '& $product': {
          color: variables.color.primary.white,
        },

        '& $cohort': {
          color: variables.color.primary.lightPurple,
          background: 'rgba(0, 0, 0, 0.08)',
        },

        '& $eyeIcon': {
          stroke: variables.color.primary.white,
        },
      },
    },

    name: {
      margin: theme.spacing(0, 1),
      overflow: 'hidden',
      flex: 1,
      textAlign: 'left',
    },

    product: {
      marginBottom: theme.spacing(0.5),
    },

    cohort: {
      backgroundColor: variables.color.primary.backgroundLightGray,
    },

    dragIcon: {
      fill: variables.color.primary.mediumGray,
    },

    eyeIcon: {
      stroke: variables.color.primary.mediumGray,
    },
  }),
);
