import { createContext } from 'react';

import { SignalBuilderContextValue } from './SignalBuilder.types';

export const DEFAULT_CONDITIONS = [
  {
    signal: {
      id: undefined,
    },
    operator: '',
    value: '',
    required: false,
  },
];

export const SignalBuilderContext = createContext<SignalBuilderContextValue>({
  chosenAttributeId: null,
  setChosenAttributeId: () => false,
  onDoubleClick: () => false,
});

export const DEFAULT_MINDSET_INDEX = 1;
