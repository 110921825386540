export const Product: React.FC = (): React.ReactElement => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#product)">
      <path
        d="M20.125 21C21.0915 21 21.875 20.2165 21.875 19.25C21.875 18.2835 21.0915 17.5 20.125 17.5C19.1585 17.5 18.375 18.2835 18.375 19.25C18.375 20.2165 19.1585 21 20.125 21Z"
        stroke="#8C8F96"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6113 12.4827L22.127 14.1779C22.2121 14.4592 22.4009 14.6978 22.6551 14.8453C22.9094 14.9928 23.2102 15.0382 23.4967 14.9724L25.214 14.5745C25.5395 14.5012 25.8799 14.534 26.1854 14.6681C26.4909 14.8023 26.7455 15.0307 26.9118 15.32C27.0781 15.6092 27.1474 15.9441 27.1095 16.2757C27.0717 16.6072 26.9287 16.9179 26.7015 17.1622L25.4987 18.4584C25.2979 18.6742 25.1863 18.9581 25.1863 19.2529C25.1863 19.5477 25.2979 19.8315 25.4987 20.0474L26.7015 21.3435C26.9287 21.5879 27.0717 21.8986 27.1095 22.2301C27.1474 22.5616 27.0781 22.8965 26.9118 23.1858C26.7455 23.4751 26.4909 23.7035 26.1854 23.8376C25.8799 23.9718 25.5395 24.0046 25.214 23.9312L23.4967 23.5334C23.2102 23.4676 22.9094 23.513 22.6551 23.6605C22.4009 23.808 22.2121 24.0465 22.127 24.3279L21.6113 26.016C21.5155 26.3358 21.3191 26.616 21.0514 26.8153C20.7836 27.0146 20.4587 27.1222 20.125 27.1222C19.7912 27.1222 19.4664 27.0146 19.1986 26.8153C18.9308 26.616 18.7345 26.3358 18.6387 26.016L18.123 24.3209C18.0379 24.0395 17.8491 23.801 17.5949 23.6535C17.3406 23.506 17.0398 23.4606 16.7533 23.5264L15.036 23.9242C14.7105 23.9976 14.37 23.9648 14.0645 23.8306C13.759 23.6965 13.5045 23.4681 13.3382 23.1788C13.1719 22.8895 13.1026 22.5546 13.1405 22.2231C13.1783 21.8916 13.3213 21.5809 13.5485 21.3365L14.7513 20.0404C14.9521 19.8245 15.0637 19.5407 15.0637 19.2459C15.0637 18.9511 14.9521 18.6672 14.7513 18.4514L13.5485 17.1552C13.3213 16.9109 13.1783 16.6002 13.1405 16.2687C13.1026 15.9371 13.1719 15.6022 13.3382 15.313C13.5045 15.0237 13.759 14.7953 14.0645 14.6611C14.37 14.527 14.7105 14.4942 15.036 14.5675L16.7533 14.9654C17.0398 15.0312 17.3406 14.9858 17.5949 14.8383C17.8491 14.6908 18.0379 14.4522 18.123 14.1709L18.6387 12.4757C18.7352 12.1562 18.9323 11.8764 19.2005 11.6778C19.4687 11.4791 19.7938 11.3723 20.1276 11.3731C20.4614 11.3738 20.786 11.4822 21.0533 11.6821C21.3205 11.882 21.5162 12.1628 21.6113 12.4827Z"
        stroke="#8C8F96"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.625 18.375H2.625C2.16087 18.375 1.71575 18.1906 1.38756 17.8624C1.05937 17.5342 0.875 17.0891 0.875 16.625V2.625C0.875 2.16087 1.05937 1.71575 1.38756 1.38756C1.71575 1.05937 2.16087 0.875 2.625 0.875H23.625C24.0891 0.875 24.5342 1.05937 24.8624 1.38756C25.1906 1.71575 25.375 2.16087 25.375 2.625V10.5"
        stroke="#8C8F96"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9795 1.51758L15.4782 8.82558C14.8035 9.34458 13.9762 9.62598 13.125 9.62598C12.2738 9.62598 11.4465 9.34458 10.7718 8.82558L1.27051 1.51758"
        stroke="#8C8F96"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="product">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
