import { createAsyncThunk } from '@store';
import { ApiError, global } from '@modules';
import { network, textTemplate } from '@utils';
import { content } from '@content';

import { Enterprise, EnterpriseListData } from './Enterprise.schema';
import { EnterpriseUpdateArg, EnterpriseCreateArg } from './Enterprise.types';

export const getList = createAsyncThunk('enterprise/getList', (...[, thunkApi]) => {
  try {
    return network.get<EnterpriseListData>({ key: 'enterprises_list' }, { signal: thunkApi.signal });
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const getEntity = createAsyncThunk('enterprise/getEntity', (enterprise: Enterprise['id'], thunkApi) => {
  try {
    return network.get<Enterprise>(
      { key: 'get_enterprises_item', params: { enterprise } },
      { signal: thunkApi.signal },
    );
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const update = createAsyncThunk('enterprise/update', async (arg: EnterpriseUpdateArg, thunkApi) => {
  const { id: enterprise, ...payload } = arg;

  thunkApi.dispatch(global.actions.isLoading(true));

  try {
    const response = await network.put<Enterprise>(
      { key: 'update_enterprise', params: { enterprise } },
      { body: JSON.stringify(payload), signal: thunkApi.signal },
    );

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.updatedSuccessfully, { value: content.enterprise }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  } finally {
    thunkApi.dispatch(global.actions.isLoading(false));
  }
});

export const create = createAsyncThunk('enterprise/create', async (payload: EnterpriseCreateArg, thunkApi) => {
  thunkApi.dispatch(global.actions.isLoading(true));

  try {
    const response = await network.post<Enterprise>(
      { key: 'create_enterprise' },
      { body: JSON.stringify(payload), signal: thunkApi.signal },
    );

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.createdSuccessfully, { value: content.enterprise }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  } finally {
    thunkApi.dispatch(global.actions.isLoading(false));
  }
});
