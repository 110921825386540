import { CSSProperties } from 'react';

import { variables } from '@styles';

import { SignalProps } from './Signal.props';
import { SIGNAL_MARGIN_BOTTOM, SIGNAL_COMPONENT_WIDTH } from './Signal.const';

/**
 Signal component utils
 @returns {function}
 */

export function getStyle({ provided, style, isDragging }: Partial<SignalProps>): CSSProperties {
  const combined = {
    ...style,
    ...provided?.draggableProps.style,
  };

  return {
    ...combined,
    height: isDragging ? combined.height : (combined.height as number) - SIGNAL_MARGIN_BOTTOM,
    border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    width: SIGNAL_COMPONENT_WIDTH,
    marginBottom: SIGNAL_MARGIN_BOTTOM,
  };
}
