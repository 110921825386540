import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { Typography, Icon } from '@components';
import { content } from '@content';

import { useStyles } from './Tree.styles';
import { TreeItemProps, TreeIconsVariants } from './Tree.props';

export const TREE_ICONS = {
  content: <Icon.ContentOutline height={14.5} width={14.5} />,
  document: <Icon.DocumentsOutline height={14.5} width={14.5} />,
  experience: <Icon.Experience height={14.5} width={14.5} />,
};

/**
 TreeItem component.
 @returns {JSX.Element}
 */

export const TreeItem = ({ level, title, branches, children }: TreeItemProps) => {
  const hasSubtitle = useMemo(() => branches?.some((item) => item.subtitle), [branches]);

  const styles = useStyles({ level });

  return (
    <div>
      <Box className={styles.item}>
        {title && (
          <>
            <Box className={styles.row}>
              <Box className={styles.circle} />
              <Box className={styles.horizonatalLine} />
              <Typography.SmallTag>{title}</Typography.SmallTag>
            </Box>
            {!hasSubtitle && <Box className={styles.smallVerticalLine} />}
          </>
        )}
        <Box className={styles.innerContainer}>
          {branches?.map(
            (elem, index) =>
              elem.subtitle && (
                <div key={`${elem.subtitle}-${index}`}>
                  <Box className={styles.row}>
                    <Box className={styles.iconDocument}>
                      {elem.icon
                        ? TREE_ICONS[elem.icon as TreeIconsVariants]
                        : TREE_ICONS['experience' as TreeIconsVariants]}
                    </Box>
                    <Box className={styles.subtitleRow}>
                      <Typography.Title>{elem.subtitle}</Typography.Title>
                      <Typography.SmallCaption className={styles.tag}>{elem.tag}</Typography.SmallCaption>
                      {elem.contentStatus && elem.contentStatus === 'none' && (
                        <>
                          <Typography.SuperSmallCaption className={styles.error}>
                            {content.errorContentMissing}
                          </Typography.SuperSmallCaption>
                          <Link to={elem.to || ''} target="_blank" className={styles.link}>
                            <Typography.SmallCaption className={styles.editText}>
                              {content.edit}
                            </Typography.SmallCaption>
                          </Link>
                        </>
                      )}
                    </Box>
                  </Box>
                </div>
              ),
          )}
        </Box>
      </Box>

      <Box>{children}</Box>
    </div>
  );
};
