import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    exclamationMark: {
      fontSize: 0,
      fill: variables.color.secondary.orange,
    },
  }),
);

export const useTooltipStyles = makeStyles(() =>
  createStyles({
    arrow: {
      color: variables.color.primary.white,
      fontSize: '2.1rem',
      '&::before': {
        boxShadow: `0.1rem 0.1rem 0.4rem ${variables.color.secondary.orange}`,
        border: `${variables.borderWidth.sm} solid ${variables.color.secondary.orange}`,
      },
    },
    tooltip: {
      backgroundColor: variables.color.primary.white,
      boxShadow: `0.1rem 0.1rem 0.4rem ${variables.color.secondary.orange}`,
      border: `${variables.borderWidth.sm} solid ${variables.color.secondary.orange}`,
      color: variables.color.secondary.orange,
      borderRadius: variables.borderRadius.sm,
      fontSize: variables.font.size.md,
      padding: `${variables.gap.sm} 1.9rem ${variables.gap.sm} ${variables.gap.lg}`,
      minWidth: '10rem',
    },
  }),
);
