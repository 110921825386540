import { SVGProps } from 'react';

export const RemoveCircleOutline = ({
  width = 16,
  height = 16,
  stroke = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14 8C14 4.6875 11.3125 2 8 2C4.6875 2 2 4.6875 2 8C2 11.3125 4.6875 14 8 14C11.3125 14 14 11.3125 14 8Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M10.5 8H5.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
