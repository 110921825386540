import { Box, Typography as MuiTypography } from '@material-ui/core';

import { Typography, Icon } from '@components';
import { format } from '@utils';
import { content } from '@content';

import { BenchmarkItemProps } from './BenchmarkItem.props';
import { useStyles } from './BenchmarkItem.styles';

/**
 * BenchmarkItem component
 * @returns {JSX.Element}
 */

export const BenchmarkItem = ({ title, value, trend, className = '' }: BenchmarkItemProps): JSX.Element => {
  const styles = useStyles({ trend });

  return (
    <Box className={className}>
      <Typography.Caption>{title}</Typography.Caption>
      <MuiTypography className={styles.valueText}>{format.number(Number(value), '--')}</MuiTypography>
      <MuiTypography className={styles.percent}>{content.percentageUnit}</MuiTypography>
      {Number(trend) && Number(value) ? (
        <Box className={styles.row}>
          {trend > 0 ? <Icon.TrendUp /> : <Icon.TrendDown />}
          <Typography.Caption className={styles.trend}>
            {`${format.percent(Math.abs(trend))} ${trend > 0 ? content.above : content.below}`}
          </Typography.Caption>
        </Box>
      ) : null}
    </Box>
  );
};
