import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * SignalLibraryBuilder component styles
 * @returns {Function}
 */
const SMALL_VIEW_WIDTH = '1530px';

export const useStyles = makeStyles((theme) =>
  createStyles({
    signalBuilder: {
      paddingTop: '25px',
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(255, 255, 255, 0.7)',
      borderRadius: variables.borderRadius.sm,
      marginLeft: 0,
      transition: 'max-width 0.2s, margin-left 0.2s',
      borderLeft: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,

      '&[data-ui-type=treatment]': {
        borderRadius: 0,
      },
    },

    pageTitle: {
      padding: theme.spacing(2, 3),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    body: {
      padding: theme.spacing(3),

      [`@media (max-width: ${SMALL_VIEW_WIDTH})`]: {
        padding: theme.spacing(1.5),
      },
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),

      [`@media (max-width: ${SMALL_VIEW_WIDTH})`]: {
        flexWrap: 'wrap',

        '& > *:first-child': {
          margin: theme.spacing(0, 1, 1, 0),
        },
      },
    },

    treatmentName: {
      width: '33.6rem',

      '& input': {
        '&::placeholder': {
          textTransform: 'capitalize',
        },
      },
    },

    signalName: {
      width: '29.2rem',

      '& input': {
        '&::placeholder': {
          textTransform: 'capitalize',
        },
      },
    },

    attributeInfoSignal: {
      display: 'flex',
      padding: theme.spacing(2),
      width: '28.7rem',
      height: '7rem',
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
    },

    attributeInfoTreatment: {
      display: 'flex',
      padding: theme.spacing(2),
      width: '19.7rem',
      height: '7rem',
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
    },

    attributeInfoBox: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(2),
    },

    attributeInfoTitle: {
      color: variables.color.primary.mediumGray,
      fontSize: variables.font.size.xxxs,
      fontWeight: variables.font.weight.bold,
    },

    attributeInfoValue: {
      color: variables.color.primary.darkestGray,
      fontWeight: variables.font.weight.bold,
    },

    droppableAttribute:
    {
      width: '200px'
    },

    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16rem',
      gap: '10px',
      padding: theme.spacing(2, 3),
      borderTop: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
    },
  }),
);

export const useMetConditionStyles = makeStyles(() =>
  createStyles({
    conditionSelect: {
      maxWidth: '10.5rem',
      '& .MuiInputBase-input': {
        padding: '0.4rem 1rem 0.8rem 1rem',
        fontSize: variables.font.size.lg,
        fontWeight: variables.font.weight.bold,
        lineHeight: variables.lineHeight.lg,
        letterSpacing: variables.letterSpacing.sm,
        color: variables.color.primary.lightPurple,
        textTransform: 'lowercase',
      },
    },
  }),
);
