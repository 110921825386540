import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      '& .MuiButton-label': {
        justifyContent: 'flex-end',
      },
      '& svg': {
        stroke: variables.color.primary.lightPurple,
      },
      '&:hover, &[data-opened="true"]': {
        backgroundColor: variables.color.primary.lightPurple,
        borderColor: variables.color.primary.lightPurple,
        color: variables.color.primary.mainPurple,
        '& svg': {
          stroke: variables.color.primary.mainPurple,
        },
      },
      '&.MuiButton-containedSecondary': {
        '& svg': {
          stroke: variables.color.primary.white,
        },
        '&:hover, &[data-opened="true"]': {
          backgroundColor: variables.color.secondary.blue,
        },
      },
    },
    buttonTitle: {
      width: '100%',
      display: 'inline-block',
      marginRight: theme.spacing(1.3),
      boxSizing: 'border-box',
    },
    paper: {
      marginTop: variables.gap.xs,
    },
    menuList: {
      minWidth: '17.8rem',
      paddingTop: '0.2rem',
      paddingBottom: variables.gap.sm,
    },
    menuItem: {
      paddingLeft: variables.gap.lg,
      paddingRight: variables.gap.lg,
      paddingTop: '0.6rem',
      paddingBottom: '0.6rem',
      minHeight: 'auto',
      '&:hover': {
        backgroundColor: variables.color.primary.lightPurple,
        '& .MuiTypography-root': {
          color: variables.color.primary.mainPurple
        },
      },
    },
    menuItemLabel: {
      borderBottom: '0.15rem solid transparent',
    },
  }),
);
