import { memo, useMemo } from 'react';
import { Box, Button, MenuItem } from '@material-ui/core';
import { Droppable } from 'react-beautiful-dnd';
import { Controller, useFormContext } from 'react-hook-form';

import { Typography, Select, Icon, Alert } from '@components';
import { BetweenField, TimesField, DateField } from '@views';
import { textTemplate } from '@utils';
import { content } from '@content';
import { Signal } from '@modules';

import { useStyles } from './Condition.styles';
import { ConditionProps, ArgumentType } from './Condition.props';
import { SIGNAL_ARGUMENTS } from './Condition.const';

import { ConditionSignalValue } from './ConditionSignalValue';
import { RequiredField } from './RequiredField';

/**
 * Condition component
 * @returns {JSX.Element}
 */

export const Condition = memo(
  ({ condition, addCondition, mindsetIndex, errorMsg = '', clearErrorMsg }: ConditionProps): JSX.Element => {
    const styles = useStyles();
    const { setValue, watch, getFieldState } = useFormContext();

    const signal: Signal = watch(`mindsets.${mindsetIndex}.condition.signal`);
    const operator = watch(`mindsets.${mindsetIndex}.condition.operator`);
    const conditionsInit = watch(`mindsets.${mindsetIndex}.conditions`);
    const conditions = useMemo(() => conditionsInit || [], [conditionsInit]);

    return (
      <Box>
        {errorMsg && (
          <Alert message={content.pleaseCorrectlyFillFields} description={errorMsg} onClose={clearErrorMsg} />
        )}
        <Box className={styles.top}>
          <Box className={styles.signalBox}>
            <Droppable droppableId={`mindsets.${mindsetIndex}.condition.signal`} type="SIGNAL">
              {(provided) => (
                <Box
                  className={styles.signalFieldContainer}
                  {...{ ref: provided.innerRef }}
                  {...provided.droppableProps}
                >
                  <Controller
                    name={`mindsets.${mindsetIndex}.condition.signal`}
                    defaultValue={signal}
                    rules={{
                      validate: (val) =>
                        (conditions && conditions.length && !condition.operator && !val.name && !condition.value) ||
                        !!val.name,
                    }}
                    render={({ field: { value }, fieldState }) => (
                      <>
                        <Typography.Label className={styles.label} data-invalid={fieldState.invalid}>
                          {content.attribute}
                        </Typography.Label>
                        <Box
                          className={styles.signalField}
                          data-signal={!!value.name}
                          data-invalid={fieldState.invalid}
                        >
                          {value?.name && signal?.name ? (
                            <>
                              <Typography.Headline
                                component="span"
                                title={value?.name}
                                display="inline"
                                noWrap
                                data-invalid={fieldState.invalid}
                              >
                                {value?.name}
                              </Typography.Headline>
                            </>
                          ) : (
                            <Typography.Body className={styles.placeholder} data-invalid={fieldState.invalid}>
                              {content.dragAttributeHere}
                            </Typography.Body>
                          )}
                        </Box>
                      </>
                    )}
                  />
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
          <Box className={styles.argumentBox}>
            <Controller
              name={`mindsets.${mindsetIndex}.condition.operator` as const}
              defaultValue={condition.operator}
              rules={{
                validate: (val) => {
                  if (signal.type === 'boolean') {
                    return (conditions.length && !val.operator && !condition.signal?.name) || !val;
                  }

                  return (conditions.length && !val.operator && !condition.signal?.name && !condition.value) || !!val;
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <Typography.Label className={styles.label} data-invalid={fieldState.invalid}>
                    {content.condition}
                  </Typography.Label>
                  {signal?.type === 'boolean' ? (
                    <Typography.Body className={styles.booleanCondition}>{content.has}</Typography.Body>
                  ) : (
                    <Select
                      className={styles.signalOptionsDropdown}
                      inputRef={field.ref}
                      value={field.value || ''}
                      error={fieldState.invalid}
                      onChange={({ target: { value } }: any) => {
                        setValue(field.name, value as string, { shouldValidate: true });
                        setValue(`mindsets.${mindsetIndex}.condition.value.${field.value}`, '');
                        setValue(`mindsets.${mindsetIndex}.condition.value`, '');
                      }}
                      placeholder={content.select}
                      onBlur={field.onBlur}
                    >
                      {signal?.type ? (
                        SIGNAL_ARGUMENTS[signal.type].map((argument: ArgumentType) => (
                          <MenuItem key={argument.value} value={argument.value}>
                            {argument.label}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">&nbsp;</MenuItem>
                      )}
                    </Select>
                  )}
                </>
              )}
            />
          </Box>
          {signal?.signalType !== 'statistic' && (
            <Box className={styles.signalValueBox}>
              <Typography.Label
                className={styles.label}
                data-invalid={getFieldState(`mindsets.${mindsetIndex}.condition.value`).invalid}
              >
                {content.value}
              </Typography.Label>
              <ConditionSignalValue
                defaultValue={condition.value}
                freeSolo={signal?.type === 'numeric'}
                signalId={signal?.id}
                disabled={!signal}
                mindsetIndex={mindsetIndex}
              />
            </Box>
          )}
          {signal?.signalType === 'statistic' && operator === 'between' && (
            <BetweenField
              mindsetIndex={mindsetIndex}
              defaultValue={typeof condition.value !== 'string' ? condition.value : undefined}
            />
          )}
          {signal?.signalType === 'statistic' && ['on', 'before', 'after'].includes(operator) && (
            <DateField mindsetIndex={mindsetIndex} withLabel operator={operator} />
          )}
          {signal?.signalType === 'statistic' && operator === '=' && (
            <TimesField defaultValue={condition.value} mindsetIndex={mindsetIndex} withLabel />
          )}
        </Box>
        <Box className={styles.bottomContainer}>
          <RequiredField condition={condition} mindsetIndex={mindsetIndex} />
          <Button variant="outlined" onClick={addCondition} className={styles.addButton}>
            <Icon.AddCircleOutline className={styles.iconAdd} />
            {textTemplate(content.addValue, { value: content.rule.toLowerCase() })}
          </Button>
        </Box>
      </Box>
    );
  },
);

Condition.displayName = 'Condition';
