import { SVGProps } from 'react';

export const Draggable = ({
  width = 16,
  height = 16,
  fill = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="4" y="4" width="2" height="2" rx="1" fill={fill} />
    <rect x="4" y="7" width="2" height="2" rx="1" fill={fill} />
    <rect x="4" y="10" width="2" height="2" rx="1" fill={fill} />
    <rect x="7" y="4" width="2" height="2" rx="1" fill={fill} />
    <rect x="7" y="7" width="2" height="2" rx="1" fill={fill} />
    <rect x="7" y="10" width="2" height="2" rx="1" fill={fill} />
    <rect x="10" y="4" width="2" height="2" rx="1" fill={fill} />
    <rect x="10" y="7" width="2" height="2" rx="1" fill={fill} />
    <rect x="10" y="10" width="2" height="2" rx="1" fill={fill} />
  </svg>
);
