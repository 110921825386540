import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * MindsetPrioritization component styles
 * @returns {Function}
 */

const smallViewWidth = '1250px';

export const useStyles = makeStyles((theme) =>
  createStyles({
    mindsetPrioritization: {
      background: variables.color.primary.white,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },

    header: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: theme.spacing(3),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,

      [`@media (max-width: ${smallViewWidth})`]: {
        padding: theme.spacing(2),
      },
    },

    icon: {
      display: 'flex',
      padding: theme.spacing(1),
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      marginRight: theme.spacing(2),

      [`@media (max-width: ${smallViewWidth})`]: {
        marginRight: theme.spacing(1.5),
      },
    },

    action: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },

    actionTitle: {
      marginBottom: theme.spacing(0.5),
    },

    actionSubTitle: {
      marginBottom: theme.spacing(1.5),
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      flexGrow: 1,

      [`@media (max-width: ${smallViewWidth})`]: {
        padding: theme.spacing(2),
      },
    },

    noItems: {
      textAlign: 'center',
      margin: 'auto',

      '& > p': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);
