import { createContext } from 'react';

import { TreatmentBuilderContextValue } from './TemplateView.types';

/**
 TemplateView component variables
 @returns {variables}
 */

export enum Libraries {
  IMAGES = 'images',
  DATA_VISUAL = 'data-visualization',
  DATA_ATTRIBUTES = 'data-attributes',
}

export enum RightPanelElems {
  DEPLOYMENT_SETTINGS = 'deployment-settings',
  TREATMENTS = 'treatments',
  MODULE_SETTINGS = 'module-settings',
  WYSIWYG_UNIVERSAL = 'wysiwyg-universal',
  HTML_EDITOR = 'html-editor',
  WYSIWYG_IMAGE = 'wysiwyg-image',
  WYSIWYG_DATA_VISUAL = 'wysiwyg-data-visual',
  WYSIWYG_DIV = 'wysiwyg-div',
}

export const TreatmentBuilderContext = createContext<TreatmentBuilderContextValue>({
  chosenElemId: '',
  chosenModuleElemId: '',
  chosenExperienceId: null,
  editableTreatmentId: null,
  showAddTreatmentPanel: false,
  showModuleEditor: false,
  isTargetContentShown: false,
  chosenModuleInnerHtml: '',
  moduleIndex: 0,
  defaultChosenTreats: [],
  chosenElemStyles: '',
  wysiwygContainerType: 'text',
  chosenLibrary: null,
  singleModulesWrapper: null,
  isTreatmentTouched: false,
  chosenModuleId: null,
  currentExperienceId: null,
  isModuleChanged: false,
  newExperienceCloned: {
    cloned: false,
    splitterId: null,
    experienceId: null,
  },
  deploymentsInfo: [],
  disclosure: null,
  elemInputActive: 'disabled',
  experiencesIds: [],
  cursorPosition: 0,
  editMode: 'no-code',
  showRemoveExperienceModal: false,
  showRemoveTreatmentModal: false,
  showRemoveWithRevisions: false,
  moduleDataVisualizationStation: {},
  DVItems: [],
  setDVItems: () => false,
  setModuleDataVisualizationStation: () => {},
  setShowRemoveWithRevisions: () => false,
  setShowRemoveTreatmentModal: () => false,
  setShowRemoveExperienceModal: () => false,
  setDisclosure: () => false,
  setExperiencesIds: () => false,
  setDeploymentsInfo: () => false,
  setNewExperienceCloned: () => false,
  setCurrentExperienceId: () => false,
  setChosenModuleId: () => false,
  setIsTreatmentTouched: () => false,
  setIsTargetContentShown: () => false,
  setSingleModulesWrapper: () => false,
  setChosenElemId: () => false,
  setChosenExperienceId: () => false,
  setEditableTreatmentId: () => false,
  setShowAddTreatmentPanel: () => false,
  setShowModuleEditor: () => false,
  setChosenModuleInnerHtml: () => false,
  setModuleIndex: () => false,
  setDefaultChosenTreats: () => false,
  setChosenElemStyles: () => false,
  setWysiwygContainerType: () => false,
  setChosenModuleElemId: () => false,
  setChosenLibrary: () => false,
  setIsModuleChanged: () => false,
  setElemInputActive: () => 'disabled',
  setCursorPosition: () => 0,
  setEditMode: () => 'no-code',
});

export const DEFAULT_DELETED_EXPERIENCE = {
  splitterId: null,
  isDeleted: false,
};

export const DEFAULT_CHOSEN_IMAGE = {
  id: null,
  chosenModuleElemId: null,
  width: null,
  height: null,
  imageSrc: null,
  name: null,
  link: null,
  altText: null,
};

export const DEFAULT_CHOSEN_DV = {
  id: 0,
  name: '',
  embedCode: '',
  embedCodeFull: '',
  thumbnailPath: '',
  defaultImagePath: '',
  codeBlock: '',
  isMaxScaleEnabled: false,
  width: 0,
  height: 0,
  businessUnit: 0,
};

export const DEFAULT_IMAGE_WRAPPER_WIDTH = 171;

export const DEFAULT_IMAGE_WRAPPER_HEIGHT = 127;

export const DEPLOYMENTS_MINDSET_DND_ID = 'deployments.mindset';
