import { createAsyncThunk } from '@store';
import { ApiError, global } from '@modules';
import { network, textTemplate } from '@utils';
import { content } from '@content';

import {
  Program,
  ClientProgramsListParams,
  ClientProgramsListData,
  ProgramUpdateArg,
  ProgramCreatePayload,
  ProgramItem,
  ProgramToggleArchivedQuery,
} from './ClientPrograms.schema';

export const getAll = createAsyncThunk('businessUnitPrograms/getAll', (businessUnit: number, thunkApi) => {
  try {
    return network.get<Program[]>(
      { key: 'get_business_unit_programs', params: { businessUnit: businessUnit, paginated: false } },
      { signal: thunkApi.signal },
    );
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});

export const getList = createAsyncThunk(
  'businessUnitPrograms/getList',
  ({ businessUnit, ...rest }: ClientProgramsListParams, thunkApi) => {
    try {
      return network.get<ClientProgramsListData>(
        { key: 'get_business_unit_programs', params: { businessUnit, ...rest } },
        { signal: thunkApi.signal },
      );
    } catch (exception) {
      return thunkApi.rejectWithValue(exception as ApiError);
    }
  },
);

export const getItem = createAsyncThunk<ProgramItem | void, { program: number }>(
  'businessUnitPrograms/getItem',
  ({ program }, { rejectWithValue, dispatch }) => {
    try {
      return network.get<ProgramItem>({ key: 'get_program', params: { program } });
    } catch (e) {
      dispatch(global.actions.enqueueError(e as ApiError));

      return rejectWithValue(e as ApiError);
    }
  },
);

export const update = createAsyncThunk('businessUnitPrograms/update', async (arg: ProgramUpdateArg, thunkApi) => {
  const { id: program, ...payload } = arg;

  thunkApi.dispatch(global.actions.isLoading(true));

  try {
    const response = await network.put<Program>(
      { key: 'update_program', params: { program } },
      { body: JSON.stringify(payload), signal: thunkApi.signal },
    );

    // thunkApi.dispatch(
    //   global.actions.enqueueNotification({
    //     message: textTemplate(content.updatedSuccessfully, { value: content.program }),
    //     options: { variant: 'success' },
    //   }),
    // );

    return response;
  } catch (exception) {
    thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

    return thunkApi.rejectWithValue(exception as ApiError);
  } finally {
    thunkApi.dispatch(global.actions.isLoading(false));
  }
});

export const toggleArchived = createAsyncThunk(
  'businessUnitPrograms/toggleArchived',
  async ({ program, isArchived }: ProgramToggleArchivedQuery, { dispatch, rejectWithValue }) => {
    try {
      const response = await network.put<Program>(
        { key: 'toggle_archive_program', params: { program } },
        { body: JSON.stringify({ isArchived }) },
      );

      // dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.entitySuccessfullyProceed, {
      //       entity: content.program,
      //       proceed: (isArchived ? content.archived : content.unarchived).toLowerCase(),
      //     }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return response;
    } catch (exception) {
      const error = exception as ApiError;

      dispatch(global.actions.enqueueError(error));

      return rejectWithValue(error);
    }
  },
);

export const create = createAsyncThunk(
  'businessUnitPrograms/create',
  async (payload: ProgramCreatePayload, thunkApi) => {
    thunkApi.dispatch(global.actions.isLoading(true));

    try {
      const { businessUnit, enterpriseGroup: enterpriseId, ...values } = payload;
      const response = await network.post<Program>(
        { key: 'create_program' },
        {
          body: JSON.stringify({ ...values, businessUnit: { id: businessUnit }, enterprise: enterpriseId }),
          signal: thunkApi.signal,
        },
      );

      // thunkApi.dispatch(
      //   global.actions.enqueueNotification({
      //     message: textTemplate(content.createdSuccessfully, { value: content.program }),
      //     options: { variant: 'success' },
      //   }),
      // );

      return response;
    } catch (exception) {
      thunkApi.dispatch(global.actions.enqueueError(exception as ApiError));

      return thunkApi.rejectWithValue(exception as ApiError);
    } finally {
      thunkApi.dispatch(global.actions.isLoading(false));
    }
  },
);
