import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import * as thunk from './Audit.thunk';
import { initialState } from './Audit.state';
import { reducers, extraReducers } from './Audit.reducer';
import { AuditSchema } from './Audit.schema';

export * from './Audit.schema';

const slice = createSlice({
  name: 'audit',
  initialState,
  reducers,
  extraReducers,
});

export const audit = {
  thunk,
  slice,
  actions: slice.actions,
  useDisclosure: (): AuditSchema['disclosure'] => useAppSelector((state) => state.audit.disclosure),
  useNullSubscriber: (): AuditSchema['nullSubscriber'] => useAppSelector((state) => state.audit.nullSubscriber),
};
