import { Bar } from '@nivo/bar';

import { BarSingleChartProps } from './BarSingleChart.props';
import { BarSingleChartComponent } from './BarSingleChartComponent';

export const BarSingleChart = ({
  data,
  color,
  highlightItem = () => {},
  grayOutItem = () => {},
}: BarSingleChartProps): JSX.Element => (
  <Bar
    enableGridY={false}
    enableGridX={false}
    layout="horizontal"
    width={400}
    height={21}
    data={data as Record<string, unknown>[]}
    keys={['value']}
    padding={0.02}
    // eslint-disable-next-line max-len
    barComponent={(props) => (
      <BarSingleChartComponent {...props} highlightItem={highlightItem} grayOutItem={grayOutItem} />
    )}
    borderRadius={4}
    indexBy="label"
    colors={[color]}
    layers={['grid', 'axes', 'bars']}
    axisBottom={{
      tickSize: 0,
      tickPadding: 10,
      ariaHidden: true,
    }}
    axisLeft={{
      tickSize: 0,
      tickPadding: 10,
    }}
  />
);
