import { SVGProps } from 'react';

export const TrashOutline = ({
  width = 16,
  height = 16,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4 4L4.55556 13.0909C4.58194 13.6162 4.95556 14 5.44444 14H10.5556C11.0464 14 11.4131 13.6162 11.4444 13.0909L12 4"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 4H13H3Z" fill={color} />
    <path d="M3 4H13" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M6 2L10 2" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
