import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Confirm, FileUpload, FileUploadProps } from '@components';
import { content } from '@content';
import { dataVisualization } from '@modules';
import { textTemplate, useLoader, validate } from '@utils';

import { ChangeDefaultImageModalProps } from './ChangeDefaultImageModal.props';
import { FormValues } from './ChangeDefaultImageModal.types';

/**
 * UploadVisualizationModal component
 * @returns {JSX.Element}
 */

export const ChangeDefaultImageModal = ({ onCancel, onSubmit }: ChangeDefaultImageModalProps): JSX.Element => {
  const { handleSubmit, control, setValue, clearErrors, setError, watch } = useForm<FormValues>({
    defaultValues: {},
  });

  const dataVisualizationMeta = dataVisualization.useEntityMeta();
  useLoader(dataVisualizationMeta);

  const defaultImage = watch('defaultImage');

  const handleDefaultImageChange: FileUploadProps['onChange'] = useCallback(
    (file) => {
      clearErrors('defaultImage');

      if (file && !Array.isArray(file)) {
        if (validate.isImageFile(file?.type)) {
          setValue('defaultImage', file);
        } else {
          setError('defaultImage', { message: content.invalidFileFormat });
        }
      } else {
        setValue('defaultImage', null);
      }
    },
    [clearErrors, setError, setValue],
  );

  return (
    <Confirm
      open
      submitDisabled={!defaultImage}
      title={content.uploadDefaultImage}
      submitLabel={content.uploadDefaultImage}
      skipSubmitArrow
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="defaultImage"
        control={control}
        rules={{ required: textTemplate(content.pleaseSelect, { field: content.defaultImage }) }}
        render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
          <FileUpload
            {...field}
            id="default_image"
            extensions={['jpeg', 'jpg', 'png']}
            label={textTemplate(content.requiredField, { field: content.defaultImage })}
            hint={error?.message || `*${content.required}. ${content.imageFileFormatsOnly}`}
            error={invalid}
            value=""
            onChange={handleDefaultImageChange}
          />
        )}
      />
    </Confirm>
  );
};
