import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { MetaSchema } from '@modules';
import * as thunk from './Deployment.thunk';
import { initialState } from './Deployment.state';
import { reducers, extraReducers } from './Deployment.reducer';
import { DeploymentEntityData, DataVizGenerationStatusPayload, DeploymentSchema } from './Deployment.schema';

export * from './Deployment.schema';
export * from './Deployment.types';

const slice = createSlice({
  name: 'deployment',
  initialState,
  reducers,
  extraReducers,
});

export const deployment = {
  thunk,
  slice,
  actions: slice.actions,
  useMeta: (): MetaSchema =>
    useAppSelector(
      ({
        deployment: {
          entity: { data, ...meta },
        },
      }) => meta,
    ),
  useDeploymentData: (): DeploymentEntityData => useAppSelector((root) => root.deployment.entity.data),
  useDataVizGenerationStatusMeta: (): MetaSchema =>
    useAppSelector(
      ({
        deployment: {
          dataVizGenerationStatus: { data, ...meta },
        },
      }) => meta,
    ),
  useDataVizGenerationStatusData: (): DataVizGenerationStatusPayload =>
    useAppSelector(
      ({
        deployment: {
          dataVizGenerationStatus: { data },
        },
      }) => data,
    ),
  useModulesList: (): DeploymentSchema['modulesList'] => useAppSelector((state) => state.deployment.modulesList),
  useInfoEmailTemplate: (): DeploymentSchema['infoEmailTemplate'] =>
    useAppSelector((state) => state.deployment.infoEmailTemplate),
  useInfoEmailTemplateMeta: (): MetaSchema =>
    useAppSelector(
      ({
        deployment: {
          infoEmailTemplate: { data, ...meta },
        },
      }) => meta,
    ),
};
