import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getList } from './ArticleLibrary.thunk';
import { ArticleLibrarySchema } from './ArticleLibrary.schema';

export const reducers = {};

export const extraReducers = (builder: ActionReducerMapBuilder<ArticleLibrarySchema>): void => {
  builder.addCase(getList.pending, ({ list }, action) => {
    if (list.status !== 'loading') {
      list.requestId = action.meta.requestId;
      list.status = 'loading';
      list.error = undefined;
    }
  });

  builder.addCase(getList.fulfilled, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'success';
      if (action.payload) {
        list.data = action.payload;
      }
    }
  });

  builder.addCase(getList.rejected, ({ list }, action) => {
    if (list.status === 'loading' && list.requestId === action.meta.requestId) {
      list.requestId = '';
      list.status = 'error';
      list.error = {
        message: action.payload?.message || action.error.message,
      };
      list.data = [];
    }
  });
};
