import React from 'react';
import { Box, Button } from '@material-ui/core';

import { Alert, Modal, Panel, Typography } from '@components';
import { textTemplate } from '@utils';
import { content } from '@content';

import { ModuleTemplateProps } from './ModuleTemplate.props';
import { useStyles } from './ModuleTemplate.styles';
import { IMAGE_SIZE_LIMIT_BYTES, IMAGE_SIZE_LIMIT_MB } from './ModuleTemplate.const';

export const ModuleTemplate: React.FC<ModuleTemplateProps> = ({
  className,
  image,
  parts = [],
  selectedId,
  disabled = false,
  readonly = false,
  onError,
  onChange,
}): React.ReactElement => {
  const styles = useStyles();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [removing, setRemoving] = React.useState(false);

  const handleImageRemove = React.useCallback(() => setRemoving(true), [setRemoving]);

  const closeRemoving = React.useCallback(() => setRemoving(false), [setRemoving]);

  const handleImageRemovingConfirm = React.useCallback(() => {
    closeRemoving();
    onChange({ coverImage: null, imageFile: null });
  }, [closeRemoving, onChange]);

  const handleImageSelection = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files && files.length !== 0) {
        const imageFile = files[0];

        if (imageFile.size <= IMAGE_SIZE_LIMIT_BYTES) {
          const reader = new FileReader();
          reader.addEventListener('load', (loadEvent: ProgressEvent<FileReader>) => {
            onChange({ coverImage: loadEvent.target?.result, imageFile });
          });
          reader.readAsDataURL(imageFile);
        } else {
          onError(textTemplate(content.imageSizeLimitExceeded, { limit: IMAGE_SIZE_LIMIT_MB }));
        }
      }
    },
    [onChange, onError],
  );

  const handleUploadClick = React.useCallback(() => inputRef.current?.click(), [inputRef]);

  return (
    <Panel className={`${styles.moduleTemplate} ${className}`}>
      {!readonly && (
        <Button
          className={styles.upload}
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={handleUploadClick}
        >
          {content.uploadImage}
          <input
            type="file"
            accept="image/jpeg, image/jpg, image/png"
            hidden
            value=""
            ref={inputRef}
            onChange={handleImageSelection}
          />
        </Button>
      )}
      {parts.length > 0 && (
        <Box className={styles.content}>
          {parts.map((part) => (
            <Box className={styles.item} key={part?.id}>
              <Typography.Tag>{part?.name}</Typography.Tag>
              <Box className={styles.rect} data-selected={part?.id === selectedId} data-imaged={!!image}>
                {image && part?.id === selectedId && (
                  <>
                    <img className={styles.image} src={image} alt="" />
                    {!readonly && (
                      <Button className={styles.remove} disabled={disabled} onClick={handleImageRemove}>
                        {content.removeImage}
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Modal open={removing} maxWidth="46.4rem">
        <Box className={styles.removing}>
          <Alert className={styles.warning} message={content.warning} />
          <Typography.Body className={styles.question}>{content.areYouSureYouWantToRemoveThisImage}</Typography.Body>
          <Box className={styles.options}>
            <Button className={styles.cancel} variant="outlined" onClick={closeRemoving}>
              {content.cancel}
            </Button>
            <Button className={styles.confirm} variant="contained" color="primary" onClick={handleImageRemovingConfirm}>
              {content.yesRemove}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Panel>
  );
};
