import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getMailPartsByProgram } from './MailParts.thunk';
import { initialState } from './MailParts.state';
import { MailPartsSchema } from './MailParts.schema';

export const reducers = {
  resetMailPartsByProgram: (state: MailPartsSchema): void => {
    state.mailPartsByProgram = initialState.mailPartsByProgram;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<MailPartsSchema>): void => {
  builder.addCase(getMailPartsByProgram.pending, ({ mailPartsByProgram }, action) => {
    if (mailPartsByProgram.status !== 'loading') {
      mailPartsByProgram.requestId = action.meta.requestId;
      mailPartsByProgram.status = 'loading';
      mailPartsByProgram.error = initialState.mailPartsByProgram.error;
    }
  });

  builder.addCase(getMailPartsByProgram.fulfilled, ({ mailPartsByProgram }, action) => {
    if (mailPartsByProgram.status === 'loading' && mailPartsByProgram.requestId === action.meta.requestId) {
      mailPartsByProgram.requestId = '';
      mailPartsByProgram.status = 'success';
      if (action.payload) {
        mailPartsByProgram.data = action.payload;
      }
    }
  });

  builder.addCase(getMailPartsByProgram.rejected, ({ mailPartsByProgram }, action) => {
    if (mailPartsByProgram.status === 'loading' && mailPartsByProgram.requestId === action.meta.requestId) {
      mailPartsByProgram.requestId = '';
      mailPartsByProgram.status = 'error';
      mailPartsByProgram.error = {
        message: action.payload?.message || action.error.message,
      };
      mailPartsByProgram.data = initialState.mailPartsByProgram.data;
    }
  });
};
