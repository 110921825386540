import { SVGProps } from 'react';

export const BusinessOutline = ({
  width = 24,
  height = 24,
  color = '#494949',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.53846 17.1429V20M5.38462 4H11.6154C11.9826 4 12.3348 4.15051 12.5945 4.41842C12.8541 4.68633 13 5.04969 13 5.42857V19.8214C13 19.8688 12.9818 19.9142 12.9493 19.9477C12.9168 19.9812 12.8728 20 12.8269 20H4V5.42857C4 5.04969 4.14588 4.68633 4.40554 4.41842C4.66521 4.15051 5.01739 4 5.38462 4ZM13.6923 11H18.6154C18.9826 11 19.3348 11.1505 19.5945 11.4184C19.8541 11.6863 20 12.0497 20 12.4286V20H13V11.7143C13 11.5248 13.0729 11.3432 13.2028 11.2092C13.3326 11.0753 13.5087 11 13.6923 11Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="6.5" y="6.5" width="1.5" height="1.5" fill={color} />
    <rect x="6.5" y="9.5" width="1.5" height="1.5" fill={color} />
    <rect x="6.5" y="12.5" width="1.5" height="1.5" fill={color} />
    <rect x="9.5" y="9.5" width="1.5" height="1.5" fill={color} />
    <rect x="9.5" y="6.5" width="1.5" height="1.5" fill={color} />
    <rect x="9.5" y="12.5" width="1.5" height="1.5" fill={color} />
    <rect x="15.5" y="13.5" width="1.5" height="1.5" fill={color} />
    <rect x="15.5" y="16.5" width="1.5" height="1.5" fill={color} />
  </svg>
);
