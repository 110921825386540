import { SVGProps } from 'react';

export const Saving = ({ color = '#89939C' }: SVGProps<SVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.75 8C3.75 5.65279 5.65279 3.75 8 3.75C10.3472 3.75 12.25 5.65279 12.25 8C12.25 10.3472 10.3472 12.25 8 12.25V13.75C11.1756 13.75 13.75 11.1756 13.75 8C13.75 4.82436 11.1756 2.25 8 2.25C4.82436 2.25 2.25 4.82436 2.25 8H3.75Z"
      fill="url(#paint0_linear_916_1076)"
    />
    <defs>
      <linearGradient id="paint0_linear_916_1076" x1="3.5" y1="9" x2="14" y2="8" gradientUnits="userSpaceOnUse">
        <stop stopColor={color} stopOpacity="0" />
        <stop offset="1" stopColor={color} />
      </linearGradient>
    </defs>
  </svg>
);
