import { base } from '@modules';

import { UsersSchema } from './Users.schema';

export const initialState: UsersSchema = {
  list: {
    ...base.state,
    data: base.paginagedState,
  },
};
