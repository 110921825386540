import { MailBuilderTemplateJson } from '@modules';
import MAIL_BUILDER_TEMPLATE_DEFAULT from './MailBuilder.template.json';

export const templateToJson = (value: string): MailBuilderTemplateJson => {
  let json: MailBuilderTemplateJson = MAIL_BUILDER_TEMPLATE_DEFAULT as MailBuilderTemplateJson;

  if (value) {
    try {
      json = JSON.parse(value) || json;
    } catch (exception) {} // eslint-disable-line no-empty
  }

  return json;
};

export const isJsonEmpty = (json: MailBuilderTemplateJson): boolean =>
  json.page.rows.length === 0 ||
  json.page.rows.every((row) => row.columns.length === 0 || row.columns.every((column) => column.modules.length === 0));
