import { useRef, useState, useCallback, cloneElement, useMemo, useEffect, useLayoutEffect } from 'react';
import { Box, IconButton } from '@material-ui/core';

import { Panel, Typography, Icon, Tooltip } from '@components';
import { func } from '@utils';

import { useStyles } from './Collapse.styles';
import { CollapseHeadChildrenPosition, CollapseProps } from './Collapse.props';

export const Collapse = ({
  className = '',
  title,
  headChildren,
  open,
  disabled = false,
  skipBodyInnerGap = false,
  saveVerticalSpace = false,
  children,
  onToggle,
}: CollapseProps): JSX.Element => {
  const styles = useStyles();

  const content = useRef<HTMLDivElement>(null);
  const openOld = useRef(open);

  const [height, setHeight] = useState(0);

  const renderHeadContents = useCallback(
    (position: CollapseHeadChildrenPosition) =>
      headChildren
        ?.filter((child) => child.position === position)
        .map((child, index) => cloneElement(child.content, { key: `${child.position}.${index}` })) || [], // eslint-disable-line react/no-array-index-key
    [headChildren],
  );

  const startHeadContents = useMemo(() => renderHeadContents('start'), [renderHeadContents]);

  const centerHeadContents = useMemo(() => renderHeadContents('center'), [renderHeadContents]);

  const handleToggle = useCallback(() => onToggle && onToggle(!open), [onToggle, open]);

  const updateContentHeight = useCallback(() => {
    const node = content.current;

    if (node) {
      const prevOpen = openOld.current;
      const nextHeight = prevOpen && !open ? 0 : node.offsetHeight;
      const toggled = prevOpen !== open;
      const mutated = open && nextHeight !== height;

      openOld.current = open;

      if (toggled || mutated) {
        setHeight(nextHeight);
      }
    }
  }, [setHeight, openOld, open, height]);

  useEffect(() => {
    const node = content.current;
    let observer: MutationObserver | undefined;

    if (node) {
      observer = new MutationObserver(updateContentHeight);
      observer.observe(node, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer?.disconnect();
    };
  }, [updateContentHeight]);

  useLayoutEffect(() => updateContentHeight(), [updateContentHeight]);

  return (
    <Panel className={`${styles.collapse} ${className}`} data-thin={saveVerticalSpace}>
      <Box className={styles.head} data-open={open} data-reduce-gap={skipBodyInnerGap || saveVerticalSpace}>
        {startHeadContents.length > 0 && <Box>{startHeadContents}</Box>}
        <Box className={styles.hat}>
          <Tooltip pos="fix" text={title}>
            <Typography.Title className={styles.title} data-small={saveVerticalSpace}>
              {title}
            </Typography.Title>
          </Tooltip>
        </Box>
        {centerHeadContents.length > 0 && <Box className={styles.center}>{centerHeadContents}</Box>}
        <IconButton
          className={styles.toggle}
          data-up={open}
          data-small={saveVerticalSpace}
          disabled={disabled}
          onClick={disabled ? func.nop : handleToggle}
        >
          <Icon.ArrowDown className={`${styles.arrow} ${saveVerticalSpace ? styles.arrowSmall : ''}`} />
        </IconButton>
      </Box>
      <Box className={styles.body} style={{ height }} data-skip-inner-gap-x={skipBodyInnerGap}>
        <Box className={styles.content} data-shown={open} {...{ ref: content }}>
          {children}
        </Box>
      </Box>
    </Panel>
  );
};
