import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables, theme } from '@styles';

/**
 * ReviewDeployment component styles
 * @returns {Function}
 */
export const useStyles = makeStyles(() =>
  createStyles({
    reviewDeployment: {
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
      flex: '1 1 auto',
    },
    header: {
      padding: theme.spacing(1.75, 3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    rowAlignBottom: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    backText: {
      marginLeft: theme.spacing(1.25),
    },
    content: {
      padding: theme.spacing(3),
    },
    backButton: {
      textTransform: 'initial',
    },
    label: {
      padding: theme.spacing(1, 0),
      color: variables.color.primary.mediumGray,
      whiteSpace: 'pre-wrap',
    },
    value: {
      padding: theme.spacing(1, 0),
    },
    grid: {
      margin: theme.spacing(3, 0, 2, 0),
    },
    buttonsContainer: {
      marginTop: theme.spacing(5),
    },
    publishButton: {
      marginRight: theme.spacing(2),
    },
    editText: {
      marginLeft: theme.spacing(1),
      color: variables.color.primary.mainPurple,
    },
    link: {
      textDecoration: 'none',
    },
  }),
);
