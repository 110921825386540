import { Box, IconButton } from '@material-ui/core';

import { Icon, Typography } from '@components';

import { LibrariesPanelWrapperProps } from './LibrariesPanelWrapper.props';
import { useStyles } from './LibrariesPanelWrapper.styles';
import { LibraryPanel } from '../LibraryPanel';
import { content } from '@content';

/**
 LibrariesPanelWrapper component
 @returns {JSX.Element}
 */

export const LibrariesPanelWrapper = ({
  children,
  isImageLibrary = false,
  onChooseLibrary,
}: LibrariesPanelWrapperProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={styles.librariesPanelWrapper} style={{ width: isImageLibrary ? '43rem' : '45rem' }}>
      {!isImageLibrary && <LibraryPanel onChooseLibrary={onChooseLibrary} />}
      <Box className={styles.content} style={{ height: isImageLibrary ? `calc(100% + 6rem)` : 'calc(100% - 5rem)' }}>
        {children}
      </Box>
    </Box>
  );
};
