import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';
import { image } from '@content';

export const useStyles = makeStyles((theme) =>
  createStyles({
    mobileOverlay: ({ maxWidth }: { maxWidth: number }) => ({
      [`@media (max-width: ${maxWidth}px)`]: {
        overflow: 'hidden',
      },
    }),

    overlay: ({ maxWidth }: { maxWidth: number }) => ({
      display: 'none',
      background: `${variables.color.primary.white} url(${image.bubble}) center / cover no-repeat`,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 2000,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [`@media (max-width: ${maxWidth}px)`]: {
        display: 'flex',
      },
    }),

    logo: {
      height: '40px',
    },

    panel: {
      textAlign: 'center',
      paddingBottom: '4rem',
      margin: theme.spacing(4.25, 3.75, 0),
    },

    icon: {
      fill: variables.color.secondary.orange,
    },

    text: {
      marginTop: '1.6rem',
      whiteSpace: 'pre-line',
      marginBottom: '4.8rem',
    },

    content: ({ maxWidth }: { maxWidth: number }) => ({
      [`@media (max-width: ${maxWidth}px)`]: {
        display: 'none',
      },
    }),
  }),
);
