import { SVGProps } from 'react';

import { variables } from '@styles';

/**
 * ClipboardOutline component
 * @returns {JSX.Element}
 */

export const ClipboardOutline = ({
  width = 16,
  height = 16,
  color = variables.color.primary.darkestGray,
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.91153 1.2002C5.14338 0.607504 5.71353 0.200195 6.40005 0.200195H9.80005C10.4866 0.200195 11.0567 0.607504 11.2886 1.2002H11.5C12.1 1.2002 12.7 1.4002 13.1 1.9002C13.5 2.3002 13.8 2.9002 13.8 3.5002V13.5002C13.8 14.1002 13.6 14.7002 13.1 15.1002C12.6 15.5002 12.1 15.8002 11.5 15.8002H4.49995C3.89995 15.8002 3.29995 15.6002 2.89995 15.1002C2.49995 14.7002 2.19995 14.1002 2.19995 13.5002V3.5002C2.19995 2.9002 2.39995 2.3002 2.89995 1.9002C3.29995 1.5002 3.89995 1.2002 4.49995 1.2002H4.91153ZM6.30005 3.8002H9.70005C10.2956 3.8002 10.8357 3.35685 11.1554 2.8002H11.5C11.7 2.8002 11.9 2.9002 12 3.0002C12.1 3.1002 12.2 3.3002 12.2 3.5002V13.5002C12.2 13.7002 12.1 13.9002 12 14.0002C11.9 14.1002 11.7 14.2002 11.5 14.2002H4.49995C4.29995 14.2002 4.09995 14.1002 3.99995 14.0002C3.89995 13.9002 3.79995 13.7002 3.79995 13.5002V3.5002C3.79995 3.35379 3.85354 3.26096 3.92149 3.14327C3.94636 3.1002 3.97316 3.05379 3.99995 3.0002C4.07011 2.85988 4.18948 2.81801 4.32355 2.77098C4.38058 2.75098 4.44026 2.73004 4.49995 2.7002H4.8883C5.1006 3.30279 5.66924 3.8002 6.30005 3.8002ZM6.20005 2.2002L6.30005 1.8002H9.70005V1.9002L9.60005 2.3002L6.20005 2.2002Z"
      fill={color}
    />
  </svg>
);
