import { variables } from '@styles';
import { SVGProps } from 'react';

/**
 * DataVisualization component
 * @returns {JSX.Element}
 */

export const DataVisualization = ({
  className = '',
  width = 25,
  height = 25,
  fill = variables.color.primary.mediumGray,
}: SVGProps<SVGElement>) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 3C3.75 2.58579 3.41421 2.25 3 2.25C2.58579 2.25 2.25 2.58579 2.25 3V21.3214C2.25 21.7003 2.40051 22.0637 2.66842 22.3316C2.93633 22.5995 3.29969 22.75 3.67857 22.75H22C22.4142 22.75 22.75 22.4142 22.75 22C22.75 21.5858 22.4142 21.25 22 21.25H3.75V3Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 10.25C5.8492 10.25 5.25 11.0352 5.25 11.8333V18.1667C5.25 18.9648 5.8492 19.75 6.75 19.75H8.25C9.1508 19.75 9.75 18.9648 9.75 18.1667V11.8333C9.75 11.0352 9.1508 10.25 8.25 10.25H6.75ZM6.75 11.8333C6.75 11.7908 6.76373 11.7632 6.7737 11.75H8.2263C8.23627 11.7632 8.25 11.7908 8.25 11.8333V18.1667C8.25 18.2092 8.23627 18.2368 8.2263 18.25H6.7737C6.76373 18.2368 6.75 18.2092 6.75 18.1667V11.8333Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 9.83333C11.25 9.03517 11.8492 8.25 12.75 8.25H14.25C15.1508 8.25 15.75 9.03517 15.75 9.83333V18.1667C15.75 18.9648 15.1508 19.75 14.25 19.75H12.75C11.8492 19.75 11.25 18.9648 11.25 18.1667V9.83333ZM12.7737 9.75C12.7637 9.76324 12.75 9.79079 12.75 9.83333V18.1667C12.75 18.2092 12.7637 18.2368 12.7737 18.25H14.2263C14.2363 18.2368 14.25 18.2092 14.25 18.1667V9.83333C14.25 9.79079 14.2363 9.76324 14.2263 9.75H12.7737Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 5.25C17.8321 5.25 17.25 6.06427 17.25 6.85526V18.1447C17.25 18.9357 17.8321 19.75 18.75 19.75H20.25C21.1679 19.75 21.75 18.9357 21.75 18.1447V6.85526C21.75 6.06427 21.1679 5.25 20.25 5.25H18.75ZM18.75 6.85526C18.75 6.80192 18.7671 6.76684 18.7795 6.75H20.2205C20.2329 6.76684 20.25 6.80192 20.25 6.85526V18.1447C20.25 18.1981 20.2329 18.2332 20.2205 18.25H18.7795C18.7671 18.2332 18.75 18.1981 18.75 18.1447V6.85526Z"
      fill={fill}
    />
  </svg>
);
