import { createAsyncThunk } from '@store';
import { ApiError } from '@modules';
import { network } from '@utils';

import { RolesAllData } from './Roles.schema';

export const getAll = createAsyncThunk('roles/getAll', (...[, thunkApi]) => {
  try {
    return network.get<RolesAllData>({ key: 'get_roles' }, { signal: thunkApi.signal });
  } catch (exception) {
    return thunkApi.rejectWithValue(exception as ApiError);
  }
});
