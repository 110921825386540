import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * DisclosureEntity component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    disclosureEntity: ({ moduleHeight }: { moduleHeight: number }) => ({
      position: 'absolute',
      left: '100%',
      padding: theme.spacing(3),
      backgroundColor: variables.color.primary.white,
      width: '30.4rem',
      textAlign: 'start',
      overflow: 'hidden',
      height: moduleHeight,

      ['@media (max-width: 1450px)']: {
        width: '25rem',
      },
    }),
    title: {
      marginBottom: theme.spacing(2),
    },
    item: {
      marginBottom: theme.spacing(0.5),
    },
  }),
);
