import { makeStyles, createStyles, alpha } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(
  createStyles({
    chip: ({ color }: { color: string }) => ({
      display: 'inline-flex',
      padding: '0 1.1rem',
      fontSize: variables.font.size.sm,
      lineHeight: variables.lineHeight.sm,
      textAlign: 'center',
      color,
      backgroundColor: alpha(color, 0.1),
      borderRadius: '2.4rem',
      border: `${variables.borderWidth.sm} solid ${color}`,
    }),
  }),
);
