import { ForwardedRef, forwardRef } from 'react';
import { Typography } from '@material-ui/core';

import { useSmallCaptionStyles } from './Typography.styles';
import { TypographyProps } from './Typography.props';

/**
 * Typography.SmallCaption component
 * @returns {JSX.Element}
 */
export const SmallCaption = forwardRef(
  ({ className = '', ...props }: TypographyProps, ref: ForwardedRef<HTMLElement>): JSX.Element => {
    const styles = useSmallCaptionStyles();

    return <Typography classes={styles} className={className} {...props} {...{ ref }} />;
  },
);

SmallCaption.displayName = 'Typography.SmallCaption';
