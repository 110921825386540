import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

import { TableStyles } from './Table.props';

/**
 * Table component styles
 * @returns {Function}
 */

const columnAlignMixin = {
  '&[data-align="left"]': {
    justifyContent: 'flex-start',
  },

  '&[data-align="middle"]': {
    justifyContent: 'center',
  },

  '&[data-align="right"]': {
    justifyContent: 'flex-end',
  },
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      '&[data-ui-type=tertiary]': {
        '&&': {
          '& $head': {
            border: 'none',
            borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
            padding: theme.spacing(2, 0),
          },

          '& $pagination': {
            marginTop: theme.spacing(2),
          },

          '& $record': {
            border: 'none',
            borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
            borderRadius: 'unset',
            padding: theme.spacing(2, 0),
            backdropFilter: 'none',
          },

          '& $headerTitle': {
            textTransform: 'uppercase',
            color: variables.color.primary.mediumGray,
            fontSize: variables.font.size.xxs,
            lineHeight: variables.lineHeight.xxxs,
          },

          '& $valueSingle': {
            color: variables.color.primary.darkestGray,
          },
        },
      },
    },

    title: {
      lineHeight: '4rem',
      marginBottom: variables.gap.xl,
    },

    head: ({ headBgColor, recordBorderWidth }: TableStyles) => ({
      '&&': {
        padding: theme.spacing(2),
        border: `${recordBorderWidth} solid ${variables.color.primary.lightGray}`,
        borderBottom: 'none',
        display: 'flex',
        backgroundColor: headBgColor,
        borderBottomLeftRadius: 'unset',
        borderBottomRightRadius: 'unset',
      },
    }),

    header: () => ({
      ...columnAlignMixin,
      display: 'flex',

      '&&': {
        padding: 0,
      },

      '&:not(:last-child)': {
        paddingRight: variables.gap.sm,
      },
    }),

    headerTitle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    sort: {
      width: '2rem',
      height: '2rem',
      marginRight: '0.9rem',
    },

    body: ({ records, autofit, recordHeight, recordGap, recordShadowBlur, pageSize }: TableStyles) => ({
      display: 'flex',
      flexDirection: 'column',
      ...(records.length !== 0
        ? {
            minHeight: recordHeight,
          }
        : null),
      maxHeight:
        Number.isNaN(pageSize) || autofit
          ? 'auto'
          : `calc(${recordHeight} * ${pageSize} + ${recordGap} * ${pageSize - 1} + ${recordShadowBlur})`,
      paddingBottom: recordShadowBlur,
      ...(autofit
        ? {
            overflowY: 'auto',
            marginRight: `-${variables.scrollbar.width}`,
          }
        : null),
    }),

    record: ({ recordHeight, recordShadowWidth, recordShadowBlur, recordBorderWidth, recordGap }: TableStyles) => ({
      backgroundColor: variables.color.primary.white,
      display: 'flex',
      minHeight: recordHeight,
      height: recordHeight,
      maxHeight: recordHeight,
      padding: `0 ${variables.gap.sm}`,
      border: `${recordBorderWidth} solid ${variables.color.primary.lightGray}`,
      borderTopLeftRadius: 'unset',
      borderTopRightRadius: 'unset',
      boxShadow: `${recordShadowWidth} ${recordShadowWidth} ${recordShadowBlur} ${variables.color.primary.lightPurple}`,
      transition: 'background-color 0.2s',

      '&:not(:first-child)': {
        marginTop: recordGap,
      },

      '&[data-clickable="true"]': {
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: variables.color.secondary.white,
        },
      },
    }),

    cell: () => ({
      ...columnAlignMixin,
      display: 'flex',
      alignItems: 'center',

      '&:not(:last-child)': {
        paddingRight: variables.gap.sm,
      },

      '&[data-sortable="true"]': {
        paddingLeft: variables.gap.xl,
      },
    }),

    cellMulti: {
      padding: `${variables.gap.sm} 0`,
    },

    value: {
      overflow: 'hidden',
    },

    valueSingle: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    valueMulti: {
      maxHeight: '100%',
      overflowY: 'auto',
    },

    pagination: {
      margin: `${variables.gap.md} auto 0`,
    },
  }),
);
