import { makeStyles, createStyles } from '@material-ui/core/styles';

const signalsWidth = '31.6rem';

/**
 * MindsetLogic component styles
 * @returns {Function}
 */
export const useStyles = makeStyles((theme) =>
  createStyles({
    placeholder: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(3, 0),
      width: '100%',
    },

    content: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },

    details: {
      width: `calc(100% - ${signalsWidth})`,
    },

    signals: {
      width: signalsWidth,
    },
  }),
);
