import { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { AccordionProps, Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { FormProvider, useForm } from 'react-hook-form';

import { Section, TopBarContext, TOP_BAR_CTX_VALUE } from '@layouts';
import { content, page } from '@content';
import { useAppDispatch, history } from '@store';
import { experience, modules, ExperiencePayload } from '@modules';
import { ContentSlidesheet } from '@views';
import { ExperienceNameModal, ExperienceNameModalProps } from '@routes';
import { searchQuery, parse, useLoader } from '@utils';

import { ExperienceOverview, ExperienceOverviewProps } from './ExperienceOverview';
import { MindsetLogic, MindsetLogicProps } from './MindsetLogic';
import { MindsetPrioritization, MindsetPrioritizationProps } from './MindsetPrioritization';

import {
  FormValues,
  CollapseId,
  ExperienceMindset,
  ForbiddenErrorDeployments,
  ForbiddenError,
} from './ExperienceEditor.types';
import { useStyles } from './ExperienceEditor.styles';
import { Forbidden, ForbiddenProps } from './Forbidden';

/**
 * ExperienceEditor page - /experiences/:id - view/editing of single experience.
 * @returns {JSX.Element}
 */
export const ExperienceEditor = (): JSX.Element => {
  const styles = useStyles();
  const [businessUnitId] = searchQuery.useMutualParams('businessUnit');
  const params = useParams<{ id?: string }>();
  const experienceId = Number.parseInt(params.id || '', 10);
  const [expandedPanels, setExpandedPanels] = useState<CollapseId[]>(['experience']);
  const [mindsetId, setMindsetId] = useState<ExperienceMindset['id']>();
  const [contentSlidesheetOpen, setContentSlidesheetOpen] = useState(false);
  const [experienceNameModalOpen, setExperienceNameModalOpen] = useState(false);
  const [forbiddenDeps, setForbiddenDeps] = useState<ForbiddenErrorDeployments>();

  useLoader(experience.useItemMeta(), experience.useListMeta(), experience.useCountsMeta());

  const formMethods = useForm<FormValues>({
    defaultValues: {
      name: '',
      businessUnit: {
        id: businessUnitId,
      },
      mindsets: [],
    },
  });

  const { reset, getValues, setValue, watch } = formMethods;

  const experienceName = watch('name');

  const allMindsets = watch('mindsets');

  const mindsets = useMemo(() => allMindsets.filter((item) => item.id), [allMindsets]);
  const mindsetIndex = useMemo(() => mindsets.findIndex((item) => item.id === mindsetId), [mindsets, mindsetId]);

  const dispatch = useAppDispatch();

  const [, setTopBarCtx] = useContext(TopBarContext);

  const getAccordionToggleHandler = useCallback(
    (id: CollapseId): AccordionProps['onChange'] =>
      (event, open) => {
        if (open) {
          setExpandedPanels((prev) => [...prev, id]);
        } else {
          setExpandedPanels((prev) => prev.filter((item) => item !== id));
        }
      },
    [setExpandedPanels],
  );

  const handleContentSlidesheetClose = useCallback(() => setContentSlidesheetOpen(false), []);

  const handleContentSlidesheetOpen = useCallback(() => {
    if (businessUnitId) {
      setContentSlidesheetOpen(true);
    }
  }, [setContentSlidesheetOpen, businessUnitId]);

  const toggleExperienceNameModal = useCallback(() => {
    setExperienceNameModalOpen((prev) => !prev);
  }, [setExperienceNameModalOpen]);

  const handleExperienceOverviewArchive: ExperienceOverviewProps['onArchive'] = useCallback(() => {
    // TODO: implement experience archivation per backend readiness
  }, []);

  const handleMindsetPrioritizationChange: MindsetPrioritizationProps['onChange'] = useCallback(
    (nextMindsetId) => {
      if (nextMindsetId === mindsetId) {
        setMindsetId(undefined);
      } else {
        setMindsetId(nextMindsetId);
        setExpandedPanels(['mindset']);
      }
    },
    [setMindsetId, setExpandedPanels, mindsetId],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { source: src, destination: dst } = result;

      // reorder mindsets
      if (result.reason === 'DROP' && result.type === 'MINDSET' && dst?.droppableId === 'mindsets') {
        if (src.index !== dst.index && mindsets) {
          const nextMindsets = [...mindsets];

          nextMindsets.splice(src.index, 1);
          nextMindsets.splice(dst.index, 0, mindsets[src.index]);

          setMindsetId(undefined);
          setValue('mindsets', nextMindsets);
        }
      }

      // drop content into mindset
      if (result.reason === 'DROP' && result.type === 'MODULE') {
        // assigning module content to single mindset in experience overview table
        const originModule = parse.integer(result.draggableId.replace(/^\D+/g, ''));

        if (dst) {
          const recipientModule = parse.integer(dst.droppableId);

          if (originModule && recipientModule) {
            (async () => {
              const cloneResult = await dispatch(
                modules.thunk.clone({
                  recipientModule,
                  originModule,
                }),
              );

              if (modules.thunk.clone.fulfilled.match(cloneResult)) {
                history.go(0);
              }
            })();
          }
        }
      }
    },
    [dispatch, setValue, setMindsetId, mindsets],
  );

  const setExperiences = useCallback(() => {
    (async () => {
      const result = await dispatch(
        experience.thunk.search({
          searchTerm: '',
          businessUnitId,
        }),
      );

      if (experience.thunk.search.fulfilled.match(result) && result.payload) {
        const { items: experiences } = result.payload;

        setTopBarCtx((prev) => ({
          ...prev,
          experiences,
          experienceId,
        }));
      }
    })();
  }, [dispatch, setTopBarCtx, businessUnitId, experienceId]);

  const updateExperience = useCallback(async () => {
    if (experienceId) {
      const payload = getValues() as unknown as ExperiencePayload;
      const result = await dispatch(experience.thunk.update([payload, experienceId]));

      if (experience.thunk.update.rejected.match(result)) {
        const forbidden = result.payload?.message === 'update_forbidden';
        const nextForbiddenDeps = (result.payload as unknown as ForbiddenError).deployments;

        setForbiddenDeps(forbidden ? nextForbiddenDeps : undefined);

        return false;
      }

      return true;
    }

    return false;
  }, [dispatch, getValues, setForbiddenDeps, experienceId]);

  const handleExperienceNameModalSubmit: ExperienceNameModalProps['onSubmit'] = useCallback(
    async (values) => {
      setValue('name', values.name);

      const succeed = await updateExperience();

      if (succeed) {
        toggleExperienceNameModal();
        dispatch(experience.thunk.getItem(experienceId));
        setExperiences();
      }
    },
    [dispatch, setValue, updateExperience, toggleExperienceNameModal, setExperiences, experienceId],
  );

  const handleMindsetLogicDrop: MindsetLogicProps['onDrop'] = useCallback(
    (key, signal) => {
      const operatorKey = key.replace('signal', 'operator');
      const valueKey = key.replace('signal', 'value');

      setValue(key as never, signal as never, {
        shouldTouch: true,
        shouldValidate: true,
      });

      setValue(operatorKey as never, '' as never, {
        shouldDirty: true,
        shouldTouch: true,
      });

      setValue(valueKey as never, '' as never, {
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [setValue],
  );

  const handleForbiddenCancel = useCallback(() => {
    setForbiddenDeps(undefined);
  }, [setForbiddenDeps]);

  const handleForbiddenSubmit: ForbiddenProps['onSubmit'] = useCallback(
    async (forbiddenValues) => {
      const values = getValues();
      const payload: ExperiencePayload = {
        ...forbiddenValues,
        businessUnit: {
          id: businessUnitId,
        },
        mindsets: values.mindsets.map(({ metConditionCount, conditions }, index) => ({
          index,
          name: ``,
          metConditionCount,
          conditions: conditions.map(({ signal, operator, value, required }, conditionIndex) => ({
            index: conditionIndex,
            signal: {
              id: signal?.id as number,
            },
            operator,
            value,
            required,
          })),
        })),
      };

      const result = await dispatch(experience.thunk.create(payload));

      if (experience.thunk.create.fulfilled.match(result) && result.payload) {
        const { id: newExperienceId } = result.payload;

        setForbiddenDeps(undefined);

        history.push(`${page.experiences}/${newExperienceId}${history.location.search}`);
      }
    },
    [dispatch, getValues, setForbiddenDeps, businessUnitId],
  );

  const handleMindsetLogicApply: MindsetLogicProps['onApply'] = useCallback(() => {
    updateExperience();
  }, [updateExperience]);

  const handleMindsetLogicDelete: MindsetLogicProps['onDelete'] = useCallback(() => {
    if (mindsets) {
      const nextMindsets = [...mindsets];

      nextMindsets.splice(mindsetIndex, 1);

      setValue('mindsets', nextMindsets);

      updateExperience();
    }
  }, [setValue, updateExperience, mindsets, mindsetIndex]);

  useEffect(() => {
    if (Number.isNaN(experienceId)) {
      return history.push(page.experiences);
    }

    (async () => {
      const result = await dispatch(experience.thunk.getItem(experienceId));

      if (experience.thunk.getItem.fulfilled.match(result) && result.payload) {
        if (result.payload.mindsets) {
          reset(result.payload);
        } else {
          const newObject = JSON.parse(JSON.stringify(result.payload));
          newObject.mindsets = [];

          reset(newObject);
        }
      }
    })();
  }, [dispatch, reset, experienceId]);

  useEffect(() => {
    if (businessUnitId && experienceId) {
      setExperiences();
    }
  }, [businessUnitId, experienceId, setExperiences]);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      sectionName: content.moduleGroupEditor,
      variant: 'picker',
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
    };
  }, [setTopBarCtx]);

  return (
    <Box className={styles.experienceEditor}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <ContentSlidesheet
          businessUnitId={businessUnitId}
          open={contentSlidesheetOpen}
          onClose={handleContentSlidesheetClose}
        />
        <FormProvider {...formMethods}>
          <Section range={3}>
            <MindsetPrioritization entityId={mindsetId} onChange={handleMindsetPrioritizationChange} />
          </Section>
          <Section range={9}>
            <ExperienceOverview
              expanded={expandedPanels.includes('experience')}
              onChange={getAccordionToggleHandler('experience')}
              onMapContentClick={handleContentSlidesheetOpen}
              onArchive={handleExperienceOverviewArchive}
              onEdit={toggleExperienceNameModal}
            />
            <MindsetLogic
              open={expandedPanels.includes('mindset')}
              entityId={mindsetId}
              onToggle={getAccordionToggleHandler('mindset')}
              onDrop={handleMindsetLogicDrop}
              onChange={() => {}}
              onApply={handleMindsetLogicApply}
              onDelete={handleMindsetLogicDelete}
            />
          </Section>
        </FormProvider>
      </DragDropContext>
      {experienceName && (
        <ExperienceNameModal
          open={experienceNameModalOpen}
          defaultValue={experienceName}
          onClose={toggleExperienceNameModal}
          onSubmit={handleExperienceNameModalSubmit}
        />
      )}
      {forbiddenDeps && (
        <Forbidden
          name={experienceName}
          deployments={forbiddenDeps}
          onCancel={handleForbiddenCancel}
          onSubmit={handleForbiddenSubmit}
        />
      )}
    </Box>
  );
};
