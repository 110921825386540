import { BaseSchema, BasePaginatedData } from './Base.schema';

export const state: BaseSchema = {
  requestId: '',
  status: 'idle',
  error: undefined,
  data: null,
};

export const paginagedState: BasePaginatedData<never> = {
  items: [],
  total: 0,
  currentPage: 1,
  pageCount: 1,
};
