import { BaseSyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@material-ui/core';

import { ContainerType, DivContainerState, ImageContainerState, UniversalContainerState, Wysiwyg } from '@components';
import {
  DataVisualizationListItemSchema,
  deployment,
  experience,
  ExperienceDeployment,
  ExperienceItemMindset,
  ModuleDataVisualizationStation,
  moduleDetails,
  ModuleDisclosureStation,
  modules,
} from '@modules';
import { useAppDispatch } from '@store';
import { useHistory, parse, textTemplate } from '@utils';
import { Section, TOP_BAR_CTX_VALUE, TopBarContext } from '@layouts';
import { variables } from '@styles';

import { LibrariesPanelWrapper } from '../../TemplateView/LibrariesPanel/LibrariesPanelWrapper';

import { HTMLElementWithDocument } from '../../TemplateView/SampleTemplate/SampleTemplate.types';
import { getUniqueStyles } from '../../TemplateView/SampleTemplate/SampleTemplate.utils';

import {
  ChosenImage,
  DefaultChosenTreat,
  ElemInputActiveType,
  HTMLElementWithOriginalInnerHTML,
  SingleModulesWrapper,
  SplitterData,
} from '../../TemplateView/TemplateView.types';
import { useStyles } from './TemplateEditor.styles';
import {
  DEFAULT_CHOSEN_IMAGE,
  DEFAULT_IMAGE_WRAPPER_HEIGHT,
  DEFAULT_IMAGE_WRAPPER_WIDTH,
  DEPLOYMENTS_MINDSET_DND_ID,
  Libraries,
  RightPanelElems,
  TreatmentBuilderContext,
} from '../../TemplateView/TemplateView.const';
import {
  convertWysiwygStylesToString,
  cotvertFromCamelToHyphenCase,
  getLibrariesWrapperTitle,
  getLibraryPanelComponent,
  getRedefinedWysiwygStyles,
  getSentenceCasedText,
  getBackgroundImageStyles,
  getUniversalInitialValues,
  transformStylesStringToObject,
  getDivInitialValues,
} from '../../TemplateView/TemplateView.utils';
import { content } from '@content';
import { ModuleModal } from '@routes/TemplateView/SampleTemplate/ModuleModal';
import { TemplateViewProps } from './TemplateEditor.props';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ModuleHeader, ModuleHeaderProps } from '@routes/TemplateView/SampleTemplate/ModuleHeader';
import { ModuleSettings } from '@routes/TemplateView/SampleTemplate/ModuleSettings/ModuleSettings';
import { HTMLEditor } from '@components/Wysiwyg/HTMLEditor';
import { RenameModuleModal, RenameModuleModalFormValues } from '@routes/TemplateView';
import { getClearModuleHtmlExternally } from '@routes/TemplateView/SampleTemplate/ModuleModal/ModuleModal.hooks';
import { TDVItem } from '@components/Wysiwyg/DVPanel/types';
import { messageReceiver, broadcaster } from '@utils/network/broadcast';

/**
 Viewing Deployment -
 TemplateView route component
 @returns {JSX.Element}
 */

export const TemplateEditor = ({ module, setIsTemplateEditorVisible }: TemplateViewProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    enterprise,
    businessUnit: businessUnitId = 0,
    deployment: depId,
    program,
    mailFile,
    template,
  } = history.query;

  const [chosenElemId, setChosenElemId] = useState('');
  const [chosenModuleElemId, setChosenModuleElemId] = useState('');
  const [chosenLibrary, setChosenLibrary] = useState<Libraries | null>(Libraries.IMAGES);
  const [rightPanelElem, setRightPanelElem] = useState<RightPanelElems>(RightPanelElems.MODULE_SETTINGS);
  const [editableTreatmentId, setEditableTreatmentId] = useState<number | null>(null);
  const [showAddTreatmentPanel, setShowAddTreatmentPanel] = useState(false);
  const [showModuleEditor, setShowModuleEditor] = useState(false);
  const [chosenModuleInnerHtml, setChosenModuleInnerHtml] = useState('');
  const [moduleIndex, setModuleIndex] = useState<number>(0);
  const [defaultChosenTreats, setDefaultChosenTreats] = useState<DefaultChosenTreat[]>([]);
  const [chosenElemStyles, setChosenElemStyles] = useState('');
  const [wysiwygContainerType, setWysiwygContainerType] = useState<ContainerType>('text');
  const [chosenImage, setChosenImage] = useState<ChosenImage>(DEFAULT_CHOSEN_IMAGE);
  const [chosenExperienceId, setChosenExperienceId] = useState<number | null>(null);
  const [singleModulesWrapper, setSingleModulesWrapper] = useState<SingleModulesWrapper>(null);
  const [isTreatmentTouched, setIsTreatmentTouched] = useState(false);
  const [chosenModuleId, setChosenModuleId] = useState<number | null>(null);
  const [currentExperienceId, setCurrentExperienceId] = useState<number | null>(null);
  const [isModuleChanged, setIsModuleChanged] = useState(false);
  const [isEditorChanged, setIsEditorChanged] = useState(false);
  const [showRenameModuleModal, setShowRenameModuleModal] = useState(false);
  const [newExperienceCloned, setNewExperienceCloned] = useState<SplitterData>({
    cloned: false,
    splitterId: null,
    experienceId: null,
  });
  const [deploymentsInfo, setDeploymentsInfo] = useState<ExperienceDeployment[]>([]);
  const [chosenDV, setChosenDV] = useState<DataVisualizationListItemSchema>();
  const [disclosure, setDisclosure] = useState<ModuleDisclosureStation | null>(null);
  const [experiencesIds, setExperiencesIds] = useState<number[]>([]);
  const [isTargetContentShown, setIsTargetContentShown] = useState(false);
  const [moduleHtml, setModuleHtml] = useState<string>(module.moduleHtml);
  const [elemInputActive, setElemInputActive] = useState<ElemInputActiveType>('disabled');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [editMode, setEditMode] = useState('no-code');
  const [moduleDeployments, setModuleDeployments] = useState([]);
  const experienceData = experience.useItemData();
  const [showRemoveExperienceModal, setShowRemoveExperienceModal] = useState(false);
  const [showRemoveTreatmentModal, setShowRemoveTreatmentModal] = useState(false);
  const [showRemoveWithRevisions, setShowRemoveWithRevisions] = useState(false);
  const [moduleDataVisualizationStation, setModuleDataVisualizationStation] = useState<any>({});
  const [DVItems, setDVItems] = useState<TDVItem[]>([]);
  const disclosureRef = useRef<ModuleDisclosureStation | null>(null);
  const setDisclosureRef = useCallback(
    (setterFunc: (prev: ModuleDisclosureStation | null) => ModuleDisclosureStation | null) => {
      disclosureRef.current = setterFunc(disclosureRef.current);
    },
    [],
  );
  const universalInitialValues = useMemo(
    () => getUniversalInitialValues(chosenModuleElemId, transformStylesStringToObject(chosenElemStyles)),
    [isModuleChanged, chosenModuleElemId],
  );

  const contextValues = {
    chosenElemId,
    chosenModuleElemId,
    chosenExperienceId,
    experiencesIds,
    editableTreatmentId,
    showAddTreatmentPanel,
    showModuleEditor,
    chosenModuleInnerHtml,
    moduleIndex,
    defaultChosenTreats,
    chosenElemStyles,
    wysiwygContainerType,
    chosenLibrary,
    singleModulesWrapper,
    isTreatmentTouched,
    chosenModuleId,
    currentExperienceId,
    newExperienceCloned,
    deploymentsInfo,
    isModuleChanged,
    disclosure,
    isTargetContentShown,
    elemInputActive,
    cursorPosition,
    editMode,
    showRemoveExperienceModal,
    showRemoveTreatmentModal,
    showRemoveWithRevisions,
    moduleDataVisualizationStation,
    DVItems,
    setDVItems,
    setModuleDataVisualizationStation,
    setShowRemoveWithRevisions,
    setShowRemoveTreatmentModal,
    setShowRemoveExperienceModal,
    setEditMode,
    setDisclosure,
    setDeploymentsInfo,
    setExperiencesIds,
    setNewExperienceCloned,
    setCurrentExperienceId,
    setChosenModuleId,
    setIsTreatmentTouched,
    setSingleModulesWrapper,
    setChosenElemId,
    setChosenModuleElemId,
    setChosenExperienceId,
    setEditableTreatmentId,
    setShowAddTreatmentPanel,
    setShowModuleEditor,
    setChosenModuleInnerHtml,
    setModuleIndex,
    setDefaultChosenTreats,
    setChosenElemStyles,
    setWysiwygContainerType,
    setChosenLibrary,
    setIsModuleChanged,
    setIsTargetContentShown,
    setElemInputActive,
    setCursorPosition,
  };
  const [topBarCtx, setTopBarCtx] = useContext(TopBarContext);

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      const { reason, type, source, destination } = result;

      // reorder mindsets
      if (
        reason === 'DROP' &&
        type === DEPLOYMENTS_MINDSET_DND_ID &&
        source.droppableId === DEPLOYMENTS_MINDSET_DND_ID &&
        destination?.droppableId === DEPLOYMENTS_MINDSET_DND_ID
      ) {
        await dispatch(experience.actions.moveMindset({ from: source.index, to: destination.index }));

        setIsTreatmentTouched(true);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setDisclosureRef(() => disclosure);
  }, [setDisclosureRef, disclosure]);

  //Displaying the currently selected template if the template id in the query was changed
  useEffect(() => {
    (async () => {
      if (depId) {
        const result = await dispatch(deployment.thunk.getInfoEmailTemplate(depId));

        if (deployment.thunk.getInfoEmailTemplate.fulfilled.match(result) && result.payload) {
          const selectedTmplId = result.payload.deploymentEmailTemplates?.find((item) => item.isSelected)?.id;

          if (selectedTmplId && template !== selectedTmplId) {
            history.push('deployments', {
              enterprise,
              businessUnit: businessUnitId,
              deployment: depId,
              program,
              mailFile,
              template: selectedTmplId,
            });
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterprise, businessUnitId, depId, program, mailFile, template, dispatch]);

  //removing items mindsets on template view loading
  useEffect(() => {
    dispatch(experience.actions.clearItemsMindsets());
  }, [dispatch]);

  useEffect(() => {
    setChosenModuleId(typeof module.id === 'number' ? module.id : module.editableModuleId);
  }, [module]);

  // getting Template by the deployment ID
  useEffect(() => {
    if (depId) {
      dispatch(deployment.thunk.getById(depId));
    }
  }, [depId, dispatch]);

  const handleChooseLibrary = useCallback(
    (libraryName: Libraries | null) => {
      setChosenLibrary(libraryName);
    },
    [setChosenLibrary],
  );

  const handleUniversalStyleChange = useCallback(
    (state: UniversalContainerState) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const changingElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      setChosenLibrary(Libraries.DATA_ATTRIBUTES);

      const redefinedWysiwygStyles = getRedefinedWysiwygStyles(state);

      redefinedWysiwygStyles.backgroundColor = redefinedWysiwygStyles.backgroundColor.toLowerCase();
      redefinedWysiwygStyles.color = redefinedWysiwygStyles.color.toLowerCase();

      const hyphenCasedStylesString = cotvertFromCamelToHyphenCase(
        convertWysiwygStylesToString(redefinedWysiwygStyles),
      );
      const uniqueStyles = getUniqueStyles(
        `${chosenElemStyles}; ${hyphenCasedStylesString} \nborder-color: ${variables.color.primary.mainPurple};`,
      );

      if (changingElem) {
        changingElem.setAttribute('style', uniqueStyles);

        if (redefinedWysiwygStyles.textTransform === 'sentence') {
          changingElem.innerHTML = getSentenceCasedText(changingElem?.innerHTML.trim());
        }
        if (redefinedWysiwygStyles.textTransform === 'unset') {
          changingElem.innerHTML = changingElem.getAttribute('originalInnerHTML') || changingElem.innerHTML;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chosenModuleElemId],
  );

  const handleImageStyleChange = useCallback(
    (state: ImageContainerState) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const imageElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (chosenModuleElemId !== state?.chosenModuleElemId) {
        return;
      }

      const filteredStyles = chosenElemStyles
        .split(';')
        .map((item) => item.replace('\n', ''))
        .excludeStrs(['width:', 'height:', 'background-color:', 'padding'])
        .join(';\n');

      if (imageElem && ([Libraries.IMAGES, Libraries.DATA_VISUAL].includes(chosenLibrary!) || !chosenLibrary)) {
        imageElem.setAttribute('src', state?.imageSrc || '');
        imageElem.setAttribute('style', filteredStyles);
      }

      if (imageElem) {
        imageElem.setAttribute('width', state?.width || '');
        imageElem.setAttribute('height', state?.height || '');
      }
    },
    [chosenElemStyles, chosenLibrary, chosenModuleElemId],
  );

  const handleDVStyleChange = useCallback(
    (state: { width: number; height: number }) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const imageElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (imageElem) {
        imageElem.setAttribute('width', state?.width.toString() || '');
        imageElem.setAttribute('height', state?.height.toString() || '');
      }
    },
    [chosenModuleElemId],
  );

  const handleLinkChange = useCallback(
    (linkValue: string) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const curElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (curElem.parentElement?.tagName !== 'A') {
        const curElemCopy = curElem.cloneNode() as HTMLElementWithOriginalInnerHTML;
        const linkElem = document.createElement('a');

        curElemCopy.innerHTML = curElem.innerHTML;
        linkElem.style.textDecoration = 'none';
        linkElem.setAttribute('temphref', linkValue);
        linkElem.appendChild(curElemCopy);
        curElem.replaceWith(linkElem);

        return;
      }

      curElem.parentElement.setAttribute('temphref', linkValue);
      curElem.parentElement.style.textDecoration = 'none';
    },
    [chosenModuleElemId],
  );

  const handleAltTextChange = useCallback(
    (altTextValue: string) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const curElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (curElem.parentElement?.tagName !== 'A') {
        const curElemCopy = curElem.cloneNode() as HTMLElementWithOriginalInnerHTML;
        const linkElem = document.createElement('a');

        curElemCopy.innerHTML = curElem.innerHTML;
        linkElem.style.textDecoration = 'none';
        linkElem.setAttribute('title', altTextValue);
        linkElem.appendChild(curElemCopy);
        curElem.replaceWith(linkElem);

        return;
      }

      curElem.parentElement.setAttribute('title', altTextValue);
      curElem.parentElement.style.textDecoration = 'none';
    },
    [chosenModuleElemId],
  );

  const handleDivStyleChange = useCallback(
    (state: DivContainerState) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const divElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;
      const divElemStyles = divElem?.getAttribute('style');
      const divOffsetWidth = divElem?.offsetWidth;
      const divOffsetHeight = divElem?.offsetHeight;

      divElem?.setAttribute(
        'style',
        getUniqueStyles(
          getUniqueStyles(`${divElemStyles} ${getBackgroundImageStyles(state, divOffsetWidth, divOffsetHeight)}`),
        ),
      );
    },
    [chosenModuleElemId],
  );

  const handleChooseImage = useCallback(
    (image: any) => {
      const { contentDocument } = document.getElementById('module-modal') as HTMLElementWithDocument;
      const changingElem = contentDocument.getElementById(chosenModuleElemId) as HTMLElementWithOriginalInnerHTML;

      if (image.thumbnailPath) {
        const ratio = image.width / image.height;
        const height = (250 / ratio).toString();

        const payload = {
          chosenModuleElemId: chosenModuleElemId,
          height,
          imageSrc: image.thumbnailPath,
          name: image.name,
          width: '250',
        };
        setChosenDV(image);
        setChosenImage(payload as any);
        setRightPanelElem(RightPanelElems.WYSIWYG_DATA_VISUAL);

        if (chosenModuleElemId.indexOf('img') !== -1) {
          handleImageStyleChange(payload);
        } else {
          handleDivStyleChange(payload);
        }

        changingElem.setAttribute('data-visualization-station', 'true');
        changingElem.setAttribute('dataVisualId', image.id);
        changingElem.setAttribute('dataVisualEmbdedCode', image.embedCode);
        changingElem.setAttribute('isMaxScaleEnabled', image.isMaxScaleEnabled);
      } else {
        setChosenImage(image);

        if (chosenModuleElemId.indexOf('img') !== -1) {
          handleImageStyleChange(image);
          setRightPanelElem(RightPanelElems.WYSIWYG_IMAGE);
        } else {
          handleDivStyleChange(image);
        }

        changingElem.removeAttribute('data-visualization-station');
        changingElem.removeAttribute('dataVisualId');
        changingElem.removeAttribute('dataVisualEmbdedCode');
        changingElem.removeAttribute('isMaxScaleEnabled');
        setModuleDataVisualizationStation(null);
      }
      setIsModuleChanged(true);
    },
    [
      chosenModuleElemId,
      handleImageStyleChange,
      setChosenImage,
      setChosenDV,
      setChosenLibrary,
      setModuleDataVisualizationStation,
    ],
  );

  const onEditorChange = (value: string) => {
    module.moduleHtml = value;
    setIsModuleChanged(true);
    setIsEditorChanged(true);
    setModuleHtml(value);
  };

  const handleDimensionsChange = ([width, height]: [number, number]) => {
    const payload = {
      height,
      width,
    };

    handleDVStyleChange(payload);
  };

  const getRightPanelElem = () => {
    if (editMode === 'html') {
      return <HTMLEditor isTemplateEditor={true} module={module} onEditorChange={onEditorChange} />;
    }

    switch (rightPanelElem) {
      case RightPanelElems.WYSIWYG_UNIVERSAL:
        return (
          <Wysiwyg.UniversalContainer
            isModuleChanged={isModuleChanged}
            setIsModuleChanged={setIsModuleChanged}
            initialValues={universalInitialValues}
            containerType={wysiwygContainerType}
            onChange={handleUniversalStyleChange}
            onLinkChange={handleLinkChange}
            onAltTextChange={handleAltTextChange}
          />
        );
      case RightPanelElems.WYSIWYG_IMAGE:
        return (
          <Wysiwyg.ImageContainer
            isModuleChanged={isModuleChanged}
            setIsModuleChanged={setIsModuleChanged}
            setChosenLibrary={setChosenLibrary}
            chosenImage={chosenImage}
            onChange={handleImageStyleChange}
            onLinkChange={handleLinkChange}
            onAltTextChange={handleAltTextChange}
          />
        );
      case RightPanelElems.WYSIWYG_DATA_VISUAL:
        return (
          <Wysiwyg.DVPanel
            chosenDV={chosenDV!}
            isModuleChanged={isModuleChanged}
            setIsModuleChanged={setIsModuleChanged}
            setChosenLibrary={setChosenLibrary}
            onImageChange={handleImageStyleChange}
            onDimensionsChange={handleDimensionsChange}
          />
        );
      case RightPanelElems.WYSIWYG_DIV:
        return (
          <Wysiwyg.DivContainer
            isModuleChanged={isModuleChanged}
            setIsModuleChanged={setIsModuleChanged}
            initialValues={getDivInitialValues(transformStylesStringToObject(chosenElemStyles))}
            chosenImage={chosenImage}
            onChange={handleDivStyleChange}
            setChosenLibrary={setChosenLibrary}
          />
        );
      case RightPanelElems.MODULE_SETTINGS:
        return (
          <ModuleSettings module={{ ...module, name: topBarCtx.contentModuleName }} onEditorChange={onEditorChange} />
        );
    }
  };

  useEffect(() => {
    const moduleElem = document?.getElementById('module-modal') as HTMLElementWithDocument;
    const imageElem = moduleElem?.contentDocument.getElementById(
      chosenModuleElemId,
    ) as HTMLElementWithOriginalInnerHTML;

    setChosenImage(DEFAULT_CHOSEN_IMAGE);

    if (imageElem && chosenModuleElemId.indexOf('img') !== -1) {
      setTimeout(() => {
        const imageSrc = imageElem.getAttribute('src');
        const width = Math.round(imageElem.getBoundingClientRect().width - 4);
        const height = Math.round(imageElem.getBoundingClientRect().height - 4);
        const dataVisualId = Number(imageElem.getAttribute('dataVisualId'));
        const dataVisualEmbedCode = imageElem.getAttribute('dataVisualEmbdedCode');
        const isMaxScaleEnabled = imageElem.getAttribute('isMaxScaleEnabled') === 'false' ? false : true;
        const linkParent = imageElem.parentElement?.tagName === 'A' ? imageElem.parentElement : null;
        const linkValue = linkParent?.getAttribute('href') ?? linkParent?.getAttribute('temphref') ?? '';
        const altTextValue = linkParent?.getAttribute('title') ?? '';

        if (chosenDV?.id !== dataVisualId) {
          setChosenDV({
            id: dataVisualId ?? 0,
            embedCode: dataVisualEmbedCode,
            isMaxScaleEnabled,
            width,
            imageSrc: '',
            height,
          } as any);
        }

        setChosenImage({
          id: null,
          chosenModuleElemId,
          imageSrc,
          width: width ? +width : DEFAULT_IMAGE_WRAPPER_WIDTH,
          height: height ? +height : DEFAULT_IMAGE_WRAPPER_HEIGHT,
          name: null,
          link: linkValue,
          altText: altTextValue,
        });
      }, 150);
    } else {
      setChosenImage(DEFAULT_CHOSEN_IMAGE);
    }
  }, [chosenModuleElemId, chosenDV, setChosenImage]);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      variant: 'section',
      sectionName: textTemplate(content.entityManager, { entity: content.content }),
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
      setModuleDeployments([]);
    };
  }, [setTopBarCtx, setModuleDeployments]);

  const handleModuleClose = useCallback(() => {
    setIsTemplateEditorVisible(false);
    setEditMode('no-code');
    setDisclosure(null);

    setTimeout(() => {
      broadcaster.postMessage('refresh');
    }, 500);
  }, [setIsTemplateEditorVisible]);

  const handleModuleChange = useCallback(
    (html: string, dirty: boolean) => {
      const clearModuleHtml = getClearModuleHtmlExternally('module-modal', chosenModuleElemId).moduleHtml;
      if (
        rightPanelElem === RightPanelElems.MODULE_SETTINGS &&
        moduleHtml !== clearModuleHtml &&
        clearModuleHtml !== '<body></body>' &&
        moduleHtml !== module.moduleHtml
      ) {
        setModuleHtml(clearModuleHtml);
      }
      if (elemInputActive === 'completed' && moduleHtml !== clearModuleHtml) {
        setModuleHtml(clearModuleHtml);
        setElemInputActive('disabled');
      }
    },
    [moduleHtml, elemInputActive, chosenModuleElemId, setModuleHtml, setElemInputActive],
  );

  const handleUpdateModule = async (templateName?: string, moduleInfo?: ExperienceItemMindset) => {
    const iframe = document.getElementById('module-modal') as HTMLElementWithDocument;
    const dcs = disclosureRef.current;

    if (iframe?.contentDocument) {
      iframe.contentDocument.body.style.margin = 'initial';
      iframe.contentDocument.body.style.border = 'initial';
      iframe.contentDocument.body.style.borderRadius = 'initial';
    }

    const curModuleHtml = getClearModuleHtmlExternally('module-modal', chosenModuleElemId);
    let curModulePayload: ExperienceItemMindset;

    if (!moduleInfo) {
      const curModuleInfo = await dispatch(modules.thunk.getModuleInfo({ module: chosenModuleId! }));
      curModulePayload = curModuleInfo.payload as unknown as ExperienceItemMindset;
    } else {
      curModulePayload = moduleInfo;
    }

    let curModuleDataVisualizationStation = null as any;

    if (moduleDataVisualizationStation && moduleDataVisualizationStation[chosenModuleId!]) {
      curModuleDataVisualizationStation = {
        ...moduleDataVisualizationStation[chosenModuleId!],
        id: (curModulePayload as any).moduleDataVisualizationStation?.id,
      };
    }

    if (module) {
      let result: any = await dispatch(
        moduleDetails.thunk.save({
          details: {
            templateName,
            id: module.editableModuleId ?? module.id,
            moduleHtml: curModuleHtml.moduleHtml
              .replaceAll(/\n/gm, '')
              .replaceAll('temphref', 'href')
              .replace(/\s+/g, ' '),
          },
          isDirty: true,
        }),
      );

      if (dcs?.disclosureCopy) {
        result = await dispatch(
          modules.thunk.replaceModuleDisclosureStation({
            module: chosenModuleId!,
            payload: {
              moduleDisclosureStation: {
                ...(result.payload.moduleDisclosureStation ?? curModulePayload.moduleDisclosureStation ?? {}),
                ...dcs,
                id:
                  result.payload.moduleDisclosureStation?.id ?? curModulePayload.moduleDisclosureStation?.id ?? dcs?.id,
              },
            },
          }),
        );
      } else if (result.payload.moduleDisclosureStation && !dcs?.disclosureCopy) {
        result = await dispatch(
          modules.thunk.replaceModuleDisclosureStation({
            module: chosenModuleId!,
            payload: {
              moduleDisclosureStation: null,
            },
          }),
        );
      }

      curModulePayload = result?.payload as unknown as ExperienceItemMindset;

      if (curModuleDataVisualizationStation) {
        let settings =
          curModuleDataVisualizationStation?.settings ?? curModulePayload.moduleDataVisualizationStation?.settings;

        if (templateName && curModuleDataVisualizationStation?.settings) {
          settings = curModulePayload.moduleDataVisualizationStation?.settings.filter((setting) =>
            curModuleDataVisualizationStation?.settings.find((settingItem: any) => settingItem.label === setting.label),
          ) as any[];

          settings = curModuleDataVisualizationStation.settings
            ? (settings ?? []).concat(curModuleDataVisualizationStation.settings.filter((setting: any) => !setting.id))
            : settings ?? [];
        }

        await dispatch(
          modules.thunk.replaceModuleDataVisualizationStation({
            module: chosenModuleId!,
            payload: {
              moduleDataVisualizationStation: {
                ...curModuleDataVisualizationStation,
                id: curModulePayload.moduleDataVisualizationStation?.id,
                settings,
              },
            },
          }),
        );
      } else if (!moduleDataVisualizationStation) {
        await dispatch(
          modules.thunk.replaceModuleDataVisualizationStation({
            module: chosenModuleId!,
            payload: {
              moduleDataVisualizationStation: null,
            },
          }),
        );
      }

      await dispatch(
        modules.actions.updateLibraryItemDisclosure({
          id: curModulePayload.id,
          moduleDisclosureStation: {
            ...(curModulePayload.moduleDisclosureStation ?? {}),
            ...dcs,
          },
        }),
      );

      dispatch(modules.actions.updateModule(result.payload));
      handleModuleClose();
    }
  };

  const handleModuleSave = useCallback<ModuleHeaderProps['onSave']>(async () => {
    const response = await dispatch(modules.thunk.getModuleInfo({ module: module.editableModuleId ?? module.id }));

    setModuleDeployments((response.payload as any)?.deployments);

    if (
      (response.payload as any)?.deployments?.length > 1 ||
      (response.payload as any)?.hasBeenUsedAroundTemplates ||
      experienceData?.type === 'revision' ||
      experienceData?.revisions.length
    ) {
      setShowRenameModuleModal(true);
    } else {
      handleUpdateModule(undefined, response.payload as any);
    }
  }, [moduleDataVisualizationStation, chosenModuleId, handleUpdateModule, setModuleDeployments]);

  const handleRenameModuleModalClose = useCallback(() => {
    setShowRenameModuleModal(false);
  }, [setShowRenameModuleModal]);

  const handleConfirmRenameModule = useCallback(
    async (nameData: RenameModuleModalFormValues) => {
      handleUpdateModule(nameData.name);
      handleRenameModuleModalClose();
    },
    [moduleDataVisualizationStation, chosenModuleId, handleUpdateModule, handleRenameModuleModalClose],
  );

  return (
    <>
      <Box className={styles.templateView}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <TreatmentBuilderContext.Provider value={contextValues}>
            <Section className={styles.leftPanel} range={2} gap={0}>
              <LibrariesPanelWrapper onChooseLibrary={handleChooseLibrary}>
                {getLibraryPanelComponent(chosenLibrary, false, handleChooseImage, undefined, setChosenImage)}
              </LibrariesPanelWrapper>
            </Section>
            <Section className={styles.centralPanel} range={6} gap={0}>
              {!!module && (
                <>
                  <ModuleHeader
                    style={{ height: '47px', minHeight: '47px' }}
                    dirty={isModuleChanged}
                    onSave={handleModuleSave}
                  />
                  <ModuleModal
                    modalTopPosition={10}
                    isTemplateEditor={true}
                    moduleHtmlElem={moduleHtml}
                    isEditorChanged={isEditorChanged}
                    setIsEditorChanged={setIsEditorChanged}
                    setRightPanelElem={setRightPanelElem}
                    onChange={handleModuleChange}
                    onClose={handleModuleClose}
                  />
                </>
              )}
            </Section>
            <Section className={styles.rightPanel} range={4} gap={0}>
              <Box className={styles.rightPanelContent}>{getRightPanelElem()}</Box>
            </Section>
          </TreatmentBuilderContext.Provider>
        </DragDropContext>
        {showRenameModuleModal && (
          <RenameModuleModal
            title={textTemplate(content.renameValue, { value: content.contentModule })}
            alertMessage={content.itsNotPossibleRenameContentModule}
            moduleData={{
              moduleTemplateName: `${topBarCtx.contentModuleName}` ?? '',
              deployments: moduleDeployments,
              hasBeenUsedAroundTemplates: module?.hasBeenUsedAroundTemplates,
              isRenameRequired: experienceData?.type === 'revision',
            }}
            type="module"
            onClose={handleRenameModuleModalClose}
            onSubmit={handleConfirmRenameModule}
          />
        )}
      </Box>
    </>
  );
};
