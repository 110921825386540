import { ColorPicker } from './ColorPicker';
import { DVContainer } from './DVContainer';
import { DVPanel } from './DVPanel';
import { DivContainer } from './DivContainer';
import { ImageContainer } from './ImageContainer';
import { UniversalContainer } from './UniversalContainer';

export const Wysiwyg = {
  ColorPicker,
  DivContainer,
  ImageContainer,
  UniversalContainer,
  DVContainer,
  DVPanel,
};

export * from './UniversalContainer/UniversalContainer.props';
export * from './ImageContainer/ImageContainer.props';
export * from './DivContainer/DivContainer.props';
