import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getAll } from './Roles.thunk';
import { initialState } from './Roles.state';
import { RolesSchema } from './Roles.schema';

export const reducers = {};

export const extraReducers = (builder: ActionReducerMapBuilder<RolesSchema>): void => {
  builder.addCase(getAll.pending, (state, action) => {
    if (state.all.status !== 'loading') {
      state.all.requestId = action.meta.requestId;
      state.all.data = initialState.all.data;
      state.all.status = 'loading';
    }
  });

  builder.addCase(getAll.fulfilled, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.data = action.payload || initialState.all.data;
      state.all.status = 'success';
    }
  });

  builder.addCase(getAll.rejected, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.error = action.error;
      state.all.status = 'error';
    }
  });
};
