import { ChangeEvent, useEffect, useMemo } from 'react';
import { Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { Condition } from '@modules';
import { content } from '@content';
import { func, parse } from '@utils';

import { useStyles } from './Condition.styles';
import { RequiredFieldProps } from './Condition.props';

/**
 * RequiredField component
 * @returns {JSX.Element}
 */

export const RequiredField = ({ mindsetIndex, conditionIndex, fieldName }: RequiredFieldProps): JSX.Element => {
  const styles = useStyles();

  const { setValue, trigger, watch } = useFormContext();

  const mindsetPath = useMemo(() => `mindsets.${mindsetIndex}`, [mindsetIndex]);

  const conditionPath = useMemo(() => `${mindsetPath}.condition`, [mindsetPath]);

  const requiredPath = useMemo(() => fieldName || `${conditionPath}.required`, [fieldName, conditionPath]);

  const metCountPath = useMemo(() => `${mindsetPath}.metConditionCount`, [mindsetPath]);

  const conditionsPath = useMemo(() => `${mindsetPath}.conditions`, [mindsetPath]);

  const conditions: Condition[] = watch(conditionsPath);

  const metCount = watch(metCountPath) || 0;

  const isRequired = watch(requiredPath);

  const isAlwaysRequired = useMemo(() => {
    if (fieldName) {
      return isRequired && conditions?.filter((item) => item.required)?.length === 1;
    }

    return !conditions?.length;
  }, [fieldName, isRequired, conditions]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const itemIdx = parse.integer(`${conditionIndex}`);

    if (Number.isNaN(itemIdx)) {
      setValue(requiredPath, checked);
    } else {
      const nextItems = [...conditions];

      nextItems[itemIdx] = {
        ...conditions[itemIdx],
        required: checked,
      };

      setValue(conditionsPath, nextItems);
    }

    if (!metCount) {
      setValue(metCountPath, 1);
      trigger(metCountPath);
    }
  };

  useEffect(() => {
    if (isAlwaysRequired) {
      setValue(requiredPath, true);
      trigger(requiredPath);
    }

    return func.nop;
  }, [setValue, trigger, isAlwaysRequired, requiredPath]);

  return (
    <Box>
      <Box className={styles.requiredCheckBox}>
        <FormControlLabel
          label={content.thisRequiredRule}
          classes={{
            root: isAlwaysRequired ? styles.requiredCheckboxDisabled : '',
            label: `${styles.checkboxLabel} ${isAlwaysRequired ? styles.checkboxLabelDisabled : ''}`,
          }}
          control={
            <Checkbox
              classes={{ root: styles.checkbox }}
              checked={isRequired}
              disabled={isAlwaysRequired}
              onChange={isAlwaysRequired ? func.nop : handleCheckboxChange}
            />
          }
        />
      </Box>
    </Box>
  );
};
