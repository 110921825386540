import { Modal } from '@components';
import { AttributesFeed } from '@routes/DataCenter';

import { AttributesImportModalProps } from './AttributesFeedModal.props';

/**
 * AttributesImportModal component
 * @returns {JSX.Element}
 */

export const AttributesFeedModal = ({
  businessUnit,
  businessUnitId,
  isModalOpen,
  onSuccess,
  onClose,
}: AttributesImportModalProps): JSX.Element => {
  return (
    <Modal open={!!isModalOpen} padding="0" onClose={onClose}>
      <AttributesFeed
        businessUnit={businessUnitId}
        uniqueIdKey={businessUnit?.uniqueIdKey}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </Modal>
  );
};
