import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';
import { initialState } from './Global.state';
import { reducers, extraReducers } from './Global.reducer';
import { Notification, SaveStatus } from './Global.schema';

const slice = createSlice({
  name: 'global',
  initialState,
  reducers,
  extraReducers,
});

export const global = {
  slice,
  actions: slice.actions,
  useIsLoading: (): boolean => useAppSelector(({ global: { isLoading } }) => isLoading),
  useNotifications: (): Notification[] => useAppSelector(({ global: { notifications } }) => notifications),
  useSaveStatus: (): SaveStatus => useAppSelector((state) => state.global.saveStatus),
};
