import { variables } from '@styles';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    barStackedChart: {
      display: 'flex',
      marginTop: '-7rem',
    },

    axisY: {
      display: 'flex',
      margin: '9.7rem 0 2.6rem',
      paddingRight: variables.gap.sm,
    },

    legendY: {
      alignSelf: 'center',
      writingMode: 'vertical-lr',
      transform: 'rotate(180deg)',
      marginLeft: variables.gap.xs,
    },

    milestonesY: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
    },

    title: {
      textAlign: 'center',
      transform: 'translateY(7rem)',
    },

    bar: {
      overflowX: 'scroll',
      marginTop: `-${variables.gap.lg}`,
      paddingTop: '10.7rem',
    },
  }),
);
