import { format } from '@utils';
import { content } from '@content';
import { Deployment } from '@modules';
import { SelectOption } from '@components';

export const findDepById = (items: Deployment[], id: number): Deployment | undefined =>
  items.find((item) => item.id === id);

const sortDepsByDate = (a: Deployment, b: Deployment) => (new Date(a.waveDate) < new Date(b.waveDate) ? -1 : 1);

export const filterDepsByProgram = (items: Deployment[], programId: number): Deployment[] =>
  items.filter((item) => item.program.id === programId).sort(sortDepsByDate);

export const getProgramOptions = (items: Deployment[]): SelectOption[] =>
  items.length === 0
    ? [{ id: '', label: `${content.no} ${content.programs}` }]
    : items.reduce((options, item) => {
        const { id, name } = item.program;

        if (!options.find((option) => option.id === `${id}`)) {
          options.push({ id: `${id}`, label: name });
        }

        return options;
      }, [] as SelectOption[]);

export const getBreadcrumbs = (picked?: Deployment): string =>
  picked ? [picked.createdAt, picked.updatedAt].map((date) => format.date(date)).join(' / ') : '';

export const getDateOptions = (items: Deployment[]): SelectOption[] =>
  items.length === 0
    ? [{ id: '', label: `${content.no} ${content.dates}` }]
    : [...items].sort(sortDepsByDate).map((item) => ({ id: `${item.id}`, label: item.name }));
