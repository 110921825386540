import { Typography } from '@material-ui/core';

import { useStyles } from './Label.styles';
import { LabelProps } from './Label.types';

/**
 * Label component. Contains "badge" and "code" variants.
 * @returns {JSX.Element}
 */
export const Label = ({
  variant,
  color = 'neutral',
  children,
  title = '',
  className = '',
}: LabelProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Typography noWrap display="inline" title={title} className={`${styles[variant]} ${styles[color]} ${className}`}>
      {children}
    </Typography>
  );
};
