import { createSlice } from '@reduxjs/toolkit';

import { useAppSelector } from '@store';

import * as thunk from './MailParts.thunk';
import { initialState } from './MailParts.state';
import { reducers, extraReducers } from './MailParts.reducer';
import { MailPartsByProgramSchema, MailPartsByProgramDataSchema } from './MailParts.schema';

export * from './MailParts.schema';

const slice = createSlice({
  name: 'mailParts',
  initialState,
  reducers,
  extraReducers,
});

export const mailParts = {
  thunk,
  slice,
  actions: slice.actions,
  useMailPartsByProgramMeta: (): Omit<MailPartsByProgramSchema, 'data'> =>
    useAppSelector(
      ({
        mailParts: {
          mailPartsByProgram: { data, ...meta },
        },
      }) => meta,
    ),
  useMailPartsByProgramData: (): MailPartsByProgramDataSchema =>
    useAppSelector(
      ({
        mailParts: {
          mailPartsByProgram: { data },
        },
      }) => data,
    ),
};
