import { ModulesSearchParams } from '@modules';

/**
 * ContentCenter component constants
 * @returns {Variables}
 */

export const ITEMS_PER_PAGE = 20;

export const SEARCH_QUERY_INIT: ModulesSearchParams = {
  businessUnitId: NaN,
  page: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  keyword: '',
  searchType: 'deployment',
  onlyRecent: false,
  sortBy: 'id',
};
