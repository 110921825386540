import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    journeyLibrary: {
      background: 'rgba(255, 255, 255, 0.7)',
      borderRadius: variables.borderRadius.sm,
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      padding: '2rem 1rem',
    },
    header: {
      display: 'flex',
      padding: '0 1rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2.1rem',
      height: '3.4rem',
    },
    title: {
      fontWeight: variables.font.weight.semiBold,
    },
    toggle: {
      maxWidth: '12rem',
    },
    select: {
      '&&': {
        maxWidth: '16.1rem',
      },
    },
    menuItem: {
      padding: `${variables.gap.lg} ${variables.gap.sm}`,
    },
    journeyListWrapper: {
      overflowY: 'auto',
      paddingRight: variables.gap.sm,
      marginRight: `-${variables.gap.sm}`,
      flex: '1',
    },
  }),
);

export const useListStyles = makeStyles(() =>
  createStyles({
    loader: {
      textAlign: 'center',
      padding: variables.gap.xs,
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
    },
  }),
);

export const useItemStyles = makeStyles(() =>
  createStyles({
    journeyListItem: {
      background: variables.color.primary.white,
      marginBottom: variables.gap.sm,
      border: `${variables.borderWidth.sm} solid transparent`,
      borderRadius: variables.borderRadius.sm,
      height: '9.1rem',
      padding: '1.5rem 2rem 2rem',
      display: 'flex',
      alignItems: 'center',
      '&[data-isdragging="true"]': {
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightPurple}`,
      },
    },
    dragIcon: {
      marginRight: '1.8rem',
    },
    journeyName: {
      flex: '1 1 auto',
      display: 'grid',
    },
    journeyNameLabel: {
      marginBottom: variables.gap.xs,
    },
    collapseBtn: {
      width: '4rem',
      minWidth: '4rem',
      height: '4rem',
      transition: 'transform 0.3s',
      '&[data-collapsed="true"]': {
        transform: 'rotate(180deg)',
      },
      '& svg': {
        pointerEvents: 'none',
      },
    },
  }),
);
