import { User, Permission, PermissionKey } from '@modules';

const hasPermission = (user: User, permissionKey: PermissionKey): boolean =>
  !!user.permissions?.includes(Permission[permissionKey]);

export const hasSomePermissions = (user: User, ...permissionKeys: PermissionKey[]): boolean =>
  permissionKeys.some((permissionKey) => hasPermission(user, permissionKey));

export const hasAllPermissions = (user: User, ...permissionKeys: PermissionKey[]): boolean =>
  permissionKeys.every((permissionKey) => hasPermission(user, permissionKey));

export const hasEachPermission = (user: User, ...permissionKeys: PermissionKey[]): boolean[] =>
  permissionKeys.map((permissionKey) => hasPermission(user, permissionKey));
