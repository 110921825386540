import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { ImageLibrarySchema } from './ImageLibrary.schema';
import { checkIfExists, create, getImageLibraryItemContent, search } from './ImageLibrary.thunk';
import { initialState } from './ImageLibrary.state';

export const reducers = {
  resetLibrary: (state: ImageLibrarySchema): void => {
    state.all = initialState.all;
  },
  setSearch: (state: ImageLibrarySchema, { payload }: PayloadAction<string | null>): void => {
    state.search.data = payload;
  },
};

export const extraReducers = (builder: ActionReducerMapBuilder<ImageLibrarySchema>): void => {
  builder.addCase(search.pending, (state, action) => {
    if (state.all.status !== 'loading') {
      state.all.requestId = action.meta.requestId;
      state.all.status = 'loading';
    }
  });

  builder.addCase(search.fulfilled, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.data = action.payload
        ? {
            ...action.payload,
            items: state.all.data.items
              .filter((item) => !action.payload?.items.find((payloadItem) => payloadItem.id === item.id))
              .concat(action.payload.items),
          }
        : initialState.all.data;
      state.all.status = 'success';
    }
  });

  builder.addCase(search.rejected, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.error = action.error;
      state.all.status = 'error';
    }
  });

  builder.addCase(getImageLibraryItemContent.pending, (state, action) => {
    if (state.itemContent.status !== 'loading') {
      state.itemContent.requestId = action.meta.requestId;
      state.itemContent.status = 'loading';
    }
  });

  builder.addCase(getImageLibraryItemContent.fulfilled, (state, action) => {
    if (state.itemContent.status === 'loading' && state.itemContent.requestId === action.meta.requestId) {
      state.itemContent.requestId = initialState.itemContent.requestId;
      state.itemContent.data = action.payload ?? initialState.itemContent.data;
      state.itemContent.status = 'success';
    }
  });

  builder.addCase(getImageLibraryItemContent.rejected, (state, action) => {
    if (state.itemContent.status === 'loading' && state.itemContent.requestId === action.meta.requestId) {
      state.itemContent.requestId = initialState.itemContent.requestId;
      state.itemContent.error = action.error;
      state.itemContent.status = 'error';
    }
  });

  builder.addCase(checkIfExists.pending, (state, action) => {
    if (state.all.status !== 'loading') {
      state.all.requestId = action.meta.requestId;
      state.all.status = 'loading';
    }
  });

  builder.addCase(checkIfExists.fulfilled, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.status = 'success';
    }
  });

  builder.addCase(checkIfExists.rejected, (state, action) => {
    if (state.all.status === 'loading' && state.all.requestId === action.meta.requestId) {
      state.all.requestId = initialState.all.requestId;
      state.all.error = action.error;
      state.all.status = 'error';
    }
  });

  builder.addCase(create.pending, (state, action) => {
    if (state.entity.status !== 'loading') {
      state.entity.requestId = action.meta.requestId;
      state.entity.data = initialState.entity.data;
      state.entity.status = 'loading';
    }
  });

  builder.addCase(create.fulfilled, (state, action) => {
    state.entity.requestId = initialState.entity.requestId;
    state.entity.status = 'success';

    if (action.payload && action.payload.itemsPerPage === state.all.data.items.length) {
      state.all.data.items = [
        action.payload.data,
        ...state.all.data.items.filter((item, index) => index < state.all.data.items.length - 1),
      ];
    } else {
      state.all.data.items = [action.payload.data, ...state.all.data.items];
    }
  });

  builder.addCase(create.rejected, (state, action) => {
    if (state.entity.status === 'loading' && state.entity.requestId === action.meta.requestId) {
      state.entity.requestId = initialState.entity.requestId;
      state.entity.error = action.error;
      state.entity.status = 'error';
    }
  });
};
