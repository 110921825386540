import { Article, MailBuilderTemplateRow } from '@modules';

export const createArticleRow = ({
  title,
  creator,
  text,
  article_url,
  image_url,
}: Article): MailBuilderTemplateRow => ({
  container: {
    style: {
      'background-color': 'transparent',
      'background-image': 'none',
      'background-repeat': 'no-repeat',
      'background-position': 'top left',
    },
  },
  content: {
    style: {
      color: '#000000',
      width: '500px',
      'background-image': 'none',
      'background-repeat': 'no-repeat',
      'background-position': 'top left',
    },
    computedStyle: {
      rowColStackOnMobile: true,
      rowReverseColStackOnMobile: false,
    },
  },
  columns: [
    {
      style: {
        'background-color': 'transparent',
        'border-bottom': '0px solid transparent',
        'border-left': '0px solid transparent',
        'border-right': '0px solid transparent',
        'border-top': '0px solid transparent',
        'padding-bottom': '5px',
        'padding-left': '0px',
        'padding-right': '0px',
        'padding-top': '5px',
      },
      modules: [
        {
          type: 'mailup-bee-newsletter-modules-image',
          descriptor: {
            image: {
              alt: title,
              src: image_url,
              href: article_url,
              width: '270px',
              height: '187px',
            },
            style: {
              width: '100%',
              'padding-top': '0px',
              'padding-right': '0px',
              'padding-bottom': '0px',
              'padding-left': '0px',
            },
            computedStyle: {
              class: 'center autowidth',
              width: '166.66666666666666px',
              hideContentOnMobile: false,
            },
          },
          // uuid: '13e579a8-1338-4661-9f68-36209923b6af',
        },
        {
          type: 'mailup-bee-newsletter-modules-button',
          descriptor: {
            button: {
              label:
                '<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 28px;" data-mce-style="font-size: 14px; line-height: 28px;"><p style="font-size: 16px; line-height: 32px; word-break: break-word;" data-mce-style="font-size: 16px; line-height: 32px;">Read More</p></div>',
              href: article_url,
              style: {
                'font-family': 'inherit',
                'background-color': '#3AAEE0',
                'border-radius': '4px',
                'border-top': '0px solid transparent',
                'border-right': '0px solid transparent',
                'border-bottom': '0px solid transparent',
                'border-left': '0px solid transparent',
                color: '#ffffff',
                'line-height': '200%',
                'padding-top': '5px',
                'padding-right': '5px',
                'padding-bottom': '5px',
                'padding-left': '5px',
                width: 'auto',
                'max-width': '100%',
              },
            },
            style: {
              'text-align': 'left',
              'padding-top': '10px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px',
            },
            computedStyle: {
              width: 89,
              height: 42,
              hideContentOnMobile: false,
            },
          },
          // uuid: '97f3b996-821f-49dd-b80a-5db681e97372',
        },
      ],
      'grid-columns': 4,
      // uuid: 'a51cc228-5005-47ed-9079-7562502612b6',
    },
    {
      style: {
        'background-color': 'transparent',
        'border-bottom': '0px solid transparent',
        'border-left': '0px solid transparent',
        'border-right': '0px solid transparent',
        'border-top': '0px solid transparent',
        'padding-bottom': '5px',
        'padding-left': '0px',
        'padding-right': '0px',
        'padding-top': '5px',
      },
      modules: [
        {
          type: 'mailup-bee-newsletter-modules-heading',
          descriptor: {
            heading: {
              title: 'h1',
              text: `<strong>${title}</strong>`,
              style: {
                color: '#555555',
                'font-size': '23px',
                'font-family': 'inherit',
                'link-color': '#E01253',
                'line-height': '120%',
                'text-align': 'left',
                direction: 'ltr',
                'font-weight': 'normal',
                'letter-spacing': '0px',
              },
            },
            style: {
              width: '100%',
              'text-align': 'left',
              'padding-top': '0px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px',
            },
            computedStyle: { width: 52, height: 42 },
          },
          // uuid: 'f38e20ce-3665-4e20-a382-d5f4642fb34e',
        },
        {
          type: 'mailup-bee-newsletter-modules-text',
          descriptor: {
            text: {
              html: `<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px;">${creator}</p></div>`,
              style: {
                color: '#555555',
                'line-height': '120%',
                'font-family': 'inherit',
              },
              computedStyle: { linkColor: '#0068a5' },
            },
            style: {
              'padding-top': '10px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px',
            },
            computedStyle: { hideContentOnMobile: false },
          },
          // uuid: '2a57660d-7acb-4ca7-bf2c-6fddd11740cf',
        },
        {
          type: 'mailup-bee-newsletter-modules-text',
          descriptor: {
            text: {
              html: `<div class="txtTinyMce-wrapper" style="font-size: 14px; line-height: 16px;" data-mce-style="font-size: 14px; line-height: 16px;"><p style="font-size: 14px; line-height: 16px; word-break: break-word;" data-mce-style="font-size: 14px; line-height: 16px;">${text}</p></div>`,
              style: {
                color: '#555555',
                'line-height': '120%',
                'font-family': 'inherit',
              },
              computedStyle: { linkColor: '#0068a5' },
            },
            style: {
              'padding-top': '10px',
              'padding-right': '10px',
              'padding-bottom': '10px',
              'padding-left': '10px',
            },
            computedStyle: { hideContentOnMobile: false },
          },
          // uuid: '3b778b90-1909-4262-b45f-a4825dbd1778',
        },
      ],
      'grid-columns': 8,
      // uuid: '38b01257-c537-4380-bf0d-6ce972b9b21d',
    },
  ],
  type: 'two-columns-3-9-empty',
  // uuid: '01ef7b36-6df5-48e1-ba86-3b19fbdfae10',
});
