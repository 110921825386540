import { base } from '@modules';
import { ExperienceSchema } from './Experience.schema';

export const initialState: ExperienceSchema = {
  item: {
    ...base.state,
    data: null,
  },
  list: { ...base.state, data: { ...base.paginagedState } },
  counts: {
    ...base.state,
    data: [
      {
        match: 0,
        total: 0,
      },
    ],
  },
  mindsets: {
    ...base.state,
    data: [],
  },
  overview: {
    ...base.state,
    data: null,
  },
};
