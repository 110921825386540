import loadScript from 'load-script';
import { pipe } from 'fp-ts/lib/function';
import * as E from 'fp-ts/lib/Either';
import beeActions from './utils/Constants';
import { fetchToken } from './services/api';
import { eitherCanExecuteAction, eitherCheckJoinParams, eitherCheckStartParams } from './utils/utils';
const BEEJS_URL = 'https://app-rsrc.getbee.io/plugin/BeePlugin.js';
const API_AUTH_URL = 'https://auth.getbee.io/apiauth';
let beeLoaderUrl = {
    beePluginUrl: '',
    authUrl: ''
};
const load = (bee) => {
    loadScript(beeLoaderUrl.beePluginUrl, err => {
        if (err) {
            throw new Error('BeePlugin.js is not reachable');
        }
        return bee();
    });
};
const { LOAD, SAVE, SEND, PREVIEW, SAVE_AS_TEMPLATE, TOGGLE_STRUCTURE, TOGGLE_COMMENTS, TOGGLE_PREVIEW, TOGGLE_MERGETAGS_PREVIEW, SHOW_COMMENT, RELOAD, LOAD_WORKSPACE, LOAD_STAGE_MODE, LOAD_CONFIG } = beeActions;
class Bee {
    constructor(token, urlConfig = { authUrl: API_AUTH_URL, beePluginUrl: BEEJS_URL }) {
        this.getToken = (clientId, clientSecret, urlConfig = { authUrl: API_AUTH_URL, beePluginUrl: BEEJS_URL }) => {
            beeLoaderUrl = urlConfig;
            if (this.token) {
                throw new Error('Toker already declared');
            }
            return fetchToken({ authUrl: urlConfig.authUrl, clientId, clientSecret })
                .then(res => {
                this.token = res.data;
                return res.data;
            });
        };
        this.start = (config, template, bucketDir, options) => {
            const { bee, token } = this;
            return pipe(eitherCheckStartParams(config, template, token), E.fold(({ message }) => new Promise(reject => reject(message)), () => new Promise(resolve => {
                bee(() => BeePlugin.create(token, Object.assign(Object.assign({}, config), { startOrigin: '[npm] @mailupinc/bee-plugin' }), instance => {
                    this.instance = instance;
                    instance.start(template, options);
                    resolve(instance);
                }, bucketDir));
            })));
        };
        this.join = (config, sessionId, bucketDir) => {
            const { bee, token } = this;
            return pipe(eitherCheckJoinParams(config, sessionId, this.token), E.fold(({ message }) => { throw new Error(message); }, () => new Promise(resolve => {
                bee(() => BeePlugin.create(token, config, instance => {
                    this.instance = instance;
                    instance.join(sessionId);
                    resolve(instance);
                }, bucketDir));
            })));
        };
        this.executeAction = (action, param = {}, options = {}) => {
            const { instance } = this;
            pipe(eitherCanExecuteAction(instance, action), E.fold(({ message }) => { throw new Error(message); }, () => instance[action](param, options)));
        };
        this.load = (template) => this.executeAction(LOAD, template);
        this.save = () => this.executeAction(SAVE);
        this.saveAsTemplate = () => this.executeAction(SAVE_AS_TEMPLATE);
        this.send = () => this.executeAction(SEND);
        this.preview = () => this.executeAction(PREVIEW);
        this.toggleStructure = () => this.executeAction(TOGGLE_STRUCTURE);
        this.togglePreview = () => this.executeAction(TOGGLE_PREVIEW);
        this.toggleComments = () => this.executeAction(TOGGLE_COMMENTS);
        this.toggleMergeTagsPreview = () => this.executeAction(TOGGLE_MERGETAGS_PREVIEW);
        this.showComment = (comment) => this.executeAction(SHOW_COMMENT, comment);
        this.reload = (template, options) => this.executeAction(RELOAD, template, options);
        this.loadWorkspace = (type) => this.executeAction(LOAD_WORKSPACE, type);
        this.loadStageMode = (args) => this.executeAction(LOAD_STAGE_MODE, args);
        this.loadConfig = (args) => this.executeAction(LOAD_CONFIG, args);
        beeLoaderUrl = urlConfig;
        this.bee = (call) => load(() => call());
        this.token = token || '';
        this.instance = null;
    }
}
export default Bee;
