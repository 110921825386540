import { FC, ReactElement, SVGProps } from 'react';

export const Dart: FC<SVGProps<SVGElement>> = ({ className, strokeWidth = '3' }): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M16.9102 30.6855L27.5935 20.0022L16.9102 9.31882"
      stroke="#003B5C"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line x1="26.5" y1="20.5" x2="3.5" y2="20.5" stroke="#003B5C" strokeWidth={strokeWidth} strokeLinecap="round" />
  </svg>
);
