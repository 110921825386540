import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * MyAccount component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    myAccount: {
      background: variables.color.primary.white,
      padding: theme.spacing(3),
      borderRadius: variables.borderRadius.sm,
    },

    row: {
      marginTop: theme.spacing(2),
    },

    upload: {
      margin: theme.spacing(1.25, 0, 0, 3),
    },

    delete: {
      margin: theme.spacing(1.25, 0, 0, 3),
    },
  }),
);
