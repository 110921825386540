import { format } from '@utils';
import { UserRole } from '@modules';

import { RoleName } from './Roles.schema';

const ROLE_SYSTEM_ADMIN: RoleName = 'ROLE_SUPER_ADMIN';

const NAME_SYSTEM_ADMIN = 'System Admin';

const NAME_BASE_PART = 'ROLE';

const NAME_DELIMETER = '_';

const NAME_PREFIX = `${NAME_BASE_PART}${NAME_DELIMETER}`;

const LABEL_DELIMETER = ' ';

export const nameToLabel = (role: UserRole): string => {
  if (role.name === ROLE_SYSTEM_ADMIN) {
    return NAME_SYSTEM_ADMIN;
  }

  return format.capitalize(role.name.replace(NAME_PREFIX, ''), NAME_DELIMETER, LABEL_DELIMETER);
};

export const labelToName = (label: string): string => {
  if (label === NAME_SYSTEM_ADMIN) {
    return ROLE_SYSTEM_ADMIN;
  }

  return `${NAME_PREFIX}${label.toUpperCase().split(LABEL_DELIMETER).join(NAME_DELIMETER)}`;
};
