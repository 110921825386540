import { ChangeEvent, useCallback, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { content } from '@content';
import { textTemplate, useWebTitleDelayed } from '@utils';
import { businessUnitPrograms, enterprise, ProgramCreatePayload } from '@modules';
import { Confirm, Select, SelectOptions, TextField, Typography } from '@components';
import { useAppDispatch } from '@store';

import { useStyles } from './CreateProgram.styles';
import { CreateProgramProps } from './CreateProgram.props';

export const CreateProgram = ({ onClose }: CreateProgramProps): JSX.Element => {
  const styles = useStyles();

  useWebTitleDelayed({
    parts: [
      textTemplate(content.entityManager, { entity: content.deployment }),
      textTemplate(content.createValue, { value: content.program }),
    ],
  });

  const dispatch = useAppDispatch();

  const enterpriseListInit = enterprise.useListData();

  const { handleSubmit, control, watch, setValue, clearErrors } = useForm<ProgramCreatePayload>({
    defaultValues: { name: '' },
  });

  const enterpriseGroup = watch('enterpriseGroup');

  const enterpriseList = useMemo(
    () => enterpriseListInit.filter((item) => item.businessUnits.length !== 0),
    [enterpriseListInit],
  );

  const enterpriseOptions = useMemo<SelectOptions<number>>(
    () =>
      enterpriseList.map((item) => ({
        id: item.id,
        label: item.name,
      })),
    [enterpriseList],
  );

  const businessUnitOptions = useMemo<SelectOptions<number>>(
    () =>
      enterpriseList
        .find((item) => item.id === enterpriseGroup)
        ?.businessUnits.map((item) => ({
          id: item.id,
          label: item.name,
        })) || [],
    [enterpriseList, enterpriseGroup],
  );

  const handleClientChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      clearErrors('businessUnit');
      setValue('businessUnit', +(event.target.value as number));
    },
    [clearErrors, setValue],
  );

  const handleFormSubmit: SubmitHandler<ProgramCreatePayload> = useCallback(
    async (payload) => {
      const result = await dispatch(businessUnitPrograms.thunk.create(payload));

      if (result.payload && businessUnitPrograms.thunk.create.fulfilled.match(result)) {
        onClose();
      }
    },
    [onClose, dispatch],
  );

  return (
    <Confirm
      open
      title={textTemplate(content.createValue, { value: content.program })}
      submitLabel={textTemplate(content.createValue, { value: content.program })}
      skipSubmitArrow
      onCancel={onClose}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Typography.Body className={styles.warning}>{content.createProgramWarning}</Typography.Body>
      <Controller
        name="enterpriseGroup"
        control={control}
        rules={{ required: content.enterpriseGroup }}
        render={({ field: { ref, ...field }, fieldState }) => (
          <Select
            {...field}
            inputRef={ref}
            required
            templated
            label={content.enterpriseGroup}
            placeholder={content.enterpriseGroup}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            options={enterpriseOptions}
          />
        )}
      />
      <Controller
        name="businessUnit"
        control={control}
        rules={{ required: content.businessUnit }}
        render={({ field: { ref, ...field }, fieldState }) => (
          <Select
            {...field}
            required
            templated
            inputRef={ref}
            label={content.businessUnit}
            placeholder={content.businessUnit}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            options={businessUnitOptions}
            onChange={handleClientChange}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        rules={{ required: content.programName }}
        render={({ field: { ref, ...field }, fieldState }) => (
          <TextField
            {...field}
            inputRef={ref}
            required
            templated
            label={content.programName}
            placeholder={content.programName}
            error={fieldState.invalid}
            hint={fieldState.error?.message}
          />
        )}
      />
    </Confirm>
  );
};
