export const Resize: React.FC = (): React.ReactElement => (
  <svg width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6.3125C0 2.99879 2.68629 0.3125 6 0.3125H22C25.3137 0.3125 28 2.99879 28 6.3125V24.3125C28 27.6262 25.3137 30.3125 22 30.3125H6C2.68629 30.3125 0 27.6262 0 24.3125V6.3125Z"
      fill="white"
    />
    <path
      d="M16 9.8125H19.5V13.3125M19.1803 10.1312L15.5 13.8125M12 20.8125H8.5V17.3125M8.81969 20.4937L12.5 16.8125"
      stroke="#494949"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 1.3125H22V-0.6875H6V1.3125ZM27 6.3125V24.3125H29V6.3125H27ZM22 29.3125H6V31.3125H22V29.3125ZM1 24.3125V6.3125H-1V24.3125H1ZM6 29.3125C3.23858 29.3125 1 27.0739 1 24.3125H-1C-1 28.1785 2.13401 31.3125 6 31.3125V29.3125ZM27 24.3125C27 27.0739 24.7614 29.3125 22 29.3125V31.3125C25.866 31.3125 29 28.1785 29 24.3125H27ZM22 1.3125C24.7614 1.3125 27 3.55108 27 6.3125H29C29 2.44651 25.866 -0.6875 22 -0.6875V1.3125ZM6 -0.6875C2.13401 -0.6875 -1 2.44651 -1 6.3125H1C1 3.55108 3.23858 1.3125 6 1.3125V-0.6875Z"
      fill="#DADEE2"
    />
  </svg>
);
