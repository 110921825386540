import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * AttributesImport component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(2, 3),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    topTitle: {
      marginBottom: theme.spacing(0.5),
    },

    body: {
      padding: theme.spacing(3, 3, 4),
      maxHeight: '44rem',
      overflowY: 'auto',
      overflowX: 'hidden',
    },

    description: {
      paddingBottom: theme.spacing(3),
    },

    attributeRow: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: theme.spacing(1, 0),
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,

      '&:last-child': {
        borderBottom: 'unset',
      },
    },

    title: {
      marginBottom: variables.gap.lg,
    },

    key: {
      flex: '0 0 3.6rem',
    },

    keyCol: {
      display: 'flex',
      alignItems: 'center',
      height: '3.2rem',
    },

    fieldName: {
      flex: '0 0 25rem',
      marginRight: theme.spacing(2),
    },

    fieldNameCol: {
      display: 'flex',
      alignItems: 'center',
      height: '3.2rem',
    },

    attributeType: {
      flex: '0 0 25rem',
      marginRight: theme.spacing(2),
    },

    attributeName: {
      flex: '0 0 25rem',
    },

    radioBtn: {
      padding: 0,
    },

    name: {
      color: variables.color.primary.darkestGray,
    },

    attrSelect: {
      '& .MuiSelect-select': {
        display: 'flex',
        paddingRight: theme.spacing(5),
      },
    },

    topRowMenuItem: {
      '&&': {
        padding: theme.spacing(0, 2),
        cursor: 'default',

        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },

    topRow: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      padding: theme.spacing(0.5, 0),
    },

    topRowAddIcon: {
      marginRight: theme.spacing(1.16),

      '&.hide': {
        display: 'none',
      },
    },

    topRowCheckBtn: {
      minWidth: 'unset',
      transition: 'opacity 0.25s',
      padding: theme.spacing(1.16),
      marginLeft: theme.spacing(0.5),
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      display: 'none',

      '&.show': {
        display: 'inline-flex',
      },

      '&:hover': {
        backgroundColor: 'transparent',
        opacity: '0.6',
      },
    },

    topRowInput: {
      flexGrow: 1,

      '& .MuiInput-root': {
        padding: 0,
        border: 'none',
      },

      '&.focus .MuiInput-root': {
        border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
      },

      '& .MuiBox-root': {
        border: 'none',
      },

      '& input': {
        padding: theme.spacing(1),
      },

      '& input::placeholder': {
        color: variables.color.primary.darkestGray,
        fontWeight: variables.font.weight.bold,
      },

      '&.focus input::placeholder': {
        color: variables.color.primary.halfMediumGray,
        fontWeight: variables.font.weight.medium,
      },
    },

    attributeMenuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    optionName: {
      fontWeight: variables.font.weight.bold,
      color: variables.color.primary.darkestGray,
    },

    optionText: {
      marginLeft: theme.spacing(0.5),
    },

    fileInputTextField: {
      '& .MuiInputBase-root': {
        fontSize: variables.font.size.sm,
        paddingTop: variables.gap.xs,
        paddingLeft: variables.gap.lg,
      },
    },

    errorMsg: {
      color: variables.color.secondary.orange,
      fontWeight: variables.font.weight.bold,
      marginBottom: variables.gap.sm,
    },

    footer: {
      textAlign: 'right',
      padding: theme.spacing(2, 3),
      borderTop: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    cancelBtn: {
      marginRight: theme.spacing(1.25),
    },
  }),
);
