import { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { formatISO } from 'date-fns';

import { Typography, DatePicker, Icon } from '@components';
import { content } from '@content';

import { BetweenFieldProps } from './BetweenField.props';
import { useStyles } from './BetweenField.styles';
import { parse } from '@utils';

export const BetweenField = ({
  mindsetIndex,
  defaultValue,
  fieldName = `mindsets.${mindsetIndex}.condition.value`,
}: BetweenFieldProps): JSX.Element => {
  const styles = useStyles();
  const { setValue, watch, trigger } = useFormContext();

  const srcKey = useMemo(() => `${fieldName}.from`, [fieldName]);

  const dstKey = useMemo(() => `${fieldName}.to`, [fieldName]);

  const fromDate = watch(srcKey);

  const handeDateChange = useCallback(
    (key: string) => (newDate: Date | null) => {
      if (newDate) {
        setValue(key, formatISO(newDate, { representation: 'date' }));
      }
      trigger(key);
    },
    [setValue, trigger],
  );

  return (
    <Box className={styles.betweenField}>
      <Controller
        name={srcKey}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <DatePicker
            fullWidth
            inputVariant="outlined"
            InputProps={{
              startAdornment: <Icon.Calendar width={15.5} height={15.5} withoutDate />,
            }}
            defaultValue={parse.dateToMui(defaultValue?.from)}
            variant="inline"
            autoOk
            format="MM/dd/yyyy"
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            value={parse.dateToMui(field.value)}
            placeholder={content.startDate}
            onChange={handeDateChange(srcKey)}
          />
        )}
      />
      <Typography.Caption component="span" align="center" className={styles.andLabel}>
        {content.and}
      </Typography.Caption>
      <Controller
        name={dstKey}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <DatePicker
            fullWidth
            inputVariant="outlined"
            InputProps={{
              startAdornment: <Icon.Calendar width={15.5} height={15.5} withoutDate />,
            }}
            variant="inline"
            defaultValue={parse.dateToMui(defaultValue?.to)}
            autoOk
            format="MM/dd/yyyy"
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            value={parse.dateToMui(field.value)}
            placeholder={content.endDate}
            minDate={fromDate}
            onChange={handeDateChange(dstKey)}
          />
        )}
      />
    </Box>
  );
};

BetweenField.displayName = 'BetweenField';
