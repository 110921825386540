import { FC, ReactElement } from 'react';
import { AxisTickProps } from '@nivo/axes';
import { variables } from '@styles';
import { BarChartData } from '@modules';
import { STEP_X, MAX_TICK_LENGTH, TICK_PADDING_RIGHT } from './BarChart.const';

export const BarChartTick: FC<AxisTickProps<string> & { data: BarChartData[] }> = ({
  data,
  textAnchor,
  textX,
  textY,
  rotate,
  tickIndex,
  value,
}): ReactElement => {
  const label = data.find((item) => item.id === Number(value))?.label || '';
  return (
    <g transform={`translate(${tickIndex * STEP_X - TICK_PADDING_RIGHT},${textY}) rotate(${rotate})`}>
      <text
        alignmentBaseline="central"
        style={{
          fontSize: variables.font.size.xs,
          fill: variables.color.secondary.gray,
        }}
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY})`}
      >
        {label && label.length > MAX_TICK_LENGTH ? `${label.substring(0, MAX_TICK_LENGTH)}...` : label}
      </text>
    </g>
  );
};
