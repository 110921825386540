import { SyntheticEvent, useCallback, useState } from 'react';

import { doc } from '@utils';

import { fillDocWithPlaceholders, getDraftSize } from './TemplateUpload.utils';
import { HtmlDraftArg, HtmlDraftHook } from './TemplateUpload.types';

export const useHtmlDraft = (onDecorate: HtmlDraftArg): HtmlDraftHook => {
  const [srcDoc, setSrcDoc] = useState('');

  const handleFrameLoad = useCallback(
    (event: SyntheticEvent<HTMLIFrameElement>) => {
      const draftDoc = event.currentTarget.contentDocument;

      if (draftDoc) {
        if (draftDoc.body.children) {
          fillDocWithPlaceholders(draftDoc);
        }

        draftDoc.title = '';

        const html = `${doc.getDoctype(draftDoc)}${draftDoc.documentElement.outerHTML}`;
        const size = getDraftSize(draftDoc);

        setSrcDoc('');
        onDecorate({ ...size, html });
      }
    },
    [onDecorate, setSrcDoc],
  );

  return {
    setSrcDoc,
    ...(srcDoc
      ? {
          frame: (
            <iframe
              style={{
                position: 'fixed',
                left: 0,
                top: 0,
                border: 0,
                width: '100%',
                height: '100%',
                visibility: 'hidden',
                pointerEvents: 'none',
              }}
              title="template_upload.draft"
              srcDoc={srcDoc}
              marginWidth={0}
              marginHeight={0}
              scrolling="no"
              onLoad={handleFrameLoad}
            />
          ),
        }
      : null),
  };
};
