import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

import {
  DatasetKey,
  DatasetValue,
  ChildKey,
  UseStyles,
  DatasetState,
  DatasetFilled,
  DatasetUiType,
} from './FileUpload.types';

const pair = (datasetKey: DatasetKey, value: DatasetValue): string => `&[data-${datasetKey}="${value}"]`;

const uiType = (value: DatasetUiType): string => pair('ui-type', value);

const state = (value: DatasetState): string => pair('state', value);

const filled = (value: DatasetFilled): string => pair('filled', value);

const child = (...keys: ChildKey[]): string => `& > $${keys.join(' > $')}`;

const children = (...keys: ChildKey[]): string => keys.map((key) => child(key)).join(', ');

export const useStyles: UseStyles = makeStyles((theme) =>
  createStyles({
    fileUpload: {
      display: 'flex',
      maxWidth: '100%',

      [uiType('primary')]: {
        flexDirection: 'column',

        [child('main', 'body')]: {
          padding: theme.spacing(2),

          [child('placeholder')]: {
            marginBottom: theme.spacing(0.5),

            '&:not(:last-child)': {
              color: variables.color.semantic.neutral,
            },

            '&:last-child': {
              color: variables.color.primary.mainPurple,
            },
          },
        },

        [filled(false)]: {
          [`${state('default')}, ${state('error')}`]: {
            [`${child('main', 'body')}:hover, ${child('main', 'body')}[data-active="true"]`]: {
              borderColor: variables.color.primary.mainPurple,
              backgroundColor: variables.color.primary.lighterPurple,
              cursor: 'pointer',
            },
          },
        },
      },

      [uiType('secondary')]: {
        flexDirection: 'row',

        [child('label')]: {
          width: '15rem',
          marginRight: theme.spacing(5),
        },

        [child('main')]: {
          [child('body')]: {
            flexDirection: 'row',
            padding: theme.spacing(0.875),

            [child('placeholder')]: {
              marginLeft: theme.spacing(1),
            },
          },
        },

        [state('default')]: {
          [child('main', 'body')]: {
            [children('name', 'placeholder')]: {
              color: variables.color.semantic.neutral,
            },

            [`${child('icon')} > path`]: {
              fill: variables.color.semantic.neutral,
            },
          },
        },

        [state('error')]: {
          [child('main', 'body', 'placeholder')]: {
            color: variables.color.semantic.negative,
          },
        },

        [filled(false)]: {
          [child('main')]: {
            width: '30rem',
          },

          [`${state('default')}, ${state('error')}`]: {
            [`${child('main', 'body')}[data-active="true"]`]: {
              borderColor: variables.color.primary.mainPurple,
              backgroundColor: variables.color.primary.lighterPurple,
              cursor: 'pointer',
            },
          },
        },

        [filled(true)]: {
          [child('main')]: {
            width: '31.5rem',
          },
        },
      },

      [uiType('tertiary')]: {
        flexDirection: 'row',
        alignItems: 'center',

        [child('label')]: {
          width: '15rem',
          marginRight: theme.spacing(5),
        },

        [child('main')]: {
          width: '34.2rem',

          [child('body')]: {
            flexDirection: 'row',
            padding: theme.spacing(0.875),
            borderStyle: 'solid',
            borderColor: variables.color.primary.lightGrayDarker,

            [child('placeholder')]: {
              marginLeft: theme.spacing(1),
              color: variables.color.primary.halfMediumGray,
              fontWeight: variables.font.weight.medium,
              fontSize: variables.font.size.sm,
            },
          },
        },

        '& $clear': {
          marginLeft: theme.spacing(1.5),
        },

        [state('error')]: {
          [child('main', 'body', 'placeholder')]: {
            color: variables.color.semantic.negative,
          },
        },

        [filled(false)]: {
          [`${state('default')}, ${state('error')}`]: {
            [`${child('main', 'body')}[data-active="true"]`]: {
              borderColor: variables.color.primary.mainPurple,
              backgroundColor: variables.color.primary.lighterPurple,
              cursor: 'pointer',
            },
          },
        },

        [filled(true)]: {
          [child('main', 'body')]: {
            [children('name', 'placeholder')]: {
              color: variables.color.primary.darkestGray,
            },
          },
        },
      },

      [state('default')]: {
        [child('main', 'body')]: {
          borderColor: variables.color.primary.lightGray,
          backgroundColor: 'transparent',

          [`${child('icon')} > path`]: {
            fill: variables.color.primary.darkestGray,
          },
        },
      },

      [state('disabled')]: {
        [child('main', 'body')]: {
          borderColor: variables.color.primary.lightGrayDarker,
          backgroundColor: variables.color.semantic.neutralLight,

          [children('name', 'placeholder')]: {
            color: variables.color.primary.halfMediumGray,
          },

          [`${child('icon')} > path`]: {
            fill: variables.color.primary.halfMediumGray,
          },
        },

        [child('main', 'hint')]: {
          color: variables.color.primary.halfMediumGray,
        },
      },

      [state('error')]: {
        [child('label')]: {
          color: variables.color.semantic.negative,
        },

        [child('main', 'body')]: {
          borderColor: variables.color.semantic.negative,
          backgroundColor: variables.color.semantic.negativeLight,

          [child('name')]: {
            color: variables.color.semantic.negative,
          },

          [`${child('icon')} > path`]: {
            fill: variables.color.semantic.negative,
          },
        },

        [child('main', 'hint')]: {
          color: variables.color.semantic.negative,
        },
      },

      [filled(false)]: {
        [child('main', 'body')]: {
          flexDirection: 'column',
          borderStyle: 'dashed',
        },
      },

      [filled(true)]: {
        [child('main', 'body')]: {
          flexDirection: 'row',
          borderStyle: 'solid',
        },
      },
    },

    label: {
      marginBottom: theme.spacing(1),
      fontSize: `${variables.font.size.sm}!important`,
      color: `${variables.color.secondary.gray}!important`,
    },

    main: {
      display: 'flex',
      flexDirection: 'column',
    },

    body: {
      display: 'flex',
      alignItems: 'center',
      borderWidth: variables.borderWidth.sm,
      borderRadius: variables.borderRadius.sm,
    },

    placeholder: {
      pointerEvents: 'none',
    },

    icon: {
      width: '1.6rem',
      height: '1.6rem',
    },

    name: {
      marginLeft: theme.spacing(1),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    meta: {
      display: 'flex',
      marginLeft: 'auto',
    },

    clear: {
      padding: 0,
      marginLeft: theme.spacing(1),
    },

    hint: {
      padding: theme.spacing(0.5, 0, 0, 1.5),
    },

    browse: {
      '&&': {
        borderWidth: variables.borderWidth.sm,
        padding: theme.spacing(0.875, 1.375),
        marginLeft: theme.spacing(1),

        '& > .MuiButton-label': {
          height: '1.8rem',
        },
      },
    },
  }),
);
