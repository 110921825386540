import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      padding: 0,
      fontSize: 0,
      stroke: variables.color.primary.lightPurple,
      '& .MuiIconButton-label': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  }),
);
