import { OptionItem } from '@components';
import { content } from '@content';
import { OptionsTypes } from '@views/LibraryNavigation/LibraryNavigation.const';

/**
 TreatmentCard component variables
 @returns {variables}
 */

export enum MenuKeys {
  EDIT_TREATMENT = 'edit-treatment',
  EDIT_TREATMENT_NAME = 'edit-treatment-name',
  EDIT_MODULE = 'edit-module',
  REMOVE_TREATMENT = 'remove-treatment',
  REMOVE_MODULE = 'remove-module',
}

export const MENU_OPTIONS: OptionItem[] = [
  {
    id: MenuKeys.EDIT_TREATMENT_NAME,
    label: content.editTreatmentName,
    type: OptionsTypes.LAST_USED,
  },
  {
    id: MenuKeys.EDIT_TREATMENT,
    label: content.editTreatment,
    type: OptionsTypes.LAST_USED,
  },
  {
    id: MenuKeys.EDIT_MODULE,
    label: content.editModule,
    type: OptionsTypes.LAST_USED,
  },
  {
    id: '3',
    type: OptionsTypes.DIVIDER,
  },
  {
    id: MenuKeys.REMOVE_TREATMENT,
    label: content.removeTreatment,
    type: OptionsTypes.LAST_USED,
    icon: 'TrashOutline',
  },
];

export const MENU_OPTIONS_UNIVERSAL: OptionItem[] = [
  {
    id: MenuKeys.EDIT_MODULE,
    label: content.editModule,
    type: OptionsTypes.LAST_USED,
  },
];
