import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { TextFieldDirection, TextFieldSize } from './TextField.props';

export type DatasetSize = TextFieldSize;

export type DatasetDirection = TextFieldDirection;

export type DatasetState = 'default' | 'disabled' | 'error';

export type DatasetIcon = 'start' | 'end' | 'none';

export enum DatasetKey {
  size = 'data-size',
  direction = 'data-direction',
  state = 'data-state',
  icon = 'data-icon',
}

export type Dataset = {
  [DatasetKey.size]: DatasetSize;
  [DatasetKey.direction]: DatasetDirection;
  [DatasetKey.state]: DatasetState;
  [DatasetKey.icon]: DatasetIcon;
};

export type DatasetKeyName = keyof typeof DatasetKey;

export type DatasetValue = Dataset[keyof Dataset];

export type ChildKey = 'meta' | 'label' | 'subLabel' | 'main' | 'body' | 'input' | 'icon' | 'sugs' | 'sugn' | 'hint';

export type UseStyles = () => ClassNameMap<'textField' | ChildKey>;
