import { RefObject, useLayoutEffect, useRef } from 'react';

export function useScrollableContainer<T extends HTMLElement>(
  initialValue: T | null,
  deps: unknown[] = [],
): RefObject<T> {
  const refElement = useRef(initialValue);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.style.overflowY = 'hidden';

    function resizeContainer() {
      if (refElement.current) {
        refElement.current.style.overflowY = 'auto';
        refElement.current.style.height = `${document.documentElement.offsetHeight - refElement.current.offsetTop}px`;
      }
    }

    window.addEventListener('resize', resizeContainer);
    resizeContainer();
    return () => {
      document.documentElement.style.overflowY = 'visible';
      window.removeEventListener('resize', resizeContainer);
    };
  }, [...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  return refElement;
}
