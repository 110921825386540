import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const filterLabel = {
  flexBasis: '20%',
  width: '22.4rem',
  paddingTop: variables.gap.sm,
};

const filterField = {
  marginBottom: '3.6rem',
};

const filterDateField = {
  ...filterField,
  flexBasis: '29.2%',
  maxWidth: '32.6rem',
};

const hidden = {
  display: 'none',
};

export const useStyles = makeStyles(() =>
  createStyles({
    queryField: {
      '& .MuiInput-underline': {
        '&::before': {
          borderBottom: `2px solid ${variables.color.primary.lightPurple}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `2px solid ${variables.color.primary.lightPurple}`,
        },
        '&.Mui-focused:after': {
          borderBottom: `2px solid ${variables.color.primary.lightPurple}`,
        },
      },
      '& .Mui-focused': {
        backgroundColor: 'transparent',
      },
      '& .MuiInputBase-input': {
        fontSize: variables.font.size.xxxl,
        fontWeight: variables.font.weight.bold,
        lineHeight: variables.lineHeight.xxxl,
        color: variables.color.primary.gray,
        padding: '0.1rem 0 0.5rem 1.5rem',
      },
    },
    filterDropdownBtn: {
      marginRight: variables.gap.sm,
      transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        background: 'transparent',
      },
    },
    filterDropdownOpenedBtn: {
      transform: 'rotate(180deg)',
    },
    filterResetBtn: {
      marginRight: variables.gap.lg,
    },
    filter: {
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
      boxShadow: '0.1rem 0.1rem 0.7rem 0.4rem rgba(208, 208, 206, 0.3)',
      maxWidth: '101.9rem',
      padding: '5rem 0 1rem 0',
    },
    filterGrid: {
      padding: '0 6.8% 0 8.4%',
    },
    filterLabel,
    filterTextField: {
      ...filterField,
      flexBasis: '80%',
      flexGrow: 1,
    },
    dateLabel: {
      ...filterLabel,
      ...hidden,
    },
    startDate: {
      ...filterDateField,
      marginRight: '1.5rem',
      ...hidden,
    },
    endDate: {
      ...filterDateField,
      ...hidden,
    },

    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: `2.4rem 1.8rem ${variables.gap.sm}`,
    },

    arrowDownIcon: {
      stroke: variables.color.primary.lightPurple,
    },
    personalizationCheck: {
      marginLeft: 0,
      marginRight: '4.9rem',
      '& .MuiTypography-root': {
        fontSize: variables.font.size.sm,
        lineHeight: variables.lineHeight.sm,
        fontWeight: variables.font.weight.regular,
        marginLeft: '0.9rem',
      },
    },
  }),
);
