import { SVGProps } from 'react';

import { variables } from '@styles';

export const DuplicateOutline = ({
  width = 16,
  height = 16,
  fill = variables.color.primary.darkestGray,
  className = '',
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.39995 11.6998C8.39995 12.1998 8.79995 12.4998 9.19995 12.4998C9.69995 12.4998 9.99995 12.1998 9.99995 11.7998V9.9998H11.8C12.2 9.9998 12.6 9.8998 12.6 9.3998C12.6 8.8998 12.2 8.5998 11.8 8.5998H9.99995V6.7998C9.99995 6.2998 9.59995 5.9998 9.19995 5.9998C8.79995 5.9998 8.49995 6.2998 8.59995 6.7998V8.3998H6.79995C6.29995 8.3998 5.99995 8.6998 5.99995 9.1998C5.99995 9.6998 6.29995 9.9998 6.79995 9.9998H8.39995V11.6998Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.30193 12.7998H3.19995C2.49995 12.7998 1.89995 12.4998 1.39995 12.0998C0.899951 11.5998 0.699951 10.9998 0.699951 10.2998V3.4998C0.699951 2.7998 0.999951 2.0998 1.49995 1.5998C2.09995 0.999805 2.79995 0.799805 3.49995 0.799805H10.3C11 0.799805 11.6 1.0998 12.1 1.4998C12.6 1.9998 12.8 2.5998 12.8 3.2998V3.30168C14.152 3.35258 15.2 4.43328 15.2 5.7998V12.6998C15.2 14.0998 14.1 15.1998 12.7 15.1998H5.80005C4.43356 15.1998 3.35288 14.1518 3.30193 12.7998ZM3.30005 11.1998V5.7998C3.30005 4.39981 4.40005 3.2998 5.80005 3.2998H11.3V3.1998C11.3 2.9998 11.2 2.6998 11 2.4998C10.8 2.2998 10.6 2.1998 10.3 2.1998H3.49995C3.19995 2.1998 2.89995 2.2998 2.59995 2.5998C2.39995 2.8998 2.29995 3.1998 2.19995 3.4998V10.1998C2.19995 10.4998 2.29995 10.6998 2.49995 10.8998C2.69995 11.0998 2.89995 11.1998 3.19995 11.1998H3.30005ZM5.80005 4.7998C5.20005 4.7998 4.80005 5.2998 4.80005 5.7998V12.6998C4.80005 13.2998 5.30005 13.6998 5.80005 13.6998H12.7C13.3 13.6998 13.7 13.1998 13.7 12.6998V5.7998C13.7 5.1998 13.2 4.7998 12.7 4.7998H5.80005Z"
      fill={fill}
    />
  </svg>
);
