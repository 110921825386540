import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useStyles = makeStyles(() =>
  createStyles({
    deploymentBuilderCollapsed: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: variables.borderRadius.sm,
      background: 'rgba(255, 255, 255, 0.7)',
      marginRight: '0.7rem',
      padding: '1.8rem 0.5rem 0 0.3rem',
    },
    button: {
      marginBottom: variables.gap.md,
    },
    title: {
      fontWeight: variables.font.weight.bold,
      color: variables.color.primary.gray,
      transform: 'rotate(-180deg)',
      writingMode: 'vertical-lr',
    },
  }),
);
