import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const imageMaxSize = '65.2rem';

const content = {
  width: `calc(${imageMaxSize} - ${variables.gap.lg} * 2)`,
};

export const useStyles = makeStyles(() =>
  createStyles({
    moduleInfoModal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0 auto',
      width: '100%',
      overflowY: 'auto',
    },

    header: {
      ...content,
      marginTop: '5rem',
    },

    title: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: variables.gap.xs,
    },

    actions: {
      display: 'flex',
      alignItems: 'flex-start',
      flexShrink: 0,
    },

    copy: {
      marginRight: variables.gap.sm,
    },

    body: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: `calc(100% - ${variables.gap.sm} * 2)`,
      maxHeight: '70rem',
      overflowY: 'auto',
      overflowX: 'visible',
      marginTop: variables.gap.xl,
    },

    params: {
      ...content,
    },

    param: {
      flexBasis: '50%',
      marginBottom: '3.2rem',
    },

    image: {
      boxShadow: '0.1rem 0.1rem 0.7rem 0.4rem rgba(208, 208, 206, 0.3)',
      marginTop: '2.4rem',
      maxWidth: imageMaxSize,
      maxHeight: imageMaxSize,
      fontSize: 0,
    },

    disclosure: {
      ...content,
      marginTop: '4rem',
    },
  }),
);
